import { createSlice } from '@reduxjs/toolkit'

const getEventUploadAvatarSlice = createSlice({
    name: 'getEventUploadAvatar',
    initialState: {
        data: null,
    },
    reducers: {
        eventUpload: (state, action) => {
            state.data = action.payload
        },
    },
})

export default getEventUploadAvatarSlice
