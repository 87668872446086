import BookingRevenue from './BookingRevenue'
import { revenue_task_by_month_by_year_admin } from '../../../../../utils/api/apiList'

const TaskBookingRevenue = () => {
    return (
        <BookingRevenue title='CA LẺ' subtitle='Trang thống kê doanh thu ca lẻ' apiEndpoint={revenue_task_by_month_by_year_admin} nameChart='Task' />
    )
}

export default TaskBookingRevenue
