import { profit_spray_by_month_by_year_admin } from '../../../../../utils/api/apiList'
import BookingProfit from './BookingProfit'

const SprayProfit = () => {
    return (
        <BookingProfit
            title='PHUN KHỬ KHUẨN'
            subtitle='Trang thống kê lợi nhuận phun khử khuẩn'
            apiEndpoint={profit_spray_by_month_by_year_admin}
            nameChart='Spray'
        />
    )
}

export default SprayProfit
