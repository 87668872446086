import CircularProgress from '@mui/material/CircularProgress'
import { Box } from '@mui/material'

const CustomLoadingComponent = () => (
    <Box
        sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
        }}
    >
        <CircularProgress color='secondary' />
    </Box>
)

export default CustomLoadingComponent
