import { useState, useEffect, forwardRef, useCallback } from 'react'
import { Box, Typography, useTheme, IconButton, Tooltip, Dialog, Slide, Button, Chip, debounce } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { tokens } from '../../../theme'
import Header from '../../../components/Header'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import StyleDataGrid from '../../../assets/styles/styleDataGrid'
import request from '../../../utils/api/request'
import {
    delete_permanent_taskbooking_by_admin,
    get_permanent_taskbooking_by_id,
    get_permanent_taskbookings2,
    payment_from_admin,
} from '../../../utils/api/apiList'
import CustomNoRowsOverlay from '../../../components/noRows'
import CustomLoadingComponent from '../../../components/loading'
import Calendar from '../../calendar'
import { splitByConsecutiveDates } from '../../../algorithm/phanTachNhomNgay'
import ModalDetails from '../../../components/modal/modalDetails'
import { processData, getRowClassName, formatNumber } from '../../../components/prefixData'
import { Add, Close } from '@mui/icons-material'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import { useSelector } from 'react-redux'
import { getEventAdminCreatePermanentSelector, getEventNewOrderSelector } from '../../../redux/selectors'
import { useLocation, useNavigate } from 'react-router-dom'
import { routerLinks } from '../../../routes/constant'
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined'
import ModalCreateAllTask from '../../../components/modal/modalCreateAllTask'
import minutesToHours from '../../../algorithm/minutesToHours'
import PopoverComponent from '../../../components/popover'
import { message } from 'antd'
import DeleteIcon from '@mui/icons-material/Delete'

//
const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction='down' ref={ref} {...props} />
})

const PermanentTask = () => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    // const [pageSize, setPageSize] = useState(10)
    const [dataPermanentTask, setDataPermanentTask] = useState([])
    const [loading, setLoading] = useState(true)
    const [detailsAnchorEl, setDetailsAnchorEl] = useState(null)
    const [currentRow, setCurrentRow] = useState({})
    const [dataDetails, setDataDetails] = useState({})
    const [openCalendar, setOpenCalendar] = useState(false)
    const [dataCalendar, setDataCalendar] = useState({})
    const [arr, setArr] = useState([])
    const [anchorElBoxCreateTask, setAnchorElBoxCreateTask] = useState(null)
    const [anchorElAccept, setAnchorElAccept] = useState(null)
    const [anchorElReject, setAnchorElReject] = useState(null)

    //selector
    const eventNewOrder = useSelector(getEventNewOrderSelector)
    const eventAdminCreatePermanent = useSelector(getEventAdminCreatePermanentSelector)

    // pagination server side
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 25,
    })
    const [totalRows, setTotalRows] = useState(0)
    //search server side
    const [searchTerm, setSearchTerm] = useState('')
    const [inputText, setInputText] = useState('')
    //sort server
    const [queryOptions, setQueryOptions] = useState({ createdAt: -1 })

    //location
    let location = useLocation()
    const { _id } = location.state || {}
    const { infoWork } = location.state || {}

    //navigate
    const navigate = useNavigate()

    // Debounced
    const debouncedSetSearchTerm = useCallback(
        debounce((value) => {
            setSearchTerm(value)
        }, 700),
        []
    )

    // handle search
    const handleInputChange = (e) => {
        const value = e.target.value
        setInputText(value)
        debouncedSetSearchTerm(value)
    }

    // handle sort server
    const handleSortModelChange = useCallback((sortModel) => {
        if (sortModel.length > 0) {
            const sortField = sortModel[0].field
            const sortOrder = sortModel[0].sort === 'asc' ? 1 : -1
            setQueryOptions({ [sortField]: sortOrder })
        } else {
            setQueryOptions({ createdAt: -1 })
        }
    }, [])

    //fetch data
    const fetchData = () => {
        request
            .post(get_permanent_taskbookings2, {
                page: paginationModel.page + 1,
                limit: paginationModel.pageSize,
                search: searchTerm,
                sort: queryOptions,
            })
            .then((res) => {
                const dataRs = processData(res.data.data.docs, paginationModel.page, paginationModel.pageSize)
                const convertDataRs = dataRs.map((elm) => {
                    return {
                        ...elm,
                        userName: elm?.user?.fullName ?? elm?.userFromAdminOrder?.name,
                    }
                })

                setDataPermanentTask(convertDataRs)
                setLoading(false)
                setTotalRows(res.data.data.total)
            })
            .catch((err) => {
                console.log(err)
                setLoading(false)
            })
    }
    const fetchInfoUser = (_id) => {
        request
            .post(get_permanent_taskbooking_by_id, { _id })
            .then((res) => {
                setDataDetails(res.data.data)
                console.log('log o day')
            })
            .catch((err) => console.log(err))
    }
    useEffect(() => {
        fetchData()
    }, [eventNewOrder, paginationModel, eventAdminCreatePermanent, searchTerm, queryOptions])

    //column
    const columns = [
        {
            field: 'index',
            headerName: 'STT',
            width: 50,
            sortable: false,
        },
        {
            field: '_id',
            headerName: 'ID đơn hàng',
            width: 200,
            sortable: false,
        },
        {
            field: 'userName',
            headerName: 'Tên khách hàng',
            width: 150,
            sortable: false,
        },
        {
            field: 'price',
            headerName: 'Giá đơn hàng',
            minWidth: 150,
            cellClassName: 'cost-column name-column--cell',
            renderCell: (params) => {
                return (
                    <Typography fontSize='14px' fontWeight={600}>
                        {formatNumber(params.row.price)} VNĐ
                    </Typography>
                )
            },
            sortable: false,
        },
        {
            field: 'date',
            headerName: 'Thời gian',
            minWidth: 150,
            renderCell: ({ row }) => {
                const startTime = minutesToHours(row.time)
                const endTimeMinutes = row?.taskId?.estimateTime + row.time
                const endTime = minutesToHours(endTimeMinutes)

                return (
                    <Box>
                        <Typography color={theme.palette.mode === 'dark' ? colors.greenAccent[400] : '#1939e3'} fontSize='14px' fontWeight={600}>
                            {startTime} - {endTime}
                        </Typography>
                        <Typography
                            color={colors.blueAccent[400]}
                            sx={{
                                '&:hover': {
                                    cursor: 'pointer',
                                    color: colors.blueAccent[500],
                                },
                            }}
                            onClick={() => handleOpenCalendar(row)}
                        >
                            xem lịch
                        </Typography>
                    </Box>
                )
            },
        },
        {
            field: 'address',
            headerName: 'Địa điểm',
            flex: 1,
            minWidth: 420,
            renderCell: ({ row }) => {
                return (
                    <Tooltip title={<h1 style={{ fontSize: '11px' }}>{row?.taskId?.address}</h1>}>
                        <Typography fontSize='14px'>{row?.taskId?.address}</Typography>
                    </Tooltip>
                )
            },
            sortable: false,
        },
        {
            field: 'status',
            headerName: 'Trạng thái đơn',
            width: 150,
            minWidth: 100,
            renderCell: ({ row }) => {
                const getColor = (statusRoot) => {
                    switch (statusRoot) {
                        case 0:
                            return 'success'
                        case 2:
                            return 'info'
                        default:
                            return 'default'
                    }
                }

                return (
                    <Chip
                        key={row.status}
                        label={row.status}
                        color={getColor(row.statusRoot)}
                        sx={{
                            minWidth: '100px',
                        }}
                    />
                )
            },
        },
        {
            field: 'action',
            headerName: 'Actions',
            cellClassName: 'action-column',
            width: 200,
            minWidth: 200,
            sortable: false,
            renderCell: ({ row }) => <BtnOpenModalDetails row={row} />,
        },
    ]
    const BtnOpenModalDetails = ({ row }) => {
        return (
            <Box display='flex' alignItems='center' justifyContent='center' gap={1}>
                <Tooltip
                    title='chi tiết đơn'
                    slotProps={{
                        popper: {
                            modifiers: [
                                {
                                    name: 'offset',
                                    options: {
                                        offset: [0, -10],
                                    },
                                },
                            ],
                        },
                    }}
                >
                    <IconButton onClick={(e) => handleOpenDetailsModal(row, e)}>
                        <MoreHorizIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip
                    title='Trang quản lý'
                    slotProps={{
                        popper: {
                            modifiers: [
                                {
                                    name: 'offset',
                                    options: {
                                        offset: [0, -10],
                                    },
                                },
                            ],
                        },
                    }}
                >
                    <IconButton
                        onClick={() => {
                            navigate(routerLinks.DetailsPermanentTask, { state: { row } })
                        }}
                    >
                        <ReceiptLongOutlinedIcon />
                    </IconButton>
                </Tooltip>
                {!row.isPaid && row.hasOwnProperty('userFromAdminOrder') && (
                    <Tooltip title='Thanh toán đơn'>
                        <IconButton onClick={(e) => handleOpenPopoverAccept(e, row)} disabled={row.isPaid ? true : false}>
                            <CheckCircleOutlineOutlinedIcon
                                color={row.isPaid ? 'disabled' : theme.palette.mode === 'dark' ? 'success' : colors.greenLight[100]}
                            />
                        </IconButton>
                    </Tooltip>
                )}
                {!row.isPaid && row.hasOwnProperty('userFromAdminOrder') && (
                    <Tooltip title='Huỷ đơn hàng'>
                        <IconButton onClick={(e) => handleOpenPopoverReject(e, row)}>
                            <DeleteIcon color='error' />
                        </IconButton>
                    </Tooltip>
                )}
            </Box>
        )
    }

    //handle accept task
    const handleAcceptTask = () => {
        fetchAcceptPermanentTask(currentRow._id)
        setAnchorElAccept(false)
    }
    const handleRejectAccept = () => {
        setAnchorElAccept(false)
    }

    const handleOpenPopoverAccept = (e, row) => {
        setCurrentRow(row)
        setAnchorElAccept(e.currentTarget)
    }

    const fetchAcceptPermanentTask = (_id) => {
        request
            .post(payment_from_admin, { _id })
            .then((res) => {
                fetchData()
                console.log(res)
                message.success('Chấp nhận thanh toán đơn thành công!')
            })
            .catch((err) => {
                console.error(err)
                message.error('Lỗi: Thanh toán đơn thất bại!')
            })
    }

    //handle delete permanent task
    const handleOpenPopoverReject = (e, row) => {
        console.log('aaa', row)
        setAnchorElReject(e.currentTarget)
        setCurrentRow(row)
    }
    const handleCloseBoxReject = () => {
        setAnchorElReject(false)
    }

    const handleConfirmReject = () => {
        const permanentTaskBookingId = currentRow._id
        const taskId = currentRow.taskId
        console.log('aaa')

        fetchRejectPermanentTask(permanentTaskBookingId, taskId)
        setAnchorElReject(false)
    }

    const fetchRejectPermanentTask = (permanentTaskBookingId, taskId) => {
        request
            .post(delete_permanent_taskbooking_by_admin, { permanentTaskBookingId, taskId })
            .then((res) => {
                console.log(res)
                message.success('Huỷ đơn thành công!')
                fetchData()
            })
            .catch((err) => {
                message.error('Lỗi: huỷ đơn thất bại!')
            })
    }

    //open calendar
    const handleOpenCalendar = (row) => {
        setCurrentRow(row)
        const newRow = { ...row }
        newRow.dateRoot = splitByConsecutiveDates(newRow.dateRoot)

        const arrayTest = newRow.dateRoot.map((listDate, index) => {
            return {
                id: `Lịch ${index + 1}`,
                title: 'Lịch ' + (index + 1),
                start: listDate[0],
                end: listDate[listDate.length - 1],
                // allDay: true,
            }
            // const startTime = moment(listDate[0])
            // startTime.set('hours', Math.floor(row.time / 60))
            // startTime.set('minutes', row.time % 60)
            // startTime.set('seconds', 0)

            // const endTime = moment(listDate[listDate.length - 1])
            // endTime.set('hours', startTime.get('hours'))
            // endTime.set('minutes', startTime.get('minutes'))
            // endTime.add(row.taskId.estimateTime, 'minutes')
            // const endTime = startTime.clone() // Create a clone to avoid modifying startTime
            // endTime.add(row.taskId.estimateTime, 'minutes')
            // console.log(endTime.format('YYYY-MM-DD HH:mm:ss'))

            // return {
            //     id: `Lịch ${index + 1}`,
            //     title: 'Lịch ' + (index + 1),
            //     start: startTime.format('YYYY-MM-DD HH:mm:ss'), //listDate[0],
            //     end: endTime.format('YYYY-MM-DD HH:mm:ss'), //listDate[listDate.length - 1],
            //     // allDay: false,
            // }
        })
        setArr(arrayTest)

        setOpenCalendar(true)
    }
    const handleClose = () => {
        setOpenCalendar(false)
    }
    const ModalCalendar = () => {
        return (
            <Dialog
                open={openCalendar}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                maxWidth='lg'
                fullWidth={true}
                sx={{
                    '& .MuiPaper-root': {
                        bgcolor: theme.palette.mode === 'dark' ? '#041425' : 'white',
                        maxHeight: 'unset',
                    },
                }}
            >
                <Calendar data={arr} dataRow={currentRow} />
                <IconButton
                    onClick={handleClose}
                    aria-label='Close'
                    sx={{
                        position: 'absolute',
                        top: 20,
                        right: 20,
                    }}
                >
                    <Close />
                </IconButton>
            </Dialog>
        )
    }

    //modal details
    const handleOpenDetailsModal = (row, event) => {
        setDetailsAnchorEl(event.currentTarget)
        const _id = row._id
        fetchInfoUser(_id)
        setDataCalendar(row)
        setCurrentRow(row)
        console.log(row)
    }

    const handleCloseModalDetails = () => {
        setDetailsAnchorEl(false)
    }

    // tao don hang
    const handleOpenBoxCreateTask = (e) => {
        setAnchorElBoxCreateTask(e.currentTarget)
    }
    const handleCloseBoxCreateTask = () => {
        setAnchorElBoxCreateTask(false)
    }

    return (
        <Box
            m='20px'
            sx={{
                '& .MuiTypography-root.MuiTypography-h2': {
                    margin: '0 0 5px 0',
                },
            }}
        >
            <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Header title='QUẢN LÝ ĐƠN HÀNG CA CỐ ĐỊNH' subtitle='Trang quản lý đơn hàng ca cố định' />
                <Button
                    sx={{
                        backgroundColor: colors.blueAccent[700],
                        color: 'white',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        padding: '10px 20px',
                        '&:hover': {
                            backgroundColor: colors.blueAccent[600],
                        },
                    }}
                    startIcon={<Add />}
                    onClick={(e) => handleOpenBoxCreateTask(e)}
                >
                    Tạo đơn hàng
                </Button>
            </Box>
            <Box height='75vh' m='40px 0 0 0' sx={StyleDataGrid()}>
                <DataGrid
                    rows={dataPermanentTask}
                    getRowId={(row) => row._id}
                    columns={columns}
                    rowCount={totalRows}
                    //pagination server side
                    initialState={{
                        pagination: { paginationModel },
                        density: 'comfortable',
                    }}
                    pagination
                    pageSizeOptions={[10, 25, 50, 100]}
                    paginationMode='server'
                    onPaginationModelChange={setPaginationModel}
                    //pagination server side

                    // sort server
                    sortingMode='server'
                    onSortModelChange={handleSortModelChange}
                    loading={loading}
                    slots={{
                        toolbar: GridToolbar,
                        noRowsOverlay: CustomNoRowsOverlay,
                        loadingOverlay: CustomLoadingComponent,
                    }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,

                            quickFilterProps: {
                                onChange: handleInputChange,
                                value: inputText,
                                placeholder: 'tìm kiếm theo địa chỉ',
                            },
                        },
                    }}
                    // onPageSizeChange={handlePageSizeChange}
                    getRowClassName={(params) => getRowClassName(params, infoWork?.data?._id)}
                />
            </Box>
            {detailsAnchorEl && (
                <ModalDetails
                    modalName='permanentTask'
                    dataDetails={dataDetails}
                    open={Boolean(detailsAnchorEl)}
                    onClose={handleCloseModalDetails}
                    handleOpenCalendar={handleOpenCalendar}
                    dataCalendar={dataCalendar}
                    fetchData={fetchData}
                    fetchDataDetails={fetchInfoUser}
                />
            )}
            {ModalCalendar()}
            {anchorElBoxCreateTask && (
                <ModalCreateAllTask boxName='permanentTask' open={Boolean(anchorElBoxCreateTask)} onClose={handleCloseBoxCreateTask} />
            )}
            <PopoverComponent
                type={'accept'}
                idRow={currentRow._id}
                anchorEl={anchorElAccept}
                onClose={handleRejectAccept}
                handleConfirm={handleAcceptTask}
                boxName={'permanentTask'}
            ></PopoverComponent>
            <PopoverComponent
                type={'cancel'}
                idRow={currentRow._id}
                anchorEl={anchorElReject}
                onClose={handleCloseBoxReject}
                handleConfirm={handleConfirmReject}
                boxName={'permanentTask'}
            ></PopoverComponent>
        </Box>
    )
}

export default PermanentTask
