import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    FormHelperText,
    IconButton,
    MenuItem,
    Modal,
    Select,
    TextField,
    Typography,
    useTheme,
} from '@mui/material'
import { tokens } from '../../theme'
import { Cancel, Close, Send } from '@mui/icons-material'
import { Formik } from 'formik'
import { formatNumber, parseNumber } from '../../components/prefixData'
import { message } from 'antd'
import { useEffect } from 'react'
import ModalCustom from '../../components/modal/modal'
import TextFieldCustom from '../../components/form/textfield'

function ModalPackage({ open, onClose, type, loading, uniqueMonths, initialFormValues, validateFormSchema, handleConfirm }) {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    //message
    const [messageApi, messageContextHolder] = message.useMessage()
    const styleBoxMobile = {
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' }, 
        gap: { xs: '10px!important', sm: '20px!important' },
        alignItems: { xs: 'flex-start!important', sm: 'center!important' },
        width: { xs: '100% !important', sm: `92%!important` },
    }
    
    return (
        <ModalCustom
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    onClose
                }
            }}
            bgcolor={theme.palette.mode === 'dark' ? '#1F2A40' : '#e0e0e0'}
            sxBox={{
                '& .box': {
                    '& .MuiTypography-root': {
                        minWidth: '120px',
                    },
                    '& .MuiBox-root.select': {
                        width: '100%',
                    },
                    display: 'flex',
                    alignItems: 'center',
                    gap: '20px',
                },
            }}
        >
            
            {messageContextHolder}
            <Typography id='modal-modal-title' color={colors.grey[500]} fontSize='16px'>
                {!!initialFormValues?.name ? 'CHỈNH SỬA GÓI' : 'THÊM GÓI'}
            </Typography>
            <IconButton
                sx={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                }}
                onClick={onClose}
            >
                <Close color='disabled' />
            </IconButton>
            <Box sx={{ mt: 1 }}>
                <Formik
                    initialValues={initialFormValues}
                    validationSchema={validateFormSchema}
                    onSubmit={(values, { resetForm, setFieldError }) => {
                        handleConfirm(values, { resetForm, setFieldError })
                    }}
                >
                    {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => {
                        return (
                            <Box
                                component='form'
                                sx={{
                                    '& > :not(style)': { m: 1, width: '92%' },
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                                onSubmit={handleSubmit}
                            >
                                <TextFieldCustom
                                    label='Tên gói'
                                    name='name'
                                    color='secondary'
                                    value={values.name}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    error={!!touched.name && !!errors.name}
                                    helperText={touched.name && errors.name}
                                    // fullWidth
                                    className='box'
                                    widthField='92%'
                                />
                                <Box className='box' sx={styleBoxMobile}>
                                    <Typography>Số tháng:</Typography>
                                    {type === 'add' ? (
                                        <FormControl fullWidth error={!!touched.month && !!errors.month}>
                                            <Select
                                                name='month'
                                                color='secondary'
                                                value={values.month}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                            >
                                                {uniqueMonths.map((month) => (
                                                    <MenuItem key={month} value={month}>
                                                        {month} tháng
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {touched.month && errors.month ? <FormHelperText>{errors.month}</FormHelperText> : null}
                                        </FormControl>
                                    ) : (
                                        <TextField
                                            name='month'
                                            color='secondary'
                                            value={values.month}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            disabled
                                            fullWidth
                                        />
                                    )}
                                </Box>

                                <TextFieldCustom
                                    label='Giá tiền'
                                    name='price'
                                    color='secondary'
                                    value={formatNumber(values.price)}
                                    onChange={(e) => {
                                        const rawValue = parseNumber(e.target.value)
                                        setFieldValue('price', Number(rawValue))
                                    }}
                                    placeholder='Nhập giá tiền'
                                    onBlur={handleBlur}
                                    error={!!touched.price && !!errors.price}
                                    helperText={touched.price && errors.price}
                                    InputProps={{
                                        sx: {
                                            '& .MuiInputBase-input': {
                                                color: colors.greenLight[400],
                                                fontWeight: 600,
                                            },
                                        },
                                    }}
                                    fullWidth
                                    className='box'
                                    widthField='92%'
                                />
                                <Box mt='16px !important' display='flex' justifyContent='flex-end' sx={{width: { xs: '100% !important', sm: `92%!important` }}}>
                                    <Button
                                        type='submit'
                                        variant='contained'
                                        color='success'
                                        sx={{ minWidth: '96px', p: '8px 16px', mr: '10px', fontWeight: 600 }}
                                        endIcon={loading ? <CircularProgress size={25} /> : <Send />}
                                    >
                                        {loading ? null : 'Lưu'}
                                    </Button>
                                    <Button
                                        sx={{ minWidth: '96px', p: '8px 16px' }}
                                        variant='outlined'
                                        color='inherit'
                                        startIcon={<Cancel />}
                                        onClick={onClose}
                                    >
                                        Huỷ
                                    </Button>
                                </Box>
                            </Box>
                        )
                    }}
                </Formik>
            </Box>
        </ModalCustom>
    )
}

export default ModalPackage
