import { useState, useEffect, useCallback } from 'react'
import {
    Box,
    useTheme,
    IconButton,
    Tooltip,
    Button,
    Modal,
    Tabs,
    Tab,
    Typography,
    AppBar,
    ListItem,
    ListItemText,
    List,
    TextField,
    InputAdornment,
    Divider,
    debounce,
} from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { tokens } from '../../theme'
import Header from '../../components/Header'
import EditIcon from '@mui/icons-material/Edit'
import StyleDataGrid from '../../assets/styles/styleDataGrid'
import request from '../../utils/api/request'
import {
    delete_partner_idimages,
    get_company_partner,
    get_historypayment_by_userid,
    update_partner_balance_from_company,
    update_partner_info_from_company,
} from '../../utils/api/apiList'
import { Image, Space } from 'antd'
import images from '../../assets/images'
import ModalInfoPartner from '../../components/modal/ModalInfoPartner'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import ModalUploadPartner from '../../components/modal/modalUploadPartner'
import { message } from 'antd'
import { baseURL } from '../../utils/api/baseURL'
import { Close } from '@mui/icons-material'
import PropTypes from 'prop-types'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { formatNumber, phoneRegExp } from '../../components/prefixData'
import moment from 'moment'
import BoxNapRutCocTien from './boxNapRutCocTien/index.js'
import * as yup from 'yup'
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined'
import CustomNoRowsOverlay from '../../components/noRows/index.js'
import CustomLoadingComponent from '../../components/loading/index.js'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import ModalCreateEditUser from '../../components/modal/modalCreateEditUser/index.js'
import {
    DeleteOutlined,
    LeftOutlined,
    RotateLeftOutlined,
    RotateRightOutlined,
    SwapOutlined,
    ZoomInOutlined,
    ZoomOutOutlined,
} from '@ant-design/icons'
import { Modal as ModalAntd } from 'antd'

//settup tabs
function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div role='tabpanel' hidden={value !== index} id={`full-width-tabpanel-${index}`} aria-labelledby={`full-width-tab-${index}`} {...other}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
}

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    }
}

//validate box nap coc
const validateSchema = yup.object().shape({
    note: yup.string().required('nhập nội dung nạp tiền'),
    deposit: yup.number().typeError('Tiền nạp phải là số').required('nhập số tiền'),
})

//validate box đổi password
const passRegExp = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/
const validateSchemaChanePassword = yup.object().shape({
    fullName: yup.string().required('Không được để trống!'),
    phoneNumber: yup.string().matches(phoneRegExp, 'Số điện thoại không đúng').required('Không được để trống!'),
    newPassword: yup
        .string()
        .min(8, 'Mật khẩu mới tối thiểu là 8 ký tự bao gồm chữ và số')
        .matches(passRegExp, 'Mật khẩu tối thiểu là 8 ký tự bao gồm chữ và số')
        .required('Không được để trống!'),
    confirmNewPassword: yup
        .string()
        .oneOf([yup.ref('newPassword'), null], 'Mật khẩu mới không khớp!')
        .required('Không được để trống!'),
})

const Employee = () => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const [anchorEl, setAnchorEl] = useState(null)
    const [dataPartners, setDataPartners] = useState([])
    const [loading, setLoading] = useState(true)
    //companyId
    const companyId = localStorage.getItem('companyId')

    //change password
    const [loadingEdit, setLoadingEdit] = useState(null)
    const [currentRow, setCurrentRow] = useState({})
    const [anchorElChangePassword, setAnchorElChangePassword] = useState(null)
    const [initialValuesEditPartner, setInitialValuesEditPartner] = useState({
        fullName: '',
        phoneNumber: '',
        newPassword: '',
        confirmNewPassword: '',
        companyId,
    })

    // profile partner
    const [openBoxProfilePartner, setOpenBoxProfilePartner] = useState(false)
    const [dataPartner, setDataPartner] = useState({})

    //box nạp cọc
    const [anchorElDeposit, setAnchorElDeposit] = useState(null)
    //initialValues
    const [initialFormValues, setInitialFormValues] = useState({
        deposit: 0,
        note: '',
    })

    //tabs chung
    const [valueTab, setValueTab] = useState(0)
    const [allTransactions, setAllTransactions] = useState([])
    const [moneyIn, setMoneyIn] = useState([])
    const [moneyOut, setMoneyOut] = useState([])
    const [searchKeyword, setSearchKeyword] = useState('')
    const [filteredTransactions, setFilteredTransactions] = useState([])

    // pagination server side
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 25,
    })
    const [totalRows, setTotalRows] = useState(0)
    //search server side
    const [searchTerm, setSearchTerm] = useState('')
    const [inputText, setInputText] = useState('')
    //sort server
    const [queryOptions, setQueryOptions] = useState({ level: 1 })

    //notification
    const [messageApi, contextHolder] = message.useMessage()
    const openMessageNotification = (type, notification, eventName) => {
        const notificationColor = type === 'success' ? 'green' : 'red'

        const notificationStyle = {
            color: notificationColor,
            fontWeight: 'bold',
        }

        messageApi.open({
            type: type,
            content: (
                <span>
                    {type === 'success' ? (
                        <>
                            Cập nhật info nhân viên <span style={notificationStyle}>{notification}</span> thành công!
                        </>
                    ) : (
                        <>
                            Cập nhật info nhân viên <span style={notificationStyle}>{notification}</span> thất bại!
                        </>
                    )}
                </span>
            ),
        })
    }

    // Debounced
    const debouncedSetSearchTerm = useCallback(
        debounce((value) => {
            setSearchTerm(value)
        }, 500),
        []
    )

    // handle search
    const handleInputChange = (e) => {
        const value = e.target.value
        setInputText(value)
        debouncedSetSearchTerm(value)
    }

    // sort server
    const handleSortModelChange = useCallback((sortModel) => {
        if (sortModel.length > 0) {
            const sortField = sortModel[0].field
            const sortOrder = sortModel[0].sort === 'asc' ? 1 : -1
            setQueryOptions({ [sortField]: sortOrder })
        } else {
            setQueryOptions({ level: 1 })
        }
    }, [])

    //fetchdata
    const fetchData = () => {
        request
            .post(get_company_partner, {
                companyId,
                level: { $gte: 2 },
                page: paginationModel.page + 1,
                limit: paginationModel.pageSize,
                search: searchTerm,
                sort: queryOptions,
            })
            .then((res) => {
                const dataRs = res.data.data.docs
                const data = dataRs.map((elm, index) => {
                    return {
                        ...elm,
                        index: index + 1 + paginationModel.page * paginationModel.pageSize,
                    }
                })
                console.log(data)
                setDataPartners(data)
                setTotalRows(res.data.data.total)
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setLoading(false)
            })
    }

    //fetch update balance partner
    const fetchUpdateBalance = (data, resetForm) => {
        request
            .post(update_partner_balance_from_company, { ...data })
            .then((res) => {
                console.log(res)
                message.success('Nạp tiền thành công!')
                fetchData()
            })
            .catch((err) => {
                message.error('Lỗi: nạp tiền thất bại!')
                console.log('Lỗi nạp tiền', err)
            })
            .finally(() => {
                resetForm()
                setAnchorElDeposit(null)
            })
    }

    //fetch change pass
    const fetchChangePassword = (data, resetForm) => {
        request
            .post(update_partner_info_from_company, { ...data })
            .then((res) => {
                console.log(res)
                message.success(
                    <Typography>
                        Đổi mật khẩu tài khoản <span style={{ color: colors.greenLight[400] }}>{currentRow.fullName}</span> thành công!
                    </Typography>
                )
                fetchData()
            })
            .catch((err) => {
                message.error('Lỗi: cập nhật thất bại!')
            })
            .finally(() => {
                resetForm()
                setAnchorElChangePassword(null)
                setLoadingEdit(false)
            })
    }

    // xoá ảnh profile nhân viên
    const fetcDeleteImageProfilePartner = (data, row) => {
        request
            .post(delete_partner_idimages, { ...data })
            .then((res) => {
                console.log(res)
                fetchData()
                message.success(`Xoá ảnh hồ sơ nhân viên ${row.fullName} thành công!`)
            })
            .catch((err) => {
                message.error('Lỗi: xoá ảnh hồ sơ thất bại!')
                console.log('Lỗi xoá ảnh', err)
            })
    }

    //fetching
    useEffect(() => fetchData(), [paginationModel, searchTerm, queryOptions])

    //searching transaction
    useEffect(() => {
        let transactions = allTransactions
        if (valueTab === 1) transactions = moneyIn
        if (valueTab === 2) transactions = moneyOut

        if (searchKeyword) {
            const keyword = searchKeyword.toLowerCase()
            transactions = transactions?.filter((item) => {
                const amount = item.amount.toString().toLowerCase()
                return (
                    item._id.toLowerCase().includes(keyword) ||
                    new Date(item.createdAt).toLocaleString().toLowerCase().includes(keyword) ||
                    amount.includes(keyword)
                )
            })
        }

        setFilteredTransactions(transactions)
    }, [searchKeyword, valueTab, allTransactions, moneyIn, moneyOut])

    // column
    const columns = [
        { field: 'index', headerName: 'STT', width: 20, sortable: false },
        // { field: '_id', headerName: 'ID', flex: 0.5, minWidth: 200 },
        {
            field: 'fullName',
            headerName: 'Tên',
            flex: 0.5,
            minWidth: 200,
            cellClassName: 'name-column--cell',
            sortable: false,
        },
        {
            field: 'phoneNumber',
            headerName: 'Số điện thoại',
            flex: 0.5,
            minWidth: 120,
            sortable: false,
        },
        {
            field: 'idImages',
            headerName: 'Ảnh hồ sơ',
            flex: 0.7,
            minWidth: 200,
            sortable: false,
            renderCell: ({ row }) => {
                const imagesArr = row.idImages
                const maxVisibleImages = 3

                return imagesArr.length > 0 ? (
                    <Image.PreviewGroup
                        display='flex'
                        alignItems='center'
                        preview={{
                            toolbarRender: (
                                _,
                                {
                                    transform: { scale },
                                    actions: { onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn, onReset },
                                    image,
                                }
                            ) => (
                                <Space size={36} className='toolbar-wrapper'>
                                    <SwapOutlined rotate={90} onClick={onFlipY} style={{ fontSize: '24px' }} />
                                    <SwapOutlined onClick={onFlipX} style={{ fontSize: '24px' }} />
                                    <RotateLeftOutlined onClick={onRotateLeft} style={{ fontSize: '24px' }} />
                                    <RotateRightOutlined onClick={onRotateRight} style={{ fontSize: '24px' }} />
                                    <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} style={{ fontSize: '24px' }} />
                                    <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} style={{ fontSize: '24px' }} />
                                    <DeleteOutlined
                                        onClick={() => {
                                            ModalAntd.confirm({
                                                title: 'Xóa ảnh này?',
                                                content: 'Bạn có chắc chắn muốn xóa ảnh này không?',
                                                okText: 'Xóa',
                                                cancelText: 'Hủy',
                                                onOk: () => {
                                                    handleDeleteImageProfile(image, row._id, row)
                                                    onReset()
                                                },
                                            })
                                        }}
                                        style={{ color: 'red', fontSize: '24px' }}
                                    />{' '}
                                </Space>
                            ),
                        }}
                    >
                        {imagesArr.slice(0, maxVisibleImages).map((imageUrl, index) => (
                            <Image key={index} src={baseURL + imageUrl} width={40} height={40} />
                        ))}
                        {imagesArr.length > maxVisibleImages && <span style={{ marginLeft: '4px' }}>+{imagesArr.length - maxVisibleImages}...</span>}
                        {/* Các ảnh ẩn vẫn được thêm vào Image.PreviewGroup để có thể xem khi click vào */}
                        {imagesArr.slice(maxVisibleImages).map((imageUrl, index) => (
                            <Image
                                key={maxVisibleImages + index}
                                src={baseURL + imageUrl}
                                style={{ display: 'none' }} // Ẩn các ảnh này trong cột
                            />
                        ))}
                    </Image.PreviewGroup>
                ) : (
                    <Image width={40} height={40} style={{ borderRadius: '50%' }} src={images.placeholderPerson} />
                )
            },
        },
        {
            field: 'balance',
            headerName: 'Số dư',
            minWidth: 120,
            flex: 0.5,
            sortable: false,
            renderCell: ({ row }) => {
                return <Typography fontSize='14px'>{formatNumber(row.balance)}</Typography>
            },
        },
        {
            field: 'paymentHistory',
            headerName: 'Biến động số dư',
            minWidth: 120,
            flex: 0.5,
            renderCell: ({ row }) => <BtnPaymentHistory row={row} />,
            sortable: false,
        },
        {
            field: 'level',
            headerName: 'Level',
            minWidth: 80,
            flex: 0.3,
        },
        {
            field: 'action',
            headerName: 'Hành động',
            flex: 0.8,
            minWidth: 200,
            sortable: false,
            cellClassName: 'action-column',
            renderCell: ({ row }) => (
                <Box display='flex' alignItems='center' gap={1}>
                    <Tooltip
                        title='Đổi mật khẩu'
                        arrow
                        slotProps={{
                            popper: {
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, -5],
                                        },
                                    },
                                ],
                            },
                        }}
                    >
                        <IconButton color='secondary' onClick={(e) => handleOpenBoxChangePassword(e, row)}>
                            <LockOpenIcon />
                        </IconButton>
                    </Tooltip>
                    <IconButton onClick={() => handleOpenBoxProfilePartner(row)} color='secondary' aria-label='edit'>
                        <EditIcon />
                    </IconButton>
                    <Tooltip
                        title='Upload'
                        arrow
                        slotProps={{
                            popper: {
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, -5],
                                        },
                                    },
                                ],
                            },
                        }}
                    >
                        <IconButton color='secondary' onClick={(e) => handleOpenBoxUpload(row, e)}>
                            <FileUploadOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        title='Nạp cọc'
                        arrow
                        slotProps={{
                            popper: {
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, -5],
                                        },
                                    },
                                ],
                            },
                        }}
                    >
                        <IconButton color='secondary' onClick={(e) => handleOpenBoxDeposit(row, e)}>
                            <AccountBalanceWalletOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            ),
        },
    ]

    // xoá ảnh profile nhân viên
    const handleDeleteImageProfile = (imageUrl, userId, row) => {
        const imagePath = imageUrl.url.replace(baseURL, '')

        const data = {
            userId: userId,
            idImagesArr: [imagePath],
        }

        // Gọi API xóa ảnh
        fetcDeleteImageProfilePartner(data, row)
    }

    //box đổi pass
    const handleOpenBoxChangePassword = (e, row) => {
        setCurrentRow(row)
        setAnchorElChangePassword(e.currentTarget)
        setInitialValuesEditPartner({
            ...initialValuesEditPartner,
            fullName: row.fullName,
            phoneNumber: row.phoneNumber,
        })
    }
    const handleCloseBoxChangePassword = () => {
        setAnchorElChangePassword(null)
    }
    const handleChangePassword = (values, { resetForm }) => {
        console.log('values change passs', values)
        const data = {
            _id: currentRow._id,
            password: values.newPassword,
            phoneNumber: values.phoneNumber,
        }
        setLoadingEdit(true)

        const timer = setTimeout(() => {
            fetchChangePassword(data, resetForm)
        }, 500)

        return () => clearTimeout(timer)
    }

    //box nạp cọc
    const handleOpenBoxDeposit = (row, e) => {
        setAnchorElDeposit(e.currentTarget)
        setDataPartner(row)
    }

    const handleCloseBoxDeposit = () => {
        setAnchorElDeposit(null)
    }

    const handleCreateDeposit = (values, { resetForm }) => {
        console.log('values', values)
        const data = {
            _id: dataPartner._id,
            deposit: values.deposit,
            note: values.note,
        }

        fetchUpdateBalance(data, resetForm)
    }

    // box payment history
    const [anchorElPaymentHistory, setAnchorElPaymentHistory] = useState(null)
    const BtnPaymentHistory = ({ row }) => (
        <Button color='info' onClick={(e) => handleOpenBoxPaymentHistory(e, row)}>
            xem
        </Button>
    )
    const handleOpenBoxPaymentHistory = (e, row) => {
        console.log(row)
        setAnchorElPaymentHistory(e.currentTarget)
        fetchDataPaymentHistory(row)
    }
    const handleCloseBoxHistoryPayment = () => {
        setAnchorElPaymentHistory(false)
        setAllTransactions([])
        setMoneyIn(null)
        setMoneyOut(null)
        setAllTransactions(null)
    }
    const fetchDataPaymentHistory = (row) => {
        request
            .post(get_historypayment_by_userid, { userId: row?._id })
            .then((res) => {
                const data = res.data.data
                console.log(data)
                if (data.length > 0) {
                    setAllTransactions(data)
                    setMoneyIn(data.filter((item) => item.amount > 0))
                    setMoneyOut(data.filter((item) => item.amount < 0))
                }
            })
            .catch((err) => {
                console.error(res)
            })
    }
    const handleChangeTab = (event, newValue) => {
        console.log(newValue)
        setValueTab(newValue)
    }
    const renderTransactions = (transactions) => (
        <List sx={{ overflow: 'auto', maxHeight: '400px' }}>
            {transactions?.map((transaction) => {
                const amount = Number(transaction.amount)
                let styled
                amount > 0 ? (styled = { color: colors.greenAccent[400] }) : (styled = { color: 'red' })

                return (
                    <>
                        <ListItem
                            key={transaction._id}
                            sx={{
                                display: 'flex',
                            }}
                        >
                            <ListItemText
                                sx={{ flex: '0.4' }}
                                primary={
                                    <p>
                                        Số tiền: <span style={styled}>{formatNumber(transaction.amount)} VNĐ</span>
                                    </p>
                                }
                                secondary={[<p>Ngày: {moment(transaction.createdAt).format('MM-DD-YYYY, HH:MM')}</p>]}
                            />
                            <ListItemText
                                sx={{ flex: '0.6' }}
                                primary={`History code: ${transaction._id}`}
                                secondary={[<p>Ghi chú: {transaction.note}</p>]}
                            />
                        </ListItem>
                        <Divider />
                    </>
                )
            })}
        </List>
    )

    //upload file
    const handleOpenBoxUpload = (row, e) => {
        setAnchorEl(e.currentTarget)
        setDataPartner(row)
    }

    const handleCloseBoxUpload = () => {
        setAnchorEl(!anchorEl)
    }

    //open box profile partner
    const handleOpenBoxProfilePartner = (row) => {
        console.log(row)
        setDataPartner(row)
        setOpenBoxProfilePartner(true)
    }

    const handleCloseBoxPartnerProfile = () => {
        setOpenBoxProfilePartner(false)
    }

    const handleSubmitProfilePartner = (dataModalInfoPartner) => {
        const data = {
            ...dataModalInfoPartner,
            registrationDate: dataPartner?.registerDate,
            balance: Number(dataModalInfoPartner.balance),
            _id: dataPartner?._id,
        }
        // console.log(dataPartner)

        request
            .post(update_partner_info_from_company, { data })
            .then((res) => {
                fetchData()
                openMessageNotification('success', dataPartner?.fullName)
            })
            .catch((err) => console.log(err))
    }

    return (
        <Box
            m='20px'
            sx={{
                '& .MuiTypography-root.MuiTypography-h2': {
                    margin: '0 0 5px 0',
                },
                '& .ant-image': { mr: '5px' },
                '& .ant-image-img': { borderRadius: '5px' },
            }}
        >
            {contextHolder}
            <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Header title='NHÂN VIÊN' subtitle='Trang quản lý nhân viên' />
            </Box>
            <Box height='75vh' m='40px 0 0 0' sx={StyleDataGrid()}>
                <DataGrid
                    getRowId={(row) => row._id}
                    rows={dataPartners}
                    rowCount={totalRows}
                    columns={columns}
                    //pagination server side
                    pagination
                    initialState={{
                        pagination: { paginationModel },
                        density: 'comfortable',
                    }}
                    pageSizeOptions={[10, 25, 50, 100]}
                    paginationMode='server'
                    onPaginationModelChange={setPaginationModel}
                    //pagination server side

                    // sort server
                    sortingMode='server'
                    onSortModelChange={handleSortModelChange}
                    loading={loading}
                    slots={{
                        toolbar: GridToolbar,
                        noRowsOverlay: CustomNoRowsOverlay,
                        loadingOverlay: CustomLoadingComponent,
                    }}
                    localeText={{
                        toolbarDensity: 'Size',
                        toolbarDensityLabel: 'Size',
                        toolbarDensityCompact: 'Small',
                        toolbarDensityStandard: 'Medium',
                        toolbarDensityComfortable: 'Large',
                    }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: {
                                onChange: handleInputChange,
                                value: inputText,
                                placeholder: 'Tìm kiếm theo tên/sdt',
                            },
                        },
                    }}
                />
            </Box>
            {openBoxProfilePartner && (
                <ModalInfoPartner
                    modalFrom='partner'
                    open={openBoxProfilePartner}
                    onClose={handleCloseBoxPartnerProfile}
                    dataPartner={dataPartner}
                    onSave={handleSubmitProfilePartner}
                    fetchData={fetchData}
                />
            )}
            <ModalUploadPartner
                modalFrom='partner'
                open={Boolean(anchorEl)}
                onClose={handleCloseBoxUpload}
                data={dataPartner}
                fetchData={fetchData}
            />
            {anchorElPaymentHistory && (
                <Modal open={Boolean(anchorElPaymentHistory)} onClose={handleCloseBoxHistoryPayment}>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '600px',
                            maxHeight: '600px',
                            minHeight: '60%',
                            bgcolor: theme.palette.mode === 'dark' ? colors.primary[400] : 'white',
                            boxShadow: 24,
                            p: 4,
                            border: '1px solid',
                            borderRadius: '4px',
                            '& .MuiInputBase-input': {
                                pl: '10px',
                                pr: '10px',
                            },
                        }}
                    >
                        <IconButton
                            sx={{
                                position: 'absolute',
                                top: '5px',
                                right: '5px',
                            }}
                            onClick={handleCloseBoxHistoryPayment}
                        >
                            <Close color='disabled' />
                        </IconButton>
                        <Box
                            sx={{
                                typography: 'body1',
                                '& .MuiPaper-root': { backgroundColor: 'inherit' },
                                '& .MuiInputBase-root.Mui-focused fieldset': {
                                    borderWidth: '1px',
                                    borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.63)' : 'rgba(0, 0, 0, 0.63)',
                                },
                                '& .MuiFormLabel-root.Mui-focused': {
                                    color: colors.grey[100],
                                },
                            }}
                        >
                            <AppBar position='static'>
                                <Tabs
                                    value={valueTab}
                                    onChange={handleChangeTab}
                                    indicatorColor='secondary'
                                    textColor='inherit'
                                    variant='fullWidth'
                                    aria-label='full width tabs example'
                                    sx={{
                                        '& .MuiTab-root': {
                                            color: colors.grey[100],
                                            fontWeight: 600,
                                        },
                                    }}
                                >
                                    <Tab label='Tất cả' {...a11yProps(0)} />
                                    <Tab label='Tiền vào' {...a11yProps(1)} />
                                    <Tab label='Tiền ra' {...a11yProps(2)} />
                                </Tabs>
                            </AppBar>
                            <Box sx={{ p: 2 }}>
                                <TextField
                                    label='Tìm kiếm theo ngày'
                                    variant='outlined'
                                    fullWidth
                                    value={searchKeyword}
                                    onChange={(e) => setSearchKeyword(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <IconButton>
                                                    <SearchOutlinedIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Box>
                            <TabPanel value={valueTab} index={0}>
                                {renderTransactions(filteredTransactions)}
                            </TabPanel>
                            <TabPanel value={valueTab} index={1}>
                                {renderTransactions(filteredTransactions)}
                            </TabPanel>
                            <TabPanel value={valueTab} index={2}>
                                {renderTransactions(filteredTransactions)}
                            </TabPanel>
                        </Box>
                    </Box>
                </Modal>
            )}

            {/* box nạp cọc */}
            <BoxNapRutCocTien
                open={Boolean(anchorElDeposit)}
                onClose={handleCloseBoxDeposit}
                dataPartner={dataPartner}
                initialFormValues={initialFormValues}
                validateSchema={validateSchema}
                handleConfirm={handleCreateDeposit}
            />

            {/* change password partner */}
            <ModalCreateEditUser
                type='edit'
                open={Boolean(anchorElChangePassword)}
                onClose={handleCloseBoxChangePassword}
                validateSchema={validateSchemaChanePassword}
                initialValues={initialValuesEditPartner}
                loading={loadingEdit}
                handleConfirm={handleChangePassword}
            />
        </Box>
    )
}

export default Employee
