import BookingRevenue from './BookingRevenue'
import { revenue_spray_by_month_by_year_admin } from '../../../../../utils/api/apiList'

const SprayBookingRevenue = () => {
    return (
        <BookingRevenue
            title='Phun khử khuẩn'
            subtitle='Trang thống kê doanh thu phun khử khuẩn'
            apiEndpoint={revenue_spray_by_month_by_year_admin}
            nameChart='Spray'
        />
    )
}

export default SprayBookingRevenue
