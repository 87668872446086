import { createSelector } from '@reduxjs/toolkit'

export const usersDataSelector = (state) => state.usersData.users
export const infoUserSelector = (state) => state.taskBooking.userInfo
export const employeeDataSelector = (state) => state.getEmployeeData.data
export const getEventNewOrderSelector = (state) => state.getEventNewOrder.eventName
export const getEventUploadAvatarSelector = (state) => state.getEventUploadAvatar.data
export const getEventUpdateCleanSelector = (state) => state.getEventUpdateClean.data
export const getEventUpdateTaskBookingSelector = (state) => state.getEventUpdateTaskBooking.data
export const getEventUpdatePermanentTaskSelector = (state) => state.getEventUpdatePermanentTask.data
export const getEventAdminCreateTaskSelector = (state) => state.getEventAdminCreateTask.data
export const getEventAdminCreateCleanSelector = (state) => state.getEventAdminCreateClean.data
export const getEventAdminCreatePermanentSelector = (state) => state.getEventAdminCreatePermanent.data
export const getEventUpdateProfileAdminSelector = (state) => state.getEventUpdateProfileAdmin.data
export const getEventAddPartnerPermanentSelector = (state) => state.getEventAddPartnerPermanent.data
