import { Box, Button, IconButton, Modal, TextField, Typography, useTheme } from '@mui/material'
import { tokens } from '../../../theme'
import { Cancel, Close, Send } from '@mui/icons-material'
import { message } from 'antd'
import { Formik } from 'formik'

const parseNumber = (value) => {
    if (!value) return value
    return value.toString().replace(/,/g, '')
}

const formatNumber = (value) => {
    if (!value) return value
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

function BoxEdit({ open, onClose, initialValues, validationSchema, handleConfirmEdit }) {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    //message
    const [messageApi, messageContextHolder] = message.useMessage()

    //handleClose
    const handleClose = () => {
        onClose()
    }

    const styleModal = {
        borderRadius: '5px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 700,
        maxHeight: '90vh',
        bgcolor: theme.palette.mode === 'dark' ? '#323d58' : '#e0e0e0',
        border: '1px solid rgba(0, 0, 0, 0.1) 0px 4px 12px',
        boxShadow: 24,
        p: 4,
    }
    const styleBox = {
        '& .MuiBox-root': {
            display: 'flex',
            alignItems: 'center',
            gap: '20px',
            width: '80%',
            '& .MuiTextField-root': {
                flex: 1,
            },
        },
        '& .MuiTypography-root': {
            minWidth: '120px',
        },
        '& .MuiBox-root.select': {
            width: '100%',
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px',
        // maxHeight: '700px',
        maxHeight: '70vh',
        overflowY: 'auto',
        mt: '20px',
        boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
        pb: '20px',
        pt: '50px',
        backgroundColor: theme.palette.mode === 'dark' ? '#142043' : '#e7e7e7',
        borderRadius: '5px',
        '& .Mui-focused fieldset': {
            borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.6) !important' : 'rgba(0, 0, 0, 0.8)', // Màu border khi focused
            borderWidth: theme.palette.mode === 'dark' ? '2px' : '1px !important',
        },
    }

    return (
        <Modal
            open={open}
            onClose={(event, reason) => {
                if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
                    return
                }
                handleClose()
            }}
            style={{ backdropFilter: 'blur(5px)' }}
        >
            <Box sx={styleModal}>
                {messageContextHolder}
                <Box display='flex' justifyContent='space-between' alignItems='center'>
                    <Typography variant='h5' color={colors.grey[100]}>
                        SỬA BẢNG GHI
                    </Typography>
                </Box>
                <IconButton
                    sx={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                    }}
                    onClick={onClose}
                >
                    <Close color='disabled' />
                </IconButton>
                <Box mt='10px'>
                    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleConfirmEdit}>
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue, setFieldError }) => {
                            return (
                                <Box component='form' onSubmit={handleSubmit} sx={styleBox}>
                                    <Box display='flex' alignItems='center'>
                                        <Typography variant='h5' fontWeight='600' minWidth='110px'>
                                            Tên dịch vụ:
                                        </Typography>
                                        <TextField fullWidth name='name' value={values.name} disabled />
                                    </Box>
                                    <Box display='flex' alignItems='center'>
                                        <Typography variant='h5' fontWeight='600' minWidth='110px'>
                                            Mô tả:
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            name='description'
                                            value={values.description}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={!!touched.description && !!errors.description}
                                            helperText={touched.description && errors.description}
                                        />
                                    </Box>
                                    <Box display='flex' alignItems='center'>
                                        <Typography variant='h5' fontWeight='600' minWidth='110px'>
                                            Giá tiền:
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            name='price'
                                            value={formatNumber(values.price)}
                                            onChange={(e) => {
                                                const rawValue = parseNumber(e.target.value)
                                                setFieldValue('price', Number(rawValue))
                                            }}
                                            onBlur={handleBlur}
                                            error={!!touched.price && !!errors.price}
                                            helperText={touched.price && errors.price}
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    color: colors.greenLight[400],
                                                },
                                                fontWeight: 600,
                                            }}
                                        />
                                    </Box>
                                    <Box justifyContent='end'>
                                        <Button
                                            sx={{ minWidth: '96px', p: '8px 16px' }}
                                            type='submit'
                                            variant='contained'
                                            color='success'
                                            endIcon={<Send />}
                                        >
                                            Gửi
                                        </Button>
                                        <Button
                                            sx={{ minWidth: '96px', p: '8px 16px' }}
                                            variant='outlined'
                                            color='inherit'
                                            startIcon={<Cancel />}
                                            onClick={handleClose}
                                        >
                                            Huỷ
                                        </Button>
                                    </Box>
                                </Box>
                            )
                        }}
                    </Formik>
                </Box>
            </Box>
        </Modal>
    )
}

export default BoxEdit
