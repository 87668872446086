import { Box, Button,Grid, IconButton, Typography, useTheme } from '@mui/material'
import { tokens } from '../../../theme'
import { mockTransactions } from '../../../data/mockData'
import Header from '../../../components/Header'
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined'
import TimerOffOutlinedIcon from '@mui/icons-material/TimerOffOutlined'
import MoreTimeOutlinedIcon from '@mui/icons-material/MoreTimeOutlined'
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined'
import DomainAddOutlinedIcon from '@mui/icons-material/DomainAddOutlined'
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined'
import BarChart from '../../../components/BarChart'
import ProgressCircle from '../../../components/ProgressCircle'
import StatBox from '../../../components/StatBox'
import Pie from '../../../components/PieChart'

function AdminDashboard() {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    const dataRow1 = [
        {
            title: '361',
            subtitle: 'Đăng kí mới hôm nay',
            progress: '0.75',
            increase: '+14%',
            icon: <DomainAddOutlinedIcon sx={{ color: colors.greenAccent[600], fontSize: '36px' }} />,
        },
        {
            title: '125',
            subtitle: 'Hết hạn sử dụng',
            progress: '0.5',
            increase: (
                <Typography fontSize='16px' lineHeight='unset' color={colors.redAccent[500]}>
                    +21%
                </Typography>
            ),
            icon: <TimerOffOutlinedIcon sx={{ color: colors.greenAccent[600], fontSize: '36px' }} />,
        },
        {
            title: '41',
            subtitle: 'Tái gia hạn gói',
            progress: '0.30',
            increase: '+5%',
            icon: <MoreTimeOutlinedIcon sx={{ color: colors.greenAccent[600], fontSize: '36px' }} />,
        },
        {
            title: '5,134',
            subtitle: 'Tổng số lượng cty',
            progress: '0.80',
            increase: '+43%',
            icon: <BusinessOutlinedIcon sx={{ color: colors.greenAccent[600], fontSize: '36px' }} />,
        },
    ]

    return (
        <Box
            m='20px'
            sx={{
                '& .MuiTypography-root.MuiTypography-h2': {
                    margin: '0 0 5px 0',
                },
            }}
        >
            {/* HEADER */}
            <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Header title='TRANG CHỦ' subtitle='Chào mừng đến với trang tổng quản lý Eclean' />

                <Box
                    sx={{
                        '& .css-q34qwa-MuiButtonBase-root-MuiButton-root:hover': {
                            bgcolor: colors.primary[400],
                        },
                        '& .css-3mc8sk-MuiButtonBase-root-MuiButton-root:hover': {
                            bgcolor: 'rgba(4, 5, 9, 0.04)',
                            color: 'black',
                        },
                    }}
                >
                    {/* <Button
                        sx={{
                            backgroundColor: colors.blueAccent[700],
                            color: 'white',
                            fontSize: '14px',
                            fontWeight: 'bold',
                            padding: '10px 20px',
                        }}
                    >
                        <DownloadOutlinedIcon sx={{ mr: '10px' }} />
                        TẢI BÁO CÁO
                    </Button> */}
                </Box>
            </Box>

            {/* GRID & CHARTS */}
            {/* ROW 1 */}
            <Grid container spacing={2} marginTop='30px'>
                {dataRow1?.map((data, i) => (
                    <Grid item xs={12} sm={6} md={3} key={i}>
                        <Box bgcolor={colors.primary[400]} display='flex' alignItems='center' justifyContent='center' height='140px' >
                            <StatBox 
                                title={data.title} 
                                subtitle={data.subtitle} 
                                increase={data.increase} 
                                icon={data.icon} 
                                progress={data.progress} 
                            />
                        </Box>
                    </Grid>
                ))}
            </Grid>

            <Box
                display='grid'
                // gridTemplateColumns='repeat(12, 1fr)'
                gridTemplateColumns={{
                    xs: 'repeat(1, 1fr)', // 1 column on extra-small screens
                    sm: 'repeat(6, 1fr)', // 6 columns on small screens
                    md: 'repeat(8, 1fr)', // 8 columns on medium screens
                    lg: 'repeat(12, 1fr)', // 12 columns on large screens
                  }}
                gridAutoRows='140px'
                gap='20px'
                marginTop='30px'
                sx={{
                    '& .css-1wo04ma, .css-1vl72ks, .css-fxrul4, .css-3zn8qu, .css-1w5x3pn': {
                        // backgroundColor: '#fff', rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px
                        boxShadow:
                            // 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px',
                            'rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px',
                    },
                    '& .css-59jqr3-MuiSvgIcon-root,.css-1yqlyn3-MuiSvgIcon-root': {
                        fontSize: '28px',
                    },
                }}
            >
                {/* ROW 2 */}
                <Box gridColumn={{ xs: 'span 12', md: 'span 8' }} gridRow='span 3' bgcolor={colors.primary[400]}>
                    <Box mt='25px' p='0 30px' display='flex' justifyContent='space-between' alignItems='center'>
                        <Box display='flex' flexDirection='column'>
                            <Typography variant='h5' fontWeight='600' color={colors.grey[100]}>
                                Thống Kê Doanh Thu
                            </Typography>
                            <Typography variant='h3' fontWeight='700' color={colors.greenAccent[500]}>
                                $59,342.32
                            </Typography>
                        </Box>
                        <Box>
                            <IconButton>
                                <DownloadOutlinedIcon
                                    sx={{
                                        fontSize: '26px',
                                        color: colors.greenAccent[500],
                                    }}
                                />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box height='88%' mt='-20px'>
                        {/* <BarChart isDashboard={true} /> */}
                    </Box>
                </Box>

                {/* TRANSACTIONS */}
                <Box gridColumn={{ xs: 'span 12', md: 'span 4' }} gridRow='span 5' bgcolor={colors.primary[400]} overflow='auto'>
                    <Box
                        display='flex'
                        justifyContent='space-between'
                        alignItems='center'
                        borderBottom={`4px solid ${colors.primary[500]}`}
                        color={colors.grey[100]}
                        p='15px'
                    >
                        <Typography variant='h5' fontWeight='600' color={colors.grey[100]}>
                            Đơn Đặt Hàng
                        </Typography>
                    </Box>
                    {mockTransactions?.map((transaction, i) => {
                        return (
                            <Box
                                key={`${transaction.txId}-${i}`}
                                display='flex'
                                justifyContent='space-between'
                                alignItems='center'
                                borderBottom={`4px solid ${colors.primary[500]}`}
                                p='15px'
                            >
                                <Box>
                                    <Typography variant='h5' fontWeight='600' color={colors.greenAccent[500]}>
                                        {transaction.txId}
                                    </Typography>
                                    <Typography color={colors.grey[100]}>{transaction.user}</Typography>
                                </Box>
                                <Box color={colors.grey[100]}>{transaction.date}</Box>
                                <Box bgcolor={colors.greenAccent[500]} padding='5px 10px' borderRadius='4px'>
                                    ${transaction.cost}
                                </Box>
                            </Box>
                        )
                    })}
                </Box>

                {/* ROW 3 */}
                <Box gridColumn={{ xs: 'span 12', sm: 'span 6', md: 'span 4' }} gridRow='span 2' bgcolor={colors.primary[400]} p='30px'>
                    <Typography variant='h5' fontWeight='600' color={colors.grey[100]}>
                        Doanh thu theo ngày
                    </Typography>
                    <Box display='flex' flexDirection='column' alignItems='center' mt='25px'>
                        <ProgressCircle size='125' />
                        <Typography variant='h5' color={colors.greenAccent[500]} sx={{ mt: '15px' }}>
                            $48,352 revenue generated
                        </Typography>
                        <Typography color={colors.grey[100]}>Includes extra misc expenditures and costs</Typography>
                    </Box>
                </Box>

                {/*  */}
                <Box gridColumn={{ xs: 'span 12', sm: 'span 6', md: 'span 4' }} gridRow='span 2' bgcolor={colors.primary[400]}>
                    <Typography variant='h5' fontWeight='600' color={colors.grey[100]} sx={{ p: '30px 30px 0 30px' }}>
                        Người lao động
                    </Typography>
                    <Box height='250px' mt='-20px'>
                        {/* <GeographyChart /> */}
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default AdminDashboard
