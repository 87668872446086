import BookingRevenue from './BookingRevenue'
import { revenue_permanent_task_by_month_by_year_admin } from '../../../../../utils/api/apiList'

const PermanentBookingRevenue = () => {
    return (
        <BookingRevenue
            title='CA CỐ ĐỊNH'
            subtitle='Trang thống kê doanh thu ca cố định'
            apiEndpoint={revenue_permanent_task_by_month_by_year_admin}
            nameChart='Permanent'
        />
    )
}

export default PermanentBookingRevenue
