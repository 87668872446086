import { profit_air_by_month_by_year_admin } from '../../../../../utils/api/apiList'
import BookingProfit from './BookingProfit'

const AirProfit = () => {
    return (
        <BookingProfit
            title='ĐIỀU HOÀ'
            subtitle='Trang thống kê lợi nhuận điều hoà'
            apiEndpoint={profit_air_by_month_by_year_admin}
            nameChart='Air'
        />
    )
}

export default AirProfit
