import { Avatar, Box, IconButton, Tooltip, Typography, useTheme } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import Header from '../../../components/Header'
import { tokens } from '../../../theme'
import { useEffect, useMemo, useState } from 'react'
import { getRowClassName, formatNumber, processData } from '../../../components/prefixData'
import CustomNoRowsOverlay from '../../../components/noRows'
import CustomLoadingComponent from '../../../components/loading'
import StyleDataGrid from '../../../assets/styles/styleDataGrid'
import { useLocation } from 'react-router-dom'
import request from '../../../utils/api/request'
import {
    add_tasker_for_permanent_taskbooking,
    add_tasker_for_taskbooking,
    find_tasker_doing_permanent_task,
    find_tasker_doing_task,
    get_taskbooking_from_permanent_taskbookings,
} from '../../../utils/api/apiList'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import moment from 'moment'
import { baseURL } from '../../../utils/api/baseURL'
import minutesToHours from '../../../algorithm/minutesToHours'
import PortraitIcon from '@mui/icons-material/Portrait'
import PopoverBoxAsignEmployee from '../../../components/boxAsignEmployee'
import connectSocket from '../../../utils/api/connectSocket'
import { admin_change_partner, admin_update_task, admin_update_task_for_partner } from '../../../utils/api/apiSocket'
import openMessageNotification from '../../../components/notification/message'
import { message } from 'antd'

function DetailsPermanentTask() {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [dataSwitchPartner, setDataSwitchPartner] = useState(null)
    const [currentRow, setCurrentRow] = useState(null)
    const [anchorEl, setAnchorEl] = useState(null)
    const [dataPartnerForTask, setDataPartnerForTask] = useState(null)
    const [dataRowsAndColumns, setDataRowsAndColumns] = useState({
        rows: [],
        columns: [],
    })
    const [pageSize, setPageSize] = useState(10)

    //socket
    const [socket, setSocket] = useState(null)

    let location = useLocation()
    const { row } = location.state || {}

    //message
    const [messageApi, messageContextHolder] = message.useMessage()

    // filter local ///////////////////////////////////////////////////////////
    // filter column - filter trực tiếp trên columns
    const VISIBLE_FIELDS = ['index', '_id', 'dateRoot', 'fullName', 'phoneNumber', 'cancelReason', 'status']

    //colums
    const columns = [
        {
            field: 'index',
            headerName: 'STT',
            width: 100,
            sortable: false,
        },
        {
            field: '_id',
            headerName: 'ID đơn hàng',
            minWidth: 200,
            flex: 1,
            sortable: false,
        },
        {
            field: 'dateRoot',
            headerName: 'Thời gian',
            minWidth: 150,
            flex: 1,
            renderCell: ({ row }) => {
                const startTime = minutesToHours(row?.time)
                const endTime = minutesToHours(row?.taskId?.estimateTime + row?.time)

                return (
                    <Box>
                        <Typography color={theme.palette.mode === 'dark' ? colors.greenAccent[400] : '#1939e3'} fontSize='14px'>
                            {startTime} - {endTime}
                        </Typography>
                        <Typography fontSize='14px'>{row.date}</Typography>
                    </Box>
                )
            },
        },
        {
            field: 'fullName',
            headerName: 'Nhân viên',
            minWidth: 150,
            flex: 1,
            renderCell: ({ row }) => (
                <Box display='flex' gap='5px' alignItems='center'>
                    <Avatar src={baseURL + row?.taskerId?.avatar} alt='avatar partner'></Avatar>

                    <Box>
                        <Typography fontSize='14px'>{row?.taskerId?.fullName}</Typography>
                        <Typography fontSize='14px'>{row?.taskerId?.phoneNumber}</Typography>
                    </Box>
                </Box>
            ),
            sortable: false,
        },
        {
            field: 'phoneNumber',
            headerName: 'Phone Number',
            // hideable: false,
            sortable: false,
        },
        {
            field: 'cancelReason',
            headerName: 'Lý do huỷ',
            minWidth: 150,
            flex: 1,
            renderCell: ({ row }) => <Typography fontSize='14px'>{row.statusRoot === 2 ? '' : row.cancelReason}</Typography>,
            sortable: false,
        },
        {
            field: 'status',
            headerName: 'Trạng thái đơn',
            width: 150,
            minWidth: 100,
            flex: 1,
        },
        {
            field: 'action',
            headerName: 'Actions',
            cellClassName: 'action-column',
            minWidth: 200,
            flex: 0.5,
            sortable: false,
            renderCell: ({ row }) => <ActionRender row={row} />,
        },
    ]

    //actions render
    const ActionRender = ({ row }) => {
        return (
            <Box display='flex' alignItems='center' justifyContent='center' gap={1}>
                {row.statusRoot === 0 ? (
                    <Tooltip
                        title='gán nhân viên'
                        slotProps={{
                            popper: {
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, -10],
                                        },
                                    },
                                ],
                            },
                        }}
                    >
                        <IconButton
                            onClick={(e) => handleOpenBoxAsignEmployee(row, e)}
                            color='secondary'
                            aria-label='edit'
                            sx={{
                                '& .MuiSvgIcon-root': {
                                    color: theme.palette.mode === 'dark' ? 'inherit' : '#fff',
                                },
                            }}
                        >
                            <PortraitIcon />
                        </IconButton>
                    </Tooltip>
                ) : row.statusRoot === 1 || row.statusRoot === 3 ? (
                    <Tooltip
                        title='đổi nhân viên'
                        slotProps={{
                            popper: {
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, -10],
                                        },
                                    },
                                ],
                            },
                        }}
                    >
                        <IconButton
                            onClick={(e) => handleChangePartner(row, e, 'switchPartner')}
                            color={[3, 4].includes(row.statusRoot) ? 'info' : 'warning'}
                            aria-label='edit'
                        >
                            <PortraitIcon />
                        </IconButton>
                    </Tooltip>
                ) : null}
            </Box>
        )
    }

    //fetching
    useEffect(() => {
        fetchData()

        const socketInstance = connectSocket()
        setSocket(socketInstance)

        socketInstance.on('connect', () => {
            console.log('Socket is connected')
        })

        return () => {
            socketInstance.disconnect()
        }
    }, [])

    const fetchData = () => {
        request
            .post(get_taskbooking_from_permanent_taskbookings, { permanentTaskBookingId: row._id })
            .then((res) => {
                let dataRes = processData(res.data.data, 0, 0)

                // Sort the dataRes array by the date field in ascending order
                dataRes = dataRes.sort((a, b) => new Date(a.dateRoot) - new Date(b.dateRoot))

                const dataConvert = dataRes.map((elm, index) => {
                    return {
                        ...elm,
                        index: index + 1,
                        fullName: elm?.taskerId?.fullName,
                        phoneNumber: elm?.taskerId?.phoneNumber,
                    }
                })

                // Set the sorted and converted data
                setDataRowsAndColumns({ rows: dataConvert, columns })
                setLoading(false)
            })
            .catch((err) => {
                console.log(err)
                setLoading(false)
            })
    }

    //fetchDataForTask
    const fetchDataPartnerForTask = (data) => {
        request
            .post(find_tasker_doing_task, { ...data })
            .then((res) => {
                setDataPartnerForTask(res.data.data.isFree)
                // dispatch(taskBookingSlice.actions.getData(res.data.data.isFree))
            })
            .catch((err) => console.log(err))
    }

    //fetch add partner
    const fetchAddPartner = (data) => {
        request
            .post(add_tasker_for_taskbooking, { ...data })
            .then((res) => {
                console.log('addPartner', res)
                // dispatch(getEventAddPartnerPermanentSlice.actions.eventAddPartnerPermanent(res.data.data.taskId))

                const taskerId = res.data.data.taskerId
                const oldTaskerId = currentRow?.taskerId?._id
                const _id = currentRow?._id

                // admin update task to user (notification user)
                socket.emit(admin_update_task, {
                    _id,
                    userId: currentRow.userId, //id của user
                    note: 'ATri test gán nhân viên ',
                    type: 'taskBooking',
                })

                // admin update task to partner (notification partner)
                socket.emit(admin_update_task_for_partner, {
                    _id,
                    userId: taskerId, //id của partner
                    note: 'ATri test gán nhân viên ->> thong bao nay gianh cho partner',
                    type: 'taskBooking',
                })

                //đổi nhân viên
                if (dataSwitchPartner) {
                    socket.emit(admin_change_partner, {
                        _id,
                        userId: oldTaskerId, //id cua partner cũ
                        note: 'Change partner socket',
                        type: 'taskBooking',
                    })
                }

                // notification page
                if (dataSwitchPartner) {
                    openMessageNotification(messageApi, 'success', 'switchPartner', data?.fullName)
                    setDataSwitchPartner(null)
                } else {
                    openMessageNotification(messageApi, 'success', 'asignPartner', data?.fullName)
                }

                fetchData()
            })
            .catch((err) => {
                console.log(err)
                message.error('Lỗi: gán nhân viên thất bại!')
            })
    }

    // columns has filter
    const columnsHasFilter = useMemo(
        () => dataRowsAndColumns?.columns?.filter((column) => VISIBLE_FIELDS.includes(column.field)),
        [dataRowsAndColumns?.columns]
    )

    //change partner
    const handleChangePartner = (row, event, switchPartner) => {
        handleOpenBoxAsignEmployee(row, event)
        setDataSwitchPartner(switchPartner)
    }

    // box gán nhân viên
    const handleOpenBoxAsignEmployee = (row, event) => {
        let date, time, partnerServiceType, data
        date = row.dateRoot
        time = row.time
        partnerServiceType = 0 //0:task, 1: clean
        data = { date, time, partnerServiceType }

        fetchDataPartnerForTask(data)
        setAnchorEl(event.currentTarget)
        setCurrentRow(row)
    }
    const handleCloseBoxAsignEmployee = () => {
        setAnchorEl(false)
        setDataSwitchPartner('')
    }

    //pagination
    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize)
    }

    return (
        <Box
            m='20px'
            sx={{
                '& .MuiTypography-root.MuiTypography-h2': {
                    margin: '0 0 5px 0',
                },
            }}
        >
            {messageContextHolder}
            <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Header title='QUẢN LÝ TÁCH ĐƠN CA CỐ ĐỊNH' subtitle='Trang quản lý đơn hàng ca cố định được tách đơn' />
                <Box>
                    <Box display='flex'>
                        <Typography mr='5px' fontWeight='600'>
                            Mã đơn:
                        </Typography>
                        <Typography fontWeight='600' color={colors.greenAccent[400]}>
                            {row._id}
                        </Typography>
                    </Box>
                    <Box display='flex'>
                        <Typography mr='5px' fontWeight='600'>
                            Tên khách:
                        </Typography>
                        <Typography fontWeight='600' color={colors.greenAccent[400]}>
                            {row.taskId.name}
                        </Typography>
                    </Box>
                    <Box display='flex'>
                        <Typography mr='5px' fontWeight='600'>
                            Địa chỉ:
                        </Typography>
                        <Typography fontWeight='600' color={colors.greenAccent[400]}>
                            {row.address}
                        </Typography>
                    </Box>
                    <Box display='flex'>
                        <Typography mr='5px' fontWeight='600'>
                            Số tiền:
                        </Typography>
                        <Typography fontWeight='600' color={colors.greenAccent[400]}>
                            {formatNumber(row.price)} VNĐ
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box
                height='75vh'
                m='40px 0 0 0'
                sx={{
                    ...StyleDataGrid(),
                    // '& p': { fontWeight: 600 },
                    // '& .MuiDataGrid-row div': { fontWeight: 600 },
                }}
            >
                <DataGrid
                    getRowHeight={() => 'auto'}
                    getRowId={(row) => row.index}
                    rows={dataRowsAndColumns.rows}
                    columns={columnsHasFilter}
                    pageSize={pageSize}
                    initialState={{
                        pagination: { paginationModel: { pageSize: 10 } },
                        columns: {
                            columnVisibilityModel: {
                                phoneNumber: false, //ẩn cột
                            },
                        },
                        filter: {
                            filterModel: {
                                items: [],
                                quickFilterExcludeHiddenColumns: false, //cho phép filter các cột ẩn
                            },
                        },
                    }}
                    pagination
                    pageSizeOptions={[10, 25, 50, 100]}
                    loading={loading}
                    slots={{
                        toolbar: GridToolbar,
                        noRowsOverlay: CustomNoRowsOverlay,
                        loadingOverlay: CustomLoadingComponent,
                    }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                        },
                    }}
                    onPageSizeChange={handlePageSizeChange}
                    getRowClassName={(params) => getRowClassName(params, data._id)}
                    sx={{
                        '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
                        '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
                        '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
                    }}
                />
            </Box>

            {anchorEl && (
                <PopoverBoxAsignEmployee
                    boxName={'permanentTaskSlice'}
                    open={Boolean(anchorEl)}
                    onClose={handleCloseBoxAsignEmployee}
                    dataPartnerForTask={dataPartnerForTask}
                    currentRow={currentRow}
                    setCurrentRow={setCurrentRow}
                    fetchAddPartner={fetchAddPartner}
                />
            )}
        </Box>
    )
}

export default DetailsPermanentTask
