import BookingRevenue from './BookingRevenue'
import { revenue_air_by_month_by_year_admin } from '../../../../../utils/api/apiList'

const AirBookingRevenue = () => {
    return (
        <BookingRevenue
            title='Điều hoà'
            subtitle='Trang thống kê doanh thu điều hoà'
            apiEndpoint={revenue_air_by_month_by_year_admin}
            nameChart='Air'
        />
    )
}

export default AirBookingRevenue
