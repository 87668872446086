import { useState, useEffect, useMemo, useCallback } from 'react'
import { Box, Typography, useTheme, IconButton, Tooltip, Button, Chip, debounce } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { tokens } from '../../../theme'
import Header from '../../../components/Header'
import PortraitIcon from '@mui/icons-material/Portrait'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import StyleDataGrid from '../../../assets/styles/styleDataGrid'
import request from '../../../utils/api/request'
import {
    admin_cancel_task as admin_cancel_task_api,
    add_tasker_for_taskbooking,
    find_tasker_doing_task,
    get_taskbooking_by_id,
    get_taskbookings,
    get_taskbookings2,
} from '../../../utils/api/apiList'
import CustomNoRowsOverlay from '../../../components/noRows'
import CustomLoadingComponent from '../../../components/loading'
import ModalDetails from '../../../components/modal/modalDetails'
import { processData, getRowClassName, formatNumber } from '../../../components/prefixData'
import { useSelector } from 'react-redux'
import { getEventAdminCreateTaskSelector, getEventNewOrderSelector, getEventUpdateTaskBookingSelector } from '../../../redux/selectors'
import PopoverBoxAsignEmployee from '../../../components/boxAsignEmployee'
import { admin_cancel_task, admin_change_partner, admin_update_task, admin_update_task_for_partner } from '../../../utils/api/apiSocket'
import connectSocket from '../../../utils/api/connectSocket'
import { message } from 'antd'
import openMessageNotification from '../../../components/notification/message'
import PopoverComponent from '../../../components/popover'
import ModalCreateAllTask from '../../../components/modal/modalCreateAllTask'
import { Add } from '@mui/icons-material'
import { useLocation } from 'react-router-dom'
import minutesToHours from '../../../algorithm/minutesToHours'

const TaskBooking = () => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const [dataTaskBooking, setDataTaskBooking] = useState([])
    const [loading, setLoading] = useState(true)
    const [dataPartnerForTask, setDataPartnerForTask] = useState([])
    const [anchorEl, setAnchorEl] = useState(null)
    const [detailsAnchorEl, setDetailsAnchorEl] = useState(null)
    const [cancelAnchorEl, setCancelAnchorEl] = useState(null)
    const [currentRow, setCurrentRow] = useState({})
    const [dataDetails, setDataDetails] = useState({})
    const [idCancel, setIdDelete] = useState(null)
    const [dataSwitchPartner, setDataSwitchPartner] = useState(null)
    const [anchorElBoxCreateTask, setAnchorElBoxCreateTask] = useState(null)
    const token = localStorage.getItem('token')

    //
    const location = useLocation()
    const { infoWork } = location.state || {}
    const _id = infoWork?.data?._id

    // pagination server side
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 25,
    })
    const [totalRows, setTotalRows] = useState(0)
    //search server side
    const [searchTerm, setSearchTerm] = useState('')
    const [inputText, setInputText] = useState('')

    //sort server
    const [queryOptions, setQueryOptions] = useState({ createdAt: -1 })

    // dispartch
    const eventNewOrder = useSelector(getEventNewOrderSelector)
    // const eventUpdateTask = useSelector(getEventUpdateTaskBookingSelector)
    const eventAdminCreakTask = useSelector(getEventAdminCreateTaskSelector)

    //socket
    const [socket, setSocket] = useState(null)

    //message
    const [messageApi, messageContextHolder] = message.useMessage()

    // Debounced
    const debouncedSetSearchTerm = useCallback(
        debounce((value) => {
            setSearchTerm(value)
        }, 500),
        []
    )

    // sort server
    const handleSortModelChange = useCallback((sortModel) => {
        if (sortModel.length > 0) {
            const sortField = sortModel[0].field
            const sortOrder = sortModel[0].sort === 'asc' ? 1 : -1
            setQueryOptions({ [sortField]: sortOrder })
        } else {
            setQueryOptions({ createdAt: -1 })
        }
    }, [])

    //fectching
    useEffect(() => {
        fetchData()

        const socketInstance = connectSocket()
        setSocket(socketInstance)

        socketInstance.on('connect', () => {
            console.log('Socket is connected')
        })

        return () => {
            socketInstance.disconnect()
        }
    }, [eventNewOrder, eventAdminCreakTask, paginationModel, searchTerm, queryOptions])

    //fetch data
    const fetchData = () => {
        setLoading(true)

        request
            .post(get_taskbookings2, {
                page: paginationModel.page + 1,
                limit: paginationModel.pageSize,
                search: searchTerm,
                sort: queryOptions,
                fromPermanentTaskBooking: false,
            })
            .then((res) => {
                //lọc đơn cố định
                // const filteredDocs = res.data.data.docs.filter((elm) => !elm.hasOwnProperty('fromPermanentTaskBooking'))
                // console.log(filteredDocs)

                let dataRs = processData(res.data.data.docs, paginationModel.page, paginationModel.pageSize)
                // const convertDataRs = dataRs.map((elm) => {
                //     let name, _id
                //     const isPermanentTask = elm.hasOwnProperty('fromPermanentTaskBooking')
                //     if (isPermanentTask) {
                //         name = 'Ca cố định'
                //         _id = elm.fromPermanentTaskBooking._id
                //     } else {
                //         name = 'Ca lẻ'
                //         // _id = elm._id
                //     }

                //     return {
                //         ...elm,
                //         name,
                //         // _id,
                //     }
                // })

                // Sắp xếp theo ngày tạo
                // dataRs = dataRs.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

                const convertDataRs = dataRs.map((elm) => {
                    return {
                        ...elm,
                        partnerName: elm?.tasker?.fullName ?? '',
                        userName: elm?.user?.fullName ?? elm?.userFromAdminOrder?.name,
                    }
                })

                console.log(convertDataRs)

                setDataTaskBooking(convertDataRs)
                setTotalRows(res.data.data.total)
                setLoading(false)
            })
            .catch((err) => {
                console.log(err)
                setLoading(false)
            })
    }

    const fetchDataPartnerForTask = (data) => {
        request.setAuthToken(token)
        request
            .post(find_tasker_doing_task, { ...data })
            .then((res) => {
                setDataPartnerForTask(res.data.data.isFree)
                // dispatch(taskBookingSlice.actions.getData(res.data.data.isFree))
            })
            .catch((err) => console.log(err))
    }
    const fetchAddPartner = (data) => {
        request
            .post(add_tasker_for_taskbooking, { ...data })
            .then((res) => {
                const taskerId = res.data.data.taskerId
                const oldTaskerId = currentRow?.taskerId?._id
                const _id = currentRow?._id
                console.log('fetchAddPartner', res.data.data)

                // admin update task to user (notification user)
                socket.emit(admin_update_task, {
                    _id,
                    userId: currentRow.userId, //id của user
                    note: 'Gán nhiên viên ca lẻ -> thông báo này giành cho user',
                    type: 'taskBooking',
                })

                // admin update task to partner (notification partner)
                socket.emit(admin_update_task_for_partner, {
                    _id,
                    userId: taskerId, //id của partner
                    note: 'Gán nhân viên ca lẻ ->> thông báo này giành cho partner',
                    type: 'taskBooking',
                })

                //đổi nhân viên
                if (dataSwitchPartner) {
                    socket.emit(admin_change_partner, {
                        _id,
                        userId: oldTaskerId, //id cua partner cũ
                        note: 'Change partner socket',
                        type: 'taskBooking',
                    })
                }

                // notification page
                if (dataSwitchPartner) {
                    openMessageNotification(messageApi, 'success', 'switchPartner', data?.fullName)
                    setDataSwitchPartner(null)
                } else {
                    openMessageNotification(messageApi, 'success', 'asignPartner', data?.fullName)
                }

                fetchData()
            })
            .catch((err) => {
                console.log(err)
                openMessageNotification('error')
            })
    }
    const fetchInfoUser = (_id) => {
        request
            .post(get_taskbooking_by_id, { _id })
            .then((res) => {
                setDataDetails(res.data.data)
            })
            .catch((err) => console.log(err))
    }

    // handle search
    const handleInputChange = (e) => {
        const value = e.target.value
        setInputText(value)
        debouncedSetSearchTerm(value)
    }

    //column
    const columns = useMemo(
        () => [
            { field: 'index', headerName: 'STT', width: 50, sortable: false },
            // { field: 'name', headerName: 'Tên đơn', width: 100 },
            { field: '_id', headerName: 'Mã đơn hàng', minWidth: 200, sortable: false },
            {
                field: 'userName',
                headerName: 'Tên khách hàng',
                width: 150,
                sortable: false,
            },
            {
                field: 'partnerName',
                headerName: 'Tên người làm',
                width: 150,
                sortable: false,
            },
            {
                field: 'price',
                headerName: 'Giá đơn hàng',
                width: 120,
                cellClassName: 'cost-column name-column--cell',
                renderCell: (params) => {
                    return (
                        <Typography fontSize='14px' fontWeight={600}>
                            {formatNumber(params.row.price)} VNĐ
                        </Typography>
                    )
                },
                sortable: false,
            },
            {
                field: 'date',
                headerName: 'Thời gian',
                width: 100,
                renderCell: ({ row }) => {
                    const startTime = minutesToHours(row.time)
                    const endTimeMinutes = row?.taskId?.estimateTime + row.time
                    const endTime = minutesToHours(endTimeMinutes)

                    return (
                        <Box>
                            <Typography
                                className='colorTime'
                                // color={theme.palette.mode === 'dark' ? colors.greenAccent[400] : '#1939e3'}
                                fontSize='14px'
                                fontWeight={600}
                            >
                                {startTime} - {endTime}
                            </Typography>
                            <Typography fontSize='14px'>{row.date}</Typography>
                        </Box>
                    )
                },
            },
            {
                field: 'address',
                headerName: 'Địa điểm',
                flex: 1,
                minWidth: 400,
                renderCell: ({ row }) => {
                    return (
                        <Tooltip title={<h1 style={{ fontSize: '11px' }}>{row?.taskId?.address}</h1>}>
                            <Typography fontSize='14px'>{row?.taskId?.address}</Typography>
                        </Tooltip>
                    )
                },
                sortable: false,
            },
            {
                field: 'status',
                headerName: 'Trạng thái đơn',
                width: 150,
                minWidth: 100,
                renderCell: ({ row }) => {
                    const getColor = (statusRoot) => {
                        switch (statusRoot) {
                            case 0:
                                return 'success'
                            case 2:
                                return 'info'
                            default:
                                return 'default'
                        }
                    }

                    return (
                        <Chip
                            key={row.status}
                            label={row.status}
                            color={getColor(row.statusRoot)}
                            sx={{
                                minWidth: '100px',
                            }}
                        />
                    )
                },
            },
            {
                field: 'action',
                headerName: 'Actions',
                cellClassName: 'action-column',
                width: 200,
                minWidth: 200,
                sortable: false,
                renderCell: ({ row }) => <ActionRender row={row} />,
            },
        ],
        [dataTaskBooking, dataPartnerForTask, dataDetails]
    )

    //action render
    const ActionRender = ({ row }) => {
        return (
            <Box display='flex' alignItems='center' justifyContent='center' gap={1}>
                {row.statusRoot === 0 ? (
                    <Tooltip
                        title='gán nhân viên'
                        slotProps={{
                            popper: {
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, -10],
                                        },
                                    },
                                ],
                            },
                        }}
                    >
                        <IconButton
                            onClick={(e) => handleOpenBoxAsignEmployee(row, e)}
                            color='secondary'
                            aria-label='edit'
                            className='colorIconAsign'
                        >
                            <PortraitIcon />
                        </IconButton>
                    </Tooltip>
                ) : row.statusRoot === 1 || row.statusRoot === 3 ? (
                    <Tooltip
                        title='đổi nhân viên'
                        slotProps={{
                            popper: {
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, -10],
                                        },
                                    },
                                ],
                            },
                        }}
                    >
                        <IconButton
                            onClick={(e) => handleChangePartner(row, e, 'switchPartner')}
                            color={[3, 4].includes(row.statusRoot) ? 'info' : 'warning'}
                            aria-label='edit'
                        >
                            <PortraitIcon />
                        </IconButton>
                    </Tooltip>
                ) : null}
                <Tooltip
                    title='chi tiết'
                    slotProps={{
                        popper: {
                            modifiers: [
                                {
                                    name: 'offset',
                                    options: {
                                        offset: [0, -10],
                                    },
                                },
                            ],
                        },
                    }}
                >
                    <IconButton onClick={(e) => handleOpenDetailsModal(row, e)}>
                        <MoreHorizIcon />
                    </IconButton>
                </Tooltip>
                {row.statusRoot === 0 || row.statusRoot === 1 || row.statusRoot === 4 ? (
                    <Tooltip
                        title='Huỷ đơn'
                        slotProps={{
                            popper: {
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, -10],
                                        },
                                    },
                                ],
                            },
                        }}
                    >
                        <IconButton onClick={(e) => handleOpenBoxCancelTask(row._id, e)} color='error'>
                            <HighlightOffIcon />
                        </IconButton>
                    </Tooltip>
                ) : (
                    <></>
                )}
            </Box>
        )
    }

    //cancel row
    const handleOpenBoxCancelTask = (id, e) => {
        setIdDelete(id)
        setCancelAnchorEl(e.currentTarget)
    }

    const handleCloseCancelTask = () => {
        setCancelAnchorEl(false)
    }

    const handleConfirmCancelTask = (note) => {
        setCancelAnchorEl(false)
        request
            .post(admin_cancel_task_api, { _id: idCancel, cancelReason: note })
            .then((res) => {
                openMessageNotification(messageApi, 'success', 'cancel')
                fetchData()
                const userId = res.data?.data?.userId
                const taskerId = res.data?.data?.taskerId
                const _id = res.data?.data?._id
                socket.emit(admin_cancel_task, {
                    cancelReason: note,
                    userId,
                    taskerId, // báo cho taskerId đã được gán.
                    _id,
                })

                console.log(res)
            })
            .catch((err) => openMessageNotification(messageApi, 'error', 'cancel'))
    }

    //modal details
    const handleOpenDetailsModal = (row, event) => {
        setCurrentRow(row)
        setDetailsAnchorEl(event.currentTarget)
        const _id = row._id
        fetchInfoUser(_id)
        // console.log(row)
    }

    // close modal details
    const handleCloseModalDetails = () => {
        setDetailsAnchorEl(false)
    }

    //change partner
    const handleChangePartner = (row, event, switchPartner) => {
        handleOpenBoxAsignEmployee(row, event)
        setDataSwitchPartner(switchPartner)
    }

    // box gán nhân viên
    const handleOpenBoxAsignEmployee = (row, event) => {
        let date, time, partnerServiceType, data
        date = row.dateRoot
        time = row.time
        partnerServiceType = 0
        data = { date, time, partnerServiceType }
        fetchDataPartnerForTask(data)
        setAnchorEl(event.currentTarget)
        setCurrentRow(row)
    }
    const handleCloseBoxAsignEmployee = () => {
        setAnchorEl(!anchorEl)
        setDataSwitchPartner('')
    }

    // tao don hang
    const handleOpenBoxCreateTask = (e) => {
        setAnchorElBoxCreateTask(e.currentTarget)
    }
    const handleCloseBoxCreateTask = () => {
        setAnchorElBoxCreateTask(false)
    }

    return (
        <Box
            m='20px'
            sx={{
                '& .MuiTypography-root.MuiTypography-h2': {
                    margin: '0 0 5px 0',
                },
                '& .colorTime': {
                    // color: 'red'
                    color: theme.palette.mode === 'dark' ? colors.greenAccent[400] : '#1939e3',
                },
                '& .colorIconAsign .MuiSvgIcon-root': {
                    color: theme.palette.mode === 'dark' ? 'inherit' : '#fff',
                },
            }}
        >
            {messageContextHolder}
            {/* {contextHolder} */}
            <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Header title='QUẢN LÝ ĐƠN HÀNG CA LẺ' subtitle='Trang quản lý đơn hàng ca lẻ' />
                <Button
                    sx={{
                        backgroundColor: colors.blueAccent[700],
                        color: 'white',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        padding: '10px 20px',
                        '&:hover': {
                            backgroundColor: colors.blueAccent[600],
                        },
                    }}
                    startIcon={<Add />}
                    onClick={(e) => handleOpenBoxCreateTask(e)}
                >
                    Tạo đơn hàng
                </Button>
            </Box>
            <Box height='75vh' m='40px 0 0 0' sx={StyleDataGrid()}>
                <DataGrid
                    getRowId={(row) => row._id}
                    rows={dataTaskBooking}
                    rowCount={totalRows}
                    columns={columns}
                    //pagination server side
                    initialState={{
                        pagination: { paginationModel },
                        density: 'comfortable',
                    }}
                    pagination
                    pageSizeOptions={[10, 25, 50, 100]}
                    paginationMode='server'
                    onPaginationModelChange={setPaginationModel}
                    //pagination server side

                    // sort server
                    sortingMode='server'
                    onSortModelChange={handleSortModelChange}
                    loading={loading}
                    slots={{
                        toolbar: GridToolbar,
                        noRowsOverlay: CustomNoRowsOverlay,
                        loadingOverlay: CustomLoadingComponent,
                    }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: {
                                onChange: handleInputChange,
                                value: inputText,
                                placeholder: 'Tìm kiếm theo địa chỉ',
                            },
                        },
                    }}
                    getRowClassName={(params) => getRowClassName(params, _id)}
                />
            </Box>
            {detailsAnchorEl && (
                <ModalDetails
                    modalName='taskBooking'
                    dataDetails={dataDetails}
                    open={Boolean(detailsAnchorEl)}
                    onClose={handleCloseModalDetails}
                    fetchData={fetchData}
                    fetchDataDetails={fetchInfoUser}
                />
            )}
            <PopoverBoxAsignEmployee
                boxName={'taskBooking'}
                open={Boolean(anchorEl)}
                onClose={handleCloseBoxAsignEmployee}
                dataPartnerForTask={dataPartnerForTask}
                currentRow={currentRow}
                setCurrentRow={setCurrentRow}
                fetchAddPartner={fetchAddPartner}
            />
            <PopoverComponent
                type='cancel'
                idRow={idCancel}
                anchorEl={cancelAnchorEl}
                onClose={handleCloseCancelTask}
                handleConfirm={handleConfirmCancelTask}
            />
            {anchorElBoxCreateTask && (
                <ModalCreateAllTask boxName='taskBooking' open={Boolean(anchorElBoxCreateTask)} onClose={handleCloseBoxCreateTask} />
            )}
        </Box>
    )
}

export default TaskBooking
