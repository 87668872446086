import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Row, Form, Input } from 'antd'
import { MdEmail, MdLock, MdArrowBack, MdPhone, MdKey } from 'react-icons/md'
import { Button } from '@mui/material'
import './style.scss'
import request, { requestLogin } from '../../utils/api/request'
import { login, admin_signin_by_phone, update_profile, is_company_phone_number } from '../../utils/api/apiList'
import { auth } from '../../firebase/setup'
import { signInWithPhoneNumber, RecaptchaVerifier } from 'firebase/auth'
import 'react-phone-input-2/lib/style.css'
import { BsFillShieldLockFill, BsTelephoneFill } from 'react-icons/bs'
import { SiKeepassxc } from 'react-icons/si'
import { CgSpinner } from 'react-icons/cg'
import OtpInput from 'otp-input-react'
import { message } from 'antd'
import { phoneRegExp } from '../../components/prefixData'

//style
const backIconStyle = {
    cursor: 'pointer',
    position: 'absolute',
    top: 16,
    left: 16,
    color: '#B0B0B0', // light gray color
    opacity: 0.5, // opacity for disabled effect
    transition: 'color 0.3s ease', // smooth transition for color change
}
const backIconHoverStyle = {
    color: '#808080', // darker gray color on hover
    opacity: 0.7, // slightly less transparent on hover
}

function Login() {
    const [errMess, setErrMess] = useState(false)
    const [errPhone, setErrPhone] = useState(false)
    const [errVerify, setErrVerify] = useState(false)
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const [formMissPass, setFormMissPass] = useState(false)
    const [openBoxOtp, setOpenBoxOtp] = useState(false)
    const [hover, setHover] = useState(false)
    const [openBoxChangePassword, setOpenBoxChangePassword] = useState(false)

    const [otp, setOtp] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [loading, setLoading] = useState(false)
    const [errorMessageVerify, setErrorMessageVerify] = useState(null)
    const [stateSignIn, setStateSignin] = useState(null)
    const [countdown, setCountdown] = useState(0)
    const [countdownOTP, setCountdownOTP] = useState(0)
    const [isActivePhone, setIsActivePhone] = useState(true)

    //form
    const formOTP = useRef(null)
    const formVerify = useRef(null)
    const formChangePass = useRef(null)

    //token
    const tokenStorage = localStorage.getItem('token')

    //mess
    const [messageApi, messageContextHolder] = message.useMessage()

    useEffect(() => {
        if (tokenStorage && tokenStorage.length > 0) {
            navigate('/')
        }
    }, [tokenStorage, navigate])

    //function
    const onFinish = (values) => {
        const { username, password } = values
        const data = {
            username,
            password,
        }

        requestLogin
            .post(login, data)
            .then((response) => {
                const token = response.data.data.token
                const role = response.data.data.user.role
                console.log('response', response.data)

                request.setAuthToken(token)
                if (role === 999) {
                    localStorage.setItem('role', 'admin')
                    localStorage.setItem('token', token)
                    window.location.href = '/'
                } else if (role === 100) {
                    localStorage.setItem('role', 'doanhnghiep')
                    localStorage.setItem('token', token)
                    localStorage.setItem('userId', response.data.data.user._id)
                    localStorage.setItem('companyId', response.data.data.user.fromCompany)
                    window.location.href = '/'
                } else {
                    setErrMess(true)
                }
            })
            .catch((error) => {
                setErrMess(true)
            })
    }

    const handleSubmit = () => {
        form.submit()
    }

    const handleMissPass = () => {
        setFormMissPass(true)
        setOpenBoxOtp(false)
    }

    const handleBack = () => {
        if (openBoxOtp) {
            setOpenBoxOtp(false)
        } else if (formMissPass) {
            setFormMissPass(false)
        }
    }

    //OTP
    function onCaptchVerify() {
        if (!window.recaptchaVerifier) {
            try {
                window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
                    size: 'invisible',
                    callback: (response) => {
                        // callback không cần thiết cho OTP, chỉ cần xác nhận reCAPTCHA
                    },
                    'expired-callback': () => {
                        console.log('reCAPTCHA expired')
                    },
                })
            } catch (err) {
                console.error('Error creating reCAPTCHA verifier:', err)
                setLoading(false)
                return
            }
        }
    }

    const handleSendOtp = () => {
        setLoading(true)
        onCaptchVerify()

        const appVerifier = window.recaptchaVerifier
        const formatPh = '+84' + phoneNumber
        console.log('handle send')

        //check phone
        request
            .post(is_company_phone_number, { phoneNumber })
            .then((res) => {
                setIsActivePhone(true)
                // send otp
                signInWithPhoneNumber(auth, formatPh, appVerifier)
                    .then((confirmationResult) => {
                        window.confirmationResult = confirmationResult
                        setLoading(false)
                        setOpenBoxOtp(true)
                        setCountdownOTP(30)
                        const countdownInterval = setInterval(() => {
                            setCountdownOTP((prevCountdown) => {
                                if (prevCountdown === 1) {
                                    clearInterval(countdownInterval)
                                    setRerequestOTP(true)
                                }
                                return prevCountdown - 1
                            })
                        }, 1000)
                        message.success('Gửi OTP thành công!')
                    })
                    .catch((error) => {
                        console.log('Error during signInWithPhoneNumber:', error)
                        setLoading(false)
                        setErrPhone(true)
                        setPhoneNumber(null)
                        message.error('Gửi OTP thất bại!')
                    })
            })
            .catch((err) => {
                console.log(err)
                message.warning('Số điện thoại chưa được đăng ký')
                setIsActivePhone(false)
                setLoading(false)
            })
    }

    const onFinishOTP = (values) => {
        const { phoneNumber } = values
        setPhoneNumber(phoneNumber)
        handleSendOtp()
    }

    //verify
    const [rerequestOTP, setRerequestOTP] = useState(false)
    const onOTPVerify = (otp) => {
        if (countdownOTP <= 0) {
            message.error('OTP đã hết hạn!')
            formVerify.current.resetFields()
            return
        }
        setLoading(true)
        window.confirmationResult
            .confirm(otp)
            .then(async (res) => {
                const phoneNumber = res.user.phoneNumber
                const formatPhone = 0 + phoneNumber.slice(3)
                setLoading(false)

                // Additional logic after successful OTP verification
                request
                    .post(admin_signin_by_phone, {
                        phoneNumber: formatPhone,
                    })
                    .then((res) => {
                        console.log(res)
                        message.success('xác thực thành công!')
                        setRerequestOTP(false)
                        setOpenBoxChangePassword(true)
                        setOpenBoxOtp(false)
                        setFormMissPass(false)
                        setPhoneNumber(formatPhone)
                        setStateSignin(res.data.data)
                    })
                    .catch((err) => {
                        console.log(err)
                        const messErr = err.response.data.data
                        setErrVerify(true)
                        switch (messErr) {
                            case 'UserNotFound':
                                message.error('số điện thoại chưa được đăng kí, vui lòng thử lại!')
                                setErrorMessageVerify('Số điện thoại chưa được đăng kí')
                                break
                            default:
                                message.error('có lỗi chưa xác định! vui lòng thử lại')
                                setErrorMessageVerify('có lỗi chưa xác định! vui lòng thử lại')
                                break
                        }
                    })
            })
            .catch((err) => {
                console.log(err)
                message.error('Mã OTP không đúng')
                setErrVerify(true)
                setErrorMessageVerify('Mã OTP không đúng, nhập lại!')
                setLoading(false)
                formVerify.current.resetFields()
            })
    }

    const onFinishVerify = () => {
        onOTPVerify(otp)
    }

    //change passs and login
    const onChangePassAndLogin = (values) => {
        formChangePass.current
            ?.validateFields()
            .then((values) => {
                const data = {
                    userInfo: {
                        password: values.newPassword,
                    },
                }
                const role = stateSignIn.user.role
                const token = stateSignIn.token

                request.setAuthToken(token)
                request
                    .post(update_profile, data)
                    .then((res) => {
                        console.log(res)
                        message.success('Đổi mật khẩu thành công, chuyển hướng đăng nhập sau 3s!')

                        let counter = 3
                        setCountdown(counter)
                        const interval = setInterval(() => {
                            counter -= 1
                            setCountdown(counter)
                            if (counter === 0) {
                                clearInterval(interval)
                                // Navigate or redirect
                                if (role === 999) {
                                    localStorage.setItem('role', 'admin')
                                    localStorage.setItem('token', token)
                                    window.location.href = '/'
                                } else if (role === 100) {
                                    localStorage.setItem('role', 'doanhnghiep')
                                    localStorage.setItem('token', token)
                                    localStorage.setItem('userId', stateSignIn.user._id)
                                    localStorage.setItem('companyId', stateSignIn.user.fromCompany)
                                    window.location.href = '/'
                                } else {
                                    setErrMess(true)
                                }
                            }
                        }, 1000)
                    })
                    .catch((err) => {
                        console.error(err)
                        console.log('Đổi mật khẩu thất bại')
                    })
            })
            .catch((e) => {
                console.error(e)
            })
    }

    const validateEmailOrPhone = (rule, value) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        const phonePattern = phoneRegExp

        if (!value) {
            return Promise.reject()
        }

        if (!emailPattern.test(value) && !phonePattern.test(value)) {
            return Promise.reject()
        }

        return Promise.resolve()
    }

    return (
        <div className='login-page layout bg-green'>
            <div id='recaptcha-container'></div>
            <Row justify='center' className='row-form row-login'>
                {messageContextHolder}
                {!formMissPass && !openBoxOtp && !openBoxChangePassword && (
                    <div className='col-form col-login m-auto'>
                        <div className='div-form-title'>
                            <p className='text-2xl font-medium text-center text-gray800'>Đăng Nhập</p>
                        </div>
                        <div>
                            {errMess && <div style={{ color: 'red' }}>Email/password is incorrect</div>}
                            <Form name='login' form={form} onFinish={onFinish}>
                                <Form.Item
                                    name='username'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Vui lòng nhập số điện thoại hoặc email!',
                                        },
                                        {
                                            validator: validateEmailOrPhone,
                                            message: 'Định dạng số điện thoại hoặc email không hợp lệ!',
                                        },
                                    ]}
                                >
                                    <Input placeholder='Số điện thoại hoặc email' prefix={<MdEmail size={20} />} />
                                </Form.Item>
                                <Form.Item
                                    name='password'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Vui lòng nhập mật khẩu!',
                                        },
                                        // {
                                        //     min: 8,
                                        //     message: 'Mật khẩu tối thiểu 8 ký tự!',
                                        // },
                                        // {
                                        //     pattern: /^(?=.*[a-zA-Z])(?=.*\d).{8,}$/,
                                        //     message: 'Mật khẩu phải bao gồm cả chữ cái và số!',
                                        // },
                                    ]}
                                >
                                    <Input.Password placeholder='Mật khẩu' prefix={<MdLock size={20} />} />
                                </Form.Item>
                                <div className='mb-4 font-13 text-right text-primary_g hover:text-medium'>
                                    <Button onClick={handleMissPass}>Quên mật khẩu</Button>
                                </div>
                                <Form.Item name='loginresult'>
                                    <Button fullWidth type='submit' variant='contained' className='form-btn' onClick={handleSubmit}>
                                        Đăng Nhập
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                )}
                {formMissPass && !openBoxOtp && (
                    <div className='col-form col-login m-auto'>
                        <div className='div-form-title'>
                            <MdArrowBack
                                size={24}
                                onClick={handleBack}
                                style={hover ? { ...backIconStyle, ...backIconHoverStyle } : backIconStyle}
                                onMouseEnter={() => setHover(true)}
                                onMouseLeave={() => setHover(false)}
                            />
                            <p className='text-2xl font-medium text-center text-gray800 mt-5'>Xác thực qua Số Điện Thoại</p>
                        </div>
                        <div>
                            {errPhone && <div style={{ color: 'red' }}>Có lỗi, vui lòng thử lại!</div>}
                            {!isActivePhone && <div style={{ color: 'orange' }}>Số điện thoại chưa được đăng ký!</div>}
                            <Form name='phone' ref={formOTP} onFinish={onFinishOTP}>
                                <Form.Item
                                    name='phoneNumber'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Vui lòng nhập số điện thoại!',
                                        },
                                        {
                                            pattern: /^[0-9]{10}$/,
                                            message: 'Số điện thoại phải có 10 chữ số!',
                                        },
                                    ]}
                                >
                                    <Input
                                        value={phoneNumber}
                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                        placeholder='Phone'
                                        prefix={<MdPhone size={20} />}
                                    />
                                </Form.Item>
                                <Form.Item name='sendotp'>
                                    <Button
                                        id='send-otp-button'
                                        fullWidth
                                        variant='contained'
                                        className='form-btn'
                                        type='submit'
                                        // onClick={onFinishOTP}
                                    >
                                        {loading && <CgSpinner size={20} style={{ marginRight: '5px' }} className='mt-1 animate-spin' />}
                                        Gửi OTP
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                )}
                {openBoxOtp && (
                    <div className='col-form col-login m-auto'>
                        <div>
                            <p className='text-2xl font-medium text-center text-gray800'>Nhập OTP</p>
                            <div className='bg-white text-emerald-500 w-fit mx-auto p-4 rounded-full'>
                                <BsFillShieldLockFill size={30} />
                            </div>
                        </div>
                        <div>
                            {errVerify && <div style={{ color: 'red' }}>{errorMessageVerify}</div>}
                            <Form name='otp' ref={formVerify} onFinish={onFinishVerify} style={{ display: 'flex', flexDirection: 'column' }}>
                                {countdownOTP > 0 && <p>OTP có hiệu lực trong: {countdownOTP} giây</p>}
                                {rerequestOTP && !countdownOTP && <Button onClick={handleSendOtp}>Gửi lại</Button>}
                                <Form.Item
                                    name='OTP'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Vui lòng nhập OTP!',
                                        },
                                        {
                                            validator: (_, value) => {
                                                if (value && value.length !== 6) {
                                                    return Promise.reject(new Error('OTP phải có 6 chữ số!'))
                                                }
                                                return Promise.resolve()
                                            },
                                        },
                                    ]}
                                >
                                    <OtpInput
                                        value={otp}
                                        onChange={(newValue) => {
                                            setOtp(newValue)
                                        }}
                                        OTPLength={6}
                                        otpType='number'
                                        disabled={false}
                                        autoFocus
                                        className='opt-container '
                                    ></OtpInput>
                                </Form.Item>
                                <Form.Item name='verifyotp'>
                                    <Button fullWidth variant='contained' className='form-btn' type='submit'>
                                        {loading && <CgSpinner size={20} className='mt-1 animate-spin' />}
                                        Xác Thực
                                    </Button>
                                </Form.Item>
                                <Button
                                    style={{ marginLeft: 'auto', color: '#6868af' }}
                                    onClick={() => {
                                        setFormMissPass(false)
                                        setOpenBoxOtp(false)
                                        setErrVerify(false)
                                        setErrPhone(false)
                                        setErrMess(false)
                                        setOtp(null)
                                        setPhoneNumber(null)
                                    }}
                                >
                                    về trang đăng nhập
                                </Button>
                            </Form>
                        </div>
                    </div>
                )}
                {openBoxChangePassword && (
                    <div className='col-form col-login m-auto'>
                        <div>
                            <p className='text-2xl font-medium text-center text-gray800'>Đổi mật khẩu mới</p>
                            <div className='bg-white text-emerald-500 w-fit mx-auto p-4 rounded-full'>
                                <SiKeepassxc size={30} />
                            </div>
                        </div>
                        <div>
                            {countdown > 0 && <p>Chuyển hướng sau: {countdown} giây</p>}
                            <Form
                                name='formChangePass'
                                ref={formChangePass}
                                style={{ display: 'flex', flexDirection: 'column' }}
                                onFinish={onChangePassAndLogin}
                            >
                                <Form.Item
                                    name='newPassword'
                                    // label='Mật khẩu mới'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Vui lòng nhập mật khẩu!',
                                        },
                                        {
                                            pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
                                            message: 'Mật khẩu tối thiểu là 8 ký tự bao gồm chữ và số!',
                                        },
                                    ]}
                                    hasFeedback
                                >
                                    <Input.Password placeholder='Mật khẩu mới' prefix={<MdLock size={20} />} />
                                </Form.Item>
                                <Form.Item
                                    name='verifyNewPassword'
                                    // label='xác thực mật khẩu'
                                    dependencies={['newPassword']}
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Vui lòng nhập mật khẩu!',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('newPassword') === value) {
                                                    return Promise.resolve()
                                                }
                                                return Promise.reject(new Error('Mật khẩu không trùng khớp!'))
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password placeholder='xác thực mật khẩu' prefix={<MdLock size={20} />} />
                                </Form.Item>
                                <Form.Item name='verifyotp'>
                                    <Button type='submit' fullWidth variant='contained' className='form-btn'>
                                        {loading && <CgSpinner size={20} className='mt-1 animate-spin' />}
                                        Đăng nhập
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                )}
            </Row>
        </div>
    )
}

export default Login
