import React, { useEffect, useState } from 'react'
import { Box, Typography, useTheme, IconButton, Button, TextField, Popover } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { tokens } from '../../theme'
import { pricingData } from '../../data/mockData'
import EditIcon from '@mui/icons-material/Edit'
import Header from '../../components/Header'
import StyleDataGrid from '../../assets/styles/styleDataGrid'
import { Add, Delete } from '@mui/icons-material'
import request from '../../utils/api/request'
import { create_package, delete_package, get_list_package, update_package } from '../../utils/api/apiList'
import { message } from 'antd'
import ModalPackage from './ModalPackage'
import * as yup from 'yup'
import { formatNumber } from '../../components/prefixData'
import BoxDelete from '../../components/popover'

const validateFormSchema = yup.object().shape({
    name: yup.string().required('tên khách hàng là bắt buộc'),
    price: yup.number().typeError('Giá tiền phải là số dương').min(0, 'Giá phải lớn hơn hoặc bằng 0').required('Yêu cầu nhập giá'),
})

const PriceList = () => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const [dataPackage, setDataPackage] = useState([])
    const [pageSize, setPageSize] = useState(10)
    const [anchorElAdd, setAnchorElAdd] = useState(false)
    const [loadingAdd, setLoadingAdd] = useState(false)
    const [uniqueMonths, setUniqueMonths] = useState([])
    const [anchorElDelete, setAnchorElDelete] = useState(null)
    const [currentRow, setCurrentRow] = useState({})
    const [anchorElEdit, setAnchorElEdit] = useState(null)
    const [loadingEdit, setLoadingEdit] = useState(false)

    const [initialFormAdd, setInitialFormAdd] = useState({
        name: '',
        month: 1,
        price: '',
    })

    const [initialFormEdit, setInitialFormEdit] = useState({
        name: '',
        month: Number,
        price: '',
    })

    //fetching
    useEffect(() => {
        fetchData()
    }, [])

    //fetchData
    const fetchData = () => {
        request
            .post(get_list_package)
            .then((res) => {
                console.log(res)
                const dataRs = res.data.data.docs.map((elm, index) => {
                    return {
                        ...elm,
                        index: index + 1,
                    }
                })
                setDataPackage(dataRs)
                const existingMonths = dataRs.map((elm) => elm.month)

                // months, tiền xử lý trước tránh tạo trùng tháng, và khởi tạo value mặc định cho select
                const months = Array.from({ length: 12 }, (_, index) => index + 1)
                const uniqueMonths = months.filter((elm) => !existingMonths.includes(elm))

                if (!uniqueMonths.includes(initialFormAdd.month)) {
                    setInitialFormAdd((prevState) => ({
                        ...prevState,
                        month: uniqueMonths[0],
                    }))
                }
                setUniqueMonths(uniqueMonths)
            })
            .catch((err) => {
                console.log(err)
                message.error('Cập nhật dữ liệu gói thất bại!')
            })
    }

    //fetch add
    const fetchAdd = (data, resetForm) => {
        request
            .post(create_package, { ...data })
            .then((res) => {
                console.log(res)
                fetchData()
                message.success('Thêm mới gói thành công!')
                resetForm()
                setAnchorElAdd(null)
            })
            .catch((err) => {
                console.log(err)
                message.error('Thêm mới dữ liệu gói thất bại!')
            })
            .finally(() => {
                setLoadingAdd(false)
            })
    }

    //fetch edit
    const fetchEdit = (data, resetForm) => {
        request
            .post(update_package, { ...data })
            .then((res) => {
                message.success('Cập nhật gói thành công!')
                resetForm()
                setAnchorElEdit(null)
                fetchData()
            })
            .catch((err) => {
                console.log('Lỗi update gói', err)
                message.error('Lỗi cập nhật gói!')
            })
            .finally(() => {
                setLoadingEdit(false)
            })
    }

    //fecth delete
    const fetchDelete = (_id) => {
        request
            .post(delete_package, { _id })
            .then((res) => {
                message.success('Xoá gói thành công!')
                fetchData()
            })
            .catch((err) => {
                message.error('Lỗi: xoá gói thất bại!')
            })
            .finally(() => {
                setAnchorElDelete(null)
            })
    }

    const columns = [
        {
            field: 'index',
            headerName: 'STT',
            width: 100,
        },
        {
            field: 'name',
            headerName: 'Tên gói',
            flex: 1,
            minWidth: 200,
            cellClassName: 'name-column--cell',
        },
        {
            field: 'month',
            headerName: 'Số tháng',
            flex: 1,
            minWidth: 100,
        },
        {
            field: 'price',
            headerName: 'Giá',
            minWidth: 150,
            flex: 1,
            renderCell: ({ row }) => formatNumber(row.price),
        },
        {
            field: 'action',
            headerName: 'Hành động',
            flex: 1,
            align: 'left',
            sortable: false,
            renderCell: ({ row }) => (
                <Box display='flex' alignItems='center' gap={1}>
                    <IconButton onClick={(e) => handleOpenEdit(e, row)} color='secondary' aria-label='edit'>
                        <EditIcon />
                    </IconButton>
                    <IconButton onClick={(e) => handleOpenBoxDelete(e, row)} color='error' aria-label='delete'>
                        <Delete />
                    </IconButton>
                </Box>
            ),
        },
    ]

    // Box add
    const handleOpenAdd = (e) => {
        setAnchorElAdd(e.currentTarget)
    }
    const handleCloseBoxAdd = () => {
        setAnchorElAdd(null)
    }
    const handleAdd = (values, { resetForm }) => {
        console.log('values add', values)

        setLoadingAdd(true)
        const timer = setTimeout(() => {
            fetchAdd(values, resetForm)
        }, 500)

        return () => clearTimeout(timer)
    }

    //Box edit
    const handleOpenEdit = (e, row) => {
        setAnchorElEdit(e.currentTarget)
        setCurrentRow(row)
        setInitialFormEdit({
            name: row.name,
            month: row.month,
            price: row.price,
        })
        console.log(row)
    }
    const handleCloseEdit = () => {
        setAnchorElEdit(null)
        setCurrentRow({})
    }
    const handleEdit = (values, { resetForm }) => {
        console.log('values edit', values)
        const data = {
            ...values,
            _id: currentRow._id,
        }

        setLoadingEdit(true)
        const timer = setTimeout(() => {
            fetchEdit(data, resetForm)
        }, 500)
        return () => clearTimeout(timer)
    }

    //Box delete
    const handleOpenBoxDelete = (e, row) => {
        setAnchorElDelete(e.currentTarget)
        setCurrentRow(row)
    }
    const handleCloseBoxDelete = () => {
        setAnchorElDelete(null)
    }
    const handleDelete = () => {
        fetchDelete(currentRow._id)
    }

    //pagination
    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize)
    }

    return (
        <Box
            m='20px'
            sx={{
                '& .MuiTypography-root.MuiTypography-h2': {
                    margin: '0 0 5px 0',
                },
            }}
        >
            <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Header title='QUẢN LÝ GÓI' subtitle='Trang quản lý gói' />
                <Button
                    sx={{
                        backgroundColor: colors.blueAccent[700],
                        background: theme.palette.mode === 'dark' ? '#3e4396' : 'linear-gradient(to right, #b993d6, #8ca6db)',
                        color: 'white',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        padding: '10px 20px',
                        '&:hover': {
                            background: theme.palette.mode === 'dark' ? '#3e4366' : 'linear-gradient(to right, #c8b7d5, #a6beee)',
                        },
                    }}
                    onClick={handleOpenAdd}
                >
                    <Add sx={{ mr: '10px' }} />
                    THÊM GÓI
                </Button>
            </Box>
            <Box height='75vh' m='40px 0 0 0' sx={StyleDataGrid()}>
                <DataGrid
                    getRowId={(row) => row._id}
                    rows={dataPackage}
                    columns={columns}
                    pageSize={pageSize}
                    rowsPerPageOptions={[5, 10, 20]}
                    pagination
                    components={{ Toolbar: GridToolbar }}
                    onPageSizeChange={handlePageSizeChange}
                />
            </Box>
            <ModalPackage
                open={Boolean(anchorElAdd)}
                onClose={handleCloseBoxAdd}
                loading={loadingAdd}
                type='add'
                uniqueMonths={uniqueMonths}
                initialFormValues={initialFormAdd}
                validateFormSchema={validateFormSchema}
                handleConfirm={handleAdd}
            />
            <ModalPackage
                open={Boolean(anchorElEdit)}
                onClose={handleCloseEdit}
                loading={loadingEdit}
                type='edit'
                uniqueMonths={uniqueMonths}
                initialFormValues={initialFormEdit}
                validateFormSchema={validateFormSchema}
                handleConfirm={handleEdit}
            />
            <BoxDelete
                type='delete'
                idRow={currentRow._id}
                anchorEl={anchorElDelete}
                onClose={handleCloseBoxDelete}
                handleConfirm={handleDelete}
                boxName='package'
            />
        </Box>
    )
}

export default PriceList
