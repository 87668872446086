import { useState, useEffect } from 'react'
import { Box, useTheme, Typography } from '@mui/material'
import Header from '../../../../../components/Header'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import moment from 'moment'
import 'moment/locale/vi'
import request from '../../../../../utils/api/request'
import { message } from 'antd'
import LineChart from '../../../../../components/LineChart'
import { formatNumber } from '../../../../../components/prefixData'
import { tokens } from '../../../../../theme'

const BookingRevenue = ({ title, subtitle, apiEndpoint, nameChart }) => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const [selectedYear, setSelectedYear] = useState(moment())
    const [dataRevenueConvert, setDataRevenueConvert] = useState([])
    const [dataTotalOrderConvert, setTotalOrderConvert] = useState([])
    const [totalRevenue, setTotalRevenue] = useState(0)
    const [totalOrder, setTotalOrder] = useState(0)

    useEffect(() => {
        const year = selectedYear.year()
        fetchDataChart({ year })
    }, [])

    const fetchDataChart = (data) => {
        request
            .post(apiEndpoint, { ...data })
            .then((res) => {
                const apiData = res.data.data
                convertChartData(apiData)
                console.log('apiData', apiData)
            })
            .catch((err) => {
                console.error(`Lỗi lấy dữ liệu chart doanh thu ${title}`, err)
                message.error('Lỗi: không thể cập nhật dữ liệu, vui lòng thử lại sau!')
            })
    }

    const handleChangeYear = (value) => {
        const year = moment(value).year()
        setSelectedYear(value)
        fetchDataChart({ year })
    }

    const convertChartData = (apiData) => {
        const revenueData = {
            id: 'Doanh thu',
            data: [],
        }

        const orderData = {
            id: 'Đơn hàng',
            data: [],
        }

        const allMonths = Array.from({ length: 12 }, (_, i) => i + 1)

        let totalRevenue = 0
        let totalOrder = 0

        allMonths.forEach((month) => {
            const monthData = apiData.find((item) => item._id === month)
            const monthLabel = `Tháng ${month}`

            if (monthData) {
                const revenue = monthData.totalRevenue
                const allOrder = monthData.totalOrder

                revenueData.data.push({ x: monthLabel, y: revenue / 1000000 })
                orderData.data.push({ x: monthLabel, y: allOrder })

                totalRevenue += revenue
                totalOrder += allOrder
            } else {
                revenueData.data.push({ x: monthLabel, y: 0 })
                orderData.data.push({ x: monthLabel, y: 0 })
            }
        })

        setDataRevenueConvert([revenueData])
        setTotalOrderConvert([orderData])
        setTotalRevenue(totalRevenue)
        setTotalOrder(totalOrder)
    }

    return (
        <Box m='20px' mb='50px'>
            <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Header title={title} subtitle={subtitle} />
            </Box>

            <Box height='75vh' m='40px 0 0 0'>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={moment.locale('vi')}>
                        <DatePicker label='Chọn năm' views={['year']} value={selectedYear} onChange={handleChangeYear} />
                    </LocalizationProvider>

                    {selectedYear && (
                        <Box>
                            <Box mr='150px' fontWeight={600} color={colors.grey[100]}>
                                TỔNG DOANH THU {title} TRONG NĂM {selectedYear.year()}:{' '}
                                <Typography fontWeight={700} display='inline-block' variant='h4' color={colors.greenAccent[500]}>
                                    {formatNumber(totalRevenue)} VNĐ
                                </Typography>
                            </Box>
                            <Box mr='150px' fontWeight={600} color={colors.grey[100]}>
                                TỔNG ĐƠN HÀNG {title} TRONG NĂM {selectedYear.year()}:{' '}
                                <Typography fontWeight={700} display='inline-block' variant='h4' color={colors.greenAccent[500]}>
                                    {formatNumber(totalOrder)}
                                </Typography>
                            </Box>
                        </Box>
                    )}
                </Box>

                {dataRevenueConvert.length > 0 && (
                    <LineChart dataChart={dataRevenueConvert} nameChart={`${nameChart}Revenue`} selectedDate={selectedYear} />
                )}
                {dataTotalOrderConvert.length > 0 && (
                    <LineChart dataChart={dataTotalOrderConvert} nameChart={`TotalOrder${nameChart}`} selectedDate={selectedYear} />
                )}
            </Box>
        </Box>
    )
}

export default BookingRevenue
