import { useState, useEffect } from 'react'
import { Box, useTheme, Typography, Button } from '@mui/material'
import { tokens } from '../../../../theme'
import Header from '../../../../components/Header'
import BarChart from '../../../../components/BarChart'
import { mockBarNewUser } from '../../../../data/mockData'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import moment from 'moment'
import 'moment/locale/vi'
import request from '../../../../utils/api/request'
import { message } from 'antd'
import { total_user_by_date_by_month, total_user_by_month_by_year } from '../../../../utils/api/apiList'

//
function getDaysInMonth(month, year) {
    return new Date(year, month, 0).getDate()
}

const AnalyzesUser = () => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    const [dataUser, setDataUser] = useState(null)
    const [selectedDate, setSelectedDate] = useState(moment())
    const [viewMode, setViewMode] = useState('year')
    const [totalUsers, setTotalUsers] = useState(null)

    // Fetch data year hoặc tháng
    const fetchData = (data, type) => {
        let dataPayload = {}
        let api

        if (type === 'year') {
            dataPayload = { year: data }
            api = total_user_by_month_by_year
        } else if (type === 'month') {
            dataPayload = { month: data + 1, year: selectedDate.year() }
            api = total_user_by_date_by_month
        }

        request
            .post(api, { ...dataPayload })
            .then((res) => {
                console.log('data user', res)
                if (type === 'year') {
                    const { filledData, totalUsers } = fillMissingMonths(res.data.data)
                    setDataUser(filledData)
                    setTotalUsers(totalUsers)
                    console.log(filledData)
                } else {
                    // setDataUser(res.data.data)
                    const filledData = fillMissingDays(res.data.data.totalUserByMonth, selectedDate.month() + 1, selectedDate.year())
                    console.log(filledData)

                    setDataUser(filledData)
                    setTotalUsers(res.data.data.totalUserCount)
                }
            })
            .catch((err) => {
                message.error('Lỗi dữ liệu, vui lòng liên hệ dev!')
                console.error('err', err)
            })
    }

    // Hàm bổ sung các tháng còn thiếu
    const fillMissingMonths = (data) => {
        const filledData = []
        let totalUsers = 0

        for (let i = 1; i <= 12; i++) {
            const monthData = data.find((item) => item._id === i)
            if (monthData) {
                filledData.push({ month: 'Tháng ' + i, 'Khách hàng': monthData.totalUser })
                totalUsers += monthData.totalUser
            } else {
                filledData.push({ month: 'Tháng ' + i, 'Khách hàng': 0 })
            }
        }

        return { filledData, totalUsers }
    }

    // Hàm bổ sung các ngày còn thiếu trong tháng
    const fillMissingDays = (data, month, year) => {
        const filledData = []
        const totalDaysInMonth = getDaysInMonth(month, year)

        for (let i = 1; i <= totalDaysInMonth; i++) {
            const dayData = data.find((item) => item._id.day === i)
            if (dayData) {
                // fix total chart chỉ nhận string ở trục x
                filledData.push({ day: String(i), 'Khách hàng': dayData.count })
            } else {
                filledData.push({ day: String(i), 'Khách hàng': 0 })
            }
        }

        return filledData
    }

    // Sử dụng hàm này sau khi fetch data theo tháng

    // Handle khi thay đổi năm
    const handleChangeYear = (value) => {
        const year = moment(value).year()
        setSelectedDate(value)
        fetchData(year, (type = 'year'))
    }

    // Handle khi thay đổi tháng
    const handleChangeMonth = (value) => {
        const monthYear = moment(value)
        setSelectedDate(monthYear)
        fetchData(monthYear.month(), (type = 'month'))
    }

    // fetching
    useEffect(() => {
        if (viewMode === 'month') {
            fetchData(selectedDate.month(), 'month')
        } else {
            fetchData(selectedDate.year(), 'year')
        }
    }, [viewMode, selectedDate])

    return (
        <Box
            m='20px'
            sx={{
                '& .MuiTypography-root.MuiTypography-h2': {
                    margin: '0 0 5px 0',
                },
                '& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: colors.grey[100],
                    borderWidth: '1px',
                },
                '& .MuiInputLabel-root.Mui-focused': { color: colors.grey[100] },
            }}
        >
            <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Header title='THỐNG KÊ KHÁCH HÀNG' subtitle='Trang thống kê khách hàng' />
            </Box>

            <Box height='75vh' m='40px 0 0 0'>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box>
                        {/* DatePicker tương ứng với loại thống kê */}
                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={moment.locale('vi')}>
                            <Box>
                                {viewMode === 'year' ? (
                                    <DatePicker label='Chọn năm' views={['year']} value={selectedDate} onChange={handleChangeYear} />
                                ) : (
                                    <DatePicker label='Chọn tháng' views={['month', 'year']} value={selectedDate} onChange={handleChangeMonth} />
                                )}
                            </Box>
                        </LocalizationProvider>

                        {/* Nút chuyển đổi giữa thống kê theo tháng và năm */}
                        <Box mt={2}>
                            <Button
                                sx={{ mr: '20px', fontWeight: 600 }}
                                variant={viewMode === 'year' ? 'contained' : 'outlined'}
                                color='secondary'
                                onClick={() => setViewMode('year')}
                            >
                                Thống kê theo năm
                            </Button>
                            <Button
                                sx={{ fontWeight: 600 }}
                                variant={viewMode === 'month' ? 'contained' : 'outlined'}
                                color='secondary'
                                onClick={() => setViewMode('month')}
                            >
                                Thống kê theo tháng
                            </Button>
                        </Box>
                    </Box>

                    <Box>
                        {/* <Box mr={'150px'} fontWeight={600} color={colors.greenAccent[500]}>
                            TỔNG SỐ KHÁCH HÀNG:{' '}
                            <Typography fontWeight={700} display='inline-block' variant='h4'>
                                2300
                            </Typography>
                        </Box> */}
                        <Box mr={'150px'} fontWeight={600} color={colors.grey[100]}>
                            TỔNG SỐ KHÁCH HÀNG TRONG {viewMode === 'month' ? `THÁNG ${selectedDate.month() + 1}` : `NĂM ${selectedDate.year()}`}:{' '}
                            <Typography fontWeight={700} display='inline-block' variant='h4' color={colors.greenAccent[500]}>
                                {totalUsers}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                {/* Biểu đồ */}
                {dataUser && <BarChart nameChart='AnalyzesUser' data={dataUser} type={viewMode} selectedDate={selectedDate} />}
            </Box>
        </Box>
    )
}

export default AnalyzesUser
