import React, { memo, useEffect, useState, useRef } from 'react'
import {
    Box,
    Typography,
    useTheme,
    IconButton,
    Button,
    TextField,
    Modal,
    Paper,
    FormControl,
    Divider,
    Popover,
    MenuItem,
    List,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    Select,
    Chip,
} from '@mui/material'
import { tokens } from '../../theme'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'
import { Close, Edit, UploadOutlined } from '@mui/icons-material'
import { formatNumber, parseNumber } from '../prefixData'
import BeenhereIcon from '@mui/icons-material/Beenhere'
import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined'
import HailIcon from '@mui/icons-material/Hail'
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices'
import { Slider, Upload } from 'antd'
import request from '../../utils/api/request'
import { partner_update_freetime, update_partner_info_from_company } from '../../utils/api/apiList'
import SendIcon from '@mui/icons-material/Send'
import { message, Modal as ModalAntd, Button as ButtonAntd } from 'antd'
import AvatarEditor from 'react-avatar-editor'
import { baseURL } from '../../utils/api/baseURL'
import ChairIcon from '@mui/icons-material/Chair'
import SanitizerIcon from '@mui/icons-material/Sanitizer'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import { Formik } from 'formik'
import * as yup from 'yup'

//dropdown antd
const stylesCSS = {
    input: {
        '& input[type=number]': {
            MozAppearance: 'textfield',
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
        },
    },
}

const phoneRegExp = /^[0-9]{10}$/

// validate
const validateSchema = (modalFrom) => {
    return yup.object().shape({
        fullName: yup.string().required('Tên là bắt buộc'),
        phoneNumber: yup
            .string()
            .typeError('Số điện thoại không đúng!')
            .matches(phoneRegExp, 'Số điện thoại không đúng!')
            .required('số điện thoại là bắt buộc'),
        balance: yup.number().typeError('phải là số').notRequired(),
        level:
            modalFrom === 'partner'
                ? yup.number().typeError('level là số').min(0, 'phải lớn hơn 0').integer('phải là số nguyên').required('không được bỏ trống')
                : yup.mixed().notRequired(),
    })
}

function ModalInfoPartner({ modalFrom, open, onClose, dataPartner, onSave, onVerify, fetchData }) {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    const [anchorElCalendar, setAnchorElCalendar] = useState(null)
    const [anchorEl, setAnchorEl] = useState(null)
    const [isEditing, setIsEditing] = useState(false)
    const [services, setServices] = useState([])
    const [imageUrl, setImageUrl] = useState('')

    // state form
    const [formData, setFormData] = useState({
        fullName: '',
        phoneNumber: '',
        level: Number,
        partnerServiceType: [],
        balance: 0,
        registrationDate: '',
    })

    //upload state
    const [uploadVisible, setUploadVisible] = useState(false)
    const [previewImage, setPreviewImage] = useState('')
    const [scale, setScale] = useState(1)
    const editorRef = useRef(null)
    const token = localStorage.getItem('token')

    //state freeTime
    const [freetime, setFreetime] = useState([])
    const days = ['Thứ hai', 'Thứ ba', 'Thứ tư', 'Thứ năm', 'Thứ sáu', 'Thứ bảy', 'Chủ nhật']
    const times = ['Sáng', 'Chiều', 'Tối']

    //message
    const [messageApi, messageContextHolder] = message.useMessage()
    const openMessageNotification = (type, notification) => {
        const notificationColor = type === 'success' ? 'green' : 'red'

        const notificationStyle = {
            color: notificationColor,
            fontWeight: 'bold',
        }
        messageApi.open({
            type: type,
            content: (
                <span>
                    Cập nhật lịch ứng viên <span style={notificationStyle}>{notification}</span> {type === 'success' ? 'thành công!' : 'thất bại!'}
                </span>
            ),
        })
    }

    // fetching data
    useEffect(() => {
        setFormData({
            fullName: dataPartner.fullName,
            phoneNumber: dataPartner.phoneNumber,
            level: dataPartner.level,
            partnerServiceType: dataPartner.partnerServiceType,
            balance: dataPartner.balance,
            registrationDate: new Date(dataPartner.registerDate).toLocaleDateString(),
        })
        setFreetime(dataPartner.freetime)
    }, [dataPartner])

    //cập nhật dịch vụ show tooltip
    useEffect(() => {
        if (formData?.partnerServiceType?.length > 0) {
            setServices(
                formData.partnerServiceType.map((elm, index) => {
                    const { label, icon } = getServiceLabelAndIcon(elm)
                    return (
                        <ListItem key={index}>
                            <ListItemAvatar>
                                <Avatar>{icon}</Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={label} />
                        </ListItem>
                    )
                })
            )
        } else {
            setServices([
                <ListItem key='empty'>
                    <ListItemAvatar>
                        <Avatar>
                            <MiscellaneousServicesIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary='Chưa gán dịch vụ' />
                </ListItem>,
            ])
        }
    }, [formData.partnerServiceType])

    //
    const handleCloseModal = () => {
        onClose()
        setIsEditing(false)
    }

    // handle Submit form
    const handleSubmitEdit = (values, { resetForm }) => {
        // modalFrom !== 'verifyPartner' && onClose()

        console.log({ values })
        setFormData(values)
        onSave(values)
        setIsEditing(false)
    }

    //tooltip dropdown
    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handlePopoverClose = () => {
        setAnchorEl(null)
    }

    // func get label service and icon
    const getServiceLabelAndIcon = (serviceType) => {
        switch (serviceType) {
            case 0:
                return { label: 'Ca lẻ & cố định', icon: <HailIcon /> }
            case 1:
                return { label: 'Tổng vệ sinh', icon: <Diversity3OutlinedIcon /> }
            case 2:
                return { label: 'Điều hoà', icon: <MiscellaneousServicesIcon /> }
            case 3:
                return { label: 'Sofa', icon: <ChairIcon /> }
            case 4:
                return { label: 'Phun khử khuẩn', icon: <SanitizerIcon /> }
            default:
                return { label: 'Unknown Service', icon: <HelpOutlineOutlinedIcon /> }
        }
    }

    //upload
    const handleUpload = ({ file }) => {
        const reader = new FileReader()
        reader.onload = () => {
            setPreviewImage(reader.result)
            setScale(1) // Reset scale when a new image is uploaded
        }
        reader.readAsDataURL(file)
    }

    const handleOk = () => {
        if (editorRef.current) {
            const canvas = editorRef.current.getImage()
            // console.log(canvas)
            canvas.toBlob((blob) => {
                const formData = new FormData()
                formData.append('file', blob, 'avatar.jpg')

                const myHeaders = new Headers()
                myHeaders.append('Authorization', `Bearer ${token}`)

                fetch(baseURL + 'users/upload_avatar', {
                    method: 'POST',
                    headers: myHeaders,
                    body: formData,
                    redirect: 'follow',
                })
                    .then((response) => response.json())
                    .then((result) => {
                        console.log(result)
                        if (result.success) {
                            const avatar = result.data
                            const dataForm = {
                                avatar,
                                _id: dataPartner?._id,
                            }
                            fecthUpdateProfile(dataForm)
                            // console.log(baseURL + 'uploads/' + avatar)
                            setImageUrl(URL.createObjectURL(blob))
                            console.log('image', URL.createObjectURL(blob))
                        }
                        setUploadVisible(false)
                    })
                    .catch((error) => {
                        console.error('Error uploading avatar:', error)
                    })
            }, 'image/jpeg')
        }
    }

    const fecthUpdateProfile = (data) => {
        // console.log(dataPartner)

        request
            .post(update_partner_info_from_company, { ...data, phoneNumber: dataPartner.phoneNumber })
            .then((res) => {
                fetchData()

                message.success(
                    <p>
                        cập nhật avatar nhân viên <span style={{ color: colors.greenAccent[500] }}>{dataPartner?.fullName}</span> thành công!
                    </p>
                )

                console.log(res)
            })
            .catch((err) => {
                message.error('cập nhật thất bại!')
                console.log(err)
            })
    }

    const handleCancel = () => {
        setUploadVisible(false)
    }

    const handleSliderChange = (value) => {
        setScale(value)
    }

    //Lịch rảnh
    const handleOpenBoxCalendar = (e) => {
        setAnchorElCalendar(e.currentTarget)
    }
    const handleCloseBoxCalendar = () => {
        setAnchorElCalendar(!anchorElCalendar)
    }
    const idPopoverCalendar = Boolean(anchorElCalendar) ? 'simple-popover' : undefined

    const convertFreetime = (freetime) => {
        return freetime?.map((ft) => {
            const dayIndex = Math.floor(ft / 3)
            const timeIndex = ft % 3
            // Chuyển đổi thứ tự để thứ hai nằm đầu và chủ nhật cuối
            const adjustedDayIndex = (dayIndex + 6) % 7
            return { day: days[adjustedDayIndex], time: times[timeIndex] }
        })
    }

    const freetimeData = convertFreetime(freetime)

    const isTimeSelected = (day, time) => {
        return freetimeData?.some((ft) => ft.day === day && ft.time === time)
    }

    const renderDayBoxes = () => {
        return days.map((day, dayIndex) => (
            <Box
                key={dayIndex}
                display='flex'
                justifyContent='space-evenly'
                sx={{
                    '& .MuiButton-contained:hover': {
                        bgcolor: '#4e6ff4',
                    },
                }}
            >
                {times.map((time, timeIndex) => (
                    <Button
                        key={timeIndex}
                        onClick={() => toggleSelect(day, time)}
                        variant={isTimeSelected(day, time) ? 'contained' : 'outlined'}
                        sx={{
                            bgcolor: isTimeSelected(day, time) ? '#435cc2' : 'initial',
                        }}
                    >
                        {time}
                    </Button>
                ))}
            </Box>
        ))
    }

    const toggleSelect = (day, time) => {
        const dayIndex = days.indexOf(day)
        const timeIndex = times.indexOf(time)
        // Chuyển đổi ngày và thời gian trở lại giá trị từ 0 đến 20
        const originalDayIndex = (dayIndex + 1) % 7
        const freetimeValue = originalDayIndex * 3 + timeIndex

        // Thêm hoặc loại bỏ freetimeValue khỏi danh sách freetime
        setFreetime((prevFreetime) => {
            if (prevFreetime.includes(freetimeValue)) {
                return prevFreetime.filter((ft) => ft !== freetimeValue)
            } else {
                return [...prevFreetime, freetimeValue]
            }
        })
    }

    const handleSubmitCalendar = (e) => {
        e.preventDefault()
        const updatedData = {
            _id: dataPartner?._id,
            freetime,
        }
        request
            .post(partner_update_freetime, { ...updatedData })
            .then((res) => {
                openMessageNotification('success', dataPartner?.fullName)
                setAnchorElCalendar(!anchorElCalendar)
            })
            .catch((err) => openMessageNotification('error', dataPartner?.fullName))
    }

    return (
        <Modal
            open={open}
            sx={{ zIndex: uploadVisible ? 0 : 'inherit' }}
            onClose={(event, reason) => {
                if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
                    return
                }
                handleCloseModal()
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '500px',
                    // height: '80%',
                    bgcolor: theme.palette.mode === 'dark' ? colors.primary[400] : 'white',
                    boxShadow: 24,
                    p: 4,
                    border: 'unset',
                    borderRadius: '4px',
                    '& .MuiInputBase-input': {
                        pl: '10px',
                        pr: '10px',
                    },
                }}
            >
                {messageContextHolder}
                <IconButton
                    sx={{
                        position: 'absolute',
                        top: '10px',
                        right: '20px',
                    }}
                    onClick={handleCloseModal}
                >
                    <Close color='disabled' />
                </IconButton>
                <Formik
                    initialValues={formData}
                    validationSchema={() => validateSchema(modalFrom)}
                    enableReinitialize={true}
                    onSubmit={handleSubmitEdit}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
                        return (
                            <Box component='form' onSubmit={handleSubmit}>
                                <Typography variant='h5' fontWeight='700' color={colors.blueAccent[300]}>
                                    {isEditing
                                        ? `Chỉnh sửa thông tin ${modalFrom === 'partner' ? 'nhân' : 'ứng'} viên`
                                        : `Thông tin ${modalFrom === 'partner' ? 'nhân' : 'ứng'} viên`}
                                </Typography>
                                <Box display='flex' p='20px'>
                                    <Box
                                        sx={{
                                            flex: '1.3',
                                            '& .MuiInputBase-root::after': {
                                                borderWidth: '1px',
                                                borderColor: colors.greenAccent[400],
                                            },
                                        }}
                                    >
                                        <Box display='flex' flexDirection='column' gap='12px' pr='20px'>
                                            <Box display='flex' alignItems='center'>
                                                <Typography fontWeight={600} mr='5px'>
                                                    Tên:
                                                </Typography>
                                                {isEditing ? (
                                                    <TextField
                                                        name='fullName'
                                                        variant='standard'
                                                        value={values.fullName}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={!!touched.fullName && !!errors.fullName}
                                                        helperText={touched.fullName && errors.fullName}
                                                        sx={{ flex: 1 }}
                                                    />
                                                ) : (
                                                    <Typography color={colors.greenAccent[400]} fontWeight='600'>
                                                        {formData.fullName}
                                                    </Typography>
                                                )}
                                            </Box>
                                            <Box display='flex' alignItems='center'>
                                                <Typography fontWeight={600} mr='5px'>
                                                    Số điện thoại:
                                                </Typography>
                                                {isEditing ? (
                                                    <TextField
                                                        name='phoneNumber'
                                                        variant='standard'
                                                        value={values.phoneNumber}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={!!touched.phoneNumber && !!errors.phoneNumber}
                                                        helperText={touched.phoneNumber && errors.phoneNumber}
                                                        sx={{ flex: 1 }}
                                                    />
                                                ) : (
                                                    <Typography color={colors.greenAccent[400]} fontWeight='600'>
                                                        {formData.phoneNumber}
                                                    </Typography>
                                                )}
                                            </Box>
                                            <Box display='flex' alignItems='center'>
                                                <Typography fontWeight={600} mr='5px'>
                                                    Loại dịch vụ:
                                                </Typography>
                                                {isEditing ? (
                                                    <FormControl
                                                        sx={{
                                                            m: 1,
                                                            width: '100%',
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    borderColor: 'rgba(0, 0, 0, 0.42)', // Thay đổi màu border
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: colors.blueAccent[400], // Màu border khi hover
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: colors.greenAccent[400], // Màu border khi focused
                                                                },
                                                                '& .MuiOutlinedInput-notchedOutline': {
                                                                    borderColor:
                                                                        theme.palette.mode === 'dark'
                                                                            ? 'rgb(255 255 255 / 42%)'
                                                                            : 'rgba(0, 0, 0, 0.42)',
                                                                },
                                                            },
                                                        }}
                                                        size='small'
                                                    >
                                                        <Select
                                                            id='demo-multiple-chip'
                                                            name='partnerServiceType'
                                                            multiple
                                                            value={values.partnerServiceType || []}
                                                            onChange={handleChange}
                                                            renderValue={(selected) => {
                                                                return (
                                                                    <Box
                                                                        sx={{
                                                                            display: 'flex',
                                                                            flexWrap: 'wrap',
                                                                            gap: 0.5,
                                                                        }}
                                                                    >
                                                                        {selected.map((value) => (
                                                                            <Chip key={value} label={value} />
                                                                        ))}
                                                                    </Box>
                                                                )
                                                            }}
                                                            MenuProps={{
                                                                PaperProps: {
                                                                    sx: { maxHeight: 300 },
                                                                },
                                                            }}
                                                        >
                                                            {[0, 1, 2, 3, 4].map((elm) => {
                                                                const { label, icon } = getServiceLabelAndIcon(elm)
                                                                return (
                                                                    <MenuItem
                                                                        value={elm}
                                                                        key={elm}
                                                                        sx={{
                                                                            '&.Mui-selected': {
                                                                                backgroundColor: `rgba(144, 202, 249, 0.16) !important`,
                                                                                '&:hover': {
                                                                                    backgroundColor:
                                                                                        theme.palette.mode === 'dark'
                                                                                            ? 'rgb(239 239 239 / 12%) !important'
                                                                                            : 'rgba(4, 5, 9, 0.12) !important',
                                                                                },
                                                                            },
                                                                        }}
                                                                    >
                                                                        <Box display='flex' alignItems='center'>
                                                                            <Avatar sx={{ marginRight: 1 }}>{icon}</Avatar>
                                                                            <Typography color={colors.greenAccent[400]}>
                                                                                {label} ({elm})
                                                                            </Typography>
                                                                        </Box>
                                                                    </MenuItem>
                                                                )
                                                            })}
                                                        </Select>
                                                    </FormControl>
                                                ) : (
                                                    <Box>
                                                        <Typography
                                                            aria-owns={open ? 'mouse-over-popover' : undefined}
                                                            aria-haspopup='true'
                                                            onMouseEnter={handlePopoverOpen}
                                                            onMouseLeave={handlePopoverClose}
                                                            color={colors.blueAccent[400]}
                                                            fontWeight={600}
                                                        >
                                                            show
                                                        </Typography>
                                                        <Popover
                                                            id='mouse-over-popover'
                                                            sx={{
                                                                pointerEvents: 'none',
                                                            }}
                                                            open={Boolean(anchorEl)}
                                                            anchorEl={anchorEl}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'left',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                            onClose={handlePopoverClose}
                                                            disableRestoreFocus
                                                        >
                                                            <List
                                                                sx={{
                                                                    width: '100%',
                                                                    minWidth: '200px',
                                                                    bgcolor: theme.palette.mode === 'dark' ? colors.grey[400] : '#fff',
                                                                }}
                                                            >
                                                                {services}
                                                            </List>
                                                        </Popover>
                                                    </Box>
                                                )}
                                            </Box>
                                            {modalFrom === 'partner' && isEditing && (
                                                <Box display='flex' alignItems='center'>
                                                    <Typography fontWeight={600} mr='5px'>
                                                        Level
                                                    </Typography>
                                                    <FormControl variant='standard' sx={{ flex: 1 }}>
                                                        <Select
                                                            name='level'
                                                            value={values.level}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={!!touched.level && !!errors.level}
                                                            displayEmpty
                                                        >
                                                            {/* Các giá trị từ 1 đến 7 */}
                                                            {[...Array(7).keys()].map((level) => (
                                                                <MenuItem key={level + 1} value={level + 1}>
                                                                    {level + 1}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        {/* Hiển thị lỗi nếu có */}
                                                        {touched.level && !!errors.level && <FormHelperText error>{errors.level}</FormHelperText>}
                                                    </FormControl>
                                                </Box>
                                            )}

                                            {isEditing && (
                                                <Box display='flex' alignItems='center'>
                                                    <Typography fontWeight={600} mr='5px'>
                                                        Lịch rảnh:
                                                    </Typography>
                                                    <Button
                                                        sx={{
                                                            color: colors.blueAccent[300],
                                                            fontWeight: '600',
                                                            textTransform: 'lowercase',
                                                            border:
                                                                theme.palette.mode === 'dark'
                                                                    ? '1px solid rgb(255 255 255 / 50%)'
                                                                    : '1px solid rgba(4, 5, 9, 0.5)',
                                                            '&:hover': {
                                                                borderColor: 'initial',
                                                            },
                                                        }}
                                                        variant='outlined'
                                                        onClick={handleOpenBoxCalendar}
                                                        aria-describedby={idPopoverCalendar}
                                                    >
                                                        tuỳ chỉnh
                                                    </Button>
                                                </Box>
                                            )}
                                            {!isEditing && (
                                                <Box display='flex' alignItems='center'>
                                                    <Typography fontWeight={600} mr='5px'>
                                                        Ví:
                                                    </Typography>
                                                    <Typography color={colors.greenAccent[400]} fontWeight='600'>
                                                        {formatNumber(formData.balance)} VNĐ
                                                    </Typography>
                                                </Box>
                                            )}
                                            {!isEditing && (
                                                <Box display='flex' alignItems='center'>
                                                    <Typography fontWeight={600} mr='5px'>
                                                        Ngày đăng kí:
                                                    </Typography>
                                                    <Typography color={colors.greenAccent[400]} fontWeight='600'>
                                                        {formData.registrationDate}
                                                    </Typography>
                                                </Box>
                                            )}
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            flex: '0.7',
                                            '& .MuiPaper-root': {
                                                height: '160px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            },
                                            textAlign: 'center',
                                        }}
                                    >
                                        <Paper variant='elevation' elevation={8}>
                                            <img
                                                src={imageUrl || baseURL + dataPartner?.avatar}
                                                style={{
                                                    height: '150px',
                                                    borderRadius: '4px',
                                                }}
                                            />
                                        </Paper>
                                        {isEditing && (
                                            <Box pt='20px'>
                                                <Button
                                                    sx={{
                                                        backgroundColor: theme.palette.mode === 'dark' ? '#8080d9' : '#6060c2',
                                                        color: 'white',
                                                        width: '100%',
                                                        '&:hover': {
                                                            backgroundColor: theme.palette.mode === 'dark' ? '#5757bbc9' : '#5757bbc9',
                                                        },
                                                    }}
                                                    icon={<UploadOutlined />}
                                                    onClick={() => setUploadVisible(true)}
                                                >
                                                    Upload
                                                </Button>
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                                <Divider />
                                <Box display='flex' justifyContent={isEditing ? 'space-between' : 'end'} mt='20px'>
                                    {isEditing ? (
                                        <Button onClick={() => setIsEditing(false)} sx={{ color: colors.grey[100] }}>
                                            <ArrowBackOutlinedIcon />
                                        </Button>
                                    ) : null}
                                    <Box>
                                        <Button
                                            onClick={() => setIsEditing(!isEditing)}
                                            startIcon={isEditing ? <SaveOutlinedIcon /> : <Edit />}
                                            type={isEditing ? 'button' : 'submit'}
                                            // variant='text'
                                            sx={{
                                                bgcolor: colors.orangeAccent[500],
                                                color: 'white',
                                                fontWeight: '600',
                                                mr: '20px',
                                                '&:hover': {
                                                    bgcolor: colors.orangeAccent[600],
                                                },
                                            }}
                                        >
                                            {isEditing ? 'Lưu' : 'Chỉnh sửa'}
                                        </Button>
                                        {!isEditing && modalFrom === 'verifyPartner' ? (
                                            <Button
                                                sx={{
                                                    bgcolor: theme.palette.mode === 'dark' ? colors.greenAccent[600] : '#3da58a',
                                                    color: 'white',
                                                    fontWeight: '600',
                                                    mr: '20px',
                                                    '&:hover': {
                                                        bgcolor: theme.palette.mode === 'dark' ? colors.greenAccent[700] : colors.greenAccent[500],
                                                    },
                                                }}
                                                startIcon={<BeenhereIcon />}
                                                onClick={(e) => onVerify(formData, e)}
                                            >
                                                Xét duyệt
                                            </Button>
                                        ) : null}
                                    </Box>
                                </Box>
                            </Box>
                        )
                    }}
                </Formik>

                <Popover
                    id={idPopoverCalendar}
                    open={Boolean(anchorElCalendar)}
                    anchorEl={anchorElCalendar}
                    onClose={handleCloseBoxCalendar}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                    }}
                >
                    <Box
                        sx={{
                            width: '380px',
                            height: '540px',
                            p: '20px',
                            backgroundColor: theme.palette.mode === 'dark' ? '#dfe4fa' : 'white',
                            overflow: 'auto',
                        }}
                    >
                        <form onSubmit={(e) => handleSubmitCalendar(e)}>
                            <IconButton
                                sx={{
                                    position: 'absolute',
                                    top: '10px',
                                    right: '20px',
                                }}
                                onClick={handleCloseBoxCalendar}
                            >
                                <Close sx={{ color: 'rgb(115 112 112 / 35%)' }} />
                            </IconButton>
                            <Typography variant='h5' fontWeight='700' color='#535ac8'>
                                Lịch rảnh
                            </Typography>

                            <Box display='flex' flexDirection='row' mt='20px'>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '20px',
                                        borderRight: '1px solid #00000040',
                                    }}
                                    flex='0.26'
                                >
                                    {days.map((day, index) => (
                                        <Button key={index}>{day}</Button>
                                    ))}
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }} flex='0.8'>
                                    {renderDayBoxes()}
                                </Box>
                            </Box>
                            <Box display='flex' mt='20px' gap='20px'>
                                <Button
                                    sx={{
                                        width: '100px',
                                        '&:hover': {
                                            backgroundColor: 'transparent',
                                            borderColor: 'currentColor',
                                        },
                                    }}
                                    variant='outlined'
                                >
                                    Bận
                                </Button>
                                <Button
                                    sx={{
                                        width: '100px',
                                        bgcolor: '#435cc2',
                                        '&:hover': {
                                            backgroundColor: '#435cc2',
                                        },
                                    }}
                                    variant='contained'
                                >
                                    Rãnh
                                </Button>
                            </Box>
                            <Box textAlign='end' mt='20px'>
                                <Button
                                    startIcon={<SendIcon sx={{ color: 'white' }} />}
                                    type='submit'
                                    sx={{
                                        fontWeight: '700',
                                        bgcolor: '#535ac8',
                                        width: '90px',
                                        '&:hover': {
                                            bgcolor: colors.blueAccent[500],
                                        },
                                        color: 'white',
                                    }}
                                >
                                    Gửi
                                </Button>
                            </Box>
                        </form>
                    </Box>
                </Popover>

                <ModalAntd
                    open={uploadVisible}
                    title='Upload Avatar'
                    onOk={handleOk}
                    onCancel={handleCancel}
                    footer={[
                        <ButtonAntd key='back' onClick={handleCancel}>
                            Cancel
                        </ButtonAntd>,
                        <ButtonAntd key='submit' type='primary' onClick={handleOk} className='buttonAntdPrimary'>
                            Upload
                        </ButtonAntd>,
                    ]}
                >
                    <Upload
                        showUploadList={false}
                        beforeUpload={(file) => {
                            handleUpload({ file })
                            return false
                        }}
                    >
                        <ButtonAntd icon={<UploadOutlined />}>Select Image</ButtonAntd>
                    </Upload>
                    {previewImage && (
                        <Box
                            sx={{
                                marginTop: '16px',
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                width: '100%',
                            }}
                        >
                            <Box display='flex' width={'100%'}>
                                <AvatarEditor
                                    ref={editorRef}
                                    image={previewImage}
                                    width={370}
                                    height={370}
                                    border={50}
                                    borderRadius={999}
                                    scale={scale}
                                    rotate={0}
                                />
                            </Box>
                            <Slider
                                min={1}
                                max={3}
                                step={0.01}
                                value={scale}
                                onChange={handleSliderChange}
                                style={{ marginTop: 16, width: '100%' }}
                            />
                        </Box>
                    )}
                </ModalAntd>
            </Box>
        </Modal>
    )
}

export default memo(ModalInfoPartner)
