import { Box, Button, Popover, Typography, useTheme } from '@mui/material'
import { tokens } from '../../theme'

function BoxBanDeleteUser({ type, id, open, onClose, anchorEl, handleConfirm }) {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    // Xác định nội dung và màu sắc dựa trên type
    let message, buttonColor, buttonLabel

    switch (type) {
        case 'delete':
            message = 'Bạn muốn xoá khách hàng này?'
            buttonColor = 'error'
            buttonLabel = 'Xoá'
            break
        case 'ban':
            message = 'Bạn muốn khoá tài khoản này?'
            buttonColor = 'warning'
            buttonLabel = 'Khoá'
            break
        case 'unlock':
            message = 'Bạn muốn mở khoá tài khoản này?'
            buttonColor = 'success'
            buttonLabel = 'Mở khoá'
            break
        default:
            message = ''
            buttonColor = 'default'
            buttonLabel = 'Xác nhận'
    }

    return (
        <Popover
            idRow={id}
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            sx={{
                '& .MuiPaper-root': {
                    boxShadow: 'rgba(149, 152, 165, 0.2) 2px 2px 2px',
                    border: '1px solid',
                    boxShadow: 'none',
                },
            }}
        >
            <Box p={2}>
                <Typography color={type === 'delete' ? colors.redAccent[300] : colors.orangeAccent[300]}>{message}</Typography>
                <Box
                    marginTop='10px'
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <Button
                        size='small'
                        variant='contained'
                        color={buttonColor}
                        onClick={handleConfirm}
                        sx={{
                            marginRight: '10px',
                            fontWeight: 600,
                            color: 'white',
                        }}
                    >
                        {buttonLabel}
                    </Button>
                    <Button
                        size='small'
                        onClick={onClose}
                        variant='outlined'
                        sx={{
                            bgcolor: theme.palette.mode === 'dark' ? colors.grey[100] : '',
                            '&:hover': {
                                bgcolor: '#ffffff4d',
                            },
                        }}
                    >
                        Huỷ
                    </Button>
                </Box>
            </Box>
        </Popover>
    )
}

export default BoxBanDeleteUser
