import React, { useCallback, useEffect, useState } from 'react'
import { Image, Modal, Space } from 'antd'
import { Box, Typography, useTheme, IconButton, Button, Popover, Tooltip, debounce } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { tokens } from '../../../theme'
import Header from '../../../components/Header'
import StyleDataGrid from '../../../assets/styles/styleDataGrid'
import request from '../../../utils/api/request'
import {
    delete_partner_idimages,
    get_company_partner,
    partner_signup,
    update_partner_balance_from_company,
    update_partner_info_from_company,
    update_user_info_from_admin,
} from '../../../utils/api/apiList'
import CustomLoadingComponent from '../../../components/loading'
import CustomNoRowsOverlay from '../../../components/noRows'
import StylePopoverDelete from '../../../assets/styles/stylePopoverDelete'
import FolderSharedOutlinedIcon from '@mui/icons-material/FolderSharedOutlined'
import moment from 'moment'
import images from '../../../assets/images'
import ModalInfoPartner from '../../../components/modal/ModalInfoPartner'
import connectSocket from '../../../utils/api/connectSocket'
import { admin_submit_partner } from '../../../utils/api/apiSocket'
import { message } from 'antd'
import ModalUploadPartner from '../../../components/modal/modalUploadPartner'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import { baseURL } from '../../../utils/api/baseURL'
import ChairIcon from '@mui/icons-material/Chair'
import SanitizerIcon from '@mui/icons-material/Sanitizer'
import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined'
import HailIcon from '@mui/icons-material/Hail'
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices'
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined'
import * as yup from 'yup'
import BoxNapRutCocTien from '../boxNapRutCocTien/index.js'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import { formatNumber, phoneRegExp } from '../../../components/prefixData/index.js'
import ModalCreateEditUser from '../../../components/modal/modalCreateEditUser/index.js'
import {
    DeleteOutlined,
    DownloadOutlined,
    RotateLeftOutlined,
    RotateRightOutlined,
    SwapOutlined,
    ZoomInOutlined,
    ZoomOutOutlined,
} from '@ant-design/icons'

//validate nap coc
const validateSchema = yup.object().shape({
    note: yup.string().required('nhập nội dung nạp tiền'),
    deposit: yup.number().typeError('Tiền nạp phải là số').required('nhập số tiền'),
})

//validate box tao moi ung vien
const passRegExp = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/
const validateSchemaPartner = yup.object().shape({
    fullName: yup.string().required('Nhập tên ứng viên'),
    phoneNumber: yup.string().matches(phoneRegExp, 'Số điện thoại không đúng').required('Nhập số điện thoại'),
    password: yup
        .string()
        .min(8, 'Mật khẩu tối thiểu là 8 ký tự bao gồm chữ và số')
        .matches(passRegExp, 'Mật khẩu tối thiểu là 8 ký tự bao gồm chữ và số')
        .required('Không được để trống!'),
    confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Mật khẩu mới không khớp!')
        .required('Không được để trống!'),
})

//validate box đổi password
const validateSchemaChanePassword = yup.object().shape({
    fullName: yup.string().required('Không được để trống!'),
    phoneNumber: yup.string().matches(phoneRegExp, 'Số điện thoại không đúng').required('Không được để trống!'),
    newPassword: yup
        .string()
        .min(8, 'Mật khẩu mới tối thiểu là 8 ký tự bao gồm chữ và số')
        .matches(passRegExp, 'Mật khẩu tối thiểu là 8 ký tự bao gồm chữ và số')
        .required('Không được để trống!'),
    confirmNewPassword: yup
        .string()
        .oneOf([yup.ref('newPassword'), null], 'Mật khẩu mới không khớp!')
        .required('Không được để trống!'),
})

const VerifyEmployee = () => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    const [dataVerifyPartner, setDataVerifyPartner] = useState([])
    const [anchorEl, setAnchorEl] = useState(null)
    const [anchorElUpload, setAnchorElUpload] = useState(null)
    const [anchorElCreatePartner, setAnchorElCreatePartner] = useState(null)
    const [loading, setLoading] = useState(true)
    const [loadingCreate, setLoadingCreate] = useState(null)
    const [loadingEdit, setLoadingEdit] = useState(null)
    const [currentRow, setCurrentRow] = useState({})
    const [anchorElChangePassword, setAnchorElChangePassword] = useState(null)

    //companyId
    const companyId = localStorage.getItem('companyId')

    // profile partner
    const [openBoxProfilePartner, setOpenBoxProfilePartner] = useState(false)
    const [dataPartner, setDataPartner] = useState({})
    const [dataPartnerUpdated, setDataPartnerUpdated] = useState(null)

    //deposit
    const [anchorElDeposit, setAnchorElDeposit] = useState(null)

    //create partner
    const [initialValuesPartner, setInitialValuesPartner] = useState({
        fullName: '',
        phoneNumber: '',
        password: '',
        confirmPassword: '',
        companyId,
    })

    //initial values edit user
    const [initialValuesEditPartner, setInitialValuesEditPartner] = useState({
        fullName: '',
        phoneNumber: '',
        newPassword: '',
        confirmNewPassword: '',
        companyId,
    })

    // pagination server side
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 25,
    })
    const [totalRows, setTotalRows] = useState(0)
    //search server side
    const [searchTerm, setSearchTerm] = useState('')
    const [inputText, setInputText] = useState('')
    //sort server
    const [queryOptions, setQueryOptions] = useState({ registerDate: -1 })

    //notification
    const [messageApi, contextHolder] = message.useMessage()
    const openMessageNotification = (type, notification, eventName) => {
        const notificationColor = type === 'success' ? 'green' : 'red'

        const notificationStyle = {
            color: notificationColor,
            fontWeight: 'bold',
        }

        messageApi.open({
            type: type,
            content: (
                <span>
                    {type === 'success' ? (
                        <>
                            {eventName === 'updatePartner' ? 'Cập nhật info ứng viên' : 'Duyệt nhân viên'}{' '}
                            <span style={notificationStyle}>{notification}</span> thành công
                        </>
                    ) : (
                        <>
                            {eventName === 'updatePartner' ? 'Cập nhật info ứng viên' : 'Duyệt nhân viên'}{' '}
                            <span style={notificationStyle}>{notification}</span> thất bại!
                        </>
                    )}
                </span>
            ),
        })
    }

    // Debounced
    const debouncedSetSearchTerm = useCallback(
        debounce((value) => {
            setSearchTerm(value)
        }, 500),
        []
    )

    // handle search
    const handleInputChange = (e) => {
        const value = e.target.value
        setInputText(value)
        debouncedSetSearchTerm(value)
    }

    // sort server
    const handleSortModelChange = useCallback((sortModel) => {
        if (sortModel.length > 0) {
            const sortField = sortModel[0].field
            const sortOrder = sortModel[0].sort === 'asc' ? 1 : -1
            setQueryOptions({ [sortField]: sortOrder })
        } else {
            setQueryOptions({ registerDate: -1 })
        }
    }, [])

    //socket
    const [socket, setSocket] = useState(null)

    //initialValues
    const [initialFormValues, setInitialFormValues] = useState({
        deposit: '',
        note: '',
    })

    //fetching - socket
    useEffect(() => {
        fetchData()

        const socketInstance = connectSocket()
        setSocket(socketInstance)

        socketInstance.on('connect', () => {
            console.log('Socket is connected')
        })

        return () => {
            socketInstance.disconnect()
        }
    }, [paginationModel, searchTerm, queryOptions])

    //fetchData
    const fetchData = () => {
        // level 1: danh sách xét duyệt, lv2: danh sách nhân viên
        request
            .post(get_company_partner, {
                companyId,
                level: 1,
                page: paginationModel.page + 1,
                limit: paginationModel.pageSize,
                search: searchTerm,
                sort: queryOptions,
            })
            .then((res) => {
                const dataRs = res.data.data.docs
                const data = dataRs.map((item, index) => {
                    return { ...item, index: index + 1 + paginationModel.page * paginationModel.pageSize }
                })
                setDataVerifyPartner(data)
                setTotalRows(res.data.data.total)
                console.log('fetchData', data)
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setLoading(false)
            })
    }

    //fetch create partner
    const fetchCreatePartner = (data, resetForm, setFieldError) => {
        request
            .post(partner_signup, { ...data })
            .then((res) => {
                console.log(res)
                message.success('Tạo tài khoản thành công!')
                fetchData()
                resetForm()
                setAnchorElCreatePartner(null)
                setLoadingCreate(false)
            })
            .catch((err) => {
                const textErr = err.response.data.data
                switch (textErr) {
                    case 'PhoneInUseInPartnerApp':
                    case 'PhoneInUseInUserApp':
                    case 'phoneInUse':
                        message.error('Số điện thoại đã được đăng ký!')
                        setFieldError('phoneNumber', 'Số điện thoại đã được đăng ký!')
                        setLoadingCreate(false)
                        break
                    default:
                        message.error('Lỗi: tạo nhân viên thất bại!')
                        resetForm()
                        setAnchorElCreatePartner(null)
                        break
                }

                console.error('Lỗi tạo nhân viên', err)
            })
    }

    //fetch update balance partner
    const fetchUpdateBalance = (data, resetForm) => {
        request
            .post(update_partner_balance_from_company, { ...data })
            .then((res) => {
                console.log(res)
                message.success('Nạp tiền thành công!')
                fetchData()
            })
            .catch((err) => {
                message.error('Lỗi: nạp tiền thất bại!')
                console.log('Lỗi nạp tiền', err)
            })
            .finally(() => {
                resetForm()
                setAnchorElDeposit(null)
            })
    }

    //fetch change pass
    const fetchChangePassword = (data, resetForm) => {
        request
            .post(update_partner_info_from_company, { ...data })
            .then((res) => {
                console.log(res)
                message.success(
                    <Typography>
                        Đổi mật khẩu tài khoản <span style={{ color: colors.greenLight[400] }}>{currentRow.fullName}</span> thành công!
                    </Typography>
                )
                fetchData()
            })
            .catch((err) => {
                message.error('Lỗi: cập nhật thất bại!')
            })
            .finally(() => {
                resetForm()
                setAnchorElChangePassword(null)
                setLoadingEdit(false)
            })
    }

    // xoá ảnh profile nhân viên
    const fetcDeleteImageProfilePartner = (data, row) => {
        request
            .post(delete_partner_idimages, { ...data })
            .then((res) => {
                console.log(res)
                fetchData()
                message.success(`Xoá ảnh hồ sơ ứng viên ${row.fullName} thành công!`)
            })
            .catch((err) => {
                message.error('Lỗi: xoá ảnh hồ sơ thất bại!')
                console.log('Lỗi xoá ảnh', err)
            })
    }

    //verify partner
    const handleOpenVerifyBox = (data, event) => {
        setDataPartnerUpdated(data)
        setAnchorEl(event.currentTarget)
    }
    const handleCloseVerifyBox = () => {
        setAnchorEl(null)
    }
    const handleVerifyPartner = () => {
        // console.log(dataPartnerUpdated)
        const data = {
            ...dataPartnerUpdated,
            _id: dataPartner?._id,
            level: 2,
        }
        request
            .post(update_partner_info_from_company, { ...data })
            .then((res) => {
                if (socket) {
                    socket.emit(admin_submit_partner, {
                        taskerId: dataPartner?._id,
                        note: 'admin duyệt nhân viên',
                    })
                }
                openMessageNotification('success', dataPartner?.fullName, 'verifyPartner')
                setAnchorEl(null)
                fetchData()
                setOpenBoxProfilePartner(false)
            })
            .catch((err) => openMessageNotification('error', dataPartner?.fullName, 'verifyPartner'))
    }

    const columns = [
        {
            field: 'index',
            headerName: 'STT',
            sortable: false,
        },
        {
            field: 'fullName',
            headerName: 'Tên nhân viên',
            flex: 0.8,
            minWidth: 200,
            cellClassName: 'name-column--cell',
            sortable: false,
        },
        {
            field: 'phoneNumber',
            headerName: 'Số điện thoại',
            minWidth: 100,
            flex: 0.5,
            sortable: false,
        },
        {
            field: 'partnerServiceType',
            headerName: 'Loại dịch vụ',
            flex: 0.7,
            minWidth: 100,
            sortable: false,
            renderCell: ({ row }) => {
                const serviceTypeToIcon = {
                    0: <HailIcon />,
                    1: <Diversity3OutlinedIcon />,
                    2: <MiscellaneousServicesIcon />,
                    3: <ChairIcon />,
                    4: <SanitizerIcon />,
                }

                return (
                    <Box
                        display='flex'
                        gap='2px'
                        sx={{
                            '& .MuiSvgIcon-root': { color: colors.grey[600] },
                        }}
                    >
                        {row?.partnerServiceType?.map((type) => (
                            <Typography fontSize={12} key={type}>
                                {serviceTypeToIcon[type]}
                            </Typography>
                        ))}
                    </Box>
                )
            },
        },
        {
            field: 'idImages',
            headerName: 'Ảnh hồ sơ',
            flex: 1,
            minWidth: 200,
            sortable: false,
            renderCell: ({ row }) => {
                const imagesArr = row.idImages
                const maxVisibleImages = 3

                return imagesArr.length > 0 ? (
                    <Image.PreviewGroup
                        preview={{
                            toolbarRender: (
                                _,
                                {
                                    transform: { scale },
                                    actions: { onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn, onReset },
                                    image,
                                }
                            ) => (
                                <Space size={36} className='toolbar-wrapper'>
                                    <SwapOutlined rotate={90} onClick={onFlipY} style={{ fontSize: '24px' }} />
                                    <SwapOutlined onClick={onFlipX} style={{ fontSize: '24px' }} />
                                    <RotateLeftOutlined onClick={onRotateLeft} style={{ fontSize: '24px' }} />
                                    <RotateRightOutlined onClick={onRotateRight} style={{ fontSize: '24px' }} />
                                    <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} style={{ fontSize: '24px' }} />
                                    <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} style={{ fontSize: '24px' }} />
                                    <DeleteOutlined
                                        onClick={() => {
                                            Modal.confirm({
                                                title: 'Xóa ảnh này?',
                                                content: 'Bạn có chắc chắn muốn xóa ảnh này không?',
                                                okText: 'Xóa',
                                                cancelText: 'Hủy',
                                                onOk: () => {
                                                    handleDeleteImageProfile(image, row._id, row), onReset()
                                                },
                                            })
                                        }}
                                        style={{ color: 'red', fontSize: '24px' }}
                                    />{' '}
                                </Space>
                            ),
                        }}
                    >
                        {imagesArr.slice(0, maxVisibleImages).map((imageUrl, index) => (
                            <Image key={index} src={baseURL + imageUrl} width={40} height={40} />
                        ))}
                        {imagesArr.length > maxVisibleImages && <span style={{ marginLeft: '4px' }}>+{imagesArr.length - maxVisibleImages}...</span>}
                        {/* Các ảnh ẩn vẫn được thêm vào Image.PreviewGroup để có thể xem khi click vào */}
                        {imagesArr.slice(maxVisibleImages).map((imageUrl, index) => (
                            <Image
                                key={maxVisibleImages + index}
                                src={baseURL + imageUrl}
                                style={{ display: 'none' }} // Ẩn các ảnh này trong cột
                            />
                        ))}
                    </Image.PreviewGroup>
                ) : (
                    <Image width={40} height={40} style={{ borderRadius: '50%' }} src={images.placeholderPerson} />
                )
            },
        },
        {
            field: 'balance',
            headerName: 'Số dư',
            minWidth: 120,
            flex: 0.5,
            sortable: false,
            renderCell: ({ row }) => {
                return <Typography fontSize='14px'>{formatNumber(row.balance)}</Typography>
            },
        },
        {
            field: 'registerDate',
            headerName: 'Ngày đăng ký',
            headerAlign: 'left',
            align: 'left',
            flex: 0.5,
            minWidth: 100,
            renderCell: ({ row }) => moment(row.registerDate).format('DD-MM-YYYY'),
        },
        {
            field: 'action',
            headerName: 'Hành động',
            minWidth: 200,
            flex: 0.8,
            // align: 'right',
            sortable: false,
            cellClassName: 'action-column',
            renderCell: ({ row }) => (
                <Box display='flex' alignItems='center' gap={1}>
                    <Tooltip
                        title='Đổi mật khẩu'
                        arrow
                        slotProps={{
                            popper: {
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, -5],
                                        },
                                    },
                                ],
                            },
                        }}
                    >
                        <IconButton color='secondary' onClick={(e) => handleOpenBoxChangePassword(e, row)}>
                            <LockOpenIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        title='Thông tin ứng viên'
                        arrow
                        slotProps={{
                            popper: {
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, -5],
                                        },
                                    },
                                ],
                            },
                        }}
                    >
                        <IconButton onClick={() => handleOpenBoxProfilePartner(row)} color='secondary' aria-label='edit'>
                            <FolderSharedOutlinedIcon fontSize='medium' />
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        title='Upload'
                        arrow
                        slotProps={{
                            popper: {
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, -5],
                                        },
                                    },
                                ],
                            },
                        }}
                    >
                        <IconButton color='secondary' onClick={(e) => handleOpenBoxUpload(row, e)}>
                            <FileUploadOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        title='Nạp cọc'
                        arrow
                        slotProps={{
                            popper: {
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, -5],
                                        },
                                    },
                                ],
                            },
                        }}
                    >
                        <IconButton color='secondary' onClick={(e) => handleOpenBoxDeposit(e, row)}>
                            <AccountBalanceWalletOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            ),
        },
    ]

    // xoá ảnh hồ sơ nhân viên
    const handleDeleteImageProfile = (imageUrl, userId, row) => {
        const imagePath = imageUrl.url.replace(baseURL, '')

        const data = {
            userId: userId,
            idImagesArr: [imagePath],
        }

        // Gọi API xóa ảnh
        fetcDeleteImageProfilePartner(data, row)
    }

    //box đổi pass
    const handleOpenBoxChangePassword = (e, row) => {
        setCurrentRow(row)
        setAnchorElChangePassword(e.currentTarget)
        setInitialValuesEditPartner({
            ...initialValuesEditPartner,
            fullName: row.fullName,
            phoneNumber: row.phoneNumber,
        })
    }
    const handleCloseBoxChangePassword = () => {
        setAnchorElChangePassword(null)
    }
    const handleChangePassword = (values, { resetForm }) => {
        console.log('values change passs', values)
        const data = {
            _id: currentRow._id,
            password: values.newPassword,
            phoneNumber: values.phoneNumber,
        }
        setLoadingEdit(true)

        const timer = setTimeout(() => {
            fetchChangePassword(data, resetForm)
        }, 500)

        return () => clearTimeout(timer)
    }

    //box nạp cọc
    const handleOpenBoxDeposit = (e, row) => {
        setAnchorElDeposit(e.currentTarget)
        setDataPartner(row)
    }

    const handleCloseBoxDeposit = () => {
        setAnchorElDeposit(null)
    }

    const handleCreateDeposit = (values, { resetForm }) => {
        console.log('values', values)
        const data = {
            _id: dataPartner._id,
            deposit: values.deposit,
            note: values.note,
        }

        fetchUpdateBalance(data, resetForm)
    }

    //create partner
    const handleOpenBoxCreatePartner = (e) => {
        setAnchorElCreatePartner(e.currentTarget)
    }

    const handleCloseBoxCreatePartner = () => {
        setAnchorElCreatePartner(null)
    }

    const handleCreatePartner = (values, { resetForm, setFieldError }) => {
        console.log('values partner', values)
        setLoadingCreate(true)

        const timer = setTimeout(() => {
            fetchCreatePartner(values, resetForm, setFieldError)
        }, [500])
        return () => clearTimeout(timer)
    }

    //upload file
    const handleOpenBoxUpload = (row, e) => {
        setAnchorElUpload(e.currentTarget)
        setDataPartner(row)
    }

    const handleCloseBoxUpload = () => {
        setAnchorElUpload(false)
    }

    //open box profile partner
    const handleOpenBoxProfilePartner = (row) => {
        console.log(row)
        setDataPartner(row)
        setOpenBoxProfilePartner(true)
    }

    const handleCloseBoxPartnerProfile = () => {
        setOpenBoxProfilePartner(false)
    }

    const handleSubmitProfilePartner = (dataModalInfoPartner) => {
        const data = {
            ...dataModalInfoPartner,
            registrationDate: dataPartner?.registerDate,
            balance: Number(dataModalInfoPartner.balance),
            _id: dataPartner?._id,
        }
        // console.log(data)

        request
            .post(update_partner_info_from_company, { data })
            .then((res) => {
                // console.log('dataRes', res)
                fetchData()
                openMessageNotification('success', dataPartner?.fullName, 'updatePartner')
            })
            .catch((err) => {
                openMessageNotification('success', dataPartner?.fullName, 'updatePartner')
            })
    }

    return (
        <Box
            m='20px'
            sx={{
                '& .MuiTypography-root.MuiTypography-h2': {
                    margin: '0 0 5px 0',
                },
                '& .ant-image': { mr: '5px' },
                '& .ant-image-img': { borderRadius: '5px' },
            }}
        >
            {contextHolder}
            <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Header title='XÉT DUYỆT NGƯỜI LAO ĐỘNG' subtitle='Trang xét duyệt người lao động' />
                <Button
                    sx={{
                        backgroundColor: colors.blueAccent[700],
                        color: 'white',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        padding: '10px 20px',
                        '&:hover': {
                            backgroundColor: colors.blueAccent[600],
                        },
                    }}
                    startIcon={<PersonAddIcon />}
                    onClick={(e) => handleOpenBoxCreatePartner(e)}
                >
                    Thêm tài khoản
                </Button>
            </Box>
            <Box height='75vh' m='40px 0 0 0' sx={StyleDataGrid()}>
                <DataGrid
                    // getRowHeight={() => 'auto'}
                    getRowId={(row) => row._id}
                    rows={dataVerifyPartner}
                    rowCount={totalRows}
                    columns={columns}
                    //pagination server side
                    pagination
                    initialState={{
                        pagination: { paginationModel },
                        density: 'comfortable',
                    }}
                    pageSizeOptions={[10, 25, 50, 100]}
                    paginationMode='server'
                    onPaginationModelChange={setPaginationModel}
                    //pagination server side

                    // sort server
                    sortingMode='server'
                    onSortModelChange={handleSortModelChange}
                    loading={loading}
                    slots={{
                        toolbar: GridToolbar,
                        noRowsOverlay: CustomNoRowsOverlay,
                        loadingOverlay: CustomLoadingComponent,
                    }}
                    localeText={{
                        toolbarDensity: 'Size',
                        toolbarDensityLabel: 'Size',
                        toolbarDensityCompact: 'Small',
                        toolbarDensityStandard: 'Medium',
                        toolbarDensityComfortable: 'Large',
                    }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: {
                                onChange: handleInputChange,
                                value: inputText,
                                placeholder: 'Tìm kiếm theo tên/sdt',
                            },
                        },
                    }}
                />
            </Box>
            {openBoxProfilePartner && (
                <ModalInfoPartner
                    modalFrom='verifyPartner'
                    open={openBoxProfilePartner}
                    onClose={handleCloseBoxPartnerProfile}
                    dataPartner={dataPartner}
                    onSave={handleSubmitProfilePartner}
                    onVerify={handleOpenVerifyBox}
                    fetchData={fetchData}
                />
            )}
            <ModalUploadPartner
                modalFrom='partner'
                open={Boolean(anchorElUpload)}
                onClose={handleCloseBoxUpload}
                data={dataPartner}
                fetchData={fetchData}
            />

            <Popover
                id={dataPartner?._id}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleCloseVerifyBox}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                sx={StylePopoverDelete()}
            >
                <Box p={2}>
                    <Typography>Bạn muốn duyệt nhân viên làm chính thức?</Typography>
                    <Box
                        marginTop='10px'
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Button
                            size='small'
                            variant='contained'
                            color='success'
                            onClick={handleVerifyPartner}
                            sx={{
                                marginRight: '10px',
                                fontWeight: 600,
                                color: 'white',
                            }}
                        >
                            OK
                        </Button>
                        <Button
                            size='small'
                            variant='outlined'
                            onClick={handleCloseVerifyBox}
                            sx={{
                                bgcolor: theme.palette.mode === 'dark' ? colors.grey[100] : '',
                            }}
                        >
                            HUỶ
                        </Button>
                    </Box>
                </Box>
            </Popover>

            {/* box nạp cọc */}
            <BoxNapRutCocTien
                open={Boolean(anchorElDeposit)}
                onClose={handleCloseBoxDeposit}
                dataPartner={dataPartner}
                initialFormValues={initialFormValues}
                validateSchema={validateSchema}
                handleConfirm={handleCreateDeposit}
            />

            {/* create partner */}
            <ModalCreateEditUser
                type='create'
                open={Boolean(anchorElCreatePartner)}
                onClose={handleCloseBoxCreatePartner}
                validateSchema={validateSchemaPartner}
                initialValues={initialValuesPartner}
                loading={loadingCreate}
                handleConfirm={handleCreatePartner}
            />

            {/* change password partner */}
            <ModalCreateEditUser
                type='edit'
                open={Boolean(anchorElChangePassword)}
                onClose={handleCloseBoxChangePassword}
                validateSchema={validateSchemaChanePassword}
                initialValues={initialValuesEditPartner}
                loading={loadingEdit}
                handleConfirm={handleChangePassword}
            />
        </Box>
    )
}

export default VerifyEmployee
