import { useState } from 'react'
import { Modal, Typography, Box, useTheme, IconButton, Button } from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
import { tokens } from '../../../theme'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import { Image, Upload } from 'antd'
import { Close } from '@mui/icons-material'
import request from '../../../utils/api/request'
import { update_partner_info_image, upload_info_identify_pic } from '../../../utils/api/apiList'
import { message } from 'antd'

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
    })

function ModalUploadPartner({ modalFrom, open, onClose, data, fetchData = () => {} }) {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    //message
    const [messageApi, messageContextHolder] = message.useMessage()

    //validate upload
    const validateFileType = (file) => {
        const allowedTypes = ['image/jpeg', 'image/png'] // Các loại file cho phép
        if (!allowedTypes.includes(file.type)) {
            message.error('File ảnh bạn đã tải lên không hợp lệ, định dạng được chấp nhận: JPG, PNG.')
            return Upload.LIST_IGNORE // Ngăn không cho file được upload
        }
        return true // Cho phép upload nếu file hợp lệ
    }

    //state antd image
    const [previewOpen, setPreviewOpen] = useState(false)
    const [previewImage, setPreviewImage] = useState('')
    const [fileList, setFileList] = useState([])
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj)
        }
        setPreviewImage(file.url || file.preview)
        setPreviewOpen(true)
    }
    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList)

    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
                color: colors.grey[100],
            }}
            type='button'
        >
            <AddOutlinedIcon />
            <Typography>Upload</Typography>
        </button>
    )

    //custom request
    const [listImage, setListImage] = useState([])
    const customRequest = async ({ file, onSuccess, onError }) => {
        const formData = new FormData()
        formData.append('file', file, file.name)
        formData.append('_id', data._id)
        const tokenStorage = localStorage.getItem('token')

        try {
            request.setAuthToken(tokenStorage)
            const response = await request.post(upload_info_identify_pic, formData)
            onSuccess(response.data, file)
            // console.log({ data: response, file })

            const uploadedFileData = {
                response: { data: response.data.data },
                lastModified: file.lastModified,
                name: file.name,
            }
            const newListImage = [...listImage, uploadedFileData]
            setListImage(newListImage)
        } catch (error) {
            onError(error)
        }
    }
    const handleSend = () => {
        const name = data.fullName
        //post len server
        const dataImage = {
            _id: data._id,
            userImages: {
                fileList: listImage,
            },
        }

        request
            .post(update_partner_info_image, { ...dataImage })
            .then((res) => {
                // console.log('sendImages', res)
                handleClose()
                // openNotification('success', name)
                message.success(`Upload hồ sơ nhân viên ${name} thành công`)
                fetchData()
            })
            .catch((err) => {
                console.error(err)
                message.error(`Upload hồ sơ nhân viên thất bại`)
            })
    }

    //handle close
    const handleClose = () => {
        setFileList([])
        setListImage([])
        onClose()
    }

    return (
        <Modal open={open} onClose={handleClose} sx={{ zIndex: 99 }}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '570px',
                    minHeight: '300px',
                    maxHeight: '80vh',
                    overflowY: 'auto',
                    bgcolor: theme.palette.mode === 'dark' ? colors.primary[400] : 'white',
                    boxShadow: 24,
                    p: 4,
                    border: 'unset',
                    borderRadius: '4px',
                }}
            >
                {messageContextHolder}
                <IconButton
                    sx={{
                        position: 'absolute',
                        top: '10px',
                        right: '20px',
                    }}
                    onClick={handleClose}
                >
                    <Close color='disabled' />
                </IconButton>
                <Box mb={2}>
                    <Typography fontSize='18px' color={colors.grey[300]}>
                        UPLOAD ẢNH HỒ SƠ NHÂN VIÊN
                    </Typography>
                    <Typography color={colors.greenAccent[400]}>{data?.fullName}</Typography>
                </Box>
                <Box
                    sx={{
                        p: '10px',
                        borderRadius: '8px',
                        background: theme.palette.mode === 'dark' ? '#32445e' : '#f3f5f8',
                        '& .ant-upload-list': {
                            gap: '12px !important',
                            p: '20px',
                        },
                    }}
                >
                    <Upload
                        customRequest={customRequest}
                        listType='picture-card'
                        fileList={fileList}
                        onPreview={handlePreview}
                        onChange={handleChange}
                        beforeUpload={validateFileType}
                    >
                        {fileList.length >= 8 ? null : uploadButton}
                    </Upload>
                    {previewImage && (
                        <Image
                            wrapperStyle={{
                                display: 'none',
                            }}
                            preview={{
                                visible: previewOpen,
                                onVisibleChange: (visible) => setPreviewOpen(visible),
                                afterOpenChange: (visible) => !visible && setPreviewImage(''),
                            }}
                            src={previewImage}
                        />
                    )}
                </Box>
                <Box textAlign='end'>
                    <Button
                        variant='contained'
                        endIcon={<SendIcon />}
                        sx={{
                            fontWeight: '700',
                            bgcolor: theme.palette.mode === 'dark' ? colors.greenAccent[600] : colors.greenAccent[400],
                            mt: '20px',
                            width: '90px',
                            '&:hover': {
                                bgcolor: colors.greenAccent[500],
                            },
                        }}
                        onClick={handleSend}
                    >
                        Gửi
                    </Button>
                </Box>
            </Box>
        </Modal>
    )
}

export default ModalUploadPartner
