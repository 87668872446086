import { profit_clean_by_month_by_year_admin } from '../../../../../utils/api/apiList'
import BookingProfit from './BookingProfit'

const CleanBookingProfit = () => {
    return (
        <BookingProfit
            title='TỔNG VỆ SINH'
            subtitle='Trang thống kê lợi nhuận tổng vệ sinh'
            apiEndpoint={profit_clean_by_month_by_year_admin}
            nameChart='Clean'
        />
    )
}

export default CleanBookingProfit
