import { Box, Button, CircularProgress, IconButton, Modal, TextField, Typography, useTheme } from '@mui/material'
import LockIcon from '@mui/icons-material/Lock'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import PersonIcon from '@mui/icons-material/Person'
import { Cancel, Close, Edit, Send, Visibility, VisibilityOff } from '@mui/icons-material'
import { Formik } from 'formik'
import { useState } from 'react'
import { tokens } from '../../../theme'
import ModalCustom from '../modal'
import TextFieldCustom from '../../form/textfield'
import './index.scss'
function ModalCreateEditUser({ type, open, onClose, validateSchema, initialValues, loading, handleConfirm }) {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    //show pass
    const [showPassword, setShowPassword] = useState(false)
    const [showNewPassword, setShowNewPassword] = useState(false)
    const [showRePassword, setShowRePassword] = useState(false)
    const [showRenewPassword, setShowRenewPassword] = useState(false)

    const handleClickShowPassword = () => setShowPassword(!showPassword)
    const handleClickShowNewPassword = () => setShowNewPassword(!showNewPassword)
    const handleClickShowRePassword = () => setShowRePassword(!showRePassword)
    const handleClickShowRenewPassword = () => setShowRenewPassword(!showRenewPassword)

    //style box
    const styleBox = {
        '& .MuiBox-root': {
            display: 'flex',
            alignItems: 'center',
            gap: '20px',
            width: '95%',
            '& .MuiTextField-root': {
                flex: 1,
            },
        },
        '& .MuiTypography-root': {
            minWidth: '120px',
            fontSize: '14px',
        },
        '& .MuiBox-root.select': {
            width: '100%',
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px',
        // maxHeight: '700px',
        maxHeight: '70vh',
        overflowY: 'auto',
        mt: '5px',
        // pb: '20px',
        pt: '20px',
        borderRadius: '5px',
        '& .Mui-focused fieldset': {
            borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.6) !important' : 'rgba(0, 0, 0, 0.8)',
            borderWidth: theme.palette.mode === 'dark' ? '2px' : '1px !important',
        },
        '& .box-quantity.MuiBox-root': {
            background: colors.blueAccent[700],
            ml: '140px',
            width: '58%',
            p: '16px 0',
            borderRadius: '4px',
            '& .MuiBox-root': {
                gap: 0,
            },
        },
        '& .MuiFormHelperText-root.Mui-error': {
            fontSize: '12px',
        },
    }

    const handleClose = () => {
        onClose()
        setShowPassword(false)
        setShowNewPassword(false)
        setShowRePassword(false)
        setShowRenewPassword(false)
    }

    return (
        <ModalCustom
            className='modal-new-user'
            open={open}
            onClose={(event, reason) => {
                if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
                    return
                }
                handleClose()
            }}
            width={600}
            bgcolor={theme.palette.mode === 'dark' ? '#1F2A40' : '#e0e0e0'}

            sxBox={{
                border: '1px solid rgba(0, 0, 0, 0.1) 0px 4px 12px',
            }}
        >

            <Box display='flex' justifyContent='space-between' alignItems='center' mb='5px'>
                <Typography color={colors.grey[200]} sx={{ fontWeight: 600, fontSize: '16px' }}>
                    {type === 'create' ? 'THÊM TÀI KHOẢN' : 'ĐỔI MẬT KHẨU'}
                </Typography>
            </Box>
            <IconButton
                sx={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                }}
                onClick={handleClose}
            >
                <Close color='disabled' />
            </IconButton>
            <Formik
                initialValues={initialValues}
                validationSchema={validateSchema}
                enableReinitialize={true}
                onSubmit={(values, { resetForm, setFieldError }) => {
                    handleConfirm(values, { resetForm, setFieldError })
                }}
            >
                {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => {
                    return (
                        <Box component='form' onSubmit={handleSubmit} sx={styleBox}>
                            <TextFieldCustom
                                label='Tên'
                                name='fullName'
                                value={values.fullName}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder='Nhập tên'
                                error={!!touched.fullName && !!errors.fullName}
                                helperText={touched.fullName && errors.fullName}
                                InputProps={{
                                    startAdornment: (
                                        <IconButton disabled>
                                            <PersonIcon />
                                        </IconButton>
                                    ),
                                }}
                                widthField='95%'

                                disabled={type === 'create' ? false : true}
                            />
                            <TextFieldCustom
                                label='Số điện thoại'
                                name='phoneNumber'
                                value={values.phoneNumber}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder='Nhập số điện thoại'
                                error={!!touched.phoneNumber && !!errors.phoneNumber}
                                helperText={touched.phoneNumber && errors.phoneNumber}
                                InputProps={{
                                    startAdornment: (
                                        <IconButton disabled>
                                            <LocalPhoneIcon />
                                        </IconButton>
                                    ),
                                }}
                                disabled={type === 'create' ? false : true}
                                widthField='95%'
                            />

                            {type === 'create' && (
                                <>
                                    <TextFieldCustom
                                        label='Mật khẩu'
                                        variant='outlined'
                                        placeholder='Nhập mật khẩu'
                                        name='password'
                                        type={showPassword ? 'text' : 'password'}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.password || ''}
                                        error={!!touched.password && !!errors.password}
                                        helperText={touched.password && errors.password}
                                        InputProps={{
                                            endAdornment: (
                                                <IconButton tabIndex={-1} onClick={handleClickShowPassword}>
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            ),
                                            startAdornment: (
                                                <IconButton disabled>
                                                    <LockIcon />
                                                </IconButton>
                                            ),
                                        }}
                                        fullWidth
                                        widthField='95%'
                                    />
                                    <TextFieldCustom
                                        label='Xác nhận mật khẩu'
                                        variant='outlined'
                                        placeholder='Nhập mật khẩu'
                                        name='confirmPassword'
                                        type={showRePassword ? 'text' : 'password'}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.confirmPassword || ''}
                                        error={!!touched.confirmPassword && !!errors.confirmPassword}
                                        helperText={touched.confirmPassword && errors.confirmPassword}
                                        InputProps={{
                                            endAdornment: (
                                                <IconButton tabIndex={-1} onClick={handleClickShowRePassword}>
                                                    {showRePassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            ),
                                            startAdornment: (
                                                <IconButton disabled>
                                                    <LockIcon />
                                                </IconButton>
                                            ),
                                        }}
                                        fullWidth
                                        widthField='95%'
                                    />
                                </>
                            )}
                            {type === 'edit' && (
                                <>
                                    <TextFieldCustom
                                        label='Mật khẩu mới'
                                        variant='outlined'
                                        placeholder='Nhập mật khẩu mới'
                                        name='newPassword'
                                        type={showNewPassword ? 'text' : 'password'}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.newPassword || ''}
                                        error={!!touched.newPassword && !!errors.newPassword}
                                        helperText={touched.newPassword && errors.newPassword}
                                        InputProps={{
                                            endAdornment: (
                                                <IconButton tabIndex={-1} onClick={handleClickShowNewPassword}>
                                                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            ),
                                            startAdornment: (
                                                <IconButton disabled>
                                                    <LockIcon />
                                                </IconButton>
                                            ),
                                        }}
                                        fullWidth
                                        widthField='95%'

                                    />
                                    <TextFieldCustom
                                        label='Xác nhận mật khẩu'
                                        variant='outlined'
                                        placeholder='Nhập mật khẩu mới'
                                        name='confirmNewPassword'
                                        type={showRenewPassword ? 'text' : 'password'}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.confirmNewPassword || ''}
                                        error={!!touched.confirmNewPassword && !!errors.confirmNewPassword}
                                        helperText={touched.confirmNewPassword && errors.confirmNewPassword}
                                        InputProps={{
                                            endAdornment: (
                                                <IconButton tabIndex={-1} onClick={handleClickShowRenewPassword}>
                                                    {showRenewPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            ),
                                            startAdornment: (
                                                <IconButton disabled>
                                                    <LockIcon />
                                                </IconButton>
                                            ),
                                        }}
                                        fullWidth
                                        widthField='95%'
                                    />
                                </>
                            )}
                            <Box justifyContent='end' sx={{ width: { xs: '100% !important', sm: '95% !important' } }}>
                                <Button
                                    sx={{ minWidth: '96px', p: '8px 16px', color: 'white', fontWeight: 600 }}
                                    type='submit'
                                    variant='contained'
                                    color='success'
                                    endIcon={!loading && <Send />}
                                    disabled={loading}
                                >
                                    {loading ? <CircularProgress size={24} color='inherit' /> : 'Gửi'}
                                </Button>
                                <Button
                                    sx={{ minWidth: '96px', p: '8px 16px' }}
                                    variant='outlined'
                                    color='inherit'
                                    startIcon={<Cancel />}
                                    onClick={handleClose}
                                    disabled={loading}
                                >
                                    Huỷ
                                </Button>
                            </Box>
                        </Box>
                    )
                }}
            </Formik>
        </ModalCustom>
    )
}

export default ModalCreateEditUser
