import moment from 'moment'
import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import {
    Modal,
    Box,
    Typography,
    Card,
    CardActionArea,
    CardMedia,
    CardContent,
    useTheme,
    CircularProgress,
    IconButton,
    Rating,
    Button,
    TextField,
    Select,
    MenuItem,
    Chip,
    InputAdornment,
    Popover,
    List,
    FormHelperText,
    FormControl,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
} from '@mui/material'
import images from '../../../assets/images'
import { Badge } from 'antd'
import { tokens } from '../../../theme'
import request from '../../../utils/api/request'
import {
    admin_update_clean,
    admin_update_permanent_taskbooking,
    admin_update_taskBooking,
    create_permanent_task_by_admin,
    get_clean_booking_rating_by_id,
    get_cleanbookings_price,
    get_permanent_taskbooking_price,
    get_price_for_taskbooking,
    get_services,
    get_task_booking_rating_by_id,
    get_taskbooking_from_permanent_taskbookings,
} from '../../../utils/api/apiList'
import { Close, Edit, Phone, Save } from '@mui/icons-material'
import { processStatus } from '../../prefixData'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DatePicker, TimePicker } from '@mui/x-date-pickers'
import { useDispatch } from 'react-redux'
import getEventUpdateTaskBookingSlice from '../../../redux/features/getEventUpdateTaskBookingSlice'
import { message } from 'antd'
import openMessageNotification from '../../notification/message'
import { Link } from 'react-router-dom'
import { routerLinks } from '../../../routes/constant'
import { formatNumber, parseNumber } from '../../prefixData'
import Skeleton from '@mui/material/Skeleton'
import { baseURL } from '../../../utils/api/baseURL'
import { Formik } from 'formik'
import { validateModalCreateEditTask } from '../../validate/validateModalCreateEditTask'
import getEventUpdatePermanentTaskSlice from '../../../redux/features/getEventUpdatePermanentTaskSlice'
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices'
import connectSocket from '../../../utils/api/connectSocket'
import { admin_update_task } from '../../../utils/api/apiSocket'

function ModalDetails({ modalName, dataDetails, open, onClose, handleOpenCalendar, dataCalendar, fetchData, fetchDataDetails }) {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    // const [loading, setLoading] = useState(true)
    const [dataModal, setDataModal] = useState(dataDetails)
    const [rate, setRate] = useState(0)
    const tokenStorage = localStorage.getItem('token')

    const dispatch = useDispatch()

    const [isEditing, setIsEditing] = useState(false)
    const [formState, setFormState] = useState({
        time: null,
        estimateTime: 0,
        date: null,
        price: null,
        endTime: null,
        startTime: null,
        estimatePeople: null,
        address: null,
        name: null,
        phoneNumber: null,
        option: null,
        package: Number,
        selectedDays: [],
    })
    const [isPermanentTask, setIsPermanentTask] = useState(null)
    const [dataPartnerPermanentTask, setDataPartnerPermanentTask] = useState(null)
    const [isLoadingPartner, setIsLoadingPartner] = useState(true)
    const [isLoadingInfo, setIsLoadingInfo] = useState(true)
    const [services, setServices] = useState([])
    const [isLoadingFillPrice, setIsLoadingFillPrice] = useState(false)
    const [anchorEl, setAnchorEl] = useState(false)
    const [totalDay, setTotalDay] = useState(null)
    const socket = connectSocket()

    // ref setField
    const setFieldValueRef = useRef()

    // check trạng thái change trên các input
    const [time, setTime] = useState(moment())
    const [day, setDay] = useState(null)
    const [estimateTime, setEstimateTime] = useState(null)
    const [option, setOption] = useState([])
    const [estimatePeople, setEstimatePeople] = useState(2)
    const [selectedDays, setSelectedDays] = useState([])

    // reset data
    const resetDataDetails = () => {
        setDataModal({})
        setFormState({})
        setDataPartnerPermanentTask(null)
    }

    // id company
    const companyId = localStorage.getItem('companyId')

    //message
    const [messageApi, messageContextHolder] = message.useMessage()

    // dữ liệu chung
    const fetchDataRating = () => {
        switch (modalName) {
            case 'clean':
                fetchCleanRating()
                break
            case 'taskBooking':
                fetchTaskRating()
                break
            case 'permanentTask':
                fetchDataPartnerPermanentTask()
                break
            default:
                break
        }
    }

    //get service
    const fetchServices = () => {
        request
            .post(get_services, {})
            .then((res) => {
                // console.log(res)
                setServices(res.data.data.docs)
            })
            .catch((err) => {
                console.error(err)
            })
    }
    useEffect(() => {
        fetchServices()
    }, [])

    //form state
    const formatTime = (timeInMinutes) => moment().startOf('day').add(timeInMinutes, 'minutes').format('HH:mm')
    const updateFormState = () => {
        const isClean = modalName === 'clean'
        const address = isClean ? dataDetails?.cleanId?.address : dataDetails?.taskId?.address
        // const name = isClean ? dataDetails?.cleanId?.name : dataDetails?.taskId?.name
        const name = dataDetails.hasOwnProperty('userFromAdminOrder') ? dataDetails.userFromAdminOrder.name : dataDetails?.userId?.fullName
        const phoneNumber = dataDetails.hasOwnProperty('userFromAdminOrder')
            ? dataDetails.userFromAdminOrder.phoneNumber
            : dataDetails?.userId?.phoneNumber

        let estimateTime = isClean ? dataDetails?.cleanId?.estimateTime : dataDetails?.taskId?.estimateTime
        const combinedTime = (estimateTime || 0) + (dataDetails?.time || 0)
        const option = isClean ? dataDetails?.cleanId?.option : dataDetails?.taskId?.option

        const convertPermanentDates = modalName === 'permanentTask' ? dataDetails?.date?.map((day) => moment(day).day()) : []
        const permanentDays = [...new Set(convertPermanentDates)] //loc các phần tử trùng trong arr

        setFormState((prevState) => ({
            ...prevState,
            endTime: formatTime(combinedTime),
            estimateTime: moment.duration(estimateTime, 'minutes').asHours(),
            time: moment()
                .startOf('day')
                .add(dataDetails?.time || 0, 'minutes'),
            date: moment(dataDetails?.date),
            price: dataDetails?.price,
            startTime: formatTime(dataDetails?.time || 0),
            estimatePeople: dataDetails?.cleanId?.estimatePeople,
            address,
            name,
            phoneNumber,
            option: option ? option.map((opt) => opt._id) : [],
            selectedDays: permanentDays,
            package: dataDetails?.package || null,
        }))

        // set state mac dinh cho cac input khi edit
        setTime(dataDetails.time)
        setDay(dataDetails.date)
        setEstimateTime(moment.duration(estimateTime, 'minutes').asHours())
        setOption(option ? option.map((opt) => opt._id) : [])
        setSelectedDays(permanentDays)
        setTotalDay(dataDetails?.date?.length || null)
        setEstimatePeople(dataDetails?.cleanId?.estimatePeople)
    }

    useEffect(() => {
        fetchDataRating()
        updateFormState()
        setIsLoadingInfo(true)
        if (Object.entries(dataDetails).length > 0) {
            // setServiceShow(dataDetails?.taskId?.option?.filter((item1) => services?.some((item2) => item2._id === item1._id)))

            setDataModal(dataDetails)
            setIsPermanentTask(dataDetails.hasOwnProperty('fromPermanentTaskBooking'))
            const timer = setTimeout(() => {
                setIsLoadingInfo(false)
            }, [700])
            return () => clearTimeout(timer)
        }
    }, [modalName, dataDetails])

    // useEffect(() => {
    //     //fetchData price task
    //     if (modalName === 'taskBooking') {
    //         if (day || time || estimateTime || option) {
    //             setIsLoadingFillPrice(true)
    //             const timerFillPrice = setTimeout(() => {
    //                 const data = { day, time, estimateTime, option }
    //                 console.log(data)

    //                 fetchFillPrice(data, 'eventFillPriceTask')
    //             }, 1000)

    //             return () => clearTimeout(timerFillPrice)
    //         }
    //     } else if (modalName === 'clean') {
    //         //fetchData price clean
    //         if (day && time && estimateTime && estimatePeople) {
    //             setIsLoadingFillPrice(true)
    //             const timerFillPrice = setTimeout(() => {
    //                 const data = { day, time, estimateTime, estimatePeople }
    //                 fetchFillPrice(data, 'eventFillPriceClean')
    //             }, 1000)
    //             return () => clearTimeout(timerFillPrice)
    //         }
    //     } else if (modalName === 'permanentTask') {
    //         // fetchData price PermanentTask
    //         // if (selectedDays || estimateTime || totalDay || option) {
    //         //     setIsLoadingFillPrice(true)
    //         //     // console.log({ totalDay })
    //         //     const timerFillPrice = setTimeout(() => {
    //         //         const data = { estimateTime, totalDay }
    //         //         console.log(data)
    //         //         // fetchFillPrice(data, 'eventFillPricePermanentTask')
    //         //     }, 1000)
    //         //     return () => clearTimeout(timerFillPrice)
    //         // }
    //     } else {
    //         return
    //     }
    // }, [day, time, estimateTime, option, selectedDays])

    // const handleInputChange = () => {}

    const handleInputChange = (name, value) => {
        switch (name) {
            case 'day':
                setDay(value)
                break
            case 'time':
                setTime(value)
                break
            case 'estimateTime':
                setEstimateTime(value)
                break
            case 'option':
                setOption(value)
                break
            case 'estimatePeople':
                setEstimatePeople(value)
                break
            case 'package':
                setTotalDay(value)
                break
            default:
                break
        }

        // Call fetchFillPrice with updated data
        const data = {
            //task
            day: name === 'day' ? value : day,
            time: name === 'time' ? value : time,
            //task & permanent
            option: name === 'option' ? value : option,
            //all
            estimateTime: name === 'estimateTime' ? value : estimateTime,
            //clean
            estimatePeople: name === 'estimatePeople' ? value : estimatePeople,
            //permanent
            totalDay: name === 'package' ? value : totalDay,
        }

        // check modal & fill price
        setIsLoadingFillPrice(true)
        if (modalName === 'taskBooking') {
            const timerFillPrice = setTimeout(() => {
                delete data.estimatePeople
                delete data.totalDay
                fetchFillPrice(data, 'eventFillPriceTask')
            }, 1000)

            return () => clearTimeout(timerFillPrice)
        }
        if (modalName === 'clean') {
            const timerFillPrice = setTimeout(() => {
                delete data.option
                delete data.totalDay
                data.estimatePeople > 1 && fetchFillPrice(data, 'eventFillPriceClean')
            }, 1000)

            return () => clearTimeout(timerFillPrice)
        }
        if (modalName === 'permanentTask') {
            const timerFillPrice = setTimeout(() => {
                delete data.estimatePeople
                fetchFillPrice(data, 'eventFillPricePermanentTask')
            }, 1000)

            return () => clearTimeout(timerFillPrice)
        }
    }

    //fill gia
    const fetchFillPrice = (data, eventFillPrice) => {
        let time = typeof data.time !== 'number' ? moment(data.time).hours() * 60 + moment(data.time).minutes() : data.time

        //dafault
        data.lat = 16.4513285 // Huế
        data.lng = 102.5704174 // Huế
        data.distance = 10000 // mặc định

        // + tiền dịch vụ cộng thêm
        let prices = 0
        if (modalName === 'taskBooking') {
            prices = data.option.reduce((total, _id) => {
                const service = services.find((service) => service._id === _id)
                return total + (service ? service.price : 0)
            }, prices)
        }
        if (modalName === 'permanentTask') {
            const result = data.option.reduce((total, _id) => {
                const service = services.find((service) => service._id === _id)
                console.log({ service })

                return total + (service ? service.price : 0)
            }, prices)
            console.log('priceService', result * data.totalDay)

            prices = result * data.totalDay
        }

        // delete data?.option
        console.log(prices)

        //api
        let api

        switch (eventFillPrice) {
            case 'eventFillPriceTask':
                api = get_price_for_taskbooking
                data.time = time
                break
            case 'eventFillPricePermanentTask':
                api = get_permanent_taskbooking_price
                break
            case 'eventFillPriceClean':
                api = get_cleanbookings_price
                data.time = time
                break
            default:
                break
        }

        request
            .post(api, data)
            .then((response) => {
                if (modalName !== 'clean') {
                    setFieldValueRef.current('price', response.data.data + prices)
                } else {
                    setFieldValueRef.current('price', response.data.data)
                }
            })
            .catch((error) => {
                console.error('Error fetching price:', error)
            })
            .finally(() => {
                setIsLoadingFillPrice(false)
            })
    }

    // fetchData partner for permanentTask
    const fetchDataPartnerPermanentTask = () => {
        const today = new Date().toISOString().split('T')[0] // cắt chuỗi, lâý ngày
        // console.log(today)
        request
            .post(get_taskbooking_from_permanent_taskbookings, { permanentTaskBookingId: dataDetails._id })
            .then((res) => {
                const data = res.data.data
                // console.log(data)
                // if (data.length > 0) {
                //     const result = data.find((elm) => {
                //         const updatedAtDate = new Date(elm.updatedAt).toISOString().split('T')[0]
                //         // console.log({ updatedAtDate })
                //         return updatedAtDate === today
                //     })
                //     // console.log({ result })
                //     if (result) {
                //         console.log({ result })
                //         setDataPartnerPermanentTask(result.taskerId)
                //     }
                //     const timer = setTimeout(() => {
                //         setIsLoadingPartner(false)
                //     }, 500)

                //     return () => clearTimeout(timer)
                // }
            })
            .catch((err) => console.log(err))
    }

    // fetch Task Rating
    const fetchTaskRating = () => {
        request.setAuthToken(tokenStorage)
        request
            .post(get_task_booking_rating_by_id, {
                taskBookingId: dataDetails._id,
            })
            .then((res) => {
                setRate(res.data.data?.taskBookingRating || 0)
            })
            .catch((err) => console.log(err))
    }

    // fetch clean rating
    const fetchCleanRating = () => {
        request.setAuthToken(tokenStorage)
        request
            .post(get_clean_booking_rating_by_id, {
                cleanBookingId: dataDetails._id,
            })
            .then((res) => {
                setRate(res.data.data?.cleanBookingRating || 0)
            })
            .catch((err) => console.log(err))
    }

    // sửa đơn
    const handleOpenEdit = () => {
        setIsEditing(true)
    }
    const handleCloseEdit = () => {
        setIsEditing(false)
        onClose()
    }

    const fetchUpdate = (api, data, modalName) => {
        request
            .post(api, { ...data })
            .then((res) => {
                //mess
                openMessageNotification(messageApi, 'success', 'capnhatdonhang')

                const dataRs = res.data.data
                console.log({ dataRs })
                console.log({ data })

                // updateFormState()
                fetchData()
                fetchDataDetails(res.data.data._id)

                if (modalName === 'clean') {
                    !dataModal.hasOwnProperty('userFromAdminOrder') &&
                        socket.emit(admin_update_task, {
                            _id: dataModal._id, //id đơn
                            userId: dataModal.userId._id, //id của user
                            note: 'Admin sửa đơn tổng vệ sinh',
                            type: 'editCleanBooking',
                        })
                } else if (modalName === 'taskBooking') {
                    !dataModal.hasOwnProperty('userFromAdminOrder') &&
                        socket.emit(admin_update_task, {
                            _id: dataModal._id, //id đơn
                            userId: dataModal.userId._id, //id của user
                            note: 'Admin sửa đơn ca lẻ',
                            type: 'editTaskBooking',
                        })
                } else {
                    // dispatch(getEventUpdatePermanentTaskSlice.actions.eventUpdatePermanentTask(dataRs))
                    // setDataModal({
                    //     ...dataModal,
                    //     price: dataRs.price,
                    //     date: dataRs.date,
                    //     time: data.time,
                    //     taskId: {
                    //         ...dataModal.taskId,
                    //         address: data.address,
                    //         estimateTime: Number(formState.estimateTime) * 60,
                    //         name: dataRs.userFromAdminOrder.name,
                    //     },
                    // })
                    // fetchData()
                    // fetchDataDetails(res.data.data._id)

                    !dataModal.hasOwnProperty('userFromAdminOrder') &&
                        socket.emit(admin_update_task, {
                            _id: dataModal._id, //id đơn
                            userId: dataModal.userId._id, //id của user
                            note: 'Admin sửa đơn ca cố định',
                            type: 'editPermanentBooking',
                        })
                }

                // const formattedTime = moment().startOf('day').add(data.time, 'minutes').format('HH:mm')
                // const formattedEndTime = moment()
                //     .startOf('day')
                //     .add(data.time + data.estimateTime, 'minutes')
                //     .format('HH:mm')

                // setFormState({
                //     ...formState,
                //     date: moment(data.date),
                //     time: moment().startOf('day').add(data.time, 'minutes'),
                //     estimateTime: moment.duration(data.estimateTime, 'minutes').asHours(),
                //     endTime: formattedEndTime,
                //     startTime: formattedTime,
                //     name: data.userFromAdminOrder.name,
                //     address: data.address,
                //     estimatePeople: data?.estimatePeople,
                //     phoneNumber: data.userFromAdminOrder.phoneNumber,
                //     price: dataRs.price,
                //     package: dataRs?.package,
                // })
            })
            .catch((err) => {
                console.log(err)
            })
        // .finally(() => onClose())
    }

    //handle edit
    const handleSubmitEdit = (values) => {
        console.log(values)
        setIsEditing(false)
        // conver number to string and remove commas
        const price = values.price.toString().replace(/,/g, '')
        const formattedDate = moment(values.date).toISOString()

        // convert to minutes
        const time = moment(values.time)
        const hours = time.hours()
        const minutes = time.minutes()
        const totalMinutes = hours * 60 + minutes
        // console.log(totalMinutes)

        const data = {
            _id: dataModal._id,
            address: values.address,
            time: totalMinutes,
            date: formattedDate,
            price: Number(price),
            estimateTime: Number(values.estimateTime) * 60,
            // check `userFromAdminOrder`
            ...(dataModal.hasOwnProperty('userFromAdminOrder')
                ? {
                      userFromAdminOrder: {
                          name: values.name,
                          phoneNumber: values.phoneNumber,
                      },
                  }
                : { userId: dataModal.userId._id }),
            // userId: dataModal?.userId?._id,
            // userFromAdminOrder: {
            //     name: values.name,
            //     phoneNumber: values.phoneNumber,
            // },
            companyId, //flow nhiều công ty
        }
        // console.log(data)

        if (modalName === 'clean') {
            data.cleanId = dataModal?.cleanId?._id
            data.estimatePeople = Number(values.estimatePeople)
            fetchUpdate(admin_update_clean, data, modalName)
        } else if (modalName === 'taskBooking') {
            data.taskId = dataModal?.taskId?._id
            data.option = values.option

            fetchUpdate(admin_update_taskBooking, data, modalName)
        } else {
            data.taskId = dataModal?.taskId?._id
            data.option = values.option

            // Calculate working days (ca cố định)
            const startDate = moment() // Ngày đặt đơn
            const totalDays = values.package * 30 // Mặc định là 30 ngày * gói
            const selectedTime = moment(values.time)
            const workingDays = calculateWorkingDays(startDate, totalDays, selectedDays, selectedTime)
            console.log('workingDays', workingDays)

            data.date = workingDays
            data.package = values.package

            console.log('data', data)
            fetchUpdate(admin_update_permanent_taskbooking, data)
        }
    }

    // close modal
    const handleCloseModal = () => {
        onClose()
        setRate(0)
        setIsEditing(false)
        resetDataDetails()
        setIsLoadingInfo(true)
    }

    const calculateWorkingDays = (startDate, totalDays, selectedDays, selectedTime) => {
        let workingDays = []
        // let validationDate = moment(startDate).add(2, 'days') // Ngày xác thực đơn sau 2 ngày từ ngày đặt
        let validationDate = moment(startDate)
        console.log(validationDate.format('DD-MM'))
        let firstWorkingDay = validationDate.clone()
        // console.log(firstWorkingDay.format('MM-DD'))

        // Tìm ngày bắt đầu làm việc là ngày hợp lệ đầu tiên trong danh sách ngày chọn
        while (!selectedDays.includes(firstWorkingDay.day())) {
            firstWorkingDay.add(1, 'day')
        }
        // console.log(firstWorkingDay)

        //
        let currentDate = firstWorkingDay.clone()
        let endDate = currentDate.clone().add(totalDays - 1, 'days')
        // console.log(currentDate.day())

        while (currentDate.isSameOrBefore(endDate)) {
            if (selectedDays.includes(currentDate.day())) {
                // Nếu currentDate không phải là ngày hiện tại, thì thời gian luôn hợp lệ.
                const isSameDay = currentDate.isSame(moment(), 'day')

                //Nếu currentDate là ngày hiện tại, thì cần kiểm tra xem selectedTime có sau thời điểm hiện tại hay không.
                const isTimeValid = !isSameDay || selectedTime.isAfter(moment())

                if (isTimeValid) {
                    workingDays.push(moment(currentDate))
                }
            }
            currentDate.add(1, 'day')
        }

        return workingDays
    }

    //dropdown services
    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handlePopoverClose = () => {
        setAnchorEl(null)
    }

    //service
    const serviceShow = dataModal?.taskId?.option.filter((item1) => services.some((item2) => item2._id === item1._id))

    // items estimateTime
    const estimateTimeItems = () => {
        const hours = modalName === 'clean' ? [2, 3, 4, 5, 6, 7, 8] : [2, 3, 4]
        return hours.map((hour) => (
            <MenuItem value={hour} key={hour}>
                <Typography>{hour} giờ</Typography>
            </MenuItem>
        ))
    }

    const RenderDayBoxes = ({ name, values }) => {
        const days = ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7']
        //chon danh sách ngày trong tuần
        const handleDayClick = (dayIndex) => {
            const updatedSelectedDays = selectedDays.includes(dayIndex) ? selectedDays.filter((day) => day !== dayIndex) : [...selectedDays, dayIndex]

            setSelectedDays(updatedSelectedDays)
            setFieldValueRef.current(name, updatedSelectedDays)

            // Tính tổng số ngày làm việc dựa trên số ngày được chọn và gói tháng
            const packageDuration = values.package * 30
            const selectedWorkdays = updatedSelectedDays.length

            // Số tuần trong gói
            const weeks = Math.floor(packageDuration / 7)
            const remainingDays = packageDuration % 7

            // Tính toán tổng số ngày làm việc
            let totalDay = selectedWorkdays * weeks

            // Tìm ngày đầu tiên của gói để kiểm tra ngày còn lại
            let startDate = moment()
            let firstDay = startDate.day() // index ngày trong tuần

            // Thêm các ngày còn lại nếu trùng với ngày làm việc đã chọn
            for (let i = 0; i < remainingDays; i++) {
                if (updatedSelectedDays.includes((firstDay + i) % 7)) {
                    totalDay++
                }
            }
            setTotalDay(totalDay)

            handleInputChange('package', totalDay)
        }

        return days.map((day, dayIndex) => (
            <>
                <Box key={dayIndex} display='flex' justifyContent='space-evenly' gap='5px'>
                    <IconButton
                        sx={{
                            width: 40,
                            height: 40,
                            bgcolor: selectedDays.includes(dayIndex) ? '#4e6ff4' : '#6b6e7c', //435cc2
                            fontSize: '14px',
                            color: 'white',
                            '&:hover': {
                                bgcolor: theme.palette.mode === 'dark' ? '#4662d6' : '#2b3b85d1',
                            },
                        }}
                        onClick={() => handleDayClick(dayIndex)}
                    >
                        {day}
                    </IconButton>
                </Box>
            </>
        ))
    }

    // items package
    const packageItems = () => {
        const months = [1, 2, 3, 4, 5, 6]
        return months.map((month) => (
            <MenuItem value={month} key={month}>
                {month} tháng
            </MenuItem>
        ))
    }

    //style badge
    const processType = (status) => {
        switch (status) {
            case 0:
            case 1:
                return 'processing'
            case 2:
                return 'info'
            default:
                return 'error'
        }
    }
    const processColor = (status) => {
        switch (status) {
            case 0:
                return '#32bb32'
            case 1:
                return '#d8990c'
            case 2:
                return '#5454ed'
            default:
                return 'red'
        }
    }

    console.log({ dataDetails })

    return (
        <Modal
            open={open}
            onClose={(event, reason) => {
                if (isEditing) {
                    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
                        return
                    }
                }
                handleCloseModal()
            }}
            style={{ backdropFilter: 'blur(5px)', zIndex: '99' }}
        >
            <Box
                sx={{
                    borderRadius: '5px',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    // width: 700,
                    width: 'auto',
                    bgcolor: theme.palette.mode === 'dark' ? '#1F2A40' : '#e0e0e0',
                    border: '1px solid rgba(0, 0, 0, 0.1) 0px 4px 12px',
                    boxShadow: 24,
                    p: 4,
                    pt: '15px',
                }}
            >
                {messageContextHolder}
                <Box display='flex' justifyContent='space-between' alignItems='center' mb='5px'>
                    <Typography variant='h4' color={colors.grey[100]}>
                        CHI TIẾT ĐƠN HÀNG
                    </Typography>
                    {/* {isPermanentTask && (
                        <Link
                            state={{ _id: dataModal.fromPermanentTaskBooking }}
                            to={routerLinks.PermanentTask}
                            style={{ color: colors.blueAccent[500] }}
                        >
                            Đến trang quản lý
                        </Link>
                    )} */}
                </Box>
                <IconButton
                    sx={{
                        position: 'absolute',
                        top: '0',
                        right: '0',
                    }}
                    onClick={handleCloseModal}
                >
                    <Close color='disabled' />
                </IconButton>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        // width: '640px',
                        width: modalName === 'permanentTask' ? 'auto' : '690px',
                        height: isEditing || modalName === 'permanentTask' ? 'auto' : '370px',
                        bgcolor: theme.palette.mode === 'dark' ? '#2a435657' : '#b3c4d157',
                        borderRadius: '8px',
                        position: 'relative',
                        pb: '10px',
                        pt: '10px',
                    }}
                >
                    <Formik
                        enableReinitialize={true}
                        initialValues={formState}
                        validationSchema={validateModalCreateEditTask(modalName)}
                        onSubmit={handleSubmitEdit}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue, setFieldError }) => {
                            setFieldValueRef.current = setFieldValue

                            return (
                                <Box
                                    display='flex'
                                    maxHeight='500px'
                                    overflow='auto'
                                    flex='1'
                                    height='100%'
                                    sx={{
                                        overflowX: 'hidden',
                                    }}
                                >
                                    <Box
                                        component='form'
                                        onSubmit={handleSubmit}
                                        style={{
                                            flex: 1,
                                            height: '100%',
                                            minWidth: '400px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: isEditing ? '20px' : '15px',
                                            padding: '20px',
                                            // justifyContent: 'center',
                                            justifyContent: 'flex-start',
                                        }}
                                    >
                                        {isLoadingInfo ? (
                                            <>
                                                <Skeleton variant='text' width='80%' height={30} />
                                                <Skeleton variant='rectangular' width='100%' height={118} />
                                                <Skeleton variant='text' width='60%' height={30} />
                                                <Skeleton variant='text' width='50%' height={30} />
                                            </>
                                        ) : (
                                            <>
                                                {(dataModal?.status === 0 || dataModal?.status === 1) &&
                                                    (isEditing ? (
                                                        <Box>
                                                            <Button
                                                                variant='contained'
                                                                color='success'
                                                                endIcon={<Save />}
                                                                type='submit'
                                                                sx={{ mr: '10px' }}
                                                            >
                                                                Lưu
                                                            </Button>
                                                            <Button variant='outlined' color='inherit' onClick={handleCloseEdit}>
                                                                Huỷ
                                                            </Button>
                                                        </Box>
                                                    ) : (
                                                        <Button
                                                            variant='contained'
                                                            color='warning'
                                                            endIcon={<Edit />}
                                                            onClick={handleOpenEdit}
                                                            sx={{
                                                                fontWeight: 600,
                                                            }}
                                                        >
                                                            Sửa đơn
                                                        </Button>
                                                    ))}

                                                <Box display='flex' alignItems='center'>
                                                    <Typography variant='h5' fontWeight='600' minWidth='110px'>
                                                        Khách hàng:
                                                    </Typography>
                                                    {isEditing ? (
                                                        <TextField
                                                            fullWidth
                                                            name='name'
                                                            value={values.name}
                                                            disabled={dataDetails.hasOwnProperty('userFromAdminOrder') ? false : true}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={!!touched.name && !!errors.name}
                                                            helperText={touched.name && errors.name}
                                                        />
                                                    ) : (
                                                        <Typography color={colors.greenLight[300]} fontWeight='600'>
                                                            {values?.name || dataModal?.userId?.fullName}
                                                        </Typography>
                                                    )}
                                                </Box>
                                                <Box display='flex' alignItems='center'>
                                                    <Typography variant='h5' fontWeight='600' minWidth='110px'>
                                                        Số điện thoại:
                                                    </Typography>
                                                    {isEditing ? (
                                                        <TextField
                                                            fullWidth
                                                            name='phoneNumber'
                                                            disabled={dataDetails.hasOwnProperty('userFromAdminOrder') ? false : true}
                                                            value={values.phoneNumber}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={!!touched.phoneNumber && !!errors.phoneNumber}
                                                            helperText={touched.phoneNumber && errors.phoneNumber}
                                                        />
                                                    ) : (
                                                        <Typography color={colors.greenLight[300]} fontWeight='600'>
                                                            {values.phoneNumber || dataModal?.userId?.phoneNumber}
                                                        </Typography>
                                                    )}
                                                </Box>
                                                <Box display='flex' alignItems='center'>
                                                    <Typography variant='h5' fontWeight='600' alignSelf='flex-start' minWidth='110px'>
                                                        Địa chỉ:
                                                    </Typography>
                                                    {isEditing ? (
                                                        <TextField
                                                            multiline
                                                            rows={3}
                                                            fullWidth
                                                            name='address'
                                                            value={values.address}
                                                            // disabled={dataDetails.hasOwnProperty('userFromAdminOrder') ? false : true}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={!!touched.address && !!errors.address}
                                                            helperText={touched.address && errors.address}
                                                        />
                                                    ) : (
                                                        <Typography color={colors.greenLight[300]} fontWeight='600'>
                                                            {values.address}
                                                        </Typography>
                                                    )}
                                                </Box>
                                                {isEditing && (
                                                    <Box display='flex' alignItems='center'>
                                                        <Typography variant='h5' fontWeight='600' minWidth='110px'>
                                                            Ngày:
                                                        </Typography>
                                                        {modalName === 'permanentTask' ? (
                                                            <Box display='flex' flexDirection='column'>
                                                                <Box display='flex' gap='20px'>
                                                                    <Box display='flex' gap='17px'>
                                                                        <RenderDayBoxes name='selectedDays' values={values} />
                                                                    </Box>
                                                                </Box>
                                                                {touched.selectedDays && errors.selectedDays && (
                                                                    <FormHelperText error>{errors.selectedDays}</FormHelperText>
                                                                )}
                                                            </Box>
                                                        ) : (
                                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                <DatePicker
                                                                    name='date'
                                                                    value={values.date}
                                                                    onChange={(newValue) => {
                                                                        setFieldValue('date', newValue)
                                                                        const dayConvert = Number(moment(newValue).day())
                                                                        setDay(dayConvert)
                                                                        // handleInputChange('day', dayConvert)

                                                                        if (moment(newValue).isAfter(moment(), 'day')) {
                                                                            setFieldValue('time', null)
                                                                            setTime(null)
                                                                            setFieldError('time', '')
                                                                        } else if (moment(newValue).isSame(moment(), 'day')) {
                                                                            if (moment(values.time).isBefore(moment())) {
                                                                                setFieldValue('time', null)
                                                                                setTime(null)
                                                                                setFieldError('time', '')
                                                                            }
                                                                        }
                                                                    }}
                                                                    minDate={moment()}
                                                                    slotProps={{
                                                                        textField: {
                                                                            variant: 'outlined',
                                                                            margin: 'normal',
                                                                            error: touched.date && !!errors.date,
                                                                            helperText: touched.date && errors.date,
                                                                            fullWidth: true,
                                                                        },
                                                                    }}
                                                                    sx={{
                                                                        '& .MuiButtonBase-root.MuiPickersDay-root': {
                                                                            fontSize: '14px !important',
                                                                        },
                                                                        mt: 0,
                                                                    }}
                                                                />
                                                            </LocalizationProvider>
                                                        )}
                                                    </Box>
                                                )}
                                                <Box display='flex' alignItems='center'>
                                                    <Typography variant='h5' fontWeight='600' minWidth='110px'>
                                                        Thời gian:
                                                    </Typography>
                                                    {isEditing ? (
                                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                                            <TimePicker
                                                                label='Bắt đầu'
                                                                name='time'
                                                                value={values.time}
                                                                onChange={(newValue) => {
                                                                    const selectedDate = moment(values.date)
                                                                    const selectedTime = moment(newValue)
                                                                    const currentTime = moment()

                                                                    if (selectedDate.isSame(currentTime, 'day')) {
                                                                        if (selectedTime.isAfter(currentTime)) {
                                                                            setFieldValue('time', newValue)
                                                                            handleInputChange('time', newValue)

                                                                            setTime(newValue)
                                                                        } else {
                                                                            setFieldError('time', 'Thời gian phải lớn hơn thời điểm hiện tại')
                                                                        }
                                                                    } else {
                                                                        setFieldValue('time', newValue)
                                                                        handleInputChange('time', newValue)
                                                                        setFieldError('time', '')

                                                                        setTime(newValue)
                                                                    }
                                                                }}
                                                                timeSteps={{ minutes: 30 }}
                                                                minTime={moment(values?.date).isSame(moment(), 'day') ? moment() : undefined}
                                                                ampm={false}
                                                                slotProps={{
                                                                    textField: {
                                                                        variant: 'outlined',
                                                                        margin: 'normal',
                                                                        error: touched.time && !!errors.time,
                                                                        helperText: touched.time && errors.time,
                                                                        fullWidth: true,
                                                                    },
                                                                }}
                                                                sx={{
                                                                    '& .MuiButtonBase-root.MuiPickersDay-root': {
                                                                        fontSize: '14px !important',
                                                                    },
                                                                    flex: 1,
                                                                    mt: 0,
                                                                    mb: 0,
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    ) : (
                                                        <Typography color={colors.greenLight[300]} fontWeight='600'>
                                                            {values.startTime + ' - ' + values.endTime + ' '}
                                                            {modalName !== 'permanentTask' ? (
                                                                moment(values?.date).format('DD/MM/YYYY')
                                                            ) : (
                                                                <Box
                                                                    display='inline-block'
                                                                    sx={{
                                                                        cursor: 'pointer',
                                                                        color: colors.blueAccent[400],
                                                                    }}
                                                                    onClick={() => {
                                                                        handleOpenCalendar(dataCalendar)
                                                                        handleCloseModal()
                                                                    }}
                                                                >
                                                                    xem lịch
                                                                </Box>
                                                            )}
                                                        </Typography>
                                                    )}
                                                </Box>
                                                {isEditing && (
                                                    <Box display='flex' alignItems='center'>
                                                        <Typography variant='h5' fontWeight='600' minWidth='110px'>
                                                            Số giờ làm:{' '}
                                                        </Typography>
                                                        <Select
                                                            name='estimateTime'
                                                            placeholder='Chọn số giờ làm'
                                                            value={values.estimateTime}
                                                            onBlur={handleBlur}
                                                            onChange={(e) => {
                                                                setFieldValue('estimateTime', Number(e.target.value))
                                                                setEstimateTime(Number(e.target.value) * 60)
                                                                handleInputChange('estimateTime', Number(e.target.value))
                                                            }}
                                                            error={!!touched.estimateTime && !!errors.estimateTime}
                                                            fullWidth
                                                        >
                                                            {estimateTimeItems()}
                                                        </Select>
                                                    </Box>
                                                )}
                                                {modalName === 'clean' ? (
                                                    isEditing ? (
                                                        <Box display='flex' alignItems='center'>
                                                            <Typography variant='h5' fontWeight='600' minWidth='110px'>
                                                                Số công:
                                                            </Typography>
                                                            <TextField
                                                                name='estimatePeople'
                                                                type='number'
                                                                value={values.estimatePeople || ''}
                                                                onBlur={handleBlur}
                                                                onChange={(e) => {
                                                                    handleChange(e)
                                                                    setEstimatePeople(Number(e.target.value))
                                                                    handleInputChange('estimatePeople', Number(e.target.value))
                                                                }}
                                                                error={!!touched.estimatePeople && !!errors.estimatePeople}
                                                                helperText={touched.estimatePeople && errors.estimatePeople}
                                                                fullWidth
                                                            />
                                                        </Box>
                                                    ) : (
                                                        <Box display='flex' alignItems='center'>
                                                            <Typography variant='h5' fontWeight='600' minWidth='110px'>
                                                                Số công:
                                                            </Typography>
                                                            <Typography color={colors.greenLight[300]} fontWeight='600'>
                                                                {dataModal?.cleanId?.estimatePeople}
                                                            </Typography>
                                                        </Box>
                                                    )
                                                ) : (
                                                    <></>
                                                )}
                                                {modalName === 'permanentTask' && (
                                                    <Box>
                                                        <FormControl variant='filled' error={!!touched.package && !!errors.package} fullWidth>
                                                            <Box className='select' width='100%' display='flex'>
                                                                <Typography variant='h5' fontWeight='600' minWidth='110px'>
                                                                    Gói:{' '}
                                                                </Typography>
                                                                {isEditing ? (
                                                                    <Select
                                                                        name='package'
                                                                        placeholder='theo tháng (1 → 12)'
                                                                        value={values.package || ''}
                                                                        onBlur={handleBlur}
                                                                        onChange={(e) => {
                                                                            handleChange(e)
                                                                            const updatedPackage = e.target.value
                                                                            const totalDay = values.selectedDays.length * updatedPackage * 4
                                                                            handleInputChange('package', totalDay)
                                                                            setTotalDay(totalDay)
                                                                        }}
                                                                        error={!!touched.package && !!errors.package}
                                                                        fullWidth
                                                                    >
                                                                        {packageItems()}
                                                                    </Select>
                                                                ) : (
                                                                    <Typography color={colors.greenLight[300]} fontWeight='600'>
                                                                        {values.package} Tháng
                                                                    </Typography>
                                                                )}
                                                            </Box>
                                                            {touched.package && errors.package && <FormHelperText>{errors.package}</FormHelperText>}
                                                        </FormControl>
                                                    </Box>
                                                )}
                                                {isEditing ? (
                                                    modalName === 'clean' ? (
                                                        <></>
                                                    ) : (
                                                        <Box display='flex' alignItems='center'>
                                                            <Typography variant='h5' fontWeight='600' minWidth='110px'>
                                                                Dịch vụ thêm:{' '}
                                                            </Typography>
                                                            <Select
                                                                name='option'
                                                                multiple
                                                                value={values.option || []}
                                                                onBlur={handleBlur}
                                                                onChange={(e) => {
                                                                    handleChange(e)
                                                                    // setOption(e.target.value)

                                                                    handleInputChange('option', e.target.value)
                                                                }}
                                                                renderValue={(selected) => {
                                                                    return (
                                                                        <Box
                                                                            sx={{
                                                                                display: 'flex',
                                                                                flexWrap: 'wrap',
                                                                                gap: 0.5,
                                                                            }}
                                                                        >
                                                                            {selected?.map((value) => {
                                                                                const service = services.find((elm) => elm._id === value)
                                                                                return <Chip key={value} label={service ? service.name : ''} />
                                                                            })}
                                                                        </Box>
                                                                    )
                                                                }}
                                                                fullWidth
                                                            >
                                                                {services.map((elm) => {
                                                                    return (
                                                                        <MenuItem
                                                                            value={elm._id}
                                                                            key={elm._id}
                                                                            sx={{
                                                                                '&.Mui-selected': {
                                                                                    backgroundColor: `rgba(144, 202, 249, 0.16) !important`,
                                                                                    '&:hover': {
                                                                                        backgroundColor:
                                                                                            theme.palette.mode === 'dark'
                                                                                                ? 'rgb(239 239 239 / 12%) !important'
                                                                                                : 'rgba(4, 5, 9, 0.12) !important',
                                                                                    },
                                                                                    '& .name-service': {
                                                                                        color: theme.palette.mode === 'dark' ? 'inherit' : 'black',
                                                                                    },
                                                                                },
                                                                            }}
                                                                        >
                                                                            <span className='name-service'>{elm.name}: </span>
                                                                            <span
                                                                                style={{
                                                                                    color: theme.palette.mode === 'dark' ? '#1ea81e' : 'green',
                                                                                    fontWeight: 600,
                                                                                    marginLeft: '2px',
                                                                                }}
                                                                            >
                                                                                {formatNumber(elm.price)} VNĐ
                                                                            </span>
                                                                        </MenuItem>
                                                                    )
                                                                })}
                                                            </Select>
                                                        </Box>
                                                    )
                                                ) : (
                                                    <></>
                                                )}
                                                {modalName !== 'clean' && !isEditing && serviceShow.length > 0 && (
                                                    <Box display='flex' alignItems='center'>
                                                        <Typography variant='h5' fontWeight='600' alignSelf='flex-start' minWidth='110px'>
                                                            Dịch vụ thêm:
                                                        </Typography>
                                                        <Box>
                                                            {serviceShow.length > 0 &&
                                                                serviceShow.map((service) => (
                                                                    <List
                                                                        key={service._id}
                                                                        sx={{
                                                                            width: '100%',
                                                                            minWidth: '200px',
                                                                            display: 'flex',
                                                                            p: 0,
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            fontWeight={600}
                                                                            display='inline-block'
                                                                            color={colors.orangeAccent[400]}
                                                                        >
                                                                            + {service.name}: {formatNumber(service.price)} VNĐ
                                                                        </Typography>
                                                                    </List>
                                                                    // <ListItem key={service}>
                                                                    //     <ListItemAvatar>
                                                                    //         <Avatar></Avatar>
                                                                    //     </ListItemAvatar>
                                                                    //     <ListItemText primary={service.name} />
                                                                    // </ListItem>
                                                                ))}
                                                        </Box>
                                                        {/* <Box>
                                                            <Typography
                                                                aria-owns={open ? 'popover-services' : undefined}
                                                                aria-haspopup='true'
                                                                fontWeight={600}
                                                                color={colors.blueAccent[400]}
                                                                sx={{ cursor: 'default' }}
                                                                onMouseEnter={handlePopoverOpen}
                                                                onMouseLeave={handlePopoverClose}
                                                            >
                                                                show
                                                            </Typography>
                                                            <Popover
                                                                id='popover-services'
                                                                sx={{
                                                                    pointerEvents: 'none',
                                                                }}
                                                                anchorEl={anchorEl}
                                                                open={Boolean(anchorEl)}
                                                                onClose={handlePopoverClose}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'left',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                                disableRestoreFocus
                                                            >
                                                                {
                                                                    serviceShow.length > 0 &&
                                                                        serviceShow.map((service) => (
                                                                            <List
                                                                                key={service._id}
                                                                                sx={{
                                                                                    pr: '10px',
                                                                                    pl: '10px',
                                                                                    width: '100%',
                                                                                    minWidth: '200px',
                                                                                    // bgcolor: theme.palette.mode === 'dark' ? colors.grey[400] : '#fff',
                                                                                }}
                                                                            >
                                                                                <Typography fontWeight={600} display='inline-block'>
                                                                                    {service.name}
                                                                                </Typography>
                                                                                :{' '}
                                                                                <Typography
                                                                                    fontWeight={600}
                                                                                    display='inline-block'
                                                                                    color={colors.greenLight[300]}
                                                                                >
                                                                                    {service.price} VNĐ
                                                                                </Typography>
                                                                            </List>
                                                                            // <ListItem key={service}>
                                                                            //     <ListItemAvatar>
                                                                            //         <Avatar></Avatar>
                                                                            //     </ListItemAvatar>
                                                                            //     <ListItemText primary={service.name} />
                                                                            // </ListItem>
                                                                        ))
                                                                    // : (
                                                                    //     <ListItem key='empty'>
                                                                    //         <ListItemAvatar>
                                                                    //             <Avatar>
                                                                    //                 <MiscellaneousServicesIcon />
                                                                    //             </Avatar>
                                                                    //         </ListItemAvatar>
                                                                    //         <ListItemText primary='Chưa gán dịch vụ' />
                                                                    //     </ListItem>
                                                                    // )
                                                                }
                                                            </Popover>
                                                        </Box> */}
                                                    </Box>
                                                )}
                                                {isEditing ? (
                                                    <Box display='flex' alignItems='center'>
                                                        <Typography variant='h5' fontWeight='600' minWidth='110px'>
                                                            Giá tiền:{' '}
                                                        </Typography>
                                                        <TextField
                                                            name='price'
                                                            type='text'
                                                            value={isLoadingFillPrice ? '' : formatNumber(values.price)}
                                                            onChange={(e) => {
                                                                const rawValue = parseNumber(e.target.value)
                                                                setFieldValue('price', Number(rawValue))
                                                            }}
                                                            onBlur={handleBlur}
                                                            error={!!touched.price && !!errors.price}
                                                            helperText={touched.price && errors.price}
                                                            InputProps={{
                                                                startAdornment: isLoadingFillPrice ? (
                                                                    <InputAdornment position='start'>
                                                                        <CircularProgress size={25} color='secondary' />
                                                                    </InputAdornment>
                                                                ) : null,
                                                                sx: {
                                                                    '& .MuiInputBase-input': {
                                                                        color: colors.greenLight[400],
                                                                    },
                                                                    fontWeight: 600,
                                                                },
                                                            }}
                                                            fullWidth
                                                        />
                                                    </Box>
                                                ) : (
                                                    <Box display='flex' alignItems='center'>
                                                        <Typography variant='h5' fontWeight='600' minWidth='110px'>
                                                            Giá tiền:
                                                        </Typography>
                                                        <Typography color={colors.greenLight[300]} fontWeight='600'>
                                                            {formatNumber(dataModal?.price ?? '')} VNĐ
                                                        </Typography>
                                                    </Box>
                                                )}
                                                {!isEditing && (dataModal?.taskId?.note?.length > 0 || dataModal?.userId?.note?.length > 0) && (
                                                    <>
                                                        <Box display='flex' alignItems='center'>
                                                            <Typography variant='h5' fontWeight='600' alignSelf='flex-start' minWidth='110px'>
                                                                Lưu ý:
                                                            </Typography>
                                                            <Typography color={colors.greenLight[300]} fontWeight='600'>
                                                                {modalName === 'clean' ? dataModal?.userId?.note : dataModal?.taskId?.note}
                                                            </Typography>
                                                        </Box>
                                                    </>
                                                )}
                                                {!isEditing && rate > 0 && (
                                                    <Box display='flex' alignItems='center'>
                                                        <Typography variant='h5' fontWeight='600' minWidth='110px'>
                                                            Đánh giá:
                                                        </Typography>
                                                        <Rating name='read-only' value={rate} readOnly />
                                                    </Box>
                                                )}
                                                {[3, 4, 5].includes(dataModal.status) && (
                                                    <Box display='flex' alignItems='center'>
                                                        <Typography variant='h5' fontWeight='600' alignSelf='flex-start' minWidth='110px'>
                                                            Lý do huỷ:
                                                        </Typography>
                                                        <Typography color='red'>
                                                            {dataModal.cancelReason != '' ? dataModal.cancelReason : 'không có lý do'}
                                                        </Typography>
                                                    </Box>
                                                )}
                                            </>
                                        )}
                                    </Box>
                                </Box>
                            )
                        }}
                    </Formik>
                    {!isEditing && modalName !== 'permanentTask' ? (
                        <Box p='20px' flex={0.7} height='100%'>
                            <Box display='flex' alignItems='end' height='100%'>
                                <Card sx={{ maxWidth: 345, height: '100%', width: '100%' }}>
                                    {isLoadingInfo ? (
                                        <CardActionArea
                                            sx={{
                                                height: '100%',
                                                width: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'start',
                                            }}
                                        >
                                            <Skeleton animation='wave' variant='rounded' width='100%' height={140} sx={{ mb: 2 }} />
                                            <Skeleton animation='wave' height={10} width='80%' sx={{ mb: 1 }} />
                                            <Skeleton animation='wave' height={10} width='60%' />
                                        </CardActionArea>
                                    ) : (
                                        <CardActionArea
                                            sx={{
                                                height: '100%',
                                                width: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'start',
                                            }}
                                        >
                                            <CardMedia
                                                component='img'
                                                sx={{ flex: 1, height: '140px' }}
                                                image={dataModal?.taskerId?.avatar ? baseURL + dataModal?.taskerId?.avatar : images.placeholderPerson}
                                                alt='green iguana'
                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant='h5' component='div'>
                                                    {dataModal?.taskerId?.fullName ?? 'Chưa gán người làm'}
                                                </Typography>
                                                {dataModal?.taskerId?.fullName && (
                                                    <Box display='flex' alignItems='center'>
                                                        <Phone />
                                                        <Typography ml='10px' variant='body2' color='text.secondary' fontSize='14px' fontWeight='600'>
                                                            {dataModal.taskerId.phoneNumber}
                                                        </Typography>
                                                    </Box>
                                                )}
                                            </CardContent>
                                        </CardActionArea>
                                    )}
                                </Card>
                            </Box>
                        </Box>
                    ) : (
                        <></>
                    )}
                    <Box
                        sx={{
                            position: 'absolute',
                            bottom: '5px',
                            right: '30px',
                            '& .ant-badge-status-text': {
                                color: `${processColor(dataModal.status)} !important`,
                                fontWeight: 700,
                                fontSize: '10px !important',
                            },
                            '& .ant-badge-status-processing::after': {
                                top: '-2px !important',
                                left: '-2px !important',
                                borderWidth: '5px !important',
                            },
                            '& .ant-badge-status-dot.ant-badge-status-processing, .ant-badge-status-dot.ant-badge-status-error': {
                                top: 0,
                            },
                        }}
                    >
                        <Badge
                            color={processColor(dataModal.status)}
                            status={processType(dataModal.status)}
                            text={<span>{processStatus(dataModal)}</span>}
                        />
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

export default memo(ModalDetails)
