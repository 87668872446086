import * as yup from 'yup'

//schema validate
const phoneRegExp = /^[0-9]{10}$/

export const validateModalCreateEditTask = (modalName) => {
    return yup.object().shape({
        address: yup.string().min(3, 'Địa chỉ quá ngắn').required('Yêu cầu nhập địa chỉ'),
        date: modalName !== 'permanentTask' && yup.date().typeError('định dạng ngày không đúng').required('vui lòng chọn ngày'),
        selectedDays: yup.array().when('$modalName', {
            is: () => modalName === 'permanentTask',
            then: (schema) => schema.min(1, 'Phải chọn ít nhất một ngày trong tuần').required('Phải chọn ít nhất một ngày trong tuần'),
            otherwise: (schema) => schema.notRequired(),
        }),
        time: yup.date().typeError('Yêu cầu chọn thời gian hợp lệ').required('Yêu cầu chọn thời gian hợp lệ'),
        estimateTime: yup.number().required('Yêu cầu chọn số giờ làm'),
        estimatePeople: yup.number().when('$modalName', {
            is: () => modalName === 'clean',
            then: (schema) => schema.min(2, 'Số công lao động phải lớn hơn hoặc bằng 2').required('Yêu cầu nhập số công'),
            otherwise: (schema) => schema.notRequired(),
        }),
        package: yup.number().when('$modalName', {
            is: () => modalName === 'permanentTask',
            then: (schema) =>
                schema.min(1, 'Gói theo tháng, nhỏ nhất là 1 tháng').integer('Gói tháng phải là số nguyên, 1->12').required('Yêu cầu nhập gói'),
            otherwise: (schema) => schema.notRequired(),
        }),
        price: yup.number().typeError('Giá tiền phải là số dương').min(0, 'Giá phải lớn hơn hoặc bằng 0').required('Yêu cầu nhập giá'),
        name: yup.string().required('Yêu cầu nhập tên khách'),
        phoneNumber: yup.string().matches(phoneRegExp, 'Số điện thoại không đúng').required('Yêu cầu nhập số điện thoại'),
    })
}
