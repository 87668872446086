import { useState, memo, useMemo, useEffect } from 'react'
import { Box, Typography, Button, FormControl, useTheme, Autocomplete, TextField, Modal, Divider, Avatar, IconButton } from '@mui/material'
import { tokens } from '../../theme'
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined'
import { formatNumber } from '../prefixData'
import { Close, Phone } from '@mui/icons-material'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import images from '../../assets/images'
import { baseURL } from '../../utils/api/baseURL'

const PopoverBoxAsignEmployee = ({ boxName, open, onClose, dataPartnerForTask, currentRow, setCurrentRow, fetchAddPartner }) => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const [dataPartner, setDataPartner] = useState(null)
    console.log(currentRow)

    //check value trước khi ném vào autocomplete
    const acValue = useMemo(() => {
        return dataPartnerForTask?.find((option) => option._id === dataPartner?._id) || null
    }, [dataPartnerForTask, dataPartner])

    //fnc
    const handleChangeEmployee = (e, newValue) => {
        console.log('newValue', newValue)
        setDataPartner(newValue)
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        const taskerId = dataPartner._id
        const data = { taskerId, _id: currentRow._id, fullName: dataPartner.fullName }

        setDataPartner('')
        fetchAddPartner(data)
        handleCloseBoxAsignEmployee()
    }

    const handleCloseBoxAsignEmployee = () => {
        onClose()
        setCurrentRow({})
        setDataPartner(null)
    }

    //style modal
    const styleModal = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: 800,
        height: 552,
        transform: 'translate(-50%, -50%)',
        bgcolor: theme.palette.mode === 'dark' ? colors.primary[300] : '#e3e6ff',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        border: 'unset',
        borderRadius: '4px',
        pb: '24px',
        pt: '24px',
        '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
            color: `${colors.grey[100]} !important`,
        },
    }

    return (
        <Modal
            open={open}
            onClose={() => handleCloseBoxAsignEmployee()}
            sx={{
                backdropFilter: 'blur(5px)',
            }}
        >
            <Box sx={{ ...styleModal }}>
                <IconButton
                    sx={{
                        position: 'absolute',
                        top: '10px',
                        right: '5px',
                    }}
                    onClick={() => handleCloseBoxAsignEmployee()}
                >
                    <Close color='disabled' />
                </IconButton>
                <Box display='flex' height='100%'>
                    <Box
                        p='20px'
                        sx={{
                            '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: `${colors.grey[100]} !important`,
                                borderWidth: '1px !important',
                            },
                            '& .Mui-focused': {
                                color: `${colors.grey[100]} !important`,
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderWidth: '1px',
                                borderColor: colors.grey[100],
                            },
                            flex: '0.7',
                            overflowY: 'auto',
                        }}
                    >
                        <Typography variant='h5' marginBottom='10px' color={theme.palette.mode === 'dark' ? colors.greenAccent[500] : '#15781e'}>
                            Danh sách nhân viên
                        </Typography>
                        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                            <FormControl sx={{ mt: '20px', width: '100%' }} size='medium'>
                                <Autocomplete
                                    id='combo-box-demo'
                                    options={dataPartnerForTask}
                                    value={acValue}
                                    onChange={(event, newValue) => handleChangeEmployee(event, newValue)}
                                    autoHighlight
                                    getOptionLabel={(option) => option?.fullName || ''}
                                    isOptionEqualToValue={(option, value) => option._id === value._id}
                                    renderInput={(params) => (
                                        <TextField {...params} label={boxName === 'cleanBooking' ? 'Người quản công' : 'Tên nhân viên'} required />
                                    )}
                                    renderOption={(props, option) => {
                                        return (
                                            <li {...props} key={option._id}>
                                                <Box width='100%'>
                                                    <Box display='flex' justifyContent='space-between'>
                                                        <Typography>{option.fullName}</Typography>
                                                        <Button
                                                            variant='text'
                                                            startIcon={<PaidOutlinedIcon />}
                                                            sx={{
                                                                p: '0',
                                                                color: colors.grey[100],
                                                            }}
                                                        >
                                                            {formatNumber(option.balance)}
                                                        </Button>
                                                    </Box>
                                                    <Typography color={colors.greenAccent[400]}>{option.phoneNumber}</Typography>
                                                </Box>
                                            </li>
                                        )
                                    }}
                                />
                                <Box marginTop='20px' display='flex' justifyContent='flex-end'>
                                    <Button
                                        variant='contained'
                                        type='submit'
                                        size='small'
                                        color='success'
                                        sx={{
                                            fontWeight: 600,
                                            color: 'white',
                                        }}
                                    >
                                        Chọn
                                    </Button>
                                    <Button
                                        variant='text'
                                        color='warning'
                                        sx={{
                                            color: colors.grey[100],
                                            ml: '5px',
                                        }}
                                        onClick={() => handleCloseBoxAsignEmployee()}
                                    >
                                        Huỷ
                                    </Button>
                                </Box>
                            </FormControl>
                        </form>
                        <Box
                            component='img'
                            sx={{
                                height: 'auto',
                                width: '100%',
                                mt: '20px',
                            }}
                            alt='The house from the offer.'
                            src={images.house}
                        />
                    </Box>
                    <Divider sx={{ height: '100%', borderWidth: '1px' }} />
                    <Box
                        sx={{
                            p: '20px',
                            flex: 0.5,
                            height: '100%',
                            overflow: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '20px',
                        }}
                    >
                        <Box
                            sx={{
                                p: '20px',
                                background: theme.palette.mode === 'dark' ? '#4a5b7c' : '#93b2df',
                                borderRadius: '10px',
                            }}
                        >
                            <Typography variant='h5' mb='20px' color={colors.greenLight[300]}>
                                Nhân viên thay thế
                            </Typography>
                            <Box display='flex' alignItems='center' mb='10px'>
                                <Avatar src={baseURL + dataPartner?.avatar} />
                                <Typography ml='10px'>{dataPartner?.fullName ?? '"Trống"'}</Typography>
                            </Box>
                            <Box display='flex' alignItems='center' mb='10px'>
                                <IconButton sx={{ width: '40px', height: '40px' }}>
                                    <Phone />
                                </IconButton>
                                <Typography ml='10px'>{dataPartner?.phoneNumber ?? '"Trống"'}</Typography>
                            </Box>
                            <Box display='flex' alignItems='center'>
                                <IconButton sx={{ width: '40px', height: '40px' }}>
                                    <AccountBalanceWalletIcon />
                                </IconButton>
                                <Typography ml='10px'>
                                    {dataPartner?.balance ? formatNumber(dataPartner.balance) + ' ' + 'VNĐ' : '"Trống"'}
                                </Typography>
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                p: '20px',
                                background: theme.palette.mode === 'dark' ? '#4a5b7c' : '#93b2df',
                                borderRadius: '10px',
                            }}
                        >
                            <Typography variant='h5' mb='20px' color={colors.orangeAccent[300]}>
                                Nhân viên hiện tại
                            </Typography>
                            <Box display='flex' alignItems='center' mb='10px'>
                                <Avatar src={baseURL + currentRow?.tasker?.avatar} />
                                <Typography ml='10px'>{currentRow?.tasker?.fullName ?? '"Trống"'}</Typography>
                            </Box>
                            <Box display='flex' alignItems='center' mb='10px'>
                                <IconButton sx={{ width: '40px', height: '40px' }}>
                                    <Phone />
                                </IconButton>
                                <Typography ml='10px'>{currentRow?.tasker?.phoneNumber ?? '"Trống"'}</Typography>
                            </Box>
                            <Box display='flex' alignItems='center'>
                                <IconButton sx={{ width: '40px', height: '40px' }}>
                                    <AccountBalanceWalletIcon />
                                </IconButton>
                                <Typography ml='10px'>
                                    {currentRow?.tasker?.balance ? formatNumber(currentRow?.tasker?.balance) + ' ' + 'VNĐ' : '"Trống"'}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}
export default memo(PopoverBoxAsignEmployee)
