import React, { useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { FormControl, IconButton, InputLabel, MenuItem, Select, useTheme } from '@mui/material'
import { tokens } from '../../theme'
import TextField from '@mui/material/TextField'
import useMediaQuery from '@mui/material/useMediaQuery'
import request from '../../utils/api/request'
import { update_company, get_city_settings, update_service } from '../../utils/api/apiList'

import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { message } from 'antd'
import { Cancel, Close, Send } from '@mui/icons-material'
import { Formik } from 'formik'
import * as yup from 'yup'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'

const phoneRegExp = /^[0-9]{10}$/
//validate
const validateFormSchema = yup.object().shape({
    name: yup.string().required('Không được bỏ trống'),
    address: yup.string().required('Không được bỏ trống'),
    phoneNumber: yup.string().matches(phoneRegExp, 'Số điện thoại không đúng').required('Yêu cầu nhập số điện thoại'),
})

const ModalEdit = ({ open, onClose, currentRow, fetchData = () => {} }) => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    //message
    const [messageApi, messageContextHolder] = message.useMessage()
    //media query
    const matches = useMediaQuery('(min-width:900px)')

    const [dataCites, setDataCites] = useState([])

    // initialFormValues
    const [initialFormValues, setInitalFormValues] = useState({
        name: currentRow?.name || '',
        address: currentRow?.address || '',
        phoneNumber: currentRow?.phoneNumber || '',
        package: currentRow?.package || 6,
        startDate: moment(currentRow?.startDate) || null,
        citySetting: currentRow?.citySetting || '',
        serviceType: currentRow?.serviceType,
    })

    //state tabs
    const [valueTab, setValueTab] = useState('1')

    const handleChangeTab = (event, newValue) => {
        setValueTab(newValue)
    }

    //fetching
    useEffect(() => {
        fetchAllCity()
    }, [currentRow])

    //handle edit
    const handleSave = (values, { resetForm }) => {
        console.log('values update company', values)

        request
            .post(update_company, { ...values, _id: currentRow?._id })
            .then((res) => {
                console.log('update company', res)

                message.success('Cập nhật công ty thành công!')
                fetchData()
            })
            .catch((err) => {
                const textErr = err.response.data.data
                switch (textErr) {
                    case 'PhoneInUseInPartnerApp':
                    case 'PhoneInUseInUserApp':
                    case 'phoneInUse':
                        message.error('Số điện thoại đã được đăng ký!')
                        break
                    default:
                        message.error('Cập nhật công ty thất bại!')
                        break
                }

                console.log(err)
            })

        //resetForm
        resetForm()
    }

    // fetch all city
    const fetchAllCity = () => {
        request
            .post(get_city_settings, {})
            .then((res) => {
                setDataCites(res.data.data.docs)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    return (
        <Modal
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    onClose()
                }
            }}
            style={{ backdropFilter: 'blur(5px)' }}
        >
            <Box
                sx={{
                    borderRadius: '5px',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: matches ? 700 : '90vw',
                    height: matches ? 'auto' : '75vh',
                    overflowY: 'auto',
                    bgcolor: theme.palette.mode === 'dark' ? '#1F2A40' : '#e0e0e0',
                    border: '1px solid rgba(0, 0, 0, 0.1) 0px 4px 12px',
                    boxShadow: 24,
                    p: 4,
                    '& .custom-color-label-textfield .MuiInputLabel-root.Mui-focused': {
                        color: '#3da58a',
                    },
                    '& .custom-color-datepicker .MuiInputLabel-root.Mui-focused': {
                        color: '#3da58a',
                    },
                }}
            >
                {messageContextHolder}
                <Typography id='modal-modal-title' color={colors.grey[500]} fontSize='16px'>
                    CHỈNH SỬA THÔNG TIN CÔNG TY
                </Typography>
                <IconButton
                    sx={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                    }}
                    onClick={onClose}
                >
                    <Close color='disabled' />
                </IconButton>
                <Box sx={{ mt: 2 }}>
                    <Formik initialValues={initialFormValues} validationSchema={validateFormSchema} onSubmit={handleSave}>
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => {
                            return (
                                <Box
                                    component='form'
                                    sx={{
                                        '& > :not(style)': { m: 1, width: '92%' },
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        '& #city-setting-label.Mui-focused': {
                                            color: colors.greenAccent[300],
                                        },
                                        '& #type-company-label-edit.Mui-focused, #package-label-edit.Mui-focused': {
                                            color: '#3da58a',
                                        },
                                    }}
                                    onSubmit={handleSubmit}
                                >
                                    <TabContext value={valueTab}>
                                        <Box
                                            sx={{
                                                borderBottom: 1,
                                                borderColor: 'divider',
                                            }}
                                        >
                                            <TabList
                                                onChange={handleChangeTab}
                                                aria-label='lab API tabs example'
                                                sx={{
                                                    '& .MuiTab-root.Mui-selected': {
                                                        color: colors.greenAccent[400],
                                                        fontWeight: 600,
                                                    },
                                                    '& .MuiTab-root': {
                                                        fontSize: '12px',
                                                    },
                                                }}
                                                indicatorColor='secondary'
                                            >
                                                <Tab label='Thông tin công ty' value='1' />
                                                <Tab label='Gói quản lý' value='2' />
                                                <Tab label='Chọn thành phố' value='3' />
                                            </TabList>
                                        </Box>
                                        <Box
                                            sx={{
                                                '& .MuiTabPanel-root': {
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    gap: '20px',
                                                },
                                                '& .MuiTabPanel-root[hidden]': {
                                                    display: 'none',
                                                },
                                                '& .MuiFormControl-root': {
                                                    width: '100%',
                                                },
                                                '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: `#4cceac !important`,
                                                },
                                            }}
                                        >
                                            <TabPanel className='tabpanel' value='1'>
                                                <TextField
                                                    name='name'
                                                    label='Tên công ty'
                                                    color='secondary'
                                                    className='custom-color-label-textfield'
                                                    value={values.name}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    error={!!touched.name && !!errors.name}
                                                    helperText={touched.name && errors.name}
                                                />
                                                <TextField
                                                    name='phoneNumber'
                                                    label='Số điện thoại'
                                                    color='secondary'
                                                    className='custom-color-label-textfield'
                                                    value={values.phoneNumber}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    error={!!touched.phoneNumber && !!errors.phoneNumber}
                                                    helperText={touched.phoneNumber && errors.phoneNumber}
                                                />
                                                <TextField
                                                    name='address'
                                                    label='Địa chỉ'
                                                    color='secondary'
                                                    className='custom-color-label-textfield'
                                                    value={values.address}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    error={!!touched.address && !!errors.address}
                                                    helperText={touched.address && errors.address}
                                                />
                                            </TabPanel>
                                            <TabPanel className='tabpanel' value='2'>
                                                <FormControl fullWidth>
                                                    <InputLabel id='type-company-label-edit'>Tên gói</InputLabel>
                                                    <Select
                                                        name='serviceType'
                                                        label='Tên gói'
                                                        labelId='type-company-label-edit'
                                                        color='secondary'
                                                        value={values.serviceType}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    >
                                                        {[0, 1].map((elm) => (
                                                            <MenuItem key={elm} value={elm}>
                                                                {elm === 0 ? 'Gói không giới hạn' : 'Gói theo tháng'}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                {values.serviceType === 1 && (
                                                    <FormControl fullWidth>
                                                        <InputLabel id='package-label-edit'>Số tháng</InputLabel>
                                                        <Select
                                                            name='package'
                                                            label='Số tháng'
                                                            labelId='package-label-edit'
                                                            color='secondary'
                                                            value={values.package}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                        >
                                                            {[6, 12].map((elm) => (
                                                                <MenuItem key={elm} value={elm}>
                                                                    {elm} tháng
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                )}
                                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                                    <DatePicker
                                                        name='startDate'
                                                        value={values.startDate}
                                                        onChange={(newValue) => {
                                                            setFieldValue('startDate', newValue)
                                                        }}
                                                        slotProps={{
                                                            textField: {
                                                                variant: 'outlined',
                                                                margin: 'normal',
                                                                label: 'Ngày bắt đầu',
                                                                error: touched.startDate && !!errors.startDate,
                                                                helperText: touched.startDate && errors.startDate,
                                                                fullWidth: true,
                                                                className: 'custom-color-datepicker',
                                                                sx: {
                                                                    mt: 0,
                                                                    mb: 0,
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            </TabPanel>
                                            <TabPanel className='tabpanel' value='3'>
                                                <FormControl fullWidth>
                                                    <InputLabel id='city-setting-label'>Chọn thành phố</InputLabel>
                                                    <Select
                                                        labelId='city-setting-label'
                                                        name='citySetting'
                                                        label='Chọn thành phố'
                                                        value={values.citySetting}
                                                        color='secondary'
                                                        className='custom-color-label-textfield'
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    >
                                                        {dataCites.map((city) => (
                                                            <MenuItem key={city._id} value={city._id}>
                                                                {city.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </TabPanel>
                                        </Box>
                                    </TabContext>
                                    <Box mt={2} display='flex' justifyContent='flex-end'>
                                        <Button
                                            type='submit'
                                            variant='contained'
                                            color='success'
                                            sx={{ minWidth: '96px', p: '8px 16px', mr: '10px', fontWeight: 600 }}
                                            endIcon={<Send />}
                                        >
                                            Lưu
                                        </Button>
                                        <Button
                                            sx={{ minWidth: '96px', p: '8px 16px' }}
                                            variant='outlined'
                                            color='inherit'
                                            startIcon={<Cancel />}
                                            onClick={onClose}
                                        >
                                            Huỷ
                                        </Button>
                                    </Box>
                                </Box>
                            )
                        }}
                    </Formik>
                </Box>
            </Box>
        </Modal>
    )
}

export default ModalEdit
