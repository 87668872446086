import { useState, useEffect } from 'react'
import { Box, useTheme, Typography, Autocomplete, TextField, FormControl, Select, MenuItem } from '@mui/material'
import Header from '../../../../components/Header'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import moment from 'moment'
import 'moment/locale/vi'
import request from '../../../../utils/api/request'
import {
    get_all_company,
    total_company_profit_by_month_by_year,
    total_company_profit_from_date_to_date_admin,
    total_deposit_by_companyid_from_date_to_date,
} from '../../../../utils/api/apiList'
import LineChart from '../../../../components/LineChart'
import { formatNumber } from '../../../../components/prefixData'
import { tokens } from '../../../../theme'
import dayjs from 'dayjs'
import { DatePicker as DatePickerAntd, Space, message } from 'antd'

function AnalyzesDeposit() {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const [companies, setCompanies] = useState([])
    const [selectedCompanyId, setSelectedCompanyId] = useState(null)
    const [companyName, setCompanyName] = useState('')

    const [dataDepositConvert, serDataDepositConvert] = useState([])
    const [totalDeposit, setTotalDeposit] = useState(0)

    const [dataCountConvert, setDataCountConvert] = useState([])
    const [totalCount, setTotalCount] = useState(0)

    const [dateRange, setDateRange] = useState([dayjs(), dayjs().add(-7, 'd')])
    const [startDate, setStartDate] = useState([dayjs()])
    const [endDate, setEndDate] = useState([dayjs().add(-7, 'd')])

    useEffect(() => {
        fetchAllCity()
    }, [])

    //fetch data date by date
    const fetchDataDateByDate = (data) => {
        //data reset
        const dataResetChartDateByDate = [
            {
                _id: {
                    day: dayjs(endDate).date(),
                    month: dayjs(endDate).month() + 1,
                    year: dayjs(endDate).year(),
                },
                count: 0,
                deposit: 0,
            },
            {
                _id: {
                    day: dayjs(startDate).date(),
                    month: dayjs(startDate).month() + 1,
                    year: dayjs(startDate).year(),
                },
                count: 0,
                deposit: 0,
            },
        ]
        request
            .post(total_deposit_by_companyid_from_date_to_date, { ...data })
            .then((res) => {
                const totalDateByDate = res.data.data
                console.log('data chart date by date', totalDateByDate)

                // hàm converChartDataDateByDate
                totalDateByDate.length > 0 ? convertChartDataDateByDate(totalDateByDate) : convertChartDataDateByDate(dataResetChartDateByDate)
            })
            .catch((err) => {
                console.error('Loi lay du lieu chart nạp cọc', err)
                message.warning('Công ty chưa có tài khoản quản lý!')
                convertChartDataDateByDate(dataResetChartDateByDate)
            })
    }

    // fetch all company
    const fetchAllCity = () => {
        request
            .post(get_all_company)
            .then((res) => {
                setCompanies(res.data.data.docs)
            })
            .catch((err) => {
                console.error('Loi get all cong ty:', err)
            })
    }

    //const handle selecte company
    const handleSelectCompany = (event, newValue) => {
        if (newValue) {
            setSelectedCompanyId(newValue._id)
            setCompanyName(newValue.name)
            fetchDataDateByDate({
                companyId: newValue._id,
                startDate,
                endDate,
            })
        } else {
            setSelectedCompanyId(null)
        }
    }

    //handle change range
    const { RangePicker } = DatePickerAntd
    const onRangeChange = (dates, dateStrings) => {
        if (dates) {
            console.log('From: ', dates[0], ', to: ', dates[1])
            console.log('From: ', dateStrings[0], ', to: ', dateStrings[1])
            setDateRange(dates)
            setStartDate(dates[0])
            setEndDate(dates[1])

            //data
            const data = {
                companyId: selectedCompanyId,
                startDate: dates[0],
                endDate: dates[1],
            }

            //fetch data date by date
            fetchDataDateByDate(data)
        } else {
            console.log('Clear')
            setDateRange([])
            setStartDate(null)
            setEndDate(null)
        }
    }
    const rangePresets = [
        {
            label: '7 ngày trước',
            value: [dayjs().add(-7, 'd'), dayjs()],
        },
        {
            label: '14 ngày trước',
            value: [dayjs().add(-14, 'd'), dayjs()],
        },
        {
            label: '30 ngày trước',
            value: [dayjs().add(-30, 'd'), dayjs()],
        },
        {
            label: '90 ngày trước',
            value: [dayjs().add(-90, 'd'), dayjs()],
        },
    ]

    // Hàm chuyển đổi dữ liệu từ API thành format của Nivo Line Chart (theo thời điểm từ ngày đến ngày)
    const convertChartDataDateByDate = (apiData) => {
        const depositData = {
            id: 'Tiền cọc',
            data: [],
        }

        const countData = {
            id: 'Số lượt nạp',
            data: [],
            color: 'hsl(52, 70%, 50%)',
        }

        let totalDeposit = 0
        let totalCount = 0

        // Duyệt qua từng phần tử trong dữ liệu trả về từ API
        apiData?.forEach((entry) => {
            const { day, month, year } = entry._id
            const dateLabel = `${day}/${month}/${year}`
            const deposit = entry.deposit
            const count = entry.count

            depositData.data.push({ x: dateLabel, y: deposit / 1000000 })
            countData.data.push({ x: dateLabel, y: count })

            totalDeposit += deposit
            totalCount += count
        })

        //data chart date by date
        serDataDepositConvert([depositData])
        setDataCountConvert([countData])

        //tổng tiền cọc
        setTotalDeposit(totalDeposit)
        setTotalCount(totalCount)
    }

    return (
        <Box
            m='20px'
            mb='50px'
            sx={{
                '& .MuiTypography-root.MuiTypography-h2': {
                    margin: '0 0 5px 0',
                },
                '& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: colors.grey[100],
                    borderWidth: '1px',
                },
                '& .MuiInputLabel-root.Mui-focused': { color: colors.grey[100] },
                '& .ant-picker-range-separator .ant-picker-separator .anticon.anticon-swap-right svg': {
                    color: colors.greenAccent[100],
                },
                '& .ant-picker .ant-picker-input >input': {
                    color: colors.grey[100],
                    fontSize: '13px',
                },
                '& .ant-picker-large .ant-picker-input>input::placeholder': {
                    color: colors.grey[100],
                    fontSize: '13px',
                },
                '& .ant-picker-suffix .anticon.anticon-calendar svg': {
                    color: colors.grey[100],
                },
                '& .ant-picker-clear .anticon.anticon-close-circle svg': {
                    color: colors.grey[100],
                },
            }}
        >
            <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Header title='THỐNG KÊ TIỀN NẠP CỌC' subtitle='Trang thống kê tiền nạp cọc' />
            </Box>

            <Box height='75vh' m='40px 0 0 0'>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box sx={{ display: 'flex', gap: '20px' }}>
                        <Box width={300} mb={2}>
                            <Autocomplete
                                freeSolo
                                options={companies}
                                getOptionLabel={(option) => option.name}
                                onChange={handleSelectCompany}
                                renderInput={(params) => <TextField {...params} label='Chọn công ty' variant='outlined' />}
                            />
                        </Box>

                        {/* Date Range Pickers khi chọn "Theo thời điểm" */}
                        <Space direction='vertical' size={24}>
                            <RangePicker
                                size='large'
                                presets={rangePresets}
                                onChange={onRangeChange}
                                style={{ padding: '15px', backgroundColor: colors.primary[500] }}
                                placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
                                value={dateRange}
                            />
                        </Space>
                    </Box>

                    {companyName && (
                        <Box>
                            <Box mr={'150px'} fontWeight={600} color={colors.grey[100]}>
                                LỢI NHUẬN :{' '}
                                <Typography fontWeight={700} display='inline-block' variant='h4' color={colors.greenAccent[500]}>
                                    {formatNumber(totalDeposit)} VNĐ
                                </Typography>
                            </Box>
                        </Box>
                    )}
                </Box>
                {/* Biểu đồ chart theo thời điểm */}
                {dataDepositConvert.length > 0 && (
                    <LineChart
                        dataChart={dataDepositConvert}
                        nameChart='depositDateByDate'
                        companyName={companyName}
                        selectedDate={dayjs(endDate).format('DD/MM/YYYY') + ' - ' + dayjs(startDate).format('DD/MM/YYYY')}
                    />
                )}
                {dataCountConvert.length > 0 && (
                    <LineChart
                        dataChart={dataCountConvert}
                        nameChart='depositCountDateByDate'
                        companyName={companyName}
                        selectedDate={dayjs(endDate).format('DD/MM/YYYY') + ' - ' + dayjs(startDate).format('DD/MM/YYYY')}
                    />
                )}
            </Box>
        </Box>
    )
}

export default AnalyzesDeposit
