import React, { useState, useEffect } from 'react'
import CKEditorComponent from '../../../components/CKEditorComponent'
import { create_policy, delete_policy, get_all_policy, update_policy } from '../../../utils/api/apiList'
import { Button, TextField, Paper, Typography, Box, useTheme, IconButton, Tooltip, Popover, Divider, InputAdornment } from '@mui/material'
import Header from '../../../components/Header'
import { tokens } from '../../../theme'
import 'ckeditor5/ckeditor5.css'
import request from '../../../utils/api/request'
import { Add, Delete, Facebook, Home, Instagram, Phone, Save } from '@mui/icons-material'
import { message } from 'antd'
import parse from 'html-react-parser'
import { Link } from 'react-router-dom'

const Policy = () => {
    const [policies, setPolicies] = useState([])
    const [selectedPolicy, setSelectedPolicy] = useState(null)
    const [title, setTitle] = useState('')
    const [content, setContent] = useState('')
    const [social, setSocial] = useState({ Facebook: '', Instagram: '', Hotline: '', Address: '' })
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const [activePolicyId, setActivePolicyId] = useState(null)
    const [anchorEl, setAnchorEl] = useState(null)
    //message
    const [messageApi, messageContextHolder] = message.useMessage()
    const resetForm = () => {
        setSelectedPolicy(null)
        setTitle('')
        setContent('')
        setActivePolicyId(null)
    }
    const [isSubmit, setIsSubmit] = useState(false)

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = () => {
        request
            .post(get_all_policy, {})
            .then((response) => {
                const policies = response.data.data.docs.map((policy, index) => ({
                    ...policy,
                    isProtected: index < 3, // Đánh dấu ba đối tượng đầu tiên
                }))
                setPolicies(policies)

                // Active mặc định policy "FAQ"
                const faqPolicy = policies.find((policy) => policy.stringLink === 'faq')
                if (faqPolicy) {
                    handlePolicyChange(faqPolicy)
                }
            })
            .catch((error) => {
                console.error('Lỗi get all dữ liệu Policy', error)
            })
    }

    //change button policy
    const handlePolicyChange = (policy) => {
        setSelectedPolicy(policy)
        setTitle(policy.title)
        setContent(policy.content)
        setActivePolicyId(policy._id)
        // setSocial(policy.social || { Facebook: '', Instagram: '', Hotline: '', Address: '' })
        setIsSubmit(false)
    }

    //save
    const handleSave = () => {
        const data = {
            title,
            content,
            // social: selectedPolicy.stringLink === 'social' ? social : undefined,
        }

        console.log('data', data)

        if (selectedPolicy) {
            // Update existing policy
            request
                .post(update_policy, { ...data, _id: selectedPolicy._id })
                .then((response) => {
                    message.success('Cập nhật điều khoản thành công!')
                    // Refresh policies
                    setPolicies(policies.map((policy) => (policy._id === selectedPolicy._id ? response.data.data : policy)))
                })
                .catch((error) => {
                    console.error('Lỗi update dữ liệu policy!', error)
                    message.error('Cập nhật điều khoản thất bại!')
                })
                .finally(() => {
                    setIsSubmit(true)
                })
        }
    }

    //delete
    const handleOpenBoxDelete = (e) => {
        setAnchorEl(e.currentTarget)
    }
    const handleCloseBoxDelele = () => {
        setAnchorEl(false)
    }
    const handleConfirmDelete = () => {
        const policyToDelete = policies.find((policy) => policy._id === activePolicyId)

        if (policyToDelete?.isProtected) {
            message.error('Bạn không thể xoá điều khoản này')
            return
        }

        request
            .post(delete_policy, { _id: activePolicyId })
            .then((res) => {
                message.success('Xoá điều khoản thành công')
                fetchData()
                resetForm()
                setAnchorEl(false)
            })
            .catch((err) => {
                message.error('Có lỗi khi xoá điều khoản')
                console.error('Lỗi khi xoá điều khoản', err)
            })
    }

    // create policy
    const handleCreateNewPolicy = () => {
        const newPolicy = {
            title: 'new policy',
            content: 'nội dung điều khoản mới',
        }

        request
            .post(create_policy, newPolicy)
            .then((response) => {
                const createdPolicy = response.data.data
                message.success('Tạo mới điều khoản thành công!')
                setPolicies([...policies, createdPolicy])
                handlePolicyChange(createdPolicy)
            })
            .catch((error) => {
                console.error('Lỗi tạo mới dữ liệu policy!', error)
                message.error('Tạo mới điều khoản thất bại!')
            })
    }

    //style text field
    const textFieldStyles = {
        InputProps: {
            sx: {
                // '& .MuiOutlinedInput-notchedOutline': {
                //     borderColor: theme.palette.primary.main, // Màu viền mặc định
                // },
                // '&:hover .MuiOutlinedInput-notchedOutline': {
                //     borderColor: theme.palette.secondary.main, // Màu viền khi hover
                // },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: colors.grey[100], // Màu viền khi focus
                    borderWidth: '1px',
                },
            },
        },
        InputLabelProps: {
            sx: {
                // color: theme.palette.text.primary, // Màu label mặc định
                '&.Mui-focused': {
                    color: colors.grey[100], // Màu label khi focus
                },
                // '&:hover': {
                //     color: theme.palette.secondary.main, // Màu label khi hover
                // },
            },
        },
    }

    return (
        <Box
            m='20px'
            sx={{
                '& .MuiTypography-root.MuiTypography-h2': {
                    margin: '0 0 5px 0',
                },
            }}
        >
            {messageContextHolder}
            <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Header title='ĐIỀU KHOẢN DỊCH VỤ' subtitle='Trang cài đặt điều khoản dịch vụ' />
                <Button
                    sx={{
                        backgroundColor: colors.blueAccent[700],
                        color: 'white',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        padding: '10px 20px',
                        '&:hover': {
                            backgroundColor: colors.blueAccent[600],
                        },
                    }}
                    startIcon={<Add />}
                    onClick={(e) => handleCreateNewPolicy(e)}
                >
                    Tạo điều khoản
                </Button>
            </Box>
            <Box m='40px 0 0 0'>
                <Paper elevation={3} style={{ padding: '16px', backgroundColor: colors.primary[400] }}>
                    <Typography variant='h6'>Các điều khoản</Typography>
                    <Box display='flex' flexWrap='wrap' gap='10px' mt='10px'>
                        {policies.map((policy) => (
                            <Button
                                key={policy._id}
                                onClick={() => handlePolicyChange(policy)}
                                variant={activePolicyId === policy._id ? 'contained' : 'outlined'}
                                sx={{
                                    m: '4px 0',
                                    border: 'none',
                                    color: 'white',
                                    backgroundColor:
                                        activePolicyId === policy._id
                                            ? theme.palette.mode === 'dark'
                                                ? colors.greenAccent[800]
                                                : '#4ad1a9'
                                            : theme.palette.mode === 'dark'
                                            ? colors.greenAccent[700]
                                            : '#37a383',
                                    '&:hover': {
                                        border: 'none',
                                        backgroundColor: theme.palette.mode === 'dark' ? colors.greenAccent[800] : '#4ad1a9',
                                    },
                                }}
                            >
                                {policy.title}
                            </Button>
                        ))}
                    </Box>
                </Paper>
            </Box>

            <Box mt='20px' mb='50px'>
                <Paper
                    elevation={3}
                    sx={{
                        padding: '16px',
                        height: '100%',
                        bgcolor: colors.primary[400],
                        display: isSubmit ? 'flex' : 'inherit',
                        columnGap: '20px',
                        '& .Mui-focused': {
                            color: colors.grey[300],
                        },
                    }}
                >
                    <Box flex={isSubmit && selectedPolicy?.stringLink !== 'social' ? 0.7 : 1}>
                        <TextField
                            fullWidth
                            label='Title'
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            style={{ marginBottom: '16px' }}
                            variant='filled'
                            required
                        />
                        {/* {selectedPolicy?.stringLink === 'social' ? (
                            <Box mt='20px'>
                                <TextField
                                    label='Facebook'
                                    value={social.Facebook}
                                    onChange={(e) => setSocial({ ...social, Facebook: e.target.value })}
                                    fullWidth
                                    margin='normal'
                                    id='social-label'
                                    {...textFieldStyles}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                <Facebook sx={{ color: '#1877F2', mr: 2 }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <TextField
                                    label='Instagram'
                                    value={social.Instagram}
                                    onChange={(e) => setSocial({ ...social, Instagram: e.target.value })}
                                    fullWidth
                                    margin='normal'
                                    {...textFieldStyles}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                <Instagram sx={{ color: '#E1306C', mr: 2 }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <TextField
                                    label='Hotline'
                                    value={social.Hotline}
                                    onChange={(e) => setSocial({ ...social, Hotline: e.target.value })}
                                    fullWidth
                                    margin='normal'
                                    {...textFieldStyles}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                <Phone sx={{ color: '#4CAF50', mr: 2 }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <TextField
                                    label='Địa chỉ'
                                    value={social.Address}
                                    onChange={(e) => setSocial({ ...social, Address: e.target.value })}
                                    fullWidth
                                    margin='normal'
                                    {...textFieldStyles}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                <Home sx={{ color: '#FF5722', mr: 2 }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Box>
                        ) : (
                            <CKEditorComponent content={content} setContent={setContent} />
                        )} */}
                        <CKEditorComponent content={content} setContent={setContent} />
                        <Box display='flex' justifyContent='space-between' alignItems='center' mt='16px'>
                            <Button
                                variant='contained'
                                startIcon={<Save />}
                                color='info'
                                onClick={handleSave}
                                sx={{
                                    width: '100px',
                                    p: '10px 0',
                                    color: 'white',
                                    bgcolor: '#0288d1',
                                    '&:hover': {
                                        bgcolor: theme.palette.mode === 'dark' ? '#0890d9' : '#0890d9',
                                    },
                                }}
                            >
                                Save
                            </Button>
                            <Tooltip title='Xoá điều khoản'>
                                <IconButton onClick={(e) => handleOpenBoxDelete(e)}>
                                    <Delete />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>
                    {isSubmit && selectedPolicy?.stringLink !== 'social' && (
                        <Box
                            sx={{
                                backgroundColor: 'white',
                                padding: '20px',
                                borderRadius: '5px',
                            }}
                            flex={0.3}
                        >
                            {parse(content)}
                        </Box>
                    )}
                </Paper>
            </Box>

            {/* box delete */}
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleCloseBoxDelele}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box p={2}>
                    <Typography>Bạn muốn xoá điều khoản này?</Typography>
                    <Box
                        marginTop='10px'
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Button
                            size='small'
                            variant='contained'
                            color='error'
                            onClick={() => handleConfirmDelete(activePolicyId)}
                            sx={{
                                fontWeight: 600,
                                color: colors.grey[100],
                                bgcolor: '#d40000',
                                color: 'white',
                                fontWeight: '600',
                                mr: '20px',
                                '&:hover': {
                                    bgcolor: 'red',
                                },
                            }}
                        >
                            Chấp nhận
                        </Button>
                        <Button
                            size='small'
                            onClick={handleCloseBoxDelele}
                            variant='outlined'
                            sx={{
                                bgcolor: 'white',
                                '&:hover': {
                                    bgcolor: '#e8e8e8c4',
                                },
                            }}
                        >
                            Huỷ
                        </Button>
                    </Box>
                </Box>
            </Popover>
        </Box>
    )
}

export default Policy
