import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Typography, useTheme, IconButton, Button, Popover, Chip } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { tokens } from '../../theme'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import Header from '../../components/Header'
import StyleDataGrid from '../../assets/styles/styleDataGrid'
import StyleButtonGradient from '../../assets/styles/styleButtonGradient'
import request from '../../utils/api/request'
import { create_discount, delete_discount, get_all_discount, update_discount } from '../../utils/api/apiList'
import CustomNoRowsOverlay from '../../components/noRows'
import CustomLoadingComponent from '../../components/loading'
import { formatNumber } from '../../components/prefixData'
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp'
import moment from 'moment'
import * as yup from 'yup'
import { message } from 'antd'
import DiscountOutlinedIcon from '@mui/icons-material/DiscountOutlined'
import ModalDiscount from './modal'

const validateFormAddSchema = yup.object().shape({
    type: yup.number().required('Vui lòng chọn theo "phần trăm (%)" hoặc "giá tiền (VNĐ)"'),
    value: yup
        .number()
        .typeError('Phải là số dương')
        .positive('Phải là số dương')
        // .required('Giá trị khuyến mãi là bắt buộc'),
        .when('type', {
            is: (val) => val === 2,
            then: (schema) => schema.min(0, 'nhỏ nhất là 0%').max(100, 'lớn nhất là 100%').required('Giá trị khuyến mãi là bắt buộc'),
            otherwise: (schema) => schema.required('Giá trị khuyến mãi là bắt buộc'),
        }),
    // maxValue: yup
    //     .number()
    //     .typeError('Phải là số dương')
    //     .positive('Phải là số dương')
    //     // .required('Giá trị khuyến mãi tối đa là bắt buộc'),
    //     .when('type', {
    //         is: (val) => val === 2,
    //         then: (schema) => schema.required('Không được để trống'),
    //         otherwise: (schema) => schema.notRequired(),
    //     }),
    limitTimes: yup
        .number()
        .typeError('Phải là số nguyên dương')
        .integer('Phải là số nguyên')
        .positive('Phải là số nguyên dương')
        .required('Số lượt dùng mã là bắt buộc'),
    startDate: yup.date().required('Ngày bắt đầu khuyến mãi là bắt buộc'),
    endDate: yup.date().required('Ngày kết thúc khuyến mãi là bắt buộc'),
})

const Discount = () => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const role = localStorage.getItem('role')
    const [currentRow, setCurrentRow] = useState(null)
    const [dataDiscounts, setDataDiscounts] = useState(null)
    const [loading, setLoading] = useState(false)
    // pagination server side
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 25,
    })
    const [totalRows, setTotalRows] = useState(0)
    //sort server
    const [queryOptions, setQueryOptions] = useState({ dateCreated: -1 })

    const [openEdit, setOpenEdit] = useState(false)
    const [openAdd, setOpenAdd] = useState(false)
    const [idRowDelete, setIdRowDelete] = useState(null)
    const [anchorEl, setAnchorEl] = useState(null)
    const [isLoadingCreateDiscount, setIsLoadingCreateDiscount] = useState(false)
    const [isLoadingEditDiscount, setIsLoadingEditDiscount] = useState(false)

    //message
    const [messageApi, messageContextHolder] = message.useMessage()

    //state form add
    const formAddValues = {
        code: '',
        type: 1,
        limitTimes: null,
        value: null,
        maxValue: null,
        minOrderValue: 0,
        startDate: null,
        endDate: null,
    }

    // state form edit
    const formEditValues = {
        code: currentRow?.code,
        type: currentRow?.type + 1,
        limitTimes: currentRow?.limitTimes,
        value: currentRow?.value,
        maxValue: currentRow?.maxValue,
        minOrderValue: currentRow?.minOrderValue || 0,
        startDate: currentRow?.startDate ? moment(currentRow?.startDate) : null,
        endDate: currentRow?.endDate ? moment(currentRow?.endDate) : null,
    }

    // sort server
    const handleSortModelChange = useCallback((sortModel) => {
        if (sortModel.length > 0) {
            const sortField = sortModel[0].field
            const sortOrder = sortModel[0].sort === 'asc' ? 1 : -1
            setQueryOptions({ [sortField]: sortOrder })
        } else {
            setQueryOptions({ dateCreated: -1 })
        }
    }, [])

    // animate arrow
    // const UpwardArrowIcon = () => {
    //     return (
    //         <Box
    //             sx={{
    //                 display: 'inline-block',
    //                 animation: 'upwardMove 1s infinite',
    //                 '& .MuiSvgIcon-root': {
    //                     fill: 'url(#gradient1)',
    //                 },
    //             }}
    //         >
    //             <svg width='0' height='0'>
    //                 <defs>
    //                     <linearGradient id='gradient1' x1='0%' y1='100%' x2='0%' y2='0%'>
    //                         <stop offset='0%' style={{ stopColor: 'rgba(0, 0, 0, 0)', stopOpacity: 1 }} />
    //                         <stop offset='100%' style={{ stopColor: '#00ff00', stopOpacity: 1 }} />
    //                     </linearGradient>
    //                 </defs>
    //             </svg>
    //             <KeyboardDoubleArrowUpIcon sx={{ fontSize: 20 }} />
    //             <style>
    //                 {`
    //                 @keyframes upwardMove {
    //                     0% {
    //                         transform: translateY(0);
    //                     }
    //                     50% {
    //                         transform: translateY(-5px);
    //                     }
    //                     100% {
    //                         transform: translateY(0);
    //                     }
    //                 }
    //                 `}
    //             </style>
    //         </Box>
    //     )
    // }

    //fetching
    useEffect(() => {
        fetchDataDiscount()
    }, [paginationModel, queryOptions])

    //fetch data
    const fetchDataDiscount = () => {
        setLoading(true)
        request
            .post(get_all_discount, { page: paginationModel.page + 1, limit: paginationModel.pageSize, sort: queryOptions })
            .then((res) => {
                const data = res.data.data
                const convertData = data.docs.map((elm, index) => {
                    return {
                        ...elm,
                        index: index + 1 + paginationModel.page * paginationModel.pageSize,
                    }
                })
                setDataDiscounts(convertData)
                setTotalRows(data.total)
            })
            .catch((err) => {
                console.error(err)
            })
            .finally(() => setLoading(false))
    }

    //create discount
    const fetchCreateDiscount = (data, resetForm) => {
        request
            .post(create_discount, { data })
            .then((res) => {
                console.log(res)
                message.success(
                    <p>
                        Tạo <span style={{ color: '#44c144' }}>mã khuyến mãi</span> thành công!
                    </p>
                )
                fetchDataDiscount()
                resetForm()
            })
            .catch((err) => {
                console.error(err)
                message.error(
                    <p>
                        Tạo <span style={{ color: colors.redAccent[400] }}>mã khuyến mãi</span> thất bại!
                    </p>
                )
            })
            .finally(() => {
                setIsLoadingCreateDiscount(false)
            })
    }

    //edit discount
    const fetchEditDiscount = (data) => {
        request
            .post(update_discount, { data })
            .then((res) => {
                console.log(res)
                message.success(
                    <p>
                        Chỉnh sửa <span style={{ color: '#44c144' }}>mã khuyến mãi</span> thành công!
                    </p>
                )
                setOpenEdit(false)
                fetchDataDiscount()
            })
            .catch((err) => {
                console.error(err)
                message.error(
                    <p>
                        Chỉnh sửa <span style={{ color: colors.redAccent[400] }}>khuyến mãi</span> thất bại!
                    </p>
                )
            })
            .finally(() => {
                setIsLoadingEditDiscount(false)
            })
    }

    //delete discount
    const handleOpenBoxDelete = (id, event) => {
        setIdRowDelete(id)
        setAnchorEl(event.currentTarget)
    }

    const handleConfirmDelete = () => {
        request
            .post(delete_discount, { _id: idRowDelete })
            .then((res) => {
                console.log(res)
                message.success(<p>xoá khuyến mãi thành công!</p>)
                fetchDataDiscount()
            })
            .catch((err) => {
                console.error(err)
                message.error(<p style={{ color: colors.redAccent[500] }}>có lỗi khi xoá!</p>)
            })
        setAnchorEl(false)
    }

    const handleCloseBoxDelete = () => {
        setAnchorEl(false)
    }

    //edit
    const handleOpenEdit = (row) => {
        setOpenEdit(true)
        setCurrentRow(row)
        console.log(row)
    }

    const handleCloseModalEdit = () => {
        setOpenEdit(false)
    }

    const handleSubmitEdit = (values) => {
        console.log('values', values)
        const data = {
            _id: currentRow?._id,
            code: values.code.toUpperCase(),
            type: --values.type,
            value: Number(values.value),
            maxValue: Number(values.maxValue),
            limitTimes: Number(values.limitTimes),
            startDate: values.startDate,
            endDate: values.endDate,
        }

        console.log(data)
        setIsLoadingEditDiscount(true)
        const timerEditDiscount = setTimeout(() => {
            fetchEditDiscount(data)
        }, 500)

        return () => clearTimeout(timerEditDiscount)
    }

    //column
    const columns = useMemo(
        () =>
            [
                {
                    field: 'index',
                    headerName: 'STT',
                    width: 70,
                },
                {
                    field: 'code',
                    headerName: 'Mã khuyến mãi',
                    minWidth: 150,
                    flex: 1,
                    cellClassName: 'name-column--cell--notTask maxValue',
                },
                // {
                //     field: 'thumbnail',
                //     headerName: 'Ảnh banner',
                //     minWidth: 120,
                //     flex: 1,
                //     renderCell: ({ row }) => <Image src={images.house} />,
                // },
                {
                    field: 'limitTimes',
                    headerName: 'Số lượng',
                    minWidth: 100,
                    flex: 1,
                },
                {
                    field: 'usedTime',
                    headerName: 'Đã dùng',
                    minWidth: 100,
                    flex: 1,
                },
                {
                    field: 'type',
                    headerName: 'Áp dụng',
                    minWidth: 220,
                    flex: 1.4,
                    renderCell: ({ row }) => {
                        let type
                        // if (row.type === 0) {
                        //     type = 'Giá Tiền (VNĐ)'
                        // } else {
                        //     type = 'Phần trăm (%)'
                        // }
                        // return type
                        switch (row.type) {
                            case 0:
                                return (type = 'Giá tiền (VNĐ)')
                            case 1:
                                return (type = 'Phần trăm (%)')
                            case 2:
                                return (type = 'Theo tiền trên đơn hàng tối thiểu')
                            case 3:
                                return (type = 'Theo (%) trên đơn hàng tối thiểu')
                            default:
                                break
                        }
                    },
                },
                {
                    field: 'value',
                    headerName: 'Giá trị khuyến mãi',
                    minWidth: 150,
                    flex: 1,
                    cellClassName: 'name-column--cell--notTask maxValue',
                    renderCell: ({ row }) => {
                        // return row.type === 0 ? formatNumber(row.value) + ' ' + 'VNĐ' : row.value + '%'
                        switch (row.type) {
                            case 0:
                            case 2:
                                return formatNumber(row.value) + ' ' + 'VNĐ'
                            case 1:
                            case 3:
                                return row.value + '%'
                            default:
                                break
                        }
                    },
                    valueGetter: (value) => {
                        return value
                    },
                },
                {
                    field: 'maxValue',
                    headerName: 'Khuyến mãi tối đa',
                    minWidth: 150,
                    flex: 1,
                    cellClassName: 'name-column--cell--notTask maxValue',
                    renderCell: ({ row }) => {
                        switch (row.type) {
                            case 0:
                            case 2:
                                return formatNumber(row.value) + ' ' + 'VNĐ'
                            case 1:
                            case 3:
                                return formatNumber(row.maxValue) + ' ' + 'VNĐ'
                            default:
                                break
                        }
                    },
                    valueGetter: (value) => {
                        return value
                    },
                },
                {
                    field: 'minOrderValue',
                    headerName: 'Đơn hàng tối thiểu',
                    minWidth: 150,
                    flex: 1,
                    cellClassName: 'name-column--cell--notTask maxValue',
                    renderCell: ({ row }) => {
                        switch (row.type) {
                            case 0:
                            case 1:
                                return 0
                            case 2:
                            case 3:
                                return formatNumber(row.minOrderValue) + ' ' + 'VNĐ'
                            default:
                                break
                        }
                    },
                    valueGetter: (value) => {
                        return value
                    },
                },
                {
                    field: 'startDate',
                    headerName: 'Ngày bắt đầu',
                    headerAlign: 'left',
                    align: 'left',
                    flex: 1,
                    minWidth: 100,
                    // renderCell: ({ row }) => {
                    //     return moment(row.startDate).format('DD-MM-YYYY')
                    // },
                    valueGetter: (value) => {
                        // console.log(value)
                        return moment(value).format('DD-MM-YYYY')
                        // return value
                    },
                },
                {
                    field: 'endDate',
                    headerName: 'Ngày kết thúc',
                    headerAlign: 'left',
                    align: 'left',
                    flex: 1,
                    minWidth: 100,
                    cellClassName: 'endDate',
                    // renderCell: ({ row }) => {
                    //     return moment(row.endDate).format('DD-MM-YYYY')
                    // },
                    valueGetter: (value) => {
                        // console.log(value)
                        return moment(value).format('DD-MM-YYYY')
                        // return value
                    },
                },
                {
                    field: 'status',
                    headerName: 'Trạng thái',
                    minWidth: 100,
                    flex: 1,
                    renderCell: ({ row }) => {
                        let status
                        let now = moment()
                        let isStartDateFuture = moment(row.startDate).isAfter(now, 'day')
                        let isEndDatePast = moment(row.endDate).isBefore(now, 'day')

                        if (isStartDateFuture) {
                            status = 0
                        } else if (isEndDatePast) {
                            status = 2
                        } else {
                            status = 1
                        }

                        return status === 2 ? (
                            <Chip label='Hết hạn' size='small' sx={{ backgroundColor: 'red', color: 'white', fontWeight: 700, minWidth: '80px' }} />
                        ) : status === 0 ? (
                            <Chip
                                label='Chưa diễn ra'
                                size='small'
                                sx={{ backgroundColor: 'orange', color: 'white', fontWeight: 700, minWidth: '80px' }}
                            />
                        ) : (
                            <Chip
                                label='Hoạt động'
                                size='small'
                                sx={{
                                    backgroundColor: theme.palette.mode === 'dark' ? '#0bb50bb3' : '#35b135a8',
                                    color: 'white',
                                    fontWeight: 700,
                                    minWidth: '80px',
                                }}
                            />
                        )
                    },
                },
                role === 'admin' && {
                    field: 'action',
                    headerName: 'Hành động',
                    minWidth: 100,
                    flex: 1,
                    sortable: false,
                    renderCell: ({ row }) => (
                        <Box display='flex' alignItems='center' gap={1}>
                            <IconButton
                                // onClick={() => handleEdit(row.id)}
                                onClick={() => handleOpenEdit(row)}
                                color='secondary'
                                aria-label='edit'
                            >
                                <EditIcon />
                            </IconButton>
                            <IconButton onClick={(event) => handleOpenBoxDelete(row._id, event)}>
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                    ),
                },
            ].filter(Boolean),
        [dataDiscounts]
    )

    // modal add
    const handleOpenModalAddUser = () => {
        setOpenAdd(true)
    }
    const handleCloseModalAdd = () => {
        setOpenAdd(false)
    }

    const handleAdd = (values, { resetForm }) => {
        console.log(values)
        const type = values.type

        const data = {
            code: values.code.toUpperCase(),
            type: --values.type,
            value: Number(values.value),
            maxValue: Number(values.maxValue),
            minOrderValue: Number(values.minOrderValue),
            limitTimes: Number(values.limitTimes),
            startDate: values.startDate,
            endDate: values.endDate,
        }

        if (type === 1 || type === 2) {
            delete data.minOrderValue
        }

        console.log(data)
        setIsLoadingCreateDiscount(true)
        const timerCreateDiscount = setTimeout(() => {
            fetchCreateDiscount(data, resetForm)
        }, 500)

        return () => clearTimeout(timerCreateDiscount)
    }

    return (
        <Box
            m='20px'
            sx={{
                '& .MuiTypography-root.MuiTypography-h2': {
                    margin: '0 0 5px 0',
                },
            }}
        >
            {messageContextHolder}
            <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Header title='ƯU ĐÃI' subtitle='Trang quản lý ưu đãi' />
                {role === 'admin' && (
                    <Button sx={StyleButtonGradient()} onClick={handleOpenModalAddUser}>
                        <DiscountOutlinedIcon sx={{ mr: '10px' }} />
                        THÊM ƯU ĐÃI
                    </Button>
                )}
            </Box>
            <Box
                height='75vh'
                m='40px 0 0 0'
                sx={{
                    ...StyleDataGrid(),
                    '& .maxValue': {
                        color: theme.palette.mode === 'dark' ? '#44c144' : '#49ac49',
                    },
                }}
            >
                <DataGrid
                    getRowId={(row) => row._id}
                    rows={dataDiscounts}
                    rowCount={totalRows}
                    columns={columns}
                    //pagination server side
                    pagination
                    initialState={{
                        pagination: { paginationModel },
                        // density: 'comfortable',
                    }}
                    pageSizeOptions={[10, 25, 50, 100]}
                    paginationMode='server'
                    onPaginationModelChange={setPaginationModel}
                    //pagination server side
                    // sort server
                    sortingMode='server'
                    onSortModelChange={handleSortModelChange}
                    loading={loading}
                    slots={{
                        toolbar: GridToolbar,
                        noRowsOverlay: CustomNoRowsOverlay,
                        loadingOverlay: CustomLoadingComponent,
                    }}
                    // rowHeight={70}
                />
            </Box>

            {role === 'admin' && (
                <>
                    <ModalDiscount
                        open={openAdd}
                        onClose={handleCloseModalAdd}
                        isLoading={isLoadingCreateDiscount}
                        initialValues={formAddValues}
                        validationSchema={validateFormAddSchema}
                        handleSubmit={handleAdd}
                        boxName='addDiscount'
                    />
                    <ModalDiscount
                        open={openEdit}
                        onClose={handleCloseModalEdit}
                        isLoading={isLoadingEditDiscount}
                        initialValues={formEditValues}
                        validationSchema={validateFormAddSchema}
                        handleSubmit={handleSubmitEdit}
                        boxName='editDiscount'
                    />
                    <Popover
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleCloseBoxDelete}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <Box p={2}>
                            <Typography>Bạn muốn xoá khuyến mãi này?</Typography>
                            <Box
                                marginTop='10px'
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <Button
                                    size='small'
                                    variant='contained'
                                    color='error'
                                    onClick={handleConfirmDelete}
                                    sx={{
                                        fontWeight: 600,
                                        color: colors.grey[100],
                                        bgcolor: '#d40000',
                                        color: 'white',
                                        fontWeight: '600',
                                        mr: '20px',
                                        '&:hover': {
                                            bgcolor: 'red',
                                        },
                                    }}
                                >
                                    Chấp nhận
                                </Button>
                                <Button
                                    size='small'
                                    onClick={handleCloseBoxDelete}
                                    variant='outlined'
                                    sx={{
                                        bgcolor: 'white',
                                        '&:hover': {
                                            bgcolor: '#e8e8e8c4',
                                        },
                                    }}
                                >
                                    Huỷ
                                </Button>
                            </Box>
                        </Box>
                    </Popover>
                </>
            )}
        </Box>
    )
}

export default Discount
