// Hàm phân tách mảng các ngày thành các mảng con dựa trên ngày liên tiếp
export const splitByConsecutiveDates = (dates) => {
    if (dates.length === 0) return []

    const result = []
    let tempGroup = [dates[0]]

    for (let i = 1; i < dates.length; i++) {
        const currentDate = new Date(dates[i])
        const previousDate = new Date(dates[i - 1])
        const differenceInDays =
            (currentDate - previousDate) / (1000 * 60 * 60 * 24)

        if (differenceInDays === 1) {
            // Ngày tiếp theo liên tục, thêm vào nhóm hiện tại
            tempGroup.push(dates[i])
        } else {
            // Ngày tiếp theo không liên tục, kết thúc nhóm hiện tại và bắt đầu nhóm mới
            result.push(tempGroup)
            tempGroup = [dates[i]]
        }
    }

    // Thêm nhóm cuối cùng vào kết quả
    result.push(tempGroup)

    return result
}
