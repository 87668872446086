import { useTheme } from '@mui/material'
function StylePopoverDelete() {
    const theme = useTheme()
    return {
        '& .css-3bmhjh-MuiPaper-root-MuiPopover-paper': {},
        '& .MuiPaper-root': {
            border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
            boxShadow:
                theme.palette.mode === 'dark'
                    ? 'none'
                    : 'rgba(149, 152, 165, 0.2) 2px 2px 2px',
        },
        '& .css-l44o5j-MuiButtonBase-root-MuiButton-root:hover': {
            bgcolor: '#ffffff4d',
        },
    }
}

export default StylePopoverDelete
