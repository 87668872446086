import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { FormControl, IconButton, InputLabel, MenuItem, Select, useTheme } from '@mui/material'
import { tokens } from '../../theme'
import TextField from '@mui/material/TextField'
import useMediaQuery from '@mui/material/useMediaQuery'
import request from '../../utils/api/request'
import { create_company, create_service, update_service } from '../../utils/api/apiList'
import { message } from 'antd'
import { Formik } from 'formik'
import * as yup from 'yup'
import { Cancel, Close, Send } from '@mui/icons-material'
import ModalCustom from '../../components/modal/modal'
import TextFieldCustom from '../../components/form/textfield'

const phoneRegExp = /^[0-9]{10}$/
//validate
const validateFormSchema = yup.object().shape({
    name: yup.string().required('Không được bỏ trống'),
    address: yup.string().required('Không được bỏ trống'),
    phoneNumber: yup.string().matches(phoneRegExp, 'Số điện thoại không đúng').required('Yêu cầu nhập số điện thoại'),
})

const ModalCreate = ({ open, onClose, fetchData }) => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    //message
    const [messageApi, messageContextHolder] = message.useMessage()

    //media query
    const matches = useMediaQuery('(min-width:600px)')

    //intialformvalues
    const initialFormValues = {
        name: '',
        address: '',
        phoneNumber: '',
        serviceType: 0,
    }

    //handle save
    const handleSave = (values, { resetForm, setFieldError }) => {
        console.log(values)
        const dataCompany = { name: values.name, phoneNumber: values.phoneNumber, address: values.address }
        request
            .post(create_company, { ...values })
            .then((res) => {
                message.success('Tạo công ty thành công')
                fetchData()
                onClose()
                resetForm()
            })
            .catch((err) => {
                const textErr = err.response.data.data
                if (textErr === 'PhoneInUse') {
                    message.error('Số điện thoại đã được đăng ký!')
                    setFieldError('phoneNumber', 'Số điện thoại đã được đăng ký!')
                } else {
                    message.error('Lỗi, tạo công ty thất bại, vui lòng liên hệ dev!')
                }
                console.log(err)
            })
        //resetForm
    }

    return (
        <ModalCustom
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    onClose
                }
            }}
            style={{ backdropFilter: 'blur(5px)' }}
            width= {matches ? 700 : '90vw'}
            bgcolor= {theme.palette.mode === 'dark' ? '#1F2A40' : '#e0e0e0'}
            sxBox={{
                border: '1px solid rgba(0, 0, 0, 0.1) 0px 4px 12px',
                p: matches ? 4 : 2,
                '& .box': {
                    '& .MuiTypography-root': {
                        minWidth: '120px',
                    },
                    '& .MuiBox-root.select': {
                        width: '100%',
                    },
                    display: 'flex',
                    alignItems: 'center',
                    gap: '20px',
                },
            }}
        >
            {messageContextHolder}
            <Typography id='modal-modal-title' color={colors.grey[500]} fontSize='16px'>
                THÊM MỚI CÔNG TY
            </Typography>
            <IconButton
                sx={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                }}
                onClick={onClose}
            >
                <Close color='disabled' />
            </IconButton>

            <Box sx={{ mt: 1 }}>
                <Formik
                    initialValues={initialFormValues}
                    validationSchema={validateFormSchema}
                    onSubmit={(values, { resetForm, setFieldError }) => {
                        handleSave(values, { resetForm, setFieldError })
                    }}
                >
                    {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => {
                        return (
                            <Box
                                component='form'
                                sx={{
                                        '& > :not(style)': { m: 1, width: '92%' },
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    '& #type-company.Mui-focused': {
                                        color: colors.greenAccent[300],
                                    },
                                }}
                                onSubmit={handleSubmit}
                            >
                                 <TextFieldCustom 
                                        label='Tên công ty'
                                        name='name'
                                        color='secondary'
                                        value={values.name}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        error={!!touched.name && !!errors.name}
                                        helperText={touched.name && errors.name}
                                        fullWidth
                                        className='box'
                                        widthField='92%'
                                    />
                                <TextFieldCustom 
                                        label='Số điện thoại'
                                        name='phoneNumber'
                                        color='secondary'
                                        value={values.phoneNumber}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        error={!!touched.phoneNumber && !!errors.phoneNumber}
                                        helperText={touched.phoneNumber && errors.phoneNumber}
                                        fullWidth
                                        className='box'
                                        widthField='92%'
                                    />
                                <TextFieldCustom 
                                        label='Địa chỉ'
                                        name='address'
                                        color='secondary'
                                        value={values.address}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        error={!!touched.address && !!errors.address}
                                        helperText={touched.address && errors.address}
                                        fullWidth
                                        className='box'
                                        widthField='92%'
                                    />
                                <Box 
                                className='box' 
                                sx={{
                                    display: 'flex',
                                    flexDirection: { xs: 'column', sm: 'row' }, 
                                    gap: { xs: '10px!important', sm: '20px!important' },
                                    alignItems: { xs: 'flex-start!important', sm: 'center!important' },
                                    width: { xs: '100% !important', sm: `92%!important` },
                                }}>
                                    <Typography>Mô hình quản lý:</Typography>
                                    <FormControl fullWidth>
                                        <InputLabel id='type-company'>Chọn</InputLabel>
                                        <Select
                                            name='serviceType'
                                            label='Chọn'
                                            labelId='type-company'
                                            color='secondary'
                                            value={values.serviceType}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        >
                                            {[0, 1].map((elm) => (
                                                <MenuItem key={elm} value={elm}>
                                                    {elm === 0 ? 'Gói không giới hạn' : 'Gói theo tháng'}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box mt='16px !important' display='flex' justifyContent='flex-end' sx={{width: { xs: '100% !important', sm: '80% !important' }}}>
                                    <Button
                                        type='submit'
                                        variant='contained'
                                        color='success'
                                        sx={{ minWidth: '96px', p: '8px 16px', mr: '10px', fontWeight: 600 }}
                                        endIcon={<Send />}
                                    >
                                        Lưu
                                    </Button>
                                    <Button
                                        sx={{ minWidth: '96px', p: '8px 16px' }}
                                        variant='outlined'
                                        color='inherit'
                                        startIcon={<Cancel />}
                                        onClick={onClose}
                                    >
                                        Huỷ
                                    </Button>
                                </Box>
                            </Box>
                        )
                    }}
                </Formik>
            </Box>
        </ModalCustom>
    )
}

export default ModalCreate
