import { Box, Button, IconButton, Modal, TextField, Typography, useTheme } from '@mui/material'
import { tokens } from '../../../../theme'
import { Formik } from 'formik'
import { Cancel, Close, Send } from '@mui/icons-material'
import { formatNumber, parseNumber } from '../../../../components/prefixData'

function BoxNapRutCocTien({ open, onClose, dataPartner, initialFormValues, validateSchema, handleConfirm }) {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    const styleBox = {
        '& .MuiBox-root': {
            display: 'flex',
            alignItems: 'center',
            gap: '20px',
            width: '95%',
            '& .MuiTextField-root': {
                flex: 1,
            },
        },
        '& .MuiTypography-root': {
            minWidth: '120px',
            fontSize: '14px',
        },
        '& .MuiBox-root.select': {
            width: '100%',
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px',
        maxHeight: '70vh',
        overflowY: 'auto',
        pt: '20px',
        backgroundColor: theme.palette.mode === 'dark' ? colors.primary[300] : '#e7e7e7',
        borderRadius: '5px',
        '& .Mui-focused fieldset': {
            borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.6) !important' : 'rgba(0, 0, 0, 0.8)', // Màu border khi focused
            borderWidth: theme.palette.mode === 'dark' ? '2px' : '1px !important',
        },
        // background: 'radial-gradient(black, transparent)',
        background: colors.primary[300],
        // p: '20px',
    }

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '500px',
                    bgcolor: theme.palette.mode === 'dark' ? colors.primary[300] : '#e7e7e7',
                    boxShadow: 24,
                    p: 4,
                    border: 'unset',
                    borderRadius: '4px',
                    '& .MuiInputBase-input': {
                        pl: '10px',
                        pr: '10px',
                    },
                }}
            >
                <IconButton
                    sx={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                    }}
                    onClick={onClose}
                >
                    <Close color='disabled' />
                </IconButton>
                <Typography color={colors.grey[400]} sx={{ color: colors.grey[200] }} fontSize='16px' fontWeight={600}>
                    NẠP TIỀN
                </Typography>
                <Formik initialValues={initialFormValues} validationSchema={validateSchema} onSubmit={handleConfirm} enableReinitialize={true}>
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
                        return (
                            <Box component='form' onSubmit={handleSubmit} sx={styleBox}>
                                <Box>
                                    <Typography>Tên: </Typography>
                                    <TextField name='fullName' value={dataPartner.fullName} disabled />
                                </Box>
                                <Box>
                                    <Typography>Ví tiền: </Typography>
                                    <TextField name='balance' value={formatNumber(dataPartner.balance)} disabled />
                                </Box>
                                <Box>
                                    <Typography>Số tiền nạp: </Typography>
                                    <TextField
                                        name='deposit'
                                        value={formatNumber(values.deposit)}
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                            const rawValue = parseNumber(e.target.value)
                                            setFieldValue('deposit', Number(rawValue))
                                        }}
                                        error={!!touched.deposit && !!errors.deposit}
                                        helperText={touched.deposit && errors.deposit}
                                    />
                                </Box>
                                <Box>
                                    <Typography>Chú thích: </Typography>
                                    <TextField
                                        multiline
                                        rows={5}
                                        // maxRows={6}
                                        name='note'
                                        value={values.note}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        error={!!touched.note && !!errors.note}
                                        helperText={touched.note && errors.note}
                                    />
                                </Box>
                                <Box justifyContent='end'>
                                    <Button
                                        sx={{ minWidth: '96px', p: '8px 16px', fontWeight: 600, color: 'white' }}
                                        type='submit'
                                        variant='contained'
                                        color='success'
                                        endIcon={<Send />}
                                    >
                                        Nạp
                                    </Button>
                                    <Button
                                        sx={{ minWidth: '96px', p: '8px 16px' }}
                                        variant='outlined'
                                        color='inherit'
                                        startIcon={<Cancel />}
                                        onClick={onClose}
                                    >
                                        Huỷ
                                    </Button>
                                </Box>
                            </Box>
                        )
                    }}
                </Formik>
            </Box>
        </Modal>
    )
}

export default BoxNapRutCocTien
