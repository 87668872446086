import { routerLinks } from './constant'

import CompanyDashboard from '../scenes/dashboard/companyDashboard'
import Users from '../scenes/users'
import Employee from '../scenes/employee'
import TaskBooking from '../scenes/workflowManagement/taskBooking'
import PermanentTask from '../scenes/workflowManagement/permanentTask'
import Clean from '../scenes/workflowManagement/clean'
import BlankPage from '../scenes/blank'
import AdminLayout from '../layout/AdminLayout'
import LoginLayout from '../layout/loginLayout/LoginLayout'
import Login from '../scenes/login'
import Discount from '../scenes/discount'
import CompanyManagement from '../scenes/companyManagement'
import Service from '../scenes/service'
import PriceList from '../scenes/priceList'
import VerifyEmployee from '../scenes/employee/verifyEmployee'
import Competence from '../scenes/employee/competence'
import Deposit from '../scenes/employee/bankManagement/deposit'
import StatisticsService from '../scenes/statistics/service'
import StatisticsIncome from '../scenes/statistics/income'
import AdminDashboard from '../scenes/dashboard/adminDashboard'
import Transaction from '../scenes/statistics/transaction'
import WithDraw from '../scenes/employee/bankManagement/withdraw'
import CompanyAccountInfo from '../scenes/setting/settingInfo/companyAccountInfo'
import StatisticsBankUser from '../scenes/statistics/bank'
import DetailsPermanentTask from '../scenes/workflowManagement/detailPermanentTask'
import PaymentHistory from '../scenes/employee/paymentHistory'
import Policy from '../scenes/setting/policy'
import AirCondition from '../scenes/workflowManagement/airCondition'
import Banner from '../scenes/banner'
import Sofa from '../scenes/workflowManagement/sofa'
import Spray from '../scenes/workflowManagement/spray'
import AdminSetting from '../scenes/setting/adminSetting'
import CompanySetting from '../scenes/setting/companyCity'
import AnalyzesUser from '../scenes/statistics/admin/users'
import CompanyRevenue from '../scenes/statistics/admin/companyRevenue'
import CompanyProfit from '../scenes/statistics/admin/companyProfit'
import TaskBookingRevenue from '../scenes/statistics/admin/services/revenue/TaskBookingRevenue'
import CleanBookingRevenue from '../scenes/statistics/admin/services/revenue/CleanBookingRevenue'
import PermanentBookingRevenue from '../scenes/statistics/admin/services/revenue/PermanentBookingRevenue'
import SofaBookingRevenue from '../scenes/statistics/admin/services/revenue/SofaBookingRevenue'
import AirBookingRevenue from '../scenes/statistics/admin/services/revenue/AirConditionRevenue'
import SprayBookingRevenue from '../scenes/statistics/admin/services/revenue/SprayRevenue'
import TaskBookingProfit from '../scenes/statistics/admin/services/profit/TaskBookingProfit'
import CleanBookingProfit from '../scenes/statistics/admin/services/profit/CleanBookingProfit'
import PermanentBookingProfit from '../scenes/statistics/admin/services/profit/PermanentProfit'
import AirProfit from '../scenes/statistics/admin/services/profit/AirProfit'
import SofaProfit from '../scenes/statistics/admin/services/profit/SofaProfit'
import SprayProfit from '../scenes/statistics/admin/services/profit/SprayProfit'
import socials from '../scenes/setting/socials'
import AnalyzesDeposit from '../scenes/statistics/admin/deposit'
import AnalyzesPartner from '../scenes/statistics/admin/partner'

const role = localStorage.getItem('role')

// let publicRoutes = [
//     {
//         path: routerLinks.Dashboard,
//         component: role === 'admin' ? AdminDashboard : CompanyDashboard,
//         layout: AdminLayout,
//     },
//     {
//         path: routerLinks.CompanyManagement,
//         component: CompanyManagement,
//         layout: AdminLayout,
//     },
//     {
//         path: role === 'admin' ? routerLinks.Service : '*',
//         component: Service,
//         layout: AdminLayout,
//     },
//     {
//         path: routerLinks.PriceList,
//         component: PriceList,
//         layout: AdminLayout,
//     },
//     {
//         path: routerLinks.Users,
//         component: Users,
//         layout: AdminLayout,
//     },
//     {
//         path: routerLinks.VerifyEmployee,
//         component: VerifyEmployee,
//         layout: AdminLayout,
//     },
//     {
//         path: routerLinks.Competence,
//         component: Competence,
//         layout: AdminLayout,
//     },
//     {
//         path: routerLinks.Employee,
//         component: Employee,
//         // layout: AdminLayout,
//     },
//     {
//         path: routerLinks.TaskBooking,
//         component: TaskBooking,
//         layout: AdminLayout,
//     },
//     {
//         path: routerLinks.PermanentTask,
//         component: PermanentTask,
//         layout: AdminLayout,
//     },
//     {
//         path: routerLinks.DetailsPermanentTask,
//         component: DetailsPermanentTask,
//         layout: AdminLayout,
//     },
//     {
//         path: routerLinks.Clean,
//         component: Clean,
//         layout: AdminLayout,
//     },
//     {
//         path: routerLinks.Aircondition,
//         component: AirCondition,
//         layout: AdminLayout,
//     },
//     {
//         path: routerLinks.Sofa,
//         component: Sofa,
//         layout: AdminLayout,
//     },
//     {
//         path: routerLinks.Spray,
//         component: Spray,
//         layout: AdminLayout,
//     },
//     {
//         path: routerLinks.Deposit,
//         component: Deposit,
//         layout: AdminLayout,
//     },
//     //balance
//     {
//         path: routerLinks.Withdraw,
//         component: WithDraw,
//         layout: AdminLayout,
//     },
//     {
//         path: routerLinks.Discount,
//         component: Discount,
//         layout: AdminLayout,
//     },
//     {
//         path: routerLinks.Banner,
//         component: Banner,
//         layout: AdminLayout,
//     },
//     //payment history
//     {
//         path: routerLinks.PaymentHistory,
//         component: PaymentHistory,
//         layout: AdminLayout,
//     },
//     //statistics
//     {
//         path: routerLinks.StatisticsUser,
//         component: StatisticsUser,
//         layout: AdminLayout,
//     },
//     {
//         path: routerLinks.StatisticsService,
//         component: StatisticsService,
//         layout: AdminLayout,
//     },
//     {
//         path: routerLinks.StatisticsIncome,
//         component: StatisticsIncome,
//         layout: AdminLayout,
//     },
//     {
//         path: routerLinks.StatisticsBankPartner,
//         component: StatisticsBankUser,
//         layout: AdminLayout,
//     },
//     {
//         path: routerLinks.StatisticsTransaction,
//         component: Transaction,
//         layout: AdminLayout,
//     },
//     // setting
//     {
//         path: role === 'admin' ? routerLinks.CitySetting : '*',
//         component: SettingCity,
//         layout: AdminLayout,
//     },
//     {
//         path: routerLinks.CompanyAccountSetting,
//         component: CompanyAccountInfo,
//         layout: AdminLayout,
//     },
//     {
//         path: role === 'admin' ? routerLinks.PolicySetting : '*',
//         component: Policy,
//         layout: AdminLayout,
//     },
//     //blank page
//     {
//         path: '*',
//         component: BlankPage,
//         layout: AdminLayout,
//     },
// ]

// // Filter public routes based on token/role
// if (role === 'admin') {
//     publicRoutes = publicRoutes.filter(
//         (route) =>
//             ![
//                 routerLinks.Workflow,
//                 routerLinks.Employee,
//                 routerLinks.Discount,
//                 routerLinks.NewOrder,
//                 routerLinks.Competence,
//                 routerLinks.Blance,
//                 routerLinks.VerifyEmployee,
//                 routerLinks.StatisticsUser,
//                 routerLinks.StatisticsService,
//                 routerLinks.StatisticsIncome,
//                 // routerLinks.Setting,
//                 routerLinks.StatisticsBankPartner,
//                 routerLinks.StatisticsTransaction,
//                 // routerLinks.PolicySetting,
//                 routerLinks.CompanyAccountSetting,
//                 // routerLinks.CitySetting,
//                 // routerLinks.Faq,
//                 // routerLinks.Bar,
//                 // routerLinks.Service,
//                 routerLinks.Banner,
//                 routerLinks.Discount,
//                 routerLinks.Competence,
//                 routerLinks.Deposit,
//                 routerLinks.Withdraw,
//                 routerLinks.TaskBooking,
//                 routerLinks.PermanentTask,
//                 routerLinks.Clean,
//                 routerLinks.Sofa,
//                 routerLinks.Aircondition,
//                 routerLinks.Spray,
//             ].includes(route.path)
//     )
// }

// Routes cho admin
const adminRoutes = [
    {
        path: routerLinks.Dashboard,
        component: AdminDashboard,
        layout: AdminLayout,
    },
    {
        path: routerLinks.CompanyManagement,
        component: CompanyManagement,
        layout: AdminLayout,
    },
    {
        path: routerLinks.Service,
        component: Service,
        layout: AdminLayout,
    },
    {
        path: routerLinks.PriceList,
        component: PriceList,
        layout: AdminLayout,
    },
    {
        path: routerLinks.Users,
        component: Users,
        layout: AdminLayout,
    },
    {
        path: routerLinks.Deposit,
        component: Deposit,
        layout: AdminLayout,
    },
    {
        path: routerLinks.Withdraw,
        component: WithDraw,
        layout: AdminLayout,
    },
    {
        path: routerLinks.Discount,
        component: Discount,
        layout: AdminLayout,
    },
    {
        path: routerLinks.Banner,
        component: Banner,
        layout: AdminLayout,
    },
    //payment history
    {
        path: routerLinks.PaymentHistory,
        component: PaymentHistory,
        layout: AdminLayout,
    },
    //statistics
    {
        path: routerLinks.AnalyzesUser,
        component: AnalyzesUser,
        layout: AdminLayout,
    },
    {
        path: routerLinks.AnalyzesPartner,
        component: AnalyzesPartner,
        layout: AdminLayout,
    },
    {
        path: routerLinks.CompanyRevenue,
        component: CompanyRevenue,
        layout: AdminLayout,
    },
    {
        path: routerLinks.CompanyProfit,
        component: CompanyProfit,
        layout: AdminLayout,
    },
    // doanh thu dich vu
    {
        path: routerLinks.TaskRevenue,
        component: TaskBookingRevenue,
        layout: AdminLayout,
    },
    {
        path: routerLinks.CleanRevenue,
        component: CleanBookingRevenue,
        layout: AdminLayout,
    },
    {
        path: routerLinks.PermanentRevenue,
        component: PermanentBookingRevenue,
        layout: AdminLayout,
    },
    {
        path: routerLinks.SofaRevenue,
        component: SofaBookingRevenue,
        layout: AdminLayout,
    },
    {
        path: routerLinks.AirRevenue,
        component: AirBookingRevenue,
        layout: AdminLayout,
    },
    {
        path: routerLinks.SprayRevenue,
        component: SprayBookingRevenue,
        layout: AdminLayout,
    },
    // loi nhuan dich vu
    {
        path: routerLinks.TaskProfit,
        component: TaskBookingProfit,
        layout: AdminLayout,
    },
    {
        path: routerLinks.CleanProfit,
        component: CleanBookingProfit,
        layout: AdminLayout,
    },
    {
        path: routerLinks.PermanentProfit,
        component: PermanentBookingProfit,
        layout: AdminLayout,
    },
    {
        path: routerLinks.AirProfit,
        component: AirProfit,
        layout: AdminLayout,
    },
    {
        path: routerLinks.SofaProfit,
        component: SofaProfit,
        layout: AdminLayout,
    },
    {
        path: routerLinks.SprayProfit,
        component: SprayProfit,
        layout: AdminLayout,
    },
    {
        path: routerLinks.StatisticsService,
        component: StatisticsService,
        layout: AdminLayout,
    },
    {
        path: routerLinks.StatisticsIncome,
        component: StatisticsIncome,
        layout: AdminLayout,
    },
    {
        path: routerLinks.StatisticsBankPartner,
        component: StatisticsBankUser,
        layout: AdminLayout,
    },
    {
        path: routerLinks.StatisticsTransaction,
        component: Transaction,
        layout: AdminLayout,
    },
    // thống kê nạp cọc (deposit)
    {
        path: routerLinks.AnalyzesDeposit,
        component: AnalyzesDeposit,
        layout: AdminLayout,
    },

    // setting
    {
        path: routerLinks.AdminSetting,
        component: AdminSetting,
        layout: AdminLayout,
    },
    {
        path: routerLinks.PolicySetting,
        component: Policy,
        layout: AdminLayout,
    },
    {
        path: routerLinks.SocialsSetting,
        component: socials,
        layout: AdminLayout,
    },
]

// Routes cho company (doanh nghiệp)
const companyRoutes = [
    {
        path: routerLinks.Dashboard,
        component: CompanyDashboard,
        layout: AdminLayout,
    },
    {
        path: routerLinks.VerifyEmployee,
        component: VerifyEmployee,
        layout: AdminLayout,
    },
    {
        path: routerLinks.Competence,
        component: Competence,
        layout: AdminLayout,
    },
    {
        path: routerLinks.Employee,
        component: Employee,
        layout: AdminLayout,
    },
    {
        path: routerLinks.TaskBooking,
        component: TaskBooking,
        layout: AdminLayout,
    },
    {
        path: routerLinks.PermanentTask,
        component: PermanentTask,
        layout: AdminLayout,
    },
    {
        path: routerLinks.DetailsPermanentTask,
        component: DetailsPermanentTask,
        layout: AdminLayout,
    },
    {
        path: routerLinks.Clean,
        component: Clean,
        layout: AdminLayout,
    },
    {
        path: routerLinks.Aircondition,
        component: AirCondition,
        layout: AdminLayout,
    },
    {
        path: routerLinks.Sofa,
        component: Sofa,
        layout: AdminLayout,
    },
    {
        path: routerLinks.Spray,
        component: Spray,
        layout: AdminLayout,
    },
    {
        path: routerLinks.CompanyAccountSetting,
        component: CompanyAccountInfo,
        layout: AdminLayout,
    },
    {
        path: routerLinks.Service,
        component: Service,
        layout: AdminLayout,
    },
    {
        path: routerLinks.Discount,
        component: Discount,
        layout: AdminLayout,
    },
    {
        path: routerLinks.Banner,
        component: Banner,
        layout: AdminLayout,
    },
    {
        path: routerLinks.CompanySetting,
        component: CompanySetting,
        layout: AdminLayout,
    },
]

// trang blank
const commonRoutes = [
    {
        path: '*',
        component: BlankPage,
        layout: AdminLayout,
    },
]

// Tùy theo vai trò mà áp dụng các route tương ứng
let publicRoutes = []

if (role === 'admin') {
    publicRoutes = [...adminRoutes, ...commonRoutes]
} else if (role === 'doanhnghiep') {
    publicRoutes = [...companyRoutes, ...commonRoutes]
} else {
    publicRoutes = [...commonRoutes]
}

//Private routes
const privateRoutes = [
    {
        path: routerLinks.Login,
        component: Login,
        layout: LoginLayout,
    },
]

export { publicRoutes, privateRoutes }
