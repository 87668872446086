import BookingRevenue from './BookingRevenue'
import { revenue_clean_by_month_by_year_admin } from '../../../../../utils/api/apiList'

const CleanBookingRevenue = () => {
    return (
        <BookingRevenue
            title='TỔNG VỆ SINH'
            subtitle='Trang thống kê doanh thu tổng vệ sinh'
            apiEndpoint={revenue_clean_by_month_by_year_admin}
            nameChart='Clean'
        />
    )
}

export default CleanBookingRevenue
