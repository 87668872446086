import { useState, useEffect } from 'react'
import {
    Box,
    useTheme,
    Typography,
    Button,
    Autocomplete,
    TextField,
    RadioGroup,
    FormControlLabel,
    FormControl,
    Radio,
    Select,
    MenuItem,
} from '@mui/material'
import Header from '../../../../components/Header'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import moment from 'moment'
import 'moment/locale/vi'
import request from '../../../../utils/api/request'
import { message } from 'antd'
import { get_all_company, total_company_revenue_by_month_by_year, total_company_revenue_from_date_to_date_admin } from '../../../../utils/api/apiList'
import LineChart from '../../../../components/LineChart'
import { formatNumber } from '../../../../components/prefixData'
import { tokens } from '../../../../theme'
import { DatePicker as DatePickerAntd, Space, ConfigProvider } from 'antd'
import dayjs from 'dayjs'

function CompanyRevenue() {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const [companies, setCompanies] = useState([])
    const [selectedYear, setSelectedYear] = useState(moment())
    const [selectedCompanyId, setSelectedCompanyId] = useState(null)
    const [companyName, setCompanyName] = useState('')
    const [dataChart, setDataChartByYear] = useState({})
    const [dataRevenueConvert, setDataRevenueConvert] = useState([])
    const [dataTotalOrderConvert, setTotalOrderConvert] = useState([])
    const [totalRevenue, setTotalRevenue] = useState(0)
    const [totalOrder, setTotalOrder] = useState(0)
    const [reportType, setReportType] = useState('year')
    const [dateRange, setDateRange] = useState([dayjs(), dayjs().add(-7, 'd')])
    const [startDate, setStartDate] = useState([dayjs()])
    const [endDate, setEndDate] = useState([dayjs().add(-7, 'd')])

    //fetching
    useEffect(() => {
        fetchAllCity()
    }, [])

    //fetchData chart
    const fetchDataChartByYear = (data) => {
        request
            .post(total_company_revenue_by_month_by_year, { ...data })
            .then((res) => {
                console.log('data chart', res.data.data)
                const apiData = res.data.data
                convertChartData(apiData)
            })
            .catch((err) => {
                console.error('Loi lay du lieu chart doanh thu cong ty', err)
                message.error('Lỗi: không thể cập nhật dữ liệu, vui lòng thử lại sau!')
            })
    }

    //fetch data date by date
    const fetchDataDateByDate = (data) => {
        request
            .post(total_company_revenue_from_date_to_date_admin, { ...data })
            .then((res) => {
                console.log('data chart date by date', res.data.data)
                const totalDateByDate = res.data.data.totalRevenueDataAdmin

                //data reset
                const dataResetChart = [
                    {
                        _id: {
                            day: dayjs(endDate).date(),
                            month: dayjs(endDate).month() + 1,
                            year: dayjs(endDate).year(),
                        },
                        order: 0,
                        revenue: 0,
                    },
                    {
                        _id: {
                            day: dayjs(startDate).date(),
                            month: dayjs(startDate).month() + 1,
                            year: dayjs(startDate).year(),
                        },
                        order: 0,
                        revenue: 0,
                    },
                ]

                console.log(dataResetChart)

                // hàm converChartDataDateByDate
                totalDateByDate.length > 0 ? convertChartDataDateByDate(totalDateByDate) : convertChartDataDateByDate(dataResetChart)
            })
            .catch((err) => {
                console.error('Loi lay du lieu chart doanh thu cong ty', err)
                message.error('Lỗi: không thể cập nhật dữ liệu, vui lòng thử lại sau!')
            })
    }

    // fetch all company
    const fetchAllCity = () => {
        request
            .post(get_all_company)
            .then((res) => {
                setCompanies(res.data.data.docs)
            })
            .catch((err) => {
                console.error('Loi get all cong ty:', err)
            })
    }

    //const handle selecte company
    const handleSelectCompany = (event, newValue) => {
        // console.log(newValue)

        if (newValue) {
            setSelectedCompanyId(newValue._id)
            setCompanyName(newValue.name)
            if (reportType === 'year') {
                fetchDataChartByYear({
                    companyId: newValue._id,
                    year: selectedYear.year(),
                })
            } else {
                fetchDataDateByDate({
                    companyId: newValue._id,
                    startDate,
                    endDate,
                })
            }
        } else {
            setSelectedCompanyId(null)
        }
    }

    //handle change reportType
    const reportTypes = ['year', 'range']
    const handleReportTypeChange = (event) => {
        const reportType = event.target.value
        setReportType(reportType)
        if (reportType === 'year') {
            fetchDataChartByYear({
                companyId: selectedCompanyId,
                year: selectedYear.year(),
            })
        } else {
            fetchDataDateByDate({
                companyId: selectedCompanyId,
                startDate,
                endDate,
            })
        }
    }

    // Handle change year
    const handleChangeYear = (value) => {
        const year = moment(value).year()
        setSelectedYear(value)

        if (selectedCompanyId) {
            fetchDataChartByYear({
                companyId: selectedCompanyId,
                year,
            })
        }
    }

    //handle change range
    const { RangePicker } = DatePickerAntd
    const onRangeChange = (dates, dateStrings) => {
        if (dates) {
            console.log('From: ', dates[0], ', to: ', dates[1])
            console.log('From: ', dateStrings[0], ', to: ', dateStrings[1])
            setDateRange(dates)
            setStartDate(dates[0])
            setEndDate(dates[1])

            //data
            const data = {
                companyId: selectedCompanyId,
                startDate: dates[0],
                endDate: dates[1],
            }

            //fetch data date by date
            fetchDataDateByDate(data)
        } else {
            console.log('Clear')
            setDateRange([])
            setStartDate(null)
            setEndDate(null)
        }
    }
    const rangePresets = [
        {
            label: '7 ngày trước',
            value: [dayjs().add(-7, 'd'), dayjs()],
        },
        {
            label: '14 ngày trước',
            value: [dayjs().add(-14, 'd'), dayjs()],
        },
        {
            label: '30 ngày trước',
            value: [dayjs().add(-30, 'd'), dayjs()],
        },
        {
            label: '90 ngày trước',
            value: [dayjs().add(-90, 'd'), dayjs()],
        },
    ]

    // Hàm chuyển đổi dữ liệu từ server thành format của Nivo Line Chart
    const convertChartData = (apiData) => {
        const revenueData = {
            id: 'Doanh thu',
            data: [],
        }

        const orderData = {
            id: 'Đơn hàng',
            data: [],
        }

        // console.log('apiData', apiData)
        let totalRevenue = 0
        let totalOrder = 0

        // Duyệt qua các tháng và đẩy dữ liệu vào mảng
        Object.keys(apiData).forEach((month) => {
            const monthLabel = `Tháng ${month}` // Định dạng nhãn trục X
            const revenue = apiData[month].totalRevenue
            const allOrder = apiData[month].totalOrder

            revenueData.data.push({ x: String(monthLabel), y: apiData[month].totalRevenue / 1000000 })
            orderData.data.push({ x: String(monthLabel), y: apiData[month].totalOrder })

            totalRevenue += revenue
            totalOrder += allOrder
        })

        // console.log(totalRevenue)

        console.log('revenueData', revenueData)
        console.log('orderdata', orderData)

        setDataRevenueConvert([revenueData])
        setTotalOrderConvert([orderData])

        setTotalRevenue(totalRevenue)
        setTotalOrder(totalOrder)
    }

    // Hàm chuyển đổi dữ liệu từ API thành format của Nivo Line Chart (theo thời điểm từ ngày đến ngày)
    const convertChartDataDateByDate = (apiData) => {
        const revenueData = {
            id: 'Doanh thu',
            data: [],
        }

        const orderData = {
            id: 'Đơn hàng',
            data: [],
        }

        let totalRevenue = 0
        let totalOrder = 0

        // Duyệt qua từng phần tử trong dữ liệu trả về từ API
        apiData?.forEach((entry) => {
            const { day, month, year } = entry._id
            const dateLabel = `${day}/${month}/${year}` // Định dạng nhãn trục X theo ngày/tháng/năm
            const revenue = entry.revenue
            const order = entry.order

            // Đẩy dữ liệu doanh thu và đơn hàng vào mảng theo format của Nivo Line Chart
            revenueData.data.push({ x: dateLabel, y: revenue / 1000000 })
            orderData.data.push({ x: dateLabel, y: order })

            // Tính tổng doanh thu và đơn hàng
            totalRevenue += revenue
            totalOrder += order
        })

        // Đặt dữ liệu vào state của chart
        setDataRevenueConvert([revenueData])
        setTotalOrderConvert([orderData])

        // Cập nhật tổng doanh thu và tổng đơn hàng
        setTotalRevenue(totalRevenue)
        setTotalOrder(totalOrder)

        console.log('revenueData', revenueData)
        console.log('orderData', orderData)
    }

    return (
        <Box
            m='20px'
            mb='50px'
            sx={{
                '& .MuiTypography-root.MuiTypography-h2': {
                    margin: '0 0 5px 0',
                },
                '& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: colors.grey[100],
                    borderWidth: '1px',
                },
                '& .MuiInputLabel-root.Mui-focused': { color: colors.grey[100] },
                '& .ant-picker-range-separator .ant-picker-separator .anticon.anticon-swap-right svg': {
                    color: colors.greenAccent[100],
                },
                '& .ant-picker .ant-picker-input >input': {
                    color: colors.grey[100],
                    fontSize: '13px',
                },
                '& .ant-picker-large .ant-picker-input>input::placeholder': {
                    color: colors.grey[100],
                    fontSize: '13px',
                },
                '& .ant-picker-suffix .anticon.anticon-calendar svg': {
                    color: colors.grey[100],
                },
                '& .ant-picker-clear .anticon.anticon-close-circle svg': {
                    color: colors.grey[100],
                },
            }}
        >
            <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Header title='THỐNG KÊ DOANH THU CÔNG TY' subtitle='Trang thống kê doanh thu công ty' />
            </Box>

            <Box height='75vh' m='40px 0 0 0'>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box sx={{ display: 'flex', gap: '20px' }}>
                        <Box width={300} mb={2}>
                            <Autocomplete
                                freeSolo
                                options={companies}
                                getOptionLabel={(option) => option.name}
                                onChange={handleSelectCompany}
                                renderInput={(params) => <TextField {...params} label='Chọn công ty' variant='outlined' />}
                            />
                        </Box>

                        {/* Chọn kiểu thống kê: Theo năm hoặc Theo thời điểm */}
                        <FormControl>
                            <Select name='reportType' value={reportType} onChange={handleReportTypeChange}>
                                {reportTypes.map((type) => {
                                    let nameType
                                    switch (type) {
                                        case 'year':
                                            nameType = 'Thống kê theo năm'
                                            break
                                        case 'range':
                                            nameType = 'Thống kê theo thời điểm'
                                            break
                                        default:
                                            break
                                    }
                                    return (
                                        <MenuItem key={type} value={type}>
                                            {nameType}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>

                        {/* Date Picker khi chọn "Theo năm" */}
                        {reportType === 'year' && (
                            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={moment.locale('vi')}>
                                <DatePicker
                                    label='Chọn năm'
                                    views={['year']}
                                    value={selectedYear}
                                    onChange={handleChangeYear}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        )}

                        {/* Date Range Pickers khi chọn "Theo thời điểm" */}
                        {reportType === 'range' && (
                            <>
                                <Space direction='vertical' size={24}>
                                    <RangePicker
                                        size='large'
                                        presets={rangePresets}
                                        onChange={onRangeChange}
                                        style={{ padding: '15px', backgroundColor: colors.primary[500] }}
                                        placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
                                        value={dateRange}
                                    />
                                </Space>
                                {/* <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={moment.locale('vi')}>
                                    <DatePicker
                                        label='Từ ngày'
                                        value={startDate}
                                        onChange={handleStartDateChange}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>

                                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={moment.locale('vi')}>
                                    <DatePicker
                                        label='Đến ngày'
                                        value={endDate}
                                        onChange={handleEndDateChange}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider> */}
                            </>
                        )}
                    </Box>

                    {companyName && reportType === 'year' ? (
                        <Box>
                            <Box mr={'150px'} fontWeight={600} color={colors.grey[100]}>
                                TỔNG DOANH THU TRONG NĂM {selectedYear.year()}:{' '}
                                <Typography fontWeight={700} display='inline-block' variant='h4' color={colors.greenAccent[500]}>
                                    {formatNumber(totalRevenue)} VNĐ
                                </Typography>
                            </Box>
                            <Box mr={'150px'} fontWeight={600} color={colors.grey[100]}>
                                TỔNG ĐƠN HÀNG TRONG NĂM {selectedYear.year()}:{' '}
                                <Typography fontWeight={700} display='inline-block' variant='h4' color={colors.greenAccent[500]}>
                                    {formatNumber(totalOrder)}
                                </Typography>
                            </Box>
                        </Box>
                    ) : (
                        <Box>
                            <Box mr={'150px'} fontWeight={600} color={colors.grey[100]}>
                                DOANH THU :{' '}
                                <Typography fontWeight={700} display='inline-block' variant='h4' color={colors.greenAccent[500]}>
                                    {formatNumber(totalRevenue)} VNĐ
                                </Typography>
                            </Box>
                            <Box mr={'150px'} fontWeight={600} color={colors.grey[100]}>
                                TỔNG ĐƠN HÀNG :{' '}
                                <Typography fontWeight={700} display='inline-block' variant='h4' color={colors.greenAccent[500]}>
                                    {formatNumber(totalOrder)}
                                </Typography>
                            </Box>
                        </Box>
                    )}
                </Box>
                {/* Biểu đồ doanh thu theo nam */}
                {dataRevenueConvert.length > 0 && reportType === 'year' && (
                    <LineChart dataChart={dataRevenueConvert} nameChart='revenue' companyName={companyName} selectedDate={selectedYear} />
                )}
                {dataTotalOrderConvert.length > 0 && reportType === 'year' && (
                    <LineChart dataChart={dataTotalOrderConvert} nameChart='totalOrder' companyName={companyName} selectedDate={selectedYear} />
                )}
                {/* Biểu đồ doanh thu theo thời điểm */}
                {dataRevenueConvert.length > 0 && reportType === 'range' && (
                    <LineChart
                        dataChart={dataRevenueConvert}
                        nameChart='revenueDateByDate'
                        companyName={companyName}
                        selectedDate={dayjs(endDate).format('DD/MM/YYYY') + ' - ' + dayjs(startDate).format('DD/MM/YYYY')}
                    />
                )}
                {dataTotalOrderConvert.length > 0 && reportType === 'range' && (
                    <LineChart
                        dataChart={dataTotalOrderConvert}
                        nameChart='totalOrderDateByDate'
                        companyName={companyName}
                        selectedDate={dayjs(endDate).format('DD/MM/YYYY') + ' - ' + dayjs(startDate).format('DD/MM/YYYY')}
                    />
                )}
            </Box>
        </Box>
    )
}

export default CompanyRevenue
