import { Box, Typography, useTheme, IconButton, Tooltip, Button, Chip, debounce } from '@mui/material'
import StyleDataGrid from '../../../assets/styles/styleDataGrid'
import Header from '../../../components/Header'
import { Add } from '@mui/icons-material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { getRowClassName, formatNumber, processData, phoneRegExp } from '../../../components/prefixData'
import minutesToHours from '../../../algorithm/minutesToHours'
import connectSocket from '../../../utils/api/connectSocket'
import { message } from 'antd'
import request from '../../../utils/api/request'
import {
    add_tasker_for_airconditionbooking,
    admin_cancel_airconditionbooking,
    create_airconditionbooking,
    create_airconditiondetail,
    delete_airconditionbooking,
    find_tasker_doing,
    get_airconditionbooking_by_id,
    get_all_airconditionbookings,
    get_all_airconditiondetails_for_city,
    get_user_profile,
} from '../../../utils/api/apiList'
import CustomNoRowsOverlay from '../../../components/noRows'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import CustomLoadingComponent from '../../../components/loading'
import PortraitIcon from '@mui/icons-material/Portrait'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import PopoverBoxAsignEmployee from '../../../components/boxAsignEmployee'
import openMessageNotification from '../../../components/notification/message'
import * as yup from 'yup'
import ModalAir from './modal'
import moment from 'moment'
import { tokens } from '../../../theme'
import ModalAirDetail from './modalDetail'
import { admin_cancel_task, admin_change_partner, admin_update_task, admin_update_task_for_partner } from '../../../utils/api/apiSocket'
import BoxDelete from '../../../components/popover'
import DeleteIcon from '@mui/icons-material/Delete'
import { useLocation } from 'react-router-dom'
import { getEventNewOrderSelector } from '../../../redux/selectors'
import { useSelector } from 'react-redux'

const validateSchema = (isEdit) => {
    return yup.object().shape({
        name: yup.string().required('tên khách hàng là bắt buộc'),
        phoneNumber: yup.string().matches(phoneRegExp).required('số điện thoại khách là bắt buộc'),
        time: yup.date().typeError('lỗi định dạng').required('thời gian là bắt buộc'),
        date: yup.date().typeError('lỗi định dạng').required('ngày làm là bắt buộc'),
        address: yup.string().required('địa chỉ là bắt buộc'),
        // quantity: yup
        //     .number()
        //     .typeError('số lượng là số nguyên dương')
        //     .integer('Phải là số nguyên')
        //     .positive('Phải là số nguyên dương')
        //     .required('không được để trống'),
        // airConditionService: yup.object().required('Chọn dịch vụ'),
        airConditionService: isEdit ? yup.mixed().notRequired() : yup.array().required('Chọn dịch vụ'),
        price: yup.number().typeError('Giá tiền phải là số dương').min(0, 'Giá phải lớn hơn hoặc bằng 0').required('Yêu cầu nhập giá'),
    })
}

function AirCondition() {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const [dataAirCondition, setDataAirCondition] = useState(null)
    const [loading, setLoading] = useState(false)
    const [currentRow, setCurrentRow] = useState(null)
    const [anchorElBoxAsign, setAnchorElBoxAsign] = useState(null)
    const [dataPartnerForTask, setDataPartnerForTask] = useState(null)
    const [dataSwitchPartner, setDataSwitchPartner] = useState(null)
    const [detailsAnchorEl, setDetailsAnchorEl] = useState(null)
    const [dataDetails, setDataDetails] = useState(null)
    const [anchorElBoxCreate, setAnChorElBoxCreate] = useState(null)
    const [isLoadingCreate, setIsLoadingCreate] = useState(false)
    const [services, setServices] = useState(null)
    const [anchorElBoxCancel, setAnchorElBoxCancel] = useState(null)
    const [anchorElDeleteTask, setAnchorElDeleteTask] = useState(null)

    //
    // const location = useLocation()
    // const { infoWork } = location.state || {}
    // const _id = infoWork?.data?._id

    // id company
    const companyId = localStorage.getItem('companyId')

    // pagination server side
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 25,
    })
    const [totalRows, setTotalRows] = useState(0)
    //search server side
    const [searchTerm, setSearchTerm] = useState('')
    const [inputText, setInputText] = useState('')
    //sort server
    const [queryOptions, setQueryOptions] = useState({ createdAt: -1 })

    //socket
    const [socket, setSocket] = useState(null)

    //message
    const [messageApi, messageContextHolder] = message.useMessage()

    //initialValues
    const [initialFormValues, setInitialFormValues] = useState({
        name: '',
        phoneNumber: '',
        time: moment(),
        date: moment(),
        address: '',
        quantities: {},
        price: null,
        airConditionService: [],
    })

    // Debounced
    const debouncedSetSearchTerm = useCallback(
        debounce((value) => {
            setSearchTerm(value)
        }, 700),
        []
    )

    // sort server
    const handleSortModelChange = useCallback((sortModel) => {
        if (sortModel.length > 0) {
            const sortField = sortModel[0].field
            const sortOrder = sortModel[0].sort === 'asc' ? 1 : -1
            setQueryOptions({ [sortField]: sortOrder })
        } else {
            setQueryOptions({ createdAt: -1 })
        }
    }, [])

    // dispartch
    const eventNewOrder = useSelector(getEventNewOrderSelector)

    //fetching
    useEffect(() => {
        fetchData()

        const socketInstance = connectSocket()
        setSocket(socketInstance)

        socketInstance.on('connect', () => {
            console.log('Socket is connected')
        })

        return () => {
            socketInstance.disconnect()
        }
    }, [eventNewOrder, paginationModel, searchTerm, queryOptions])

    //fetchData
    const fetchData = () => {
        setLoading(true)
        request
            .post(get_all_airconditionbookings, {
                page: paginationModel.page + 1,
                limit: paginationModel.pageSize,
                search: searchTerm,
                sort: queryOptions,
            })
            .then((res) => {
                let dataRs = processData(res.data.data.docs, paginationModel.page, paginationModel.pageSize, 'airCondition')

                // Sắp xếp theo ngày tạo
                // dataRs = dataRs.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

                const dataConvert = dataRs.map((data) => ({
                    ...data,
                    nameService: data?.airConditionService?.name,
                    partnerName: data?.taskerId?.fullName ?? '',
                    userName: data?.userId?.fullName ?? data?.userFromAdminOrder?.fullName,
                }))

                console.log(dataConvert)

                setDataAirCondition(dataConvert)
                setTotalRows(res.data.data.total)
                setLoading(false)
            })
            .catch((err) => {
                console.log(err)
                setLoading(false)
            })
    }

    //find tasker freetime
    const fetchDataPartnerForTask = (data) => {
        request
            .post(find_tasker_doing, { ...data })
            .then((res) => {
                setDataPartnerForTask(res.data.data.isFree)
                // dispatch(taskBookingSlice.actions.getData(res.data.data.isFree))
            })
            .catch((err) => console.log(err))
    }

    //fetch add partner
    const fetchAddPartner = (data) => {
        request
            .post(add_tasker_for_airconditionbooking, { ...data })
            .then((res) => {
                console.log('addPartner', res)

                const taskerId = res.data.data.taskerId
                const oldTaskerId = currentRow?.taskerId?._id
                const _id = currentRow?._id

                // admin update task to user (notification user)
                socket.emit(admin_update_task, {
                    _id,
                    userId: currentRow.userId, //id của user
                    note: 'gán nhân viên -> thông báo giành cho user ',
                    type: 'airBooking',
                })

                // admin update task to partner (notification partner)
                socket.emit(admin_update_task_for_partner, {
                    _id,
                    userId: taskerId, //id của partner
                    note: 'gán nhân viên điều hoà ->> thong bao nay gianh cho partner',
                    type: 'airBooking',
                })

                //đổi nhân viên
                if (dataSwitchPartner) {
                    socket.emit(admin_change_partner, {
                        _id,
                        userId: oldTaskerId, //id cua partner cũ
                        note: 'Change partner socket',
                        type: 'airBooking',
                    })
                }

                // notification page
                if (dataSwitchPartner) {
                    openMessageNotification(messageApi, 'success', 'switchPartner', data?.fullName)
                    setDataSwitchPartner(null)
                } else {
                    openMessageNotification(messageApi, 'success', 'asignPartner', data?.fullName)
                }

                fetchData()
            })
            .catch((err) => {
                console.log(err)
                message.error('Lỗi: gán nhân viên thất bại!')
            })
    }

    //fetchInfo user
    const fetchInfoUser = (_id) => {
        request
            .post(get_airconditionbooking_by_id, { _id })
            .then((res) => {
                console.log(res)

                setDataDetails(res.data.data)
            })
            .catch((err) => console.log(err))
    }

    //fetchCreate
    const fetchCreate = (data, resetForm) => {
        request
            .post(create_airconditionbooking, { ...data })
            .then((res) => {
                console.log(res)
                message.success('Tạo task thành công!')
                fetchData()
                setAnChorElBoxCreate(false)
                resetForm()
            })
            .catch((err) => {
                console.log(err)
                message.error('Lỗi: tạo task thất bại!')
            })
            .finally(() => {
                setIsLoadingCreate(false)
            })
    }

    //fetch cancel task
    const fetchCancelTask = (data) => {
        request
            .post(admin_cancel_airconditionbooking, { ...data })
            .then((res) => {
                console.log(res)
                message.success('huỷ đơn điều hoà thành công!')
                fetchData()

                // gửi socket (giành cho đơn của user tạo)
                const userId = res.data?.data?.userId
                const _id = res.data?.data?._id
                socket.emit(admin_cancel_task, {
                    reason: data.cancelReason,
                    userId,
                    _id,
                })
            })
            .catch((err) => {
                console.error(err)
                message.error('Lỗi: huỷ đơn thất bại')
            })
            .finally(() => setAnchorElBoxCancel(false))
    }

    //fetch delete
    const fetchDelete = (_id) => {
        request
            .post(delete_airconditionbooking, { _id })
            .then((res) => {
                console.log(res)
                message.success('Xoá đơn hàng thành công!')
                fetchData()
            })
            .catch((err) => {
                console.error(err)
                message.error('Lỗi: xoá đơn hàng thất bại')
            })
            .finally(() => setAnchorElDeleteTask(false))
    }

    //handle create task
    const handleOpenBoxCreateTask = (e) => {
        console.log('aaa')
        setAnChorElBoxCreate(e.currentTarget)
    }

    const handleCloseBoxCreate = () => {
        setAnChorElBoxCreate(false)
    }

    const handleSubmitCreate = (values, { resetForm }) => {
        const selectedServiceIds = values.airConditionServices.map((service) => service._id)

        // Lọc lại quantityDetail chỉ chứa các dịch vụ đã được chọn
        const filteredQuantities = Object.keys(values.quantities)
            .filter((serviceId) => selectedServiceIds.includes(serviceId))
            .reduce((acc, serviceId) => {
                acc[serviceId] = values.quantities[serviceId]
                return acc
            }, {})

        const data = {
            time: moment(values.time).hour() * 60 + moment(values.time).minute(),
            date: values.date,
            price: values.price,
            quantity: Object.values(filteredQuantities).reduce((acc, curr) => acc + curr, 0),
            address: values.address,
            airConditionService: selectedServiceIds,
            userFromAdminOrder: {
                fullName: values.name,
                address: values.address,
                phoneNumber: values.phoneNumber,
            },
            quantityDetail: filteredQuantities,
            note: '',
            companyId, //flow nhiều công ty
        }
        // console.log({ data })

        //fetching create
        setIsLoadingCreate(true)
        const timerCreateDiscount = setTimeout(() => {
            fetchCreate(data, resetForm)
        }, 500)

        return () => clearTimeout(timerCreateDiscount)
    }

    //column
    const columns = useMemo(
        () => [
            // { field: 'index', headerName: 'STT', width: 50 },
            { field: '_id', headerName: 'Mã đơn hàng', minWidth: 200, sortable: false },
            // { field: 'quantity', headerName: 'Đơn vị', width: 100, align: 'center', sortable: false },
            {
                field: 'userName',
                headerName: 'Tên khách hàng',
                width: 150,
                sortable: false,
            },
            {
                field: 'partnerName',
                headerName: 'Tên người làm',
                width: 150,
                sortable: false,
            },
            {
                field: 'price',
                headerName: 'Giá đơn hàng',
                width: 120,
                cellClassName: 'cost-column name-column--cell',
                renderCell: (params) => {
                    return (
                        <Typography fontSize='14px' fontWeight={600}>
                            {formatNumber(params.row.price)} VNĐ
                        </Typography>
                    )
                },
                sortable: false,
            },
            {
                field: 'date',
                headerName: 'Thời gian',
                width: 100,
                renderCell: ({ row }) => {
                    const startTime = minutesToHours(row.time)

                    return (
                        <Box>
                            <Typography
                                className='colorTime'
                                // color={theme.palette.mode === 'dark' ? colors.greenAccent[400] : '#1939e3'}
                                fontSize='14px'
                                fontWeight={600}
                            >
                                {startTime}
                            </Typography>
                            <Typography fontSize='14px'>{row?.date}</Typography>
                        </Box>
                    )
                },
            },
            {
                field: 'address',
                headerName: 'Địa điểm',
                flex: 1,
                minWidth: 300,
                renderCell: ({ row }) => {
                    return (
                        <Tooltip title={<h1 style={{ fontSize: '11px' }}>{row?.address}</h1>}>
                            <Typography fontSize='14px'>{row?.address}</Typography>
                        </Tooltip>
                    )
                },
                sortable: false,
            },
            {
                field: 'status',
                headerName: 'Trạng thái đơn',
                width: 150,
                minWidth: 100,
                renderCell: ({ row }) => {
                    const getColor = (statusRoot) => {
                        switch (statusRoot) {
                            case 0:
                                return 'success'
                            case 2:
                                return 'info'
                            default:
                                return 'default'
                        }
                    }

                    return (
                        <Chip
                            key={row.status}
                            label={row.status}
                            color={getColor(row.statusRoot)}
                            sx={{
                                minWidth: '100px',
                            }}
                        />
                    )
                },
            },
            {
                field: 'action',
                headerName: 'Actions',
                cellClassName: 'action-column',
                width: 200,
                minWidth: 200,
                sortable: false,
                renderCell: ({ row }) => <ActionRender row={row} />,
            },
        ],
        [dataAirCondition]
    )

    //action render
    const ActionRender = ({ row }) => {
        return (
            <Box display='flex' alignItems='center' justifyContent='center' gap={1}>
                <Tooltip
                    title='chi tiết'
                    slotProps={{
                        popper: {
                            modifiers: [
                                {
                                    name: 'offset',
                                    options: {
                                        offset: [0, -10],
                                    },
                                },
                            ],
                        },
                    }}
                >
                    <IconButton onClick={(e) => handleOpenDetailsModal(row, e)}>
                        <MoreHorizIcon />
                    </IconButton>
                </Tooltip>
                {row.statusRoot === 0 ? (
                    <Tooltip
                        title='gán nhân viên'
                        slotProps={{
                            popper: {
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, -10],
                                        },
                                    },
                                ],
                            },
                        }}
                    >
                        <IconButton
                            onClick={(e) => handleOpenBoxAsignEmployee(row, e)}
                            color='secondary'
                            aria-label='edit'
                            className='colorIconAsign'
                        >
                            <PortraitIcon />
                        </IconButton>
                    </Tooltip>
                ) : row.statusRoot === 1 || row.statusRoot === 3 ? (
                    <Tooltip
                        title='đổi nhân viên'
                        slotProps={{
                            popper: {
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, -10],
                                        },
                                    },
                                ],
                            },
                        }}
                    >
                        <IconButton
                            onClick={(e) => handleChangePartner(row, e, 'switchPartner')}
                            color={[3, 4].includes(row.statusRoot) ? 'info' : 'warning'}
                            aria-label='edit'
                        >
                            <PortraitIcon />
                        </IconButton>
                    </Tooltip>
                ) : null}
                {row.statusRoot === 0 || row.statusRoot === 1 || row.statusRoot === 4 ? (
                    <Tooltip
                        title='Huỷ đơn'
                        slotProps={{
                            popper: {
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, -10],
                                        },
                                    },
                                ],
                            },
                        }}
                    >
                        <IconButton onClick={(e) => handleOpenBoxCancelTask(row, e)} color='error'>
                            <HighlightOffIcon />
                        </IconButton>
                    </Tooltip>
                ) : (
                    <></>
                )}
                {row.statusRoot === 0 && row.hasOwnProperty('userFromAdminOrder') && (
                    <Tooltip
                        title='Xoá đơn'
                        slotProps={{
                            popper: {
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, -10],
                                        },
                                    },
                                ],
                            },
                        }}
                    >
                        <IconButton onClick={(e) => handleDeleteTask(row, e)} color='default'>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                )}
            </Box>
        )
    }

    //change partner
    const handleChangePartner = (row, event, switchPartner) => {
        handleOpenBoxAsignEmployee(row, event)
        setDataSwitchPartner(switchPartner)
    }

    // box gán nhân viên
    const handleOpenBoxAsignEmployee = (row, event) => {
        let date, time, data
        date = row.dateRoot
        time = row.time
        data = { date, time }
        fetchDataPartnerForTask(data)
        setAnchorElBoxAsign(event.currentTarget)
        setCurrentRow(row)
    }
    const handleCloseBoxAsignEmployee = () => {
        setAnchorElBoxAsign(false)
        setDataSwitchPartner('')
    }

    //modal details - edit
    const handleOpenDetailsModal = (row, event) => {
        setCurrentRow(row)
        setDetailsAnchorEl(event.currentTarget)
        const _id = row._id
        fetchInfoUser(_id)
        console.log(row._id)
    }

    // close modal details
    const handleCloseModalDetails = () => {
        setDetailsAnchorEl(false)
    }

    //cancel
    const handleOpenBoxCancelTask = (row, e) => {
        console.log(row)
        setCurrentRow(row)
        setAnchorElBoxCancel(e.currentTarget)
    }

    const handleCloseBoxCancelTask = () => {
        setAnchorElBoxCancel(false)
    }

    const handleConfirmCancelTask = (cancelReason) => {
        const data = {
            _id: currentRow._id,
            cancelReason,
        }
        // console.log(data)

        fetchCancelTask(data)
    }

    //delete
    const handleDeleteTask = (row, e) => {
        setCurrentRow(row)
        setAnchorElDeleteTask(e.currentTarget)
    }
    const handleCloseBoxDeleteTask = () => {
        setAnchorElDeleteTask(false)
    }
    const handleConfirmDeleteTask = () => {
        fetchDelete(currentRow._id)
    }

    // handle search
    const handleInputChange = (e) => {
        const value = e.target.value
        setInputText(value)
        debouncedSetSearchTerm(value)
    }

    return (
        <Box
            m='20px'
            sx={{
                '& .MuiTypography-root.MuiTypography-h2': {
                    margin: '0 0 5px 0',
                },
                '& .colorTime': {
                    // color: 'red'
                    color: theme.palette.mode === 'dark' ? colors.greenAccent[400] : '#1939e3',
                },
                '& .colorIconAsign .MuiSvgIcon-root': {
                    color: theme.palette.mode === 'dark' ? 'inherit' : '#fff',
                },
            }}
        >
            {messageContextHolder}
            <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Header title='QUẢN LÝ ĐƠN ĐIỀU HOÀ' subtitle='Trang quản lý đơn hàng điều hoà' />
                <Button
                    sx={{
                        backgroundColor: colors.blueAccent[700],
                        color: 'white',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        padding: '10px 20px',
                        '&:hover': {
                            backgroundColor: colors.blueAccent[600],
                        },
                    }}
                    startIcon={<Add />}
                    onClick={(e) => handleOpenBoxCreateTask(e)}
                >
                    Tạo đơn hàng
                </Button>
            </Box>
            <Box height='75vh' m='40px 0 0 0' sx={StyleDataGrid()}>
                <DataGrid
                    getRowId={(row) => row._id}
                    rows={dataAirCondition}
                    rowCount={totalRows}
                    columns={columns}
                    //pagination server side
                    pagination
                    initialState={{
                        pagination: { paginationModel },
                        density: 'comfortable',
                    }}
                    pageSizeOptions={[10, 25, 50, 100]}
                    paginationMode='server'
                    onPaginationModelChange={setPaginationModel}
                    //pagination server side

                    // sort server
                    sortingMode='server'
                    onSortModelChange={handleSortModelChange}
                    loading={loading}
                    slots={{
                        toolbar: GridToolbar,
                        noRowsOverlay: CustomNoRowsOverlay,
                        loadingOverlay: CustomLoadingComponent,
                    }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: {
                                onChange: handleInputChange,
                                value: inputText,
                                placeholder: 'tìm kiếm theo địa chỉ',
                            },
                        },
                    }}
                    getRowClassName={(params) => getRowClassName(params)}
                />
            </Box>

            {anchorElBoxAsign && (
                <PopoverBoxAsignEmployee
                    boxName={'airCondition'}
                    open={Boolean(anchorElBoxAsign)}
                    onClose={handleCloseBoxAsignEmployee}
                    dataPartnerForTask={dataPartnerForTask}
                    currentRow={currentRow}
                    setCurrentRow={setCurrentRow}
                    fetchAddPartner={fetchAddPartner}
                />
            )}

            {anchorElBoxCreate && (
                <ModalAir
                    open={Boolean(anchorElBoxCreate)}
                    onClose={handleCloseBoxCreate}
                    isLoading={isLoadingCreate}
                    initialValues={initialFormValues}
                    validationSchema={() => validateSchema(false)}
                    handleSubmit={handleSubmitCreate}
                />
            )}

            {detailsAnchorEl && (
                <ModalAirDetail
                    dataDetails={dataDetails}
                    open={Boolean(detailsAnchorEl)}
                    onClose={handleCloseModalDetails}
                    validateSchema={() => validateSchema(true)}
                    fetchDataDetails={fetchInfoUser}
                    fetchData={fetchData}
                />
            )}

            <BoxDelete
                type='cancel'
                idRow={currentRow?._id}
                anchorEl={anchorElBoxCancel}
                onClose={handleCloseBoxCancelTask}
                handleConfirm={handleConfirmCancelTask}
            />

            <BoxDelete
                type='delete'
                idRow={currentRow?._id}
                anchorEl={anchorElDeleteTask}
                onClose={handleCloseBoxDeleteTask}
                handleConfirm={handleConfirmDeleteTask}
            />
        </Box>
    )
}

export default AirCondition
