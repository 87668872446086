export const admin_update_task = 'admin_update_task'
export const user_create_task = 'user_create_task'
export const admin_update_task_for_partner = 'admin_update_task_for_partner'
export const partner_done_task = 'partner_done_task'
export const partner_cancel_task = 'partner_cancel_task'
export const user_cancel_task_before_pending = 'user_cancel_task_before_pending'
export const user_cancel_task_after_pending = 'user_cancel_task_after_pending'
export const user_check_done_clean = 'user_check_done_clean'
export const socket_result = 'socket_result'
export const admin_change_partner = 'admin_change_partner'
export const admin_submit_partner = 'admin_submit_partner'
export const admin_cancel_task = 'admin_cancel_task'
