import images from '../assets/images'
import { tokens } from '../theme'

export const mockDataTeam = [
    {
        id: 1,
        name: 'Jon Snow',
        email: 'jonsnow@gmail.com',
        age: 35,
        phone: '(665)121-5454',
        access: 'admin',
    },
    {
        id: 2,
        name: 'Cersei Lannister',
        email: 'cerseilannister@gmail.com',
        age: 42,
        phone: '(421)314-2288',
        access: 'manager',
    },
    {
        id: 3,
        name: 'Jaime Lannister',
        email: 'jaimelannister@gmail.com',
        age: 45,
        phone: '(422)982-6739',
        access: 'user',
    },
    {
        id: 4,
        name: 'Anya Stark',
        email: 'anyastark@gmail.com',
        age: 16,
        phone: '(921)425-6742',
        access: 'admin',
    },
    {
        id: 5,
        name: 'Daenerys Targaryen',
        email: 'daenerystargaryen@gmail.com',
        age: 31,
        phone: '(421)445-1189',
        access: 'user',
    },
    {
        id: 6,
        name: 'Ever Melisandre',
        email: 'evermelisandre@gmail.com',
        age: 150,
        phone: '(232)545-6483',
        access: 'manager',
    },
    {
        id: 7,
        name: 'Ferrara Clifford',
        email: 'ferraraclifford@gmail.com',
        age: 44,
        phone: '(543)124-0123',
        access: 'user',
    },
    {
        id: 8,
        name: 'Rossini Frances',
        email: 'rossinifrances@gmail.com',
        age: 36,
        phone: '(222)444-5555',
        access: 'user',
    },
    {
        id: 9,
        name: 'Harvey Roxie',
        email: 'harveyroxie@gmail.com',
        age: 65,
        phone: '(444)555-6239',
        access: 'admin',
    },
]

export const mockDataContacts = [
    {
        id: 1,
        name: 'Jon Snow',
        email: 'jonsnow@gmail.com',
        age: 35,
        phone: '(665)121-5454',
        address: '0912 Won Street, Alabama, SY 10001',
        city: 'New York',
        zipCode: '10001',
        status: 'idle',
        registrarId: 123512,
    },
    {
        id: 2,
        name: 'Cersei Lannister',
        email: 'cerseilannister@gmail.com',
        age: 42,
        phone: '(421)314-2288',
        address: '1234 Main Street, New York, NY 10001',
        city: 'New York',
        zipCode: '13151',
        status: 'busy',
        registrarId: 123512,
    },
    {
        id: 3,
        name: 'Jaime Lannister',
        email: 'jaimelannister@gmail.com',
        age: 45,
        phone: '(422)982-6739',
        address: '3333 Want Blvd, Estanza, NAY 42125',
        city: 'New York',
        zipCode: '87281',
        status: 'busy',
        registrarId: 4132513,
    },
    {
        id: 4,
        name: 'Anya Stark',
        email: 'anyastark@gmail.com',
        age: 16,
        phone: '(921)425-6742',
        address: '1514 Main Street, New York, NY 22298',
        city: 'New York',
        zipCode: '15551',
        status: 'busy',
        registrarId: 123512,
    },
    {
        id: 5,
        name: 'Daenerys Targaryen',
        email: 'daenerystargaryen@gmail.com',
        age: 31,
        phone: '(421)445-1189',
        address: '11122 Welping Ave, Tenting, CD 21321',
        city: 'Tenting',
        zipCode: '14215',
        status: 'busy',
        registrarId: 123512,
    },
    {
        id: 6,
        name: 'Ever Melisandre',
        email: 'evermelisandre@gmail.com',
        age: 150,
        phone: '(232)545-6483',
        address: '1234 Canvile Street, Esvazark, NY 10001',
        city: 'Esvazark',
        zipCode: '10001',
        status: 'idle',
        registrarId: 123512,
    },
    {
        id: 7,
        name: 'Ferrara Clifford',
        email: 'ferraraclifford@gmail.com',
        age: 44,
        phone: '(543)124-0123',
        address: '22215 Super Street, Everting, ZO 515234',
        city: 'Evertin',
        zipCode: '51523',
        status: 'idle',
        registrarId: 123512,
    },
    {
        id: 8,
        name: 'Rossini Frances',
        email: 'rossinifrances@gmail.com',
        age: 36,
        phone: '(222)444-5555',
        address: '4123 Ever Blvd, Wentington, AD 142213',
        city: 'Esteras',
        zipCode: '44215',
        status: 'idle',
        registrarId: 512315,
    },
    {
        id: 9,
        name: 'Harvey Roxie',
        email: 'harveyroxie@gmail.com',
        age: 65,
        phone: '(444)555-6239',
        address: '51234 Avery Street, Cantory, ND 212412',
        city: 'Colunza',
        zipCode: '111234',
        status: 'idle',
        registrarId: 928397,
    },
    {
        id: 10,
        name: 'Enteri Redack',
        email: 'enteriredack@gmail.com',
        age: 42,
        phone: '(222)444-5555',
        address: '4123 Easer Blvd, Wentington, AD 142213',
        city: 'Esteras',
        zipCode: '44215',
        status: 'busy',
        registrarId: 533215,
    },
    {
        id: 11,
        name: 'Steve Goodman',
        email: 'stevegoodmane@gmail.com',
        age: 11,
        phone: '(444)555-6239',
        address: '51234 Fiveton Street, CunFory, ND 212412',
        city: 'Colunza',
        zipCode: '1234',
        status: 'busy',
        registrarId: 92197,
    },
]

export const mockDataInvoices = [
    {
        id: 1,
        name: 'Jon Snow',
        email: 'jonsnow@gmail.com',
        cost: '21.24',
        phone: '(665)121-5454',
        date: '03/12/2022',
    },
    {
        id: 2,
        name: 'Cersei Lannister',
        email: 'cerseilannister@gmail.com',
        cost: '1.24',
        phone: '(421)314-2288',
        date: '06/15/2021',
    },
    {
        id: 3,
        name: 'Jaime Lannister',
        email: 'jaimelannister@gmail.com',
        cost: '11.24',
        phone: '(422)982-6739',
        date: '05/02/2022',
    },
    {
        id: 4,
        name: 'Anya Stark',
        email: 'anyastark@gmail.com',
        cost: '80.55',
        phone: '(921)425-6742',
        date: '03/21/2022',
    },
    {
        id: 5,
        name: 'Daenerys Targaryen',
        email: 'daenerystargaryen@gmail.com',
        cost: '1.24',
        phone: '(421)445-1189',
        date: '01/12/2021',
    },
    {
        id: 6,
        name: 'Ever Melisandre',
        email: 'evermelisandre@gmail.com',
        cost: '63.12',
        phone: '(232)545-6483',
        date: '11/02/2022',
    },
    {
        id: 7,
        name: 'Ferrara Clifford',
        email: 'ferraraclifford@gmail.com',
        cost: '52.42',
        phone: '(543)124-0123',
        date: '02/11/2022',
    },
    {
        id: 8,
        name: 'Rossini Frances',
        email: 'rossinifrances@gmail.com',
        cost: '21.24',
        phone: '(222)444-5555',
        date: '05/02/2021',
    },
]

export const mockTransactions = [
    {
        txId: '01e4dsa',
        user: 'johndoe',
        date: '2021-09-01',
        cost: '43.95',
    },
    {
        txId: '0315dsaa',
        user: 'jackdower',
        date: '2022-04-01',
        cost: '133.45',
    },
    {
        txId: '01e4dsa',
        user: 'aberdohnny',
        date: '2021-09-01',
        cost: '43.95',
    },
    {
        txId: '51034szv',
        user: 'goodmanave',
        date: '2022-11-05',
        cost: '200.95',
    },
    {
        txId: '0a123sb',
        user: 'stevebower',
        date: '2022-11-02',
        cost: '13.55',
    },
    {
        txId: '01e4dsa',
        user: 'aberdohnny',
        date: '2021-09-01',
        cost: '43.95',
    },
    {
        txId: '120s51a',
        user: 'wootzifer',
        date: '2019-04-15',
        cost: '24.20',
    },
    {
        txId: '0315dsaa',
        user: 'jackdower',
        date: '2022-04-01',
        cost: '133.45',
    },
]

export const mockDiscountData = [
    {
        index: 1,
        name: 'Khuyến mãi mùa hè',
        description: 'Giảm giá 50% cho tất cả các sản phẩm mùa hè.',
        thumbnail: images.house,
        type: 'Mọi người',
        createdDate: '2023-06-01',
        status: 'Hoạt động',
    },
    {
        index: 2,
        name: 'Mua 1 tặng 1',
        description: 'Mua một sản phẩm bất kỳ, tặng một sản phẩm cùng loại.',
        thumbnail: images.house,
        type: 'Thành viên',
        createdDate: '2023-07-15',
        status: 'Hoạt động',
    },
    {
        index: 3,
        name: 'Giảm giá Black Friday',
        description: 'Giảm giá đến 70% nhân ngày Black Friday.',
        thumbnail: images.house,
        type: 'Mọi người',
        createdDate: '2023-11-20',
        status: 'Đã kết thúc',
    },
    {
        index: 4,
        name: 'Ưu đãi đặc biệt cho VIP',
        description: 'Giảm giá 30% cho khách hàng VIP.',
        thumbnail: images.house,
        type: 'VIP',
        createdDate: '2023-05-10',
        status: 'Hoạt động',
    },
    {
        index: 5,
        name: 'Ưu đãi mùa đông',
        description: 'Giảm giá 40% cho các sản phẩm mùa đông.',
        thumbnail: images.house,
        type: 'Mọi người',
        createdDate: '2023-12-01',
        status: 'Hoạt động',
    },
    {
        index: 6,
        name: 'Khuyến mãi Tết',
        description: 'Giảm giá 20% cho tất cả các sản phẩm nhân dịp Tết.',
        thumbnail: images.house,
        type: 'Mọi người',
        createdDate: '2024-01-20',
        status: 'Hoạt động',
    },
    {
        index: 7,
        name: 'Sale cuối năm',
        description: 'Giảm giá 60% cho các sản phẩm cuối năm.',
        thumbnail: images.house,
        type: 'Mọi người',
        createdDate: '2023-12-15',
        status: 'Đã kết thúc',
    },
    {
        index: 8,
        name: 'Giảm giá ngày lễ',
        description: 'Giảm giá đặc biệt cho các sản phẩm trong ngày lễ.',
        thumbnail: images.house,
        type: 'Mọi người',
        createdDate: '2023-09-01',
        status: 'Hoạt động',
    },
    {
        index: 9,
        name: 'Khuyến mãi sinh nhật',
        description: 'Ưu đãi đặc biệt cho khách hàng có sinh nhật trong tháng.',
        thumbnail: images.house,
        type: 'Thành viên',
        createdDate: '2023-08-20',
        status: 'Hoạt động',
    },
    {
        index: 10,
        name: 'Ưu đãi thành viên mới',
        description: 'Giảm giá 15% cho thành viên mới đăng ký.',
        thumbnail: images.house,
        type: 'Thành viên mới',
        createdDate: '2023-04-01',
        status: 'Hoạt động',
    },
]

export const mockCompanyData = [
    {
        id: 1,
        name: 'ABC Cleaning Services',
        service: 'Dọn nhà',
        phone: '0123456789',
        address: '123 Đường ABC, Quận XYZ, Thành phố HCM',
        createDate: '2024-02-28',
        status: 'Chưa thanh toán',
    },
    {
        id: 2,
        name: 'XYZ Cooking Service',
        service: 'Nấu ăn',
        phone: '0987654321',
        address: '456 Đường XYZ, Quận ABC, Thành phố Hà Nội',
        createDate: '2024-02-28',
        status: 'Đã thanh toán',
    },
    {
        id: 3,
        name: 'Senior Care Center',
        service: 'Chăm người già',
        phone: '0365478912',
        address: '789 Đường DEF, Quận MNO, Thành phố Đà Nẵng',
        createDate: '2024-02-27',
        status: 'Đã thanh toán',
    },
    {
        id: 4,
        name: 'Sunshine Housekeeping',
        service: 'Dọn nhà',
        phone: '0321654987',
        address: '321 Đường GHI, Quận PQR, Thành phố Hải Phòng',
        createDate: '2024-02-26',
        status: 'Chưa thanh toán',
    },
    {
        id: 5,
        name: 'Healthy Meals Delivery',
        service: 'Giao đồ ăn lành mạnh',
        phone: '0153792648',
        address: '987 Đường JKL, Quận STU, Thành phố Cần Thơ',
        createDate: '2024-02-25',
        status: 'Đã thanh toán',
    },
]

export const mockBarData = [
    {
        country: 'AD',
        'hot dog': 137,
        'hot dogColor': 'hsl(229, 70%, 50%)',
        burger: 96,
        burgerColor: 'hsl(296, 70%, 50%)',
        kebab: 72,
        kebabColor: 'hsl(97, 70%, 50%)',
        donut: 140,
        donutColor: 'hsl(340, 70%, 50%)',
    },
    {
        country: 'AE',
        'hot dog': 55,
        'hot dogColor': 'hsl(307, 70%, 50%)',
        burger: 28,
        burgerColor: 'hsl(111, 70%, 50%)',
        kebab: 58,
        kebabColor: 'hsl(273, 70%, 50%)',
        donut: 29,
        donutColor: 'hsl(275, 70%, 50%)',
    },
    {
        country: 'AF',
        'hot dog': 109,
        'hot dogColor': 'hsl(72, 70%, 50%)',
        burger: 23,
        burgerColor: 'hsl(96, 70%, 50%)',
        kebab: 34,
        kebabColor: 'hsl(106, 70%, 50%)',
        donut: 152,
        donutColor: 'hsl(256, 70%, 50%)',
    },
    {
        country: 'AG',
        'hot dog': 133,
        'hot dogColor': 'hsl(257, 70%, 50%)',
        burger: 52,
        burgerColor: 'hsl(326, 70%, 50%)',
        kebab: 43,
        kebabColor: 'hsl(110, 70%, 50%)',
        donut: 83,
        donutColor: 'hsl(9, 70%, 50%)',
    },
    {
        country: 'AI',
        'hot dog': 81,
        'hot dogColor': 'hsl(190, 70%, 50%)',
        burger: 80,
        burgerColor: 'hsl(325, 70%, 50%)',
        kebab: 112,
        kebabColor: 'hsl(54, 70%, 50%)',
        donut: 35,
        donutColor: 'hsl(285, 70%, 50%)',
    },
    {
        country: 'AL',
        'hot dog': 66,
        'hot dogColor': 'hsl(208, 70%, 50%)',
        burger: 111,
        burgerColor: 'hsl(334, 70%, 50%)',
        kebab: 167,
        kebabColor: 'hsl(182, 70%, 50%)',
        donut: 18,
        donutColor: 'hsl(76, 70%, 50%)',
    },
    {
        country: 'AM',
        'hot dog': 80,
        'hot dogColor': 'hsl(87, 70%, 50%)',
        burger: 47,
        burgerColor: 'hsl(141, 70%, 50%)',
        kebab: 158,
        kebabColor: 'hsl(224, 70%, 50%)',
        donut: 49,
        donutColor: 'hsl(274, 70%, 50%)',
    },
]

export const mockPieData = [
    {
        id: 'ngã vào tim em',
        label: 'ngã vào tim em',
        value: 239,
        color: 'hsl(104, 70%, 50%)',
    },
    {
        id: 'nấu cháo lòng',
        label: 'nấu cháo lòng',
        value: 170,
        color: 'hsl(162, 70%, 50%)',
    },
    {
        id: 'đấm lộn phóng lợn',
        label: 'đấm lộn phóng lợn',
        value: 322,
        color: 'hsl(291, 70%, 50%)',
    },
    {
        id: 'bơi lội cùng chủ',
        label: 'bơi lội cùng chủ',
        value: 503,
        color: 'hsl(229, 70%, 50%)',
    },
    {
        id: 'đánh ghen thuê',
        label: 'đánh ghen thuê',
        value: 584,
        color: 'hsl(344, 70%, 50%)',
    },
]

export const mockLineData = [
    {
        id: 'japan',
        color: tokens('dark').greenAccent[500],
        data: [
            {
                x: 'plane',
                y: 101,
            },
            {
                x: 'helicopter',
                y: 75,
            },
            {
                x: 'boat',
                y: 36,
            },
            {
                x: 'train',
                y: 216,
            },
            {
                x: 'subway',
                y: 35,
            },
            {
                x: 'bus',
                y: 236,
            },
            {
                x: 'car',
                y: 88,
            },
            {
                x: 'moto',
                y: 232,
            },
            {
                x: 'bicycle',
                y: 281,
            },
            {
                x: 'horse',
                y: 1,
            },
            {
                x: 'skateboard',
                y: 35,
            },
            {
                x: 'others',
                y: 14,
            },
        ],
    },
    {
        id: 'france',
        color: tokens('dark').blueAccent[300],
        data: [
            {
                x: 'plane',
                y: 212,
            },
            {
                x: 'helicopter',
                y: 190,
            },
            {
                x: 'boat',
                y: 270,
            },
            {
                x: 'train',
                y: 9,
            },
            {
                x: 'subway',
                y: 75,
            },
            {
                x: 'bus',
                y: 175,
            },
            {
                x: 'car',
                y: 33,
            },
            {
                x: 'moto',
                y: 189,
            },
            {
                x: 'bicycle',
                y: 97,
            },
            {
                x: 'horse',
                y: 87,
            },
            {
                x: 'skateboard',
                y: 299,
            },
            {
                x: 'others',
                y: 251,
            },
        ],
    },
    {
        id: 'us',
        color: tokens('dark').redAccent[200],
        data: [
            {
                x: 'plane',
                y: 191,
            },
            {
                x: 'helicopter',
                y: 136,
            },
            {
                x: 'boat',
                y: 91,
            },
            {
                x: 'train',
                y: 190,
            },
            {
                x: 'subway',
                y: 211,
            },
            {
                x: 'bus',
                y: 152,
            },
            {
                x: 'car',
                y: 189,
            },
            {
                x: 'moto',
                y: 152,
            },
            {
                x: 'bicycle',
                y: 8,
            },
            {
                x: 'horse',
                y: 197,
            },
            {
                x: 'skateboard',
                y: 107,
            },
            {
                x: 'others',
                y: 170,
            },
        ],
    },
]

export const mockGeographyData = [
    {
        id: 'AFG',
        value: 520600,
    },
    {
        id: 'AGO',
        value: 949905,
    },
    {
        id: 'ALB',
        value: 329910,
    },
    {
        id: 'ARE',
        value: 675484,
    },
    {
        id: 'ARG',
        value: 432239,
    },
    {
        id: 'ARM',
        value: 288305,
    },
    {
        id: 'ATA',
        value: 415648,
    },
    {
        id: 'ATF',
        value: 665159,
    },
    {
        id: 'AUT',
        value: 798526,
    },
    {
        id: 'AZE',
        value: 481678,
    },
    {
        id: 'BDI',
        value: 496457,
    },
    {
        id: 'BEL',
        value: 252276,
    },
    {
        id: 'BEN',
        value: 440315,
    },
    {
        id: 'BFA',
        value: 343752,
    },
    {
        id: 'BGD',
        value: 920203,
    },
    {
        id: 'BGR',
        value: 261196,
    },
    {
        id: 'BHS',
        value: 421551,
    },
    {
        id: 'BIH',
        value: 974745,
    },
    {
        id: 'BLR',
        value: 349288,
    },
    {
        id: 'BLZ',
        value: 305983,
    },
    {
        id: 'BOL',
        value: 430840,
    },
    {
        id: 'BRN',
        value: 345666,
    },
    {
        id: 'BTN',
        value: 649678,
    },
    {
        id: 'BWA',
        value: 319392,
    },
    {
        id: 'CAF',
        value: 722549,
    },
    {
        id: 'CAN',
        value: 332843,
    },
    {
        id: 'CHE',
        value: 122159,
    },
    {
        id: 'CHL',
        value: 811736,
    },
    {
        id: 'CHN',
        value: 593604,
    },
    {
        id: 'CIV',
        value: 143219,
    },
    {
        id: 'CMR',
        value: 630627,
    },
    {
        id: 'COG',
        value: 498556,
    },
    {
        id: 'COL',
        value: 660527,
    },
    {
        id: 'CRI',
        value: 60262,
    },
    {
        id: 'CUB',
        value: 177870,
    },
    {
        id: '-99',
        value: 463208,
    },
    {
        id: 'CYP',
        value: 945909,
    },
    {
        id: 'CZE',
        value: 500109,
    },
    {
        id: 'DEU',
        value: 63345,
    },
    {
        id: 'DJI',
        value: 634523,
    },
    {
        id: 'DNK',
        value: 731068,
    },
    {
        id: 'DOM',
        value: 262538,
    },
    {
        id: 'DZA',
        value: 760695,
    },
    {
        id: 'ECU',
        value: 301263,
    },
    {
        id: 'EGY',
        value: 148475,
    },
    {
        id: 'ERI',
        value: 939504,
    },
    {
        id: 'ESP',
        value: 706050,
    },
    {
        id: 'EST',
        value: 977015,
    },
    {
        id: 'ETH',
        value: 461734,
    },
    {
        id: 'FIN',
        value: 22800,
    },
    {
        id: 'FJI',
        value: 18985,
    },
    {
        id: 'FLK',
        value: 64986,
    },
    {
        id: 'FRA',
        value: 447457,
    },
    {
        id: 'GAB',
        value: 669675,
    },
    {
        id: 'GBR',
        value: 757120,
    },
    {
        id: 'GEO',
        value: 158702,
    },
    {
        id: 'GHA',
        value: 893180,
    },
    {
        id: 'GIN',
        value: 877288,
    },
    {
        id: 'GMB',
        value: 724530,
    },
    {
        id: 'GNB',
        value: 387753,
    },
    {
        id: 'GNQ',
        value: 706118,
    },
    {
        id: 'GRC',
        value: 377796,
    },
    {
        id: 'GTM',
        value: 66890,
    },
    {
        id: 'GUY',
        value: 719300,
    },
    {
        id: 'HND',
        value: 739590,
    },
    {
        id: 'HRV',
        value: 929467,
    },
    {
        id: 'HTI',
        value: 538961,
    },
    {
        id: 'HUN',
        value: 146095,
    },
    {
        id: 'IDN',
        value: 490681,
    },
    {
        id: 'IND',
        value: 549818,
    },
    {
        id: 'IRL',
        value: 630163,
    },
    {
        id: 'IRN',
        value: 596921,
    },
    {
        id: 'IRQ',
        value: 767023,
    },
    {
        id: 'ISL',
        value: 478682,
    },
    {
        id: 'ISR',
        value: 963688,
    },
    {
        id: 'ITA',
        value: 393089,
    },
    {
        id: 'JAM',
        value: 83173,
    },
    {
        id: 'JOR',
        value: 52005,
    },
    {
        id: 'JPN',
        value: 199174,
    },
    {
        id: 'KAZ',
        value: 181424,
    },
    {
        id: 'KEN',
        value: 60946,
    },
    {
        id: 'KGZ',
        value: 432478,
    },
    {
        id: 'KHM',
        value: 254461,
    },
    {
        id: 'OSA',
        value: 942447,
    },
    {
        id: 'KWT',
        value: 414413,
    },
    {
        id: 'LAO',
        value: 448339,
    },
    {
        id: 'LBN',
        value: 620090,
    },
    {
        id: 'LBR',
        value: 435950,
    },
    {
        id: 'LBY',
        value: 75091,
    },
    {
        id: 'LKA',
        value: 595124,
    },
    {
        id: 'LSO',
        value: 483524,
    },
    {
        id: 'LTU',
        value: 867357,
    },
    {
        id: 'LUX',
        value: 689172,
    },
    {
        id: 'LVA',
        value: 742980,
    },
    {
        id: 'MAR',
        value: 236538,
    },
    {
        id: 'MDA',
        value: 926836,
    },
    {
        id: 'MDG',
        value: 840840,
    },
    {
        id: 'MEX',
        value: 353910,
    },
    {
        id: 'MKD',
        value: 505842,
    },
    {
        id: 'MLI',
        value: 286082,
    },
    {
        id: 'MMR',
        value: 915544,
    },
    {
        id: 'MNE',
        value: 609500,
    },
    {
        id: 'MNG',
        value: 410428,
    },
    {
        id: 'MOZ',
        value: 32868,
    },
    {
        id: 'MRT',
        value: 375671,
    },
    {
        id: 'MWI',
        value: 591935,
    },
    {
        id: 'MYS',
        value: 991644,
    },
    {
        id: 'NAM',
        value: 701897,
    },
    {
        id: 'NCL',
        value: 144098,
    },
    {
        id: 'NER',
        value: 312944,
    },
    {
        id: 'NGA',
        value: 862877,
    },
    {
        id: 'NIC',
        value: 90831,
    },
    {
        id: 'NLD',
        value: 281879,
    },
    {
        id: 'NOR',
        value: 224537,
    },
    {
        id: 'NPL',
        value: 322331,
    },
    {
        id: 'NZL',
        value: 86615,
    },
    {
        id: 'OMN',
        value: 707881,
    },
    {
        id: 'PAK',
        value: 158577,
    },
    {
        id: 'PAN',
        value: 738579,
    },
    {
        id: 'PER',
        value: 248751,
    },
    {
        id: 'PHL',
        value: 557292,
    },
    {
        id: 'PNG',
        value: 516874,
    },
    {
        id: 'POL',
        value: 682137,
    },
    {
        id: 'PRI',
        value: 957399,
    },
    {
        id: 'PRT',
        value: 846430,
    },
    {
        id: 'PRY',
        value: 720555,
    },
    {
        id: 'QAT',
        value: 478726,
    },
    {
        id: 'ROU',
        value: 259318,
    },
    {
        id: 'RUS',
        value: 268735,
    },
    {
        id: 'RWA',
        value: 136781,
    },
    {
        id: 'ESH',
        value: 151957,
    },
    {
        id: 'SAU',
        value: 111821,
    },
    {
        id: 'SDN',
        value: 927112,
    },
    {
        id: 'SDS',
        value: 966473,
    },
    {
        id: 'SEN',
        value: 158085,
    },
    {
        id: 'SLB',
        value: 178389,
    },
    {
        id: 'SLE',
        value: 528433,
    },
    {
        id: 'SLV',
        value: 353467,
    },
    {
        id: 'ABV',
        value: 251,
    },
    {
        id: 'SOM',
        value: 445243,
    },
    {
        id: 'SRB',
        value: 202402,
    },
    {
        id: 'SUR',
        value: 972121,
    },
    {
        id: 'SVK',
        value: 319923,
    },
    {
        id: 'SVN',
        value: 728766,
    },
    {
        id: 'SWZ',
        value: 379669,
    },
    {
        id: 'SYR',
        value: 16221,
    },
    {
        id: 'TCD',
        value: 101273,
    },
    {
        id: 'TGO',
        value: 498411,
    },
    {
        id: 'THA',
        value: 506906,
    },
    {
        id: 'TJK',
        value: 613093,
    },
    {
        id: 'TKM',
        value: 327016,
    },
    {
        id: 'TLS',
        value: 607972,
    },
    {
        id: 'TTO',
        value: 936365,
    },
    {
        id: 'TUN',
        value: 898416,
    },
    {
        id: 'TUR',
        value: 237783,
    },
    {
        id: 'TWN',
        value: 878213,
    },
    {
        id: 'TZA',
        value: 442174,
    },
    {
        id: 'UGA',
        value: 720710,
    },
    {
        id: 'UKR',
        value: 74172,
    },
    {
        id: 'URY',
        value: 753177,
    },
    {
        id: 'USA',
        value: 658725,
    },
    {
        id: 'UZB',
        value: 550313,
    },
    {
        id: 'VEN',
        value: 707492,
    },
    {
        id: 'VNM',
        value: 538907,
    },
    {
        id: 'VUT',
        value: 650646,
    },
    {
        id: 'PSE',
        value: 476078,
    },
    {
        id: 'YEM',
        value: 957751,
    },
    {
        id: 'ZAF',
        value: 836949,
    },
    {
        id: 'ZMB',
        value: 714503,
    },
    {
        id: 'ZWE',
        value: 405217,
    },
    {
        id: 'KOR',
        value: 171135,
    },
]

export const mockDataOrder = [
    {
        id: 1,
        name: 'Dọn dẹp nhà',
        cost: '21.24',
        date: '03/12/2022',
        jobType: 'Dịch vụ phổ biến',
        address: '55 Lê Lợi, Quận Tân Bình, Thành phố Vũng Tàu',
        status: 'chờ giao nhân viên',
    },
    {
        id: 2,
        name: 'Trông Trẻ',
        cost: '45.67',
        date: '23/07/2023',
        jobType: 'Dịch vụ chăm sóc và hỗ trợ',
        address: '312 Lê Hồng phong, Quận ABC, Thành phố Gia Lai',
        status: 'đang tiến hành',
    },
    {
        id: 3,
        name: 'Vệ sinh bình nóng lạnh',
        cost: '80.00',
        date: '18/02/2024',
        jobType: 'Dịch vụ bảo dưỡng điện máy',
        address: '456 Đường XYZ, Quận ABC, Thành phố Hà Nội',
        status: 'đã hoàn thành',
    },
    {
        id: 4,
        name: 'Dọn dẹp văn phòng',
        cost: '130.00',
        date: '29/12/2023',
        jobType: 'Dịch vụ dành cho doanh nghiệp',
        address: '789 Đường DEF, Quận MNO, Thành phố Đà Nẵng',
        status: 'đã huỷ',
    },
    {
        id: 5,
        name: 'Giặt ủi',
        cost: '20.25',
        date: '01/02/2024',
        jobType: 'Dịch vụ  ích nâng cao',
        address: '177 Đường Điện Biên Phủ, Thành phố Huế',
        status: 'đang tiến hành',
    },
    {
        id: 6,
        name: 'Tổng vệ sinh',
        cost: '60.00',
        date: '14/02/2024',
        jobType: 'Dịch vụ phổ biến',
        address: '321 Đường GHI, Quận PQR, Thành phố Hải Phòng',
        status: 'chờ giao nhân viên',
    },
    {
        id: 7,
        name: 'Chăm sóc người cao tuổi',
        cost: '30.00',
        date: '04/03/2024',
        jobType: 'Dịch vụ chăm sóc và hỗ trợ',
        address: 'Đường 23 tháng 8, TP Thành phố HCM',
        status: 'đã hoàn thành',
    },
    {
        id: 8,
        name: 'Vệ sinh máy giặt',
        cost: '90.00',
        date: '08/03/2024',
        jobType: 'Dịch vụ bảo dưỡng điện máy',
        address: '123 Đường ABC, Quận XYZ, Thành phố HCM',
        status: 'đã huỷ',
    },
]

export const pricingData = [
    {
        id: 1,
        package: '1 tháng',
        price: '1,000,000',
        discount: 0,
        createDate: '02/05/2023',
    },
    {
        id: 2,
        package: '3 tháng',
        price: '2,700,000',
        discount: 10,
        createDate: '16/12/2023',
    },
    {
        id: 3,
        package: '6 tháng',
        price: '5,100,000', // Giảm 15% so với giá gói 1 tháng
        discount: 15, // Khuyến mãi 15%
        createDate: '01/26/2024',
    },
    {
        id: 4,
        package: '12 tháng',
        price: '9,600,000', // Giảm 20% so với giá gói 1 tháng
        discount: 20, // Khuyến mãi 20%
        createDate: '03/03/2024',
    },
]

export const serviceData = [
    {
        id: 1,
        serviceGroup: 'ABC Cleaning Services',
        serviceName: 'Dọn nhà',
        createDate: '2024-02-28',
    },
    {
        id: 2,
        serviceGroup: 'XYZ Cooking Service',
        serviceName: 'Nấu ăn',
        createDate: '2024-02-28',
    },
    {
        id: 3,
        serviceGroup: 'Senior Care Center',
        serviceName: 'Chăm người già',
        createDate: '2024-02-27',
    },
    {
        id: 4,
        serviceGroup: 'Sunshine Housekeeping',
        serviceName: 'Dọn nhà',
        phone: '0321654987',
        createDate: '2024-02-26',
    },
    {
        id: 5,
        serviceGroup: 'Healthy Meals Delivery',
        serviceName: 'Giao đồ ăn lành mạnh',
        createDate: '2024-02-25',
    },
]

export const dataCompetence = [
    {
        id: 1,
        name: 'John Đoé',
        level: 'Senior',
        rating: 4.5,
        completedTask: 80,
        failedTask: 5,
        cancelledTask: 3,
    },
    {
        id: 2,
        name: 'Jane Smith',
        level: 'Junior',
        rating: 4.0,
        completedTask: 65,
        failedTask: 8,
        cancelledTask: 2,
    },
    {
        id: 3,
        name: 'Michael Johnson',
        level: 'Senior',
        rating: 4.8,
        completedTask: 95,
        failedTask: 2,
        cancelledTask: 1,
    },
    {
        id: 4,
        name: 'Sarah Lee',
        level: 'Junior',
        rating: 4.2,
        completedTask: 55,
        failedTask: 10,
        cancelledTask: 4,
    },
    {
        id: 5,
        name: 'David Williams',
        level: 'Senior',
        rating: 4.6,
        completedTask: 75,
        failedTask: 6,
        cancelledTask: 2,
    },
    {
        id: 6,
        name: 'Emily Brown',
        level: 'Junior',
        rating: 3.9,
        completedTask: 60,
        failedTask: 9,
        cancelledTask: 3,
    },
    {
        id: 7,
        name: 'Christopher Davis',
        level: 'Senior',
        rating: 4.7,
        completedTask: 85,
        failedTask: 4,
        cancelledTask: 1,
    },
    {
        id: 8,
        name: 'Olivia Wilson',
        level: 'Junior',
        rating: 4.1,
        completedTask: 50,
        failedTask: 12,
        cancelledTask: 5,
    },
    {
        id: 9,
        name: 'James Taylor',
        level: 'Senior',
        rating: 4.4,
        completedTask: 70,
        failedTask: 7,
        cancelledTask: 2,
    },
    {
        id: 10,
        name: 'Emma Martinez',
        level: 'Junior',
        rating: 4.0,
        completedTask: 65,
        failedTask: 8,
        cancelledTask: 3,
    },
    {
        id: 11,
        name: 'Alexander Anderson',
        level: 'Senior',
        rating: 4.8,
        completedTask: 90,
        failedTask: 3,
        cancelledTask: 2,
    },
    {
        id: 12,
        name: 'Ava Garcia',
        level: 'Junior',
        rating: 4.3,
        completedTask: 55,
        failedTask: 11,
        cancelledTask: 4,
    },
    {
        id: 13,
        name: 'William Rodriguez',
        level: 'Senior',
        rating: 4.6,
        completedTask: 80,
        failedTask: 5,
        cancelledTask: 1,
    },
    {
        id: 14,
        name: 'Mia Lopez',
        level: 'Junior',
        rating: 3.8,
        completedTask: 60,
        failedTask: 10,
        cancelledTask: 4,
    },
    {
        id: 15,
        name: 'Ethan Martinez',
        level: 'Senior',
        rating: 4.9,
        completedTask: 100,
        failedTask: 1,
        cancelledTask: 0,
    },
    {
        id: 16,
        name: 'Isabella Nguyen',
        level: 'Junior',
        rating: 4.2,
        completedTask: 70,
        failedTask: 9,
        cancelledTask: 2,
    },
    {
        id: 17,
        name: 'Alexander Hernandez',
        level: 'Senior',
        rating: 4.7,
        completedTask: 85,
        failedTask: 3,
        cancelledTask: 2,
    },
    {
        id: 18,
        name: 'Sophia Martinez',
        level: 'Junior',
        rating: 4.0,
        completedTask: 55,
        failedTask: 12,
        cancelledTask: 3,
    },
    {
        id: 19,
        name: 'Michael Thomas',
        level: 'Senior',
        rating: 4.5,
        completedTask: 75,
        failedTask: 6,
        cancelledTask: 1,
    },
    {
        id: 20,
        name: 'Charlotte Martin',
        level: 'Junior',
        rating: 4.1,
        completedTask: 60,
        failedTask: 8,
        cancelledTask: 4,
    },
]

export const dataVerify = [
    {
        id: 1,
        name: 'Nguyễn Văn A',
        phone: '0123456789',
        address: '123 Đường ABC, Quận XYZ, Thành phố HCM',
        service: 'Chăm sóc sức khoẻ',
        cardInfo: 'https://example.com/nguyen-van-a-card.jpg',
        createDate: '2024-02-28',
    },
    {
        id: 2,
        name: 'Trần Thị B',
        phone: '0987654321',
        address: '456 Đường XYZ, Quận ABC, Thành phố Hà Nội',
        service: 'Nấu ăn',
        cardInfo: 'https://example.com/tran-thi-b-card.jpg',
        createDate: '2024-02-28',
    },
    {
        id: 3,
        name: 'Lê Văn C',
        phone: '0398765432',
        address: '789 Đường DEF, Quận UVW, Thành phố Đà Nẵng',
        service: 'Cảnh quan',
        cardInfo: 'https://example.com/le-van-c-card.jpg',
        createDate: '2024-02-28',
    },
    {
        id: 4,
        name: 'Phạm Thị D',
        phone: '0765432109',
        address: '987 Đường UVW, Quận DEF, Thành phố Hải Phòng',
        service: 'Dọn nhà',
        cardInfo: 'https://example.com/pham-thi-d-card.jpg',
        createDate: '2024-02-28',
    },
    {
        id: 5,
        name: 'Hoàng Văn E',
        phone: '0934567890',
        address: '654 Đường MNO, Quận GHI, Thành phố Cần Thơ',
        service: 'Sửa chữa gia dụng',
        cardInfo: 'https://example.com/hoang-van-e-card.jpg',
        createDate: '2024-02-28',
    },
    {
        id: 6,
        name: 'Vũ Thị F',
        phone: '0654321098',
        address: '321 Đường KLM, Quận STU, Thành phố Vũng Tàu',
        service: 'Chăm sóc trẻ em',
        cardInfo: 'https://example.com/vu-thi-f-card.jpg',
        createDate: '2024-02-28',
    },
    {
        id: 7,
        name: 'Đặng Văn G',
        phone: '0876543210',
        address: '456 Đường NOP, Quận RST, Thành phố Đồng Nai',
        service: 'Vận chuyển',
        cardInfo: 'https://example.com/dang-van-g-card.jpg',
        createDate: '2024-02-28',
    },
    {
        id: 8,
        name: 'Lê Thị H',
        phone: '0987654321',
        address: '789 Đường UVW, Quận ABC, Thành phố Hồ Chí Minh',
        service: 'Giúp việc gia đình',
        cardInfo: 'https://example.com/le-thi-h-card.jpg',
        createDate: '2024-02-28',
    },
    {
        id: 9,
        name: 'Ngô Văn I',
        phone: '0765432109',
        address: '987 Đường MNO, Quận XYZ, Thành phố Hải Phòng',
        service: 'Sửa máy tính',
        cardInfo: 'https://example.com/ngo-van-i-card.jpg',
        createDate: '2024-02-28',
    },
    {
        id: 10,
        name: 'Mai Thị K',
        phone: '0934567890',
        address: '654 Đường GHI, Quận DEF, Thành phố Hà Nội',
        service: 'Chăm sóc thú cưng',
        cardInfo: 'https://example.com/mai-thi-k-card.jpg',
        createDate: '2024-02-28',
    },
    {
        id: 11,
        name: 'Võ Văn L',
        phone: '0654321098',
        address: '321 Đường STU, Quận NOP, Thành phố TP. Hồ Chí Minh',
        service: 'Chăm sóc thú cưng',
        cardInfo: 'https://example.com/vo-van-l-card.jpg',
        createDate: '2024-02-28',
    },
    {
        id: 12,
        name: 'Đinh Thị M',
        phone: '0765432109',
        address: '987 Đường DEF, Quận MNO, Thành phố Hải Phòng',
        service: 'Sửa chữa ô tô',
        cardInfo: 'https://example.com/dinh-thi-m-card.jpg',
        createDate: '2024-02-28',
    },
    {
        id: 13,
        name: 'Trương Văn N',
        phone: '0934567890',
        address: '654 Đường XYZ, Quận UVW, Thành phố Cần Thơ',
        service: 'Chăm sóc người già',
        cardInfo: 'https://example.com/truong-van-n-card.jpg',
        createDate: '2024-02-28',
    },
    {
        id: 14,
        name: 'Phan Thị O',
        phone: '0654321098',
        address: '321 Đường ABC, Quận STU, Thành phố Hà Nội',
        service: 'Thiết kế đồ hoạ',
        cardInfo: 'https://example.com/phan-thi-o-card.jpg',
        createDate: '2024-02-28',
    },
    {
        id: 15,
        name: 'Hoàng Văn P',
        phone: '0876543210',
        address: '456 Đường NOP, Quận GHI, Thành phố Đà Nẵng',
        service: 'Chăm sóc cây cảnh',
        cardInfo: 'https://example.com/hoang-van-p-card.jpg',
        createDate: '2024-02-28',
    },
    {
        id: 16,
        name: 'Nguyễn Thị Q',
        phone: '0987654321',
        address: '789 Đường UVW, Quận DEF, Thành phố TP. Hồ Chí Minh',
        service: 'Nấu ăn',
        cardInfo: 'https://example.com/nguyen-thi-q-card.jpg',
        createDate: '2024-02-28',
    },
    {
        id: 17,
        name: 'Vũ Văn R',
        phone: '0123456789',
        address: '123 Đường MNO, Quận XYZ, Thành phố Hà Nội',
        service: 'Chăm sóc thú cưng',
        cardInfo: 'https://example.com/vu-van-r-card.jpg',
        createDate: '2024-02-28',
    },
    {
        id: 18,
        name: 'Trần Thị S',
        phone: '0398765432',
        address: '456 Đường DEF, Quận NOP, Thành phố Cần Thơ',
        service: 'Sửa chữa máy lạnh',
        cardInfo: 'https://example.com/tran-thi-s-card.jpg',
        createDate: '2024-02-28',
    },
    {
        id: 19,
        name: 'Lê Văn T',
        phone: '0765432109',
        address: '789 Đường GHI, Quận STU, Thành phố TP. Hồ Chí Minh',
        service: 'Chăm sóc trẻ em',
        cardInfo: 'https://example.com/le-van-t-card.jpg',
        createDate: '2024-02-28',
    },
    {
        id: 20,
        name: 'Đặng Thị U',
        phone: '0934567890',
        address: '654 Đường KLM, Quận RST, Thành phố Đà Nẵng',
        service: 'Thiết kế website',
        cardInfo: 'https://example.com/dang-thi-u-card.jpg',
        createDate: '2024-02-28',
    },
]

export const dataDeposit = [
    {
        id: 1,
        name: 'Phạm Hồng Thái',
        numberOfDeposits: 5,
        latestDeposit: {
            dateTime: '2024-02-28 09:15:00',
            amount: 5000000,
        },
        numberOfWithdrawals: 0,
        latestWithdraw: {
            dateTime: null,
            amount: null,
        },
        accountBalance: 5000000,
    },
    {
        id: 2,
        name: 'Nguyễn Văn An',
        numberOfDeposits: 3,
        latestDeposit: {
            dateTime: '2024-02-27 15:30:00',
            amount: 3000000,
        },
        numberOfWithdrawals: 2,
        latestWithdraw: {
            dateTime: '2024-02-26 10:45:00',
            amount: 3500000,
        },
        accountBalance: 10000000,
    },
    {
        id: 3,
        name: 'Trần Thị Bình',
        numberOfDeposits: 7,
        latestDeposit: {
            dateTime: '2024-02-28 11:45:00',
            amount: 7000000,
        },
        numberOfWithdrawals: 4,
        latestWithdraw: {
            dateTime: '2024-02-25 11:20:00',
            amount: 6000000,
        },
        accountBalance: 20000000,
    },
    {
        id: 4,
        name: 'Lê Văn Cường',
        numberOfDeposits: 4,
        latestDeposit: {
            dateTime: '2024-02-27 10:20:00',
            amount: 4000000,
        },
        numberOfWithdrawals: 2,
        latestWithdraw: {
            dateTime: '2024-02-24 08:55:00',
            amount: 3000000,
        },
        accountBalance: 12000000,
    },
    {
        id: 5,
        name: 'Hoàng Thị Dung',
        numberOfDeposits: 6,
        latestDeposit: {
            dateTime: '2024-02-28 13:00:00',
            amount: 6000000,
        },
        numberOfWithdrawals: 3,
        latestWithdraw: {
            dateTime: '2024-02-23 12:10:00',
            amount: 4000000,
        },
        accountBalance: 18000000,
    },
    {
        id: 6,
        name: 'Phan Văn Đức',
        numberOfDeposits: 2,
        latestDeposit: {
            dateTime: '2024-02-27 17:45:00',
            amount: 2000000,
        },
        numberOfWithdrawals: 1,
        latestWithdraw: {
            dateTime: '2024-02-22 15:40:00',
            amount: 2500000,
        },
        accountBalance: 8000000,
    },
    {
        id: 7,
        name: 'Nguyễn Thị Mai',
        numberOfDeposits: 8,
        latestDeposit: {
            dateTime: '2024-02-28 08:30:00',
            amount: 8000000,
        },
        numberOfWithdrawals: 5,
        latestWithdraw: {
            dateTime: '2024-02-21 16:25:00',
            amount: 7000000,
        },
        accountBalance: 22000000,
    },
    {
        id: 8,
        name: 'Trần Văn Nam',
        numberOfDeposits: 3,
        latestDeposit: {
            dateTime: '2024-02-27 14:00:00',
            amount: 3000000,
        },
        numberOfWithdrawals: 2,
        latestWithdraw: {
            dateTime: '2024-02-20 17:50:00',
            amount: 3500000,
        },
        accountBalance: 10000000,
    },
    {
        id: 9,
        name: 'Nguyễn Thị Oanh',
        numberOfDeposits: 5,
        latestDeposit: {
            dateTime: '2024-02-28 10:10:00',
            amount: 5000000,
        },
        numberOfWithdrawals: 3,
        latestWithdraw: {
            dateTime: '2024-02-19 19:15:00',
            amount: 4500000,
        },
        accountBalance: 15000000,
    },
    {
        id: 10,
        name: 'Lê Văn Phong',
        numberOfDeposits: 6,
        latestDeposit: {
            dateTime: '2024-02-28 12:20:00',
            amount: 6000000,
        },
        numberOfWithdrawals: 2,
        latestWithdraw: {
            dateTime: '2024-02-18 20:30:00',
            amount: 3000000,
        },
        accountBalance: 18000000,
    },
    {
        id: 11,
        name: 'Phạm Thị Quỳnh',
        numberOfDeposits: 4,
        latestDeposit: {
            dateTime: '2024-02-27 16:00:00',
            amount: 4000000,
        },
        numberOfWithdrawals: 4,
        latestWithdraw: {
            dateTime: '2024-02-17 11:45:00',
            amount: 5500000,
        },
        accountBalance: 12000000,
    },
    {
        id: 12,
        name: 'Hoàng Văn Rất',
        numberOfDeposits: 7,
        latestDeposit: {
            dateTime: '2024-02-28 14:30:00',
            amount: 7000000,
        },
        numberOfWithdrawals: 1,
        latestWithdraw: {
            dateTime: '2024-02-16 09:20:00',
            amount: 2000000,
        },
        accountBalance: 20000000,
    },
    {
        id: 13,
        name: 'Đỗ Thị Sáng',
        numberOfDeposits: 3,
        latestDeposit: {
            dateTime: '2024-02-27 11:40:00',
            amount: 3000000,
        },
        numberOfWithdrawals: 2,
        latestWithdraw: {
            dateTime: '2024-02-15 14:35:00',
            amount: 3500000,
        },
        accountBalance: 10000000,
    },
    {
        id: 14,
        name: 'Vũ Văn Tài',
        numberOfDeposits: 6,
        latestDeposit: {
            dateTime: '2024-02-28 09:00:00',
            amount: 6000000,
        },
        numberOfWithdrawals: 3,
        latestWithdraw: {
            dateTime: '2024-02-14 16:50:00',
            amount: 4500000,
        },
        accountBalance: 18000000,
    },
    {
        id: 15,
        name: 'Nguyễn Thị Út',
        numberOfDeposits: 5,
        latestDeposit: {
            dateTime: '2024-02-28 13:45:00',
            amount: 5000000,
        },
        numberOfWithdrawals: 1,
        latestWithdraw: {
            dateTime: '2024-02-13 18:05:00',
            amount: 2500000,
        },
        accountBalance: 15000000,
    },
    {
        id: 16,
        name: 'Trần Văn Vinh',
        numberOfDeposits: 4,
        latestDeposit: {
            dateTime: '2024-02-27 09:20:00',
            amount: 4000000,
        },
        numberOfWithdrawals: 4,
        latestWithdraw: {
            dateTime: '2024-02-12 09:30:00',
            amount: 6000000,
        },
        accountBalance: 12000000,
    },
    {
        id: 17,
        name: 'Hoàng Thị Yến',
        numberOfDeposits: 8,
        latestDeposit: {
            dateTime: '2024-02-28 11:20:00',
            amount: 8000000,
        },
        numberOfWithdrawals: 2,
        latestWithdraw: {
            dateTime: '2024-02-11 10:45:00',
            amount: 3000000,
        },
        accountBalance: 22000000,
    },
    {
        id: 18,
        name: 'Đặng Văn Anh',
        numberOfDeposits: 2,
        latestDeposit: {
            dateTime: '2024-02-27 14:50:00',
            amount: 2000000,
        },
        numberOfWithdrawals: 3,
        latestWithdraw: {
            dateTime: '2024-02-10 12:00:00',
            amount: 4000000,
        },
        accountBalance: 8000000,
    },
    {
        id: 19,
        name: 'Lê Thị Bình',
        numberOfDeposits: 5,
        latestDeposit: {
            dateTime: '2024-02-28 10:30:00',
            amount: 5000000,
        },
        numberOfWithdrawals: 1,
        latestWithdraw: {
            dateTime: '2024-02-09 13:15:00',
            amount: 2000000,
        },
        accountBalance: 15000000,
    },
    {
        id: 20,
        name: 'Nguyễn Văn Chính',
        numberOfDeposits: 6,
        latestDeposit: {
            dateTime: '2024-02-28 14:00:00',
            amount: 6000000,
        },
        numberOfWithdrawals: 1,
        latestWithdraw: {
            dateTime: '2024-02-09 13:15:00',
            amount: 2000000,
        },
        accountBalance: 18000000,
    },
]

export const mockBarNewUser = [
    {
        month: 'Tháng 1',
        user: 140,
    },
    {
        month: 'Tháng 2',
        user: 29,
    },
    {
        month: 'Tháng 3',
        user: 152,
    },
    {
        month: 'Tháng 4',
        user: 83,
    },
    {
        month: 'Tháng 5',
        user: 35,
    },
    {
        month: 'Tháng 6',
        user: 18,
    },
    {
        month: 'Tháng 7',
        user: 49,
    },
    {
        month: 'Tháng 8',
        user: 56,
    },
    {
        month: 'Tháng 9',
        user: 123,
    },
    {
        month: 'Tháng 10',
        user: 90,
    },
    {
        month: 'Tháng 11',
        user: 19,
    },
    {
        month: 'Tháng 12',
        user: 130,
    },
]

export const mockBarChartStatisticsService = [
    {
        day: 'Thứ 2',
        'nấu ăn': 9,
        'dọn nhà': 3,
        'chăm sóc người già': 7,
        'tổng vệ sinh': 4,
    },
    {
        day: 'Thứ 3',
        'nấu ăn': 5,
        'dọn nhà': 8,
        'chăm sóc người già': 3,
        'tổng vệ sinh': 7,
    },
    {
        day: 'Thứ 4',
        'nấu ăn': 6,
        'dọn nhà': 7,
        'chăm sóc người già': 4,
        'tổng vệ sinh': 10,
    },
    {
        day: 'Thứ 5',
        'nấu ăn': 2,
        'dọn nhà': 10,
        'chăm sóc người già': 13,
        'tổng vệ sinh': 8,
        'chăm sóc em bé': 5,
    },
    {
        day: 'Thứ 6',
        'nấu ăn': 6,
        'dọn nhà': 1,
        'chăm sóc người già': 5,
        'tổng vệ sinh': 2,
    },
    {
        day: 'Thứ 7',
        'nấu ăn': 4,
        'dọn nhà': 9,
        'chăm sóc người già': 3,
        'tổng vệ sinh': 13,
        'sửa điện lạnh': 20,
    },
]

export const mockDataTransaction = [
    {
        _id: '6b1ecc88-df35-4a5a-a4d9-7137894f58e0',
        name: 'Le Khanh',
        transactionTime: '2023-09-18 07:29:22',
        desctiption: 'Subscription fee',
        amount: 236.87,
    },
    {
        _id: 'ebfe72aa-c3f8-462f-aee8-30ae30f93634',
        name: 'Pham Khanh',
        transactionTime: '2023-07-02 07:29:22',
        desctiption: 'Bill payment',
        amount: 973.99,
    },
    {
        _id: 'f2862f00-7a7e-4908-aa5c-4356f969515f',
        name: 'Pham Khanh',
        transactionTime: '2024-03-01 07:29:22',
        desctiption: 'Cash withdrawal',
        amount: 949.15,
    },
    {
        _id: '5fd3dd48-7b5f-4b70-9321-3f0e3ed5b120',
        name: 'Dang Mai',
        transactionTime: '2024-04-10 07:29:22',
        desctiption: 'Cash withdrawal',
        amount: 376.65,
    },
    {
        _id: '7e94b1db-ce77-492e-8853-8e640eae2a17',
        name: 'Hoang Hanh',
        transactionTime: '2023-09-22 07:29:22',
        desctiption: 'Refund',
        amount: 205.05,
    },
    {
        _id: '678714e8-4c28-4781-aa16-f961d0a4ac86',
        name: 'Phan Mai',
        transactionTime: '2023-10-05 07:29:22',
        desctiption: 'Deposit',
        amount: 100.81,
    },
    {
        _id: 'bf3a9a34-f823-4783-8e1e-713a5343478a',
        name: 'Do Giang',
        transactionTime: '2023-06-10 07:29:22',
        desctiption: 'Bank transfer',
        amount: 517.17,
    },
    {
        _id: 'e181f7f5-7675-49f6-8a29-874890a3f20d',
        name: 'Tran Binh',
        transactionTime: '2024-01-25 07:29:22',
        desctiption: 'Online purchase',
        amount: 238.59,
    },
    {
        _id: '5e8c6611-d88b-4c15-87bf-d0128e669152',
        name: 'Phan Duong',
        transactionTime: '2024-02-06 07:29:22',
        desctiption: 'Cash withdrawal',
        amount: 101.25,
    },
    {
        _id: '3ccefbff-a306-4857-b8ac-09fb65ad3cb6',
        name: 'Bui Nga',
        transactionTime: '2024-02-17 07:29:22',
        desctiption: 'Loan repayment',
        amount: 325.39,
    },
    {
        _id: '191ba7ad-a678-4aa7-9a4f-f3fd34357b87',
        name: 'Tran Cuong',
        transactionTime: '2023-12-22 07:29:22',
        desctiption: 'Refund',
        amount: 12.17,
    },
    {
        _id: 'd3b85a7e-667f-4909-b26f-7b8f2b3073c8',
        name: 'Do Binh',
        transactionTime: '2024-03-31 07:29:22',
        desctiption: 'Purchase at store',
        amount: 138.54,
    },
]
