import React from 'react'
import { Box, Select, FormControl, MenuItem, InputLabel, useTheme, Typography } from '@mui/material'
import Header from '../../components/Header'
import BarChart from '../../components/BarChart'
import { tokens } from '../../theme'
import { mockBarChartStatisticsService } from '../../data/mockData'

function Bar() {
    const [typeStatistics, setTypeStatistics] = React.useState('')
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    const handleChange = (event) => {
        setTypeStatistics(event.target.value)
    }
    return (
        <Box m='20px'>
            {/* <Header title='Bar Chart' /> */}
            <FormControl sx={{ minWidth: 100 }}>
                <InputLabel id='demo-simple-select-autowidth-label'>Thống kê</InputLabel>
                <Select
                    labelId='demo-simple-select-autowidth-label'
                    id='demo-simple-select-autowidth'
                    value={typeStatistics}
                    onChange={handleChange}
                    autoWidth
                    label='Thống kê'
                >
                    <MenuItem value={10}>Hôm nay</MenuItem>
                    <MenuItem value={21}>Tuần trước</MenuItem>
                    <MenuItem value={22}>Tháng trước</MenuItem>
                </Select>
            </FormControl>
            <Box height='70vh'>
                {/* <BarChart
                    nameChart='statisticsService'
                    data={mockBarChartStatisticsService}
                /> */}
            </Box>
        </Box>
    )
}

export default Bar
