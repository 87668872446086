import { createSlice } from '@reduxjs/toolkit'

const getEventUpdateCleanSlice = createSlice({
    name: 'getEventUpdateClean',
    initialState: {
        data: null,
    },
    reducers: {
        eventUpdateClean: (state, action) => {
            state.data = action.payload
        },
    },
})

export default getEventUpdateCleanSlice
