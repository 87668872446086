import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    MenuItem,
    Modal,
    Select,
    TextField,
    Typography,
    useTheme,
} from '@mui/material'
import { Formik } from 'formik'
import { tokens } from '../../theme'
import { Cancel, Close, Send } from '@mui/icons-material'
import PercentIcon from '@mui/icons-material/Percent'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import CustomLoadingComponent from '../../components/loading'
import TextFieldCustom from '../../components/form/textfield'

const parseNumber = (value) => {
    if (!value) return value
    return value.toString().replace(/,/g, '')
}

const formatNumber = (value) => {
    if (!value) return value
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

function ModalDiscount({ open, onClose, isLoading, initialValues, validationSchema, handleSubmit, boxName }) {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    //style
    const styleModal = {
        borderRadius: '5px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 700,
        maxWidth: 'calc(100vw - 40px)',
        maxHeight: '90vh',
        bgcolor: theme.palette.mode === 'dark' ? '#323d58' : '#e0e0e0',
        // bgcolor: colors.primary[400],
        border: '1px solid rgba(0, 0, 0, 0.1) 0px 4px 12px',
        boxShadow: 24,
        p: 4,
        pt: '15px',
    }
    const styleBox = {
        '& .MuiBox-root': {
            display: 'flex',
            alignItems: 'center',
            gap: '20px',
            width: '80%',
            '& .MuiTextField-root': {
                flex: 1,
            },
        },
        '& .MuiTypography-root': {
            minWidth: '120px',
            fontSize: '14px',
        },
        '& .MuiBox-root.select': {
            width: '100%',
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px',
        // maxHeight: '700px',
        maxHeight: '76vh',
        overflowY: 'auto',
        overflowX: 'hidden',
        mt: '5px',
        boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
        pb: '20px',
        pt: '50px',
        backgroundColor: theme.palette.mode === 'dark' ? '#142043' : '#e7e7e7',
        borderRadius: '5px',
        '& .Mui-focused fieldset': {
            borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.6) !important' : 'rgba(0, 0, 0, 0.8)', // Màu border khi focused
            borderWidth: theme.palette.mode === 'dark' ? '2px' : '1px !important',
        },
    }
    const styleBoxMobile = {
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' }, 
        gap: { xs: '10px!important', sm: '20px!important' },
        alignItems: { xs: 'flex-start!important', sm: 'center!important' },
        width: { xs: '90% !important', sm: `80%!important` },
    }
    return (
        <Modal open={open} onClose={onClose} style={{ backdropFilter: 'blur(5px)', zIndex: '99' }}>
            <Box sx={styleModal}>
                <IconButton
                    sx={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                    }}
                    onClick={onClose}
                >
                    <Close color='disabled' />
                </IconButton>
                <Box>
                    <Typography variant='h4' component='h2' color={colors.grey[400]}>
                        {boxName === 'addDiscount' ? 'Thêm ưu đãi mới' : 'Chỉnh sửa'}
                    </Typography>
                    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                            <Box component='form' onSubmit={handleSubmit} sx={styleBox}>
                                {isLoading && (
                                    <Box
                                        position='absolute'
                                        top={0}
                                        left={0}
                                        right={0}
                                        bottom={0}
                                        display='flex'
                                        alignItems='center'
                                        justifyContent='center'
                                        bgcolor='rgba(255, 255, 255, 0.2)'
                                        zIndex={2}
                                        width='100% !important'
                                    >
                                        <CustomLoadingComponent />
                                    </Box>
                                )}
                                <TextFieldCustom 
                                    label='Mã khuyến mãi'
                                    name='code'
                                    value={values.code || ''}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    placeholder='Nhập mã khuyến mãi'
                                    helperText='Có thể để trống, mã sẽ tự sinh!'
                                    widthFieldXs='90%'
                                    FormHelperTextProps={{
                                        sx: {
                                            color: theme.palette.mode === 'dark' ? '#73a4d4' : '#3d77b0',
                                        },
                                    }}
                                    inputProps={{
                                        style: {
                                            textTransform: 'uppercase', // Chuyển đổi chữ cái thành chữ hoa
                                        },
                                    }}
                                />
                               
                                <Box sx={{ width: { xs: '90% !important', sm: `80%!important` }, }}>
                                    <FormControl variant='filled' error={!!touched.type && !!errors.type} fullWidth>
                                        <Box className='select' width='100%' sx={{
                                            display: 'flex',
                                            flexDirection: { xs: 'column', sm: 'row' }, 
                                            gap: { xs: '10px!important', sm: '20px!important' },
                                            alignItems: { xs: 'flex-start!important', sm: 'center!important' },
                                            // width: { xs: '90% !important', sm: `80%!important` },
                                        }}>
                                            <Typography minWidth={'100px'}>Áp dụng: </Typography>
                                            <Select
                                                name='type'
                                                value={values.type || ''}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                placeholder='Theo phần trăm (%) hoặc giá tiền (VNĐ)'
                                                fullWidth
                                                error={!!touched.type && !!errors.type}
                                                disabled={boxName === 'editDiscount' ? true : false}
                                                sx={{
                                                    '& .MuiSelect-select': {
                                                        padding: '16px',
                                                    },
                                                    width: '100%'
                                                }}
                                            >
                                                <MenuItem value={1}>
                                                    <Typography>Giá tiền (VNĐ)</Typography>
                                                </MenuItem>
                                                <MenuItem value={2}>
                                                    <Typography>Phần trăm (%)</Typography>
                                                </MenuItem>
                                                <MenuItem value={3}>
                                                    <Typography>Theo tiền trên đơn hàng tối thiểu</Typography>
                                                </MenuItem>
                                                <MenuItem value={4}>
                                                    <Typography>Theo phần trăm (%) trên đơn hàng tối thiểu</Typography>
                                                </MenuItem>
                                            </Select>
                                        </Box>
                                        {touched.type && errors.type && (
                                            <FormHelperText
                                                sx={{
                                                    ml: '154px',
                                                }}
                                            >
                                                {errors.type}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Box>
                                <TextFieldCustom 
                                    label='Khuyến mãi'
                                    name='value'
                                    value={formatNumber(values.value) || ''}
                                    placeholder='Nhập giá trị khuyến mãi'
                                    onBlur={handleBlur}
                                    // onChange={handleChange}
                                    onChange={(e) => {
                                        const rawValue = parseNumber(e.target.value)
                                        setFieldValue('value', Number(rawValue))
                                    }}
                                    error={!!touched.value && !!errors.value}
                                    helperText={touched.value && errors.value}
                                    widthFieldXs='90%'
                                    InputProps={{
                                        endAdornment:
                                            values.type === 2 ? (
                                                // <InputAdornment position='end'>%</InputAdornment>
                                                <InputAdornment position='end'>
                                                    <PercentIcon />
                                                </InputAdornment>
                                            ) : (
                                                <InputAdornment sx={{ width: '6%', fontWeight: 600 }} position='end'>
                                                    VNĐ
                                                </InputAdornment>
                                            ),
                                    }}
                                />
                                
                                {values.type === 2 || values.type === 4 ? (
                                    <TextFieldCustom
                                        label='Khuyến mãi tối đa'
                                        name='maxValue'
                                        value={formatNumber(values.maxValue) || ''}
                                        placeholder='Nhập khuyến mãi tối đa'
                                        onBlur={handleBlur}
                                        // onChange={handleChange}
                                        onChange={(e) => {
                                            const rawValue = parseNumber(e.target.value)
                                            setFieldValue('maxValue', Number(rawValue))
                                        }}
                                        required
                                        error={!!touched.maxValue && !!errors.maxValue}
                                        helperText={touched.maxValue && errors.maxValue}
                                            widthFieldXs='90%'
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment sx={{ width: '6%', '& p': { fontWeight: 600 } }} position='end'>
                                                    VNĐ
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                ) : (
                                    <></>
                                )}

                                {values.type === 3 || values.type === 4 ? (
                                    <TextFieldCustom 
                                        label='Đơn hàng tối thiểu'
                                        name='minOrderValue'
                                        value={formatNumber(values.minOrderValue) || 0}
                                        placeholder='Nhập khuyến mãi tối đa'
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                            const rawValue = parseNumber(e.target.value)
                                            if (rawValue < 0) {
                                                setFieldValue('minOrderValue', 0)
                                            } else {
                                                setFieldValue('minOrderValue', Number(rawValue))
                                            }
                                        }}
                                        widthFieldXs='90%'
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment sx={{ width: '6%', '& p': { fontWeight: 600 } }} position='end'>
                                                    VNĐ
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                ) : (
                                    <></>
                                )}

                                <TextFieldCustom
                                    label='Số lượt dùng'
                                    name='limitTimes'
                                    value={values.limitTimes || ''}
                                    placeholder='Nhập số lượt dùng mã tối đa'
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    error={!!touched.limitTimes && !!errors.limitTimes}
                                    helperText={touched.limitTimes && errors.limitTimes}
                                    widthFieldXs='90%'
                                />
                                <Box sx={styleBoxMobile}>
                                    <Typography>Ngày bắt đầu: </Typography>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <DatePicker
                                            name='startDate'
                                            value={values.startDate}
                                            onChange={(newValue) => {
                                                setFieldValue('startDate', newValue)
                                            }}
                                            slotProps={{
                                                textField: {
                                                    variant: 'outlined',
                                                    margin: 'normal',
                                                    error: touched.startDate && !!errors.startDate,
                                                    helperText: touched.startDate && errors.startDate,
                                                    fullWidth: true,
                                                },
                                            }}
                                            sx={{ m: 0 }}
                                        />
                                    </LocalizationProvider>
                                </Box>
                                <Box sx={styleBoxMobile}>
                                    <Typography>Ngày kết thúc: </Typography>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <DatePicker
                                            name='endDate'
                                            value={values.endDate}
                                            onChange={(newValue) => {
                                                setFieldValue('endDate', newValue)
                                            }}
                                            slotProps={{
                                                textField: {
                                                    variant: 'outlined',
                                                    margin: 'normal',
                                                    error: touched.endDate && !!errors.endDate,
                                                    helperText: touched.endDate && errors.endDate,
                                                    fullWidth: true,
                                                },
                                            }}
                                            sx={{ m: 0 }}
                                        />
                                    </LocalizationProvider>
                                </Box>
                                <Box justifyContent='end' sx={{width: { xs: '90% !important', sm: `80%!important` }}}>
                                    <Button
                                        sx={{ minWidth: '96px', p: '8px 16px' }}
                                        type='submit'
                                        variant='contained'
                                        color='success'
                                        endIcon={<Send />}
                                    >
                                        Gửi
                                    </Button>
                                    <Button
                                        sx={{ minWidth: '96px', p: '8px 16px' }}
                                        variant='outlined'
                                        color='inherit'
                                        startIcon={<Cancel />}
                                        onClick={onClose}
                                    >
                                        Huỷ
                                    </Button>
                                </Box>
                            </Box>
                        )}
                    </Formik>
                </Box>
            </Box>
        </Modal>
    )
}

export default ModalDiscount
