import React from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import {
    ClassicEditor,
    Bold,
    Essentials,
    Italic,
    Paragraph,
    Heading,
    FontFamily,
    FontSize,
    FontColor,
    FontBackgroundColor,
    Strikethrough,
    FontSizeUI,
    Alignment,
    Subscript,
    Link,
    // Image,
    // ImageCaption,
    // ImageResize,
    // ImageStyle,
    // ImageToolbar,
    // LinkImage,
    // ImageUpload,
    // FileRepository,
    // SimpleUploadAdapter,
    Superscript,
    GeneralHtmlSupport,
    SourceEditing,
} from 'ckeditor5'
import { Box, useTheme } from '@mui/material'
import { tokens } from '../theme'

const CKEditorComponent = ({ content, setContent }) => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    return (
        <Box
            className='Mui-ckediter'
            minHeight='300px'
            sx={{
                '& p': {
                    color: 'black !important',
                },
                '& .ck-toolbar': {
                    backgroundColor: theme.palette.mode === 'dark' ? `${colors.grey[100]} !important` : 'inherit',
                },
                '& .ck-content': {
                    minHeight: '300px !important',
                    backgroundColor: theme.palette.mode === 'dark' ? `${colors.grey[100]} !important` : 'inherit',
                },
                '& .ck-source-editing-area': {
                    color: 'black !important',
                },
            }}
        >
            <CKEditor
                editor={ClassicEditor}
                config={{
                    plugins: [
                        Essentials,
                        Bold,
                        Italic,
                        Paragraph,
                        Heading,
                        FontFamily,
                        FontSize,
                        FontColor,
                        FontBackgroundColor,
                        FontSizeUI,
                        Alignment,
                        Strikethrough,
                        Subscript,
                        Superscript,
                        Link,
                        // Image,
                        // ImageToolbar,
                        // ImageCaption,
                        // ImageStyle,
                        // ImageResize,
                        // LinkImage,
                        // ImageUpload,
                        // FileRepository,
                        // SimpleUploadAdapter,
                        // Style,
                        GeneralHtmlSupport,
                        SourceEditing,
                    ],
                    toolbar: [
                        'undo',
                        'redo',
                        '|',
                        'heading',
                        '|',
                        'bold',
                        'italic',
                        '|',
                        'fontfamily',
                        'fontsize',
                        'fontColor',
                        'fontBackgroundColor',
                        '|',
                        'alignment',
                        '|',
                        'strikethrough',
                        'subscript',
                        'superscript',
                        '|',
                        'link',
                        // 'insertImage',
                        // 'image',
                        'sourceEditing',
                    ],
                    fontSize: {
                        options: [16, 18, 'default', 20, 22, 24, 26, 28, 30],
                        supportAllValues: true,
                    },
                    // style: {
                    //     definitions: [
                    //         {
                    //             name: 'Article category',
                    //             element: 'h3',
                    //             classes: ['category'],
                    //         },
                    //         {
                    //             model: 'paragraph',
                    //             name: 'style-tag-p',
                    //             element: 'p',
                    //             classes: ['style-tag-p'],
                    //         },
                    //     ],
                    // },
                    htmlSupport: {
                        allow: [
                            // Enables all HTML features.
                            {
                                name: /.*/,
                                attributes: true,
                                classes: true,
                                styles: true,
                            },
                        ],
                        disallow: [
                            {
                                attributes: [{ key: /.*/, value: /data:(?!image\/(png|jpeg|gif|webp))/i }],
                            },
                        ],
                    },
                }}
                data={content}
                onChange={(event, editor) => {
                    const data = editor.getData()
                    setContent(data)
                }}
            />
        </Box>
    )
}

export default CKEditorComponent
