import React, { useEffect, useState } from 'react'
import { Box, Typography, useTheme, IconButton, Button, Popover } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { tokens } from '../../theme'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import Header from '../../components/Header'
import GroupAddIcon from '@mui/icons-material/GroupAdd'
import StyleDataGrid from '../../assets/styles/styleDataGrid'
import CustomNoRowsOverlay from '../../components/noRows'
import request from '../../utils/api/request'
import { delete_service, get_services } from '../../utils/api/apiList'
import CustomLoadingComponent from '../../components/loading'
import { message } from 'antd'
import { formatNumber } from '../../components/prefixData'
import ModalService from './ModalService'

const Service = () => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const [dataService, setDataService] = useState([])
    const [pageSize, setPageSize] = useState(10)
    const [anchorEl, setAnchorEl] = useState(null)
    const [currentRow, setCurrentRow] = useState(null)
    const [anchorElDelete, setAlchorElDelete] = useState(null)
    const [loading, setLoading] = useState(true)
    const [type, setType] = useState(null)

    const role = localStorage.getItem('role')

    //message
    const [messageApi, messageContextHolder] = message.useMessage()

    const fetchData = () => {
        request
            .post(get_services)
            .then((res) => {
                const modifiedData = res.data.data.docs.map((item, index) => ({
                    ...item,
                    index: index + 1,
                    priceConvert: formatNumber(item.price || 0) + ' VNĐ',
                }))
                setDataService(modifiedData)
                setLoading(false)
            })
            .catch((err) => console.log(err))
    }

    useEffect(() => {
        fetchData()
    }, [])

    //create/edit service
    const handleOpenModal = (e, row, type) => {
        setAnchorEl(e.currentTarget)
        setCurrentRow(row)
        setType(type)
    }
    const handleCloseModal = () => {
        setAnchorEl(null)
        setType(null)
        setCurrentRow(null)
    }

    //delete
    const handleDelete = (row, event) => {
        setCurrentRow(row)
        setAlchorElDelete(event.currentTarget)
    }

    const handleCloseDelete = () => {
        setAlchorElDelete(null)
    }

    const handleConfirmDelete = () => {
        request
            .post(delete_service, { _id: currentRow._id })
            .then(() => {
                message.success(
                    <p style={{ display: 'inline-block' }}>
                        Xoá dịch vụ <span style={{ color: 'green', fontWeight: '600' }}>{currentRow.name}</span> thành công!
                    </p>
                )
                fetchData()
            })
            .catch((err) => {
                message.error('Xoá dịch vụ thất bại!')
                console.log(err)
            })

        setAlchorElDelete(null)
    }

    const columns = [
        {
            field: 'index',
            headerName: 'STT',
            width: 50,
        },
        {
            field: 'name',
            headerName: 'Tên dịch vụ',
            flex: 1,
            minWidth: 300,
            cellClassName: 'name-column--cell--notTask',
        },
        {
            field: 'priceConvert',
            headerName: 'Giá',
            flex: 1,
            minWidth: 100,
        },
        {
            field: 'description',
            headerName: 'Mô tả',
            flex: 1,
            minWidth: 150,
        },
        role === 'admin' && {
            field: 'action',
            headerName: 'Hành động',
            flex: 1,
            minWidth: 100,
            // headerAlign: 'center',
            cellClassName: 'action-column',
            align: 'left',
            sortable: false,
            renderCell: ({ row }) => (
                <Box display='flex' alignItems='center' gap={1}>
                    <IconButton onClick={(e) => handleOpenModal(e, row, 'edit')} color='secondary' aria-label='edit'>
                        <EditIcon />
                    </IconButton>
                    <IconButton onClick={(event) => handleDelete(row, event)} aria-label='delete'>
                        <DeleteIcon />
                    </IconButton>
                </Box>
            ),
        },
    ].filter(Boolean) // Loại bỏ các cột có giá trị là `undefined` hoặc `false`

    //pagination
    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize)
    }

    return (
        <Box
            m='20px'
            sx={{
                '& .MuiTypography-root.MuiTypography-h2': {
                    margin: '0 0 5px 0',
                },
            }}
        >
            {messageContextHolder}
            <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Header title='DỊCH VỤ' subtitle='Trang quản lý dịch vụ' />
                {role === 'admin' && (
                    <Button
                        sx={{
                            backgroundColor: colors.blueAccent[700],
                            background: theme.palette.mode === 'dark' ? '#3e4396' : 'linear-gradient(to right, #b993d6, #8ca6db)',
                            color: 'white',
                            fontSize: '14px',
                            fontWeight: 'bold',
                            padding: '10px 20px',
                            '&:hover': {
                                background: theme.palette.mode === 'dark' ? '#3e4366' : 'linear-gradient(to right, #c8b7d5, #a6beee)',
                            },
                        }}
                        onClick={(e) => handleOpenModal(e, {}, 'create')}
                    >
                        <GroupAddIcon sx={{ mr: '10px' }} />
                        THÊM DỊCH VỤ
                    </Button>
                )}
            </Box>
            <Box height='75vh' m='40px 0 0 0' sx={StyleDataGrid()}>
                <DataGrid
                    rows={dataService}
                    getRowId={(row) => row._id}
                    columns={columns}
                    pageSize={pageSize}
                    initialState={{
                        ...dataService.initialState,
                        pagination: { paginationModel: { pageSize: 100 } },
                    }}
                    pageSizeOptions={[50, 100]}
                    pagination
                    loading={loading}
                    slots={{
                        toolbar: GridToolbar,
                        noRowsOverlay: CustomNoRowsOverlay,
                        loadingOverlay: CustomLoadingComponent,
                    }}
                    onPageSizeChange={handlePageSizeChange}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                        },
                    }}
                />
            </Box>
            {role === 'admin' && (
                <ModalService type={type} open={Boolean(anchorEl)} onClose={handleCloseModal} currentRow={currentRow} fetchData={fetchData} />
            )}
            {role === 'admin' && (
                <Popover
                    id={currentRow?._id}
                    open={Boolean(anchorElDelete)}
                    anchorElDelete={anchorElDelete}
                    onClose={handleCloseDelete}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <Box p={2}>
                        <Typography>Bạn muốn xoá dịch vụ này?</Typography>
                        <Box
                            marginTop='10px'
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Button
                                size='small'
                                variant='contained'
                                color='error'
                                onClick={handleConfirmDelete}
                                sx={{
                                    fontWeight: 600,
                                    color: colors.grey[100],
                                    bgcolor: '#d40000',
                                    color: 'white',
                                    fontWeight: '600',
                                    mr: '20px',
                                    '&:hover': {
                                        bgcolor: 'red',
                                    },
                                }}
                            >
                                OK
                            </Button>
                            <Button
                                size='small'
                                onClick={handleCloseDelete}
                                variant='outlined'
                                sx={{
                                    bgcolor: 'white',
                                    '&:hover': {
                                        bgcolor: '#e8e8e8c4',
                                    },
                                }}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Box>
                </Popover>
            )}
        </Box>
    )
}

export default Service
