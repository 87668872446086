export const routerLinks = {
    //admin
    Dashboard: '/',
    CompanyManagement: '/company-management',
    Service: '/service',
    PriceList: '/price-list',
    Users: '/users',

    //DN
    Employee: '/employee',
    VerifyEmployee: '/verify-employee',
    Competence: '/competence-employee',
    Withdraw: '/withdraw',
    Deposit: '/deposit',

    TaskBooking: '/Task-Booking',
    Clean: '/Clean',
    PermanentTask: '/Permanent-Task',
    Aircondition: '/Air-Condition',
    Sofa: '/Sofa',
    Spray: '/Spray',

    //details permanentTask
    DetailsPermanentTask: '/details-permanent-task',

    NewOrder: '/new-order',
    Discount: '/discount',
    Banner: '/banner',
    StatisticsUser: '/statistics-user',
    StatisticsService: '/service-volume-statistics',
    StatisticsIncome: '/statistics-income',
    StatisticsTransaction: '/statistics-transaction',
    StatisticsBankPartner: '/statistics-bank-partner',

    //setting
    CompanySetting: '/company-setting',
    AdminSetting: '/admin-setting',
    CompanyAccountSetting: '/account-setting',
    PolicySetting: '/policy-setting',
    SocialsSetting: '/socials-setting',

    ////////////// Thống kê admin //////////////////////
    AnalyzesUser: '/analyzes-user',
    AnalyzesPartner: '/analyzes-partner',

    CompanyRevenue: '/analyzes-company-revenue',
    CompanyProfit: '/analyzes-company-profit',

    //services doanh thu
    TaskRevenue: '/analyzes-task-revenue',
    CleanRevenue: '/analyzes-clean-revenue',
    PermanentRevenue: '/analyzes-permanent-revenue',
    SofaRevenue: '/analyzes-sofa-revenue',
    AirRevenue: '/analyzes-airCondition-revenue',
    SprayRevenue: '/analyzes-spray-revenue',

    //services lợi nhuận
    TaskProfit: '/analyzes-task-profit',
    CleanProfit: '/analyzes-clean-profit',
    PermanentProfit: '/analyzes-permanent-profit',
    SofaProfit: '/analyzes-sofa-profit',
    AirProfit: '/analyzes-airCondition-profit',
    SprayProfit: '/analyzes-spray-profit',

    // thống kê deposit
    AnalyzesDeposit: '/analyzes-deposit',

    ////////////// Thống kê admin //////////////////////

    //payment history
    PaymentHistory: '/payment-history',

    //all
    Form: '/form',
    Calendar: '/calendar',
    Faq: '/faq',
    Bar: '/bar',
    Pie: '/pie',
    Line: '/line',
    Geography: '/geography',
    Login: '/login',
}

export const m_routerLinks = {
    M_Dashboard: '/',
    M_Settings: '/setting-general',
}
