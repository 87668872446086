import { createContext, useState, useMemo } from 'react'
import { createTheme } from '@mui/material/styles'

// color design tokens export
export const tokens = (mode) => ({
    ...(mode === 'dark'
        ? {
              grey: {
                  100: '#e0e0e0',
                  200: '#c2c2c2',
                  300: '#a3a3a3',
                  400: '#858585',
                  500: '#666666',
                  600: '#525252',
                  700: '#3d3d3d',
                  800: '#292929',
                  900: '#141414',
              },
              primary: {
                  100: '#d0d1d5',
                  200: '#a1a4ab',
                  300: '#283351', //'#727681',
                  400: '#1F2A40',
                  500: '#141b2d',
                  600: '#101624',
                  700: '#0c101b',
                  800: '#080b12',
                  900: '#040509',
              },
              greenAccent: {
                  100: '#dbf5ee',
                  200: '#b7ebde',
                  300: '#94e2cd',
                  400: '#70d8bd',
                  500: '#4cceac',
                  600: '#3da58a',
                  700: '#2e7c67',
                  800: '#1e5245',
                  900: '#0f2922',
              },
              greenLight: {
                  100: '#d4f3d4',
                  200: '#a9e7a9',
                  300: '#7ddc7d',
                  400: '#52d052',
                  500: '#27c427',
                  600: '#1f9d1f',
                  700: '#177617',
                  800: '#104e10',
                  900: '#082708',
              },
              redAccent: {
                  100: '#f8dcdb',
                  200: '#f1b9b7',
                  300: '#e99592',
                  400: '#e2726e',
                  500: '#db4f4a',
                  600: '#af3f3b',
                  700: '#832f2c',
                  800: '#58201e',
                  900: '#2c100f',
              },
              blueAccent: {
                  100: '#e1e2fe',
                  200: '#c3c6fd',
                  300: '#a4a9fc',
                  400: '#868dfb',
                  500: '#6870fa',
                  600: '#535ac8',
                  700: '#3e4396',
                  800: '#2a2d64',
                  900: '#151632',
              },
              orangeAccent: {
                  100: '#f7e8d3',
                  200: '#efd0a7',
                  300: '#e6b97b',
                  400: '#dea14f',
                  500: '#d68a23',
                  600: '#ab6e1c',
                  700: '#805315',
                  800: '#56370e',
                  900: '#2b1c07',
              },
          }
        : {
              grey: {
                  100: '#141414',
                  200: '#292929',
                  300: '#3d3d3d',
                  400: '#525252',
                  500: '#666666',
                  600: '#858585',
                  700: '#a3a3a3',
                  800: '#c2c2c2',
                  900: '#e0e0e0',
              },
              primary: {
                  100: '#040509',
                  200: '#080b12',
                  300: '#0c101b',
                  400: '#ffffff', // manually changed f2f0f0
                  500: '#e0e0e0',
                  600: '#1F2A40',
                  700: '#727681',
                  800: '#a1a4ab',
                  900: '#d0d1d5',
              },
              greenAccent: {
                  100: '#0f2922',
                  200: '#1e5245',
                  300: '#2e7c67',
                  400: '#3da58a',
                  500: '#4cceac',
                  600: '#70d8bd',
                  700: '#94e2cd',
                  800: '#b7ebde',
                  900: '#dbf5ee',
              },
              greenLight: {
                  100: '#082708',
                  200: '#104e10',
                  300: '#177617',
                  400: '#1f9d1f',
                  500: '#27c427',
                  600: '#52d052',
                  700: '#7ddc7d',
                  800: '#a9e7a9',
                  900: '#d4f3d4',
              },
              redAccent: {
                  100: '#2c100f',
                  200: '#58201e',
                  300: '#832f2c',
                  400: '#af3f3b',
                  500: '#db4f4a',
                  600: '#e2726e',
                  700: '#e99592',
                  800: '#f1b9b7',
                  900: '#f8dcdb',
              },
              blueAccent: {
                  100: '#151632',
                  200: '#2a2d64',
                  300: '#3e4396',
                  400: '#535ac8',
                  500: '#6870fa',
                  600: '#868dfb',
                  700: '#a4a9fc',
                  800: '#c3c6fd',
                  900: '#e1e2fe',
              },
              orangeAccent: {
                  100: '#2b1c07',
                  200: '#56370e',
                  300: '#805315',
                  400: '#ab6e1c',
                  500: '#d68a23',
                  600: '#dea14f',
                  700: '#e6b97b',
                  800: '#efd0a7',
                  900: '#f7e8d3',
              },
          }),
})

// mui theme settings
export const themeSettings = (mode) => {
    const colors = tokens(mode)
    return {
        palette: {
            mode: mode,
            ...(mode === 'dark'
                ? {
                      // palette values for dark mode
                      primary: {
                          main: colors.primary[500],
                      },
                      secondary: {
                          main: colors.greenAccent[500],
                      },
                      neutral: {
                          dark: colors.grey[700],
                          main: colors.grey[500],
                          light: colors.grey[100],
                      },
                      background: {
                          default: colors.primary[500],
                      },
                  }
                : {
                      // palette values for light mode
                      primary: {
                          main: colors.primary[100],
                      },
                      secondary: {
                          main: colors.greenAccent[500],
                      },
                      neutral: {
                          dark: colors.grey[700],
                          main: colors.grey[500],
                          light: colors.grey[100],
                      },
                      background: {
                          default: '#fcfcfc',
                      },
                  }),
        },
        typography: {
            fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
            fontSize: 12,
            h1: {
                fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
                fontSize: 40,
            },
            h2: {
                fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
                fontSize: 32,
            },
            h3: {
                fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
                fontSize: 24,
            },
            h4: {
                fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
                fontSize: 20,
            },
            h5: {
                fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
                fontSize: 16,
            },
            h6: {
                fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
                fontSize: 14,
            },
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: {
                    '*::-webkit-scrollbar': {
                        width: '5px',
                    },
                    '*::-webkit-scrollbar-track': {
                        background: '#E4EFEF',
                    },
                    '*::-webkit-scrollbar-thumb': {
                        background: '#1D388F61',
                        borderRadius: '2px',
                    },
                    '*::-webkit-scrollbar-thumb:hover': {
                        background: '#6878a9', // Màu khi hover
                        cursor: 'pointer',
                    },
                    '*::-webkit-scrollbar-track:hover': {
                        background: '#E4EFEF',
                        cursor: 'pointer',
                    },
                    '@keyframes blink': {
                        '0%': { opacity: '0.3' },
                        '50%': { opacity: '0.9' },
                        '100%': { opacity: '0.3' },
                    },
                    '.blink-animation': {
                        animation: 'blink 1s linear 3',
                    },
                },
            },
        },
    }
}

// context for color mode
export const ColorModeContext = createContext({
    toggleColorMode: () => {},
})

export const useMode = () => {
    const [mode, setMode] = useState(() => {
        const storedMode = localStorage.getItem('themeMode')
        return storedMode ? storedMode : 'dark'
    })

    const colorMode = useMemo(
        () => ({
            toggleColorMode: () => {
                // setMode((prev) => (prev === 'light' ? 'dark' : 'light'))
                const newMode = mode === 'light' ? 'dark' : 'light'
                setMode(newMode)
                localStorage.setItem('themeMode', newMode)
            },
        }),
        [mode],
    )

    const theme = useMemo(() => createTheme(themeSettings(mode)), [mode])

    return [theme, colorMode]
}
