import { useState, useEffect, useMemo, useCallback } from 'react'
import { Box, Typography, useTheme, Chip, debounce } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { tokens } from '../../../theme'
import Header from '../../../components/Header'
import StyleDataGrid from '../../../assets/styles/styleDataGrid'
import request from '../../../utils/api/request'
import CustomNoRowsOverlay from '../../../components/noRows'
import CustomLoadingComponent from '../../../components/loading'
import { getRowClassName, formatNumber, parseNumber } from '../../../components/prefixData'
import connectSocket from '../../../utils/api/connectSocket'
import { get_all_payment } from '../../../utils/api/apiList'
import moment from 'moment'

//style status
const getColor = (status) => {
    switch (status) {
        case 'CANCEL':
            return 'error'
        case 'PENDING':
            return 'warning'
        default:
            return 'default'
    }
}
// filter column - filter trực tiếp trên columns
// const VISIBLE_FIELDS = ['index', '_id', 'orderCode', 'reference', 'transactionDateTime', 'amount', 'statusConvert']

function Transaction() {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const [loading, setLoading] = useState(true)
    const [dataTransaction, setDataTransaction] = useState([])
    const [queryOptions, setQueryOptions] = useState()

    // pagination server side
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 25,
    })
    const [totalRows, setTotalRows] = useState(0)

    //column
    const columns = useMemo(
        () => [
            { field: 'index', headerName: 'STT', width: 50, sortable: false },
            { field: '_id', headerName: 'Mã giao dịch', minWidth: 250, flex: 0.5, sortable: false },
            {
                field: 'fullName',
                headerName: 'Người giao dịch',
                minWidth: 150,
                flex: 0.5,
                sortable: false,
            },
            {
                field: 'orderCode',
                headerName: 'Order Code',
                minWidth: 150,
                flex: 0.5,
                sortable: false,
            },
            {
                field: 'reference',
                headerName: 'Tham chiếu',
                minWidth: 150,
                flex: 0.5,
                sortable: false,
            },
            {
                field: 'amount',
                headerName: 'Số tiền',
                width: 120,
                flex: 0.5,
                cellClassName: 'cost-column name-column--cell',
                renderCell: ({ row }) => {
                    return formatNumber(row.amount)
                },
                valueGetter: (value) => {
                    return parseNumber(value)
                },
                sortable: false,
            },
            {
                field: 'transactionDateTime',
                headerName: 'Thời gian',
                minWidth: 100,
                flex: 0.5,
                renderCell: ({ row }) => {
                    const time = moment(row.transactionDateTime).format('HH:mm:ss')
                    const day = moment(row.transactionDateTime).format('DD-MM-YYYY')
                    return (
                        <Box>
                            <Typography color={colors.greenAccent[400]} fontSize='14px'>
                                {time}
                            </Typography>
                            <Typography fontSize='14px'>{day}</Typography>
                        </Box>
                    )
                },
                valueGetter: (value) => {
                    const day = moment(value).format('DD-MM-YYYY')
                    return day
                },
            },
            // {
            //     field: 'description',
            //     headerName: 'Mô tả',
            //     minWidth: 250,
            //     flex: 0.7,
            // },
            {
                field: 'statusConvert',
                headerName: 'Trạng thái',
                width: 120,
                flex: 0.5,
                renderCell: ({ row }) => (
                    <Chip size='small' label={row.statusConvert} color={getColor(row.status)} sx={{ minWidth: 80, fontWeight: 600 }} />
                ),
            },
        ],
        []
    )

    // convert root field
    const mapFieldName = (field) => {
        switch (field) {
            case 'statusConvert':
                return 'status'
            default:
                return field
        }
    }

    // sort server
    const handleSortModelChange = useCallback((sortModel) => {
        if (sortModel.length > 0) {
            const sortField = mapFieldName(sortModel[0].field)
            const sortOrder = sortModel[0].sort === 'asc' ? 1 : -1
            setQueryOptions({ [sortField]: sortOrder })
        } else {
            setQueryOptions({})
        }
    }, [])

    //fetching
    useEffect(() => {
        const socket = connectSocket()
        socket.on('connect', () => {
            console.log('Socket is connected')
        })

        fetchData()
    }, [paginationModel, queryOptions])

    //fetch data
    const fetchData = () => {
        request
            .post(get_all_payment, { page: paginationModel.page + 1, limit: paginationModel.pageSize, sort: queryOptions })
            .then((res) => {
                const dataConvert = res.data.data.docs.map((elm, index) => {
                    let status
                    switch (elm.status) {
                        case 'CANCEL':
                            status = 'đã huỷ'
                            break
                        case 'PENDING':
                            status = 'đang chờ'
                            break
                        case 'PAID':
                            status = 'đã thanh toán'
                            break
                        default:
                            break
                    }

                    return {
                        ...elm,
                        index: index + 1 + paginationModel.page * paginationModel.pageSize,
                        statusConvert: status,
                        fullName: elm?.userId?.fullName,
                    }
                })

                setDataTransaction(dataConvert)
                setTotalRows(res.data.data.total)
                setLoading(false)
            })
            .catch((err) => console.log(err))
    }

    return (
        <Box
            m='20px'
            sx={{
                '& .MuiTypography-root.MuiTypography-h2': {
                    margin: '0 0 5px 0',
                },
            }}
        >
            <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Header title='THỐNG KÊ GIAO DỊCH' subtitle='Trang thống kê giao dịch' />
            </Box>
            <Box height='75vh' m='40px 0 0 0' sx={StyleDataGrid()}>
                <DataGrid
                    getRowId={(row) => row._id}
                    rows={dataTransaction}
                    columns={columns}
                    //pagination server side
                    rowCount={totalRows}
                    initialState={{
                        pagination: { paginationModel },
                        density: 'comfortable',
                    }}
                    pagination
                    pageSizeOptions={[10, 25, 50]}
                    paginationMode='server'
                    onPaginationModelChange={setPaginationModel}
                    //pagination server side

                    // sort server
                    sortingMode='server'
                    onSortModelChange={handleSortModelChange}
                    loading={loading}
                    slots={{
                        toolbar: GridToolbar,
                        noRowsOverlay: CustomNoRowsOverlay,
                        loadingOverlay: CustomLoadingComponent,
                    }}
                    getRowClassName={getRowClassName}
                />
            </Box>
        </Box>
    )
}

export default Transaction
