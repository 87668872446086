import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Typography, useTheme, IconButton, Button, Tooltip, Modal, Popover, debounce, Chip } from '@mui/material'
import { DataGrid, GridToolbar, gridClasses } from '@mui/x-data-grid'
import { tokens } from '../../../../theme'
import { dataDeposit } from '../../../../data/mockData'
import Header from '../../../../components/Header'
import { useDispatch } from 'react-redux'
import PieChartOutlinedIcon from '@mui/icons-material/PieChartOutlined'
import Pie from '../../../pie'
import CustomNoRowsOverlay from '../../../../components/noRows'
import StyleDataGrid from '../../../../assets/styles/styleDataGrid'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import DoNotTouchOutlinedIcon from '@mui/icons-material/DoNotTouchOutlined'
import PopoverComponent from '../../../../components/popover'
import request from '../../../../utils/api/request'
import { check_withdrawal_request, get_all_withdrawal_request, update_withdrawal_request } from '../../../../utils/api/apiList'
import moment from 'moment'
import { formatNumber } from '../../../../components/prefixData'
import { message } from 'antd'

//style status
const getColor = (status) => {
    switch (status) {
        case 0:
            return 'warning'
        case 2:
            return 'error'
        default:
            return 'default'
    }
}

const WithDraw = () => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const [data, setData] = useState([])
    // const [pageSize, setPageSize] = useState(10)
    const [anchorEl, setAnchorEl] = useState(null)
    const [rejectAnchorEl, setRejectAnchorEl] = useState(null)
    const [loading, setLoading] = useState(true)
    const [idRow, setIdRow] = useState(null)
    const [currentRow, setCurrentRow] = useState(null)
    const [queryOptions, setQueryOptions] = useState({
        dateCreated: -1,
    })

    //message
    const [messageApi, messageContextHolder] = message.useMessage()

    // pagination server side
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 25,
    })
    const [totalRows, setTotalRows] = useState(0)

    // search server side
    const [searchTerm, setSearchTerm] = useState('')
    const [inputText, setInputText] = useState('')

    // Debounced
    const debouncedSetSearchTerm = useCallback(
        debounce((value) => {
            setSearchTerm(value)
        }, 700),
        []
    )

    // handle search
    const handleInputChange = (e) => {
        const value = e.target.value
        setInputText(value)
        debouncedSetSearchTerm(value)
    }

    // convert root field
    const mapFieldName = (field) => {
        switch (field) {
            case 'statusConvert':
                return 'status'
            default:
                return field
        }
    }

    // sort server
    const handleSortModelChange = useCallback((sortModel) => {
        if (sortModel.length > 0) {
            const sortField = mapFieldName(sortModel[0].field)
            const sortOrder = sortModel[0].sort === 'asc' ? 1 : -1
            setQueryOptions({ [sortField]: sortOrder })
        } else {
            setQueryOptions({ dateCreated: -1 })
        }
    }, [])

    //fetching
    useEffect(() => {
        fetchDataWithDrawal()
    }, [paginationModel, searchTerm, queryOptions])

    //fetchData
    const fetchDataWithDrawal = () => {
        request
            .post(get_all_withdrawal_request, {
                page: paginationModel.page + 1,
                limit: paginationModel.pageSize,
                search: searchTerm,
                sort: queryOptions,
            })
            .then((res) => {
                const dataRes = res.data.data.docs.map((item) => {
                    let statusConvert
                    switch (item?.status) {
                        case 0:
                            statusConvert = 'chờ xử lý'
                            break
                        case 1:
                            statusConvert = 'chấp nhận'
                            break
                        case 2:
                            statusConvert = 'từ chối'
                            break
                        default:
                            break
                    }
                    return {
                        ...item,
                        amount: `${formatNumber(item?.amount)} VNĐ`,
                        amountRoot: item?.amount,
                        statusConvert,
                        partnerName: item?.userId?.fullName,
                        nameCompany: item?.userId?.fromCompany?.name,
                    }
                })

                setData(dataRes)
                setTotalRows(res.data.data.total)
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
                console.log(err)
            })
    }

    //columns
    const columns = useMemo(
        () => [
            {
                field: 'partnerName',
                headerName: 'Tên nhân viên',
                width: 150,
                sortable: false,
            },
            {
                field: 'nameCompany',
                headerName: 'Tên công ty',
                flex: 1,
                minWidth: 200,
                sortable: false,
            },
            {
                field: 'bankAccountName',
                headerName: 'Chủ tài khoản',
                cellClassName: 'name-column--cell',
                flex: 1,
                minWidth: 200,
                sortable: false,
            },
            {
                field: 'accountNumber',
                headerName: 'Số tài khoản',
                flex: 1,
                minWidth: 120,
                sortable: false,
            },
            {
                field: 'bank',
                headerName: 'Ngân hàng',
                flex: 1,
                minWidth: 120,
                sortable: false,
            },
            {
                field: 'amount',
                headerName: 'Số tiền rút',
                flex: 1,
                minWidth: 100,
                cellClassName: 'name-column--cell',
                sortable: false,
            },
            {
                field: 'dateCreated',
                headerName: 'Thời gian khởi tạo',
                width: 150,
                renderCell: ({ row }) => {
                    const time = moment(row?.dateCreated).format('DD-MM-YYYY hh:mm')
                    return <Box>{time}</Box>
                },
            },
            {
                field: 'statusConvert',
                headerName: 'Trạng thái',
                width: 100,
                renderCell: ({ row }) => (
                    <Chip size='small' label={row.statusConvert} color={getColor(row.status)} sx={{ minWidth: 80, fontWeight: 600 }} />
                ),
            },
            {
                field: 'action',
                headerName: 'Hành động',
                cellClassName: 'action-column',
                width: 100,
                minWidth: 100,
                sortable: false,
                renderCell: ({ row }) => {
                    return (
                        <Box>
                            <Tooltip title='Chấp nhận yêu cầu rút'>
                                <IconButton onClick={(e) => handleOpenBoxAcceptWithDraw(row, e)} disabled={row.status === 0 ? false : true}>
                                    <CheckCircleOutlineOutlinedIcon color={row.status !== 0 ? 'disabled' : 'success'} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title='Từ chối yêu cầu'>
                                <IconButton onClick={(e) => handleOpenBoxRejectWithDrawal(row, e)} disabled={row.status === 0 ? false : true}>
                                    <DoNotTouchOutlinedIcon color={row.status !== 0 ? 'disabled' : 'error'} />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )
                },
            },
        ],
        []
    )

    //accept yeu cau
    const fetchUpdateWithDrawal = (note) => {
        const updatedData = {
            _id: currentRow._id,
            userId: currentRow.userId._id,
            status: rejectAnchorEl ? 2 : 1, //0 pending, 1 accept, 2: reject
            amount: currentRow.amountRoot,
            reason: note,
        }
        request
            .post(update_withdrawal_request, { ...updatedData })
            .then((res) => {
                message.success('Chấp nhận yêu cầu rút tiền!')
                fetchDataWithDrawal()
                console.log(res)
            })
            .catch((err) => {
                message.error('Lỗi, vui lòng thử lại!')
                console.log(err)
            })
    }

    // check truoc khi accept
    const fetchCheckWithDrawal = async (userId) => {
        console.log(userId)

        return request
            .post(check_withdrawal_request, { userId })
            .then((res) => {
                console.log(res)

                if (res.data.success && res.data.data.isValid) {
                    return true
                } else {
                    throw new Error('UserNotFound')
                }
            })
            .catch((err) => {
                console.error(err.message || 'Kiểm tra thất bại')
                return false
            })
    }

    //handle open/close box accept withdraw
    const handleOpenBoxAcceptWithDraw = (row, e) => {
        setAnchorEl(e.currentTarget)
        setIdRow(row._id)
        setCurrentRow(row)
    }

    const handleCloseBoxAcceptWithDraw = () => {
        setAnchorEl(!anchorEl)
    }

    const handleConfirm = async () => {
        const isValid = await fetchCheckWithDrawal(currentRow.userId._id)

        if (isValid) {
            console.log('accepted')

            fetchUpdateWithDrawal()
        } else {
            message.warning('có sai số ở Data, vui lòng liên hệ Dev để xử lý')
        }

        setAnchorEl(!anchorEl)
    }

    // từ chối rút
    const handleOpenBoxRejectWithDrawal = (row, e) => {
        setRejectAnchorEl(e.currentTarget)
        setCurrentRow(row)
    }

    const handleCloseBoxRejectWithDrawal = () => {
        setRejectAnchorEl(false)
    }

    const handleConfirmBoxReject = (note) => {
        fetchUpdateWithDrawal(note)
        setRejectAnchorEl(false)
    }

    // create class
    const getRowClassName = (params) => {
        switch (params.row.status) {
            case 0:
                return 'pending-row'
            case 1:
                return 'completed-row'
            case 2:
                return 'cancelled-row'
            default:
                return ''
        }
    }

    return (
        <Box
            m='20px'
            sx={{
                '& .MuiTypography-root.MuiTypography-h2': {
                    margin: '0 0 5px 0',
                },
            }}
        >
            {messageContextHolder}
            <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Header title='QUẢN LÝ PARTNER RÚT TIỀN' subtitle='Trang quản lý rút tiền' />
            </Box>
            <Box
                height='75vh'
                m='40px 0 0 0'
                sx={{
                    ...StyleDataGrid(),
                    '& .MuiDataGrid-cell': {
                        borderBottom: 'none',
                        display: 'flex',
                        alignItems: 'center',
                    },
                    '& .name-column--cell--withdraw': {
                        color: colors.redAccent[400],
                    },
                    '& .name-column--cell': {
                        fontWeight: 'bold',
                    },
                }}
            >
                <DataGrid
                    getRowHeight={() => 'auto'}
                    sx={{
                        '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                            py: 1,
                        },
                        '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                            py: '15px',
                        },
                        '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
                            py: '22px',
                        },
                    }}
                    getRowId={(row) => row._id}
                    rows={data}
                    columns={columns}
                    //pagination server side
                    rowCount={totalRows}
                    initialState={{
                        pagination: { paginationModel },
                        density: 'comfortable',
                    }}
                    pagination
                    pageSizeOptions={[10, 25, 50]}
                    paginationMode='server'
                    onPaginationModelChange={setPaginationModel}
                    //pagination server side

                    // sort server
                    sortingMode='server'
                    onSortModelChange={handleSortModelChange}
                    loading={loading}
                    slots={{
                        toolbar: GridToolbar,
                        noRowsOverlay: CustomNoRowsOverlay,
                    }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: false,
                            quickFilterProps: {
                                onChange: handleInputChange,
                                value: inputText,
                                placeholder: 'search số tài khoản',
                            },
                        },
                    }}
                    getRowClassName={getRowClassName}
                    localeText={{
                        //custom text density (thanh toolbar)
                        toolbarDensity: 'Size',
                        toolbarDensityLabel: 'Size',
                        toolbarDensityCompact: 'Small',
                        toolbarDensityStandard: 'Medium',
                        toolbarDensityComfortable: 'Large',
                    }}
                />
            </Box>

            <PopoverComponent
                type='accept'
                idRow={idRow}
                anchorEl={anchorEl}
                onClose={handleCloseBoxAcceptWithDraw}
                handleConfirm={handleConfirm}
                boxName='withDrawal'
            />

            <PopoverComponent
                type='cancel'
                idRow={currentRow?._id}
                anchorEl={rejectAnchorEl}
                onClose={handleCloseBoxRejectWithDrawal}
                handleConfirm={handleConfirmBoxReject}
                boxName='withDrawal'
            />
        </Box>
    )
}

export default WithDraw
