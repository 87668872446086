import { useState, useEffect, useCallback } from 'react'
import {
    Box,
    Typography,
    useTheme,
    IconButton,
    Tooltip,
    Modal,
    TextField,
    Button,
    InputAdornment,
    Popover,
    FormControl,
    FormHelperText,
    Select,
    MenuItem,
    Autocomplete,
    debounce,
} from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { tokens } from '../../../theme'
import Header from '../../../components/Header'
import StyleDataGrid from '../../../assets/styles/styleDataGrid'
import request from '../../../utils/api/request'
import CustomNoRowsOverlay from '../../../components/noRows'
import CustomLoadingComponent from '../../../components/loading'
import { getRowClassName } from '../../../components/prefixData'
import { AccountCircle, Cancel, Close, Delete, Edit, Send } from '@mui/icons-material'
import { delete_user_bank_account, get_all_user_bank_account, update_user_bank_account } from '../../../utils/api/apiList'
import { Formik } from 'formik'
import * as yup from 'yup'
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined'
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined'
import openMessageNotification from '../../../components/notification/message'
import { message } from 'antd'
import axios from 'axios'
import ModalCustom from '../../../components/modal/modal'
import TextFieldCustom from '../../../components/form/textfield'

const userSchema = yup.object().shape({
    bankAccountNumber: yup.number().required('Yêu cầu nhập số tài khoản'),
    bankAccountName: yup.string().required('Yêu cầu nhập tên ngân hàng'),
    userAccountName: yup.string().required('Yêu cầu nhập tên chủ tài khoản'),
})

function StatisticsBankPartner() {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const [pageSize, setPageSize] = useState(10)
    const [dataBankUsers, setDataBankUsers] = useState([])
    const [banks, setBanks] = useState([])
    const [loading, setLoading] = useState(true)

    const [currentRow, setCurrentRow] = useState(null)
    const [anchorElDelete, setAnchorElDelete] = useState(null)
    const [anchorElEdit, setAnchorElEdit] = useState(null)
    const [initialFormValues, setInitialFormValues] = useState({
        bankAccountNumber: null,
        bankAccountName: '',
        userAccountName: null,
    })

    // pagination server side
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 25,
    })
    const [totalRows, setTotalRows] = useState(0)
    //search server side
    const [searchTerm, setSearchTerm] = useState('')
    const [inputText, setInputText] = useState('')

    //message
    const [messageApi, messageContextHolder] = message.useMessage()

    // Debounced
    const debouncedSetSearchTerm = useCallback(
        debounce((value) => {
            setSearchTerm(value)
        }, 700),
        []
    )

    // handle search
    const handleInputChange = (e) => {
        const value = e.target.value
        setInputText(value)
        debouncedSetSearchTerm(value)
    }

    useEffect(() => {
        fetchData()
        fetchBanks()
    }, [paginationModel, searchTerm])

    //fetch data
    const fetchData = () => {
        request
            .post(get_all_user_bank_account, { page: paginationModel.page + 1, limit: paginationModel.pageSize, search: searchTerm })
            .then((res) => {
                const dataRs = res.data.data.docs
                if (dataRs.length > 0) {
                    const dataConvert = dataRs.map((elm, index) => {
                        return {
                            ...elm,
                            index: index + 1 + paginationModel.page * paginationModel.pageSize,
                            fullName: elm.userId.fullName,
                            phoneNumber: elm.userId.phoneNumber,
                            balance: elm.userId.balance,
                            avatar: elm.userId.avatar,
                        }
                    })
                    setDataBankUsers(dataConvert)
                    setLoading(false)
                    setTotalRows(res.data.data.total)
                }
            })
            .catch((err) => {
                setLoading(false)
                console.log(err)
            })
    }

    //fetch data bank VN
    const fetchBanks = async () => {
        try {
            const response = await axios.get('https://api.vietqr.io/v2/banks')
            if (response.data.code === '00') {
                setBanks(response.data.data)
                console.log(response.data.data)
            } else {
                console.error(response)
            }
        } catch (err) {
            console.error('error: ', err)
        }
    }

    //column
    const columns = [
        { field: 'index', headerName: 'STT', width: 80 },
        {
            field: 'fullName',
            headerName: 'Tên nhân viên',
            cellClassName: 'cost-column name-column--cell',
            minWidth: 150,
            flex: 0.3,
        },
        {
            field: 'phoneNumber',
            headerName: 'Số điện thoại',
            minWidth: 150,
            flex: 0.3,
        },
        {
            field: 'userAccountName',
            headerName: 'Chủ tài khoản',
            cellClassName: 'cost-column name-column--cell',
            minWidth: 150,
            flex: 0.3,
        },
        {
            field: 'bankAccountName',
            headerName: 'Ngân hàng',
            minWidth: 150,
            flex: 0.3,
        },
        {
            field: 'bankAccountNumber',
            headerName: 'Số tài khoản',
            minWidth: 150,
            flex: 0.3,
        },

        {
            field: 'action',
            headerName: 'Actions',
            cellClassName: 'action-column',
            width: 100,
            renderCell: ({ row }) => {
                return (
                    <Box display='flex' alignItems='center' justifyContent='center' gap={1}>
                        <Tooltip
                            title='Chỉnh sửa'
                            slotProps={{
                                popper: {
                                    modifiers: [
                                        {
                                            name: 'offset',
                                            options: {
                                                offset: [0, -10],
                                            },
                                        },
                                    ],
                                },
                            }}
                        >
                            <IconButton color='warning' aria-label='edit' onClick={(e) => handleOpenEdit(e, row)}>
                                <Edit />
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            title='Xoá'
                            slotProps={{
                                popper: {
                                    modifiers: [
                                        {
                                            name: 'offset',
                                            options: {
                                                offset: [0, -10],
                                            },
                                        },
                                    ],
                                },
                            }}
                        >
                            <IconButton onClick={(e) => handleOpenDelete(e, row)}>
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )
            },
        },
    ]

    //delete
    const handleOpenDelete = (e, row) => {
        setAnchorElDelete(e.currentTarget)
        setCurrentRow(row)
    }
    const handleCloseDelete = () => {
        setAnchorElDelete(false)
    }
    const handleDelete = () => {
        request
            .post(delete_user_bank_account, { _id: currentRow?._id })
            .then((res) => {
                openMessageNotification(messageApi, 'success', 'deleteBankPartner', currentRow?.userId.fullName)
                fetchData()
                console.log(res)
            })
            .catch((err) => {
                console.log(err)
                openMessageNotification(messageApi, 'error', 'deleteBankPartner', currentRow?.userId.fullName)
            })

        handleCloseDelete()
    }

    //edit
    const handleOpenEdit = (e, row) => {
        console.log(row)

        setAnchorElEdit(e.currentTarget)
        setInitialFormValues(row)
    }
    const handleCloseEdit = () => {
        setAnchorElEdit(false)
        setInitialFormValues({
            bankAccountNumber: null,
            bankAccountName: null,
            userAccountName: null,
        })
    }
    const handleEdit = (values) => {
        // console.log(values)
        const dataUpdate = {
            bankAccountName: values.bankAccountName,
            bankAccountNumber: values.bankAccountNumber,
            userAccountName: values.userAccountName,
            _id: values._id,
            userId: values.userId._id,
        }
        const namePartner = values.userId.fullName
        // console.log(dataUpdate)

        request
            .post(update_user_bank_account, { ...dataUpdate })
            .then((res) => {
                console.log(res)
                openMessageNotification(messageApi, 'success', 'editBankPartner', namePartner)
                fetchData()
            })
            .catch((err) => {
                console.log(err)
                openMessageNotification(messageApi, 'error', 'editBankPartner', namePartner)
            })

        handleCloseEdit()
    }

    //pagination
    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize)
    }

    //style
    const styleBox = {
        '& .box.MuiBox-root': {
            display: 'flex',
            alignItems: 'center',
            gap: '20px',
            width: '80%',
            '& .MuiTextField-root': {
                flex: 1,
            },
        },
        '& .MuiTypography-root': {
            minWidth: '120px',
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px',
        maxHeight: '700px',
        overflowY: 'auto',
        boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
        pb: '20px',
        pt: '40px',
        backgroundColor: theme.palette.mode === 'dark' ? colors.primary[600] : '#e7e7e7',
        borderRadius: '5px',
        '& .Mui-focused fieldset': {
            borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.6) !important' : 'rgba(0, 0, 0, 0.8)', // Màu border khi focused
            borderWidth: theme.palette.mode === 'dark' ? '2px' : '1px !important',
        },
    }

    const styleButton = {
        bgcolor: '#d40000',
        color: 'white',
        fontWeight: '600',
        mr: '20px',
        '&:hover': {
            bgcolor: 'red',
        },
    }

    return (
        <Box
            m='20px'
            sx={{
                '& .MuiTypography-root.MuiTypography-h2': {
                    margin: '0 0 5px 0',
                },
            }}
        >
            {messageContextHolder}
            <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Header title='THỐNG KÊ TÀI KHOẢN NGÂN HÀNG NGƯỜI LAO ĐỘNG' subtitle='Trang thống kê ngân hàng' />
            </Box>
            <Box height='75vh' m='40px 0 0 0' sx={StyleDataGrid()}>
                <DataGrid
                    getRowId={(row) => row._id}
                    rows={dataBankUsers}
                    columns={columns}
                    rowCount={totalRows}
                    //pagination server side
                    pagination
                    initialState={{
                        pagination: { paginationModel },
                        density: 'comfortable',
                    }}
                    pageSizeOptions={[10, 25, 50, 100]}
                    paginationMode='server'
                    onPaginationModelChange={setPaginationModel}
                    //pagination server side
                    loading={loading}
                    slots={{
                        toolbar: GridToolbar,
                        noRowsOverlay: CustomNoRowsOverlay,
                        loadingOverlay: CustomLoadingComponent,
                    }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: {
                                onChange: handleInputChange,
                                value: inputText,
                                placeholder: 'tìm kiếm theo stk, tên tk',
                            },
                        },
                    }}
                    onPageSizeChange={handlePageSizeChange}
                    getRowClassName={getRowClassName}
                />
            </Box>
            <ModalCustom
                open={Boolean(anchorElEdit)}
                onClose={handleCloseEdit}
                style={{ backdropFilter: 'blur(5px)', zIndex: '99' }}
                sx={{
                    border: '1px solid rgba(0, 0, 0, 0.1) 0px 4px 12px',
                    p: theme.palette.mode === 'dark' ? 2 : 4,
                }}
            >

                <Box
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                    sx={{
                        pl: theme.palette.mode === 'dark' ? '50px' : '0',
                        pt: theme.palette.mode === 'dark' ? '20px' : '5px',
                        pb: theme.palette.mode === 'dark' ? 'unset' : '5px',
                    }}
                >
                    <Typography variant='h4' color={colors.grey[300]}>
                        SỬA THÔNG TIN NGÂN HÀNG NGƯỜI LAO ĐỘNG
                    </Typography>
                </Box>
                <IconButton
                    sx={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                    }}
                    onClick={handleCloseEdit}
                >
                    <Close color='disabled' />
                </IconButton>
                <Formik initialValues={initialFormValues} validationSchema={userSchema} onSubmit={handleEdit}>
                    {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                        <Box component='form' onSubmit={handleSubmit} sx={styleBox}>
                            <TextFieldCustom
                                label='Tên chủ tài khoản'
                                value={values.userAccountName || ''}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                name='userAccountName'
                                error={!!touched.userAccountName && !!errors.userAccountName}
                                helperText={touched.userAccountName && errors.userAccountName}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position='start'>
                                            <AccountCircle />
                                        </InputAdornment>
                                    ),
                                }}
                                className='box'
                            />
                            <Box
                                className='box'
                                sx={{
                                    '& .Mui-focused': {
                                        color: `${colors.grey[100]} !important`,
                                    },
                                    display: 'flex',
                                    flexDirection: { xs: 'column', sm: 'row' },
                                    gap: { xs: '10px!important', sm: '20px!important' },
                                    alignItems: { xs: 'flex-start!important', sm: 'center!important' },
                                    width: { xs: '100%!important', sm: '80%!important' },
                                }}
                            >
                                <Typography>Tên ngân hàng: </Typography>
                                <Autocomplete
                                    freeSolo
                                    options={banks}
                                    value={values.bankAccountName || ''}
                                    getOptionLabel={(option) => (typeof option === 'string' ? option : option.shortName)}
                                    isOptionEqualToValue={(option, value) =>
                                        typeof value === 'string' ? option.shortName === value : option.shortName === value.shortName
                                    }
                                    onChange={(event, value) => {
                                        if (typeof value === 'string') {
                                            setFieldValue('bankAccountName', value)
                                        } else if (value && value.shortName) {
                                            setFieldValue('bankAccountName', value.shortName)
                                        } else {
                                            setFieldValue('bankAccountName', '')
                                        }
                                    }}
                                    onInputChange={(event, value) => {
                                        setFieldValue('bankAccountName', value)
                                    }}
                                    onBlur={handleBlur}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            name='bankAccountName'
                                            label='Chọn ngân hàng'
                                            error={!!touched.bankAccountName && !!errors.bankAccountName}
                                            helperText={touched.bankAccountName && errors.bankAccountName}
                                            InputProps={{
                                                ...params.InputProps,
                                                startAdornment: (
                                                    <InputAdornment position='start'>
                                                        <AccountBalanceOutlinedIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                    renderOption={(props, option) => (
                                        <li {...props} key={option.id || option}>
                                            {typeof option === 'string' ? option : `${option.shortName} - ${option.name}`}
                                        </li>
                                    )}
                                    fullWidth
                                />
                            </Box>
                            <TextFieldCustom
                                label='Số tài khoản'
                                value={values.bankAccountNumber || ''}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                name='bankAccountNumber'
                                error={!!touched.bankAccountNumber && !!errors.bankAccountNumber}
                                helperText={touched.bankAccountNumber && errors.bankAccountNumber}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position='start'>
                                            <CreditCardOutlinedIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                className='box'
                                widthField='80%'
                            />
                            <Box justifyContent='flex-end' display='flex' mt='20px' sx={{ width: { xs: '100% !important', sm: `80%!important` } }}>
                                <Button type='submit' sx={{ mr: '20px' }} variant='contained' color='success' endIcon={<Send />}>
                                    Gửi
                                </Button>
                                <Button variant='outlined' color='inherit' startIcon={<Cancel />} onClick={handleCloseEdit}>
                                    Huỷ
                                </Button>
                            </Box>
                        </Box>
                    )}
                </Formik>

            </ModalCustom>
            <Popover
                id={currentRow?._id}
                open={Boolean(anchorElDelete)}
                anchorEl={anchorElDelete}
                onClose={handleCloseDelete}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                sx={{
                    '& .Mui-focused fieldset': {
                        borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.23) !important' : 'rgba(0, 0, 0, 0.23) !important',
                        borderWidth: '1px !important',
                    },
                    '& .MuiFormLabel-root.Mui-focused': {
                        color: colors.grey[100],
                    },
                }}
            >
                <Box p={2}>
                    <Typography>Bạn muốn xoá tài khoản này?</Typography>
                    <Box
                        sx={{
                            mt: '10px',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            flexDirection: 'column',
                        }}
                    >
                        <Box display='flex' justifyContent='flex-end'>
                            <Button
                                size='small'
                                variant='contained'
                                onClick={handleDelete}
                                sx={{
                                    marginRight: '10px',
                                    fontWeight: 600,
                                    color: colors.grey[100],
                                    ...styleButton,
                                }}
                            >
                                OK
                            </Button>
                            <Button
                                size='small'
                                onClick={handleCloseDelete}
                                variant='outlined'
                                sx={{
                                    bgcolor: 'white',
                                    '&:hover': {
                                        bgcolor: '#e8e8e8c4',
                                    },
                                }}
                            >
                                Huỷ
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Popover>
        </Box>
    )
}

export default StatisticsBankPartner
