// admin: get company
export const get_all_company = 'companies/get_companies'
export const create_company = 'companies/create_company'
export const delete_company = 'companies/delete_company'
export const update_company = 'companies/update_company'
export const get_list_user = 'users/get_list_user'
export const get_company_by_id = 'companies/get_company_by_id'
export const get_companies_admin = 'companies/get_companies_admin'

//admin: package
export const create_package = 'packages/create_package'
export const update_package = 'packages/update_package'
export const get_list_package = 'packages/get_list_package'
export const delete_package = 'packages/delete_package'

// admin: get service
export const create_service = 'services/create_service'
export const get_services = 'services/get_services'
export const delete_service = 'services/delete_service'
export const update_service = 'services/update_service'

// company: get all task
export const get_taskbookings = 'taskbookings/get_taskbookings'
export const get_taskbookings2 = 'taskbookings/get_taskbookings2'
export const get_permanent_taskbookings = 'permanenttaskbookings/get_permanent_taskbookings'
export const get_permanent_taskbookings2 = 'permanenttaskbookings/get_permanent_taskbookings2'
export const get_clean_bookings = 'cleanbookings/get_clean_bookings'
export const get_clean_bookings2 = 'cleanbookings/get_clean_bookings2'
export const get_all_airconditionbookings = 'airconditionbookings/get_all_airconditionbookings'
export const get_all_sofabookings = 'sofabookings/get_all_sofabookings'
export const get_all_spraybookings = 'spraybookings/get_all_spraybookings'

//admin tạo đơn hàng
export const create_task_by_admin = 'users/create_task_by_admin'
export const create_clean_by_admin = 'users/create_clean_by_admin'
export const create_permanent_task_by_admin = 'users/create_permanent_task_by_admin'
export const create_airconditionbooking = 'airconditionbookings/create_airconditionbooking'
export const create_sofabooking = 'sofabookings/create_sofabooking'
export const create_spraybooking = 'spraybookings/create_spraybooking'

//edit đơn hàng
export const admin_update_clean = 'users/admin_update_clean'
export const admin_update_taskBooking = 'users/admin_update_taskbooking'
export const admin_update_permanent_taskbooking = 'users/admin_update_permanent_taskbooking'
export const update_airconditionbooking = 'airconditionbookings/update_airconditionbooking'
export const update_sofabooking = 'sofabookings/update_sofabooking'
export const update_spraybooking = 'spraybookings/update_spraybooking'

// Company: get partner cho ca cố định từ ca lẻ
export const get_taskbooking_from_permanent_taskbookings = 'permanenttaskbookings/get_taskbooking_from_permanent_taskbookings'

//huỷ các loại đơn hàng
export const admin_cancel_task = 'users/admin_cancel_task'
export const admin_cancel_clean = 'users/admin_cancel_clean'
export const admin_cancel_spraybooking = 'spraybookings/admin_cancel_spraybooking'
export const admin_cancel_sofabooking = 'sofabookings/admin_cancel_sofabooking'
export const admin_cancel_airconditionbooking = 'airconditionbookings/admin_cancel_airconditionbooking'

// Xoá các loại đơn hàng
export const delete_permanent_taskbooking_by_admin = 'users/delete_permanent_taskbooking_by_admin'
export const delete_task_booking_by_admin = 'users/delete_task_booking_by_admin'
export const delete_clean_booking_by_admin = 'users/delete_clean_booking_by_admin'
export const delete_sofabooking = 'sofabookings/delete_sofabooking'
export const delete_airconditionbooking = 'airconditionbookings/delete_airconditionbooking'
export const delete_spraybooking = 'spraybookings/delete_spraybooking'

//company: tìm kiếm nhân viên đang rãnh theo khung giờ của task hiện tại
export const find_tasker_doing_task = 'taskbookings/find_tasker_doing_task' //clean va booking dung chung
export const find_tasker_doing_permanent_task = 'permanenttaskbookings/find_tasker_doing_permanent_task'
export const find_tasker_doing = 'airconditionbookings/find_tasker_doing' //airCoindtion
export const find_tasker_doing_clean = 'cleanbookings/find_tasker_doing_clean' // tổng vệ sinh
export const find_tasker_doing_sofa_booking = 'sofabookings/find_tasker_doing_sofa_booking'
export const find_tasker_doing_spray_booking = 'spraybookings/find_tasker_doing_spray_booking'

//company: gán người lao động
export const add_tasker_for_taskbooking = 'taskbookings/add_tasker_for_taskbooking'
export const add_tasker_for_clean_booking = 'cleanbookings/add_tasker_for_clean_booking'
export const add_tasker_for_permanent_taskbooking = 'permanenttaskbookings/add_tasker_for_permanent_taskbooking'
export const add_tasker_for_airconditionbooking = 'airconditionbookings/add_tasker_for_airconditionbooking'
export const add_tasker_for_sofabooking = 'sofabookings/add_tasker_for_sofabooking'
export const add_tasker_for_spraybooking = 'spraybookings/add_tasker_for_spraybooking'

//company: get thông tin đơn theo id và info user
export const get_taskbooking_by_id = 'taskbookings/get_taskbooking_by_id'
export const get_permanent_taskbooking_by_id = 'permanenttaskbookings/get_permanent_taskbooking_by_id'
export const get_cleanbooking_by_id = 'cleanbookings/get_cleanbooking_by_id'
export const get_airconditionbooking_by_id = 'airconditionbookings/get_airconditionbooking_by_id'
export const get_sofabooking_by_id = 'sofabookings/get_sofabooking_by_id'
export const get_spraybooking_by_id = 'spraybookings/get_spraybooking_by_id'

//company: get transaction
export const get_all_payment = 'payments/get_all_payment'
export const get_payment_by_id = 'payments/get_payment_by_id'

//company: partner rút tiền
export const get_all_withdrawal_request = 'withdrawals/get_all_withdrawal_request'
export const update_withdrawal_request = 'withdrawals/update_withdrawal_request'

//Partner
export const get_company_partner = 'companies/get_company_partner'
export const partner_update_freetime = 'users/partner_update_freetime'

//Verify partner
export const update_partner_info_from_company = 'users/update_partner_info_from_company'

//setting page
export const create_city_setting = 'citysettings/create_city_setting'
export const update_city_setting = 'citysettings/update_city_setting'
export const get_city_setting_by_id = 'citysettings/get_city_setting_by_id'
export const get_city_settings = 'citysettings/get_city_settings'
export const create_level_discount = 'citysettings/create_level_discount'

//create new setting
export const create_new_city_setting = 'citysettings/create_new_city_setting'
export const delete_city_setting = 'citysettings/delete_city_setting'
export const create_task_booking_price = 'citysettings/create_task_booking_price'
export const create_permanent_task_booking_price = 'citysettings/create_permanent_task_booking_price'
export const create_clean_booking_price = 'citysettings/create_clean_booking_price'

// fill price
export const get_price_for_taskbooking = 'taskbookings/get_price_for_taskbooking'
export const get_permanent_taskbooking_price = 'permanenttaskbookings/get_permanent_taskbooking_price'
export const get_cleanbookings_price = 'cleanbookings/get_cleanbookings_price'

//policy
export const get_all_policy = 'users/get_all_policy'
export const create_policy = 'users/create_policy'
export const update_policy = 'users/update_policy'
export const delete_policy = 'policy/delete_policy'

// Rating
export const get_ratings = 'ratings/get_ratings'
export const get_task_booking_rating_by_id = 'ratings/get_task_booking_rating_by_id'
export const get_clean_booking_rating_by_id = 'ratings/get_clean_booking_rating_by_id'
export const get_aircondition_booking_by_id_and_user_id = 'ratings/get_aircondition_booking_by_id_and_user_id'
export const get_sofa_booking_by_id_and_user_id = 'ratings/get_sofa_booking_by_id_and_user_id'
export const get_spray_booking_by_id_and_user_id = 'ratings/get_spray_booking_by_id_and_user_id'

//update giấy tờ partner
export const upload_info_identify_pic = 'users/upload_info_identify_pic'
export const update_partner_info_image = 'users/update_partner_info_image'

//signup account company
export const signup_company_account_from_admin = 'users/signup_company_account_from_admin'

//get/update data user profile admin Company
export const get_user_profile = 'users/get_user_profile'
export const update_profile = 'users/update_profile'

// tạo mới, sửa, xoa thông tin user từ admin
export const user_change_password = 'users/user_change_password'
export const update_user_info_from_admin = 'users/update_user_info_from_admin'
export const signup_with_phone = 'users/signup_with_phone'
export const delete_user_from_admin = 'users/delete_user_from_admin'

//upload avarta admin company
export const upload_avatar = 'users/upload_avatar'

//Thống kê tài khoản ngân hàng người lao động
export const get_all_user_bank_account = 'userbankaccounts/get_all_user_bank_account'
export const update_user_bank_account = 'userbankaccounts/update_user_bank_account'
export const get_user_bank_account_by_id = 'userbankaccounts/get_user_bank_account_by_id'
export const delete_user_bank_account = 'userbankaccounts/delete_user_bank_account'

// Partner deposit
export const get_all_deposit = 'deposits/get_all_deposit'

//signin by phone
export const admin_signin_by_phone = 'users/admin_signin_by_phone'

//check phone đã đăng ký
export const is_company_phone_number = 'users/is_company_phone_number'

//payment history
export const get_all_historypayment = 'historypayments/get_all_historypayment'
export const get_historypayment_by_id = 'historypayments/get_historypayment_by_id'
export const get_historypayment_by_userid = 'historypayments/get_historypayment_by_userid'

//discount
export const create_discount = 'discounts/create_discount'
export const update_discount = 'discounts/update_discount'
export const delete_discount = 'discounts/delete_discount'
export const get_all_discount = 'discounts/get_all_discount'
export const get_discount_by_code = 'discounts/get_discount_by_code'
export const get_discount_price = 'discounts/get_discount_price'

//banner
export const create_banner = 'banners/create_banner'
export const get_list_banner = 'banners/get_list_banner'
export const update_banner = 'banners/update_banner'
export const delete_banner = 'banners/delete_banner'

//check withdrawal
export const check_withdrawal_request = 'withdrawals/check_withdrawal_request'

//accept payment permanentTask
export const payment_from_admin = 'permanenttaskbookings/payment_from_admin'

//setting Air condition
export const get_all_airconditiondetails_for_city = 'airconditiondetails/get_all_airconditiondetails_for_city'
export const admin_get_all_airconditiondetails_for_city = 'airconditiondetails/admin_get_all_airconditiondetails_for_city'
export const create_airconditiondetail = 'airconditiondetails/create_airconditiondetail'
export const update_airconditiondetail = 'airconditiondetails/update_airconditiondetail'
export const delete_airconditiondetail = 'airconditiondetails/delete_airconditiondetail'

//setting sofa
export const create_sofadetail = 'sofadetails/create_sofadetail'
export const update_sofadetail = 'sofadetails/update_sofadetail'
export const delete_sofadetail = 'sofadetails/delete_sofadetail'
export const get_sofadetail_by_id = 'sofadetails/get_sofadetail_by_id'
export const get_all_sofadetails_for_city = 'sofadetails/get_all_sofadetails_for_city'
export const admin_get_all_sofadetails_for_city = 'sofadetails/admin_get_all_sofadetails_for_city'

//setting Spray
export const create_spraydetail = 'spraydetails/create_spraydetail'
export const update_spraydetail = 'spraydetails/update_spraydetail'
export const delete_spraydetail = 'spraydetails/delete_spraydetail'
export const get_spraydetail_by_id = 'spraydetails/get_spraydetail_by_id'
export const get_all_spraydetails_for_city = 'spraydetails/get_all_spraydetails_for_city'
export const admin_get_all_spraydetails_for_city = 'spraydetails/admin_get_all_spraydetails_for_city'

//api show ngân hàng
export const getDataBanks = 'https://api.vietqr.io/v2/banks'

//api tạo phí hoa hồng (admin - doanhnghiep)
export const create_app_discount = 'citysettings/create_app_discount'

//Update partner balance
export const update_partner_balance_from_company = 'users/update_partner_balance_from_company'

//create partner
export const partner_signup = 'users/partner_signup'

// xoá ảnh info partner
export const delete_partner_idimages = 'users/delete_partner_idimages'

//analyzes /////////////////////////////////////
// thống kê khách hàng theo năm
export const total_user_by_month_by_year = 'analyzes/total_user_by_month_by_year'
//thống kê khách hàng theo tháng
export const total_user_by_date_by_month = 'analyzes/total_user_by_date_by_month'

//thống kê partner theo năm
export const total_partner_by_month_by_year_admin = 'analyzes/total_partner_by_month_by_year_admin'
//thống kê partner theo tháng
export const total_partner_by_date_by_month_admin = 'analyzes/total_partner_by_date_by_month_admin'

//thông kê doanh thu công ty theo năm (admin)
export const total_company_revenue_by_month_by_year = 'analyzes/total_company_revenue_by_month_by_year'
//thống kê doanh thu công ty từ ngày tới ngày
export const total_company_revenue_from_date_to_date_admin = 'analyzes/total_company_revenue_from_date_to_date_admin'

//thống kê lợi nhuận công ty
export const total_company_profit_by_month_by_year = 'analyzes/total_company_profit_by_month_by_year'
//thống kê lợi nhuận công ty từ ngày tới ngày
export const total_company_profit_from_date_to_date_admin = 'analyzes/total_company_profit_from_date_to_date_admin'

//thống kê doanh thu theo dịch vụ
export const revenue_task_by_month_by_year_admin = 'analyzes/revenue_task_by_month_by_year_admin'
export const revenue_clean_by_month_by_year_admin = 'analyzes/revenue_clean_by_month_by_year_admin'
export const revenue_permanent_task_by_month_by_year_admin = 'analyzes/revenue_permanent_task_by_month_by_year_admin'
export const revenue_sofa_by_month_by_year_admin = 'analyzes/revenue_sofa_by_month_by_year_admin'
export const revenue_air_by_month_by_year_admin = 'analyzes/revenue_air_by_month_by_year_admin'
export const revenue_spray_by_month_by_year_admin = 'analyzes/revenue_spray_by_month_by_year_admin'

// thống kê lợi nhuận theo dịch vụ
export const profit_task_by_month_by_year_admin = 'analyzes/profit_task_by_month_by_year_admin'
export const profit_permanent_task_by_month_by_year_admin = 'analyzes/profit_permanent_task_by_month_by_year_admin'
export const profit_clean_by_month_by_year_admin = 'analyzes/profit_clean_by_month_by_year_admin'
export const profit_sofa_by_month_by_year_admin = 'analyzes/profit_sofa_by_month_by_year_admin'
export const profit_air_by_month_by_year_admin = 'analyzes/profit_air_by_month_by_year_admin'
export const profit_spray_by_month_by_year_admin = 'analyzes/profit_spray_by_month_by_year_admin'

// thống kê nạp cọc từ ngày tới ngày
export const total_deposit_by_companyid_from_date_to_date = 'analyzes/total_deposit_by_companyid_from_date_to_date'

//login
export const login = 'login'
