import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

const taskBookingSlice = createSlice({
    name: 'taskBooking',
    initialState: {
        userInfo: {},
    },
    reducers: {
        getData: (state, action) => {
            state.userInfo = action.payload
        },
    },
})

export default taskBookingSlice
