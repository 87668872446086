import { useState } from 'react'
import { formatDate, formatRange } from '@fullcalendar/core'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import { Box, List, ListItem, ListItemText, Typography, useTheme } from '@mui/material'
import Header from '../../components/Header'
import { tokens } from '../../theme'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'

const Calendar = ({ data, dataRow }) => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const [currentEvents, setCurrentEvents] = useState([])
    const _id_uuid = uuidv4()

    const commonDates = dataRow?.dateRoot?.filter((date) => dataRow?.doneDate.includes(date))
    // console.log(commonDates)

    const formattedDates = commonDates?.map((date) => moment(date).format('YYYY-MM-DD'))

    // Kiểm tra nếu commonDates rỗng
    if (!commonDates || commonDates.length === 0) {
        const highlightedElements = document.querySelectorAll('.highlighted')
        highlightedElements.forEach((element) => {
            element.classList.remove('highlighted')
        })
    } else {
        formattedDates?.forEach((date) => {
            const element = document.querySelector(`[data-date="${date}"]`)
            if (element) {
                element.classList.add('highlighted')
            }
        })
    }

    const handleDateClick = (selected) => {
        const title = prompt('Please enter a new title for your event')
        const calendarApi = selected.view.calendar
        calendarApi.unselect()

        if (title) {
            calendarApi.addEvent({
                id: _id_uuid,
                title,
                start: selected.startStr,
                end: selected.endStr,
                // allDay: selected.allDay,
            })
        }
    }

    const handleEventClick = (selected) => {
        if (window.confirm(`Are you sure you want to delete the event '${selected.event.title}'`)) {
            selected.event.remove()
        }
    }

    return (
        <Box
            m='20px'
            sx={{
                '& .MuiBox-root': { marginBottom: '30px' },
                '& .MuiTypography-root.MuiTypography-h2': {
                    margin: '0 0 5px 0',
                },
                '& .fc-list-day th:first-child': {
                    bgcolor: colors.primary[800],
                },
                '& .fc .fc-list-event:hover td': {
                    bgcolor: 'unset',
                },
                '& .fc-list-day-text': {
                    // color: colors.greenAccent[400],
                    color: 'white',
                },
                '& .fc-event-title': {
                    color: 'white',
                },
                '& .fc-daygrid-dot-event': {
                    // bgcolor: colors.blueAccent[500],
                    bgcolor: '#3788d8',
                },
                '& .fc-daygrid-event-dot': {
                    borderColor: '#52d837',
                },
                '& .MuiListItem-root': {
                    bgcolor: theme.palette.mode === 'dark' ? '#3788d8' : '#3788d8',
                },
                '& .MuiListItemText-root': {
                    color: 'white',
                },
                '& .fc-list-day-side-text': {
                    color: 'white',
                },
            }}
        >
            <Header title='Calendar' subtitle='Full Calendar Interactive Page' />

            <Box display='flex' justifyContent='space-between'>
                {/* CALENDAR SIDEBAR */}
                <Box
                    flex='1 1 20%'
                    backgroundColor={colors.primary[400]}
                    p='15px'
                    borderRadius='4px'
                    // color={colors.grey[100]}
                    sx={{
                        height: '75vh',
                        overflowY: 'auto',
                    }}
                >
                    <Typography variant='h5'>Events</Typography>
                    <List>
                        {currentEvents.map((event) => {
                            // mục đích: lấy sự kiện event.start tức là ngày sự kiện
                            // format ngày sự kiện event.start thành chuỗi ngày để render ra UI
                            return (
                                <ListItem
                                    key={event.id}
                                    sx={{
                                        backgroundColor: colors.greenAccent[500],
                                        margin: '10px 0',
                                        borderRadius: '2px',
                                    }}
                                >
                                    <ListItemText
                                        primary={event.title}
                                        secondary={
                                            <Typography>
                                                {!event.end
                                                    ? formatDate(event.start, {
                                                          year: 'numeric',
                                                          month: 'short',
                                                          day: 'numeric',
                                                          locale: 'vi',
                                                      })
                                                    : formatRange(event.start, event.end, {
                                                          year: 'numeric',
                                                          month: 'short',
                                                          day: 'numeric',
                                                          locale: 'vi',
                                                      })}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                            )
                        })}
                    </List>
                </Box>

                {/* CALENDAR */}
                <Box
                    flex='1 1 100%'
                    ml='15px'
                    sx={{
                        '& .fc-col-header-cell-cushion, .fc-daygrid-day-number': {
                            color: colors.grey[100],
                        },
                        '& .highlighted': { bgcolor: '#80cefb47 !important' },
                        '& .fc-scrollgrid-section-body td .fc-scroller-harness.fc-scroller-harness-liquid .fc-scroller.fc-scroller-liquid-absolute':
                            {
                                overflow: 'unset !important',
                            },
                    }}
                >
                    <FullCalendar
                        height='75vh'
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                        headerToolbar={{
                            left: 'prev,next today',
                            center: 'title',
                            right: 'dayGridMonth,listMonth', //'dayGridMonth,timeGridWeek,timeGridDay,listMonth'
                        }}
                        initialView='dayGridMonth' // chế độ xem ban đầu
                        editable={data ? false : true}
                        selectable={data ? false : true}
                        selectMirror={data ? false : true} // giúp kéo thả (nhấn dữ chuột kéo đến các ngày khác)
                        dayMaxEvents={true} // giúp tạo nút +more khi số lượng note vượt quá chiều dài ô
                        // moreLinkClick={"popover"} // mặc định là "popover" ngoài ra còn có  “week”, “day" chỉ sử dụng khi kèm theo dayMaxEvent
                        select={handleDateClick}
                        eventClick={handleEventClick}
                        eventsSet={(e) => setCurrentEvents(e)}
                        events={data}
                        displayEventTime={false}
                        // slotDuration='02:00' // hiển thị giờ cách nhau 2 tiếng ở tab week
                    />
                </Box>
            </Box>
        </Box>
    )
}

export default Calendar
