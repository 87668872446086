import { Box, Button, Divider, InputAdornment, TextField, Typography, useTheme } from '@mui/material'
import Header from '../../../components/Header'
import { tokens } from '../../../theme'
import { message } from 'antd'
import { Add, Facebook, Home, Phone, Save } from '@mui/icons-material'
import EmailIcon from '@mui/icons-material/Email'
import LanguageIcon from '@mui/icons-material/Language'
import { get_all_policy, update_policy } from '../../../utils/api/apiList'
import request from '../../../utils/api/request'
import { useState, useEffect } from 'react'
import { Formik } from 'formik'

function socials() {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const [messageApi, messageContextHolder] = message.useMessage()
    const [socials, setSocials] = useState(null)
    const [contact, setContact] = useState(null)

    const [socialLinks, setSocialLinks] = useState({
        Facebook: '',
        Instagram: '',
    })

    const [contactData, setContactData] = useState({
        Hotline: '',
        Address: '',
        Email: '',
        Website: '',
    })

    // const defaultSocialKeys = ['Facebook', 'Zalo']

    // Thêm mạng xã hội mới
    const addSocialLink = () => {
        setSocialLinks({
            ...socialLinks,
            [`Social-${Object.keys(socialLinks).length + 1}`]: '',
        })
    }

    //fetching
    useEffect(() => {
        fetchData()
    }, [])

    //fetch socials
    const fetchData = () => {
        request
            .post(get_all_policy)
            .then((res) => {
                const data = res.data.data.docs
                const socials = data.find((elm) => elm.stringLink === 'social')
                const contacts = data.find((elm) => elm.stringLink === 'contact')

                if (socials) {
                    setSocialLinks({
                        ...socials.social,
                    })
                }

                if (contacts) {
                    setContactData({
                        ...contacts.contact,
                    })
                }

                console.log('socials', socials.social)
                console.log('contact', contacts.contact)

                setSocials(socials)
                setContact(contacts)
            })
            .catch((error) => {
                console.error('Lỗi get all dữ liệu Policy(socials)', error)
                message.error('Lỗi: không thể cập nhật thông tin mạng xã hội!')
            })
    }

    //handle submit social
    const handleSubmitSocial = (values, { resetForm }) => {
        const data = {
            ...socials,
            social: { ...values },
        }

        console.log('data', data)

        request
            .post(update_policy, { ...data })
            .then((res) => {
                message.success('Cập nhật mạng xã hội thành công!')
                fetchData()
            })
            .catch((error) => {
                console.error('Lỗi update dữ liệu policy!', error)
                message.error('Cập nhật mạng xã hội thất bại!')
            })
    }

    // Xoá mạng xã hội
    const removeSocialLink = (key) => {
        const updatedSocialLinks = { ...socialLinks }
        // Xoá mạng xã hội theo key
        delete updatedSocialLinks[key]

        // Cập nhật lại state
        setSocialLinks(updatedSocialLinks)

        const data = {
            ...socials,
            social: updatedSocialLinks,
        }

        request
            .post(update_policy, { ...data })
            .then((res) => {
                message.success(`Đã xoá mạng xã hội ${key}`)
                fetchData()
            })
            .catch((error) => {
                console.error('Lỗi khi xoá mạng xã hội!', error)
                message.error('Xoá mạng xã hội thất bại!')
            })
    }

    // handle submit contact
    const handleSubmitContact = (values, { resetForm }) => {
        console.log('values contact', values)

        const data = {
            ...contact,
            contact: { ...values },
        }

        console.log('data', data)

        request
            .post(update_policy, { ...data })
            .then((res) => {
                message.success('Cập nhật liên hệ thành công!')
                fetchData()
            })
            .catch((error) => {
                console.error('Lỗi update dữ liệu policy!', error)
                message.error('Cập nhật liên hệ thất bại!')
            })
    }

    return (
        <Box
            m='20px'
            sx={{
                '& .MuiTypography-root.MuiTypography-h2': {
                    margin: '0 0 5px 0',
                },
            }}
        >
            {messageContextHolder}
            <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Header title='CÀI ĐẶT CHUNG' subtitle='Trang cài đặt chung' />
            </Box>
            <Box mt='40px' bgcolor={colors.primary[400]} minHeight='75vh' p='20px' paddingBottom='40px'>
                <Box display='flex'>
                    <Box flex={1}>
                        <Formik enableReinitialize={true} initialValues={contactData} onSubmit={handleSubmitContact}>
                            {({ values, errors, touched, handleChange, handleSubmit }) => (
                                <Box component='form' onSubmit={handleSubmit}>
                                    <Box p='0 24px' display='flex' flexDirection='column' mt='20px'>
                                        <Typography variant='h5'>LIÊN HỆ</Typography>
                                        <TextField
                                            value={values.Hotline}
                                            name='Hotline'
                                            label='Hotline'
                                            onChange={handleChange}
                                            fullWidth
                                            margin='normal'
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position='start'>
                                                        <Phone sx={{ color: '#4CAF50', mr: 2 }} />
                                                    </InputAdornment>
                                                ),
                                                sx: {
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: colors.grey[100],
                                                        borderWidth: '1px',
                                                    },
                                                },
                                            }}
                                            InputLabelProps={{
                                                sx: {
                                                    '&.Mui-focused': {
                                                        color: colors.grey[100],
                                                    },
                                                },
                                            }}
                                            error={!!touched.Hotline && !!errors.Hotline}
                                            helperText={touched.Hotline && errors.Hotline}
                                        />
                                        <TextField
                                            value={values.Address}
                                            name='Address'
                                            label='Address'
                                            onChange={handleChange}
                                            fullWidth
                                            margin='normal'
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position='start'>
                                                        <Home sx={{ color: '#FF5722', mr: 2 }} />
                                                    </InputAdornment>
                                                ),
                                                sx: {
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: colors.grey[100],
                                                        borderWidth: '1px',
                                                    },
                                                },
                                            }}
                                            InputLabelProps={{
                                                sx: {
                                                    '&.Mui-focused': {
                                                        color: colors.grey[100],
                                                    },
                                                },
                                            }}
                                            error={!!touched.Address && !!errors.Address}
                                            helperText={touched.Address && errors.Address}
                                        />
                                        <TextField
                                            value={values.Email}
                                            name='Email'
                                            label='Email'
                                            onChange={handleChange}
                                            fullWidth
                                            margin='normal'
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position='start'>
                                                        <EmailIcon sx={{ color: '#1976D2', mr: 2 }} />
                                                    </InputAdornment>
                                                ),
                                                sx: {
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: colors.grey[100],
                                                        borderWidth: '1px',
                                                    },
                                                },
                                            }}
                                            InputLabelProps={{
                                                sx: {
                                                    '&.Mui-focused': {
                                                        color: colors.grey[100],
                                                    },
                                                },
                                            }}
                                            error={!!touched.Email && !!errors.Email}
                                            helperText={touched.Email && errors.Email}
                                        />
                                        <TextField
                                            value={values.Website}
                                            name='Website'
                                            label='Website'
                                            onChange={handleChange}
                                            fullWidth
                                            margin='normal'
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position='start'>
                                                        <LanguageIcon sx={{ color: '#a5aa13', mr: 2 }} />
                                                    </InputAdornment>
                                                ),
                                                sx: {
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: colors.grey[100],
                                                        borderWidth: '1px',
                                                    },
                                                },
                                            }}
                                            InputLabelProps={{
                                                sx: {
                                                    '&.Mui-focused': {
                                                        color: colors.grey[100],
                                                    },
                                                },
                                            }}
                                            error={!!touched.Website && !!errors.Website}
                                            helperText={touched.Website && errors.Website}
                                        />
                                        <Box display='flex' justifyContent='space-between' mt='10px'>
                                            <Button
                                                sx={{
                                                    background: theme.palette.mode === 'dark' ? '#138567' : '#3da58a',
                                                    fontWeight: '600',
                                                    '&:hover': {
                                                        bgcolor: '#188b6d',
                                                    },
                                                }}
                                                size='large'
                                                type='submit'
                                                variant='contained'
                                                startIcon={<Save />}
                                            >
                                                Lưu
                                            </Button>
                                        </Box>
                                    </Box>
                                </Box>
                            )}
                        </Formik>
                    </Box>
                    <Box flex={1}>
                        <Formik enableReinitialize={true} initialValues={socialLinks} onSubmit={handleSubmitSocial}>
                            {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                                <Box component='form' onSubmit={handleSubmit}>
                                    <Box p='0 24px' display='flex' flexDirection='column' mt='20px'>
                                        <Typography variant='h5'>MẠNG XÃ HỘI</Typography>
                                        {Object.keys(values).map((key, index) => (
                                            <Box key={index} display='flex' alignItems='center'>
                                                <TextField
                                                    label={key}
                                                    name={key}
                                                    value={values[key]}
                                                    onChange={handleChange}
                                                    fullWidth
                                                    margin='normal'
                                                    InputProps={{
                                                        // startAdornment: (
                                                        //     <InputAdornment position='start'>
                                                        //         {key.includes('Facebook') ? (
                                                        //             <Facebook sx={{ color: '#1877F2', mr: 2 }} />
                                                        //         ) : // <BubbleChartIcon sx={{ color: '#9C27B0', mr: 2 }} />
                                                        //         null}
                                                        //     </InputAdornment>
                                                        // ),
                                                        sx: {
                                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                borderColor: colors.grey[100],
                                                                borderWidth: '1px',
                                                            },
                                                        },
                                                    }}
                                                    InputLabelProps={{
                                                        sx: {
                                                            '&.Mui-focused': {
                                                                color: colors.grey[100],
                                                            },
                                                        },
                                                    }}
                                                    error={touched[key] && !!errors[key]}
                                                    helperText={touched[key] && errors[key]}
                                                />

                                                {/* {!defaultSocialKeys.includes(key) && (
                                                    <Button onClick={() => removeSocialLink(key)} variant='outlined' color='error' sx={{ ml: 2 }}>
                                                        Xoá
                                                    </Button>
                                                )} */}
                                                {/* <Button onClick={() => removeSocialLink(key)} variant='outlined' color='error' sx={{ ml: 2 }}>
                                                    Xoá
                                                </Button> */}
                                            </Box>
                                        ))}
                                        <Box display='flex' justifyContent='space-between' mt='10px'>
                                            <Button
                                                sx={{
                                                    background: theme.palette.mode === 'dark' ? '#138567' : '#3da58a',
                                                    fontWeight: '600',
                                                    '&:hover': {
                                                        bgcolor: '#188b6d',
                                                    },
                                                }}
                                                size='large'
                                                type='submit'
                                                variant='contained'
                                                startIcon={<Save />}
                                            >
                                                Lưu
                                            </Button>
                                            {/* <Button
                                                variant='contained'
                                                sx={{
                                                    background: colors.blueAccent[500],
                                                    fontWeight: '600',
                                                    '&:hover': {
                                                        bgcolor: colors.blueAccent[400],
                                                    },
                                                }}
                                                size='large'
                                                startIcon={<Add />}
                                                onClick={addSocialLink}
                                            >
                                                Thêm mạng xã hội
                                            </Button> */}
                                        </Box>
                                    </Box>
                                </Box>
                            )}
                        </Formik>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default socials
