import React from 'react'
import { Modal, Box, useTheme } from '@mui/material'
import { tokens } from '../../theme'


function ModalCustom({
  open,
  onClose,
  children,
  width = 700,
  bgcolor,
  sx = {},
  sxBox = {},
  style = {},
  ...props
}) {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)
  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={sx}
      style={style}
      {...props}
    >
      <Box
        sx={{
          borderRadius: '5px',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: width,
          maxWidth: 'calc(100vw - 40px)',
          bgcolor: bgcolor || theme.palette.mode === 'dark' ? colors.primary[400] : '#e0e0e0',
          boxShadow: 24,
          p: 4,
          ...sxBox
        }}
      >
        {children}
      </Box>
    </Modal>
  );
}

export default ModalCustom
