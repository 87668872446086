import React, { useCallback, useEffect, useState } from 'react'
import { Box, Typography, useTheme, IconButton, Button, debounce, Tooltip, Chip } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { tokens } from '../../theme'
import DeleteIcon from '@mui/icons-material/Delete'
import Header from '../../components/Header'
import StyleDataGrid from '../../assets/styles/styleDataGrid'
import request from '../../utils/api/request'
import { delete_user_from_admin, get_list_user, signup_with_phone, update_user_info_from_admin } from '../../utils/api/apiList'
import CustomLoadingComponent from '../../components/loading'
import moment from 'moment'
import CustomNoRowsOverlay from '../../components/noRows'
import { message } from 'antd'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import * as yup from 'yup'
import { phoneRegExp } from '../../components/prefixData'
// import ModalCreateEditUser from './modalCreateEditUser'
import ModalCreateEditUser from '../../components/modal/modalCreateEditUser'
import { Edit } from '@mui/icons-material'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import BlockIcon from '@mui/icons-material/Block'
import BoxBanDeleteUser from './boxBanDeleteUser'

//validate user
const passRegExp = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/
const validateSchemaCreateUser = yup.object().shape({
    fullName: yup.string().required('Nhập tên!'),
    phoneNumber: yup.string().matches(phoneRegExp, 'Số điện thoại không đúng').required('Nhập số điện thoại!'),
    password: yup
        .string()
        .min(8, 'Mật khẩu tối thiểu là 8 ký tự bao gồm chữ và số')
        .matches(passRegExp, 'Mật khẩu tối thiểu là 8 ký tự bao gồm chữ và số')
        .required('Không được để trống!'),
    confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Mật khẩu mới không khớp!')
        .required('Không được để trống!'),
})
const validateSchemaEditUser = yup.object().shape({
    fullName: yup.string().required('Nhập tên!'),
    phoneNumber: yup.string().matches(phoneRegExp, 'Số điện thoại không đúng').required('Nhập số điện thoại!'),
    newPassword: yup
        .string()
        .min(8, 'Mật khẩu mới tối thiểu là 8 ký tự bao gồm chữ và số')
        .matches(passRegExp, 'Mật khẩu tối thiểu là 8 ký tự bao gồm chữ và số')
        .required('Không được để trống!'),
    confirmNewPassword: yup
        .string()
        .oneOf([yup.ref('newPassword'), null], 'Mật khẩu mới không khớp!')
        .required('Không được để trống!'),
})

const Users = () => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const [dataUsers, setDataUsers] = useState([])
    const [anchorElDelete, setAnchorElDelete] = useState(null)
    const [loading, setLoading] = useState(true)
    const [loadingCreateUser, setLoadingCreateUser] = useState(null)
    const [anchorElCreateUser, setAnchorElCreateUser] = useState(null)

    const [currentRow, setCurrentRow] = useState({})
    const [loadingEdit, setLoadingEdit] = useState(null)
    const [anchorElEdit, setAnchorElEdit] = useState(null)

    const [anchorElBanUser, setAnchorElBanUser] = useState(null)
    const [anchorElUnlock, setAnchorElUnlock] = useState(null)

    //companyId
    const companyId = localStorage.getItem('companyId')

    //initial values create user
    const [intitalValueUser, setInitialValueUser] = useState({
        fullName: '',
        phoneNumber: '',
        password: '',
        confirmPassword: '',
        // companyId,
    })

    //initial values edit user
    const [intitalValueEditUser, setInitialValueEditUser] = useState({
        fullName: '',
        phoneNumber: '',
        newPassword: '',
        confirmNewPassword: '',
        // companyId,
    })

    // pagination server side
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 25,
    })
    const [totalRows, setTotalRows] = useState(0)
    //search server side
    const [searchTerm, setSearchTerm] = useState('')
    const [inputText, setInputText] = useState('')
    //sort server
    const [queryOptions, setQueryOptions] = useState({ registerDate: -1 })

    // Debounced
    const debouncedSetSearchTerm = useCallback(
        debounce((value) => {
            setSearchTerm(value)
        }, 700),
        []
    )

    // handle search
    const handleInputChange = (e) => {
        const value = e.target.value
        setInputText(value)
        debouncedSetSearchTerm(value)
    }

    // sort server
    const handleSortModelChange = useCallback((sortModel) => {
        if (sortModel.length > 0) {
            const sortField = sortModel[0].field
            const sortOrder = sortModel[0].sort === 'asc' ? 1 : -1
            setQueryOptions({ [sortField]: sortOrder })
        } else {
            setQueryOptions({ registerDate: -1 })
        }
    }, [])

    //fetchData
    const fetchData = () => {
        request
            .post(get_list_user, { page: paginationModel.page + 1, limit: paginationModel.pageSize, search: searchTerm, sort: queryOptions })
            .then((res) => {
                const modifiedData = res.data.data.docs.map((item, index) => ({
                    ...item,
                    index: index + 1 + paginationModel.page * paginationModel.pageSize,
                }))
                setDataUsers(modifiedData)
                setLoading(false)
                setTotalRows(res.data.data.total)
            })
            .catch((err) => console.log(err))
    }

    //fetchCreate user
    const fetchCreateUser = (data, resetForm, setFieldError) => {
        request
            .post(signup_with_phone, { ...data })
            .then((res) => {
                message.success('Thêm mới khách hàng thành công!')
                console.log('user app', res)
                fetchData()
                setAnchorElCreateUser(null)
                resetForm()
            })
            .catch((err) => {
                const logErr = err.response.data.data
                if (logErr === 'PhoneInUseInUserApp') {
                    message.error('Lỗi: số điện thoại đã được đăng ký!')
                    setFieldError('phoneNumber', 'Số điện thoại đã được đăng ký!')
                } else {
                    message.error('Lỗi: thêm mới khách hàng thất bại!')
                    console.error('Lỗi: thêm mới khách hàng thất bại', err)
                    setAnchorElCreateUser(null)
                    resetForm()
                }
            })
            .finally(() => {
                setLoadingCreateUser(false)
            })
    }

    //fetch edit user
    const fetchEditUser = (data, resetForm, setFieldError) => {
        request
            .post(update_user_info_from_admin, { ...data })
            .then((res) => {
                console.log(res)
                message.success('Cập nhật tài khoản khách thành công!')

                fetchData()
            })
            .catch((err) => {
                console.error('Lỗi: cập nhật thất bại!', err)
                message.error('Lỗi: cập nhật thất bại!')
            })
            .finally(() => {
                setLoadingEdit(null)
                setAnchorElEdit(null)
                resetForm()
            })
    }

    //fetch ban user
    const fetchUnLockOrBanUser = (data, type) => {
        request
            .post(update_user_info_from_admin, { ...data })
            .then((res) => {
                console.log(res)
                if (type === 'ban') {
                    message.success(
                        <Typography>
                            Khoá tài khoản khách hàng <span style={{ color: colors.orangeAccent[400] }}>{currentRow.fullName}</span> thành công!
                        </Typography>
                    )
                } else {
                    message.success(
                        <Typography>
                            Mở khoá tài khoản khách hàng <span style={{ color: colors.greenLight[400] }}>{currentRow.fullName}</span> thành công!
                        </Typography>
                    )
                }
                fetchData()
                setAnchorElBanUser(null)
                setAnchorElUnlock(null)
            })
            .catch((err) => {
                console.error('Lỗi: khoá tài khoản khách thất bại!', err)
                message.error('Lỗi: khoá tài khoản khách thất bại!')
            })
    }

    //fetch delete user
    const fetchDeleteUser = (_id) => {
        request
            .post(delete_user_from_admin, { _id })
            .then((res) => {
                console.log(res)
                message.success(
                    <Typography>
                        Xoá tài khoản <span style={{ color: colors.redAccent[500] }}>{currentRow.fullName}</span> thành công!
                    </Typography>
                )
            })
            .catch((err) => {
                message.error('Xoá tài khoản thất bại!')
            })
            .finally(() => {
                fetchData()
                setAnchorElDelete(null)
            })
    }

    //fetching
    useEffect(() => {
        fetchData()
    }, [paginationModel, searchTerm, queryOptions])

    //handle create user
    const handleOpenBoxCreateUser = (e) => {
        setAnchorElCreateUser(e.currentTarget)
    }
    const handleCloseBoxCreateUser = () => {
        setAnchorElCreateUser(null)
    }
    const handleConfirmCreateUser = (values, { resetForm, setFieldError }) => {
        console.log('values user', values)
        delete values.confirmPassword
        setLoadingCreateUser(true)

        const timer = setTimeout(() => {
            fetchCreateUser(values, resetForm, setFieldError)
        }, 500)

        return () => clearTimeout(timer)
    }

    //delete user
    const handleOpenDeleteUser = (e, row) => {
        setCurrentRow(row)
        setAnchorElDelete(e.currentTarget)
    }

    const handleCloseBoxDeleteUser = () => {
        setAnchorElDelete(null)
    }

    const handleConfirmDeleteUser = () => {
        const _id = currentRow._id
        fetchDeleteUser(_id)
    }

    //edit
    const handleOpenEdit = (e, row) => {
        setAnchorElEdit(e.currentTarget)
        setCurrentRow(row)
        setInitialValueEditUser({
            ...intitalValueEditUser,
            fullName: row.fullName,
            phoneNumber: row.phoneNumber,
        })
    }
    const handleCloseEdit = () => {
        setAnchorElEdit(null)
        setCurrentRow({})
    }
    const handleConfirmEdit = (values, { resetForm, setFieldError }) => {
        console.log('values edit', values)
        setLoadingEdit(true)
        const data = {
            _id: currentRow._id,
            password: values.newPassword,
            phoneNumber: values.phoneNumber,
        }

        const timer = setTimeout(() => {
            fetchEditUser(data, resetForm, setFieldError)
        }, 500)

        return () => clearTimeout(timer)
    }

    //ban user
    const handleOpenBanUser = (e, row) => {
        setAnchorElBanUser(e.currentTarget)
        setCurrentRow(row)
    }
    const handleCloseBanUser = () => {
        setAnchorElBanUser(null)
    }
    const handleConfirmBanUser = () => {
        const data = {
            _id: currentRow._id,
            role: 0,
        }
        console.log('values user baned', data)

        fetchUnLockOrBanUser(data, 'ban')
    }

    // unlock account
    const handleOpenBoxUnlockAccount = (e, row) => {
        setAnchorElUnlock(e.currentTarget)
        setCurrentRow(row)
    }
    const handleCloseBoxUnlock = () => {
        setAnchorElUnlock(null)
    }
    const handleUnLockAccount = () => {
        const data = {
            _id: currentRow._id,
            role: 2,
        }
        console.log('values unlock account', data)

        fetchUnLockOrBanUser(data, 'unlock')
    }

    //columns
    const columns = [
        {
            field: 'index',
            headerName: 'STT',
            sortable: false,
        },
        {
            field: 'fullName',
            headerName: 'Tên',
            flex: 0.7,
            minWidth: 150,
            cellClassName: 'name-column--cell name-column--users',
            sortable: false,
        },
        {
            field: 'phoneNumber',
            headerName: 'Phone',
            type: 'Number',
            flex: 0.7,
            minWidth: 100,
            sortable: false,
        },
        {
            field: 'registerDate',
            headerName: 'Ngày đăng ký',
            flex: 0.5,
            minWidth: 100,
            renderCell: ({ row }) => {
                return <p>{moment(row.registerDate).format('DD-MM-YYYY')}</p>
            },
        },
        {
            field: 'role',
            headerName: 'Trạng thái',
            flex: 0.5,
            minWidth: 100,
            renderCell: ({ row }) => {
                let label, chipColor

                if (row.role > 0) {
                    label = 'Hoạt động'
                    chipColor = {
                        backgroundColor: '#4caf50',
                        color: 'white',
                    }
                } else {
                    label = 'Tài khoản bị khoá'
                    chipColor = {
                        backgroundColor: '#f44336',
                        color: 'white',
                    }
                }

                return <Chip label={label} size='medium' sx={{ fontWeight: 600, minWidth: '110px', ...chipColor }} />
            },
        },
        {
            field: 'action',
            headerName: 'Actions',
            flex: 0.7,
            width: '120px',
            sortable: false,
            cellClassName: 'action-column',
            renderCell: ({ row }) => (
                <Box display='flex' alignItems='center' justifyContent='center' gap={1}>
                    <Tooltip
                        title='Đổi mật khẩu'
                        slotProps={{
                            popper: {
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, -10],
                                        },
                                    },
                                ],
                            },
                        }}
                    >
                        <IconButton onClick={(e) => handleOpenEdit(e, row)} aria-label='edit'>
                            <Edit />
                        </IconButton>
                    </Tooltip>
                    {row.role > 0 ? (
                        <Tooltip
                            title='Khoá tài khoản'
                            slotProps={{
                                popper: {
                                    modifiers: [
                                        {
                                            name: 'offset',
                                            options: {
                                                offset: [0, -10],
                                            },
                                        },
                                    ],
                                },
                            }}
                        >
                            <IconButton onClick={(e) => handleOpenBanUser(e, row)} aria-label='edit'>
                                <BlockIcon />
                            </IconButton>
                        </Tooltip>
                    ) : (
                        <Tooltip
                            title='Mở khoá tài khoản'
                            slotProps={{
                                popper: {
                                    modifiers: [
                                        {
                                            name: 'offset',
                                            options: {
                                                offset: [0, -10],
                                            },
                                        },
                                    ],
                                },
                            }}
                        >
                            <IconButton onClick={(e) => handleOpenBoxUnlockAccount(e, row)} aria-label='edit'>
                                <LockOpenIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    <Tooltip
                        title='Xoá tài khoản'
                        slotProps={{
                            popper: {
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, -10],
                                        },
                                    },
                                ],
                            },
                        }}
                    >
                        <IconButton onClick={(e) => handleOpenDeleteUser(e, row)} aria-label='delete'>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            ),
        },
    ]

    return (
        <Box
            m='20px'
            sx={{
                '& .MuiTypography-root.MuiTypography-h2': {
                    margin: '0 0 5px 0',
                },
                // '& .row-disabled': {
                //     bgcolor: colors.grey[500],
                //     color: '#f9f9f9',
                //     opacity: '0.6',
                // },
                '& .name-column--users': {
                    color: `${colors.greenLight[400]} !important`,
                },
            }}
        >
            <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Header title='QUẢN LÝ KHÁCH HÀNG' subtitle='Trang quản lý khách hàng' />
                <Button
                    sx={{
                        backgroundColor: colors.blueAccent[700],
                        color: 'white',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        padding: '10px 20px',
                        '&:hover': {
                            backgroundColor: colors.blueAccent[600],
                        },
                    }}
                    startIcon={<PersonAddIcon />}
                    onClick={(e) => handleOpenBoxCreateUser(e)}
                >
                    Thêm tài khoản
                </Button>
            </Box>
            <Box height='75vh' m='40px 0 0 0' sx={StyleDataGrid()}>
                <DataGrid
                    getRowId={(row) => row._id}
                    rows={dataUsers}
                    columns={columns}
                    rowCount={totalRows}
                    //pagination server side
                    pagination
                    initialState={{
                        pagination: { paginationModel },
                        density: 'comfortable',
                    }}
                    pageSizeOptions={[10, 25, 50, 100]}
                    paginationMode='server'
                    onPaginationModelChange={setPaginationModel}
                    // sort server
                    sortingMode='server'
                    onSortModelChange={handleSortModelChange}
                    loading={loading}
                    slots={{
                        toolbar: GridToolbar,
                        noRowsOverlay: CustomNoRowsOverlay,
                        loadingOverlay: CustomLoadingComponent,
                    }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: {
                                onChange: handleInputChange,
                                value: inputText,
                                placeholder: 'Tìm kiếm theo tên,sdt',
                            },
                        },
                    }}
                    getRowClassName={(params) => (params.row.role === 0 ? 'row-disabled' : '')}
                />
            </Box>

            {/* box ban */}
            <BoxBanDeleteUser
                type={'ban'}
                id={currentRow._id}
                open={Boolean(anchorElBanUser)}
                onClose={handleCloseBanUser}
                anchorEl={anchorElBanUser}
                handleConfirm={handleConfirmBanUser}
            />

            {/* box unlock account */}
            <BoxBanDeleteUser
                type={'unlock'}
                id={currentRow._id}
                open={Boolean(anchorElUnlock)}
                onClose={handleCloseBoxUnlock}
                anchorEl={anchorElUnlock}
                handleConfirm={handleUnLockAccount}
            />

            {/* box delete */}
            <BoxBanDeleteUser
                type={'delete'}
                id={currentRow._id}
                open={Boolean(anchorElDelete)}
                onClose={handleCloseBoxDeleteUser}
                anchorEl={anchorElDelete}
                handleConfirm={handleConfirmDeleteUser}
            />

            {/* create user */}
            <ModalCreateEditUser
                type='create'
                open={Boolean(anchorElCreateUser)}
                onClose={handleCloseBoxCreateUser}
                validateSchema={validateSchemaCreateUser}
                initialValues={intitalValueUser}
                loading={loadingCreateUser}
                handleConfirm={handleConfirmCreateUser}
            />

            {/* edit user */}
            <ModalCreateEditUser
                type='edit'
                open={Boolean(anchorElEdit)}
                onClose={handleCloseEdit}
                validateSchema={validateSchemaEditUser}
                initialValues={intitalValueEditUser}
                loading={loadingEdit}
                handleConfirm={handleConfirmEdit}
            />
        </Box>
    )
}

export default Users
