import { useState, useEffect, useRef } from 'react'
import {
    Modal,
    useTheme,
    Box,
    TextField,
    Typography,
    IconButton,
    Button,
    InputAdornment,
    Select,
    FormControl,
    MenuItem,
    FormHelperText,
    Chip,
} from '@mui/material'
import { tokens } from '../../../theme'
import { Cancel, Close, Send } from '@mui/icons-material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { TimePicker } from '@mui/x-date-pickers'
import { Formik } from 'formik'
import * as yup from 'yup'
import moment from 'moment'
import request from '../../../utils/api/request'
import {
    create_clean_by_admin,
    create_permanent_task_by_admin,
    create_task_by_admin,
    get_cleanbookings_price,
    get_permanent_taskbooking_price,
    get_price_for_taskbooking,
    get_services,
} from '../../../utils/api/apiList'
import { message } from 'antd'
import { useDispatch } from 'react-redux'
import getEventAdminCreateTaskSlice from '../../../redux/features/getEventAdminCreateTaskSlice'
import getEventAdminCreateCleanSlice from '../../../redux/features/getEventAdminCreateCleanSlice'
import CircularProgress from '@mui/material/CircularProgress'
import CustomLoadingComponent from '../../loading'
import getEventAdminCreatePermanentSlice from '../../../redux/features/getEventAdminCreatePermanentSlice'
import { formatNumber, phoneRegExp, parseNumber } from '../../prefixData'

function ModalCreateAllTask({ boxName, open, onClose }) {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const [initialFormValues, setInitialFormValues] = useState({
        name: null,
        phoneNumber: null,
        address: null,
        date: moment(),
        time: null,
        estimateTime: null,
        price: null,
        estimatePeople: null,
        package: 1,
        selectedDays: null,
        option: [],
    })
    const [isLoadingFillPrice, setIsLoadingFillPrice] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [services, setServices] = useState([])
    // check trạng thái change trên các input
    const [day, setDay] = useState(null)
    const [time, setTime] = useState(null)
    const [estimateTime, setEstimateTime] = useState(null)
    const [estimatePeople, setEstimatePeople] = useState(null)
    const [totalDay, setTotalDay] = useState(null)
    const [option, setOption] = useState([])
    // chon ngay ca co dinh
    const [selectedDays, setSelectedDays] = useState([])

    // ref setField
    const setFieldValueRef = useRef()

    //idCompany
    const companyId = localStorage.getItem('companyId')
    // console.log({ companyId })

    //schema validate
    const getValidationSchema = (modalName) => {
        return yup.object().shape({
            address: yup.string().min(3, 'Địa chỉ quá ngắn').required('Yêu cầu nhập địa chỉ'),
            // date: yup.date().notRequired('Yêu cầu chọn ngày'),
            date: yup.date().when('$modalName', {
                is: () => modalName === 'permanentTask',
                then: (schema) => schema.notRequired(),
                otherwise: (schema) => schema.required('vui lòng chọn ngày'),
            }),
            selectedDays: yup.array().when('$modalName', {
                is: () => modalName === 'permanentTask',
                then: (schema) => schema.min(1, 'Phải chọn ít nhất một ngày trong tuần').required('Phải chọn ít nhất một ngày trong tuần'),
                otherwise: (schema) => schema.notRequired(),
            }),
            // time: yup.date().required('Yêu cầu chọn thời gian'),
            time: yup.date().required('Yêu cầu chọn thời gian hợp lệ'),
            estimateTime: yup.number().required('Yêu cầu chọn số giờ làm'),
            estimatePeople: yup.number().when('$modalName', {
                is: () => modalName === 'clean',
                then: (schema) => schema.min(2, 'Số công lao động phải lớn hơn hoặc bằng 2').required('Yêu cầu nhập số công'),
                otherwise: (schema) => schema.notRequired(),
            }),
            package: yup.number().when('$modalName', {
                is: () => modalName === 'permanentTask',
                then: (schema) =>
                    schema.min(1, 'Gói theo tháng, nhỏ nhất là 1 tháng').integer('Gói tháng phải là số nguyên, 1->12').required('Yêu cầu nhập gói'),
                otherwise: (schema) => schema.notRequired(),
            }),
            price: yup.number().typeError('Giá tiền phải là số dương').min(0, 'Giá phải lớn hơn hoặc bằng 0').required('Yêu cầu nhập giá'),
            name: yup.string().required('Yêu cầu nhập tên khách'),
            phoneNumber: yup.string().matches(phoneRegExp, 'Số điện thoại không đúng').required('Yêu cầu nhập số điện thoại'),
        })
    }

    //message
    const [messageApi, messageContextHolder] = message.useMessage()
    const dispatch = useDispatch()

    // create
    const fetchdataCreate = (api, data, resetForm) => {
        request
            .post(api, { ...data })
            .then((res) => {
                console.log(res)
                switch (boxName) {
                    case 'clean':
                        message.success(`Admin tạo đơn hàng Tổng vệ sinh thành công!`)
                        dispatch(getEventAdminCreateCleanSlice.actions.eventAdminCreateClean(res.data.data))
                        break
                    case 'permanentTask':
                        message.success(`Admin tạo đơn hàng ca cố định thành công!`)
                        dispatch(getEventAdminCreatePermanentSlice.actions.eventAdminCreatePermanent(res.data.data))
                        break
                    default:
                        message.success(`Admin tạo đơn hàng ca lẻ thành công!`)
                        dispatch(getEventAdminCreateTaskSlice.actions.eventAdminCreateTask(res.data.data))
                        break
                }
                resetForm()

                //reset data
                setDay(null)
                setTime(null)
                setEstimateTime(null)
                setEstimatePeople(null)
                setFieldValueRef.current('price', null)
                setSelectedDays([])
                onClose()
            })
            .catch((err) => {
                console.log(err)
                message.error('Admin tạo đơn hàng thất bại!')
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleCreate = (values, { resetForm }) => {
        console.log(values)

        //time
        const time = values.time
        const hoursConvertMinutes = moment(time).hours() * 60
        const minutes = moment(time).minutes()
        const totalMinutes = hoursConvertMinutes + minutes

        //date
        const date = moment(values.date).toISOString()

        const dataUpdate = {
            ...values,
            date,
            estimateTime: values.estimateTime * 60,
            time: totalMinutes,
            userFromAdminOrder: {
                name: values.name,
                phoneNumber: values.phoneNumber,
            },
            typeOfHouse: 1, //default
            note: 'admin tạo đơn', //default
            companyId, //id công ty (cập nhật theo flow nhiều công ty)
        }
        console.log(dataUpdate)

        // setIsLoading(true)
        const timerFetchCreate = setTimeout(() => {
            switch (boxName) {
                case 'clean':
                    dataUpdate.estimateS = 100 //default clean
                    fetchdataCreate(create_clean_by_admin, dataUpdate, resetForm)
                    break
                case 'permanentTask':
                    // Calculate working days (ca cố định)
                    const startDate = moment() // Ngày đặt đơn
                    const totalDays = values.package * 30 // Mặc định là 30 ngày * gói
                    const selectedTime = moment(values.time)
                    const workingDays = calculateWorkingDays(startDate, totalDays, selectedDays, selectedTime)
                    console.log('workingDays', workingDays)
                    // console.log('options', option)
                    dataUpdate.option = option
                    dataUpdate.date = workingDays
                    dataUpdate.package = values.package
                    fetchdataCreate(create_permanent_task_by_admin, dataUpdate, resetForm)
                    break
                default:
                    dataUpdate.option = option
                    fetchdataCreate(create_task_by_admin, dataUpdate, resetForm)
                    break
            }
        }, 500)

        return () => clearTimeout(timerFetchCreate)
    }

    //get service
    const fetchServices = () => {
        request
            .post(get_services, {})
            .then((res) => {
                console.log(res)
                setServices(res.data.data.docs)
            })
            .catch((err) => {
                console.error(err)
            })
    }
    useEffect(() => {
        fetchServices()
    }, [])

    //fill gia
    const fetchFillPrice = (data, eventFillPrice) => {
        const time = moment(data.time).hours() * 60 + moment(data.time).minutes()
        //dafault
        data.lat = 16.4513285 // Huế
        data.lng = 102.5704174 // Huế
        data.distance = 10000 // mặc định

        // + tiền dịch vụ cộng thêm
        let prices = 0
        if (boxName === 'taskBooking') {
            prices = option.reduce((total, _id) => {
                const service = services.find((service) => service._id === _id)
                return total + (service ? service.price : 0)
            }, 0)
        }
        if (boxName === 'permanentTask') {
            const result = option.reduce((total, _id) => {
                const service = services.find((service) => service._id === _id)
                return total + (service ? service.price : 0)
            }, 0)
            console.log('priceService', result * data.totalDay)
            prices = result * data.totalDay
        }

        // console.log(prices)
        console.log(data)

        //api
        let api

        switch (eventFillPrice) {
            case 'eventFillPriceTask':
                api = get_price_for_taskbooking
                data.time = time
                break
            case 'eventFillPricePermanentTask':
                api = get_permanent_taskbooking_price
                break
            case 'eventFillPriceClean':
                api = get_cleanbookings_price
                data.time = time
                break
            default:
                break
        }

        request
            .post(api, data)
            .then((response) => {
                if (boxName !== 'clean') {
                    setFieldValueRef.current('price', response.data.data + prices)
                } else {
                    setFieldValueRef.current('price', response.data.data)
                }
            })
            .catch((error) => {
                console.error('Error fetching price:', error)
            })
            .finally(() => {
                setIsLoadingFillPrice(false)
            })
    }

    // fetch Data price
    useEffect(() => {
        //fetchData price task
        if (boxName === 'taskBooking') {
            if ((day && time && estimateTime) || option) {
                // console.log('day', day)
                setIsLoadingFillPrice(true)
                const timerFillPrice = setTimeout(() => {
                    const data = { day, time, estimateTime }
                    fetchFillPrice(data, 'eventFillPriceTask')
                }, 1000)

                return () => clearTimeout(timerFillPrice)
            }
        } else if (boxName === 'clean') {
            // //fetchData price clean
            if ((time && estimateTime && estimatePeople) || day) {
                setIsLoadingFillPrice(true)
                const timerFillPrice = setTimeout(() => {
                    const data = { day, time, estimateTime, estimatePeople }
                    fetchFillPrice(data, 'eventFillPriceClean')
                }, 1000)

                return () => clearTimeout(timerFillPrice)
            }
        } else if (boxName === 'permanentTask') {
            // fetchData price PermanentTask
            if ((selectedDays && estimateTime && totalDay) || option) {
                setIsLoadingFillPrice(true)
                // console.log({ totalDay })
                const timerFillPrice = setTimeout(() => {
                    const data = { estimateTime, totalDay }
                    fetchFillPrice(data, 'eventFillPricePermanentTask')
                }, 1000)

                return () => clearTimeout(timerFillPrice)
            }
        } else {
            return
        }
    }, [day, time, estimateTime, estimatePeople, totalDay, selectedDays, option])

    //handle close modal
    const handleClose = () => {
        onClose()

        //reset data
        setDay(null)
        setTime(null)
        setEstimateTime(null)
        setEstimatePeople(null)
        setSelectedDays([])
        setTotalDay(null)
        // setTotalDay(null) //permanentTask
    }

    // ************************************* CA CỐ ĐỊNH ************************************
    // Tính toán lịch ngày làm việc
    const calculateWorkingDays = (startDate, totalDays, selectedDays, selectedTime) => {
        let workingDays = []
        // let validationDate = moment(startDate).add(2, 'days') // Ngày xác thực đơn sau 2 ngày từ ngày đặt
        let validationDate = moment(startDate)
        console.log(validationDate.format('DD-MM'))
        let firstWorkingDay = validationDate.clone()
        // console.log(firstWorkingDay.format('MM-DD'))

        // Tìm ngày bắt đầu làm việc là ngày hợp lệ đầu tiên trong danh sách ngày chọn
        while (!selectedDays.includes(firstWorkingDay.day())) {
            firstWorkingDay.add(1, 'day')
        }
        // console.log(firstWorkingDay)

        //
        let currentDate = firstWorkingDay.clone()
        let endDate = currentDate.clone().add(totalDays - 1, 'days')
        // console.log(currentDate.day())

        while (currentDate.isSameOrBefore(endDate)) {
            if (selectedDays.includes(currentDate.day())) {
                // Nếu currentDate không phải là ngày hiện tại, thì thời gian luôn hợp lệ.
                const isSameDay = currentDate.isSame(moment(), 'day')

                //Nếu currentDate là ngày hiện tại, thì cần kiểm tra xem selectedTime có sau thời điểm hiện tại hay không.
                const isTimeValid = !isSameDay || selectedTime.isAfter(moment())

                if (isTimeValid) {
                    workingDays.push(moment(currentDate))
                }
            }
            currentDate.add(1, 'day')
        }

        return workingDays
    }

    // render ui
    const RenderDayBoxes = ({ name, values }) => {
        const days = ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7']

        //chon danh sách ngày trong tuần
        const handleDayClick = (dayIndex) => {
            const updatedSelectedDays = selectedDays.includes(dayIndex) ? selectedDays.filter((day) => day !== dayIndex) : [...selectedDays, dayIndex]

            setSelectedDays(updatedSelectedDays)
            setFieldValueRef.current(name, updatedSelectedDays)

            // tính toán lại số ngày sau mỗi lần pick chọn ngày
            // Tính tổng số ngày làm việc dựa trên số ngày được chọn và gói tháng
            const packageDuration = values.package * 30
            const selectedWorkdays = updatedSelectedDays.length

            // Số tuần trong gói
            const weeks = Math.floor(packageDuration / 7)
            const remainingDays = packageDuration % 7

            // Tính toán tổng số ngày làm việc
            let totalDay = selectedWorkdays * weeks

            // Tìm ngày đầu tiên của gói để kiểm tra ngày còn lại
            let startDate = moment() // Sử dụng moment để lấy ngày hiện tại hoặc ngày bắt đầu thực tế
            let firstDay = startDate.day() // Lấy chỉ số ngày trong tuần (0 = Chủ nhật, 1 = Thứ hai, ...)

            // Thêm các ngày còn lại nếu trùng với ngày làm việc đã chọn
            for (let i = 0; i < remainingDays; i++) {
                if (updatedSelectedDays.includes((firstDay + i) % 7)) {
                    totalDay++
                }
            }

            setTotalDay(totalDay)
        }

        return days.map((day, dayIndex) => (
            <>
                <Box key={dayIndex} display='flex' justifyContent='space-evenly' gap='5px'>
                    <IconButton
                        sx={{
                            width: 40,
                            height: 40,
                            bgcolor: selectedDays.includes(dayIndex) ? '#4e6ff4' : '#6b6e7c', //435cc2
                            fontSize: '14px',
                            color: 'white',
                            '&:hover': {
                                bgcolor: theme.palette.mode === 'dark' ? '#4662d6' : '#2b3b85d1',
                            },
                        }}
                        onClick={() => handleDayClick(dayIndex)}
                    >
                        {day}
                    </IconButton>
                </Box>
            </>
        ))
    }

    // items estimateTime
    const estimateTimeItems = () => {
        const hours = boxName === 'clean' ? [2, 3, 4, 5, 6, 7, 8] : [2, 3, 4]
        return hours.map((hour) => (
            <MenuItem value={hour} key={hour}>
                <Typography>{hour} giờ</Typography>
            </MenuItem>
        ))
    }

    // items package
    const packageItems = () => {
        const months = [1, 2, 3, 4, 5, 6]
        return months.map((month) => (
            <MenuItem value={month} key={month}>
                {month} tháng
            </MenuItem>
        ))
    }

    //style
    const styleBox = {
        '& .box.MuiBox-root': {
            display: 'flex',
            alignItems: 'center',
            gap: '20px',
            width: '80%',
            '& .MuiTextField-root': {
                flex: 1,
            },
        },
        '& .MuiTypography-root': {
            minWidth: '100px',
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px',
        // maxHeight: '700px',
        maxHeight: '70vh',
        overflowY: 'auto',
        mt: '5px',
        boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
        pb: '20px',
        pt: '50px',
        backgroundColor: theme.palette.mode === 'dark' ? '#142043' : '#e7e7e7',
        borderRadius: '5px',
        '& .Mui-focused fieldset': {
            borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.6) !important' : 'rgba(0, 0, 0, 0.8)', // Màu border khi focused
            borderWidth: theme.palette.mode === 'dark' ? '2px' : '1px !important',
        },
    }
    const styleModal = {
        borderRadius: '5px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 700,
        maxHeight: '90vh',
        bgcolor: theme.palette.mode === 'dark' ? '#323d58' : '#e0e0e0',
        // bgcolor: colors.primary[400],
        border: '1px solid rgba(0, 0, 0, 0.1) 0px 4px 12px',
        boxShadow: 24,
        p: 4,
        pt: '15px',
    }

    const validate = (values) => {
        const errors = {}
        const selectedDate = moment(values.date)
        const selectedTime = moment(values.time)
        const currentTime = moment()

        if (selectedDate.isSame(currentTime, 'day')) {
            if (!selectedTime.isSameOrAfter(currentTime)) {
                errors.time = 'Thời gian phải lớn hơn thời điểm hiện tại'
            }
        }

        return errors
    }

    return (
        <Modal
            open={open}
            onClose={(event, reason) => {
                if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
                    return
                }
                handleClose()
            }}
            style={{ backdropFilter: 'blur(5px)', zIndex: '99' }}
        >
            <Box sx={styleModal}>
                {messageContextHolder}
                <Box display='flex' justifyContent='space-between' alignItems='center'>
                    <Typography variant='h4' color={colors.grey[400]}>
                        TẠO ĐƠN HÀNG
                    </Typography>
                </Box>
                <IconButton
                    sx={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                    }}
                    onClick={handleClose}
                >
                    <Close color='disabled' />
                </IconButton>
                <Formik
                    initialValues={initialFormValues}
                    validate={boxName !== 'permanentTask' ? validate : null}
                    validationSchema={getValidationSchema(boxName)}
                    onSubmit={handleCreate}
                    enableReinitialize={true}
                >
                    {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue, setFieldError, setSubmitting }) => {
                        setFieldValueRef.current = setFieldValue
                        return (
                            <Box
                                component='form'
                                onSubmit={handleSubmit}
                                // onSubmit={(e) => {
                                //     e.preventDefault()
                                //     if (Object.keys(errors).length > 0) {
                                //         setSubmitting(false)
                                //         return
                                //     }
                                //     handleSubmit(values)
                                //     setSubmitting(false)
                                // }}
                                sx={styleBox}
                                name={boxName}
                            >
                                {isLoading && (
                                    <Box
                                        position='absolute'
                                        top={0}
                                        left={0}
                                        right={0}
                                        bottom={0}
                                        display='flex'
                                        alignItems='center'
                                        justifyContent='center'
                                        bgcolor='rgba(255, 255, 255, 0.2)'
                                        zIndex={2}
                                        width='100% !important'
                                    >
                                        <CustomLoadingComponent />
                                    </Box>
                                )}
                                <Box className='box'>
                                    <Typography>Tên khách hàng: </Typography>
                                    <TextField
                                        value={values.name || ''}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name='name'
                                        error={!!touched.name && !!errors.name}
                                        helperText={touched.name && errors.name}
                                    />
                                </Box>
                                <Box className='box'>
                                    <Typography>Số điện thoại: </Typography>
                                    <TextField
                                        value={values.phoneNumber || ''}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name='phoneNumber'
                                        error={!!touched.phoneNumber && !!errors.phoneNumber}
                                        helperText={touched.phoneNumber && errors.phoneNumber}
                                    />
                                </Box>
                                <Box className='box'>
                                    <Typography>Địa chỉ: </Typography>
                                    <TextField
                                        value={values.address || ''}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name='address'
                                        rows={3}
                                        multiline
                                        error={!!touched.address && !!errors.address}
                                        helperText={touched.address && errors.address}
                                    />
                                </Box>
                                <Box className='box' gap={boxName === 'permanentTask' ? '18px !important' : '20px'}>
                                    {boxName != 'permanentTask' ? (
                                        <>
                                            <Typography>Ngày: </Typography>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <DatePicker
                                                    name='date'
                                                    value={values.date}
                                                    onChange={(newValue) => {
                                                        setFieldValue('date', newValue)
                                                        const dayConvert = Number(moment(newValue).day())
                                                        setDay(dayConvert)
                                                        const selectedDate = moment(newValue)

                                                        if (selectedDate.isSame(moment(), 'day')) {
                                                            if (moment(values.time).isBefore(moment())) {
                                                                setFieldValue('time', null)
                                                                setTime(null)
                                                                setFieldError('time', '')
                                                            }
                                                        }

                                                        // if (selectedDate.isAfter(moment(), 'day')) {
                                                        //     // Nếu chọn ngày trong tương lai, reset time về null
                                                        //     setFieldValue('time', null)
                                                        //     setTime(null)
                                                        //     setFieldError('time', '')
                                                        // } else if (selectedDate.isSame(moment(), 'day')) {
                                                        //     // Nếu chọn ngày hiện tại, có thể giữ giá trị time hiện tại
                                                        //     if (moment(values.time).isBefore(moment())) {
                                                        //         setFieldValue('time', null)
                                                        //         setTime(null)
                                                        //         setFieldError('time', '')
                                                        //     }
                                                        // }
                                                    }}
                                                    minDate={moment()}
                                                    slotProps={{
                                                        textField: {
                                                            variant: 'outlined',
                                                            margin: 'normal',
                                                            error: touched.date && !!errors.date,
                                                            helperText: touched.date && errors.date,
                                                            fullWidth: true,
                                                        },
                                                    }}
                                                    sx={{
                                                        '& .MuiButtonBase-root.MuiPickersDay-root': {
                                                            fontSize: '14px !important',
                                                        },
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        </>
                                    ) : (
                                        <Box display='flex' flexDirection='column'>
                                            <Box display='flex' gap='20px'>
                                                <Typography>Chọn Ngày: </Typography>
                                                <Box display='flex' gap='17px'>
                                                    <RenderDayBoxes name='selectedDays' values={values} />
                                                </Box>
                                            </Box>
                                            {touched.selectedDays && errors.selectedDays && (
                                                <FormHelperText sx={{ ml: '134px' }} error>
                                                    {errors.selectedDays}
                                                </FormHelperText>
                                            )}
                                            {/* <FormHelperText sx={{ ml: '134px' }}>Ngày bắt đầu sau 2 ngày tạo</FormHelperText> */}
                                        </Box>
                                    )}
                                </Box>
                                <Box className='box'>
                                    <Typography>Thời gian: </Typography>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        {boxName === 'permanentTask' ? (
                                            <TimePicker
                                                name='time'
                                                value={values.time}
                                                onChange={(newValue) => {
                                                    setFieldValue('time', newValue)
                                                    setTime(newValue)
                                                }}
                                                timeSteps={{ minutes: 30 }}
                                                ampm={false}
                                                slotProps={{
                                                    textField: {
                                                        variant: 'outlined',
                                                        margin: 'normal',
                                                        error: touched.time && !!errors.time,
                                                        helperText: touched.time && errors.time,
                                                        fullWidth: true,
                                                    },
                                                }}
                                            />
                                        ) : (
                                            <TimePicker
                                                name='time'
                                                value={values.time}
                                                onChange={(newValue) => {
                                                    const selectedDate = moment(values.date)
                                                    const selectedTime = moment(newValue)
                                                    const currentTime = moment()

                                                    if (selectedDate.isSame(currentTime, 'day')) {
                                                        if (selectedTime.isSameOrAfter(currentTime)) {
                                                            setFieldValue('time', selectedTime)
                                                            setTime(selectedTime)
                                                            setFieldError('time', '')
                                                        } else {
                                                            setFieldError('time', 'Thời gian phải lớn hơn thời điểm hiện tại')
                                                        }
                                                    } else {
                                                        setFieldValue('time', newValue)
                                                        setTime(newValue)
                                                        setFieldError('time', '')
                                                    }
                                                }}
                                                // disablePast
                                                // minTime={moment().add(5, 'minutes')}
                                                timeSteps={{ minutes: 30 }}
                                                minTime={moment(values.date).isSame(moment(), 'day') ? moment() : undefined}
                                                ampm={false}
                                                slotProps={{
                                                    textField: {
                                                        variant: 'outlined',
                                                        margin: 'normal',
                                                        error: touched.time && !!errors.time,
                                                        helperText: touched.time && errors.time,
                                                        fullWidth: true,
                                                    },
                                                }}
                                            />
                                        )}
                                    </LocalizationProvider>
                                </Box>

                                <Box className='box'>
                                    <FormControl variant='filled' error={!!touched.estimateTime && !!errors.estimateTime} fullWidth>
                                        <Box className='select' width='100%' display='flex' gap='20px'>
                                            <Typography>Số giờ làm: </Typography>
                                            <Select
                                                name='estimateTime'
                                                value={values.estimateTime || ''}
                                                placeholder='Chọn số giờ làm'
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    handleChange(e)
                                                    setEstimateTime(Number(e.target.value))
                                                }}
                                                error={!!touched.estimateTime && !!errors.estimateTime}
                                                fullWidth
                                            >
                                                {estimateTimeItems()}
                                            </Select>
                                        </Box>
                                        {touched.estimateTime && errors.estimateTime && (
                                            <FormHelperText
                                                sx={{
                                                    ml: '134px',
                                                }}
                                            >
                                                {errors.estimateTime}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Box>
                                {boxName === 'clean' && (
                                    <Box className='box'>
                                        <Typography>Số công: </Typography>
                                        <TextField
                                            name='estimatePeople'
                                            type='number'
                                            value={values.estimatePeople || ''}
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                handleChange(e)
                                                setEstimatePeople(Number(e.target.value))
                                            }}
                                            error={!!touched.estimatePeople && !!errors.estimatePeople}
                                            helperText={touched.estimatePeople && errors.estimatePeople}
                                        />
                                    </Box>
                                )}
                                {boxName === 'permanentTask' && (
                                    <Box className='box'>
                                        <FormControl variant='filled' error={!!touched.package && !!errors.package} fullWidth>
                                            <Box className='select' width='100%' display='flex' gap='20px'>
                                                <Typography>Loại gói: </Typography>
                                                <Select
                                                    name='package'
                                                    placeholder='theo tháng (1 → 12)'
                                                    value={values.package || ''}
                                                    onBlur={handleBlur}
                                                    onChange={(e) => {
                                                        handleChange(e)
                                                        const updatedPackage = e.target.value
                                                        const totalDay = selectedDays.length * updatedPackage * 4
                                                        setTotalDay(totalDay)
                                                        // console.log(totalDay)
                                                    }}
                                                    error={!!touched.package && !!errors.package}
                                                    fullWidth
                                                >
                                                    {packageItems()}
                                                </Select>
                                            </Box>
                                            {touched.package && errors.package && (
                                                <FormHelperText
                                                    sx={{
                                                        ml: '134px',
                                                    }}
                                                >
                                                    {errors.package}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Box>
                                )}
                                {boxName === 'clean' ? (
                                    <></>
                                ) : (
                                    <Box className='box'>
                                        <Typography>Dịch vụ thêm: </Typography>
                                        <Select
                                            name='option'
                                            multiple
                                            value={values.option}
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                handleChange(e)
                                                setOption(e.target.value)
                                            }}
                                            renderValue={(selected) => {
                                                return (
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            flexWrap: 'wrap',
                                                            gap: 0.5,
                                                        }}
                                                    >
                                                        {selected.map((value) => {
                                                            const service = services.find((elm) => elm._id === value)
                                                            return <Chip key={value} label={service ? service.name : ''} />
                                                        })}
                                                    </Box>
                                                )
                                            }}
                                            fullWidth
                                        >
                                            {services.map((elm) => {
                                                return (
                                                    <MenuItem
                                                        value={elm._id}
                                                        key={elm._id}
                                                        sx={{
                                                            '&.Mui-selected': {
                                                                backgroundColor: `rgba(144, 202, 249, 0.16) !important`,
                                                                '&:hover': {
                                                                    backgroundColor:
                                                                        theme.palette.mode === 'dark'
                                                                            ? 'rgb(239 239 239 / 12%) !important'
                                                                            : 'rgba(4, 5, 9, 0.12) !important',
                                                                },
                                                                '& .name-service': {
                                                                    color: theme.palette.mode === 'dark' ? 'inherit' : 'black',
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        <span className='name-service'>{elm.name}: </span>
                                                        <span
                                                            style={{
                                                                color: theme.palette.mode === 'dark' ? '#1ea81e' : 'green',
                                                                fontWeight: 600,
                                                                marginLeft: '2px',
                                                            }}
                                                        >
                                                            {formatNumber(elm.price)} VNĐ
                                                        </span>
                                                    </MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </Box>
                                )}
                                <Box className='box'>
                                    <Typography>Giá tiền: </Typography>
                                    <TextField
                                        name='price'
                                        type='text'
                                        value={isLoadingFillPrice ? '' : formatNumber(values.price)}
                                        onChange={(e) => {
                                            const rawValue = parseNumber(e.target.value)
                                            setFieldValue('price', Number(rawValue))
                                        }}
                                        onBlur={handleBlur}
                                        error={!!touched.price && !!errors.price}
                                        helperText={touched.price && errors.price}
                                        InputProps={{
                                            startAdornment: isLoadingFillPrice ? (
                                                <InputAdornment position='start'>
                                                    <CircularProgress size={25} color='secondary' />
                                                </InputAdornment>
                                            ) : null,
                                            sx: {
                                                '& .MuiInputBase-input': {
                                                    color: colors.greenLight[400],
                                                    fontWeight: 600,
                                                },
                                            },
                                        }}
                                    />
                                </Box>
                                <Box alignSelf='end' mt='20px' mr='40px'>
                                    <Button type='submit' sx={{ mr: '20px' }} variant='contained' color='success' endIcon={<Send />}>
                                        Gửi
                                    </Button>
                                    <Button variant='outlined' color='inherit' startIcon={<Cancel />} onClick={handleClose}>
                                        Huỷ
                                    </Button>
                                </Box>
                            </Box>
                        )
                    }}
                </Formik>
            </Box>
        </Modal>
    )
}

export default ModalCreateAllTask
