import {
    Box,
    Button,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    Tab,
    Tabs,
    TextField,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material'
import { tokens } from '../../../theme'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import { message } from 'antd'
import { useEffect, useState } from 'react'
import {
    create_app_discount,
    create_new_city_setting,
    delete_city_setting,
    get_city_setting_by_id,
    get_city_settings,
    update_city_setting,
} from '../../../utils/api/apiList'
import request from '../../../utils/api/request'
import { Close, Delete } from '@mui/icons-material'
import Header from '../../../components/Header'
import { formatNumber, parseNumber } from '../../../components/prefixData'
import Popover from '../../../components/popover'

//validate vị tri Viet Nam
const MIN_LAT_VN = 8.1790665
const MAX_LAT_VN = 23.393395
const MIN_LNG_VN = 102.14441
const MAX_LNG_VN = 109.464934

//validate location
const validationLocationSchema = yup.object().shape({
    name: yup.string().required('Tên thành phố là bắt buộc'),
    lat: yup
        .number()
        .required('Latitude là bắt buộc')
        .min(MIN_LAT_VN, `Latitude phải lớn hơn hoặc bằng ${MIN_LAT_VN}`)
        .max(MAX_LAT_VN, `Latitude phải nhỏ hơn hoặc bằng ${MAX_LAT_VN}`),
    lng: yup
        .number()
        .required('Longitude là bắt buộc')
        .min(MIN_LNG_VN, `Longitude phải lớn hơn hoặc bằng ${MIN_LNG_VN}`)
        .max(MAX_LNG_VN, `Longitude phải nhỏ hơn hoặc bằng ${MAX_LNG_VN}`),
    cityRadius: yup.number().required('Bán kính là bắt buộc').positive('Bán kính phải là số dương'),
})

//validate App Discount
const validationAppDiscountSchema = yup.object().shape({
    appDiscount: yup
        .number()
        .typeError('Phí hoa hồng phải là số')
        .min(0, 'Phí hoa hồng phải lớn hơn hoặc bằng 0')
        .required('Phí hoa hồng là bắt buộc'),
})

//settup tabs
function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
            style={{ flex: 1 }}
        >
            {value === index && (
                <Box sx={{ p: 4 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
}

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    }
}

function AdminSetting() {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    const [dataSettingCities, setDataSettingCities] = useState(null)
    const [cityId, setCityId] = useState('')
    const [dataSettingCity, setDataSettingCity] = useState(null)
    const [anchorElDeleteCity, setAnchorElDeleteCity] = useState(null)

    //state location city
    const [formDataPositionCity, setFormDataPositionCity] = useState({
        name: '',
        lat: '',
        lng: '',
        cityRadius: Number,
        geometry: [],
    })

    //state phí hoa hồng admin-doanhnghiep
    const [formDataAppDiscount, setFormDataAppDiscount] = useState({
        appDiscount: 0,
    })

    //tabs
    const [valueTab, setValueTab] = useState(0)

    // tabs city
    const [openBoxAddCity, setOpenBoxAddCity] = useState(false)
    const [nameCity, setNameCity] = useState('')

    // handle change tab
    const handleChangeTab = (event, newValue) => {
        setValueTab(newValue)
    }

    //notification
    const [messageApi, messageContextHolder] = message.useMessage()

    //fetching
    useEffect(() => {
        fetchGetAllSettingCitys()
    }, [])
    useEffect(() => {
        if (cityId) {
            // truyền giá trị theo form target của mui, hiện là e.target.value
            // handleSelectCity({ target: { value: cityId } })
            getCitySetting()
        }
    }, [cityId])

    //get all setting citys
    const fetchGetAllSettingCitys = () => {
        request
            .post(get_city_settings, {})
            .then((res) => {
                setDataSettingCities(res.data.data.docs)
            })
            .catch((err) => {
                message.error('Lỗi: không cập nhật được thông tin thành phố')
            })
    }

    //fectch create setting
    const createSettingCity = (data) => {
        request
            .post(create_new_city_setting, { data })
            .then((res) => {
                const newCityId = res.data.data._id
                setCityId(newCityId)
                fetchGetAllSettingCitys()
                message.success('Tạo thành phố thành công!')
            })
            .catch((err) => {
                message.error('Lỗi: tạo thành phố thất bại')
            })
    }

    // fetch update setting city
    const fetchUpdateCity = (data) => {
        request
            .post(update_city_setting, { data })
            .then((res) => {
                console.log(res)
                getCitySetting()
                message.success('Cập nhật thành phố thành công!')
            })
            .catch((err) => {
                message.error('Cập nhật thành phố thất bại!')
            })
    }

    //get city setting by id
    const getCitySetting = () => {
        request
            .post(get_city_setting_by_id, { _id: cityId })
            .then((res) => {
                const dataCity = res.data.data
                setDataSettingCity(dataCity)

                setFormDataPositionCity({
                    name: dataCity.name,
                    lat: dataCity.lat ?? '',
                    lng: dataCity.lng ?? '',
                    cityRadius: dataCity.cityRadius ?? '',
                    geometry: dataCity.geometry ?? [],
                })

                setFormDataAppDiscount({
                    appDiscount: dataCity.appDiscount * 100,
                })
            })
            .catch((err) => {
                console.error(err)
            })
    }

    // handle create city
    const handleOpenBoxAddCity = () => setOpenBoxAddCity(true)
    const handleCloseBoxAddCity = () => setOpenBoxAddCity(false)

    const handleChangeNameCity = (event) => {
        setNameCity(event.target.value)
    }

    // Thêm mới thành phố
    const handleCreateCity = (e) => {
        e.preventDefault()
        const data = { name: nameCity }
        createSettingCity(data)
        setNameCity('')
        setOpenBoxAddCity(false)
    }

    // chọn thành phố để setting
    const handleSelectCity = (event) => {
        const selectedCityId = event.target.value

        // console.log(selectedCityId)
        setCityId(selectedCityId)
    }

    // xoá thành phố
    const handleOpenPopoverDelete = (e) => {
        setAnchorElDeleteCity(e.currentTarget)
    }

    const handleCloseBoxDelete = () => {
        setAnchorElDeleteCity(false)
    }

    const handleConfirmDeleteCity = () => {
        request
            .post(delete_city_setting, { _id: cityId })
            .then(() => {
                message.success('Xoá thành phố thành công!')
                setCityId('')
                setAnchorElDeleteCity(false)
                fetchGetAllSettingCitys()
            })
            .catch(() => {
                message.error('Xoá thành phố thất bại!')
                setAnchorElDeleteCity(false)
            })
    }

    //handle submit positon city
    const handleSubmitPostionCity = (value) => {
        const updatedFormData = {
            ...value,
            _id: cityId,
            geometry: [value.lat, value.lng],
        }
        fetchUpdateCity(updatedFormData)
    }

    //handle submit app discount
    const handleSubmitAppDiscount = (values, { resetForm }) => {
        console.log('values app discount', values)

        if (cityId) {
            let data = {
                _id: cityId,
                appDiscount: values.appDiscount / 100,
            }
            console.log('data app discount', data)

            request
                .post(create_app_discount, { ...data })
                .then((res) => {
                    console.log(res)
                    message.success('Tạo phí hoa hồng thành công!')
                    getCitySetting()
                })
                .catch((err) => {
                    console.error(err)
                    message.error('Lỗi: tạo phí hoa hồng thất bại!')
                })
        } else {
            message.error('Thành phố không tồn tại!')
        }
    }

    // style
    const styleBorderPanel = {
        borderTop: '1px solid',
        borderBottom: '1px solid',
        borderColor: '#b6bbbc8a',
        padding: '20px 0px',
        borderRadius: '4px',
    }
    const styleButtonSubmit = {
        bgcolor: theme.palette.mode === 'dark' ? colors.greenAccent[700] : '#3da58a',
        color: 'white',
        fontWeight: '600',
        '&:hover': {
            bgcolor: theme.palette.mode === 'dark' ? colors.greenAccent[600] : 'rgb(61 165 138 / 80%)',
        },
        width: '100px',
        height: '38px',
    }

    return (
        <Box
            m='20px'
            sx={{
                '& .MuiTypography-root.MuiTypography-h2': {
                    margin: '0 0 5px 0',
                },
                '& .MuiInputLabel-outlined, .MuiInputLabel-formControl.Mui-focused': {
                    color: colors.greenAccent[400],
                },
                '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: `${colors.grey[100]} !important`,
                    borderWidth: '1px !important',
                },
                '& .MuiFilledInput-root::after': {
                    borderColor: colors.grey[100],
                },
                '& .MuiFormControl-root': { mb: '20px', minWidth: '200px' },
                '& .MuiFilledInput-root::after, .MuiInput-underline::after': {
                    borderColor: colors.greenAccent[400],
                },
            }}
        >
            {messageContextHolder}
            <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Header title='CÀI ĐẶT' subtitle='Trang cài đặt' />
                <Button
                    sx={{
                        backgroundColor: colors.blueAccent[700],
                        color: 'white',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        padding: '10px 20px',
                        '&:hover': {
                            backgroundColor: colors.blueAccent[600],
                        },
                    }}
                    onClick={(e) => handleOpenBoxAddCity(e)}
                >
                    Thêm Thành Phố
                </Button>
            </Box>
            <Box
                height='auto'
                m='40px 0 0 0'
                p='40px'
                sx={{
                    width: '100%',
                    typography: 'body1',
                    bgcolor: theme.palette.mode === 'dark' ? 'rgb(75 93 131 / 38%)' : '#e7eaff14',
                    borderRadius: '4px',
                    boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
                }}
            >
                <Box
                    display='flex'
                    justifyContent='space-between'
                    sx={{
                        //style label outline input
                        '& .MuiInputLabel-formControl.Mui-focused, .MuiInputLabel-outlined.MuiInputLabel-shrink': {
                            top: '-12px',
                            fontSize: '16px',
                        },
                        '& .MuiInputLabel-outlined': {
                            top: 0,
                            fontSize: '14px',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            top: 0,
                        },
                        '& .MuiOutlinedInput-notchedOutline legend': {
                            display: 'none',
                        },
                    }}
                >
                    <FormControl>
                        <InputLabel id='select-city-label'>Chọn Thành Phố</InputLabel>
                        <Select
                            labelId={'select-city-label'}
                            label={'Chọn Thành Phố'}
                            value={dataSettingCities?.some((city) => city._id === cityId) ? cityId : ''}
                            onChange={handleSelectCity}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        '& .MuiList-root': {
                                            maxHeight: '250px',
                                            overflowY: 'auto',
                                        },
                                    },
                                },
                            }}
                            sx={{
                                background:
                                    theme.palette.mode === 'dark'
                                        ? 'repeating-linear-gradient(45deg, #8c91b3, transparent 100px)'
                                        : 'repeating-linear-gradient(45deg, #b5bef8, transparent 100px)',
                            }}
                        >
                            {dataSettingCities?.map((dataCity) => {
                                return (
                                    <MenuItem key={dataCity._id} value={dataCity._id}>
                                        {dataCity.name}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>

                    {cityId && (
                        <Box>
                            <Tooltip title='Xoá thành phố'>
                                <IconButton onClick={handleOpenPopoverDelete}>
                                    <Delete />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )}
                </Box>

                {cityId && (
                    <Box
                        sx={{
                            ...styleBorderPanel,
                            flexGrow: 1,
                            minHeight: '58vh',
                            display: 'flex',
                        }}
                    >
                        {/* Tab dọc: các menu setting chính */}
                        <Tabs
                            orientation='vertical'
                            variant='scrollable'
                            value={valueTab}
                            onChange={handleChangeTab}
                            sx={{
                                borderRight: 1,
                                borderColor: 'divider',
                                width: '200px',
                                '& .MuiTabs-flexContainer .MuiButtonBase-root': {
                                    fontWeight: '600',
                                },
                                '& .MuiTab-root.Mui-selected': {
                                    color: theme.palette.mode === 'dark' ? '#acb0e6' : colors.blueAccent[400],
                                    bgcolor: theme.palette.mode === 'dark' ? 'rgb(76 87 130 / 35%)' : 'rgb(172 182 220 / 35%)',
                                },
                                '& .MuiTabs-indicator': {
                                    bgcolor: colors.blueAccent[500],
                                },
                            }}
                        >
                            <Tab label='Cài đặt vị trí thành phố' {...a11yProps(0)} />
                            <Tab label='Cài đặt phí hoa hồng' {...a11yProps(1)} />
                        </Tabs>
                        {/* Tab content: nội dung cuả các tab chính */}
                        <TabPanel value={valueTab} index={0}>
                            <Box sx={{ marginBottom: '50px' }}>
                                <Typography marginBottom='10px' fontWeight='600'>
                                    1. Cài đặt vị trí thành phố (Latitude - Longitude):
                                </Typography>
                                <Formik
                                    initialValues={formDataPositionCity}
                                    enableReinitialize={true}
                                    validationSchema={validationLocationSchema}
                                    onSubmit={handleSubmitPostionCity}
                                >
                                    {({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => {
                                        return (
                                            <Form>
                                                <Box
                                                    sx={{
                                                        p: '24px',
                                                        '& .MuiInputLabel-formControl.Mui-error.Mui-focused': {
                                                            // change label textField when focused input
                                                            fontSize: '16px !important',
                                                            color: theme.palette.mode === 'dark' ? '#f44336' : '#d32f2f',
                                                        },
                                                        '& .MuiFormHelperText-root.Mui-error': {
                                                            fontSize: '14px',
                                                        },
                                                    }}
                                                >
                                                    <Box display='flex' flexDirection='column'>
                                                        <TextField
                                                            sx={{ marginRight: '20px' }}
                                                            label='Tên thành phố'
                                                            variant='filled'
                                                            name='name'
                                                            value={values.name}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={!!touched.name && !!errors.name}
                                                            helperText={touched.name && errors.name}
                                                            InputLabelProps={{
                                                                sx: {
                                                                    '&.MuiInputLabel-filled': {
                                                                        fontSize: '14px',
                                                                    },
                                                                },
                                                            }}
                                                        />

                                                        <TextField
                                                            sx={{ marginRight: '20px' }}
                                                            label='Bán kính (m)'
                                                            variant='filled'
                                                            name='cityRadius'
                                                            value={formatNumber(values.cityRadius)}
                                                            onChange={(e) => {
                                                                const formattedValue = parseNumber(e.target.value)
                                                                setFieldValue('cityRadius', formattedValue)
                                                            }}
                                                            onBlur={handleBlur}
                                                            error={!!touched.cityRadius && !!errors.cityRadius}
                                                            helperText={touched.cityRadius && errors.cityRadius}
                                                            InputLabelProps={{
                                                                sx: {
                                                                    '&.MuiInputLabel-filled': {
                                                                        fontSize: '14px',
                                                                    },
                                                                },
                                                            }}
                                                        />

                                                        <TextField
                                                            sx={{ marginRight: '20px' }}
                                                            label='Latitude (vĩ độ)'
                                                            variant='filled'
                                                            name='lat'
                                                            value={values.lat}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={!!touched.lat && !!errors.lat}
                                                            helperText={touched.lat && errors.lat}
                                                            InputLabelProps={{
                                                                sx: {
                                                                    '&.MuiInputLabel-filled': {
                                                                        fontSize: '14px',
                                                                    },
                                                                },
                                                            }}
                                                        />

                                                        <TextField
                                                            sx={{ marginRight: '20px' }}
                                                            label='Longitude (tung độ)'
                                                            variant='filled'
                                                            name='lng'
                                                            value={values.lng}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={!!touched.lng && !!errors.lng}
                                                            helperText={touched.lng && errors.lng}
                                                            InputLabelProps={{
                                                                sx: {
                                                                    '&.MuiInputLabel-filled': {
                                                                        fontSize: '14px',
                                                                    },
                                                                },
                                                            }}
                                                        />
                                                    </Box>
                                                    <Box textAlign='end'>
                                                        <Button
                                                            type='submit'
                                                            sx={{
                                                                bgcolor: theme.palette.mode === 'dark' ? colors.greenAccent[600] : '#3da58a',
                                                                color: 'white',
                                                                fontWeight: '600',
                                                                mr: '20px',
                                                                p: '8px 16px',
                                                                '&:hover': {
                                                                    backgroundColor: '#46b296',
                                                                },
                                                            }}
                                                        >
                                                            Chấp nhận
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            </Form>
                                        )
                                    }}
                                </Formik>
                            </Box>
                        </TabPanel>
                        {/* Setting phí hoa hồng [ADMIN] */}
                        <TabPanel value={valueTab} index={1}>
                            <Box>
                                <Typography fontWeight='600'>2. Cài đặt % hoa hồng:</Typography>
                                <Formik
                                    initialValues={formDataAppDiscount}
                                    enableReinitialize={true}
                                    validationSchema={validationAppDiscountSchema}
                                    onSubmit={handleSubmitAppDiscount}
                                >
                                    {({ values, errors, touched, handleBlur, handleChange }) => {
                                        return (
                                            <Form>
                                                <Box p='0 24px' display='flex' mt='20px'>
                                                    <Box>
                                                        <Box
                                                            sx={{
                                                                '& .MuiInputLabel-formControl.Mui-error.Mui-focused': {
                                                                    // change label textField when focused input
                                                                    fontSize: '16px !important',
                                                                    color: theme.palette.mode === 'dark' ? '#f44336' : '#d32f2f',
                                                                },
                                                                '& .MuiFormHelperText-root.Mui-error': {
                                                                    fontSize: '14px',
                                                                },
                                                            }}
                                                        >
                                                            <TextField
                                                                variant='standard'
                                                                label='Nhập phí hoa hồng (%)'
                                                                name='appDiscount'
                                                                value={values.appDiscount}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                error={!!touched.appDiscount && !!errors.appDiscount}
                                                                helperText={touched.appDiscount && errors.appDiscount}
                                                            ></TextField>
                                                        </Box>
                                                        <Box>
                                                            <Button
                                                                type='submit'
                                                                sx={{
                                                                    ...styleButtonSubmit,
                                                                }}
                                                            >
                                                                Chấp nhận
                                                            </Button>
                                                        </Box>
                                                    </Box>
                                                    <Box flex={0.7} display='flex' alignItems='center' justifyContent='center'>
                                                        <Box
                                                            sx={{
                                                                backgroundColor: theme.palette.mode === 'dark' ? '#424242' : '#f5f5f5',
                                                                border: `2px solid ${theme.palette.mode === 'dark' ? '#00c853' : '#4caf50'}`,
                                                                padding: '16px',
                                                                borderRadius: '8px',
                                                                textAlign: 'center',
                                                                boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
                                                            }}
                                                        >
                                                            <Typography
                                                                // variant='h6'
                                                                fontWeight='600'
                                                                color={theme.palette.mode === 'dark' ? '#00e676' : '#388e3c'}
                                                            >
                                                                Phí hoa hồng đã cài đặt
                                                            </Typography>
                                                            <Typography
                                                                // variant='h4'
                                                                fontWeight='700'
                                                                color={theme.palette.mode === 'dark' ? '#00e676' : '#388e3c'}
                                                            >
                                                                {(values?.appDiscount ?? dataSettingCity?.appDiscount * 100 ?? 0) + '%'}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Form>
                                        )
                                    }}
                                </Formik>
                            </Box>
                        </TabPanel>
                    </Box>
                )}
            </Box>

            <Modal open={openBoxAddCity} onClose={handleCloseBoxAddCity}>
                <Box component='form' onSubmit={handleCreateCity}>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 400,
                            bgcolor: colors.primary[400],
                            border: '2px solid #000',
                            boxShadow: 24,
                            p: 4,
                            border: 'unset',
                        }}
                    >
                        <IconButton
                            sx={{
                                position: 'absolute',
                                top: '10px',
                                right: '20px',
                            }}
                            onClick={handleCloseBoxAddCity}
                        >
                            <Close />
                        </IconButton>
                        <Typography fontSize='16px'>Thêm thành phố</Typography>
                        <Box
                            display='flex'
                            justifyContent='space-around'
                            flexDirection='column'
                            sx={{
                                '& .MuiInputLabel-formControl': {
                                    color: colors.greenAccent[400],
                                },
                                mt: '20px',
                            }}
                        >
                            <TextField color='secondary' label='Tên thành phố' value={nameCity} onChange={handleChangeNameCity} required />
                            <Button
                                type='submit'
                                sx={{
                                    backgroundColor: colors.blueAccent[700],
                                    color: 'white',
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    height: '40px',
                                    width: '100px',
                                    mt: '20px',
                                    alignSelf: 'end',
                                    '&:hover': {
                                        backgroundColor: colors.blueAccent[800],
                                    },
                                }}
                            >
                                Thêm
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>

            {/* delete city */}
            <Popover
                type='delete'
                idRow={cityId}
                anchorEl={anchorElDeleteCity}
                onClose={handleCloseBoxDelete}
                handleConfirm={handleConfirmDeleteCity}
                boxName={'deleteCitySetting'}
            />
        </Box>
    )
}

export default AdminSetting
