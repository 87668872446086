import { useState, useEffect } from 'react'
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar'
import { Box, Button, Drawer, IconButton, Tooltip, Typography, useTheme } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { tokens } from '../../theme'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import PermContactCalendarOutlinedIcon from '@mui/icons-material/PermContactCalendarOutlined'
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined'
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined'
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined'
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined'
import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined'
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined'
import MapOutlinedIcon from '@mui/icons-material/MapOutlined'
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined'
import DiscountOutlinedIcon from '@mui/icons-material/DiscountOutlined'
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined'
import LocationCityIcon from '@mui/icons-material/LocationCity'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'
import FolderSharedOutlinedIcon from '@mui/icons-material/FolderSharedOutlined'
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined'
import { routerLinks } from '../../routes/constant'
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined'
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined'
import PieChartOutlinedIcon from '@mui/icons-material/PieChartOutlined'
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange'
import CloseIcon from '@mui/icons-material/Close'
import './style.scss'
import { OutWallet, InWallet } from './svg'
import request from '../../utils/api/request'
import { get_user_profile } from '../../utils/api/apiList'
import { useSelector } from 'react-redux'
import { getEventUpdateProfileAdminSelector, getEventUploadAvatarSelector } from '../../redux/selectors'
import { baseURL } from '../../utils/api/baseURL'
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined'
import images from '../../assets/images'
import EmojiFlagsOutlinedIcon from '@mui/icons-material/EmojiFlagsOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined'
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined'
import EqualizerOutlinedIcon from '@mui/icons-material/EqualizerOutlined'
import AutoGraphOutlinedIcon from '@mui/icons-material/AutoGraphOutlined'
import StackedLineChartOutlinedIcon from '@mui/icons-material/StackedLineChartOutlined'
import BubbleChartOutlinedIcon from '@mui/icons-material/BubbleChartOutlined'
import ShowChartOutlinedIcon from '@mui/icons-material/ShowChartOutlined'

function SidebarComponent({ isMobile, collapsed, onCollapsed }) {
    const [isCollapsed, setIsCollapsed] = useState(false)
    const [selected, setSelected] = useState('Trang chủ')
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const role = localStorage.getItem('role')
    const location = useLocation()

    // nhận sự kiện upload
    const dataEventUpload = useSelector(getEventUploadAvatarSelector)
    const dataUpdateProfileAdmin = useSelector(getEventUpdateProfileAdminSelector)

    //state data admin account
    const [dataAdminCompany, setDataAdminCompany] = useState(null)
    const idAdminCompany = localStorage.getItem('userId')
    const token = localStorage.getItem('token')

    //active menu
    useEffect(() => {
        const path = location.pathname
        const pathToTitle = {
            [routerLinks.CompanyManagement]: 'Quản lý công ty',
            [routerLinks.Service]: 'Quản lý dịch vụ',
            [routerLinks.PriceList]: 'Quản lý gói',
            [routerLinks.NewOrder]: 'Đơn hàng mới',
            [routerLinks.TaskBooking]: 'Ca lẻ',
            [routerLinks.PermanentTask]: 'Ca cố định',
            [routerLinks.Clean]: 'Tổng vệ sinh',
            [routerLinks.Aircondition]: 'Điều hoà',
            [routerLinks.Sofa]: 'Sofa',
            [routerLinks.Spray]: 'Phun khử khuẩn',
            [routerLinks.Employee]: 'Nhân viên',
            [routerLinks.VerifyEmployee]: 'Xét duyệt',
            [routerLinks.Competence]: 'Năng lực',
            [routerLinks.Deposit]: 'Nạp',
            [routerLinks.Withdraw]: 'Rút',
            [routerLinks.PaymentHistory]: 'Biến động số dư',
            [routerLinks.Discount]: 'Quản lý ưu đãi',
            [routerLinks.Banner]: 'Quản lý banner',
            [routerLinks.Users]: 'Quản lý khách hàng',
            [routerLinks.StatisticsService]: 'Khối lượng dịch vụ',
            [routerLinks.AnalyzesUser]: 'Thống kê khách hàng',
            [routerLinks.AnalyzesPartner]: 'Thống kê partner',
            [routerLinks.CompanyRevenue]: 'Doanh thu công ty',
            [routerLinks.CompanyProfit]: 'Lợi nhuận công ty',
            [routerLinks.AnalyzesDeposit]: 'Tiền nạp cọc',
            [routerLinks.TaskRevenue]: 'Doanh thu ca lẻ',
            [routerLinks.CleanRevenue]: 'Doanh thu tổng vệ sinh',
            [routerLinks.PermanentRevenue]: 'Doanh thu ca cố định',
            [routerLinks.AirRevenue]: 'Doanh thu điều hoà',
            [routerLinks.SofaRevenue]: 'Doanh thu sofa',
            [routerLinks.SprayRevenue]: 'Doanh thu phun khử khuẩn',
            [routerLinks.TaskProfit]: 'Lợi nhuận ca lẻ',
            [routerLinks.CleanProfit]: 'Lợi nhuận tổng vệ sinh',
            [routerLinks.PermanentProfit]: 'Lợi nhuận ca cố định',
            [routerLinks.AirProfit]: 'Lợi nhuận điều hoà',
            [routerLinks.SofaProfit]: 'Lợi nhuận sofa',
            [routerLinks.SprayProfit]: 'Lợi nhuận phun khử khuẩn',
            [routerLinks.StatisticsIncome]: 'Doanh thu',
            [routerLinks.StatisticsTransaction]: 'Giao dịch',
            [routerLinks.StatisticsBankPartner]: 'Quản lý ngân hàng',
            [routerLinks.Form]: 'Profile Form',
            [routerLinks.Calendar]: 'Calendar',
            [routerLinks.Faq]: 'FAQ Page',
            [routerLinks.Bar]: 'Bar Chart',
            [routerLinks.Pie]: 'Pie Chart',
            [routerLinks.Line]: 'Line Chart',
            [routerLinks.Geography]: 'Geography Chart',
            [routerLinks.AdminSetting]: 'Cài đặt thành phố',
            [routerLinks.PolicySetting]: 'Điều khoản dịch vụ',
            [routerLinks.CompanySetting]: 'Cài đặt công việc',
            [routerLinks.SocialsSetting]: 'Cài đặt chung',
            default: 'Trang chủ',
        }
        const selectedTitle = pathToTitle[path] || pathToTitle.default
        setSelected(selectedTitle)
    }, [location])

    //menu item
    const Item = ({ title, to, icon, selected, setSelected }) => {
        const navigate = useNavigate()

        return isCollapsed ? (
            <Tooltip
                title={title}
                arrow
                slotProps={{
                    popper: {
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, -20],
                                },
                            },
                        ],
                    },
                }}
            >
                <Box>
                    <MenuItem
                        active={selected === title}
                        sx={{ color: colors.grey[100] }}
                        onClick={() => {
                            setSelected(title)
                            navigate(to)
                        }}
                        icon={icon}
                    >
                        {title}
                    </MenuItem>
                </Box>
            </Tooltip>
        ) : (
            <MenuItem
                active={selected === title}
                sx={{ color: colors.grey[100] }}
                onClick={() => {
                    setSelected(title)
                    navigate(to)
                }}
                icon={icon}
            >
                {title}
            </MenuItem>
        )
    }

    //fetch data company admin account
    const fetchDataAdminCompany = () => {
        request.setAuthToken(token)
        request
            .post(get_user_profile, { info: { _id: idAdminCompany } })
            .then((res) => {
                const data = res.data.data
                const avatar = res.data.data.avatar
                setDataAdminCompany({ ...data, avatarConfig: baseURL + avatar })
                // console.log({ ...data, avatarConfig: baseURL + avatar, fullNameConfig: data.fullName })
            })
            .catch((err) => console.log(err))
    }

    useEffect(() => {
        fetchDataAdminCompany()
        // if (dataEventUpload) {
        //     fetchDataAdminCompany()
        // }
    }, [dataEventUpload, dataUpdateProfileAdmin])

    const onCollapsedMenu = () => {
        if (isMobile) {
            !!onCollapsed && onCollapsed()
        } else {
            setIsCollapsed(!isCollapsed)
        }
    }
    const renderMenu = (
        <Box
            sx={{
                '& .ps-sidebar-root': {
                    height: '100%',
                },
                '& .ps-sidebar-container': {
                    backgroundColor: colors.primary[400],
                },
                '& .ps-menu-button:hover': {
                    color: '#868dfb !important',
                    cursor: 'pointer',
                },
                '& .ps-menu-button.ps-active': {
                    color: '#6870fa !important',
                    background: theme.palette.mode === 'light' ? 'linear-gradient(to right, #ddd6f3, #ffedbc)' : 'unset',
                },
                '& .ps-submenu-content': {
                    bgcolor: colors.primary[400],
                },
                boxShadow: 'rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px',
                '& .ps-menu-label': {
                    fontSize: '14px !important',
                },
            }}
            className='wrapper-sidebar'
        >
            <Sidebar collapsed={isCollapsed}>
                <Menu>
                    <MenuItem
                        onClick={onCollapsedMenu}
                        icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
                        style={{
                            margin: '10px 0 20px 0',
                            color: colors.grey[100],
                        }}
                    >
                        {!isCollapsed && (
                            <Box display='flex' justifyContent='space-between' alignItems='center' ml='15px'>
                                <Typography variant='h4' color={colors.grey[100]} fontWeight='900'>
                                    {role === 'admin' ? 'ECLEAN ADMIN' : 'ECLEAN COMPANY'}
                                </Typography>
                                <IconButton onClick={onCollapsedMenu}>{isMobile ? <CloseIcon /> : <MenuOutlinedIcon />}</IconButton>
                            </Box>
                        )}
                    </MenuItem>

                    {!isCollapsed && role !== 'admin' && (
                        <Box mb='25px'>
                            <Box display='flex' justifyContent='center' alignItems='center'>
                                <img
                                    alt='profile-user'
                                    src={dataAdminCompany?.avatarConfig || images.placeholderPerson}
                                    // src={dataAdminCompany?.avatarConfig || `${process.env.PUBLIC_URL}/assets/user.jpeg`}
                                    style={{
                                        cursor: 'pointer',
                                        borderRadius: '50%',
                                        objectFit: 'cover',
                                        width: 100,
                                        height: 100,
                                    }}
                                />
                            </Box>
                            <Box textAlign='center'>
                                <Typography variant='h2' color={colors.grey[100]} fontWeight='bold' sx={{ m: '10px 0 0 0' }}>
                                    {dataAdminCompany?.fullName || 'AdminName'}
                                </Typography>
                                <Typography variant='h5' color={colors.greenAccent[500]}>
                                    Doanh nghiệp
                                </Typography>
                            </Box>
                        </Box>
                    )}

                    {!isCollapsed && role === 'admin' && (
                        <Box mb='25px'>
                            <Box display='flex' justifyContent='center' alignItems='center'>
                                <img
                                    alt='icon'
                                    src={images.icon}
                                    style={{
                                        cursor: 'pointer',
                                        borderRadius: '50%',
                                        objectFit: 'cover',
                                        width: 100,
                                        height: 100,
                                    }}
                                />
                            </Box>
                            <Box textAlign='center'>
                                <Typography variant='h2' color={colors.grey[100]} fontWeight='bold' sx={{ m: '10px 0 0 0' }}>
                                    {'Admin'}
                                </Typography>
                            </Box>
                        </Box>
                    )}

                    {/* <Box paddingLeft={isCollapsed ? undefined : '10%'}> */}
                    <Box
                        sx={{
                            '& .ps-menu-button .css-havevq-MuiSvgIcon-root.iconCustom': {
                                fill: 'none',
                                width: '18px',
                                height: '18px',
                            },
                            '& .ps-menu-icon.ps-active': {
                                '& .iconCustom path': { stroke: '#6870fa' },
                            },
                            '& .ps-menu-button:hover ': {
                                '& .iconCustom': {
                                    '& path': { stroke: '#6870fa' },
                                },
                            },
                        }}
                    >
                        <Item title='Trang chủ' to='/' icon={<HomeOutlinedIcon />} selected={selected} setSelected={setSelected} />
                        {role === 'admin' ? (
                            <>
                                <Typography variant='h6' color={colors.grey[300]} sx={{ m: isCollapsed ? '5px 0 5px 20px' : '5px 0 5px 28px' }}>
                                    Quản trị
                                </Typography>
                                <Item
                                    title='Quản lý công ty'
                                    to='/company-management'
                                    icon={<LocationCityIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />
                                <Item
                                    title='Quản lý gói'
                                    to='/price-list'
                                    icon={<PaidOutlinedIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />
                                <Item
                                    title='Quản lý dịch vụ'
                                    to={routerLinks.Service}
                                    icon={<ReceiptLongIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />
                                <Item
                                    title='Quản lý ưu đãi'
                                    to={routerLinks.Discount}
                                    icon={<DiscountOutlinedIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />
                                <Item
                                    title='Quản lý banner'
                                    to={routerLinks.Banner}
                                    icon={<EmojiFlagsOutlinedIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />

                                <Item
                                    title='Quản lý khách hàng'
                                    to={routerLinks.Users}
                                    icon={<PermContactCalendarOutlinedIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />
                                <SubMenu label='Nhân viên Nạp/Rút' icon={<AccountBalanceWalletOutlinedIcon />}>
                                    <Item
                                        title='Nạp'
                                        to={routerLinks.Deposit}
                                        icon={<InWallet strokeColor={theme.palette.mode === 'dark' ? '#fff' : '#000'} />}
                                        selected={selected}
                                        setSelected={setSelected}
                                    />
                                    <Item
                                        title='Rút'
                                        to={routerLinks.Withdraw}
                                        icon={<OutWallet strokeColor={theme.palette.mode === 'dark' ? '#fff' : '#000'} />}
                                        selected={selected}
                                        setSelected={setSelected}
                                    />
                                </SubMenu>
                                <Item
                                    title='Quản lý ngân hàng'
                                    to={routerLinks.StatisticsBankPartner}
                                    icon={<AccountBalanceOutlinedIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />
                                <Item
                                    title='Giao dịch'
                                    to={routerLinks.StatisticsTransaction}
                                    icon={<CurrencyExchangeIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />
                                <Typography variant='h6' color={colors.grey[300]} sx={{ m: isCollapsed ? '5px 0 5px 20px' : '5px 0 5px 28px' }}>
                                    Thống kê
                                </Typography>
                                <Item
                                    title='Doanh thu công ty'
                                    to={routerLinks.CompanyRevenue}
                                    icon={<AutoGraphOutlinedIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />
                                <Item
                                    title='Lợi nhuận công ty'
                                    to={routerLinks.CompanyProfit}
                                    icon={<AutoGraphOutlinedIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />
                                <Item
                                    title='Thống kê khách hàng'
                                    to={routerLinks.AnalyzesUser}
                                    icon={<EqualizerOutlinedIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />
                                <Item
                                    title='Thống kê partner'
                                    to={routerLinks.AnalyzesPartner}
                                    icon={<EqualizerOutlinedIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />
                                <Item
                                    title='Tiền nạp cọc'
                                    to={routerLinks.AnalyzesDeposit}
                                    icon={<ShowChartOutlinedIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />
                                <SubMenu label='Dịch vụ' icon={<StackedLineChartOutlinedIcon />}>
                                    <SubMenu label='Doanh thu' className='styleRevenueProfit'>
                                        <Item
                                            title='Doanh thu ca lẻ'
                                            to={routerLinks.TaskRevenue}
                                            icon={<TimelineOutlinedIcon />}
                                            selected={selected}
                                            setSelected={setSelected}
                                        />
                                        <Item
                                            title='Doanh thu tổng vệ sinh'
                                            to={routerLinks.CleanRevenue}
                                            icon={<TimelineOutlinedIcon />}
                                            selected={selected}
                                            setSelected={setSelected}
                                        />
                                        <Item
                                            title='Doanh thu ca cố định'
                                            to={routerLinks.PermanentRevenue}
                                            icon={<TimelineOutlinedIcon />}
                                            selected={selected}
                                            setSelected={setSelected}
                                        />
                                        <Item
                                            title='Doanh thu điều hoà'
                                            to={routerLinks.AirRevenue}
                                            icon={<TimelineOutlinedIcon />}
                                            selected={selected}
                                            setSelected={setSelected}
                                        />
                                        <Item
                                            title='Doanh thu sofa'
                                            to={routerLinks.SofaRevenue}
                                            icon={<TimelineOutlinedIcon />}
                                            selected={selected}
                                            setSelected={setSelected}
                                        />
                                        <Item
                                            title='Doanh thu phun khử khuẩn'
                                            to={routerLinks.SprayRevenue}
                                            icon={<TimelineOutlinedIcon />}
                                            selected={selected}
                                            setSelected={setSelected}
                                        />
                                    </SubMenu>
                                    <SubMenu label='Lợi nhuận' className='styleRevenueProfit'>
                                        <Item
                                            title='Lợi nhuận ca lẻ'
                                            to={routerLinks.TaskProfit}
                                            icon={<TimelineOutlinedIcon />}
                                            selected={selected}
                                            setSelected={setSelected}
                                        />
                                        <Item
                                            title='Lợi nhuận tổng vệ sinh'
                                            to={routerLinks.CleanProfit}
                                            icon={<TimelineOutlinedIcon />}
                                            selected={selected}
                                            setSelected={setSelected}
                                        />
                                        <Item
                                            title='Lợi nhuận ca cố định'
                                            to={routerLinks.PermanentProfit}
                                            icon={<TimelineOutlinedIcon />}
                                            selected={selected}
                                            setSelected={setSelected}
                                        />
                                        <Item
                                            title='Lợi nhuận điều hoà'
                                            to={routerLinks.AirProfit}
                                            icon={<TimelineOutlinedIcon />}
                                            selected={selected}
                                            setSelected={setSelected}
                                        />
                                        <Item
                                            title='Lợi nhuận sofa'
                                            to={routerLinks.SofaProfit}
                                            icon={<TimelineOutlinedIcon />}
                                            selected={selected}
                                            setSelected={setSelected}
                                        />
                                        <Item
                                            title='Lợi nhuận phun khử khuẩn'
                                            to={routerLinks.SprayProfit}
                                            icon={<TimelineOutlinedIcon />}
                                            selected={selected}
                                            setSelected={setSelected}
                                        />
                                    </SubMenu>
                                </SubMenu>

                                <Typography variant='h6' color={colors.grey[300]} sx={{ m: isCollapsed ? '5px 0 5px 20px' : '5px 0 5px 28px' }}>
                                    Cài đặt
                                </Typography>
                                <Item
                                    title='Điều khoản dịch vụ'
                                    to={routerLinks.PolicySetting}
                                    icon={<InventoryOutlinedIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />
                                <Item
                                    title='Cài đặt thành phố'
                                    to={routerLinks.AdminSetting}
                                    icon={<SettingsOutlinedIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />
                                <Item
                                    title='Cài đặt chung'
                                    to={routerLinks.SocialsSetting}
                                    icon={<BubbleChartOutlinedIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />
                            </>
                        ) : (
                            <>
                                <Typography variant='h6' color={colors.grey[300]} sx={{ m: '15px 0 5px 20px' }}>
                                    Doanh nghiệp
                                </Typography>

                                <SubMenu label='Quản lý đơn hàng' icon={<LocalMallOutlinedIcon />}>
                                    {/* <Item
                                        title='Đơn hàng mới'
                                        to='/new-order'
                                        icon={<WorkOutlineOutlinedIcon />}
                                        selected={selected}
                                        setSelected={setSelected}
                                    /> */}
                                    <Item
                                        title='Ca lẻ'
                                        to={routerLinks.TaskBooking}
                                        icon={<WorkOutlineOutlinedIcon />}
                                        selected={selected}
                                        setSelected={setSelected}
                                    />
                                    <Item
                                        title='Ca cố định'
                                        to={routerLinks.PermanentTask}
                                        icon={<WorkOutlineOutlinedIcon />}
                                        selected={selected}
                                        setSelected={setSelected}
                                    />
                                    <Item
                                        title='Tổng vệ sinh'
                                        to={routerLinks.Clean}
                                        icon={<WorkOutlineOutlinedIcon />}
                                        selected={selected}
                                        setSelected={setSelected}
                                    />
                                    <Item
                                        title='Điều hoà'
                                        to={routerLinks.Aircondition}
                                        icon={<WorkOutlineOutlinedIcon />}
                                        selected={selected}
                                        setSelected={setSelected}
                                    />
                                    <Item
                                        title='Sofa'
                                        to={routerLinks.Sofa}
                                        icon={<WorkOutlineOutlinedIcon />}
                                        selected={selected}
                                        setSelected={setSelected}
                                    />
                                    <Item
                                        title='Phun khử khuẩn'
                                        to={routerLinks.Spray}
                                        icon={<WorkOutlineOutlinedIcon />}
                                        selected={selected}
                                        setSelected={setSelected}
                                    />
                                </SubMenu>
                                <SubMenu label='Quản lý nhân viên' icon={<ContactsOutlinedIcon />}>
                                    <Item
                                        title='Xét duyệt'
                                        to={routerLinks.VerifyEmployee}
                                        icon={<HowToRegOutlinedIcon />}
                                        selected={selected}
                                        setSelected={setSelected}
                                    />
                                    <Item
                                        title='Nhân viên'
                                        to={routerLinks.Employee}
                                        icon={<FolderSharedOutlinedIcon />}
                                        selected={selected}
                                        setSelected={setSelected}
                                    />
                                    {/* <Item
                                        title='Năng lực'
                                        to={routerLinks.Competence}
                                        icon={<TrendingUpOutlinedIcon />}
                                        selected={selected}
                                        setSelected={setSelected}
                                    /> */}
                                    {/* <SubMenu label='Nạp/Rút' icon={<AccountBalanceWalletOutlinedIcon />}>
                                        <Item
                                            title='Nạp'
                                            to={routerLinks.Deposit}
                                            icon={<InWallet strokeColor={theme.palette.mode === 'dark' ? '#fff' : '#000'} />}
                                            selected={selected}
                                            setSelected={setSelected}
                                        />
                                        <Item
                                            title='Rút'
                                            to={routerLinks.Withdraw}
                                            icon={<OutWallet strokeColor={theme.palette.mode === 'dark' ? '#fff' : '#000'} />}
                                            selected={selected}
                                            setSelected={setSelected}
                                        />
                                    </SubMenu> */}
                                    {/* <Item
                                        title='Biến động số dư'
                                        to={routerLinks.PaymentHistory}
                                        icon={<CurrencyExchangeIcon />}
                                        selected={selected}
                                        setSelected={setSelected}
                                    /> */}
                                </SubMenu>
                                <Item
                                    title='Dịch vụ'
                                    to={routerLinks.Service}
                                    icon={<ReceiptLongIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />
                                <Item
                                    title='Ưu đãi'
                                    to={routerLinks.Discount}
                                    icon={<DiscountOutlinedIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />
                                <Item
                                    title='Banner'
                                    to={routerLinks.Banner}
                                    icon={<EmojiFlagsOutlinedIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />
                                <Typography variant='h6' color={colors.grey[300]} sx={{ m: '15px 0 5px 20px' }}>
                                    Thống kê
                                </Typography>
                                <Typography variant='h6' color={colors.grey[300]} sx={{ m: '15px 0 5px 20px' }}>
                                    Cài đặt
                                </Typography>
                                <Item
                                    title='Cài đặt người dùng'
                                    to={routerLinks.CompanyAccountSetting}
                                    icon={<ManageAccountsOutlinedIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />
                                <Item
                                    title='Cài đặt công việc'
                                    to={routerLinks.CompanySetting}
                                    icon={<SettingsOutlinedIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />
                                {/* <Item
                                    title='Khối lượng dịch vụ'
                                    to='/service-volume-statistics'
                                    icon={<PieChartOutlinedIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />
                                <Item
                                    title='Số lượng user'
                                    to={routerLinks.StatisticsUser}
                                    icon={<BarChartOutlinedIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />
                                <Item
                                    title='Doanh thu'
                                    to={routerLinks.StatisticsIncome}
                                    icon={<BarChartOutlinedIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                /> */}
                            </>
                        )}

                        {/* <Typography variant='h6' color={colors.grey[300]} sx={{ m: '15px 0 5px 20px' }}>
                            Biểu mẫu
                        </Typography>
                        <Item title='Profile Form' to='/form' icon={<PersonOutlinedIcon />} selected={selected} setSelected={setSelected} />
                        <Item title='Calendar' to='/calendar' icon={<CalendarTodayOutlinedIcon />} selected={selected} setSelected={setSelected} />
                        <Item title='FAQ Page' to='/faq' icon={<HelpOutlineOutlinedIcon />} selected={selected} setSelected={setSelected} />

                        <Typography variant='h6' color={colors.grey[300]} sx={{ m: '15px 0 5px 20px' }}>
                            Charts
                        </Typography>

                        <Item title='Line Chart' to='/line' icon={<TimelineOutlinedIcon />} selected={selected} setSelected={setSelected} />
                        <Item title='Geography Chart' to='/geography' icon={<MapOutlinedIcon />} selected={selected} setSelected={setSelected} /> */}
                    </Box>
                </Menu>
            </Sidebar>
        </Box>
    )
    return isMobile ? (
        <Drawer open={collapsed} onClose={onCollapsed}>
            {renderMenu}
        </Drawer>
    ) : (
        renderMenu
    )
}

export default SidebarComponent
