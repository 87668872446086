import React, { useState, useRef, useEffect } from 'react'
import { Box, Divider, TextField, Typography, useTheme, Button as ButtonMui, IconButton, CircularProgress, InputAdornment } from '@mui/material'
import Header from '../../../components/Header'
import { tokens } from '../../../theme'
import { Avatar, Button, Modal, Upload, Slider } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import AvatarEditor from 'react-avatar-editor'
import 'antd/dist/reset.css'
import { Formik } from 'formik'
import * as yup from 'yup'
import request from '../../../utils/api/request'
import { get_user_profile, update_profile, user_change_password } from '../../../utils/api/apiList'
import { Email, Lock, Person, Phone, Visibility, VisibilityOff } from '@mui/icons-material'
import openMessageNotification from '../../../components/notification/message'
import { message } from 'antd'
import { baseURL } from '../../../utils/api/baseURL'
import { useDispatch } from 'react-redux'
import getEventUploadAvatarSlice from '../../../redux/features/getEventUploadAvatarSlice'
import getEventUpdateProfileAdminSlice from '../../../redux/features/getEventUpdateProfileAdminSlice'
import { phoneRegExp } from '../../../components/prefixData'

//validate
const passRegExp = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/

const profileSchema = yup.object().shape({
    fullName: yup.string().required('Không được để trống!'),
    email: yup.string().email('Email không hợp lệ!').required('Không được để trống!'),
    phoneNumber: yup.string().matches(phoneRegExp, 'Số điện thoại không hợp lệ!').required('Không được để trống!'),
})

const passwordSchema = yup.object().shape({
    password: yup
        .string()
        .min(8, 'Mật khẩu không đúng')
        .matches(passRegExp, 'Mật khẩu tối thiểu là 8 ký tự bao gồm chữ và số')
        .required('Không được để trống!'),
    newPassword: yup
        .string()
        .min(8, 'Mật khẩu mới tối thiểu là 8 ký tự bao gồm chữ và số')
        .matches(passRegExp, 'Mật khẩu tối thiểu là 8 ký tự bao gồm chữ và số')
        .notOneOf([yup.ref('password')], 'Mật khẩu mới không được trùng với mật khẩu hiện tại')
        .required('Không được để trống!'),
    confirmPassword: yup
        .string()
        .oneOf([yup.ref('newPassword'), null], 'Mật khẩu mới không khớp!')
        .required('Không được để trống!'),
})

function CompanyAccountInfo() {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const [infoAdminCompany, setInfoAdminCompany] = useState(null)
    const [initialProfileValues, setInitialProfileValues] = useState({
        fullName: '',
        email: '',
        phoneNumber: '',
    })
    const [initialPasswordValues, setInitialPasswordValues] = useState({
        password: '',
        newPassword: '',
        confirmPassword: '',
    })
    const idAdminCompany = localStorage.getItem('userId')
    const token = localStorage.getItem('token')

    const [loadingProfile, setLoadingProfile] = useState(null)
    const [loadingChangePassword, setLoadingChangePassword] = useState(null)

    //state redux
    const dispatch = useDispatch()

    //show pass
    const [showPassword, setShowPassword] = useState(false)
    const [showNewPassword, setShowNewPassword] = useState(false)
    const [showRenewPassword, setShowRenewPassword] = useState(false)
    const handleClickShowPassword = () => setShowPassword(!showPassword)
    const handleClickShowNewPassword = () => setShowNewPassword(!showNewPassword)
    const handleClickShowRenewPassword = () => setShowRenewPassword(!showRenewPassword)

    // upload antd
    const [uploadVisible, setUploadVisible] = useState(false)
    const [viewVisible, setViewVisible] = useState(false)
    const [imageUrl, setImageUrl] = useState('')
    const [previewImage, setPreviewImage] = useState('')
    const [scale, setScale] = useState(1)
    const editorRef = useRef(null)
    const [errorFile, setErrorFile] = useState(null)

    //message
    const [messageApi, messageContextHolder] = message.useMessage()

    //validate upload
    const validateFileType = (file) => {
        const allowedTypes = ['image/jpeg', 'image/png'] // Các loại file cho phép
        if (!allowedTypes.includes(file.type)) {
            message.error('File ảnh bạn đã tải lên không hợp lệ, định dạng được chấp nhận: JPG, PNG.')
            setErrorFile(true)
            return Upload.LIST_IGNORE // Ngăn không cho file được upload
        }
        return true // Cho phép upload nếu file hợp lệ
    }

    //fetchData Account
    const fetchData = () => {
        request.setAuthToken(token)
        request
            .post(get_user_profile, { info: { _id: idAdminCompany } })
            .then((res) => {
                const data = res.data.data
                console.log(data)
                const avatar = baseURL + data.avatar

                setInfoAdminCompany({ ...data, avatarConfig: avatar })

                setInitialProfileValues({
                    fullName: data.fullName || '',
                    email: data.email || '',
                    phoneNumber: data.phoneNumber || '',
                })
            })
            .catch((err) => console.log(err))
    }

    useEffect(() => {
        fetchData()
    }, [])

    //fetch change password
    const fetchChangePassword = (data, resetForm) => {
        request
            .post(user_change_password, { ...data })
            .then((res) => {
                openMessageNotification(messageApi, 'success', 'changePasswordAccountCompany', infoAdminCompany?.fullName)
                fetchData()
                resetForm()
            })
            .catch((err) => {
                const messErr = err.response.data.data
                openMessageNotification(messageApi, 'error', 'changePasswordAccountCompany', messErr)
                console.log(err)
            })
            .finally(() => setLoadingChangePassword(false))
    }

    //updateProfile
    const fecthUpdateProfile = (data, resetForm, setFieldError) => {
        request
            .post(update_profile, { userInfo: { ...data } })
            .then((res) => {
                fetchData()
                openMessageNotification(messageApi, 'success', 'updateProfileAccountCompany', infoAdminCompany?.fullName)
                dispatch(getEventUpdateProfileAdminSlice.actions.eventUpdateProfileAdmin(res.data))
            })
            .catch((err) => {
                const textErr = err.response.data.data
                switch (textErr) {
                    case 'PhoneInUseInPartnerApp':
                    case 'PhoneInUseInUserApp':
                    case 'phoneInUse':
                        message.error('Số điện thoại đã được đăng ký!')
                        setFieldError('phoneNumber', 'Số điện thoại đã được đăng ký!')
                        break
                    default:
                        message.error('Cập nhật hồ sơ admin thất bại, vui lòng thử lại!')
                        break
                }

                console.error('Lỗi cập nhật profile doanhnghiep', err)
            })
            .finally(() => setLoadingProfile(false))
    }

    //upload
    const handleUpload = ({ file }) => {
        const reader = new FileReader()
        reader.onload = () => {
            setPreviewImage(reader.result)
            setScale(1) // Reset scale when a new image is uploaded
        }
        reader.readAsDataURL(file)
    }

    const handleOk = () => {
        if (editorRef.current) {
            const canvas = editorRef.current.getImage()
            canvas.toBlob((blob) => {
                const formData = new FormData()
                formData.append('file', blob, 'avatar.jpg')

                const myHeaders = new Headers()
                myHeaders.append('Authorization', `Bearer ${token}`)

                fetch(baseURL + 'users/upload_avatar', {
                    method: 'POST',
                    headers: myHeaders,
                    body: formData,
                    redirect: 'follow',
                })
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.success) {
                            // const avatar = baseURL + 'uploads/' + result.data
                            // console.log(result)

                            const avatar = result.data
                            const dataForm = {
                                avatar,
                            }
                            const dataEventUpload = { data: infoAdminCompany, type: result.success }
                            fecthUpdateProfile(dataForm)
                            dispatch(getEventUploadAvatarSlice.actions.eventUpload(dataEventUpload))
                        }
                        setImageUrl(URL.createObjectURL(blob))
                        setUploadVisible(false)
                    })
                    .catch((error) => {
                        console.error('Error uploading avatar:', error)
                    })
            }, 'image/jpeg')
        }
    }

    const handleCancel = () => {
        setUploadVisible(false)
        setPreviewImage(null)
        setErrorFile(false)
    }

    const handleSliderChange = (value) => {
        setScale(value)
    }

    //submit profile
    const handleSubmitProfile = (values, { resetForm, setFieldError }) => {
        const dataForm = {
            ...values,
            // fromCompany: infoAdminCompany?._id,
        }
        // console.log(dataForm)

        setLoadingProfile(true)
        const timer = setTimeout(() => {
            fecthUpdateProfile(dataForm, resetForm, setFieldError)
        }, 500)

        return () => clearTimeout(timer)
    }

    //submit change passsword
    const handleSubmitPassword = (values, { resetForm }) => {
        const dataForm = {
            ...values,
            phoneNumber: infoAdminCompany?.phoneNumber,
        }

        delete dataForm.confirmPassword

        setLoadingChangePassword(true)

        const timer = setTimeout(() => {
            fetchChangePassword(dataForm, resetForm)
        }, 500)

        return () => clearTimeout(timer)
    }

    return (
        <Box
            m='20px'
            sx={{
                '& .MuiTypography-root.MuiTypography-h2': {
                    margin: '0 0 5px 0',
                },
                '& .MuiInputLabel-outlined, .MuiInputLabel-formControl.Mui-focused': {
                    color: `${colors.greenAccent[400]}`,
                },
                '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: `${colors.grey[100]} !important`,
                    borderWidth: '1px !important',
                },
                '& .MuiFilledInput-root::after': {
                    borderColor: colors.grey[100],
                },
                '& .MuiFormControl-root': { mb: '20px', minWidth: '200px' },
                '& .MuiFilledInput-root::after, .MuiInput-underline::after': {
                    borderColor: colors.greenAccent[400],
                },
            }}
        >
            {messageContextHolder}
            <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Header title='THÔNG TIN TÀI KHOẢN' subtitle='Cập nhật ảnh và thông tin cá nhân tại đây.' />
            </Box>
            <Box
                height='auto'
                m='40px 0 0 0'
                p='40px'
                minHeight='75vh'
                sx={{
                    width: '100%',
                    typography: 'body1',
                    bgcolor: theme.palette.mode === 'dark' ? 'rgb(75 93 131 / 38%)' : '#ffffff9c',
                    borderRadius: '4px',
                    boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
                }}
            >
                <Box>
                    <Box display='flex' gap='40px' alignItems='center'>
                        <Box style={{ position: 'relative', display: 'inline-block' }}>
                            <Avatar
                                src={imageUrl || infoAdminCompany?.avatarConfig}
                                size={128}
                                onClick={() => setViewVisible(true)}
                                style={{ cursor: 'pointer' }}
                            />
                            <Button
                                style={{ position: 'absolute', bottom: 0, right: 0 }}
                                icon={<UploadOutlined />}
                                onClick={() => setUploadVisible(true)}
                            />
                        </Box>
                        <Box>
                            <Typography variant='h5' fontWeight='600'>
                                {infoAdminCompany?.fullName}
                            </Typography>
                            <Typography color={colors.grey[400]}>admin</Typography>
                        </Box>
                    </Box>
                    <Box mt='40px'>
                        <Formik
                            onSubmit={(values, { setFieldError, resetForm }) => handleSubmitProfile(values, { setFieldError, resetForm })}
                            initialValues={initialProfileValues}
                            enableReinitialize={true}
                            validationSchema={profileSchema}
                        >
                            {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                                <form onSubmit={handleSubmit}>
                                    <Box
                                        sx={{
                                            '& .MuiInputLabel-root.MuiInputLabel-formControl': {
                                                fontSize: '14px', // default size when no data and not focused
                                            },
                                            '& .MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-shrink': {
                                                fontSize: '16px', // size when there is data or focused
                                            },
                                            '& .MuiInputLabel-formControl.Mui-error.Mui-focused': {
                                                fontSize: '16px !important',
                                                color: theme.palette.mode === 'dark' ? '#f44336' : '#d32f2f',
                                            },
                                            '& .MuiFormHelperText-root.Mui-error': {
                                                fontSize: '14px',
                                            },
                                            '& fieldset': {
                                                fontSize: '16px !important',
                                            },
                                        }}
                                    >
                                        <Typography variant='h5' fontWeight='600' ml='15%'>
                                            Cập nhật hồ sơ
                                        </Typography>
                                        <Box mt='20px' display='flex' flexDirection='column' maxWidth='600px' ml='30%'>
                                            <TextField
                                                sx={{ mr: '20px' }}
                                                variant='outlined'
                                                label='Tên'
                                                name='fullName'
                                                value={values.fullName || ''}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                error={!!touched.fullName && !!errors.fullName}
                                                helperText={touched.fullName && errors.fullName}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position='start'>
                                                            <Person sx={{ mr: 2 }} />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            <TextField
                                                sx={{ mr: '20px' }}
                                                variant='outlined'
                                                label='Email'
                                                type='text'
                                                name='email'
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.email || ''}
                                                error={!!touched.email && !!errors.email}
                                                helperText={touched.email && errors.email}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position='start'>
                                                            <Email sx={{ mr: 2 }} />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            <TextField
                                                sx={{ mr: '20px' }}
                                                variant='outlined'
                                                label='Số điện thoại'
                                                name='phoneNumber'
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.phoneNumber || ''}
                                                disabled
                                                helperText={'Số điện thoại là duy nhất, không thể chỉnh sửa!'}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position='start'>
                                                            <Phone sx={{ mr: 2 }} />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                // error={!!touched.phoneNumber && !!errors.phoneNumber}
                                                // helperText={touched.phoneNumber && errors.phoneNumber}
                                                // helperText={
                                                //     touched.phoneNumber && errors.phoneNumber
                                                //         ? errors.phoneNumber
                                                //         : 'Có thể chỉnh sửa, Số điện thoại là duy nhất!'
                                                // }
                                            />
                                        </Box>
                                        <ButtonMui
                                            sx={{
                                                ml: '30%',
                                                background: theme.palette.mode === 'dark' ? '#138567' : '#3da58a',
                                                fontWeight: '600',
                                                width: '74px',
                                                '&:hover': {
                                                    bgcolor: '#188b6d',
                                                },
                                            }}
                                            type='submit'
                                            variant='contained'
                                        >
                                            {loadingProfile ? <CircularProgress size={24} color='inherit' /> : 'Lưu'}
                                        </ButtonMui>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                        <Divider sx={{ ml: '10%', mr: '10%', mt: '20px' }} />
                        <Formik
                            onSubmit={handleSubmitPassword}
                            initialValues={initialPasswordValues}
                            enableReinitialize={true}
                            validationSchema={passwordSchema}
                        >
                            {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                                <form onSubmit={handleSubmit}>
                                    <Box
                                        mt='20px'
                                        sx={{
                                            '& .MuiInputLabel-root.MuiInputLabel-formControl': {
                                                fontSize: '14px', // default size when no data and not focused
                                            },
                                            '& .MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-shrink': {
                                                fontSize: '16px', // size when there is data or focused
                                            },
                                            '& .MuiInputLabel-formControl.Mui-error.Mui-focused': {
                                                fontSize: '16px !important',
                                                color: theme.palette.mode === 'dark' ? '#f44336' : '#d32f2f',
                                            },
                                            '& .MuiFormHelperText-root.Mui-error': {
                                                fontSize: '14px',
                                            },
                                            '& fieldset': {
                                                fontSize: '16px !important',
                                            },
                                        }}
                                    >
                                        <Typography variant='h5' fontWeight='600' ml='15%'>
                                            Cập nhật mật khẩu
                                        </Typography>
                                        <Box mt='20px' display='flex' flexDirection='column' maxWidth='600px' ml='30%'>
                                            <TextField
                                                sx={{ mr: '20px' }}
                                                variant='outlined'
                                                label='Mật khẩu hiện tại'
                                                name='password'
                                                type={showPassword ? 'text' : 'password'}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.password || ''}
                                                error={!!touched.password && !!errors.password}
                                                helperText={touched.password && errors.password}
                                                InputProps={{
                                                    endAdornment: (
                                                        <IconButton tabIndex={-1} onClick={handleClickShowPassword}>
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    ),
                                                }}
                                            />
                                            <TextField
                                                sx={{ mr: '20px' }}
                                                variant='outlined'
                                                label='Mật khẩu mới'
                                                name='newPassword'
                                                type={showNewPassword ? 'text' : 'password'}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.newPassword || ''}
                                                error={!!touched.newPassword && !!errors.newPassword}
                                                helperText={touched.newPassword && errors.newPassword}
                                                // helperText={
                                                //     touched.newPassword && errors.newPassword
                                                //         ? errors.newPassword
                                                //         : 'Mật khẩu tối thiểu là 8 ký tự bao gồm chữ và số'
                                                // }
                                                InputProps={{
                                                    endAdornment: (
                                                        <IconButton tabIndex={-1} onClick={handleClickShowNewPassword}>
                                                            {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    ),
                                                }}
                                            />
                                            <TextField
                                                sx={{ mr: '20px' }}
                                                variant='outlined'
                                                label='Xác nhận mật khẩu mới'
                                                name='confirmPassword'
                                                type={showRenewPassword ? 'text' : 'password'}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.confirmPassword || ''}
                                                error={!!touched.confirmPassword && !!errors.confirmPassword}
                                                helperText={touched.confirmPassword && errors.confirmPassword}
                                                InputProps={{
                                                    endAdornment: (
                                                        <IconButton tabIndex={-1} onClick={handleClickShowRenewPassword}>
                                                            {showRenewPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    ),
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                    <ButtonMui
                                        sx={{
                                            ml: '30%',
                                            background: theme.palette.mode === 'dark' ? '#138567' : '#3da58a',
                                            fontWeight: '600',
                                            width: '74px',
                                            '&:hover': {
                                                bgcolor: '#188b6d',
                                            },
                                        }}
                                        type='submit'
                                        variant='contained'
                                    >
                                        {loadingChangePassword ? <CircularProgress size={24} color='inherit' /> : 'Lưu'}
                                    </ButtonMui>
                                </form>
                            )}
                        </Formik>
                    </Box>
                </Box>
            </Box>

            <Modal
                open={uploadVisible}
                title='Upload Avatar'
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key='back' onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key='submit' type='primary' onClick={handleOk} className='buttonAntdPrimary'>
                        Upload
                    </Button>,
                ]}
            >
                <Upload
                    showUploadList={false}
                    beforeUpload={(file) => {
                        // Chỉ upload nếu file hợp lệ
                        if (validateFileType(file)) {
                            handleUpload({ file })
                        }
                        return false // Chặn upload mặc định của Antd
                    }}
                >
                    <Button icon={<UploadOutlined />}>Select Image</Button>
                </Upload>
                {previewImage && !errorFile && (
                    <Box
                        sx={{
                            marginTop: '16px',
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            width: '100%',
                        }}
                    >
                        <Box display='flex' width={'100%'}>
                            <AvatarEditor
                                ref={editorRef}
                                image={previewImage}
                                width={370}
                                height={370}
                                border={50}
                                borderRadius={999}
                                scale={scale}
                                rotate={0}
                            />
                        </Box>
                        <Slider min={1} max={3} step={0.01} value={scale} onChange={handleSliderChange} style={{ marginTop: 16, width: '100%' }} />
                    </Box>
                )}
            </Modal>
            <Modal open={viewVisible} title='View Avatar' footer={null} onCancel={() => setViewVisible(false)}>
                <img alt='Avatar' style={{ width: '100%' }} src={imageUrl || infoAdminCompany?.avatarConfig} />
            </Modal>
        </Box>
    )
}

export default CompanyAccountInfo
