import { Box, Button, IconButton, Typography, useTheme } from '@mui/material'
import { tokens } from '../../../theme'
import { mockTransactions } from '../../../data/mockData'
import Header from '../../../components/Header'
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined'
import EmailIcon from '@mui/icons-material/Email'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import BarChart from '../../../components/BarChart'
import ProgressCircle from '../../../components/ProgressCircle'
import StatBox from '../../../components/StatBox'
import HailIcon from '@mui/icons-material/Hail'
import GroupIcon from '@mui/icons-material/Group'

function CompanyDashboard() {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    const dataRow1 = [
        {
            title: '12,361',
            subtitle: 'Tài khoản đăng ký mới',
            progress: '0.75',
            increase: '+14%',
            icon: <PersonAddIcon sx={{ color: colors.greenAccent[600], fontSize: '26px' }} />,
        },
        {
            title: '431,225',
            subtitle: 'Người lao động',
            progress: '0.5',
            increase: '+21%',
            icon: <HailIcon sx={{ color: colors.greenAccent[600], fontSize: '26px' }} />,
        },
        {
            title: '32,441',
            subtitle: 'Tổng số người dùng',
            progress: '0.30',
            increase: '+5%',
            icon: <GroupIcon sx={{ color: colors.greenAccent[600], fontSize: '26px' }} />,
        },
        // {
        //     title: '1,325,134',
        //     subtitle: 'Traffic Received',
        //     progress: '0.80',
        //     increase: '+43%',
        //     icon: <TrafficIcon sx={{ color: colors.greenAccent[600], fontSize: '26px' }} />,
        // },
    ]

    return (
        <Box
            m='20px'
            sx={{
                '& .MuiTypography-root.MuiTypography-h2': {
                    margin: '0 0 5px 0',
                },
            }}
        >
            {/* HEADER */}
            <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Header title='TRANG CHỦ' subtitle='Chào mừng đến với trang quản lý cho doanh nghiệp' />

                <Box
                    sx={{
                        '& .css-q34qwa-MuiButtonBase-root-MuiButton-root:hover': {
                            bgcolor: colors.primary[400],
                        },
                        '& .css-3mc8sk-MuiButtonBase-root-MuiButton-root:hover': {
                            bgcolor: 'rgba(4, 5, 9, 0.04)',
                            color: 'black',
                        },
                    }}
                >
                    {/* <Button
                        sx={{
                            backgroundColor: colors.blueAccent[700],
                            color: 'white',
                            fontSize: '14px',
                            fontWeight: 'bold',
                            padding: '10px 20px',
                        }}
                    >
                        <DownloadOutlinedIcon sx={{ mr: '10px' }} />
                        TẢI BÁO CÁO
                    </Button> */}
                </Box>
            </Box>

            {/* GRID & CHARTS */}
            <Box
                display='grid'
                gridTemplateColumns='repeat(12, 1fr)'
                gridAutoRows='140px'
                gap='20px'
                marginTop='30px'
                sx={{
                    '& .boxShadowStyle': {
                        boxShadow: 'rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px',
                    },
                }}
            >
                {/* ROW 1 */}
                {dataRow1.map((data, i) => (
                    <Box
                        key={i}
                        gridColumn='span 4'
                        bgcolor={colors.primary[400]}
                        display='flex'
                        alignItems='center'
                        justifyContent='center'
                        className='boxShadowStyle'
                    >
                        <StatBox title={data.title} subtitle={data.subtitle} increase={data.increase} icon={data.icon} progress={data.progress} />
                    </Box>
                ))}

                {/* ROW 2 */}
                <Box gridColumn='span 8' gridRow='span 3' bgcolor={colors.primary[400]} className='boxShadowStyle'>
                    <Box mt='25px' p='0 30px' display='flex' justifyContent='space-between' alignItems='center'>
                        <Box display='flex' flexDirection='column'>
                            <Typography variant='h5' fontWeight='600' color={colors.grey[100]}>
                                Thống Kê Doanh Thu
                            </Typography>
                            <Typography variant='h3' fontWeight='700' color={colors.greenAccent[400]}>
                                59,342.32 VNĐ
                            </Typography>
                        </Box>
                        {/* <Box>
                            <IconButton>
                                <DownloadOutlinedIcon
                                    sx={{
                                        fontSize: '26px',
                                        color: colors.greenAccent[500],
                                    }}
                                />
                            </IconButton>
                        </Box> */}
                    </Box>
                    <Box height='88%' mt='-20px'>
                        {/* <BarChart isDashboard={true} /> */}
                    </Box>
                </Box>

                {/* TRANSACTIONS */}
                <Box gridColumn='span 4' gridRow='span 5' bgcolor={colors.primary[400]} overflow='auto' className='boxShadowStyle'>
                    <Box
                        display='flex'
                        justifyContent='space-between'
                        alignItems='center'
                        borderBottom={`4px solid ${colors.primary[500]}`}
                        color={colors.grey[100]}
                        p='15px'
                    >
                        <Typography variant='h5' fontWeight='600' color={colors.grey[100]}>
                            Đơn Đặt Hàng
                        </Typography>
                    </Box>
                    {mockTransactions.map((transaction, i) => {
                        return (
                            <Box
                                key={`${transaction.txId}-${i}`}
                                display='flex'
                                justifyContent='space-between'
                                alignItems='center'
                                borderBottom={`4px solid ${colors.primary[400]}`}
                                p='15px'
                            >
                                <Box>
                                    <Typography variant='h5' fontWeight='600' color={colors.greenAccent[400]}>
                                        {transaction.txId}
                                    </Typography>
                                    <Typography color={colors.grey[100]}>{transaction.user}</Typography>
                                </Box>
                                <Box color={colors.grey[100]}>{transaction.date}</Box>
                                <Box bgcolor={colors.greenAccent[600]} padding='5px 10px' borderRadius='4px'>
                                    {transaction.cost} VNĐ
                                </Box>
                            </Box>
                        )
                    })}
                </Box>

                {/* ROW 3 */}
                <Box gridColumn='span 4' gridRow='span 2' bgcolor={colors.primary[400]} p='30px' className='boxShadowStyle'>
                    <Typography variant='h5' fontWeight='600' color={colors.grey[100]}>
                        Doanh thu theo ngày
                    </Typography>
                    <Box display='flex' flexDirection='column' alignItems='center' mt='25px'>
                        <ProgressCircle size='125' />
                        <Typography variant='h5' color={colors.greenAccent[400]} sx={{ mt: '15px' }}>
                            48,352 VNĐ
                        </Typography>
                        {/* <Typography color={colors.grey[100]}>Includes extra misc expenditures and costs</Typography> */}
                    </Box>
                </Box>

                {/*  */}
                <Box gridColumn='span 4' gridRow='span 2' bgcolor={colors.primary[400]} className='boxShadowStyle'>
                    <Typography variant='h5' fontWeight='600' color={colors.grey[100]} sx={{ p: '30px 30px 0 30px' }}>
                        Người lao động
                    </Typography>
                    <Box height='250px' mt='-20px'>
                        {/* <BarChart isDashboard={true} /> */}
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default CompanyDashboard
