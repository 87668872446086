import React, { useEffect, useState, useRef, useMemo } from 'react'
import {
    Box,
    Typography,
    useTheme,
    IconButton,
    Button,
    TextField,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    Modal,
    Divider,
    Tab,
    Tabs,
    Tooltip,
    Popover,
    Autocomplete,
} from '@mui/material'
import { tokens } from '../../../theme'
import Header from '../../../components/Header'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
// import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { TimePicker } from '@mui/x-date-pickers'
import { Add, Close, Delete, Edit, PlusOne } from '@mui/icons-material'
import moment from 'moment'
import {
    admin_get_all_airconditiondetails_for_city,
    admin_get_all_sofadetails_for_city,
    admin_get_all_spraydetails_for_city,
    create_airconditiondetail,
    create_app_discount,
    create_city_setting,
    create_clean_booking_price,
    create_level_discount,
    create_new_city_setting,
    create_permanent_task_booking_price,
    create_sofadetail,
    create_spraydetail,
    create_task_booking_price,
    delete_airconditiondetail,
    delete_city_setting,
    delete_sofadetail,
    delete_spraydetail,
    get_city_setting_by_id,
    get_city_settings,
    get_company_by_id,
    update_airconditiondetail,
    update_city_setting,
    update_sofadetail,
    update_spraydetail,
} from '../../../utils/api/apiList'
import request from '../../../utils/api/request'
import { converHourToMinutes } from '../../../algorithm/convertHourToMinutes'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import { message } from 'antd'
import './style.scss'
import minutesToHours from '../../../algorithm/minutesToHours'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import FormHelperText from '@mui/material/FormHelperText'
import PropTypes from 'prop-types'
import ModalTable from './modalTable'
import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import SaveIcon from '@mui/icons-material/Save'
import CancelIcon from '@mui/icons-material/Close'
import {
    GridRowModes,
    DataGrid,
    GridToolbarContainer,
    GridActionsCellItem,
    GridRowEditStopReasons,
    GridToolbar,
    GridToolbarQuickFilter,
} from '@mui/x-data-grid'
import { v4 as uuidv4 } from 'uuid'
import StyleDataGrid from '../../../assets/styles/styleDataGrid'
import CustomNoRowsOverlay from '../../../components/noRows'
import BoxDelete from './boxDelete'
import { formatNumber, parseNumber } from '../../../components/prefixData'
import BoxEditSofaAirSpray from './boxEditSofaAirSpray'
import BoxEditCleanPermanent from './boxEditCleanPemanent'

//validate giá mac dinh cua ca le
const validationTaskPriceSchema = yup.object().shape({
    price: yup.number().typeError('Giá tiền phải là số dương').required('Giá tiền là bắt buộc').positive('Giá tiền phải là số dương'),
    estimateTime: yup.number().typeError('Số giờ làm phải là số dương').required('Số giờ làm là bắt buộc').positive('Số giờ làm phải là số dương'),
})

// validate giá tuỳ chỉnh của ca le
const validationTaskPriceSpecialSchema = yup.object().shape({
    // day: yup.string().required('Ngày là bắt buộc'),
    time: yup.date().when('day', {
        is: (day) => day === 'Tất cả ngày',
        then: (schema) => schema.required('Thời gian là bắt buộc'),
        otherwise: (schema) => schema.notRequired(),
    }),
    estimateTime: yup.number().typeError('Số giờ làm phải là số dương').required('Số giờ làm là bắt buộc').positive('Số giờ làm phải là số dương'),
    price: yup.number().typeError('Giá tiền phải là số dương').required('Giá tiền là bắt buộc').positive('Giá tiền phải là số dương'),
})

//validate gía ca cố định và tổng vệ sinh
const validationPermanentAndCleanPriceSchema = () => {
    return yup.object().shape({
        pricePerDay: yup.number().typeError('Giá tiền phải là số dương').required('Giá tiền là bắt buộc').positive('Giá tiền phải là số dương'),
        estimateTime: yup
            .number()
            .typeError('Số giờ làm phải là số nguyên dương')
            .min(2, 'không được nhỏ hơn 2')
            .integer('Số giờ làm phải là số nguyên dương')
            .required('Số giờ làm là bắt buộc'),
    })
}

//validate discount
const userSchemaDiscountSchema = yup.object().shape({
    level: yup.number().positive('level phải lớn hơn 0').integer('Level phải là số nguyên').typeError('level là số').required('level là bắt buộc'),
    discount: yup
        .number()
        .typeError('chiếc khấu là số')
        .min(0, 'Chiết khấu phải lớn hơn hoặc bằng 0')
        .max(100, 'Chiết khấu phải bé hơn hoặc bằng 100')
        .required('chiết khấu là bắt buộc'),
})

//validate AirConditon and sofa
const validationAirAndSofaAndSpraySchema = (isOtherAirService, isSelectedTypeAir, serviceAirShowUI) => {
    return yup.object().shape({
        description: yup.string().required('Vui lòng nhập mô tả'),
        price: yup.number().typeError('Giá tiền phải là số dương').required('Giá tiền là bắt buộc').positive('Giá tiền phải là số dương'),
        name:
            isOtherAirService && !isSelectedTypeAir
                ? yup
                      .string()
                      .required('Tên dịch vụ là bắt buộc')
                      .test('unique-service-name', 'Tên dịch vụ đã tồn tại', (value) => {
                          return !serviceAirShowUI.some((service) => service.name.toLowerCase() === value?.toLowerCase())
                      })
                : yup.mixed().notRequired(),
    })
}
const validateSchemaEditAirAndSofaAndSpray = () => {
    return yup.object().shape({
        description: yup.string().required('Vui lòng nhập mô tả'),
        price: yup.number().typeError('Giá tiền phải là số dương').required('Giá tiền là bắt buộc').positive('Giá tiền phải là số dương'),
    })
}

//settup tabs
function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
            style={{ flex: 1 }}
        >
            {value === index && (
                <Box sx={{ p: 4 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

function TabPanelJob(props) {
    const { children, value, index, ...other } = props

    return (
        <div role='tabpanel' hidden={value !== index} id={`job-tabpanel-${index}`} aria-labelledby={`job-tab-${index}`} {...other}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

function TabPanelTaskPrice(props) {
    const { children, value, index, ...other } = props

    return (
        <div role='tabpanel' hidden={value !== index} id={`task-tabpanel-${index}`} aria-labelledby={`task-tab-${index}`} {...other}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
}

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    }
}

function a11xProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    }
}

//role
const role = localStorage.getItem('role')

const CompanySetting = () => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const tokenStorage = localStorage.getItem('token')
    const companyId = localStorage.getItem('companyId')

    //id thành phố của doanh nghiệp
    // const [idCitySettingCompany, setIdCitySettingCompany] = useState(null)
    // const [dataSettingCities, setDataSettingCities] = useState(null)

    const [cityId, setCityId] = useState('')
    const [dataSettingCity, setDataSettingCity] = useState(null)
    const [rows, setRows] = useState(null)
    const [rowsDiscount, setRowsDiscount] = useState(null)

    //permanent
    const [rowsPermanentPrice, setRowsPermanentPrice] = useState(null)
    const [anchorlDeleteRowPermanentPrice, setAnchorlDeleteRowPermanentPrice] = useState(null)
    const [currentRowPermanentPrice, setCurrentRowPermanentPrice] = useState(null)
    const [anchorElEditPermanentPrice, setAnchorElEditPermanentPrice] = useState(null)
    const [initialValuesPermanentPrice, setInitialValuesPermanentPrice] = useState(null)

    //clean
    const [rowsCleanPrice, setRowsCleanPrice] = useState(null)
    const [anchorlDeleteRowCleanPrice, setAnchorlDeleteRowCleanPrice] = useState(null)
    const [currentRowCleanPrice, setCurrentRowCleanPrice] = useState(null)
    const [anchorElEditCleanPrice, setAnchorElEditCleanPrice] = useState(null)
    const [initialValuesCleanPrice, setInitialValuesCleanPrice] = useState(null)

    //air condition
    const [allAirCondition, setAllAirCondition] = useState([])
    const [anchorElDeleteAir, setAnchorElDeleteAir] = useState(null)
    const [currentRowAir, setCurrentRowAir] = useState(null)
    const [serviceAirShowUI, setServiceAirShowUI] = useState([])
    const [isSelectedTypeAir, setIsSelectedTypeAir] = useState(false)
    const [anchorElEditAir, setAnchorElEditAir] = useState(false)
    const [isOtherAirService, setIsOtherAirService] = useState(false)

    //sofa
    const [allSofa, setAllSofa] = useState(null)
    const [anchorElDeleteSofa, setAnchorElDeleteSofa] = useState(null)
    const [currentRowSofa, setCurrentRowSofa] = useState(null)
    const [serviceSofaShowUI, setServiceSofaShowUI] = useState([])
    const [isSelectedTypeSofa, setIsSelectedTypeSofa] = useState(false)
    const [anchorElEditSofa, setAnchorElEditSofa] = useState(false)
    const [isOtherSofaService, setIsOtherSofaService] = useState(false)

    //spray
    const [allSpray, setAllSpray] = useState(null)
    const [anchorElDeleteSpray, setAnchorElDeleteSpray] = useState(null)
    const [currentRowSpray, setCurrentRowSpray] = useState(null)
    const [serviceSprayShowUI, setServiceSprayShowUI] = useState([])
    const [isSelectedTypeSpray, setIsSelectedTypeSpray] = useState(false)
    const [anchorElEditSpray, setAnchorElEditSpray] = useState(false)
    const [isOtherSprayService, setIsOtherSprayService] = useState(false)

    //state task price
    const [formDataTaskPrice, setFormDataTaskPrice] = useState({
        description: 'default',
        estimateTime: '',
        price: '',
    })

    //state task price special
    const [formDataTaskPriceSpecial, setFormDataTaskPriceSpecial] = useState({
        day: 'Tất cả ngày',
        time: null,
        estimateTime: '',
        price: '',
    })

    //state permanent price
    const [formDataPermanentPrice, setFormDataPermanentPrice] = useState({
        estimateTime: '',
        pricePerDay: '',
    })

    //state clean price
    const [formDataCleanPrice, setFormDataCleanPrice] = useState({
        estimateTime: '',
        pricePerDay: '',
    })

    //state Air condition
    const [formDataAirConditionPrice, setFormDataAirConditionPrice] = useState({
        type: Number,
        service: '',
        name: '',
        price: '',
        description: '',
        forCity: cityId,
    })

    //state sofa
    const [formDataSofaPrice, setFormDataSofaPrice] = useState({
        type: Number,
        service: '',
        name: '',
        price: '',
        description: '',
        forCity: cityId,
    })

    //state spray
    const [formDataSprayPrice, setFormDataSprayPrice] = useState({
        type: Number,
        service: '',
        name: '',
        price: '',
        description: '',
        forCity: cityId,
    })

    //tabs chung
    const [valueTab, setValueTab] = useState(0)
    //tab job
    const [valueTabJob, setValueTabJob] = useState(0)
    //tab task price
    const [valueTabTaskPrice, setValueTabTaskPrice] = useState(0)

    // handle change tab
    const handleChangeTab = (event, newValue) => {
        setValueTab(newValue)
    }
    const handleChangeTabJob = (event, newValue) => {
        setValueTabJob(newValue)
        console.log('tab job', newValue)

        switch (newValue) {
            case 3:
                fetchAllAirConditionDetails()
                break
            case 4:
                fetchAllSofaDetails()
                break
            case 5:
                fetchAllSprayDetails()
                break
            default:
                break
        }
    }
    const handleChangeTabTask = (event, newValue) => {
        setValueTabTaskPrice(newValue)
        // console.log('tab job ca le', newValue)
    }

    //notification
    const [messageApi, messageContextHolder] = message.useMessage()
    const notificationDescriptions = {
        position: 'Cài đặt vị trí thành phố thành công',
        task: 'Cài đặt ca lẻ thành công',
        taskDefault: (
            <p>
                Cài đặt <span style={{ color: colors.greenLight[600] }}>giá mặc định ca lẻ</span> thành công
            </p>
        ),
        updateTaskPrice: (
            <p>
                Cập nhật <span style={{ color: colors.greenLight[600] }}>ca lẻ</span> thành công
            </p>
        ),
        createPermanentPrice: (
            <p>
                Cài đặt <span style={{ color: colors.greenLight[600] }}>giá ca cố định</span> thành công
            </p>
        ),
        createCleanPrice: (
            <p>
                Cài đặt <span style={{ color: colors.greenLight[600] }}>ca tổng vệ sinh</span> thành công
            </p>
        ),
        createDiscount: (
            <p>
                Cài đặt <span style={{ color: colors.greenLight[600] }}>giảm giá</span> thành công
            </p>
        ),
        updatePermanentTask: 'Cập nhật ca cố định thành công',
        clean: 'Cài đặt tổng vệ sinh thành công',
        updateCleanTask: 'Cập nhật tổng vệ sinh thành công',
        addEstimateTime: 'Thêm mới số giờ làm thành công',
        createCity: 'Thêm mới thành phố thành công',
        deleteCity: 'Xoá thành phố thành công',
        deleteTaskPriceDefault: (
            <p>
                Xoá bản ghi <span style={{ color: colors.redAccent[400] }}>giá mặc định</span> của{' '}
                <span style={{ color: colors.redAccent[400] }}>ca lẻ</span> thành công
            </p>
        ),
        deleteTaskPriceSpecial: (
            <p>
                Xoá bản ghi <span style={{ color: colors.redAccent[400] }}>giá tuỳ chọn</span> của{' '}
                <span style={{ color: colors.redAccent[400] }}>ca lẻ</span> thành công
            </p>
        ),
        eventDeleteRowPermanentPrice: (
            <p>
                Xoá bản ghi của <span style={{ color: colors.redAccent[400] }}>ca cố định</span> thành công
            </p>
        ),
        eventDeleteRowCleanPrice: (
            <p>
                Xoá bản ghi của <span style={{ color: colors.redAccent[400] }}>ca Tổng vệ sinh</span> thành công
            </p>
        ),
        eventDeleteRowDiscount: (
            <p>
                Xoá bản ghi <span style={{ color: colors.redAccent[400] }}>giảm giá</span> thành công
            </p>
        ),
        default: 'Cài đặt thành công',
    }

    const openMessageNotification = (type, eventName) => {
        let description = notificationDescriptions[eventName] || notificationDescriptions.default

        if (type === 'error') {
            description = 'Cập nhật thất bại'
        }

        messageApi.open({
            type,
            content: description,
        })
    }

    // fetch Data
    useEffect(() => {
        fetchData()
    }, [cityId, valueTabTaskPrice, valueTabJob, valueTab])

    // fetch data
    const fetchData = () => {
        getIdCitySetting()

        if (cityId) {
            getSettingCity()
        }
    }

    // fetchAllAirConditionDetails(selectedCityId)
    //     fetchAllSofaDetails(selectedCityId)
    //     fetchAllSprayDetails(selectedCityId)

    // get idCitySetting (lấy id của setting của thành phố từ id company)
    const getIdCitySetting = () => {
        request
            .post(get_company_by_id, { _id: companyId })
            .then((res) => {
                console.log('Id city setting', res)
                setCityId(res.data.data.citySetting)
            })
            .catch((err) => {
                console.error('Lỗi: lấy thông tin id setting thành phố', err)
            })
    }

    // get city by id
    const getSettingCity = async () => {
        try {
            let res
            res = await request.post(get_city_setting_by_id, { _id: cityId })

            // ****************** DATA TASK PRICE ************************
            const dataTable = res.data.data?.workingPrice?.map((data, index) => {
                const timeConvert = minutesToHours(data?.time)
                const randomId = uuidv4()
                return {
                    ...data,
                    index: index + 1,
                    time: timeConvert,
                    id: data?.id ?? randomId,
                    _id: randomId,
                }
            })

            //check key 'description' có trong data hay không?
            //nếu có thì trả về bảng của giá task default,
            //nếu không trả về bảng task special
            if (valueTabJob === 0) {
                if (valueTabTaskPrice === 0) {
                    const dataTableTaskDefault = dataTable.filter((elm) => elm.hasOwnProperty('description'))
                    setRows(dataTableTaskDefault)
                } else if (valueTabTaskPrice === 1) {
                    const dataTableTaskSpecial = dataTable.filter((elm) => !elm.hasOwnProperty('description'))
                    setRows(dataTableTaskSpecial)
                }
            }

            // ********************* DATA PERMANENT PRICE ********************
            if (valueTabJob === 1) {
                const dataTablePermanentTask = res.data.data?.permanentPrice.map((elm, index) => {
                    const randomId = uuidv4()
                    return {
                        ...elm,
                        index: index + 1,
                        id: randomId,
                    }
                })
                console.log(dataTablePermanentTask)
                setRowsPermanentPrice(dataTablePermanentTask)
            }

            // ********************* DATA CLEAN PRICE ********************
            if (valueTabJob === 2) {
                const dataTableCleanTask = res.data.data?.cleanPrice.map((elm, index) => {
                    const randomId = uuidv4()
                    return {
                        ...elm,
                        index: index + 1,
                        id: randomId,
                    }
                })
                console.log(dataTableCleanTask)
                setRowsCleanPrice(dataTableCleanTask)
            }

            // ********************* DATA CLEAN PRICE ********************
            if (valueTab === 1) {
                const dataTableDiscount = res.data.data?.levelDiscount.map((elm, index) => {
                    const randomId = uuidv4()
                    return {
                        ...elm,
                        index: index + 1,
                        id: randomId,
                    }
                })
                console.log('dataTableDiscount', dataTableDiscount)
                setRowsDiscount(dataTableDiscount)
            }
            setDataSettingCity(res.data.data)

            // setRows(dataTable)
            return res.data.data
        } catch (err) {
            console.log(err)
            throw err
        }
    }

    // fetch create task price
    const fetchCreateTaskPrice = async (data, event) => {
        try {
            await request.post(create_task_booking_price, { _id: cityId, workingPrice: [...data] })
            switch (event) {
                case 'eventDeletTaskPriceDefault':
                    openMessageNotification('success', 'deleteTaskPriceDefault')
                    setOpenBoxDeleteTaskPriceDefault(false)
                    break
                case 'eventDeletTaskPriceSpecial':
                    openMessageNotification('success', 'deleteTaskPriceSpecial')
                    setOpenBoxDeleteTaskPriceSpecial(false)
                    break
                default:
                    openMessageNotification('success', 'taskDefault')
                    break
            }
            getSettingCity()
        } catch (err) {
            console.error(err)
            openMessageNotification('error')
        }
    }

    //********************************** CẬP NHẬT GIÁ CA LẺ  ******************************************/
    // ------------ GIÁ MẶC ĐỊNH CA LẺ ---------------
    // handle submit task default price
    const handleSubmitTaskDefaultPrice = async (values, { resetForm }) => {
        console.log('handle task default price')

        try {
            const currentCityData = await getSettingCity()

            const updatedWorkingPrice = [...currentCityData.workingPrice]
            // tìm kiếm key estimateTime
            const existingIndex = currentCityData.workingPrice.findIndex((item) => {
                return item.estimateTime === Number(values.estimateTime) && item.hasOwnProperty('description')
            })

            if (existingIndex !== -1) {
                // nếu đã tồn tại thì update ghi đè giá trị
                updatedWorkingPrice[existingIndex] = {
                    ...updatedWorkingPrice[existingIndex],
                    description: values.description,
                    estimateTime: Number(values.estimateTime),
                    price: Number(values.price),
                }
            } else {
                // nếu chưa tồn tại thì push vào mãng
                updatedWorkingPrice.push({
                    description: values.description,
                    estimateTime: Number(values.estimateTime),
                    price: Number(values.price),
                })
            }

            //server không tạo api update price nên phải dùng api tạo giá và tự check điều kiện để update giá
            await fetchCreateTaskPrice(updatedWorkingPrice)

            // hàm reset form được cung cấp bởi formik
            resetForm()
        } catch (err) {
            openMessageNotification('error')
        }
    }

    // ----------- GIÁ TUỲ CHỌN CA LẺ -----------------------
    const days = ['Chủ nhật', 'Thứ hai', 'Thứ ba', 'Thứ tư', 'Thứ năm', 'Thứ sáu', 'Thứ bảy', 'Tất cả ngày']
    const handleSubmitTaskDefaultPriceSpecial = async (values, { resetForm }) => {
        console.log('handle task price special')
        const time = moment(values.time)
        const totalMinutes = time.hours() * 60 + time.minutes()
        let day = values.day
        switch (day) {
            case 'Chủ nhật':
                day = 0
                break
            case 'Thứ hai':
                day = 1
                break
            case 'Thứ ba':
                day = 2
                break
            case 'Thứ tư':
                day = 3
                break
            case 'Thứ năm':
                day = 4
                break
            case 'Thứ sáu':
                day = 5
                break
            case 'Thứ bảy':
                day = 6
                break
            default:
                day = 7
                break
        }

        try {
            const currentCityData = await getSettingCity()

            const updatedWorkingPrice = [...currentCityData.workingPrice]
            // tìm kiếm key estimateTime
            const existingIndex = currentCityData.workingPrice.findIndex((item) => {
                return item.estimateTime === Number(values.estimateTime) && item.day === day && !item.hasOwnProperty('description')
            })

            console.log('existingIndex giá tuỳ chọn', existingIndex)
            let dataUpdated

            if (day === 7) {
                dataUpdated = {
                    time: totalMinutes,
                    estimateTime: Number(values.estimateTime),
                    price: Number(values.price),
                    id: uuidv4(),
                }
                console.log(day)
            } else {
                dataUpdated = {
                    day,
                    estimateTime: Number(values.estimateTime),
                    price: Number(values.price),
                    id: uuidv4(),
                }
            }
            console.log(dataUpdated)

            if (existingIndex !== -1) {
                // nếu đã tồn tại thì update ghi đè giá trị
                delete dataUpdated.id

                updatedWorkingPrice[existingIndex] = {
                    ...updatedWorkingPrice[existingIndex],
                    ...dataUpdated,
                }
            } else {
                // nếu chưa tồn tại thì push vào mãng
                updatedWorkingPrice.push({ ...dataUpdated })
            }

            console.log(updatedWorkingPrice)

            // if (existingIndex !== -1) {
            //     // nếu đã tồn tại thì update ghi đè giá trị
            //     updatedWorkingPrice[existingIndex] = {
            //         ...updatedWorkingPrice[existingIndex],
            //         ...dataUpdated,
            //     }
            // } else {
            //     // nếu chưa tồn tại thì push vào mãng
            //     updatedWorkingPrice.push({ ...dataUpdated })
            // }

            //server không tạo api update price nên phải dùng api tạo giá và tự check điều kiện để update giá
            await fetchCreateTaskPrice(updatedWorkingPrice)

            // hàm reset form được cung cấp bởi formik
            resetForm()
        } catch (err) {
            openMessageNotification('error')
        }
    }

    // ------------ TASK TABLE PRICE ----------------------------------------------------------------
    //TABLE TASK PRICE DEFAULT **************************************************************
    // function EditToolbar(props) {
    //     const { setRows, setRowModesModel, showQuickFilter } = props

    //     const handleClick = () => {
    //         const id = randomId()
    //         setRows((oldRows) => {
    //             const newIndex = oldRows.length + 1
    //             return [
    //                 ...oldRows,
    //                 { id, index: newIndex, estimateTime: '', price: '', description: 'default', isNew: true },
    //             ]
    //         })
    //         setRowModesModel((oldModel) => ({
    //             ...oldModel,
    //             [id]: { mode: GridRowModes.Edit, fieldToFocus: 'estimateTime' },
    //         }))
    //     }

    //     return (
    //         <GridToolbarContainer sx={{ justifyContent: 'space-between' }}>
    //             <Button color='primary' startIcon={<AddIcon />} onClick={handleClick}>
    //                 Add record
    //             </Button>
    //             {showQuickFilter && <GridToolbarQuickFilter />}
    //         </GridToolbarContainer>
    //     )
    // }
    const [rowIdTaskPriceDefault, setRowIdTaskPriceDefault] = useState(null)
    const [openBoxDeleteTaskPriceDefault, setOpenBoxDeleteTaskPriceDefault] = useState(null)
    const [rowModesModel, setRowModesModel] = useState({})

    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true
        }
    }

    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
    }

    const handleSaveClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
    }

    const handleDeleteClick = (id) => () => {
        // setRows(rows.filter((row) => row.id !== id))
        // console.log(id)
        setRowIdTaskPriceDefault(id)
        setOpenBoxDeleteTaskPriceDefault(true)
    }
    const handleDeleteRowTaskPriceDefault = (id) => {
        const dataTaskPriceDefault = [...rows]
        const existingIndex = dataTaskPriceDefault.findIndex((item) => {
            return item.id === id && item.hasOwnProperty('description')
        })

        // Nếu phần tử tồn tại, xóa nó khỏi mảng
        // if (existingIndex !== -1) {
        //     dataTaskPriceDefault.splice(existingIndex, 1)
        // }
        if (existingIndex === -1) {
            console.log('Phần tử không tồn tại trong mảng dataTaskPriceDefault')
            return
        }
        const dataWillDelete = dataTaskPriceDefault[existingIndex]
        delete dataWillDelete.time

        // console.log(dataTaskPriceDefault[existingIndex])
        // console.log(dataSettingCity)

        // lọc dữ liệu của dataSettingCity, loại ra dataWillDelete
        const dataUpdate = dataSettingCity.workingPrice.filter((elm) => {
            return (
                (elm.price !== dataWillDelete.price && elm.estimateTime !== dataWillDelete.estimateTime) ||
                elm.description !== dataWillDelete.description
            )
        })
        // console.log(dataUpdate)
        fetchCreateTaskPrice(dataUpdate, 'eventDeletTaskPriceDefault')
    }
    const handleCloseBoxDeleteTaskPriceDefault = () => {
        setOpenBoxDeleteTaskPriceDefault(false)
    }

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        })

        const editedRow = rows.find((row) => row.id === id)
        if (editedRow.isNew) {
            setRows(rows.filter((row) => row.id !== id))
        }
    }

    //hàm xử lý khi save/update dữ liệu row
    const processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow, isNew: false }
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)))

        const dataTaskPriceDefault = [...dataSettingCity.workingPrice]
        // kiểm tra key estimateTime có tồn tại hay chưa && tìm kiếm description có trong obj hay không.
        const existingIndex = dataTaskPriceDefault.findIndex((item) => {
            return item.estimateTime === updatedRow.estimateTime && item.hasOwnProperty('description')
        })
        // console.log(updatedRow)

        if (existingIndex !== -1) {
            dataTaskPriceDefault[existingIndex] = {
                ...dataTaskPriceDefault[existingIndex],
                description: updatedRow.description,
                estimateTime: updatedRow.estimateTime,
                price: updatedRow.price,
            }
        } else {
            dataTaskPriceDefault.push({
                description: updatedRow.description,
                estimateTime: updatedRow.estimateTime,
                price: updatedRow.price,
            })
        }
        // console.log('dataUpdate', dataTaskPriceDefault)

        //server không tạo api update price nên phải dùng api tạo giá và tự check điều kiện để update giá
        fetchCreateTaskPrice(dataTaskPriceDefault)

        return updatedRow
    }

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel)
    }

    const columnsTaskDefault = [
        // { field: 'index', headerName: 'STT', width: 80, flex: 0.5, editable: true },
        {
            field: 'estimateTime',
            headerName: 'Số giờ làm',
            type: 'number',
            align: 'left',
            headerAlign: 'left',
            width: 180,
            flex: 1,
            // editable: true,
        },
        {
            field: 'price',
            headerName: 'Giá tiền',
            type: 'number',
            cellClassName: 'name-column--cell edit-price',
            width: 180,
            flex: 1,
            align: 'left',
            headerAlign: 'left',
            editable: true,
            // valueGetter: (value) => {
            //     return formatNumber(value) + ' ' + 'VNĐ'
            // },
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            flex: 0.5,
            flex: 1,
            align: 'left',
            headerAlign: 'left',
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label='Save'
                            sx={{
                                color: 'inherit',
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label='Cancel'
                            className='textPrimary'
                            onClick={handleCancelClick(id)}
                            color='inherit'
                        />,
                    ]
                }

                return [
                    <GridActionsCellItem icon={<EditIcon />} label='Edit' className='textPrimary' onClick={handleEditClick(id)} color='inherit' />,
                    <GridActionsCellItem id={id} icon={<DeleteIcon />} label='Delete' onClick={handleDeleteClick(id)} color='inherit' />,
                ]
            },
        },
    ]

    // TABLE TASK SPECIAL PRICE **********************************************************
    // function EditToolbarTaskSpecial(props) {
    //     const { setRows, setRowModesModelTaskPriceSpecial, showQuickFilter } = props

    //     const handleClick = () => {
    //         const id = randomId()
    //         setRows((oldRows) => {
    //             const newIndex = oldRows.length + 1
    //             return [
    //                 ...oldRows,
    //                 { id, index: newIndex, estimateTime: '', price: '', day: '', time: '', isNew: true },
    //             ]
    //         })
    //         setRowModesModelTaskPriceSpecial((oldModel) => ({
    //             ...oldModel,
    //             [id]: { mode: GridRowModes.Edit, fieldToFocus: 'day' },
    //         }))
    //     }

    //     return (
    //         <GridToolbarContainer sx={{ justifyContent: 'space-between' }}>
    //             <Button color='primary' startIcon={<AddIcon />} onClick={handleClick}>
    //                 Add record
    //             </Button>
    //             {showQuickFilter && <GridToolbarQuickFilter />}
    //         </GridToolbarContainer>
    //     )
    // }
    const [rowIdTaskPriceSpecial, setRowIdTaskPriceSpecial] = useState(null)
    const [openBoxDeleteTaskPriceSpecial, setOpenBoxDeleteTaskPriceSpecial] = useState(null)
    const [rowModesModelTaskPriceSpecial, setRowModesModelTaskPriceSpecial] = useState({})

    const handleRowEditStopTaskPriceSpecial = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true
        }
    }

    const handleEditClickTaskPriceSpecial = (id) => () => {
        setRowModesModelTaskPriceSpecial({ ...rowModesModelTaskPriceSpecial, [id]: { mode: GridRowModes.Edit } })
    }

    const handleSaveClickTaskPriceSpecial = (id) => () => {
        setRowModesModelTaskPriceSpecial({ ...rowModesModelTaskPriceSpecial, [id]: { mode: GridRowModes.View } })
    }

    const handleDeleteClickTaskPriceSpecial = (id) => () => {
        // setRows(rows.filter((row) => row.id !== id))
        console.log(id)
        setRowIdTaskPriceSpecial(id)
        setOpenBoxDeleteTaskPriceSpecial(true)
    }
    const handleDeleteRowTaskPriceSpecial = (id) => {
        const dataUpdate = dataSettingCity.workingPrice.filter((elm) => {
            return !(elm.id === id)
        })

        // console.log(dataUpdate)
        fetchCreateTaskPrice(dataUpdate, 'eventDeletTaskPriceSpecial')
    }

    const handleCloseBoxDeleteTaskPriceSpecial = () => {
        setOpenBoxDeleteTaskPriceSpecial(false)
    }

    const handleCancelClickTaskPriceSpecial = (id) => () => {
        setRowModesModelTaskPriceSpecial({
            ...rowModesModelTaskPriceSpecial,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        })

        const editedRow = rows.find((row) => row.id === id)
        if (editedRow.isNew) {
            setRows(rows.filter((row) => row.id !== id))
        }
    }

    //hàm xử lý khi save/update dữ liệu row
    const processRowUpdateTaskPriceSpecial = (newRow) => {
        let updatedRow = { ...newRow, isNew: false }
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)))

        // let current = Date.now()
        // let dataTaskPrice = [...dataSettingCity.workingPrice].map((item, idx) => {
        //     item._id = current + idx
        //     return item
        // })

        // kiểm tra giá trị estimateTime và check key description không được có trong obj
        // let existingIndex = dataTaskPrice.findIndex((item) => {
        //     return item.estimateTime === updatedRow.estimateTime && !item.hasOwnProperty('description')
        // })

        // const dataSpectial = dataTaskPrice.filter((item) => item.estimateTime === updatedRow.estimateTime && !item.hasOwnProperty('description'))
        // delete updatedRow.isNew
        delete updatedRow.index
        console.log(dataSettingCity.workingPrice)
        console.log(updatedRow)

        //đổi kiểu dữ liệu time và day trước khi gửi lên server
        let day = updatedRow.day
        switch (day) {
            case 'Chủ nhật':
                day = 0
                break
            case 'Thứ hai':
                day = 1
                break
            case 'Thứ ba':
                day = 2
                break
            case 'Thứ tư':
                day = 3
                break
            case 'Thứ năm':
                day = 4
                break
            case 'Thứ sáu':
                day = 5
                break
            case 'Thứ bảy':
                day = 6
                break
            default:
                day = null
                break
        }

        let time = updatedRow.time
        let [hours, minutes] = time.split(':').map(Number)
        let totalMinutes = hours * 60 + minutes

        const dataTaskPrice = dataSettingCity.workingPrice.map((elm) => {
            if (elm.id === updatedRow.id) {
                return { ...elm, day: day || null, time: totalMinutes || null, price: updatedRow.price }
            }
            return elm
        })
        console.log(dataTaskPrice)

        // console.log('dataTaskPrice', dataTaskPrice)

        // if (existingIndex !== -1) {
        //     // if (day !== null) {
        //     //     dataTaskPrice[existingIndex] = {
        //     //         ...dataTaskPrice[existingIndex],
        //     //         estimateTime: updatedRow.estimateTime,
        //     //         price: updatedRow.price,
        //     //         day: Number(day),
        //     //         // time: Number(totalMinutes),
        //     //     }
        //     // } else {
        //     //     dataTaskPrice[existingIndex] = {
        //     //         estimateTime: updatedRow.estimateTime,
        //     //         price: updatedRow.price,
        //     //         time: Number(totalMinutes),
        //     //     }
        //     // }
        //     dataTaskPrice[existingIndex] = {
        //         ...dataTaskPrice[existingIndex],
        //         estimateTime: updatedRow.estimateTime,
        //         price: updatedRow.price,
        //         day: Number(day),
        //         // time: Number(totalMinutes),
        //     }
        //     console.log(existingIndex)

        //     // console.log(dataTaskPrice)
        // } else {
        //     dataTaskPrice.push({
        //         estimateTime: updatedRow.estimateTime,
        //         price: updatedRow.price,
        //         day: Number(day),
        //         time: Number(totalMinutes),
        //     })
        // }
        // console.log(dataTaskPrice)
        // console.log(updatedRow)

        fetchCreateTaskPrice(dataTaskPrice)

        return updatedRow
    }

    const handleRowModesModelChangeTaskPriceSpecial = (newRowModesModel) => {
        setRowModesModelTaskPriceSpecial(newRowModesModel)
    }

    const columnsTaskSpecial = [
        // { field: 'index', headerName: 'STT', width: 80, flex: 0.5, editable: true },
        {
            field: 'day',
            headerName: 'Ngày',
            align: 'left',
            headerAlign: 'left',
            width: 180,
            flex: 1,
            editable: true,
            valueGetter: (value) => {
                let day
                switch (value) {
                    case 0:
                        day = 'Chủ nhật'
                        break

                    case 1:
                        day = 'Thứ hai'
                        break
                    case 2:
                        day = 'Thứ ba'
                        break
                    case 3:
                        day = 'Thứ tư'
                        break
                    case 4:
                        day = 'Thứ năm'
                        break
                    case 5:
                        day = 'Thứ sáu'
                        break
                    case 6:
                        day = 'Thứ bảy'
                        break
                    default:
                        // day = 'Tất cả ngày'
                        break
                }
                return day
            },
            type: 'singleSelect',
            valueOptions: ['Chủ nhật', 'Thứ hai', 'Thứ ba', 'Thứ tư', 'Thứ năm', 'Thứ sáu', 'Thứ bảy'],
            // valueOptions: (params) => {
            //     if (params.row.day === 'Tất cả ngày') {
            //         // Nếu là "Tất cả ngày" thì chỉ cho phép chọn duy nhất "Tất cả ngày"
            //         return ['Tất cả ngày']
            //     } else {
            //         // Nếu không thì hiển thị các lựa chọn ngày thông thường
            //         return ['Chủ nhật', 'Thứ hai', 'Thứ ba', 'Thứ tư', 'Thứ năm', 'Thứ sáu', 'Thứ bảy']
            //     }
            // },
        },
        {
            field: 'time',
            headerName: 'Thời gian',
            align: 'left',
            headerAlign: 'left',
            width: 180,
            flex: 1,
            editable: true,
        },
        {
            field: 'estimateTime',
            headerName: 'Số giờ làm',
            type: 'number',
            align: 'left',
            headerAlign: 'left',
            width: 180,
            flex: 1,
            // editable: true,
        },
        {
            field: 'price',
            headerName: 'Giá tiền',
            type: 'number',
            cellClassName: 'name-column--cell edit-price',
            width: 180,
            flex: 1,
            align: 'left',
            headerAlign: 'left',
            editable: true,
            // valueGetter: (value) => {
            //     return formatNumber(value) + ' ' + 'VNĐ'
            // },
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            flex: 0.5,
            flex: 1,
            align: 'left',
            headerAlign: 'left',
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModelTaskPriceSpecial[id]?.mode === GridRowModes.Edit

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label='Save'
                            sx={{
                                color: 'inherit',
                            }}
                            onClick={handleSaveClickTaskPriceSpecial(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label='Cancel'
                            className='textPrimary'
                            onClick={handleCancelClickTaskPriceSpecial(id)}
                            color='inherit'
                        />,
                    ]
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label='Edit'
                        className='textPrimary'
                        onClick={handleEditClickTaskPriceSpecial(id)}
                        color='inherit'
                    />,
                    <GridActionsCellItem
                        id={id}
                        icon={<DeleteIcon />}
                        label='Delete'
                        onClick={handleDeleteClickTaskPriceSpecial(id)}
                        color='inherit'
                    />,
                ]
            },
        },
    ]

    // ********************************** CA CỐ ĐỊNH *****************************************
    // ----------------- GIÁ CA CỐ ĐỊNH ------------------
    const handleSubmitPermanentPrice = async (values, { resetForm }, event) => {
        console.log('handle special price')
        console.log(values)
        if (cityId) {
            try {
                const currentCityData = await getSettingCity()

                const updatedPermanentPrice = [...currentCityData.permanentPrice]
                // tìm kiếm key estimateTime
                const existingIndex = updatedPermanentPrice.findIndex((item) => {
                    return item.estimateTime === Number(values.estimateTime)
                })

                if (existingIndex !== -1) {
                    // nếu đã tồn tại thì update ghi đè giá trị
                    updatedPermanentPrice[existingIndex] = {
                        ...updatedPermanentPrice[existingIndex],
                        estimateTime: Number(values.estimateTime),
                        pricePerDay: Number(values.pricePerDay),
                    }
                } else {
                    // nếu chưa tồn tại thì push vào mãng
                    updatedPermanentPrice.push({
                        estimateTime: Number(values.estimateTime),
                        pricePerDay: Number(values.pricePerDay),
                    })
                }

                // console.log('dataUpdate', updatedPermanentPrice)

                await fetchCreatePermanentPrice(updatedPermanentPrice, event)

                // hàm reset form được cung cấp bởi formik
                resetForm()
            } catch (err) {
                openMessageNotification('error')
            }
        } else {
            openMessageNotification('error')
        }
    }

    // fetch create permanent Price
    const fetchCreatePermanentPrice = async (data, event) => {
        try {
            const res = await request.post(create_permanent_task_booking_price, {
                _id: cityId,
                permanentPrice: [...data],
            })
            getSettingCity()

            switch (event) {
                case 'eventDeleteRowPermanentPrice':
                    openMessageNotification('success', event)
                    break
                case 'editPermanentPrice':
                    message.success('Cập nhật bảng ghi ca cố định thành công!')
                    setAnchorElEditPermanentPrice(false)
                    break
                default:
                    openMessageNotification('success', 'createPermanentPrice')
                    break
            }
        } catch (err) {
            console.error(err)
            openMessageNotification('error')
        }
    }

    //table permanentTask
    const columnsPermanentPrice = [
        {
            field: 'index',
            headerName: 'STT',
            minWidth: 100,
            flex: 0.5,
        },
        {
            field: 'estimateTime',
            headerName: 'Số giờ làm',
            minWidth: 100,
            flex: 1,
            cellClassName: 'cost-column name-column--cell',
        },
        {
            field: 'pricePerDay',
            headerName: 'Giá tiền',
            minWidth: 100,
            flex: 1,
            cellClassName: 'cost-column name-column--cell',
            valueGetter: (value) => {
                return formatNumber(value) + ' ' + 'VNĐ'
            },
        },
        {
            field: 'actions',
            headerName: 'Hành động',
            minWidth: 100,
            flex: 0.5,
            renderCell: ({ row }) => {
                return (
                    <Box>
                        <IconButton onClick={(e) => handleOpenEditPermanentPrice(e, row)}>
                            <Edit />
                        </IconButton>
                        <IconButton onClick={(e) => handleDeleteRowPermanentPrice(e, row)}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                )
            },
        },
    ]

    //delete row permanent price
    const handleDeleteRowPermanentPrice = (e, row) => {
        setCurrentRowPermanentPrice(row)
        setAnchorlDeleteRowPermanentPrice(e.currentTarget)
    }
    const handleCloseBoxDeletePermanentPrice = () => {
        setAnchorlDeleteRowPermanentPrice(false)
    }
    const handleConfirmDeletePermanentPrice = (currentRow) => {
        const dataPermanentPrice = [...rowsPermanentPrice]
        // tìm kiếm index data cần xoá
        const existingIndex = dataPermanentPrice.findIndex((elm) => elm.estimateTime === Number(currentRow.estimateTime))
        const dataWillDelete = dataPermanentPrice[existingIndex]
        console.log(dataWillDelete)

        // lọc data cần xoá khỏi data city
        const dataUpdated = dataSettingCity.permanentPrice.filter((elm) => {
            return !(elm.estimateTime === Number(dataWillDelete.estimateTime))
        })
        console.log(dataUpdated)

        // fetch data
        fetchCreatePermanentPrice(dataUpdated, 'eventDeleteRowPermanentPrice')

        setAnchorlDeleteRowPermanentPrice(false)
    }

    //edit permanent
    const handleOpenEditPermanentPrice = (e, row) => {
        setAnchorElEditPermanentPrice(e.currentTarget)
        setCurrentRowPermanentPrice(row)
        setInitialValuesPermanentPrice({ ...row })
    }
    const handleCloseEditPermanentPrice = () => {
        setAnchorElEditPermanentPrice(false)
    }
    const handleConfirmEditPermanentPrice = (values, { resetForm }) => {
        // console.log('edit permanent', values)
        // console.log('row permanent', rowsPermanentPrice)
        handleSubmitPermanentPrice(values, { resetForm }, 'editPermanentPrice')
    }

    // ********************************** TỔNG VỆ SINH *****************************************
    const handleSubmitCleanPrice = async (values, { resetForm }, event) => {
        console.log('handle submit clean price')
        if (cityId) {
            try {
                const currentCityData = await getSettingCity()

                const updatedCleanPrice = [...currentCityData.cleanPrice]
                // tìm kiếm key estimateTime
                const existingIndex = updatedCleanPrice.findIndex((item) => {
                    return item.estimateTime === Number(values.estimateTime)
                })

                if (existingIndex !== -1) {
                    // nếu đã tồn tại thì update ghi đè giá trị
                    updatedCleanPrice[existingIndex] = {
                        ...updatedCleanPrice[existingIndex],
                        estimateTime: Number(values.estimateTime),
                        pricePerDay: Number(values.pricePerDay),
                    }
                } else {
                    // nếu chưa tồn tại thì push vào mãng
                    updatedCleanPrice.push({
                        estimateTime: Number(values.estimateTime),
                        pricePerDay: Number(values.pricePerDay),
                    })
                }

                console.log(updatedCleanPrice)

                await fetchCreateCleanPrice(updatedCleanPrice, event)

                // hàm reset form được cung cấp bởi formik
                resetForm()
            } catch (err) {
                openMessageNotification('error')
            }
        } else {
            openMessageNotification('error')
        }
    }

    //fetch create clean price
    const fetchCreateCleanPrice = async (data, event) => {
        try {
            const res = await request.post(create_clean_booking_price, {
                _id: cityId,
                cleanPrice: [...data],
            })
            getSettingCity()

            switch (event) {
                case 'eventDeleteRowCleanPrice':
                    openMessageNotification('success', event)
                    break
                case 'editClean':
                    message.success('Cập nhật bản ghi ca TVS thành công!')
                    setAnchorElEditCleanPrice(false)
                    break
                default:
                    openMessageNotification('success', 'createCleanPrice')
                    break
            }
        } catch (err) {
            console.error(err)
            openMessageNotification('error')
        }
    }

    //table clean Task
    const columnsCleanPrice = [
        {
            field: 'index',
            headerName: 'STT',
            minWidth: 100,
            flex: 0.5,
        },
        {
            field: 'estimateTime',
            headerName: 'Số giờ làm',
            minWidth: 100,
            flex: 1,
            cellClassName: 'cost-column name-column--cell',
        },
        {
            field: 'pricePerDay',
            headerName: 'Tiền công',
            minWidth: 100,
            flex: 1,
            cellClassName: 'cost-column name-column--cell',
            valueGetter: (value) => {
                return formatNumber(value) + ' ' + 'VNĐ'
            },
        },
        {
            field: 'actions',
            headerName: 'Hành động',
            minWidth: 100,
            flex: 0.5,
            renderCell: ({ row }) => {
                return (
                    <Box>
                        <IconButton onClick={(e) => handleOpenEditCleanPrice(e, row)}>
                            <Edit />
                        </IconButton>
                        <IconButton onClick={(e) => handleDeleteRowCleanPrice(e, row)}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                )
            },
        },
    ]

    //delete row clean price
    const handleDeleteRowCleanPrice = (e, row) => {
        setCurrentRowCleanPrice(row)
        setAnchorlDeleteRowCleanPrice(e.currentTarget)
    }
    const handleCloseBoxDeleteCleanPrice = () => {
        setAnchorlDeleteRowCleanPrice(false)
    }
    const handleConfirmDeleteCleanPrice = (currentRow) => {
        const dataCleanPrice = [...rowsCleanPrice]
        // tìm kiếm index data cần xoá
        const existingIndex = dataCleanPrice.findIndex((elm) => elm.estimateTime === Number(currentRow.estimateTime))
        const dataWillDelete = dataCleanPrice[existingIndex]
        console.log(dataWillDelete)

        // lọc data cần xoá khỏi data city
        const dataUpdated = dataSettingCity.cleanPrice.filter((elm) => {
            return !(elm.estimateTime === Number(dataWillDelete.estimateTime))
        })
        console.log(dataUpdated)

        // fetch data
        fetchCreateCleanPrice(dataUpdated, 'eventDeleteRowCleanPrice')

        setAnchorlDeleteRowCleanPrice(false)
    }

    //edit clean
    const handleOpenEditCleanPrice = (e, row) => {
        setAnchorElEditCleanPrice(e.currentTarget)
        setCurrentRowCleanPrice(row)
        setInitialValuesCleanPrice({ ...row })
    }
    const handleCloseEditCleanPrice = () => {
        setAnchorElEditCleanPrice(false)
    }
    const handleConfirmEditCleanPrice = (values, { resetForm }) => {
        console.log('edit clean', values)
        // console.log('row clean', rowsCleanPrice)
        handleSubmitCleanPrice(values, { resetForm }, 'editClean')
    }

    // ************************************* ĐIỀU HOÀ ************************************************
    //state edit air
    const [initialValuesAir, setInitialValuesAir] = useState(null)

    const handleSubmitAirConditionPrice = (values, { resetForm }) => {
        let name, type

        if (allAirCondition.length > 0) {
            if (isOtherAirService) {
                if (isSelectedTypeAir) {
                    // Nếu người dùng chọn nhóm dịch vụ
                    const selectedService = serviceAirShowUI.find((elm) => {
                        return elm.type === values.type
                    })

                    if (selectedService) {
                        name = selectedService.name
                        type = selectedService.type
                    }
                } else if (!isSelectedTypeAir) {
                    // Nếu người dùng không chọn nhóm dịch vụ
                    name = values.name
                    type = serviceAirShowUI.length + 1 // Tự động sinh type mới
                }
            } else {
                // Nếu người dùng chọn dịch vụ có sẵn
                const selectedService = allAirCondition.find((elm) => {
                    return elm._id === values.service
                })

                if (selectedService) {
                    name = selectedService.name
                    type = selectedService.type
                }
            }
        } else {
            // Nếu không có dịch vụ nào có sẵn trong thành phố hiện tại
            name = values.name
            type = 1
        }

        const data = {
            type,
            name,
            price: Number(values.price),
            forCity: cityId,
            description: values.description,
        }

        // console.log(data)
        // console.log({ values })

        fetchCreateAirConditionPrice(data)
        resetForm()
    }

    const fetchCreateAirConditionPrice = (data) => {
        request
            .post(create_airconditiondetail, { data })
            .then((res) => {
                console.log(res)
                message.success('Tạo mới giá điều hoà thành công')
                fetchAllAirConditionDetails()
                setIsOtherAirService(false)
                setIsSelectedTypeAir(false)
            })
            .catch((err) => {
                console.log(err)
                message.error('Lỗi: tạo giá điều hoà thất bại!')
            })
    }

    const fetchAllAirConditionDetails = () => {
        request
            .post(admin_get_all_airconditiondetails_for_city, { forCity: cityId })
            .then((res) => {
                console.log('all service airCondition', res.data.data.docs)
                const data = res.data.data.docs.map((elm, index) => {
                    return {
                        ...elm,
                        index: index + 1,
                    }
                })
                setAllAirCondition(data)

                // Sử dụng reduce để lấy danh sách duy nhất theo tên, sử dụng để show ui tên dịch vụ
                const uniqueServices = data.reduce((acc, current) => {
                    const x = acc.find((item) => item.name === current.name)
                    if (!x) {
                        return acc.concat([current])
                    } else {
                        return acc
                    }
                }, [])

                console.log('uniqueServices', uniqueServices)
                setServiceAirShowUI(uniqueServices)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const fetchUpdateAirCondition = (data) => {
        request
            .post(update_airconditiondetail, { ...data })
            .then((res) => {
                console.log(res)
                message.success('Cập nhật bảng ghi điều hoà thành công!')
                fetchAllAirConditionDetails()
            })
            .catch((err) => {
                console.error(err)
                message.error('Lỗi: cập nhật thất bại!')
            })
            .finally(() => {
                setAnchorElEditAir(false)
            })
    }

    //delete air
    const fetchDeleteAir = (_id) => {
        request
            .post(delete_airconditiondetail, { _id })
            .then((res) => {
                message.success('Xoá bản ghi điều hoà thành công!')
                fetchAllAirConditionDetails()
            })
            .catch((err) => message.error('Lỗi: Xoá bản ghi điều hoà thất bại!'))
    }

    //open box delete air
    const handleOpenDeleteRowAir = (e, row) => {
        setAnchorElDeleteAir(e.currentTarget)
        setCurrentRowAir(row)
    }
    const handleCloseBoxDeleteAir = () => {
        setAnchorElDeleteAir(false)
    }
    const handleConfirmDeleteAir = () => {
        fetchDeleteAir(currentRowAir._id)
        setAnchorElDeleteAir(false)
    }

    //edit air
    const handleOpenEditAir = (e, row) => {
        setAnchorElEditAir(e.currentTarget)
        setCurrentRowAir(row)
        setInitialValuesAir({ ...row })
    }
    const handleCloseEditAir = () => {
        setAnchorElEditAir(false)
    }
    const handleConfirmEditAir = (values, { resetForm }) => {
        console.log('edit air', values)

        fetchUpdateAirCondition(values)
    }

    //column air
    const columnsAirConditionPrice = [
        {
            field: 'index',
            headerName: 'STT',
            width: 30,
        },
        {
            field: 'name',
            headerName: 'Tên dịch vụ',
            minWidth: 100,
            flex: 0.5,
            cellClassName: 'cost-column name-column--cell',
        },
        {
            field: 'description',
            headerName: 'Mô tả',
            minWidth: 100,
            flex: 1,
        },
        {
            field: 'price',
            headerName: 'Giá',
            minWidth: 100,
            flex: 0.2,
            cellClassName: 'cost-column name-column--cell',
            valueGetter: (value) => {
                return formatNumber(value) + ' ' + 'VNĐ'
            },
        },
        {
            field: 'actions',
            headerName: 'Hành động',
            minWidth: 100,
            flex: 0.2,
            renderCell: ({ row }) => {
                return (
                    <Box>
                        <IconButton onClick={(e) => handleOpenEditAir(e, row)}>
                            <Edit />
                        </IconButton>
                        <IconButton onClick={(e) => handleOpenDeleteRowAir(e, row)}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                )
            },
        },
    ]

    // ************************************* SOFA ************************************************
    const [initialValuesSofa, setInitialValuesSofa] = useState(null)

    const handleSubmitSofaPrice = (values, { resetForm }) => {
        let name, type

        if (allSofa.length > 0) {
            if (isOtherSofaService) {
                if (isSelectedTypeSofa) {
                    // Nếu người dùng chọn nhóm dịch vụ
                    const selectedService = serviceSofaShowUI.find((elm) => {
                        return elm.type === values.type
                    })

                    if (selectedService) {
                        name = selectedService.name
                        type = selectedService.type
                    }
                } else if (!isSelectedTypeSofa) {
                    // Nếu người dùng không chọn nhóm dịch vụ
                    name = values.name
                    type = serviceSofaShowUI.length + 1 // Tự động sinh type mới
                }
            } else {
                // Nếu người dùng chọn dịch vụ có sẵn
                const selectedService = allSofa.find((elm) => {
                    return elm._id === values.service
                })

                if (selectedService) {
                    name = selectedService.name
                    type = selectedService.type
                }
            }
        } else {
            // Nếu không có dịch vụ nào có sẵn trong thành phố hiện tại
            name = values.name
            type = 1
        }

        const data = {
            type,
            name,
            price: Number(values.price),
            forCity: cityId,
            description: values.description,
        }

        console.log(data)
        console.log({ values })

        fetchCreateSofaPrice(data)
        resetForm()
    }

    const fetchCreateSofaPrice = (data) => {
        request
            .post(create_sofadetail, { data })
            .then((res) => {
                console.log(res)
                message.success('Tạo mới giá Sofa thành công')
                fetchAllSofaDetails()
                setIsOtherSofaService(false)
                setIsSelectedTypeSofa(false)
            })
            .catch((err) => {
                console.log(err)
                message.error('Lỗi: tạo giá Sofa thất bại!')
            })
    }

    const fetchAllSofaDetails = () => {
        request
            .post(admin_get_all_sofadetails_for_city, { forCity: cityId })
            .then((res) => {
                console.log('All service sofa', res)
                const data = res.data.data.docs.map((elm, index) => {
                    return {
                        ...elm,
                        index: index + 1,
                    }
                })
                setAllSofa(data)

                // Sử dụng reduce để lấy danh sách duy nhất theo tên, sử dụng để show ui tên dịch vụ
                if (data.length > 0) {
                    const uniqueServices = data.reduce((acc, current) => {
                        const x = acc.find((item) => item.name === current.name)
                        return !x ? acc.concat([current]) : acc
                    }, [])

                    console.log('uniqueServicesSofa', uniqueServices)
                    setServiceSofaShowUI(uniqueServices)
                } else {
                    setServiceSofaShowUI([])
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const fetchUpdateSofa = (data) => {
        request
            .post(update_sofadetail, { ...data })
            .then((res) => {
                console.log(res)
                message.success('Cập nhật bảng ghi Sofa thành công!')
                fetchAllSofaDetails()
            })
            .catch((err) => {
                console.error(err)
                message.error('Lỗi: cập nhật thất bại!')
            })
            .finally(() => {
                setAnchorElEditSofa(false)
            })
    }

    //delete sofa
    const fetchDeleteSofa = (_id) => {
        request
            .post(delete_sofadetail, { _id })
            .then((res) => {
                message.success('Xoá bản ghi sofa thành công!')
                fetchAllSofaDetails()
            })
            .catch((err) => message.error('Lỗi: Xoá bản ghi sofa thất bại!'))
    }

    //open box delete air
    const handleOpenDeleteRowSofa = (e, row) => {
        setAnchorElDeleteSofa(e.currentTarget)
        setCurrentRowSofa(row)
    }
    const handleCloseBoxDeleteSofa = () => {
        setAnchorElDeleteSofa(false)
    }
    const handleConfirmDeleteSofa = () => {
        fetchDeleteSofa(currentRowSofa._id)
        setAnchorElDeleteSofa(false)
    }

    //edit air
    const handleOpenEditSofa = (e, row) => {
        setAnchorElEditSofa(e.currentTarget)
        setCurrentRowSofa(row)
        setInitialValuesSofa({ ...row })
    }
    const handleCloseEditSofa = () => {
        setAnchorElEditAir(false)
    }
    const handleConfirmEditSofa = (values, { resetForm }) => {
        console.log('edit air', values)

        fetchUpdateSofa(values)
    }

    //column sofa
    const columnsSofa = [
        {
            field: 'index',
            headerName: 'STT',
            width: 100,
        },
        {
            field: 'name',
            headerName: 'Tên dịch vụ',
            minWidth: 100,
            flex: 0.5,
            cellClassName: 'cost-column name-column--cell',
        },
        {
            field: 'description',
            headerName: 'Mô tả',
            minWidth: 100,
            flex: 0.5,
        },
        {
            field: 'price',
            headerName: 'Giá',
            minWidth: 100,
            flex: 0.2,
            cellClassName: 'cost-column name-column--cell',
            valueGetter: (value) => {
                return formatNumber(value) + ' ' + 'VNĐ'
            },
        },
        {
            field: 'actions',
            headerName: 'Hành động',
            minWidth: 100,
            flex: 0.2,
            renderCell: ({ row }) => {
                return (
                    <Box>
                        <IconButton onClick={(e) => handleOpenEditSofa(e, row)}>
                            <Edit />
                        </IconButton>
                        <IconButton onClick={(e) => handleOpenDeleteRowSofa(e, row)}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                )
            },
        },
    ]

    // ************************************* SPRAY ************************************************
    const [initialValuesSpray, setInitialValuesSpray] = useState(null)

    const handleSubmitSprayPrice = (values, { resetForm }) => {
        let name, type

        if (allSpray.length > 0) {
            if (isOtherSprayService) {
                if (isSelectedTypeSpray) {
                    // Nếu người dùng chọn nhóm dịch vụ
                    const selectedService = serviceSprayShowUI.find((elm) => {
                        return elm.type === values.type
                    })

                    if (selectedService) {
                        name = selectedService.name
                        type = selectedService.type
                    }
                } else if (!isSelectedTypeSpray) {
                    // Nếu người dùng không chọn nhóm dịch vụ
                    name = values.name
                    type = serviceSprayShowUI.length + 1 // Tự động sinh type mới
                }
            } else {
                // Nếu người dùng chọn dịch vụ có sẵn
                const selectedService = allSpray.find((elm) => {
                    return elm._id === values.service
                })

                if (selectedService) {
                    name = selectedService.name
                    type = selectedService.type
                }
            }
        } else {
            // Nếu không có dịch vụ nào có sẵn trong thành phố hiện tại
            name = values.name
            type = 1
        }

        const data = {
            type,
            name,
            price: Number(values.price),
            forCity: cityId,
            description: values.description,
        }

        console.log(data)
        console.log({ values })

        fetchCreateSprayPrice(data)
        resetForm()
    }

    const fetchCreateSprayPrice = (data) => {
        request
            .post(create_spraydetail, { data })
            .then((res) => {
                console.log(res)
                message.success('Tạo bản ghi phun khử khuẩn thành công')
                fetchAllSprayDetails()
                setIsOtherSprayService(false)
                setIsSelectedTypeSpray(false)
            })
            .catch((err) => {
                console.log(err)
                message.error('Lỗi: tạo bản ghi thất bại!')
            })
    }

    const fetchAllSprayDetails = () => {
        request
            .post(admin_get_all_spraydetails_for_city, { forCity: cityId })
            .then((res) => {
                console.log('All service spray', res.data.data.docs)
                const data = res.data.data.docs.map((elm, index) => {
                    return {
                        ...elm,
                        index: index + 1,
                    }
                })
                setAllSpray(data)

                // Sử dụng reduce để lấy danh sách duy nhất theo tên, sử dụng để show ui tên dịch vụ
                const uniqueServices = data.reduce((acc, current) => {
                    const x = acc.find((item) => item.name === current.name)
                    if (!x) {
                        return acc.concat([current])
                    } else {
                        return acc
                    }
                }, [])

                console.log('uniqueServicesSpray', uniqueServices)
                setServiceSprayShowUI(uniqueServices)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const fetchUpdateSpray = (data) => {
        request
            .post(update_spraydetail, { ...data })
            .then((res) => {
                console.log(res)
                message.success('Cập nhật bảng ghi phun khử khuẩn thành công!')
                fetchAllSprayDetails()
            })
            .catch((err) => {
                console.error(err)
                message.error('Lỗi: cập nhật thất bại!')
            })
            .finally(() => {
                setAnchorElEditSpray(false)
            })
    }

    //delete sofa
    const fetchDeleteSpray = (_id) => {
        request
            .post(delete_spraydetail, { _id })
            .then((res) => {
                message.success('Xoá bản ghi phun khử khuẩn thành công!')
                fetchAllSprayDetails()
            })
            .catch((err) => message.error('Lỗi: Xoá bản ghi thất bại!'))
    }

    //open box delete air
    const handleOpenDeleteRowSpray = (e, row) => {
        setAnchorElDeleteSpray(e.currentTarget)
        setCurrentRowSpray(row)
    }
    const handleCloseBoxDeleteSpray = () => {
        setAnchorElDeleteSpray(false)
    }
    const handleConfirmDeleteSpray = () => {
        fetchDeleteSpray(currentRowSpray._id)
        setAnchorElDeleteSpray(false)
    }

    //edit air
    const handleOpenEditSpray = (e, row) => {
        setAnchorElEditSpray(e.currentTarget)
        setCurrentRowSpray(row)
        setInitialValuesSpray({ ...row })
    }
    const handleCloseEditSpray = () => {
        setAnchorElEditAir(false)
    }
    const handleConfirmEditSpray = (values, { resetForm }) => {
        console.log('edit spray', values)

        fetchUpdateSpray(values)
    }

    //column sofa
    const columnsSpray = [
        {
            field: 'index',
            headerName: 'STT',
            width: 100,
        },
        {
            field: 'name',
            headerName: 'Tên dịch vụ',
            minWidth: 100,
            flex: 0.5,
            cellClassName: 'cost-column name-column--cell',
        },
        {
            field: 'description',
            headerName: 'Mô tả',
            minWidth: 100,
            flex: 0.5,
        },
        {
            field: 'price',
            headerName: 'Giá',
            minWidth: 100,
            flex: 0.2,
            cellClassName: 'cost-column name-column--cell',
            valueGetter: (value) => {
                return formatNumber(value) + ' ' + 'VNĐ'
            },
        },
        {
            field: 'actions',
            headerName: 'Hành động',
            minWidth: 100,
            flex: 0.2,
            renderCell: ({ row }) => {
                return (
                    <Box>
                        <IconButton onClick={(e) => handleOpenEditSpray(e, row)}>
                            <Edit />
                        </IconButton>
                        <IconButton onClick={(e) => handleOpenDeleteRowSpray(e, row)}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                )
            },
        },
    ]

    //************************************* SPRAY - END ******************************************* */

    // Setting level discount
    const fetchCreateDiscount = (levelDiscount, event) => {
        request
            .post(create_level_discount, { _id: cityId, levelDiscount })
            .then((res) => {
                console.log(res)
                getSettingCity()
                switch (event) {
                    case 'eventDeleteRowDiscount':
                        openMessageNotification('success', event)
                        break
                    default:
                        openMessageNotification('success', 'createDiscount')
                        break
                }
            })
            .catch((err) => {
                console.log(err)
                openMessageNotification('error')
            })
    }

    const handleSubmitDiscount = async (values, { resetForm }) => {
        if (cityId) {
            try {
                const currentCityData = await getSettingCity()
                const dataDiscount = [...currentCityData.levelDiscount]
                // const { level, discount } = values
                const level = values.level
                const discount = values.discount / 100

                const existingIndex = dataDiscount.findIndex((item) => item.level === Number(level))
                if (existingIndex >= 0) {
                    dataDiscount[existingIndex].discount = Number(discount)
                } else {
                    dataDiscount.push({
                        level: Number(level),
                        discount: Number(discount),
                    })
                }

                console.log('level discount', dataDiscount)

                fetchCreateDiscount(dataDiscount)

                // hàm reset form được cung cấp bởi formik
                resetForm()
            } catch (err) {
                openMessageNotification('error')
            }
        } else {
            openMessageNotification('error')
        }
    }

    //table discount level
    const columnsLevelDiscount = [
        {
            field: 'level',
            headerName: 'Cấp độ người lao động',
            minWidth: 100,
            flex: 0.5,
            cellClassName: 'cost-column name-column--cell',
        },
        {
            field: 'discount',
            headerName: 'Chiết khấu (%)',
            minWidth: 100,
            flex: 0.5,
            cellClassName: 'cost-column name-column--cell',
            renderCell: ({ row }) => {
                return row.discount * 100 + '%'
            },
        },
        {
            field: 'actions',
            headerName: 'Hành động',
            minWidth: 100,
            flex: 0.5,
            renderCell: ({ row }) => {
                return (
                    <IconButton onClick={(e) => handleDeleteRowDiscount(e, row)}>
                        <DeleteIcon />
                    </IconButton>
                )
            },
        },
    ]

    const [anchorElDeleteDiscount, setAnchorElDeleteDiscount] = useState(null)
    const [currentRowDiscount, setCurrentRowDiscount] = useState(null)
    const handleDeleteRowDiscount = (e, row) => {
        setAnchorElDeleteDiscount(e.currentTarget)
        setCurrentRowDiscount(row)
    }
    const handleCloseBoxDeleteDiscount = () => {
        setAnchorElDeleteDiscount(false)
    }
    const handleConfirmDeleteDiscount = () => {
        const dataDiscount = [...rowsDiscount]
        // tìm kiếm index data cần xoá
        const existingIndex = dataDiscount.findIndex((elm) => elm.level === Number(currentRowDiscount.level))
        const dataWillDelete = dataDiscount[existingIndex]
        console.log(dataWillDelete)

        // lọc data cần xoá khỏi data city
        const dataUpdated = dataSettingCity.levelDiscount.filter((elm) => {
            return !(elm.level === Number(dataWillDelete.level))
        })
        console.log(dataUpdated)

        // fetch data
        fetchCreateDiscount(dataUpdated, 'eventDeleteRowDiscount')

        setAnchorElDeleteDiscount(false)
    }

    // style
    const styleBorderPanel = {
        borderTop: '1px solid',
        borderBottom: '1px solid',
        borderColor: '#b6bbbc8a',
        padding: '20px 0px',
        borderRadius: '4px',
    }
    const styleButtonSubmit = {
        bgcolor: theme.palette.mode === 'dark' ? colors.greenAccent[700] : '#3da58a',
        color: 'white',
        fontWeight: '600',
        '&:hover': {
            bgcolor: theme.palette.mode === 'dark' ? colors.greenAccent[600] : 'rgb(61 165 138 / 80%)',
        },
        width: '100px',
        height: '38px',
    }
    const styleModal = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: 230,
        height: 100,
        transform: 'translate(-50%, -50%)',
        bgcolor: theme.palette.mode === 'dark' ? colors.primary[300] : 'white',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        border: 'unset',
        borderRadius: '4px',
        pb: '62px',
        pt: '24px',
        '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
            color: `${colors.grey[100]} !important`,
        },
    }

    return (
        <Box
            m='20px'
            sx={{
                '& .MuiTypography-root.MuiTypography-h2': {
                    margin: '0 0 5px 0',
                },
                '& .MuiInputLabel-outlined, .MuiInputLabel-formControl.Mui-focused': {
                    color: `${colors.greenAccent[400]}`,
                },
                '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: `${colors.grey[100]} !important`,
                    borderWidth: '1px !important',
                },
                '& .MuiFilledInput-root::after': {
                    borderColor: colors.grey[100],
                },
                '& .MuiFormControl-root': { mb: '20px', minWidth: '200px' },
                '& .MuiFilledInput-root::after, .MuiInput-underline::after': {
                    borderColor: colors.greenAccent[400],
                },
            }}
        >
            {messageContextHolder}
            <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Header title='CÀI ĐẶT' subtitle='Trang cài đặt' />
            </Box>
            <Box
                height='auto'
                m='40px 0 0 0'
                p='40px'
                sx={{
                    width: '100%',
                    typography: 'body1',
                    bgcolor: theme.palette.mode === 'dark' ? 'rgb(75 93 131 / 38%)' : '#e7eaff14',
                    borderRadius: '4px',
                    boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
                }}
            >
                {/* phần cài đặt doanh nghiệp */}
                {role === 'doanhnghiep' && (
                    <Box
                        sx={{
                            ...styleBorderPanel,
                            flexGrow: 1,
                            minHeight: '58vh',
                            display: 'flex',
                        }}
                    >
                        {/* Tab dọc: các menu setting chính */}
                        <Tabs
                            orientation='vertical'
                            variant='scrollable'
                            value={valueTab}
                            onChange={handleChangeTab}
                            aria-label='Vertical tabs example'
                            sx={{
                                borderRight: 1,
                                borderColor: 'divider',
                                width: '200px',
                                '& .MuiTabs-flexContainer .MuiButtonBase-root': {
                                    fontWeight: '600',
                                },
                                '& .MuiTab-root.Mui-selected': {
                                    color: theme.palette.mode === 'dark' ? '#acb0e6' : colors.blueAccent[400],
                                    bgcolor: theme.palette.mode === 'dark' ? 'rgb(76 87 130 / 35%)' : 'rgb(172 182 220 / 35%)',
                                },
                                '& .MuiTabs-indicator': {
                                    bgcolor: colors.blueAccent[500],
                                },
                            }}
                        >
                            <Tab label='Cài đặt công việc' {...a11yProps(0)} />
                            <Tab label='Cài đặt chiết khấu' {...a11yProps(1)} />
                        </Tabs>

                        {/* Tab content: nội dung cuả các tab chính */}
                        {/* Setting công việc và giá [DOANHNGHIEP] */}
                        <TabPanel value={valueTab} index={0}>
                            <Box sx={{ mb: '50px' }}>
                                <Typography fontWeight='600'>2. Cài đặt công việc:</Typography>
                                <Box display='grid' gridTemplateColumns='repeat(12, 1fr)' gap='20px' p='0 24px' mt={'20px'}>
                                    <Box sx={{ typography: 'body1' }} gridColumn='span 12'>
                                        <Box>
                                            <Box>
                                                <Tabs
                                                    variant='scrollable'
                                                    value={valueTabJob}
                                                    onChange={handleChangeTabJob}
                                                    aria-label='full width tabs example'
                                                    sx={{
                                                        borderBottom: 1,
                                                        borderColor: 'divider',
                                                        '& .MuiTabs-flexContainer .MuiButtonBase-root': {
                                                            fontWeight: '600',
                                                        },
                                                        '& .MuiTab-root.Mui-selected': {
                                                            color: theme.palette.mode === 'dark' ? '#acb0e6' : colors.blueAccent[400],
                                                            bgcolor:
                                                                theme.palette.mode === 'dark' ? 'rgb(76 87 130 / 35%)' : 'rgb(172 182 220 / 35%)',
                                                        },
                                                        '& .MuiTabs-indicator': {
                                                            bgcolor: colors.blueAccent[500],
                                                        },
                                                    }}
                                                >
                                                    <Tab label='Ca lẻ' {...a11xProps(0)} />
                                                    <Tab label='Cài cố định' {...a11xProps(1)} />
                                                    <Tab label='Tổng vệ sinh' {...a11xProps(2)} />
                                                    <Tab label='Điều hoà' {...a11xProps(3)} />
                                                    <Tab label='Sofa' {...a11xProps(4)} />
                                                    <Tab label='Phun khử khuẩn' {...a11xProps(5)} />
                                                </Tabs>
                                                <TabPanelJob value={valueTabJob} index={0}>
                                                    <Box>
                                                        <Tabs
                                                            variant='scrollable'
                                                            value={valueTabTaskPrice}
                                                            onChange={handleChangeTabTask}
                                                            sx={{
                                                                '& .MuiTab-root.Mui-selected': {
                                                                    color: colors.grey[100],
                                                                },
                                                                '& .MuiTabs-indicator': {
                                                                    bgcolor: colors.grey[100],
                                                                },
                                                            }}
                                                        >
                                                            <Tab label='Mặc định' {...a11xProps(0)} />
                                                            <Tab label='Tuỳ chọn' {...a11xProps(1)} />
                                                        </Tabs>
                                                        <TabPanelTaskPrice value={valueTabTaskPrice} index={0}>
                                                            <Formik
                                                                initialValues={formDataTaskPrice}
                                                                onSubmit={handleSubmitTaskDefaultPrice}
                                                                validationSchema={validationTaskPriceSchema}
                                                            >
                                                                {({ values, errors, touched, handleBlur, handleChange, setFieldValue }) => (
                                                                    <Form>
                                                                        <Box
                                                                            sx={{
                                                                                // p: '24px',
                                                                                mb: '20px',
                                                                                '& .MuiInputLabel-formControl.Mui-error.Mui-focused': {
                                                                                    // change label textField when focused input
                                                                                    fontSize: '16px !important',
                                                                                    color: theme.palette.mode === 'dark' ? '#f44336' : '#d32f2f',
                                                                                },
                                                                                '& .MuiFormHelperText-root.Mui-error': {
                                                                                    fontSize: '14px',
                                                                                },
                                                                            }}
                                                                        >
                                                                            <Box display='flex' flexDirection='column'>
                                                                                <TextField
                                                                                    label='Số giờ làm'
                                                                                    variant='filled'
                                                                                    name='estimateTime'
                                                                                    value={values.estimateTime}
                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                    error={!!touched.estimateTime && !!errors.estimateTime}
                                                                                    helperText={touched.estimateTime && errors.estimateTime}
                                                                                    InputLabelProps={{
                                                                                        sx: {
                                                                                            '&.MuiInputLabel-filled': {
                                                                                                fontSize: '14px',
                                                                                            },
                                                                                        },
                                                                                    }}
                                                                                />
                                                                                <TextField
                                                                                    label='Giá tiền'
                                                                                    variant='filled'
                                                                                    name='price'
                                                                                    // value={values.price}
                                                                                    // onChange={handleChange}
                                                                                    value={formatNumber(values.price)}
                                                                                    onChange={(e) => {
                                                                                        const formattedValue = parseNumber(e.target.value)
                                                                                        setFieldValue('price', formattedValue)
                                                                                    }}
                                                                                    onBlur={handleBlur}
                                                                                    error={!!touched.price && !!errors.price}
                                                                                    helperText={touched.price && errors.price}
                                                                                    InputLabelProps={{
                                                                                        sx: {
                                                                                            '&.MuiInputLabel-filled': {
                                                                                                fontSize: '14px',
                                                                                            },
                                                                                        },
                                                                                    }}
                                                                                />
                                                                            </Box>
                                                                            <Box>
                                                                                <Button
                                                                                    type='submit'
                                                                                    sx={{
                                                                                        ...styleButtonSubmit,
                                                                                    }}
                                                                                >
                                                                                    Chấp nhận
                                                                                </Button>
                                                                            </Box>
                                                                        </Box>
                                                                    </Form>
                                                                )}
                                                            </Formik>
                                                            <Divider sx={{ mb: '20px' }} />
                                                            <Box
                                                                sx={{
                                                                    height: 500,
                                                                    width: '100%',
                                                                    '& .actions': {
                                                                        color: 'text.secondary',
                                                                    },
                                                                    '& .textPrimary': {
                                                                        color: 'text.primary',
                                                                    },
                                                                    '& .MuiDataGrid-row--editing .MuiDataGrid-cell': {
                                                                        bgcolor: (theme) =>
                                                                            theme.palette.mode === 'dark'
                                                                                ? '#376331 !important'
                                                                                : 'rgb(217 243 190) !important',
                                                                    },
                                                                }}
                                                            >
                                                                <Button color='info'>Bảng ghi</Button>
                                                                <DataGrid
                                                                    rows={rows}
                                                                    columns={columnsTaskDefault}
                                                                    editMode='row'
                                                                    rowModesModel={rowModesModel}
                                                                    onRowModesModelChange={handleRowModesModelChange}
                                                                    onRowEditStop={handleRowEditStop}
                                                                    processRowUpdate={processRowUpdate}
                                                                    slots={{
                                                                        // toolbar: EditToolbar,
                                                                        toolbar: GridToolbar,
                                                                    }}
                                                                    slotProps={{
                                                                        toolbar: {
                                                                            setRows,
                                                                            setRowModesModel,
                                                                            showQuickFilter: true,
                                                                        },
                                                                    }}
                                                                    sx={{
                                                                        ...StyleDataGrid(),
                                                                        '& .edit-price': {
                                                                            '& .MuiInputBase-input': {
                                                                                fontSize: '14px !important',
                                                                            },
                                                                        },
                                                                    }}
                                                                />
                                                            </Box>
                                                        </TabPanelTaskPrice>
                                                        <TabPanelTaskPrice value={valueTabTaskPrice} index={1}>
                                                            <Formik
                                                                initialValues={formDataTaskPriceSpecial}
                                                                onSubmit={handleSubmitTaskDefaultPriceSpecial}
                                                                validationSchema={validationTaskPriceSpecialSchema}
                                                            >
                                                                {({ values, errors, touched, handleBlur, handleChange, setFieldValue }) => (
                                                                    <Form>
                                                                        <Box
                                                                            sx={{
                                                                                mb: '20px',
                                                                                '& .MuiInputLabel-formControl.Mui-error.Mui-focused': {
                                                                                    fontSize: '16px !important',
                                                                                    color: theme.palette.mode === 'dark' ? '#f44336' : '#d32f2f',
                                                                                },
                                                                                '& .MuiFormHelperText-root.Mui-error': {
                                                                                    fontSize: '14px',
                                                                                },
                                                                            }}
                                                                        >
                                                                            <Box display='flex' flexDirection='column'>
                                                                                <FormControl variant='filled' error={!!touched.day && !!errors.day}>
                                                                                    <InputLabel>Ngày</InputLabel>
                                                                                    <Select
                                                                                        name='day'
                                                                                        value={values.day}
                                                                                        onChange={handleChange}
                                                                                        onBlur={handleBlur}
                                                                                    >
                                                                                        {days.map((day, index) => (
                                                                                            <MenuItem key={index} value={day}>
                                                                                                {day}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Select>
                                                                                    {touched.day && errors.day && (
                                                                                        <FormHelperText>{errors.day}</FormHelperText>
                                                                                    )}
                                                                                </FormControl>
                                                                                {values.day === 'Tất cả ngày' ? (
                                                                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                                        <TimePicker
                                                                                            name='time'
                                                                                            value={values.time}
                                                                                            onChange={(newValue) => setFieldValue('time', newValue)}
                                                                                            slotProps={{
                                                                                                textField: {
                                                                                                    variant: 'outlined',
                                                                                                    margin: 'normal',
                                                                                                    error: touched.time && !!errors.time,
                                                                                                    helperText: touched.time && errors.time,
                                                                                                    fullWidth: true,
                                                                                                },
                                                                                            }}
                                                                                        />
                                                                                    </LocalizationProvider>
                                                                                ) : (
                                                                                    <></>
                                                                                )}
                                                                                <TextField
                                                                                    label='Số giờ làm'
                                                                                    variant='filled'
                                                                                    name='estimateTime'
                                                                                    value={values.estimateTime}
                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                    error={!!touched.estimateTime && !!errors.estimateTime}
                                                                                    helperText={touched.estimateTime && errors.estimateTime}
                                                                                    InputLabelProps={{
                                                                                        sx: {
                                                                                            '&.MuiInputLabel-filled': {
                                                                                                fontSize: '14px',
                                                                                            },
                                                                                        },
                                                                                    }}
                                                                                />
                                                                                <TextField
                                                                                    label='Giá tiền'
                                                                                    variant='filled'
                                                                                    name='price'
                                                                                    // value={values.price}
                                                                                    // onChange={handleChange}
                                                                                    value={formatNumber(values.price)}
                                                                                    onChange={(e) => {
                                                                                        const formattedValue = parseNumber(e.target.value)
                                                                                        setFieldValue('price', formattedValue)
                                                                                    }}
                                                                                    onBlur={handleBlur}
                                                                                    error={!!touched.price && !!errors.price}
                                                                                    helperText={touched.price && errors.price}
                                                                                    InputLabelProps={{
                                                                                        sx: {
                                                                                            '&.MuiInputLabel-filled': {
                                                                                                fontSize: '14px',
                                                                                            },
                                                                                        },
                                                                                    }}
                                                                                />
                                                                            </Box>
                                                                            <Box>
                                                                                <Button
                                                                                    type='submit'
                                                                                    sx={{
                                                                                        ...styleButtonSubmit,
                                                                                    }}
                                                                                >
                                                                                    Chấp nhận
                                                                                </Button>
                                                                            </Box>
                                                                        </Box>
                                                                    </Form>
                                                                )}
                                                            </Formik>
                                                            <Divider sx={{ mb: '20px' }} />
                                                            <Box
                                                                sx={{
                                                                    height: 500,
                                                                    width: '100%',
                                                                    '& .actions': {
                                                                        color: 'text.secondary',
                                                                    },
                                                                    '& .textPrimary': {
                                                                        color: 'text.primary',
                                                                    },
                                                                    '& .MuiDataGrid-row--editing .MuiDataGrid-cell': {
                                                                        bgcolor: (theme) =>
                                                                            theme.palette.mode === 'dark'
                                                                                ? '#376331 !important'
                                                                                : 'rgb(217 243 190) !important',
                                                                    },
                                                                }}
                                                            >
                                                                <Button color='info'>Bảng ghi</Button>
                                                                <DataGrid
                                                                    rows={rows}
                                                                    columns={columnsTaskSpecial}
                                                                    getRowId={(row) => row?.id ?? row._id}
                                                                    editMode='row'
                                                                    rowModesModel={rowModesModelTaskPriceSpecial}
                                                                    onRowModesModelChange={handleRowModesModelChangeTaskPriceSpecial}
                                                                    onRowEditStop={handleRowEditStopTaskPriceSpecial}
                                                                    processRowUpdate={processRowUpdateTaskPriceSpecial}
                                                                    slots={{
                                                                        // toolbar: EditToolbarTaskSpecial,
                                                                        toolbar: GridToolbar,
                                                                    }}
                                                                    slotProps={{
                                                                        toolbar: {
                                                                            setRows,
                                                                            setRowModesModelTaskPriceSpecial,
                                                                            showQuickFilter: true,
                                                                        },
                                                                    }}
                                                                    sx={{
                                                                        ...StyleDataGrid(),
                                                                        '& .edit-price': {
                                                                            '& .MuiInputBase-input': {
                                                                                fontSize: '14px !important',
                                                                            },
                                                                        },
                                                                    }}
                                                                />
                                                            </Box>
                                                        </TabPanelTaskPrice>
                                                    </Box>
                                                </TabPanelJob>
                                                <TabPanelJob value={valueTabJob} index={1}>
                                                    <Formik
                                                        initialValues={formDataPermanentPrice}
                                                        onSubmit={handleSubmitPermanentPrice}
                                                        validationSchema={validationPermanentAndCleanPriceSchema}
                                                    >
                                                        {({ values, errors, touched, handleBlur, handleChange, setFieldValue }) => (
                                                            <Form name='formPermanentTask'>
                                                                <Box
                                                                    display='flex'
                                                                    flexDirection='column'
                                                                    sx={{
                                                                        mb: '20px',
                                                                        '& .MuiInputLabel-formControl.Mui-error.Mui-focused': {
                                                                            // change label textField when focused input
                                                                            fontSize: '16px !important',
                                                                            color: theme.palette.mode === 'dark' ? '#f44336' : '#d32f2f',
                                                                        },
                                                                        '& .MuiFormHelperText-root.Mui-error': {
                                                                            fontSize: '14px',
                                                                        },
                                                                    }}
                                                                >
                                                                    <TextField
                                                                        label='Số giờ làm'
                                                                        variant='filled'
                                                                        name='estimateTime'
                                                                        value={values.estimateTime}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        error={!!touched.estimateTime && !!errors.estimateTime}
                                                                        helperText={touched.estimateTime && errors.estimateTime}
                                                                        InputLabelProps={{
                                                                            sx: {
                                                                                '&.MuiInputLabel-filled': {
                                                                                    fontSize: '14px',
                                                                                },
                                                                            },
                                                                        }}
                                                                    />
                                                                    <TextField
                                                                        label='Giá tiền'
                                                                        variant='filled'
                                                                        name='pricePerDay'
                                                                        // value={values.pricePerDay}
                                                                        // onChange={handleChange}
                                                                        value={formatNumber(values.pricePerDay)}
                                                                        onChange={(e) => {
                                                                            const formattedValue = parseNumber(e.target.value)
                                                                            setFieldValue('pricePerDay', formattedValue)
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                        error={!!touched.pricePerDay && !!errors.pricePerDay}
                                                                        helperText={touched.pricePerDay && errors.pricePerDay}
                                                                        InputLabelProps={{
                                                                            sx: {
                                                                                '&.MuiInputLabel-filled': {
                                                                                    fontSize: '14px',
                                                                                },
                                                                            },
                                                                        }}
                                                                    />
                                                                </Box>
                                                                <Box>
                                                                    <Button
                                                                        type='submit'
                                                                        sx={{
                                                                            ...styleButtonSubmit,
                                                                        }}
                                                                    >
                                                                        Chấp nhận
                                                                    </Button>
                                                                </Box>
                                                            </Form>
                                                        )}
                                                    </Formik>
                                                    <Divider sx={{ mb: '20px', mt: '20px' }} />
                                                    <Box
                                                        sx={{
                                                            height: 500,
                                                            width: '100%',
                                                            '& .actions': {
                                                                color: 'text.secondary',
                                                            },
                                                            '& .textPrimary': {
                                                                color: 'text.primary',
                                                            },
                                                        }}
                                                    >
                                                        <Button color='info'>Bảng ghi</Button>
                                                        <DataGrid
                                                            rows={rowsPermanentPrice}
                                                            columns={columnsPermanentPrice}
                                                            // editMode='row'
                                                            // rowModesModel={rowModesModelPermanentPrice}
                                                            // onRowModesModelChange={handleRowModesModelChangePermanentPrice}
                                                            // onRowEditStop={handleRowEditStopPermanentPrice}
                                                            // processRowUpdate={processRowUpdatePermanentPrice}
                                                            slots={{
                                                                // toolbar: EditToolbar,
                                                                toolbar: GridToolbar,
                                                            }}
                                                            slotProps={{
                                                                toolbar: {
                                                                    showQuickFilter: true,
                                                                },
                                                            }}
                                                            sx={{ ...StyleDataGrid() }}
                                                        />
                                                    </Box>
                                                </TabPanelJob>
                                                <TabPanelJob value={valueTabJob} index={2}>
                                                    <Formik
                                                        initialValues={formDataCleanPrice}
                                                        onSubmit={handleSubmitCleanPrice}
                                                        validationSchema={validationPermanentAndCleanPriceSchema}
                                                    >
                                                        {({ values, errors, touched, handleBlur, handleChange, setFieldValue }) => (
                                                            <Form name='formCleanPrice'>
                                                                <Box
                                                                    display='flex'
                                                                    flexDirection='column'
                                                                    sx={{
                                                                        mb: '20px',
                                                                        '& .MuiInputLabel-formControl.Mui-error.Mui-focused': {
                                                                            // change label textField when focused input
                                                                            fontSize: '16px !important',
                                                                            color: theme.palette.mode === 'dark' ? '#f44336' : '#d32f2f',
                                                                        },
                                                                        '& .MuiFormHelperText-root.Mui-error': {
                                                                            fontSize: '14px',
                                                                        },
                                                                    }}
                                                                >
                                                                    <TextField
                                                                        label='Số giờ làm'
                                                                        variant='filled'
                                                                        name='estimateTime'
                                                                        value={values.estimateTime}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        error={!!touched.estimateTime && !!errors.estimateTime}
                                                                        helperText={touched.estimateTime && errors.estimateTime}
                                                                        InputLabelProps={{
                                                                            sx: {
                                                                                '&.MuiInputLabel-filled': {
                                                                                    fontSize: '14px',
                                                                                },
                                                                            },
                                                                        }}
                                                                    />
                                                                    <TextField
                                                                        label='Tiền công'
                                                                        variant='filled'
                                                                        name='pricePerDay'
                                                                        // value={values.pricePerDay}
                                                                        // onChange={handleChange}
                                                                        value={formatNumber(values.pricePerDay)}
                                                                        onChange={(e) => {
                                                                            const formattedValue = parseNumber(e.target.value)
                                                                            setFieldValue('pricePerDay', formattedValue)
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                        error={!!touched.pricePerDay && !!errors.pricePerDay}
                                                                        helperText={touched.pricePerDay && errors.pricePerDay}
                                                                        InputLabelProps={{
                                                                            sx: {
                                                                                '&.MuiInputLabel-filled': {
                                                                                    fontSize: '14px',
                                                                                },
                                                                            },
                                                                        }}
                                                                    />
                                                                </Box>
                                                                <Box>
                                                                    <Button
                                                                        type='submit'
                                                                        sx={{
                                                                            ...styleButtonSubmit,
                                                                        }}
                                                                    >
                                                                        Chấp nhận
                                                                    </Button>
                                                                </Box>
                                                            </Form>
                                                        )}
                                                    </Formik>
                                                    <Divider sx={{ mb: '20px', mt: '20px' }} />
                                                    <Box
                                                        sx={{
                                                            height: 500,
                                                            width: '100%',
                                                            '& .actions': {
                                                                color: 'text.secondary',
                                                            },
                                                            '& .textPrimary': {
                                                                color: 'text.primary',
                                                            },
                                                        }}
                                                    >
                                                        <Button color='info'>Bảng ghi</Button>
                                                        <DataGrid
                                                            rows={rowsCleanPrice}
                                                            columns={columnsCleanPrice}
                                                            slots={{
                                                                toolbar: GridToolbar,
                                                            }}
                                                            slotProps={{
                                                                toolbar: {
                                                                    showQuickFilter: true,
                                                                },
                                                            }}
                                                            sx={{ ...StyleDataGrid() }}
                                                        />
                                                    </Box>
                                                </TabPanelJob>
                                                <TabPanelJob value={valueTabJob} index={3}>
                                                    <Formik
                                                        initialValues={formDataAirConditionPrice}
                                                        onSubmit={handleSubmitAirConditionPrice}
                                                        validationSchema={validationAirAndSofaAndSpraySchema(
                                                            isOtherAirService,
                                                            isSelectedTypeAir,
                                                            serviceAirShowUI
                                                        )}
                                                    >
                                                        {({
                                                            values,
                                                            errors,
                                                            touched,
                                                            handleBlur,
                                                            handleChange,
                                                            setFieldValue,
                                                            setErrors,
                                                            setTouched,
                                                        }) => (
                                                            <Form>
                                                                <Box
                                                                    display='flex'
                                                                    flexDirection='column'
                                                                    sx={{
                                                                        mb: '20px',
                                                                        '& .MuiInputLabel-formControl.Mui-error.Mui-focused': {
                                                                            // change label textField when focused input
                                                                            fontSize: '16px !important',
                                                                            color: theme.palette.mode === 'dark' ? '#f44336' : '#d32f2f',
                                                                        },
                                                                        '& .MuiFormHelperText-root.Mui-error': {
                                                                            fontSize: '14px',
                                                                        },
                                                                    }}
                                                                >
                                                                    {allAirCondition.length > 0 && (
                                                                        <TextField
                                                                            select
                                                                            label='Chọn dịch vụ'
                                                                            name='service'
                                                                            value={values.service}
                                                                            onChange={(e) => {
                                                                                handleChange(e)
                                                                                console.log('service', e.target.value)

                                                                                const selectedService = serviceAirShowUI.find(
                                                                                    (service) => service._id === e.target.value
                                                                                )
                                                                                if (selectedService) {
                                                                                    setFieldValue('type', selectedService.type)
                                                                                    setIsOtherAirService(false)
                                                                                } else {
                                                                                    setIsOtherAirService(true)
                                                                                }

                                                                                //reset lỗi khi select dịch vụ mới
                                                                                setErrors({})
                                                                                setTouched({})
                                                                            }}
                                                                            onBlur={handleBlur}
                                                                            error={!!touched.service && !!errors.service}
                                                                            helperText={touched.service && errors.service}
                                                                            variant='filled'
                                                                        >
                                                                            {serviceAirShowUI.map((service) => (
                                                                                <MenuItem key={service._id} value={service._id}>
                                                                                    {service.name}
                                                                                </MenuItem>
                                                                            ))}
                                                                            <MenuItem key='new-service' value='new-service'>
                                                                                Thêm mới dịch vụ
                                                                            </MenuItem>
                                                                        </TextField>
                                                                    )}

                                                                    {isOtherAirService && (
                                                                        <Autocomplete
                                                                            freeSolo
                                                                            options={serviceAirShowUI || []}
                                                                            value={
                                                                                serviceAirShowUI.find((service) => service.type === values.type) || ''
                                                                            }
                                                                            onChange={(event, value) => {
                                                                                console.log('Selected value:', value)
                                                                                if (value) {
                                                                                    console.log(value)

                                                                                    // Nếu chọn một nhóm dịch vụ có sẵn, set type theo nhóm dịch vụ đã chọn
                                                                                    setFieldValue('type', value.type)
                                                                                    setIsSelectedTypeAir(true)
                                                                                } else {
                                                                                    // Nếu không chọn gì, tự động sinh type mới
                                                                                    setFieldValue('type', serviceAirShowUI.length + 1)
                                                                                    setIsSelectedTypeAir(false)
                                                                                }
                                                                            }}
                                                                            getOptionLabel={(service) => service?.name || ''}
                                                                            renderInput={(params) => (
                                                                                <TextField
                                                                                    {...params}
                                                                                    onBlur={handleBlur}
                                                                                    label='Chọn nhóm dịch vụ'
                                                                                    name='type'
                                                                                    helperText='Nếu không chọn nhóm dịch vụ, nhóm dịch vụ sẽ tự sinh.'
                                                                                />
                                                                            )}
                                                                            renderOption={(props, option) => (
                                                                                <li {...props} key={option._id} style={{ marginTop: '10px' }}>
                                                                                    <Box width='100%'>
                                                                                        <Box display='flex' justifyContent='space-between'>
                                                                                            <Typography>{option.name}</Typography>
                                                                                            <Typography color={colors.greenAccent[400]}>
                                                                                                type: {option.type}
                                                                                            </Typography>
                                                                                        </Box>
                                                                                    </Box>
                                                                                </li>
                                                                            )}
                                                                        />
                                                                    )}

                                                                    {(allAirCondition.length < 1 || (isOtherAirService && !isSelectedTypeAir)) && (
                                                                        <TextField
                                                                            label='Tên dịch vụ mới'
                                                                            name='name'
                                                                            value={values.name}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            error={!!touched.name && !!errors.name}
                                                                            helperText={touched.name && errors.name}
                                                                            variant='filled'
                                                                        />
                                                                    )}

                                                                    <TextField
                                                                        label='Nhập mô tả'
                                                                        name='description'
                                                                        value={values.description}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        error={!!touched.description && !!errors.description}
                                                                        helperText={touched.description && errors.description}
                                                                        variant='filled'
                                                                    />
                                                                    <TextField
                                                                        label={'Giá tiền'}
                                                                        variant='filled'
                                                                        name='price'
                                                                        value={formatNumber(values.price)}
                                                                        onChange={(e) => {
                                                                            const formattedValue = parseNumber(e.target.value)
                                                                            setFieldValue('price', formattedValue)
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                        error={!!touched.price && !!errors.price}
                                                                        helperText={touched.price && errors.price}
                                                                        InputLabelProps={{
                                                                            sx: {
                                                                                '&.MuiInputLabel-filled': {
                                                                                    fontSize: '14px',
                                                                                },
                                                                            },
                                                                        }}
                                                                    />
                                                                </Box>
                                                                <Box>
                                                                    <Button
                                                                        type='submit'
                                                                        sx={{
                                                                            ...styleButtonSubmit,
                                                                        }}
                                                                    >
                                                                        Chấp nhận
                                                                    </Button>
                                                                </Box>
                                                            </Form>
                                                        )}
                                                    </Formik>
                                                    <Divider sx={{ mb: '20px', mt: '20px' }} />
                                                    <Box
                                                        sx={{
                                                            height: 500,
                                                            width: '100%',
                                                            '& .actions': {
                                                                color: 'text.secondary',
                                                            },
                                                            '& .textPrimary': {
                                                                color: 'text.primary',
                                                            },
                                                        }}
                                                    >
                                                        <Button color='info'>Bảng ghi</Button>
                                                        <DataGrid
                                                            getRowId={(row) => row._id}
                                                            rows={allAirCondition}
                                                            columns={columnsAirConditionPrice}
                                                            slots={{
                                                                toolbar: GridToolbar,
                                                            }}
                                                            slotProps={{
                                                                toolbar: {
                                                                    showQuickFilter: true,
                                                                },
                                                            }}
                                                            sx={{ ...StyleDataGrid() }}
                                                        />
                                                    </Box>
                                                </TabPanelJob>
                                                <TabPanelJob value={valueTabJob} index={4}>
                                                    <Formik
                                                        initialValues={formDataSofaPrice}
                                                        onSubmit={handleSubmitSofaPrice}
                                                        // validationSchema={validationSofaSchema}
                                                        validationSchema={validationAirAndSofaAndSpraySchema(
                                                            isOtherSofaService,
                                                            isSelectedTypeSofa,
                                                            serviceSofaShowUI
                                                        )}
                                                    >
                                                        {({
                                                            values,
                                                            errors,
                                                            touched,
                                                            handleBlur,
                                                            handleChange,
                                                            setFieldValue,
                                                            setErrors,
                                                            setTouched,
                                                        }) => (
                                                            <Form>
                                                                <Box
                                                                    display='flex'
                                                                    flexDirection='column'
                                                                    sx={{
                                                                        mb: '20px',
                                                                        '& .MuiInputLabel-formControl.Mui-error.Mui-focused': {
                                                                            // change label textField when focused input
                                                                            fontSize: '16px !important',
                                                                            color: theme.palette.mode === 'dark' ? '#f44336' : '#d32f2f',
                                                                        },
                                                                        '& .MuiFormHelperText-root.Mui-error': {
                                                                            fontSize: '14px',
                                                                        },
                                                                    }}
                                                                >
                                                                    {allSofa?.length > 0 && (
                                                                        <TextField
                                                                            select
                                                                            label='Chọn dịch vụ'
                                                                            name='service'
                                                                            value={values.service}
                                                                            onChange={(e) => {
                                                                                handleChange(e)
                                                                                console.log('service', e.target.value)

                                                                                const selectedService = serviceSofaShowUI.find(
                                                                                    (service) => service._id === e.target.value
                                                                                )
                                                                                if (selectedService) {
                                                                                    setFieldValue('type', selectedService.type)
                                                                                    setIsOtherSofaService(false)
                                                                                } else {
                                                                                    setIsOtherSofaService(true)
                                                                                }

                                                                                //reset lỗi khi select dịch vụ mới
                                                                                setErrors({})
                                                                                setTouched({})
                                                                            }}
                                                                            onBlur={handleBlur}
                                                                            error={!!touched.service && !!errors.service}
                                                                            helperText={touched.service && errors.service}
                                                                            variant='filled'
                                                                        >
                                                                            {serviceSofaShowUI.map((service) => (
                                                                                <MenuItem key={service._id} value={service._id}>
                                                                                    {service.name}
                                                                                </MenuItem>
                                                                            ))}
                                                                            <MenuItem key='new-service' value='new-service'>
                                                                                Thêm mới dịch vụ
                                                                            </MenuItem>
                                                                        </TextField>
                                                                    )}

                                                                    {isOtherSofaService && (
                                                                        <Autocomplete
                                                                            freeSolo
                                                                            options={serviceSofaShowUI || []}
                                                                            value={
                                                                                serviceSofaShowUI?.find((service) => service.type === values.type) ||
                                                                                ''
                                                                            }
                                                                            onChange={(event, value) => {
                                                                                console.log('Selected value:', value)
                                                                                if (value) {
                                                                                    console.log(value)

                                                                                    // Nếu chọn một nhóm dịch vụ có sẵn, set type theo nhóm dịch vụ đã chọn
                                                                                    setFieldValue('type', value.type)
                                                                                    setIsSelectedTypeSofa(true)
                                                                                } else {
                                                                                    // Nếu không chọn gì, tự động sinh type mới
                                                                                    setFieldValue('type', serviceSofaShowUI.length + 1)
                                                                                    setIsSelectedTypeSofa(false)
                                                                                }
                                                                            }}
                                                                            getOptionLabel={(service) => service?.name || ''}
                                                                            renderInput={(params) => (
                                                                                <TextField
                                                                                    {...params}
                                                                                    onBlur={handleBlur}
                                                                                    label='Chọn nhóm dịch vụ'
                                                                                    name='type'
                                                                                    helperText='Nếu không chọn nhóm dịch vụ, nhóm dịch vụ sẽ tự sinh.'
                                                                                />
                                                                            )}
                                                                            renderOption={(props, option) => (
                                                                                <li {...props} key={option._id} style={{ marginTop: '10px' }}>
                                                                                    <Box width='100%'>
                                                                                        <Box display='flex' justifyContent='space-between'>
                                                                                            <Typography>{option.name}</Typography>
                                                                                            <Typography color={colors.greenAccent[400]}>
                                                                                                type: {option.type}
                                                                                            </Typography>
                                                                                        </Box>
                                                                                    </Box>
                                                                                </li>
                                                                            )}
                                                                        />
                                                                    )}

                                                                    {(allSofa?.length < 1 || (isOtherSofaService && !isSelectedTypeSofa)) && (
                                                                        <TextField
                                                                            label='Tên dịch vụ mới'
                                                                            name='name'
                                                                            value={values.name}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            error={!!touched.name && !!errors.name}
                                                                            helperText={touched.name && errors.name}
                                                                            variant='filled'
                                                                        />
                                                                    )}

                                                                    <TextField
                                                                        label='Nhập mô tả'
                                                                        name='description'
                                                                        value={values.description}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        error={!!touched.description && !!errors.description}
                                                                        helperText={touched.description && errors.description}
                                                                        variant='filled'
                                                                    />
                                                                    <TextField
                                                                        label={'Giá tiền'}
                                                                        variant='filled'
                                                                        name='price'
                                                                        value={formatNumber(values.price)}
                                                                        onChange={(e) => {
                                                                            const formattedValue = parseNumber(e.target.value)
                                                                            setFieldValue('price', formattedValue)
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                        error={!!touched.price && !!errors.price}
                                                                        helperText={touched.price && errors.price}
                                                                        InputLabelProps={{
                                                                            sx: {
                                                                                '&.MuiInputLabel-filled': {
                                                                                    fontSize: '14px',
                                                                                },
                                                                            },
                                                                        }}
                                                                    />
                                                                </Box>
                                                                <Box>
                                                                    <Button
                                                                        type='submit'
                                                                        sx={{
                                                                            ...styleButtonSubmit,
                                                                        }}
                                                                    >
                                                                        Chấp nhận
                                                                    </Button>
                                                                </Box>
                                                            </Form>
                                                        )}
                                                    </Formik>
                                                    <Divider sx={{ mb: '20px', mt: '20px' }} />
                                                    <Box
                                                        sx={{
                                                            height: 500,
                                                            width: '100%',
                                                            '& .actions': {
                                                                color: 'text.secondary',
                                                            },
                                                            '& .textPrimary': {
                                                                color: 'text.primary',
                                                            },
                                                        }}
                                                    >
                                                        <Button color='info'>Bảng ghi</Button>
                                                        <DataGrid
                                                            getRowId={(row) => row._id}
                                                            rows={allSofa}
                                                            columns={columnsSofa}
                                                            slots={{
                                                                toolbar: GridToolbar,
                                                            }}
                                                            slotProps={{
                                                                toolbar: {
                                                                    showQuickFilter: true,
                                                                },
                                                            }}
                                                            sx={{ ...StyleDataGrid() }}
                                                        />
                                                    </Box>
                                                </TabPanelJob>
                                                <TabPanelJob value={valueTabJob} index={5}>
                                                    <Formik
                                                        initialValues={formDataSprayPrice}
                                                        onSubmit={handleSubmitSprayPrice}
                                                        validationSchema={validationAirAndSofaAndSpraySchema(
                                                            isOtherSprayService,
                                                            isSelectedTypeSpray,
                                                            serviceSprayShowUI
                                                        )}
                                                    >
                                                        {({
                                                            values,
                                                            errors,
                                                            touched,
                                                            handleBlur,
                                                            handleChange,
                                                            setFieldValue,
                                                            setErrors,
                                                            setTouched,
                                                        }) => (
                                                            <Form>
                                                                <Box
                                                                    display='flex'
                                                                    flexDirection='column'
                                                                    sx={{
                                                                        mb: '20px',
                                                                        '& .MuiInputLabel-formControl.Mui-error.Mui-focused': {
                                                                            // change label textField when focused input
                                                                            fontSize: '16px !important',
                                                                            color: theme.palette.mode === 'dark' ? '#f44336' : '#d32f2f',
                                                                        },
                                                                        '& .MuiFormHelperText-root.Mui-error': {
                                                                            fontSize: '14px',
                                                                        },
                                                                    }}
                                                                >
                                                                    {allSpray?.length > 0 && (
                                                                        <TextField
                                                                            select
                                                                            label='Chọn dịch vụ'
                                                                            name='service'
                                                                            value={values.service}
                                                                            onChange={(e) => {
                                                                                handleChange(e)
                                                                                console.log('service', e.target.value)

                                                                                const selectedService = serviceSprayShowUI.find(
                                                                                    (service) => service._id === e.target.value
                                                                                )
                                                                                if (selectedService) {
                                                                                    setFieldValue('type', selectedService.type)
                                                                                    setIsOtherSprayService(false)
                                                                                } else {
                                                                                    setIsOtherSprayService(true)
                                                                                }

                                                                                //reset lỗi khi select dịch vụ mới
                                                                                setErrors({})
                                                                                setTouched({})
                                                                            }}
                                                                            onBlur={handleBlur}
                                                                            error={!!touched.service && !!errors.service}
                                                                            helperText={touched.service && errors.service}
                                                                            variant='filled'
                                                                        >
                                                                            {serviceSprayShowUI.map((service) => (
                                                                                <MenuItem key={service._id} value={service._id}>
                                                                                    {service.name}
                                                                                </MenuItem>
                                                                            ))}
                                                                            <MenuItem key='new-service' value='new-service'>
                                                                                Thêm mới dịch vụ
                                                                            </MenuItem>
                                                                        </TextField>
                                                                    )}

                                                                    {isOtherSprayService && (
                                                                        <Autocomplete
                                                                            freeSolo
                                                                            options={serviceSprayShowUI || []}
                                                                            value={
                                                                                serviceSprayShowUI.find((service) => service.type === values.type) ||
                                                                                ''
                                                                            }
                                                                            onChange={(event, value) => {
                                                                                console.log('Selected value:', value)
                                                                                if (value) {
                                                                                    console.log(value)

                                                                                    // Nếu chọn một nhóm dịch vụ có sẵn, set type theo nhóm dịch vụ đã chọn
                                                                                    setFieldValue('type', value.type)
                                                                                    setIsSelectedTypeSpray(true)
                                                                                } else {
                                                                                    // Nếu không chọn gì, tự động sinh type mới
                                                                                    setFieldValue('type', serviceSprayShowUI.length + 1)
                                                                                    setIsSelectedTypeSpray(false)
                                                                                }
                                                                            }}
                                                                            getOptionLabel={(service) => service?.name || ''}
                                                                            renderInput={(params) => (
                                                                                <TextField
                                                                                    {...params}
                                                                                    onBlur={handleBlur}
                                                                                    label='Chọn nhóm dịch vụ'
                                                                                    name='type'
                                                                                    helperText='Nếu không chọn nhóm dịch vụ, nhóm dịch vụ sẽ tự sinh.'
                                                                                />
                                                                            )}
                                                                            renderOption={(props, option) => (
                                                                                <li {...props} key={option._id} style={{ marginTop: '10px' }}>
                                                                                    <Box width='100%'>
                                                                                        <Box display='flex' justifyContent='space-between'>
                                                                                            <Typography>{option.name}</Typography>
                                                                                            <Typography color={colors.greenAccent[400]}>
                                                                                                type: {option.type}
                                                                                            </Typography>
                                                                                        </Box>
                                                                                    </Box>
                                                                                </li>
                                                                            )}
                                                                        />
                                                                    )}

                                                                    {(allSpray?.length < 1 || (isOtherSprayService && !isSelectedTypeSpray)) && (
                                                                        <TextField
                                                                            label='Tên dịch vụ mới'
                                                                            name='name'
                                                                            value={values.name}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            error={!!touched.name && !!errors.name}
                                                                            helperText={touched.name && errors.name}
                                                                            variant='filled'
                                                                        />
                                                                    )}

                                                                    <TextField
                                                                        label='Nhập mô tả'
                                                                        name='description'
                                                                        value={values.description}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        error={!!touched.description && !!errors.description}
                                                                        helperText={touched.description && errors.description}
                                                                        variant='filled'
                                                                    />
                                                                    <TextField
                                                                        label={'Giá tiền'}
                                                                        variant='filled'
                                                                        name='price'
                                                                        value={formatNumber(values.price)}
                                                                        onChange={(e) => {
                                                                            const formattedValue = parseNumber(e.target.value)
                                                                            setFieldValue('price', formattedValue)
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                        error={!!touched.price && !!errors.price}
                                                                        helperText={touched.price && errors.price}
                                                                        InputLabelProps={{
                                                                            sx: {
                                                                                '&.MuiInputLabel-filled': {
                                                                                    fontSize: '14px',
                                                                                },
                                                                            },
                                                                        }}
                                                                    />
                                                                </Box>
                                                                <Box>
                                                                    <Button
                                                                        type='submit'
                                                                        sx={{
                                                                            ...styleButtonSubmit,
                                                                        }}
                                                                    >
                                                                        Chấp nhận
                                                                    </Button>
                                                                </Box>
                                                            </Form>
                                                        )}
                                                    </Formik>
                                                    <Divider sx={{ mb: '20px', mt: '20px' }} />
                                                    <Box
                                                        sx={{
                                                            height: 500,
                                                            width: '100%',
                                                            '& .actions': {
                                                                color: 'text.secondary',
                                                            },
                                                            '& .textPrimary': {
                                                                color: 'text.primary',
                                                            },
                                                        }}
                                                    >
                                                        <Button color='info'>Bảng ghi</Button>
                                                        <DataGrid
                                                            getRowId={(row) => row._id}
                                                            rows={allSpray}
                                                            columns={columnsSpray}
                                                            slots={{
                                                                toolbar: GridToolbar,
                                                            }}
                                                            slotProps={{
                                                                toolbar: {
                                                                    showQuickFilter: true,
                                                                },
                                                            }}
                                                            sx={{ ...StyleDataGrid() }}
                                                        />
                                                    </Box>
                                                </TabPanelJob>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </TabPanel>
                        {/* Setting giảm giá */}
                        <TabPanel value={valueTab} index={1}>
                            <Box>
                                <Typography fontWeight='600'>3. Cài đặt khuyến mãi:</Typography>
                                <Formik
                                    onSubmit={handleSubmitDiscount}
                                    initialValues={{
                                        level: '',
                                        discount: '',
                                    }}
                                    validationSchema={userSchemaDiscountSchema}
                                >
                                    {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => {
                                        return (
                                            <Form style={{ marginTop: '20px' }} onSubmit={handleSubmit}>
                                                <Box p='0 24px'>
                                                    <Box
                                                        sx={{
                                                            '& .MuiInputLabel-formControl.Mui-error.Mui-focused': {
                                                                // change label textField when focused input
                                                                fontSize: '16px !important',
                                                                color: theme.palette.mode === 'dark' ? '#f44336' : '#d32f2f',
                                                            },
                                                            '& .MuiFormHelperText-root.Mui-error': {
                                                                fontSize: '14px',
                                                            },
                                                        }}
                                                    >
                                                        <TextField
                                                            color='secondary'
                                                            label='Level'
                                                            type='text'
                                                            name='level'
                                                            value={values.level}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            error={!!touched.level && !!errors.level}
                                                            helperText={touched.level && errors.level}
                                                            sx={{ marginRight: '20px' }}
                                                        />
                                                        <TextField
                                                            variant='standard'
                                                            label='Nhập giá trị chiết khấu (%)'
                                                            name='discount'
                                                            value={values.discount}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            error={!!touched.discount && !!errors.discount}
                                                            helperText={touched.discount && errors.discount}
                                                        ></TextField>
                                                    </Box>
                                                    <Box>
                                                        <Button
                                                            type='submit'
                                                            sx={{
                                                                ...styleButtonSubmit,
                                                            }}
                                                        >
                                                            Chấp nhận
                                                        </Button>
                                                    </Box>
                                                    <Divider sx={{ mb: '20px', mt: '20px' }} />
                                                    <Box
                                                        sx={{
                                                            height: 500,
                                                            width: '100%',
                                                            '& .actions': {
                                                                color: 'text.secondary',
                                                            },
                                                            '& .textPrimary': {
                                                                color: 'text.primary',
                                                            },
                                                        }}
                                                    >
                                                        <Button color='info'>Bảng ghi</Button>
                                                        <DataGrid
                                                            rows={rowsDiscount}
                                                            columns={columnsLevelDiscount}
                                                            slots={{
                                                                toolbar: GridToolbar,
                                                            }}
                                                            slotProps={{
                                                                toolbar: {
                                                                    showQuickFilter: true,
                                                                },
                                                            }}
                                                            sx={{ ...StyleDataGrid() }}
                                                        />
                                                    </Box>
                                                </Box>
                                            </Form>
                                        )
                                    }}
                                </Formik>
                            </Box>
                        </TabPanel>
                    </Box>
                )}

                {/* /////////////////////////////////////////////////////////////////////////////////////////////////// */}
            </Box>
            {/* delete row table edit task */}
            <Modal open={Boolean(openBoxDeleteTaskPriceDefault)} onClose={handleCloseBoxDeleteTaskPriceDefault}>
                <Box sx={{ ...styleModal }}>
                    <Typography>Bạn muốn xoá bản ghi này?</Typography>
                    <Box textAlign='end' mt='12px'>
                        <Button size='small' variant='contained' color='error' onClick={() => handleDeleteRowTaskPriceDefault(rowIdTaskPriceDefault)}>
                            Chấp nhận
                        </Button>
                        <Button
                            size='small'
                            variant='outlined'
                            sx={{
                                bgcolor: 'white',
                                '&:hover': {
                                    bgcolor: '#e8e8e8c4',
                                },
                                ml: '10px',
                            }}
                            onClick={handleCloseBoxDeleteTaskPriceDefault}
                        >
                            Huỷ
                        </Button>
                    </Box>
                </Box>
            </Modal>

            {/* delete row */}
            <Modal open={Boolean(openBoxDeleteTaskPriceSpecial)} onClose={handleCloseBoxDeleteTaskPriceSpecial}>
                <Box sx={{ ...styleModal }}>
                    <Typography>Bạn muốn xoá bản ghi này?</Typography>
                    <Box textAlign='end' mt='12px'>
                        <Button size='small' variant='contained' color='error' onClick={() => handleDeleteRowTaskPriceSpecial(rowIdTaskPriceSpecial)}>
                            Chấp nhận
                        </Button>
                        <Button
                            size='small'
                            variant='outlined'
                            sx={{
                                bgcolor: 'white',
                                '&:hover': {
                                    bgcolor: '#e8e8e8c4',
                                },
                                ml: '10px',
                            }}
                            onClick={handleCloseBoxDeleteTaskPriceSpecial}
                        >
                            Huỷ
                        </Button>
                    </Box>
                </Box>
            </Modal>
            <Popover
                open={Boolean(anchorlDeleteRowPermanentPrice)}
                anchorEl={anchorlDeleteRowPermanentPrice}
                onClose={handleCloseBoxDeletePermanentPrice}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box p={2}>
                    <Typography>Bạn muốn xoá bảng ghi này?</Typography>
                    <Box
                        marginTop='10px'
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Button
                            size='small'
                            variant='contained'
                            color='error'
                            onClick={() => handleConfirmDeletePermanentPrice(currentRowPermanentPrice)}
                            sx={{
                                fontWeight: 600,
                                color: colors.grey[100],
                                bgcolor: '#d40000',
                                color: 'white',
                                fontWeight: '600',
                                mr: '20px',
                                '&:hover': {
                                    bgcolor: 'red',
                                },
                            }}
                        >
                            Chấp nhận
                        </Button>
                        <Button
                            size='small'
                            onClick={handleCloseBoxDeletePermanentPrice}
                            variant='outlined'
                            sx={{
                                bgcolor: 'white',
                                '&:hover': {
                                    bgcolor: '#e8e8e8c4',
                                },
                            }}
                        >
                            Huỷ
                        </Button>
                    </Box>
                </Box>
            </Popover>
            <Popover
                open={Boolean(anchorlDeleteRowCleanPrice)}
                anchorEl={anchorlDeleteRowCleanPrice}
                onClose={handleCloseBoxDeleteCleanPrice}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box p={2}>
                    <Typography>Bạn muốn xoá bảng ghi này?</Typography>
                    <Box
                        marginTop='10px'
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Button
                            size='small'
                            variant='contained'
                            color='error'
                            onClick={() => handleConfirmDeleteCleanPrice(currentRowCleanPrice)}
                            sx={{
                                fontWeight: 600,
                                color: colors.grey[100],
                                bgcolor: '#d40000',
                                color: 'white',
                                fontWeight: '600',
                                mr: '20px',
                                '&:hover': {
                                    bgcolor: 'red',
                                },
                            }}
                        >
                            Chấp nhận
                        </Button>
                        <Button
                            size='small'
                            onClick={handleCloseBoxDeleteCleanPrice}
                            variant='outlined'
                            sx={{
                                bgcolor: 'white',
                                '&:hover': {
                                    bgcolor: '#e8e8e8c4',
                                },
                            }}
                        >
                            Huỷ
                        </Button>
                    </Box>
                </Box>
            </Popover>
            <BoxDelete anchorEl={anchorElDeleteDiscount} onClose={handleCloseBoxDeleteDiscount} handleConfirm={handleConfirmDeleteDiscount} />
            <BoxDelete anchorEl={anchorElDeleteAir} onClose={handleCloseBoxDeleteAir} handleConfirm={handleConfirmDeleteAir} />
            <BoxDelete anchorEl={anchorElDeleteSofa} onClose={handleCloseBoxDeleteSofa} handleConfirm={handleConfirmDeleteSofa} />
            <BoxDelete anchorEl={anchorElDeleteSpray} onClose={handleCloseBoxDeleteSpray} handleConfirm={handleConfirmDeleteSpray} />

            {/* edit */}
            {anchorElEditAir && (
                <BoxEditSofaAirSpray
                    open={Boolean(anchorElEditAir)}
                    onClose={handleCloseEditAir}
                    initialValues={initialValuesAir}
                    validationSchema={validateSchemaEditAirAndSofaAndSpray}
                    handleConfirmEdit={handleConfirmEditAir}
                />
            )}
            {anchorElEditSofa && (
                <BoxEditSofaAirSpray
                    open={Boolean(anchorElEditSofa)}
                    onClose={handleCloseEditSofa}
                    initialValues={initialValuesSofa}
                    validationSchema={validateSchemaEditAirAndSofaAndSpray}
                    handleConfirmEdit={handleConfirmEditSofa}
                />
            )}
            {anchorElEditSpray && (
                <BoxEditSofaAirSpray
                    open={Boolean(anchorElEditSpray)}
                    onClose={handleCloseEditSpray}
                    initialValues={initialValuesSpray}
                    validationSchema={validateSchemaEditAirAndSofaAndSpray}
                    handleConfirmEdit={handleConfirmEditSpray}
                />
            )}
            {anchorElEditCleanPrice && (
                <BoxEditCleanPermanent
                    open={Boolean(anchorElEditCleanPrice)}
                    onClose={handleCloseEditCleanPrice}
                    initialValues={initialValuesCleanPrice}
                    validationSchema={validationPermanentAndCleanPriceSchema}
                    handleConfirmEdit={handleConfirmEditCleanPrice}
                />
            )}
            {anchorElEditPermanentPrice && (
                <BoxEditCleanPermanent
                    open={Boolean(anchorElEditPermanentPrice)}
                    onClose={handleCloseEditPermanentPrice}
                    initialValues={initialValuesPermanentPrice}
                    validationSchema={validationPermanentAndCleanPriceSchema}
                    handleConfirmEdit={handleConfirmEditPermanentPrice}
                />
            )}
        </Box>
    )
}

export default CompanySetting
