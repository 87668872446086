import React, { useEffect, useState } from 'react'
import {
    Box,
    Typography,
    useTheme,
    IconButton,
    Button,
    Popover,
    Tooltip,
    TextField,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListItemButton,
    ListItemAvatar,
    Checkbox,
    Avatar,
    Menu,
    MenuItem,
    Modal,
    FormControl,
    Input,
    InputAdornment,
    FilledInput,
    InputLabel,
    CardMedia,
    Card,
    CardContent,
    CardActions,
} from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { tokens } from '../../theme'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import Header from '../../components/Header'
import GroupAddIcon from '@mui/icons-material/GroupAdd'
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices'
import CommentIcon from '@mui/icons-material/Comment'
import useMediaQuery from '@mui/material/useMediaQuery'
import StyleDataGrid from '../../assets/styles/styleDataGrid'
import {
    delete_company,
    update_company,
    get_all_company,
    get_services,
    signup_company_account_from_admin,
    get_companies_admin,
} from '../../utils/api/apiList'
import request from '../../utils/api/request'
import moment from 'moment'
import CustomNoRowsOverlay from '../../components/noRows'
import CircularProgress from '@mui/material/CircularProgress'
import CustomLoadingComponent from '../../components/loading'
import { Formik } from 'formik'
import * as yup from 'yup'
import { Close, Visibility, VisibilityOff } from '@mui/icons-material'
import LockRoundedIcon from '@mui/icons-material/LockRounded'
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled'
import { message, notification } from 'antd'
import ModalEdit from './ModalEdit'
import ModalCreate from './ModalCreate'
import ThreePIcon from '@mui/icons-material/ThreeP'
import { baseURL } from '../../utils/api/baseURL'

const phoneRegExp = /^[0-9]{10}$/
const passReg = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/

const userSchema = yup.object().shape({
    email: yup.string().email('Email không hợp lệ!').required('Không được để trống!'),
    phoneNumber: yup.string().matches(phoneRegExp, 'Số điện thoại không hợp lệ!').required('Không được để trống!'),
    password: yup
        .string()
        // .min(6, 'Mật khẩu quá ngắn! tối thiểu 6 ký tự')
        .matches(passReg, 'Mật khẩu tối thiểu là 8 ký tự bao gồm chữ và số!')
        .required('Không được để trống!'),
    confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Mật khẩu không khớp!')
        .required('Không được để trống!'),
})

const CompanyManagement = () => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const [currentRow, setCurrentRow] = useState(null)
    const [dataService, setDataService] = useState([])
    const [pageSize, setPageSize] = useState(5)
    const [anChorElEdit, setAnChorElEdit] = useState(null)
    const [anchorElCreate, setAnchorElCreate] = useState(null)
    const [openDelete, setOpenDelete] = useState(false)
    const [idRow, setIdRow] = useState(null)
    const [anchorEl, setAnchorEl] = useState(null)
    const [anchorElService, setAnchorElService] = useState(null)
    const [loading, setLoading] = useState(true)
    const [dataCompany, setDataCompany] = useState([])
    const [selectedServicesId, setSelectedServicesId] = useState([])
    const [openModalAccount, setOpenModalAccount] = useState(false)
    const [initialAccountValues, setInitialAccountValues] = useState({
        email: '',
        phoneNumber: '',
        password: '',
        confirmPassword: '',
    })

    //show pass
    const [showPassword, setShowPassword] = useState(false)
    const handleClickShowPassword = () => setShowPassword(!showPassword)

    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword)

    //show info account company
    const [anchorElInfoAccount, setAnchorElInfoAccount] = useState(null)

    //media query
    const matches = useMediaQuery('(min-width:900px)')
    //add ten class cho column
    const getRowClassName = (params) => {
        if (params.row.isPaid) {
            return 'paid'
        }
        return 'pending-pay'
    }

    //notification
    const [api, contextHolder] = notification.useNotification()
    const openNotification = (type, notification) => {
        api[type]({
            message: 'Thông báo',
            description: type === 'success' ? `Tạo tài khoản cho Cty ${notification} thành công!` : 'Tạo tài khoản thất bại, vui lòng thử lại',
            style: {
                cursor: 'pointer',
            },
        })
    }

    const fetchData = async () => {
        setLoading(true)
        try {
            // Lấy tất cả công ty
            const companyResponse = await request.post(get_all_company, {})
            const modifiedData = companyResponse.data.data.docs.map((item, index) => ({
                ...item,
                index: index + 1,
            }))

            // Lấy tài khoản quản lý công ty
            const adminResponse = await request.post(get_companies_admin)
            const companyAdminData = adminResponse.data.data

            // Cập nhật dữ liệu công ty với thông tin tài khoản quản lý
            const updatedDataCompany = modifiedData.map((company) => {
                const adminAccount = companyAdminData.find((account) => account.fromCompany._id === company._id)
                return adminAccount ? { ...company, adminAccount } : company
            })

            setDataCompany(updatedDataCompany)
            console.log(updatedDataCompany)
        } catch (err) {
            console.log(err)
        } finally {
            setLoading(false)
        }
    }

    //fetch service
    // const fetchDataService = () => {
    //     request
    //         .post(get_services, {})
    //         .then((res) => {
    //             const modifiedData = res.data.data.docs.map((item, index) => ({
    //                 ...item,
    //                 index: index + 1,
    //             }))
    //             setDataService(modifiedData)
    //         })
    //         .catch((err) => console.log(err))
    // }

    //get all company
    useEffect(() => {
        fetchData()
    }, [])

    ///////////////////////////////////////////////////// service ///////////////////////////////////////
    // //service
    // const handleOpenService = (event, row) => {
    //     setCurrentRow(row)
    //     setIdRow(row._id)
    //     setAnchorElService(event.currentTarget)
    //     fetchDataService()
    //     setSelectedServicesId([])
    // }

    // const handleCloseService = () => {
    //     setAnchorElService(null)
    // }

    // //toggle select service
    // const handleToggle = (service) => () => {
    //     const currentIndex = selectedServicesId.findIndex((selectedService) => selectedService === service._id)
    //     const newSelectedServices = [...selectedServicesId]

    //     if (currentIndex === -1) {
    //         newSelectedServices.push(service._id)
    //     } else {
    //         newSelectedServices.splice(currentIndex, 1)
    //     }

    //     setSelectedServicesId(newSelectedServices)
    // }
    // const isServiceSelected = (service) => {
    //     return selectedServicesId.some((_id) => _id === service._id)
    // }

    // //handle update service for company
    // const handleUpdateServiceForCompany = () => {
    //     request
    //         .post(update_company, { _id: idRow, serviceDetail: selectedServicesId, phoneNumber: currentRow?.phoneNumber })
    //         .then((res) => {
    //             console.log('fetchUpdateService', res)
    //             message.success(
    //                 <Typography>
    //                     gán dịch vụ cho công ty <span style={{ color: colors.greenLight[500] }}>{currentRow?.name}</span> thành công!
    //                 </Typography>
    //             )
    //             handleCloseService()
    //             fetchData()
    //         })
    //         .catch((err) => {
    //             message.error('Lỗi: Gán dịch vụ thất bại!')
    //             console.error(err)
    //         })
    // }

    ///////////////////////////////////////////////////// service ///////////////////////////////////////

    //edit

    const handleOpenEdit = (e, row) => {
        setAnChorElEdit(e.currentTarget)
        setCurrentRow(row)
    }

    const handleCloseEdit = () => {
        setAnChorElEdit(null)
    }

    //delete
    const handleDelete = (id, event) => {
        setOpenDelete(true)
        setIdRow(id)
        setAnchorEl(event.currentTarget)
    }

    const handleCloseDelete = () => {
        setOpenDelete(false)
        setAnchorEl(null)
    }

    const handleConfirmDelete = () => {
        setOpenDelete(false)
        setAnchorEl(null)
        request
            .post(delete_company, { _id: idRow })
            .then((res) => {
                fetchData()
                message.success('Xoá công ty thành công!')
            })
            .catch((err) => {
                message.error('Lỗi: xoá công ty thất bại!')
                console.log(err)
            })
    }

    //columns
    const columns = [
        {
            field: 'index',
            headerName: 'STT',
            width: 20,
        },
        {
            field: 'name',
            headerName: 'Tên công ty',
            flex: 1,
            minWidth: 150,
            cellClassName: 'name-column--cell',
        },
        // {
        //     field: 'service',
        //     headerName: 'Dịch vụ',
        //     flex: 0.3,
        //     minWidth: 100,
        // },
        {
            field: 'phoneNumber',
            headerName: 'Điện thoại',
            flex: 0.4,
            minWidth: 100,
        },
        {
            field: 'address',
            headerName: 'Địa chỉ',
            flex: 1,
            minWidth: 150,
        },
        {
            field: 'package',
            headerName: 'Gói',
            width: 150,
            flex: 0.5,
            valueGetter: (value, row) => {
                if (row?.serviceType === 0) {
                    return 'Không giới hạn'
                }
                return value + ' tháng'
            },
        },
        {
            field: 'endDate',
            headerName: 'HSD',
            flex: 0.5,
            minWidth: 100,
            cellClassName: 'endDate-cell',
            renderCell: (params) => {
                const { startDate, endDate } = params.row
                if (!startDate || !endDate) {
                    return 'Hết hạn'
                } else {
                    const startDateFormatted = moment(startDate).format('DD/MM/YYYY')
                    const endDateFormatted = moment(endDate).format('DD/MM/YYYY')
                    return (
                        <Box>
                            <Typography fontSize='12px' color={colors.greenAccent[400]}>
                                {startDateFormatted}
                            </Typography>
                            <Typography fontSize='12px' color={colors.redAccent[400]}>
                                {endDateFormatted}
                            </Typography>
                        </Box>
                    )
                }
            },
        },
        {
            field: 'isPaid',
            headerName: 'Trạng thái',
            flex: 0.5,
            minWidth: 100,
            valueGetter: (value) => {
                if (value) {
                    return 'Đã thanh toán'
                }
                return 'Chưa thanh toán'
            },
        },
        {
            field: 'action',
            headerName: 'Hành động',
            flex: 1,
            minWidth: 150,
            sortable: false,
            cellClassName: 'action-column',
            renderCell: ({ row }) => (
                <Box display='flex' alignItems='center' gap={1}>
                    <Tooltip
                        title='Chỉnh sửa công ty'
                        arrow
                        slotProps={{
                            popper: {
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, -5],
                                        },
                                    },
                                ],
                            },
                        }}
                    >
                        <IconButton
                            // onClick={() => handleEdit(row.id)}
                            onClick={(e) => handleOpenEdit(e, row)}
                            color='secondary'
                            aria-label='edit'
                        >
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        title='Tạo tài khoản quản lý công ty'
                        arrow
                        slotProps={{
                            popper: {
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, -5],
                                        },
                                    },
                                ],
                            },
                        }}
                    >
                        <IconButton color='default' onClick={(e) => handleOpenModalAccount(e, row)}>
                            <GroupAddIcon />
                        </IconButton>
                    </Tooltip>
                    {row?.adminAccount && (
                        <Tooltip
                            title='info tài khoản'
                            arrow
                            slotProps={{
                                popper: {
                                    modifiers: [
                                        {
                                            name: 'offset',
                                            options: {
                                                offset: [0, -5],
                                            },
                                        },
                                    ],
                                },
                            }}
                        >
                            <IconButton color='default' onClick={(e) => handleOpenInfoAccount(e, row)}>
                                <ThreePIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    <Tooltip
                        title='Xoá công ty'
                        arrow
                        slotProps={{
                            popper: {
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, -5],
                                        },
                                    },
                                ],
                            },
                        }}
                    >
                        <IconButton onClick={(event) => handleDelete(row._id, event)} aria-label='delete' color='error'>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            ),
        },
    ]

    //modal create account company
    const handleOpenModalAccount = (e, row) => {
        setOpenModalAccount(true)
        setCurrentRow(row)
    }

    const handleSubmitAccount = (values) => {
        const dataForm = {
            ...values,
            fromCompany: data?._id,
            fullName: data?.name,
        }

        request
            .post(signup_company_account_from_admin, { ...dataForm })
            .then((res) => {
                setOpenModalAccount(false)
                openNotification('success', data.name)
            })
            .catch((err) => openNotification('error'))
    }

    // modal show info account company
    const handleOpenInfoAccount = (e, row) => {
        console.log('row', row)
        setAnchorElInfoAccount(e.currentTarget)
        setCurrentRow(row)
    }

    const handleCloseInfoAccount = () => {
        setAnchorElInfoAccount(false)
    }

    //modal addCompany
    const handleOpenModalAddCompany = (e) => {
        setAnchorElCreate(e.currentTarget)
    }
    const handleCloseCreate = () => {
        setAnchorElCreate(null)
    }

    const handlePageSizeChange = (newPageSize) => {
        console.log(`New page size: ${newPageSize}`)
        setPageSize(newPageSize)
    }

    return (
        <Box
            m='20px'
            sx={{
                '& .MuiTypography-root.MuiTypography-h2': {
                    margin: '0 0 5px 0',
                },
            }}
        >
            {contextHolder}
            <Box
                display='flex'
                justifyContent='space-between'
                // alignItems='center'
                sx={{
                    flexDirection: matches ? 'row' : 'column',
                    alignItems: matches ? 'center' : 'flex-start',
                }}
            >
                <Header title='QUẢN LÝ CÔNG TY' subtitle='Trang quản lý công ty' />
                <Button
                    sx={{
                        backgroundColor: colors.blueAccent[700],
                        background:
                            theme.palette.mode === 'dark'
                                ? '#3e4396' //'linear-gradient(to right, #2b5876, #4e4376)'
                                : 'linear-gradient(to right, #b993d6, #8ca6db)',
                        // color: colors.grey[100],
                        color: 'white',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        padding: '10px 20px',
                        '&:hover': {
                            background:
                                theme.palette.mode === 'dark'
                                    ? '#3e4366' //'linear-gradient(to right, #346382, #5b5083)'
                                    : 'linear-gradient(to right, #c8b7d5, #a6beee)',
                        },
                    }}
                    onClick={handleOpenModalAddCompany}
                >
                    <GroupAddIcon sx={{ mr: '10px' }} />
                    THÊM CÔNG TY
                </Button>
            </Box>
            <Box height={matches ? '75vh' : '100%'} m='40px 0 0 0' sx={StyleDataGrid()}>
                <DataGrid
                    rows={dataCompany}
                    getRowId={(row) => row._id}
                    columns={columns}
                    pageSize={pageSize}
                    initialState={{
                        ...dataCompany.initialState,
                        pagination: { paginationModel: { pageSize: 100 } },
                    }}
                    pageSizeOptions={[50, 100]}
                    pagination
                    loading={loading}
                    slots={{
                        toolbar: GridToolbar,
                        noRowsOverlay: CustomNoRowsOverlay,
                        loadingOverlay: CustomLoadingComponent,
                    }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: {
                                placeholder: 'Tìm kiếm ...',
                            },
                        },
                    }}
                    onPageSizeChange={handlePageSizeChange}
                    getRowClassName={getRowClassName}
                    sx={{
                        '& .pending-pay': {
                            background:
                                // 'linear-gradient(to right, #fd746c, #ff9068)',
                                theme.palette.mode === 'dark'
                                    ? 'linear-gradient(to right, #200122, #6f0000)'
                                    : 'linear-gradient(to right, #ddd6f3, #ffedbc)',
                            '&:hover': {
                                background:
                                    theme.palette.mode === 'dark'
                                        ? 'linear-gradient(to right, #641325, #960808)'
                                        : 'linear-gradient(to right, #ffd5c7, #ffedbc)',
                            },
                        },
                        '& .css-bvbdia-MuiTablePagination-root .MuiTablePagination-input': {
                            display: 'flex',
                        },
                        '& .endDate-cell': {
                            display: 'flex',
                            alignItems: 'center',
                        },
                    }}
                />
            </Box>
            {anChorElEdit && <ModalEdit open={Boolean(anChorElEdit)} onClose={handleCloseEdit} currentRow={currentRow} fetchData={fetchData} />}
            <ModalCreate open={Boolean(anchorElCreate)} onClose={handleCloseCreate} fetchData={fetchData} />

            <Modal open={openModalAccount} disableEscapeKeyDown sx={{ backdropFilter: 'blur(5px)' }}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '500px',
                        minHeight: '300px',
                        maxHeight: '80vh',
                        overflowY: 'auto',
                        bgcolor: theme.palette.mode === 'dark' ? colors.primary[400] : '#c2cbff',
                        boxShadow: 24,
                        p: 4,
                        border: 'unset',
                        borderRadius: '4px',
                    }}
                >
                    <Typography variant='h6' component='h2' color={theme.palette.mode === 'dark' ? 'white' : '#4e4c4c'} fontWeight='600'>
                        TẠO TÀI KHOẢN QUẢN LÝ
                    </Typography>
                    <IconButton
                        sx={{
                            position: 'absolute',
                            top: '10px',
                            right: '20px',
                        }}
                        onClick={() => setOpenModalAccount(false)}
                    >
                        <Close color='disabled' />
                    </IconButton>
                    <Box
                        p='30px'
                        sx={{
                            bgcolor: theme.palette.mode === 'dark' ? colors.primary[500] : '#dde6ff',
                            borderRadius: '4px',
                            boxShadow:
                                theme.palette.mode === 'dark'
                                    ? 'inset 0 0 0.5px 1px hsla(0, 0%,100%, 0.075), 0 0 0 1px hsla(0, 0%, 0%, 0.05), 0 0.3px 0.4px hsla(0, 0%, 0%, 0.02),0 0.9px 1.5px hsla(0, 0%, 0%, 0.045), 0 3.5px 6px hsla(0, 0%, 0%, 0.09)'
                                    : 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px',
                        }}
                    >
                        <Formik onSubmit={handleSubmitAccount} initialValues={initialAccountValues} validationSchema={userSchema}>
                            {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => {
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <Box display='grid' gap='30px' gridTemplateColumns='100%'>
                                            <TextField
                                                label='Tên Công Ty'
                                                variant='filled'
                                                value={currentRow?.name}
                                                sx={{
                                                    '& .MuiInputBase-input': {
                                                        // WebkitTextFillColor: `${colors.greenAccent[400]} !important`,
                                                        fontWeight: '700',
                                                    },
                                                }}
                                                disabled
                                            />
                                            <TextField
                                                label='Email'
                                                name='email'
                                                type='text'
                                                variant='filled'
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.email}
                                                error={!!touched.email && !!errors.email}
                                                helperText={touched.email && errors.email}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment>
                                                            <IconButton>
                                                                <LockRoundedIcon />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                    sx: {
                                                        '&:after': {
                                                            borderBottomColor: theme.palette.mode === 'dark' ? 'white' : colors.greenAccent[400], // Màu viền khi focus
                                                        },
                                                        '&:hover:not(.Mui-disabled):before': {
                                                            borderColor: theme.palette.mode === 'dark' ? 'white' : 'rgba(0, 0, 0, 0.6)', // khi hover
                                                        },
                                                    },
                                                }}
                                                InputLabelProps={{
                                                    sx: {
                                                        '&.Mui-focused': {
                                                            color:
                                                                !!touched.email && !!errors.email
                                                                    ? '#D32F2F'
                                                                    : theme.palette.mode === 'dark'
                                                                    ? 'rgba(255, 255, 255, 0.5)'
                                                                    : 'rgba(0, 0, 0, 0.6)', // Màu khi focus
                                                        },
                                                    },
                                                }}
                                            />
                                            <TextField
                                                label='Số điện thoại'
                                                name='phoneNumber'
                                                type='text'
                                                variant='filled'
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.phoneNumber}
                                                error={!!touched.phoneNumber && !!errors.phoneNumber}
                                                helperText={touched.phoneNumber && errors.phoneNumber}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment>
                                                            <IconButton>
                                                                <PhoneEnabledIcon />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                    sx: {
                                                        '&:after': {
                                                            borderBottomColor: theme.palette.mode === 'dark' ? 'white' : colors.greenAccent[400], // Màu viền khi focus
                                                        },
                                                        '&:hover:not(.Mui-disabled):before': {
                                                            borderColor: theme.palette.mode === 'dark' ? 'white' : 'rgba(0, 0, 0, 0.6)',
                                                        },
                                                    },
                                                }}
                                                InputLabelProps={{
                                                    sx: {
                                                        '&.Mui-focused': {
                                                            color:
                                                                !!touched.phoneNumber && !!errors.phoneNumber
                                                                    ? '#D32F2F'
                                                                    : theme.palette.mode === 'dark'
                                                                    ? 'rgba(255, 255, 255, 0.5)'
                                                                    : 'rgba(0, 0, 0, 0.6)', // Màu khi focus
                                                        },
                                                    },
                                                }}
                                            />
                                            <TextField
                                                variant='filled'
                                                type={showPassword ? 'text' : 'password'}
                                                label='Mật khẩu'
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.password}
                                                name='password'
                                                error={!!touched.password && !!errors.password}
                                                helperText={touched.password && errors.password}
                                                InputProps={{
                                                    endAdornment: (
                                                        <IconButton onClick={handleClickShowPassword}>
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    ),
                                                    sx: {
                                                        '&:after': {
                                                            borderBottomColor: theme.palette.mode === 'dark' ? 'white' : colors.greenAccent[400], // Màu viền khi focus
                                                        },
                                                        '&:hover:not(.Mui-disabled):before': {
                                                            borderColor: theme.palette.mode === 'dark' ? 'white' : 'rgba(0, 0, 0, 0.6)',
                                                        },
                                                    },
                                                }}
                                                InputLabelProps={{
                                                    sx: {
                                                        '&.Mui-focused': {
                                                            color:
                                                                !!touched.phoneNumber && !!errors.phoneNumber
                                                                    ? '#D32F2F'
                                                                    : theme.palette.mode === 'dark'
                                                                    ? 'rgba(255, 255, 255, 0.5)'
                                                                    : 'rgba(0, 0, 0, 0.6)', // Màu khi focus
                                                        },
                                                    },
                                                }}
                                            />
                                            <TextField
                                                variant='filled'
                                                type={showConfirmPassword ? 'text' : 'password'}
                                                label='Nhập lại mật khẩu'
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.confirmPassword}
                                                name='confirmPassword'
                                                error={!!touched.confirmPassword && !!errors.confirmPassword}
                                                helperText={touched.confirmPassword && errors.confirmPassword}
                                                InputProps={{
                                                    endAdornment: (
                                                        <IconButton onClick={handleClickShowConfirmPassword}>
                                                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    ),
                                                    sx: {
                                                        '&:after': {
                                                            borderBottomColor: theme.palette.mode === 'dark' ? 'white' : colors.greenAccent[400], // Màu viền khi focus
                                                        },
                                                        '&:hover:not(.Mui-disabled):before': {
                                                            borderColor: theme.palette.mode === 'dark' ? 'white' : 'rgba(0, 0, 0, 0.6)',
                                                        },
                                                    },
                                                }}
                                                InputLabelProps={{
                                                    sx: {
                                                        '&.Mui-focused': {
                                                            color:
                                                                !!touched.phoneNumber && !!errors.phoneNumber
                                                                    ? '#D32F2F'
                                                                    : theme.palette.mode === 'dark'
                                                                    ? 'rgba(255, 255, 255, 0.5)'
                                                                    : 'rgba(0, 0, 0, 0.6)', // Màu khi focus
                                                        },
                                                    },
                                                }}
                                            />
                                        </Box>
                                        <Box display='flex' justifyContent='end' mt='20px'>
                                            <Button
                                                type='submit'
                                                // color='success'
                                                variant='contained'
                                                sx={{
                                                    '&:hover': {
                                                        bgcolor: theme.palette.mode === 'dark' ? 'rgb(81 172 63)' : '#c2caff',
                                                    },
                                                    bgcolor: theme.palette.mode == 'dark' ? '#25983f' : '#abb5fa',
                                                    color: theme.palette.mode === 'dark' ? 'white' : colors.grey[100],
                                                }}
                                            >
                                                Gửi
                                            </Button>
                                        </Box>
                                    </form>
                                )
                            }}
                        </Formik>
                    </Box>
                </Box>
            </Modal>

            <Popover
                id={idRow}
                open={Boolean(openDelete)}
                anchorEl={anchorEl}
                onClose={handleCloseDelete}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                // sx={{
                //     '& .css-3bmhjh-MuiPaper-root-MuiPopover-paper': {
                //         boxShadow: 'rgba(149, 152, 165, 0.2) 2px 2px 2px',
                //     },
                //     '& .css-jx6v87-MuiPaper-root-MuiPopover-paper': {
                //         border: '1px solid',
                //         boxShadow: 'none',
                //     },
                //     '& .css-l44o5j-MuiButtonBase-root-MuiButton-root:hover': {
                //         bgcolor: '#ffffff4d',
                //     },
                // }}
            >
                <Box p={2}>
                    <Typography>Bạn muốn xoá công ty này?</Typography>
                    <Box
                        marginTop='10px'
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Button
                            size='small'
                            variant='contained'
                            color='error'
                            onClick={handleConfirmDelete}
                            sx={{
                                marginRight: '10px',
                                fontWeight: 600,
                            }}
                        >
                            Đồng ý
                        </Button>
                        <Button
                            size='small'
                            onClick={handleCloseDelete}
                            variant='outlined'
                            sx={{
                                bgcolor: theme.palette.mode === 'dark' ? colors.grey[100] : '',
                            }}
                        >
                            Huỷ
                        </Button>
                    </Box>
                </Box>
            </Popover>

            {/* modal show account company */}
            <Modal open={Boolean(anchorElInfoAccount)} onClose={handleCloseInfoAccount}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '400px',
                        height: 'auto',
                        overflowY: 'auto',
                        bgcolor: theme.palette.mode === 'dark' ? colors.primary[400] : '#c2cbff',
                        boxShadow: 24,
                        p: 4,
                        border: 'unset',
                        borderRadius: '4px',
                    }}
                >
                    <Typography color={theme.palette.mode === 'dark' ? 'white' : '#4e4c4c'} fontWeight='600'>
                        THÔNG TIN TÀI KHOẢN QUẢN LÝ
                    </Typography>
                    <IconButton
                        sx={{
                            position: 'absolute',
                            top: '10px',
                            right: '20px',
                        }}
                        onClick={handleCloseInfoAccount}
                    >
                        <Close color='disabled' />
                    </IconButton>
                    <Box display='flex' gap={'20px'} mt='20px'>
                        <Card sx={{ width: 345 }}>
                            <CardMedia sx={{ height: 200 }} image={baseURL + currentRow?.adminAccount?.avatar} title='green iguana' />
                            <CardContent>
                                <Typography gutterBottom variant='h5' component='div'>
                                    {currentRow?.adminAccount?.fullName}
                                </Typography>
                                <Typography variant='body2' sx={{ color: 'text.secondary' }}>
                                    {currentRow?.adminAccount?.phoneNumber}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Box>
                </Box>
            </Modal>

            {/* gán service */}
            {/* <Popover
                open={Boolean(anchorElService)}
                onClose={handleCloseService}
                anchorEl={anchorElService}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                className='popover-service'
                sx={{
                    '& .MuiPaper-root': {
                        bgcolor: colors.primary[400],
                    },
                }}
            >
                <Box width='250px' margin='15px'>
                    <Typography variant='h5' bgcolor={colors.primary[400]} color={colors.grey[100]} p='10px 20px' fontWeight='600'>
                        Danh sách dịch vụ
                    </Typography>
                    <List
                        sx={{
                            width: '100%',
                            bgcolor: colors.primary[400],
                            height: '200px',
                            overflowY: 'auto',
                        }}
                    >
                        {dataService.map((service) => {
                            const labelId = `checkbox-list-label-${service.index}`

                            return (
                                <ListItem
                                    key={service.index}
                                    secondaryAction={
                                        <IconButton edge='end' aria-label='comments'>
                                            <CommentIcon />
                                        </IconButton>
                                    }
                                    disablePadding
                                >
                                    <ListItemButton role={undefined} onClick={handleToggle(service)} dense>
                                        <ListItemIcon>
                                            <Checkbox
                                                edge='start'
                                                checked={isServiceSelected(service)}
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                                color='secondary'
                                            />
                                        </ListItemIcon>
                                        <ListItemText id={labelId} primary={service.name} />
                                    </ListItemButton>
                                </ListItem>
                            )
                        })}
                    </List>
                    <Box pb='10px' bgcolor={colors.primary[400]} display='flex' justifyContent='flex-end'>
                        <Button color='secondary' onClick={handleUpdateServiceForCompany}>
                            Chọn
                        </Button>
                        <Button
                            sx={{
                                color: colors.grey[100],
                            }}
                            onClick={handleCloseService}
                        >
                            Huỷ
                        </Button>
                    </Box>
                </Box>
            </Popover> */}
        </Box>
    )
}

export default CompanyManagement
