import moment from 'moment'
import React, { memo, useEffect, useRef, useState } from 'react'
import {
    Modal,
    Box,
    Typography,
    useTheme,
    CircularProgress,
    IconButton,
    Skeleton,
    Button,
    TextField,
    Autocomplete,
    CardActionArea,
    CardMedia,
    Card,
    CardContent,
    InputAdornment,
    Rating,
} from '@mui/material'
import { tokens } from '../../../theme'
import request from '../../../utils/api/request'
import { Close, Edit, Phone, Save } from '@mui/icons-material'
import { message } from 'antd'
import { Formik } from 'formik'
import { formatNumber, parseNumber } from '../../../components/prefixData'
import { get_all_spraydetails_for_city, get_spray_booking_by_id_and_user_id, update_spraybooking } from '../../../utils/api/apiList'
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { baseURL } from '../../../utils/api/baseURL'
import images from '../../../assets/images'
import CustomLoadingComponent from '../../../components/loading'
import minutesToHours from '../../../algorithm/minutesToHours'
import { admin_update_task } from '../../../utils/api/apiSocket'
import connectSocket from '../../../utils/api/connectSocket'

const formatTime = (timeInMinutes) => moment().startOf('day').add(timeInMinutes, 'minutes')

function ModalSprayDetail({ dataDetails, open, onClose, validateSchema, fetchDataDetails, fetchData }) {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const [dataRate, setDataRate] = useState(0)
    const [isEditing, setIsEditing] = useState(null)
    const [isLoadingInfo, setIsLoadingInfo] = useState(true)
    const [services, setServices] = useState(null)
    const [isLoadingFillPrice, setIsLoadingFillPrice] = useState(null)
    const [loadingSubmitEdit, setLoadingSubmitEdit] = useState(null)
    const [service, setService] = useState([])
    const [quantity, setQuantity] = useState([])
    const setFieldValueRef = useRef()
    const socket = connectSocket()

    // console.log(dataDetails)

    //message
    const [messageApi, messageContextHolder] = message.useMessage()

    // id company
    const companyId = localStorage.getItem('companyId')

    // formState
    const [formValues, setFormValues] = useState({
        name: '',
        address: '',
        phoneNumber: '',
        time: null,
        date: null,
        sprayService: '',
        quantity: null,
        price: '',
    })

    //fetch Edit
    const fetchEdit = (data, resetForm) => {
        request
            .post(update_spraybooking, { ...data })
            .then((res) => {
                console.log(res)
                message.success('Chỉnh sửa đơn thành công!')
                setIsEditing(false)
                resetForm()
                fetchData()
                fetchDataDetails(res.data.data._id)

                !dataDetails.hasOwnProperty('userFromAdminOrder') &&
                    socket.emit(admin_update_task, {
                        _id: dataDetails._id, //id đơn
                        userId: dataDetails.userId._id, //id của user
                        note: 'Admin sửa đơn khử khuẩn',
                        type: 'editSprayBooking',
                    })
            })
            .catch((err) => {
                console.log(err)
                message.error('lỗi: chỉnh sửa đơn thất bại!')
            })
            .finally(() => {
                setLoadingSubmitEdit(false)
            })
    }

    //fetch all service air
    const fetchServices = () => {
        request
            .post(get_all_spraydetails_for_city, {
                lat: 0,
                lng: 0,
                distance: 10000,
            })
            .then((res) => {
                console.log(res.data.data)
                setServices(res.data.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    //fetch rating
    const fetchRate = () => {
        request
            .post(get_spray_booking_by_id_and_user_id, { userId: dataDetails?.userId?._id ?? '', sprayBookingId: dataDetails?._id })
            .then((res) => {
                // console.log('rate', res)
                setDataRate(res.data.data)
            })
            .catch((err) => {
                setDataRate({})
                console.log(err)
            })
    }

    useEffect(() => {
        setIsLoadingInfo(true)
        if (dataDetails) {
            fetchRate()
            setFormValues({
                name: dataDetails.userFromAdminOrder?.fullName || dataDetails?.userId?.fullName,
                address: dataDetails.userFromAdminOrder?.address || dataDetails?.address,
                phoneNumber: dataDetails.userFromAdminOrder?.phoneNumber || dataDetails?.userId?.phoneNumber,
                time: formatTime(dataDetails.time),
                date: moment(dataDetails.date),
                quantity: dataDetails?.quantity,
                price: dataDetails?.price,
                sprayService: dataDetails?.sprayService,
                quantity: dataDetails?.quantity,
            })
            setService(dataDetails?.sprayService)

            const timer = setTimeout(() => {
                setIsLoadingInfo(false)
            }, [700])
            return () => clearTimeout(timer)
        }
    }, [dataDetails])

    // fill price
    useEffect(() => {
        // Bắt đầu loading
        setIsLoadingFillPrice(true)

        if (service || quantity) {
            const timerLoadingPrice = setTimeout(() => {
                const calculatedPrice = Number(service?.price) * quantity
                setFieldValueRef.current('price', calculatedPrice)
                setIsLoadingFillPrice(false)
            }, 300)

            return () => {
                clearTimeout(timerLoadingPrice)
                setIsLoadingFillPrice(false)
            }
        } else {
            setIsLoadingFillPrice(false)
        }
    }, [service, quantity])

    // handle submit edit
    const handleSubmitEdit = (values, { resetForm }) => {
        const data = {
            time: moment(values.time).hour() * 60 + moment(values.time).minute(),
            date: values.date,
            price: values.price,
            quantity: values.quantity,
            address: values.address,
            sprayService: values.sprayService._id,
            // check `userFromAdminOrder`
            ...(dataDetails.hasOwnProperty('userFromAdminOrder')
                ? {
                      userFromAdminOrder: {
                          fullName: values.name,
                          address: values.address,
                          phoneNumber: values.phoneNumber,
                      },
                  }
                : { userId: dataDetails.userId._id }),
            note: '',
            _id: dataDetails._id,
            companyId,
        }
        console.log(data)

        // setLoadingSubmitEdit(true)
        const timer = setTimeout(() => {
            fetchEdit(data, resetForm)
        }, [300])

        return () => clearTimeout(timer)
    }

    const handleOpenEdit = () => {
        setIsEditing(true)
        fetchServices()
    }

    const handleClose = () => {
        onClose()
        setIsEditing(false)
    }

    const styleBox = {
        flex: 1,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: isEditing ? '20px' : '15px',
        padding: '40px',
        paddingTop: '20px',
        paddingBottom: '20px',
        justifyContent: 'center',
        '& .Mui-focused fieldset': {
            borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.6) !important' : 'rgba(0, 0, 0, 0.8)', // Màu border khi focused
            borderWidth: theme.palette.mode === 'dark' ? '2px' : '1px !important',
        },
        '& .MuiBox-root': {
            display: 'flex',
            alignItems: 'center',
            gap: '20px',
            width: '100%',
            '& .MuiTextField-root': {
                flex: 1,
            },
        },
        '& .MuiTypography-root': {
            minWidth: '80px',
        },
        '& .MuiTextField-root': {
            mt: 0,
            mb: 0,
        },
        // flex: isEditing ? 1 : '0.6',
        '& .box-service.MuiBox-root': {
            alignItems: 'flex-start',
        },
        '& .box-quantity.MuiBox-root': {
            alignItems: 'flex-start',
        },
    }

    return (
        <Modal
            open={open}
            onClose={(event, reason) => {
                if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
                    return
                }
                handleClose()
            }}
            style={{ backdropFilter: 'blur(5px)', zIndex: '99' }}
        >
            <Box
                sx={{
                    borderRadius: '5px',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    // width: 700,
                    width: 800,
                    bgcolor: theme.palette.mode === 'dark' ? '#1F2A40' : '#e0e0e0',
                    border: '1px solid rgba(0, 0, 0, 0.1) 0px 4px 12px',
                    boxShadow: 24,
                    p: 4,
                    pt: '15px',
                }}
            >
                {messageContextHolder}
                <Box display='flex' justifyContent='space-between' alignItems='center' mb='5px'>
                    <Typography variant='h4' color={colors.grey[100]}>
                        CHI TIẾT ĐƠN HÀNG
                    </Typography>
                </Box>
                <IconButton
                    sx={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                    }}
                    onClick={handleClose}
                >
                    <Close color='disabled' />
                </IconButton>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        // width: '640px',
                        width: '100%',
                        bgcolor: theme.palette.mode === 'dark' ? '#2a435657' : '#b3c4d157',
                        borderRadius: '8px',
                        position: 'relative',
                        pb: '10px',
                    }}
                >
                    <Formik validationSchema={validateSchema} initialValues={formValues} enableReinitialize={true} onSubmit={handleSubmitEdit}>
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue, setFieldError }) => {
                            setFieldValueRef.current = setFieldError
                            return (
                                <Box display='flex' maxHeight='500px' overflow='auto' flex='1' height='100%'>
                                    <Box component='form' onSubmit={handleSubmit} sx={styleBox}>
                                        {loadingSubmitEdit && (
                                            <Box
                                                position='absolute'
                                                top={0}
                                                left={0}
                                                right={0}
                                                bottom={0}
                                                display='flex'
                                                alignItems='center'
                                                justifyContent='center'
                                                bgcolor='rgba(255, 255, 255, 0.2)'
                                                zIndex={2}
                                                width='100% !important'
                                            >
                                                <CustomLoadingComponent />
                                            </Box>
                                        )}
                                        {isLoadingInfo ? (
                                            <>
                                                <Skeleton variant='text' width='80%' height={30} />
                                                <Skeleton variant='rectangular' width='100%' height={118} />
                                                <Skeleton variant='text' width='60%' height={30} />
                                                <Skeleton variant='text' width='50%' height={30} />
                                            </>
                                        ) : (
                                            <>
                                                {(dataDetails?.status === 0 || dataDetails?.status === 1) &&
                                                    (isEditing ? (
                                                        <Box>
                                                            <Button
                                                                variant='contained'
                                                                color='success'
                                                                endIcon={<Save />}
                                                                type='submit'
                                                                sx={{ mr: '10px' }}
                                                            >
                                                                Lưu
                                                            </Button>
                                                            <Button variant='outlined' color='inherit' onClick={handleClose}>
                                                                Huỷ
                                                            </Button>
                                                        </Box>
                                                    ) : (
                                                        <Button variant='contained' color='warning' endIcon={<Edit />} onClick={handleOpenEdit}>
                                                            Sửa đơn
                                                        </Button>
                                                    ))}
                                                <Box>
                                                    <Typography>Khách hàng:</Typography>
                                                    {isEditing ? (
                                                        <TextField
                                                            fullWidth
                                                            name='name'
                                                            value={values.name}
                                                            disabled={dataDetails.hasOwnProperty('userFromAdminOrder') ? false : true}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={!!touched.name && !!errors.name}
                                                            helperText={touched.name && errors.name}
                                                        />
                                                    ) : (
                                                        <Typography color={colors.greenLight[300]} fontWeight='600'>
                                                            {dataDetails?.userFromAdminOrder?.fullName || dataDetails?.userId?.fullName}
                                                        </Typography>
                                                    )}
                                                </Box>
                                                <Box>
                                                    <Typography>Số điện thoại:</Typography>
                                                    {isEditing ? (
                                                        <TextField
                                                            fullWidth
                                                            name='phoneNumber'
                                                            value={values.phoneNumber}
                                                            disabled={dataDetails.hasOwnProperty('userFromAdminOrder') ? false : true}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={!!touched.phoneNumber && !!errors.phoneNumber}
                                                            helperText={touched.phoneNumber && errors.phoneNumber}
                                                        />
                                                    ) : (
                                                        <Typography color={colors.greenLight[300]} fontWeight='600'>
                                                            {dataDetails?.userFromAdminOrder?.phoneNumber || dataDetails?.userId?.phoneNumber}
                                                        </Typography>
                                                    )}
                                                </Box>
                                                <Box>
                                                    <Typography>Địa chỉ:</Typography>
                                                    {isEditing ? (
                                                        <TextField
                                                            fullWidth
                                                            name='address'
                                                            value={values.address}
                                                            // disabled={dataDetails.hasOwnProperty('userFromAdminOrder') ? false : true}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={!!touched.address && !!errors.address}
                                                            helperText={touched.address && errors.address}
                                                        />
                                                    ) : (
                                                        <Typography color={colors.greenLight[300]} fontWeight='600'>
                                                            {dataDetails?.userFromAdminOrder?.address || dataDetails?.address}
                                                        </Typography>
                                                    )}
                                                </Box>
                                                <Box className='box-service'>
                                                    <Typography>Đơn hàng:</Typography>
                                                    {isEditing ? (
                                                        <Autocomplete
                                                            name='sprayService'
                                                            options={services || ''}
                                                            value={values.sprayService || ''}
                                                            onChange={(event, newValue) => {
                                                                setFieldValue('sprayService', newValue)

                                                                // Kiểm tra loại dịch vụ và đặt lại giá trị quantity
                                                                if (newValue?.type === 2) {
                                                                    setFieldValue('quantity', values.quantity) // dịch vụ 201-500 m2
                                                                    setQuantity(values.quantity)
                                                                } else {
                                                                    setQuantity(1)
                                                                }

                                                                // Tính toán giá ngay khi chọn dịch vụ
                                                                // const calculatedPrice = newValue?.price * (values.quantity || 1)
                                                                const calculatedPrice = newValue?.price * (newValue?.type === 2 ? values.quantity : 1)
                                                                setFieldValue('price', calculatedPrice)
                                                                setService(newValue)
                                                            }}
                                                            autoHighlight
                                                            disableClearable
                                                            getOptionLabel={(service) => service?.description || ''}
                                                            renderInput={(params) => <TextField {...params} onBlur={handleBlur} />}
                                                            isOptionEqualToValue={(option, value) => option._id === value._id}
                                                            renderOption={(props, option) => (
                                                                <li {...props} key={option._id} style={{ marginTop: '10px' }}>
                                                                    <Box width='100%'>
                                                                        <Box display='flex' justifyContent='space-between'>
                                                                            <Typography>{option.description}</Typography>
                                                                            <Button
                                                                                variant='text'
                                                                                sx={{
                                                                                    p: '0',
                                                                                    color: colors.greenAccent[400],
                                                                                    fontSize: '14px',
                                                                                }}
                                                                            >
                                                                                {formatNumber(option.price)}
                                                                            </Button>
                                                                        </Box>
                                                                    </Box>
                                                                </li>
                                                            )}
                                                            fullWidth
                                                        />
                                                    ) : (
                                                        <Typography color={colors.greenLight[300]} fontWeight='600'>
                                                            {dataDetails?.sprayService?.description}
                                                        </Typography>
                                                    )}
                                                </Box>

                                                {service?.type === 2 && (
                                                    <Box>
                                                        <Typography>Số m2:</Typography>
                                                        {isEditing ? (
                                                            <TextField
                                                                fullWidth
                                                                type='number'
                                                                name='quantity'
                                                                value={values.quantity}
                                                                onBlur={(e) => {
                                                                    let newQuantity = Number(e.target.value)

                                                                    // Giới hạn số lượng từ 201 đến 500 khi người dùng rời khỏi input
                                                                    if (newQuantity < 201) {
                                                                        newQuantity = 201
                                                                    } else if (newQuantity > 500) {
                                                                        newQuantity = 500
                                                                    }

                                                                    setQuantity(newQuantity)
                                                                    setFieldValue('quantity', newQuantity)

                                                                    // Cập nhật giá trị price sau khi đã điều chỉnh quantity
                                                                    setFieldValue('price', Number(service?.price) * newQuantity)
                                                                }}
                                                                onChange={(e) => {
                                                                    const newQuantity = Number(e.target.value)
                                                                    setQuantity(newQuantity)
                                                                    setFieldValue('quantity', newQuantity)
                                                                    setFieldValue('price', Number(service?.price) * newQuantity)
                                                                }}
                                                                helperText='Tối thiểu 201m2 và tối đa 500m2'
                                                            />
                                                        ) : (
                                                            <Typography color={colors.greenLight[300]} fontWeight='600'>
                                                                {dataDetails?.quantity}
                                                            </Typography>
                                                        )}
                                                    </Box>
                                                )}
                                                {!isEditing ? (
                                                    <Box>
                                                        <Typography>Ngày: </Typography>
                                                        <Typography color={colors.greenLight[300]} fontWeight='600'>
                                                            {moment(dataDetails?.date).format('DD-MM-YYYY')}
                                                        </Typography>
                                                    </Box>
                                                ) : (
                                                    <Box>
                                                        <Typography>Ngày: </Typography>
                                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                                            <DatePicker
                                                                name='date'
                                                                value={values.date}
                                                                onChange={(newValue) => {
                                                                    setFieldValue('date', newValue)
                                                                }}
                                                                slotProps={{
                                                                    textField: {
                                                                        variant: 'outlined',
                                                                        margin: 'normal',
                                                                        error: touched.date && !!errors.date,
                                                                        helperText: touched.date && errors.date,
                                                                        fullWidth: true,
                                                                    },
                                                                }}
                                                                disablePast
                                                            />
                                                        </LocalizationProvider>
                                                    </Box>
                                                )}
                                                {!isEditing ? (
                                                    <Box>
                                                        <Typography>Giờ: </Typography>
                                                        <Typography color={colors.greenLight[300]} fontWeight='600'>
                                                            {minutesToHours(dataDetails?.time)}
                                                        </Typography>
                                                    </Box>
                                                ) : (
                                                    <Box>
                                                        <Typography>Giờ: </Typography>
                                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                                            <TimePicker
                                                                name='time'
                                                                value={values.time}
                                                                onChange={(newValue) => {
                                                                    setFieldValue('time', newValue)
                                                                }}
                                                                slotProps={{
                                                                    textField: {
                                                                        variant: 'outlined',
                                                                        margin: 'normal',
                                                                        error: touched.time && !!errors.time,
                                                                        helperText: touched.time && errors.time,
                                                                        fullWidth: true,
                                                                    },
                                                                }}
                                                                timeSteps={{ minutes: 30 }}
                                                                minTime={moment(values?.date).isSame(moment(), 'day') ? moment() : undefined}
                                                                ampm={false}
                                                            />
                                                        </LocalizationProvider>
                                                    </Box>
                                                )}
                                                {isEditing ? (
                                                    <Box>
                                                        <Typography>Giá tiền: </Typography>
                                                        <TextField
                                                            name='price'
                                                            type='text'
                                                            value={isLoadingFillPrice ? '' : formatNumber(values.price)}
                                                            // value={formatNumber(values.price)}
                                                            onChange={(e) => {
                                                                const rawValue = parseNumber(e.target.value)
                                                                setFieldValue('price', Number(rawValue))
                                                            }}
                                                            onBlur={handleBlur}
                                                            error={!!touched.price && !!errors.price}
                                                            helperText={touched.price && errors.price}
                                                            InputProps={{
                                                                startAdornment: isLoadingFillPrice ? (
                                                                    <InputAdornment position='start'>
                                                                        <CircularProgress size={25} color='secondary' />
                                                                    </InputAdornment>
                                                                ) : null,
                                                                sx: {
                                                                    '& .MuiInputBase-input': {
                                                                        color: colors.greenLight[400],
                                                                    },
                                                                    fontWeight: 600,
                                                                },
                                                            }}
                                                            fullWidth
                                                        />
                                                    </Box>
                                                ) : (
                                                    <Box display='flex' alignItems='center'>
                                                        <Typography>Giá tiền:</Typography>
                                                        <Typography color={colors.greenLight[300]} fontWeight='600'>
                                                            {formatNumber(dataDetails?.price ?? '')} VNĐ
                                                        </Typography>
                                                    </Box>
                                                )}
                                                {!isEditing && (
                                                    <>
                                                        {dataDetails.hasOwnProperty('userFromAdminOrder') && (
                                                            <Box display='flex' alignItems='center'>
                                                                <Typography>Lưu ý:</Typography>
                                                                <Typography color={colors.orangeAccent[500]} fontWeight='600'>
                                                                    Admin tạo đơn
                                                                </Typography>
                                                            </Box>
                                                        )}
                                                        {dataRate.sprayBookingRating && (
                                                            <Box display='flex' alignItems='center'>
                                                                <Typography>Đánh giá:</Typography>
                                                                <Rating name='read-only' value={dataRate.sprayBookingRating} readOnly />
                                                            </Box>
                                                        )}
                                                    </>
                                                )}
                                                {[3, 4, 5].includes(dataDetails.status) && (
                                                    <Box display='flex' alignItems='center'>
                                                        <Typography>Lý do huỷ:</Typography>
                                                        <Typography color='red' fontWeight='600'>
                                                            {dataDetails.cancelReason != '' ? dataDetails.cancelReason : 'không có lý do'}
                                                        </Typography>
                                                    </Box>
                                                )}
                                            </>
                                        )}
                                    </Box>
                                </Box>
                            )
                        }}
                    </Formik>
                    {!isEditing && (
                        <Box p='20px' flex={0.7} height='100%'>
                            <Box display='flex' alignItems='end' height='100%'>
                                <Card sx={{ maxWidth: 345, height: '100%', width: '100%' }}>
                                    {isLoadingInfo ? (
                                        <CardActionArea
                                            sx={{
                                                height: '100%',
                                                width: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'start',
                                            }}
                                        >
                                            <Skeleton animation='wave' variant='rounded' width='100%' height={140} sx={{ mb: 2 }} />
                                            <Skeleton animation='wave' height={10} width='80%' sx={{ mb: 1 }} />
                                            <Skeleton animation='wave' height={10} width='60%' />
                                        </CardActionArea>
                                    ) : (
                                        <CardActionArea
                                            sx={{
                                                height: '100%',
                                                width: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'start',
                                            }}
                                        >
                                            <CardMedia
                                                component='img'
                                                sx={{ flex: 1, height: '140px' }}
                                                image={
                                                    dataDetails?.taskerId?.avatar ? baseURL + dataDetails?.taskerId?.avatar : images.placeholderPerson
                                                }
                                                alt='green iguana'
                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant='h5' component='div'>
                                                    {dataDetails?.taskerId?.fullName ?? 'Chưa gán người làm'}
                                                </Typography>
                                                <Box display='flex' alignItems='center'>
                                                    <Phone />
                                                    <Typography ml='10px' variant='body2' color='text.secondary' fontSize='14px' fontWeight='600'>
                                                        {dataDetails?.taskerId?.phoneNumber}
                                                    </Typography>
                                                </Box>
                                            </CardContent>
                                        </CardActionArea>
                                    )}
                                </Card>
                            </Box>
                        </Box>
                    )}
                </Box>
            </Box>
        </Modal>
    )
}

export default memo(ModalSprayDetail)
