import { profit_permanent_task_by_month_by_year_admin } from '../../../../../utils/api/apiList'
import BookingProfit from './BookingProfit'

const PermanentBookingProfit = () => {
    return (
        <BookingProfit
            title='CA CỐ ĐỊNH'
            subtitle='Trang thống kê lợi nhuận ca cố định'
            apiEndpoint={profit_permanent_task_by_month_by_year_admin}
            nameChart='Permanent'
        />
    )
}

export default PermanentBookingProfit
