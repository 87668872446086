import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    FormControl,
    IconButton,
    InputAdornment,
    Modal,
    TextField,
    Typography,
    useTheme,
} from '@mui/material'
import { Formik } from 'formik'
import { Cancel, Close, Send } from '@mui/icons-material'
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import CustomLoadingComponent from '../../../components/loading'
import { tokens } from '../../../theme'
import { useEffect, useRef, useState } from 'react'
import request from '../../../utils/api/request'
import { get_all_spraydetails_for_city } from '../../../utils/api/apiList'
import { formatNumber, parseNumber } from '../../../components/prefixData'

function ModalCreateSpray({ open, onClose, isLoading, initialValues, validationSchema, handleSubmit, boxName }) {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const [services, setServices] = useState(null)
    const [isLoadingFillPrice, setIsLoadingFillPrice] = useState(false)
    const [quantity, setQuantity] = useState(null)
    const [service, setService] = useState(null)

    // ref setField
    const setFieldValueRef = useRef()

    //fetch all service air
    const fetchServices = () => {
        request
            .post(get_all_spraydetails_for_city, {
                lat: 0,
                lng: 0,
                distance: 10000,
            })
            .then((res) => {
                const dataFilter = res.data.data.filter((service) => service.type !== 3)
                setServices(dataFilter)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        fetchServices()
    }, [])

    useEffect(() => {
        // Bắt đầu loading
        setIsLoadingFillPrice(true)

        if (service && quantity) {
            const timerLoadingPrice = setTimeout(() => {
                const calculatedPrice = Number(service?.price) * quantity
                setFieldValueRef.current('price', calculatedPrice)
                setIsLoadingFillPrice(false)
            }, 300)

            return () => {
                clearTimeout(timerLoadingPrice)
                setIsLoadingFillPrice(false)
            }
        } else {
            setIsLoadingFillPrice(false)
        }
    }, [service, quantity])

    //style
    const styleModal = {
        borderRadius: '5px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 700,
        maxHeight: '90vh',
        bgcolor: theme.palette.mode === 'dark' ? '#323d58' : '#e0e0e0',
        // bgcolor: colors.primary[400],
        border: '1px solid rgba(0, 0, 0, 0.1) 0px 4px 12px',
        boxShadow: 24,
        p: 4,
        pt: '15px',
    }
    const styleBox = {
        '& .MuiBox-root': {
            display: 'flex',
            alignItems: 'center',
            gap: '20px',
            width: '80%',
            '& .MuiTextField-root': {
                flex: 1,
            },
        },
        '& .MuiTypography-root': {
            minWidth: '120px',
        },
        '& .MuiBox-root.select': {
            width: '100%',
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px',
        // maxHeight: '700px',
        maxHeight: '70vh',
        overflowY: 'auto',
        mt: '5px',
        boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
        pb: '20px',
        pt: '24px',
        backgroundColor: theme.palette.mode === 'dark' ? '#142043' : '#e7e7e7',
        borderRadius: '5px',
        '& .Mui-focused fieldset': {
            borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.6) !important' : 'rgba(0, 0, 0, 0.8)', // Màu border khi focused
            borderWidth: theme.palette.mode === 'dark' ? '2px' : '1px !important',
        },
    }

    return (
        <Modal open={open} onClose={onClose} style={{ backdropFilter: 'blur(5px)', zIndex: '99' }}>
            <Box sx={styleModal}>
                <IconButton
                    sx={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                    }}
                    onClick={onClose}
                >
                    <Close color='disabled' />
                </IconButton>
                <Box>
                    <Typography variant='h4' component='h2' color={colors.grey[400]}>
                        Tạo đơn phun khử khuẩn
                    </Typography>
                    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit} enableReinitialize={true}>
                        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
                            setFieldValueRef.current = setFieldValue
                            return (
                                <Box component='form' onSubmit={handleSubmit} sx={styleBox}>
                                    {isLoading && (
                                        <Box
                                            position='absolute'
                                            top={0}
                                            left={0}
                                            right={0}
                                            bottom={0}
                                            display='flex'
                                            alignItems='center'
                                            justifyContent='center'
                                            bgcolor='rgba(255, 255, 255, 0.2)'
                                            zIndex={2}
                                            width='100% !important'
                                        >
                                            <CustomLoadingComponent />
                                        </Box>
                                    )}
                                    <Box>
                                        <Typography>Tên khách hàng: </Typography>
                                        <TextField
                                            name='name'
                                            value={values.name || ''}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            placeholder='Nhập tên khách'
                                            error={!!touched.name && !!errors.name}
                                            helperText={touched.name && errors.name}
                                        />
                                    </Box>
                                    <Box>
                                        <Typography>Số điện thoại: </Typography>
                                        <TextField
                                            name='phoneNumber'
                                            value={values.phoneNumber || ''}
                                            placeholder='Nhập số điện thoại'
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={!!touched.phoneNumber && !!errors.phoneNumber}
                                            helperText={touched.phoneNumber && errors.phoneNumber}
                                        />
                                    </Box>
                                    <Box>
                                        <Typography>Địa chỉ: </Typography>
                                        <TextField
                                            name='address'
                                            value={values.address || ''}
                                            placeholder='Nhập địa chỉ'
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={!!touched.address && !!errors.address}
                                            helperText={touched.address && errors.address}
                                        />
                                    </Box>
                                    <Box>
                                        <FormControl fullWidth sx={{ display: 'flex', flexDirection: 'row', gap: '20px', alignItems: 'center' }}>
                                            <Typography>Dịch vụ</Typography>
                                            <Autocomplete
                                                name='sprayService'
                                                options={services || []}
                                                value={values.sprayService || null}
                                                onChange={(event, newValue) => {
                                                    setFieldValue('sprayService', newValue)

                                                    // Kiểm tra loại dịch vụ và đặt lại giá trị quantity
                                                    if (newValue?.type === 2) {
                                                        setFieldValue('quantity', 201) // dịch vụ 201-500 m2
                                                        setQuantity(201)
                                                    } else {
                                                        setFieldValue('quantity', 1) // mặc định quantity 1 cho các dịch vụ type 1
                                                    }

                                                    // Tính toán giá ngay khi chọn dịch vụ
                                                    const calculatedPrice = newValue?.price * (newValue?.type === 2 ? 201 : 1)
                                                    setFieldValue('price', calculatedPrice)
                                                    setService(newValue)
                                                }}
                                                autoHighlight
                                                disableClearable
                                                getOptionLabel={(service) => service?.description || ''}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        error={Boolean(touched.sprayService && errors.sprayService)}
                                                        helperText={touched.sprayService && errors.sprayService}
                                                        onBlur={handleBlur}
                                                    />
                                                )}
                                                renderOption={(props, option) => (
                                                    <li {...props} key={option._id} style={{ marginTop: '10px' }}>
                                                        <Box width='100%'>
                                                            <Box display='flex' justifyContent='space-between'>
                                                                {/* <Typography>{option.name}</Typography> */}
                                                                <Typography>{option.description}</Typography>
                                                                <Button
                                                                    variant='text'
                                                                    // startIcon={<PaidOutlinedIcon />}
                                                                    sx={{
                                                                        p: '0',
                                                                        color: colors.greenAccent[400],
                                                                        fontSize: '14px',
                                                                    }}
                                                                >
                                                                    {formatNumber(option.price)}
                                                                </Button>
                                                            </Box>
                                                        </Box>
                                                    </li>
                                                )}
                                                fullWidth
                                            />
                                        </FormControl>
                                    </Box>
                                    {service?.type === 2 && (
                                        <Box>
                                            <Typography>Số mét vuông(m2): </Typography>
                                            <TextField
                                                name='quantity'
                                                type='number'
                                                value={values.quantity || ''}
                                                placeholder='Nhập số lượng'
                                                onBlur={(e) => {
                                                    let newQuantity = Number(e.target.value)

                                                    // Giới hạn số lượng từ 201 đến 500 khi người dùng rời khỏi input
                                                    if (newQuantity < 201) {
                                                        newQuantity = 201
                                                    } else if (newQuantity > 500) {
                                                        newQuantity = 500
                                                    }

                                                    setQuantity(newQuantity)
                                                    setFieldValue('quantity', newQuantity)

                                                    // Cập nhật giá trị price sau khi đã điều chỉnh quantity
                                                    setFieldValue('price', Number(service?.price) * newQuantity)
                                                }}
                                                onChange={(e) => {
                                                    const newQuantity = Number(e.target.value)
                                                    setQuantity(newQuantity)
                                                    setFieldValue('quantity', newQuantity)
                                                }}
                                                helperText='Tối thiểu 201m2 và tối đa 500m2'
                                            />
                                        </Box>
                                    )}
                                    <Box>
                                        <Typography>Ngày: </Typography>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DatePicker
                                                name='date'
                                                value={values.date}
                                                onChange={(newValue) => {
                                                    setFieldValue('date', newValue)
                                                }}
                                                slotProps={{
                                                    textField: {
                                                        variant: 'outlined',
                                                        margin: 'normal',
                                                        error: touched.date && !!errors.date,
                                                        helperText: touched.date && errors.date,
                                                        fullWidth: true,
                                                    },
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </Box>
                                    <Box>
                                        <Typography>Thời gian: </Typography>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <TimePicker
                                                name='time'
                                                value={values.time}
                                                onChange={(newValue) => {
                                                    setFieldValue('time', newValue)
                                                }}
                                                slotProps={{
                                                    textField: {
                                                        variant: 'outlined',
                                                        margin: 'normal',
                                                        error: touched.time && !!errors.time,
                                                        helperText: touched.time && errors.time,
                                                        fullWidth: true,
                                                    },
                                                }}
                                                timeSteps={{ minutes: 30 }}
                                                ampm={false}
                                            />
                                        </LocalizationProvider>
                                    </Box>
                                    <Box>
                                        <Typography>Giá tiền: </Typography>
                                        <TextField
                                            name='price'
                                            value={isLoadingFillPrice ? '' : formatNumber(values.price)}
                                            onChange={(e) => {
                                                const rawValue = parseNumber(e.target.value)
                                                setFieldValue('price', Number(rawValue))
                                            }}
                                            placeholder='Nhập giá tiền'
                                            onBlur={handleBlur}
                                            error={!!touched.price && !!errors.price}
                                            helperText={touched.price && errors.price}
                                            InputProps={{
                                                startAdornment: isLoadingFillPrice ? (
                                                    <InputAdornment position='start'>
                                                        <CircularProgress size={25} color='secondary' />
                                                    </InputAdornment>
                                                ) : null,
                                                sx: {
                                                    '& .MuiInputBase-input': {
                                                        color: colors.greenLight[400],
                                                        fontWeight: 600,
                                                    },
                                                },
                                            }}
                                        />
                                    </Box>
                                    <Box justifyContent='end'>
                                        <Button
                                            sx={{ minWidth: '96px', p: '8px 16px' }}
                                            type='submit'
                                            variant='contained'
                                            color='success'
                                            endIcon={<Send />}
                                        >
                                            Gửi
                                        </Button>
                                        <Button
                                            sx={{ minWidth: '96px', p: '8px 16px' }}
                                            variant='outlined'
                                            color='inherit'
                                            startIcon={<Cancel />}
                                            onClick={onClose}
                                        >
                                            Huỷ
                                        </Button>
                                    </Box>
                                </Box>
                            )
                        }}
                    </Formik>
                </Box>
            </Box>
        </Modal>
    )
}

export default ModalCreateSpray
