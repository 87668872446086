import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { IconButton, useTheme } from '@mui/material'
import { tokens } from '../../theme'
import TextField from '@mui/material/TextField'
import useMediaQuery from '@mui/material/useMediaQuery'
import request from '../../utils/api/request'
import { create_service, update_service } from '../../utils/api/apiList'
import { message } from 'antd'
import { Formik } from 'formik'
import * as yup from 'yup'
import { Cancel, Close, Send } from '@mui/icons-material'
import { formatNumber, parseNumber } from '../../components/prefixData'
const validateFormSchema = yup.object().shape({
    name: yup.string().required('Vui lòng nhập tên dịch vụ'),
    price: yup.number().typeError('Giá tiền phải là số dương').min(0, 'Giá phải lớn hơn hoặc bằng 0').required('Yêu cầu nhập giá'),
})

const ModalService = ({ type, open, onClose, currentRow, fetchData }) => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    //message
    const [messageApi, messageContextHolder] = message.useMessage()

    //media query
    const matches = useMediaQuery('(min-width:600px)')

    //intialformvalues
    const initialFormValues = {
        name: currentRow?.name ?? '',
        price: currentRow?.price ?? '',
        description: currentRow?.description ?? '',
    }

    //fetch create
    const fetchCreate = (values) => {
        request
            .post(create_service, { ...values })
            .then((res) => {
                console.log(res)
                message.success(
                    <p style={{ display: 'inline-block' }}>
                        Thêm dịch vụ <span style={{ color: 'green', fontWeight: '600' }}>{values.name}</span> thành công!
                    </p>
                )
                fetchData()
            })
            .catch((err) => {
                message.error('Thêm dịch vụ thất bại!')
                console.log(err)
            })
    }

    //fetchUpdate
    const fetchUpdate = (values) => {
        request
            .post(update_service, { ...values, _id: currentRow?._id })
            .then((res) => {
                message.success('Cập nhật dịch vụ thành công!')
                fetchData()
            })
            .catch((err) => {
                message.error('Cập nhật dịch vụ thất bại!')
                console.log(err)
            })
    }

    //handle save
    const handleSave = (values, { resetForm }) => {
        // console.log('values service', values)
        if (type === 'create') {
            fetchCreate(values)
        } else {
            fetchUpdate(values)
            console.log('values service', values)
        }

        onClose()
        resetForm()
    }

    return (
        <Modal
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    onClose()
                }
            }}
            style={{ backdropFilter: 'blur(5px)' }}
        >
            <Box
                sx={{
                    borderRadius: '5px',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: matches ? 700 : '90vw',
                    bgcolor: theme.palette.mode === 'dark' ? '#1F2A40' : '#e0e0e0',
                    border: '1px solid rgba(0, 0, 0, 0.1) 0px 4px 12px',
                    boxShadow: 24,
                    p: matches ? 4 : 2,
                }}
            >
                {messageContextHolder}
                <Typography id='modal-modal-title' color={colors.grey[500]} fontSize='14px'>
                    {type === 'create' ? 'THÊM MỚI DỊCH VỤ' : 'CHỈNH SỬA DỊCH VỤ'}
                </Typography>
                <IconButton
                    sx={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                    }}
                    onClick={onClose}
                >
                    <Close color='disabled' />
                </IconButton>

                <Box id='modal-modal-description' sx={{ mt: 2 }}>
                    <Formik initialValues={initialFormValues} validationSchema={validateFormSchema} onSubmit={handleSave}>
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => {
                            return (
                                <Box
                                    component='form'
                                    sx={{
                                        '& > :not(style)': { m: 1, width: '85%' },
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                    autoComplete='off'
                                    onSubmit={handleSubmit}
                                >
                                    <TextField
                                        name='name'
                                        label='Tên dịch vụ'
                                        color='secondary'
                                        value={values.name}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        error={!!touched.name && !!errors.name}
                                        helperText={touched.name && errors.name}
                                    />
                                    <TextField
                                        name='price'
                                        label='Giá tiền'
                                        color='secondary'
                                        value={formatNumber(values.price)}
                                        onChange={(e) => {
                                            const rawValue = parseNumber(e.target.value)
                                            setFieldValue('price', Number(rawValue))
                                        }}
                                        onBlur={handleBlur}
                                        error={!!touched.price && !!errors.price}
                                        helperText={touched.price && errors.price}
                                    />
                                    <TextField
                                        name='description'
                                        label='Mô tả'
                                        color='secondary'
                                        value={values.description}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        error={!!touched.description && !!errors.description}
                                        helperText={touched.description && errors.description}
                                    />
                                    <Box mt='16px !important' display='flex' justifyContent='flex-end'>
                                        <Button
                                            type='submit'
                                            variant='contained'
                                            color='success'
                                            sx={{ minWidth: '96px', p: '8px 16px', mr: '10px', fontWeight: 600 }}
                                            endIcon={<Send />}
                                        >
                                            Lưu
                                        </Button>
                                        <Button
                                            sx={{ minWidth: '96px', p: '8px 16px' }}
                                            variant='outlined'
                                            color='inherit'
                                            startIcon={<Cancel />}
                                            onClick={onClose}
                                        >
                                            Huỷ
                                        </Button>
                                    </Box>
                                </Box>
                            )
                        }}
                    </Formik>
                </Box>
            </Box>
        </Modal>
    )
}

export default ModalService
