import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Typography, useTheme, Chip, debounce } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { tokens } from '../../../../theme'
import Header from '../../../../components/Header'
import CustomNoRowsOverlay from '../../../../components/noRows'
import StyleDataGrid from '../../../../assets/styles/styleDataGrid'
import request from '../../../../utils/api/request'
import { get_all_deposit } from '../../../../utils/api/apiList'
import moment from 'moment'

// filter column - filter trực tiếp trên columns
const VISIBLE_FIELDS = ['index', '_id', 'fullName', 'nameCompany', 'phoneNumber', 'amount', 'balance', 'dateCreatedConvert', 'statusConvert']

const Blance = () => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    // const [pageSize, setPageSize] = useState(10)

    // const [dataDeposit, setDataDeposit] = useState(null)
    const [loading, setLoading] = useState(true)
    const [dataRowsAndColumns, setDataRowsAndColumns] = useState({
        rows: [],
        columns: [],
    })
    const [queryOptions, setQueryOptions] = useState({})

    // pagination server side
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 25,
    })
    const [totalRows, setTotalRows] = useState(0)
    // search server side
    const [searchTerm, setSearchTerm] = useState('')
    const [inputText, setInputText] = useState('')

    // Debounced
    const debouncedSetSearchTerm = useCallback(
        debounce((value) => {
            setSearchTerm(value)
        }, 700),
        []
    )

    // handle search
    const handleInputChange = (e) => {
        const value = e.target.value
        setInputText(value)
        debouncedSetSearchTerm(value)
    }

    //columns
    const columns = [
        {
            field: 'index',
            headerName: 'STT',
            width: 50,
            sortable: false,
        },
        {
            field: '_id',
            headerName: '#Id',
            width: 200,
            cellClassName: 'name-column--cell--deposit',
            sortable: false,
        },
        {
            field: 'fullName',
            headerName: 'Tên nhân viên',
            cellClassName: 'name-column--cell--notTask',
            flex: 1,
            minWidth: 200,
            sortable: false,
        },
        {
            field: 'nameCompany',
            headerName: 'Tên công ty',
            cellClassName: 'name-column--cell--notTask',
            flex: 1,
            minWidth: 200,
            sortable: false,
        },
        {
            field: 'phoneNumber',
            headerName: 'Số điện thoại',
            flex: 1,
            minWidth: 150,
            cellClassName: 'name-column--cell--deposit',
            sortable: false,
        },
        {
            field: 'amount',
            headerName: 'Số tiền nạp (VNĐ)',
            cellClassName: 'amount',
            flex: 1,
            minWidth: 150,
            sortable: false,
            renderCell: (params) => {
                return (
                    <Box>
                        <Typography fontWeight='700'>{params?.value?.toLocaleString()}</Typography>
                    </Box>
                )
            },
        },
        {
            field: 'balance',
            headerName: 'Số dư tài khoản (VNĐ)',
            cellClassName: 'name-column--cell balance',
            // headerAlign: 'left',
            // align: 'left',
            flex: 1,
            minWidth: 150,
            sortable: false,
            renderCell: (params) => {
                return (
                    <Box>
                        <Typography fontWeight='700'>{params?.value?.toLocaleString()}</Typography>
                    </Box>
                )
            },
        },
        {
            field: 'dateCreatedConvert',
            headerName: 'Thời gian',
            flex: 1,
            minWidth: 150,
            renderCell: (params) => {
                return (
                    <Box>
                        <Typography fontWeight='600'>{params.value}</Typography>
                    </Box>
                )
            },
        },
        {
            field: 'statusConvert',
            headerName: 'Trạng thái',
            width: 150,
            renderCell: ({ row }) => {
                const getStatus = (status) => {
                    switch (status) {
                        case 0:
                            return { label: 'Chờ xử lý', color: 'warning' }
                        case 1:
                            return { label: 'Đã xử lý', color: 'success' }
                        case 2:
                            return { label: 'Đã huỷ', color: 'error' }
                        default:
                            return { label: 'Không xác định', color: 'default' }
                    }
                }

                const statusInfo = getStatus(row.status)

                return <Chip label={statusInfo.label} color={statusInfo.color} sx={{ fontWeight: 600, minWidth: '80px', color: 'white' }} />
            },
        },
    ]

    // convert root field
    const mapFieldName = (field) => {
        switch (field) {
            case 'dateCreatedConvert':
                return 'dateCreated'
            case 'statusConvert':
                return 'status'
            default:
                return field
        }
    }

    // sort server
    const handleSortModelChange = useCallback((sortModel) => {
        if (sortModel.length > 0) {
            const sortField = mapFieldName(sortModel[0].field)
            const sortOrder = sortModel[0].sort === 'asc' ? 1 : -1
            setQueryOptions({ [sortField]: sortOrder })
        } else {
            setQueryOptions({})
        }
    }, [])

    useEffect(() => {
        fetchDataDeposit()
    }, [paginationModel, searchTerm, queryOptions])

    //fetchData
    const fetchDataDeposit = () => {
        request
            .post(get_all_deposit, {
                page: paginationModel.page + 1,
                limit: paginationModel.pageSize,
                search: searchTerm,
                sort: queryOptions,
            })
            .then((res) => {
                console.log(res)
                const dataRs = res.data.data.docs
                const dataConvert = dataRs.map((elm, index) => {
                    let status = elm?.status
                    switch (status) {
                        case 0:
                            status = 'Chờ xử lý'
                            break
                        case 1:
                            status = 'Đã xử lý'
                            break
                        case 2:
                            status = 'Đã huỷ'
                            break
                        default:
                            break
                    }
                    return {
                        ...elm,
                        index: index + 1 + paginationModel.page * paginationModel.pageSize,
                        fullName: elm.userId?.fullName,
                        phoneNumber: elm.userId?.phoneNumber,
                        balance: elm.userId?.balance,
                        dateCreatedConvert: moment(elm?.dateCreated).format('DD-MM-YYYY HH:mm'),
                        statusConvert: status,
                        nameCompany: elm.userId?.fromCompany?.name,
                    }
                })

                console.log(dataConvert)

                // setData(dataConvert)

                setDataRowsAndColumns({ rows: dataConvert, columns })

                // setDataDeposit(dataConvert)
                setTotalRows(res.data.data.total)
                setLoading(false)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // columns has filter
    const columnsHasFilter = useMemo(
        () => dataRowsAndColumns?.columns?.filter((column) => VISIBLE_FIELDS.includes(column.field)),
        [dataRowsAndColumns?.columns]
    )

    return (
        <Box
            m='20px'
            sx={{
                '& .MuiTypography-root.MuiTypography-h2': {
                    margin: '0 0 5px 0',
                },
            }}
        >
            <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Header title='QUẢN LÝ PARTNER NẠP' subtitle='Trang quản lý nạp' />
            </Box>
            <Box
                height='75vh'
                m='40px 0 0 0'
                sx={{
                    ...StyleDataGrid(),
                    '& .MuiDataGrid-cell': {
                        borderBottom: 'none',
                        display: 'flex',
                        alignItems: 'center',
                    },
                    '& .name-column--cell--withdraw': {
                        color: colors.redAccent[400],
                    },
                    '& .name-column--cell.balance': {
                        color: theme.palette.mode === 'dark' ? '#77d4ff' : '#1939e3',
                    },
                    '& .amount': {
                        color: theme.palette.mode === 'dark' ? '#ff9aef' : '#ff24dc',
                    },
                }}
            >
                <DataGrid
                    getRowHeight={() => 'auto'}
                    sx={{
                        '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                            py: 1,
                        },
                        '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                            py: '15px',
                        },
                        '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
                            py: '22px',
                        },
                    }}
                    getRowId={(row) => row._id}
                    rows={dataRowsAndColumns.rows}
                    columns={dataRowsAndColumns.columns}
                    // rows={dataDeposit}
                    // columns={columns}
                    //pagination server side
                    rowCount={totalRows}
                    initialState={{
                        pagination: { paginationModel },
                        density: 'comfortable',
                    }}
                    pagination
                    pageSizeOptions={[10, 25, 50]}
                    paginationMode='server'
                    onPaginationModelChange={setPaginationModel}
                    //pagination server side

                    // sort server
                    sortingMode='server'
                    onSortModelChange={handleSortModelChange}
                    loading={loading}
                    slots={{
                        toolbar: GridToolbar,
                        noRowsOverlay: CustomNoRowsOverlay,
                    }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: {
                                onChange: handleInputChange,
                                value: inputText,
                                placeholder: 'search theo _id',
                            },
                        },
                    }}
                    localeText={{
                        // MuiTablePagination: {
                        //     labelDisplayedRows: ({ from, to, count }) =>
                        //         `${from} - ${to} of more than ${count}`,
                        // },//custom pagination

                        //custom text density (thanh toolbar)
                        toolbarDensity: 'Size',
                        toolbarDensityLabel: 'Size',
                        toolbarDensityCompact: 'Small',
                        toolbarDensityStandard: 'Medium',
                        toolbarDensityComfortable: 'Large',
                    }}
                />
            </Box>
        </Box>
    )
}

export default Blance
