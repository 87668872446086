import { Box, Button, Popover, Typography, useTheme } from '@mui/material'
import { tokens } from '../../../theme'

function BoxDelete({ anchorEl, onClose, handleConfirm }) {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    return (
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <Box p={2}>
                <Typography>Bạn muốn xoá bảng ghi này?</Typography>
                <Box
                    marginTop='10px'
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <Button
                        size='small'
                        variant='contained'
                        color='error'
                        onClick={handleConfirm}
                        sx={{
                            fontWeight: 600,
                            color: colors.grey[100],
                            bgcolor: '#d40000',
                            color: 'white',
                            fontWeight: '600',
                            mr: '20px',
                            '&:hover': {
                                bgcolor: 'red',
                            },
                        }}
                    >
                        Chấp nhận
                    </Button>
                    <Button
                        size='small'
                        onClick={onClose}
                        variant='outlined'
                        sx={{
                            bgcolor: 'white',
                            '&:hover': {
                                bgcolor: '#e8e8e8c4',
                            },
                        }}
                    >
                        Huỷ
                    </Button>
                </Box>
            </Box>
        </Popover>
    )
}

export default BoxDelete
