import { useState } from 'react'
import {
    Box,
    useTheme,
    Button,
    IconButton,
    Typography,
    Popover,
} from '@mui/material'
import { tokens } from '../../../theme'
import Header from '../../../components/Header'
import Bar from '../../bar'

const StatisticsService = () => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    return (
        <Box
            m='20px'
            sx={{
                '& .MuiTypography-root.MuiTypography-h2': {
                    margin: '0 0 5px 0',
                },
            }}
        >
            <Box
                display='flex'
                justifyContent='space-between'
                alignItems='center'
            >
                <Header
                    title='THỐNG KÊ KHỐI LƯỢNG DỊCH VỤ'
                    // subtitle='Trang thống kê'
                />
            </Box>
            <Box
                height='70vh'
                m='40px 0 0 0'
                display='flex'
                flexDirection='column'
            >
                <Box display='flex' flex='1 0 auto' gap={10}>
                    <Box>
                        TỔNG SỐ LƯỢNG DỊCH VỤ:{' '}
                        <Typography
                            fontWeight={700}
                            display='inline-block'
                            variant='h4'
                        >
                            12
                        </Typography>
                    </Box>
                    <Box>
                        ĐƠN ĐẶT HÔM NAY:{' '}
                        <Typography
                            fontWeight={700}
                            display='inline-block'
                            variant='h4'
                        >
                            9
                        </Typography>
                    </Box>
                    <Box>
                        TỔNG SỐ LƯỢNG ĐƠN ĐẶT:{' '}
                        <Typography
                            fontWeight={700}
                            display='inline-block'
                            variant='h4'
                        >
                            889
                        </Typography>
                    </Box>
                </Box>
                <Box>
                    <Bar />
                </Box>
            </Box>
        </Box>
    )
}

export default StatisticsService
