import { tokens } from '../../theme'
import { useTheme } from '@mui/material'

function StyleDataGrid() {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    return {
        '& .MuiDataGrid-root': {
            border: 'none',
        },
        '& .MuiDataGrid-cell': {
            display: 'flex',
            alignItems: 'center',
        },
        '& .name-column--cell': {
            color: theme.palette.mode === 'dark' ? colors.greenAccent[300] : '#1939e3', //#1939e3  233dcb
            // color: colors.greenAccent[300],
            fontWeight: '600',
        },
        '& .name-column--cell--notTask': {
            color: colors.greenAccent[400],
            fontWeight: '700',
        },
        '& .name-column--cell--deposit': {
            fontWeight: '700',
        },
        '& .cost-column': { display: 'flex', alignItems: 'center' },
        '& .action-column,.status-column': { display: 'flex' },
        '& .MuiDataGrid-columnHeaders': {
            background: theme.palette.mode === 'dark' ? 'rgb(62, 67, 150)' : 'linear-gradient(to right, #b993d6, #8ca6db)',
            borderBottom: 'none',
        },
        //
        '& .MuiDataGrid-columnHeaders [role=row]': {
            background: 'unset !important',
        },
        '& .css-jmgi9p::after': {
            height: '0px',
        },

        '& .MuiDataGrid-virtualScroller': {
            backgroundColor: colors.primary[400],
        },
        '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            background: theme.palette.mode === 'dark' ? 'rgb(62, 67, 150)' : 'linear-gradient(to right, #b993d6, #8ca6db)',
            borderBottomLeftRadius: 4,
            borderBottomRightRadius: 4,
        },
        '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
            color: `${colors.grey[100]} !important`,
        },
        '& .css-tgsonj,.MuiDataGrid-virtualScrollerContent': {
            borderLeft: '1px solid rgba(0, 0, 0, 0.15)',
            borderRight: '1px solid rgba(0, 0, 0, 0.15)',
        },
        '& .cancelled-row': {
            background:
                theme.palette.mode === 'dark'
                    ? 'linear-gradient(to right, #200122, #6f0000)'
                    : 'linear-gradient(to right, #ef8181, #fee192, #e75858)', //'linear-gradient(to right, #f3d6d6, #ffedbc)',
            '&:hover': {
                background:
                    theme.palette.mode === 'dark' ? 'linear-gradient(to right, #641325, #960808)' : 'linear-gradient(to right, #ffb39b, #e4d5ab)',
            },
        },
        '& .pending-row': {
            background:
                theme.palette.mode === 'dark'
                    ? 'linear-gradient(to right, #1e4e18, #162211)'
                    : 'linear-gradient(to right, #5bca66, #aed679, #3da358)', //'linear-gradient(to right, #d2fad6, #e7f2d8, #d1efff)',
            '&:hover': {
                background:
                    theme.palette.mode === 'dark'
                        ? 'linear-gradient(to right, #275433, #194d44)'
                        : 'linear-gradient(to right, #77dc81, #b9e284, #54ce74)', //'linear-gradient(to right, #c1f3c6, #d1efff)',
            },
        },
        '& .completed-row': {
            background:
                theme.palette.mode === 'dark'
                    ? 'linear-gradient(to right, #292799, #232f41)'
                    : 'linear-gradient(to right, #7e9ce1, #9bb8d9, #504baa)', //'linear-gradient(to right, #d1dfff, #c8dbf0)',
            '&:hover': {
                background:
                    theme.palette.mode === 'dark' ? 'linear-gradient(to right, #49309a, #828cb4)' : 'linear-gradient(to right, #b3c9fb, #b5d2f3)',
            },
        },
    }
}

export default StyleDataGrid
