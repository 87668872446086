import { useState } from 'react'
import { Box, useTheme, Button, IconButton, Typography, Popover } from '@mui/material'
import { tokens } from '../../../theme'
import Header from '../../../components/Header'
import Bar from '../../bar'
import BarChart from '../../../components/BarChart'
import { mockBarNewUser } from '../../../data/mockData'

const StatisticsIncome = () => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    return (
        <Box
            m='20px'
            sx={{
                '& .MuiTypography-root.MuiTypography-h2': {
                    margin: '0 0 5px 0',
                },
            }}
        >
            <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Header title='THỐNG KÊ DOANH THU' subtitle='Trang thống kê doanh thu' />
            </Box>
            <Box height='75vh' m='40px 0 0 0'>
                <Box>
                    TỔNG SỐ LƯỢNG NGƯỜI SỬ DỤNG:{' '}
                    <Typography fontWeight={700} display='inline-block' variant='h4'>
                        2300
                    </Typography>
                </Box>
                {/* <BarChart nameChart='statisticsUser' data={mockBarNewUser} /> */}
            </Box>
        </Box>
    )
}

export default StatisticsIncome
